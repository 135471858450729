import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { createTheme, ThemeProvider } from '@mui/material';
import App from './App';
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import ThemeOptions from './theme';
//import { onUpdate } from './serviceWorkerUpdateAvailable';


const theme = createTheme(ThemeOptions);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register({
//   onUpdate,
// });
