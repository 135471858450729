import { TblRecycleBin } from '../../../generated/graphql';
import {logger} from "../../../helpers/logger";

export const tblRecycleBinQuery = (doc: TblRecycleBin) => {
  if (!doc) {
    doc = {
      autoId: 0,
      PKey: '',
      fldTable: '',
      fldRecordKey: '',
      fldDateDeleted: new Date(0).toISOString(),
      updatedAt: new Date(0).toISOString(),
    };
  }
  logger('TblRecycleBin').info(
    `pull query triggered - TblRecycleBin:  { ${new Date().toISOString()} - ID: ${doc.autoId} , updatedAt: ${doc.updatedAt} }`,
  );
  return {
    query: `
      query GetLatestTblRecycleBin($autoId: Int, $filterDate: Date) {
        tblRecycleBin(
          where: {            
            updatedAt: {_gte: $filterDate}            
            autoId: {_gt: $autoId}
          },
          order_by: [
            {updatedAt: asc},
            {autoId: asc}],
          limit: 500
        ) {
          fldDateDeleted
          fldDeletedBy
          fldRecordKey
          fldTable
          PKey
          updatedAt
          autoId
        }
      }
    `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
    },
  };
};

export const tblRecycleBinSubscription = `
  subscription onTblRecycleBinChanged {
    tblRecycleBin {
      fldDateDeleted
      fldDeletedBy
      fldRecordKey
      fldTable
      PKey
    }
  }
`;
