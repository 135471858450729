import { gql, useQuery  } from "@apollo/client";
import { Inventory, InventoryLocation } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";

export const GET_INVENTORY_LOCATION = gql`
  query GetInventoryLocation($productId: String!) {
    inventoryLocations(productId: $productId) {
      inventoryLocations {
        amount
        locationPath
        inventory {
          productId
        }
      }
    }
  }
`

interface InventoryLocationResult {
  inventoryLocation: InventoryLocation[];
  inventoryLocationLoading: boolean;
}

export const useGetInventoryLocation = (productId: string | undefined): InventoryLocationResult => {

  if (!productId) {
    return {
      inventoryLocation: [],
      inventoryLocationLoading: false,
    };
  }

  const { data, loading, error } = useQuery(GET_INVENTORY_LOCATION, {
    variables: { productId },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    logger('GetInventoryLocation').error('Error fetching locations for spare-inventory -->', error.message);
  }
  
  return {
    inventoryLocation: data?.inventoryLocations?.inventoryLocations || [],
    inventoryLocationLoading: loading,
  };
};

  