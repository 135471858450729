import '../../../App.css';
import '../../../theme/styles.css';
import '../styles.css';
import { FC, useEffect, useState } from 'react';
import { get, isEmpty } from 'lodash';
import { WorkIssuesDocument } from 'src/rxdb/collections/WorkIssues/rxdb';
import { useForm } from 'react-hook-form';
import { LogEntryDocument } from 'src/pages/LogEntryPage/rxdb';
import { useAuth } from '../../../contexts/auth';
import { TblSparesUsedDocument } from '../../SparesTab/rxdb';
import { getDatabase } from '../../../rxdb';
import { normalizeDateFormValue } from 'src/helpers';
import DatePicker from 'src/components/UI/Forms/DatePicker';
import RichTextEditor from 'src/components/UI/Forms/RichTextEditor';
import { isValidDateFormat } from 'src/utils/format-dates';

interface Props {
  workIssue: WorkIssuesDocument;
  logEntry: LogEntryDocument;
  onSubmit: (data: any, spares: TblSparesUsedDocument[]) => void;
}

const LogEntryCheckDetailForm: FC<Props> = ({
  workIssue,
  logEntry,
  onSubmit,
}) => {
  const { user } = useAuth();
  const {
    control, setValue, handleSubmit, getValues, reset,
  } = useForm<any>({
    // For uncontrolled components keep empty string or undefined. Null wouldn't work.
    defaultValues: {
      fldCompany: workIssue.Company || '',
      Department: workIssue.Department || null,
      LogDate: normalizeDateFormValue(logEntry.LogDate || new Date()),
      fldCost: workIssue.fldActualCost || logEntry.fldCost || 0,
      fldEnteredBy: logEntry.fldEnteredBy || user?.fldCrewID || '',
      LogEntry: `${workIssue.Subject}${
        workIssue.Notes ? `<br/><br/>${workIssue.fldHTML}` : ''
      }`,
      fldHTML:`${workIssue.Subject}${
        workIssue.Notes ? `<br/><br/>${workIssue.fldHTML}` : ''
      }`,
      fldHours: logEntry.fldHours || '',
      fldIsWarranty: workIssue.fldIsWarranty,
      fldPerformedBy: isEmpty(logEntry.fldPerformedBy)
      ? [`${user?.fldFirst} ${user?.fldLast}`]
      : logEntry.fldPerformedBy?.split(', '),
      // fldPerformedBy: logEntry.fldPerformedBy || user?.fldCrewID || '',
      fldRestricted: logEntry.fldRestricted || false,
      fldSMS: logEntry.fldSMS || false,
      fldTime: workIssue.fldActualWork || logEntry.fldTime || '',
      fldTimeQty: logEntry.fldTimeQty || '',
      fldWorkList: workIssue.fldWorkList,
      EqKey: workIssue.EqKey,
      Equipment: null,
      fldSRHKey: workIssue.fldSRHKey,
      fldLocHierarchy: workIssue.fldLocHierarchy,
      Curr: workIssue.fldActualCurrency || logEntry.Curr || null,
    },
  });

  const setInitialValues = async () => {
    const equipment = await workIssue.populate('EqKey');

    reset({
      ...getValues(),
      Equipment: equipment?.toJSON() || null,
    });
  };

  const spares: TblSparesUsedDocument[] = []; // Passing an empty list for spares as Checks don't relate with spares.
  const onFormSubmit = (data: any) => onSubmit(data, spares);

  useEffect(() => {
    setInitialValues();
  }, []);

  const onChange = (name: string, value: any) => setValue(name, value);

  return (
    <form
      id="logentry-details-form"
      className="relative bg-white flex-grow"
      onSubmit={handleSubmit(onFormSubmit)}
    >
      <div className="bg-white h-full flex-grow pt-6">
        <div className="px-6 h-full">
          <div className="mb-6">
            <h3 className="text-xl font-bold">{workIssue.Subject}</h3>
          </div>
          <div className="mt-3">
            <div className="mt-3 flex flex-col md:flex-row">
              <DatePicker
                rules={{
                  required: '*Date value is required for this field!',
                  validate: (value) => isValidDateFormat(value) || 'Please enter a valid date format dd-MMM-yyyy',
                }}
                name="LogDate"
                control={control}
                label="Date Completed"
              />
            </div>

            <div className="my-4">
              <RichTextEditor
                label="Log Entry"
                control={control}
                name="fldHTML"
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default LogEntryCheckDetailForm;
