import React, { FC } from 'react';
import Button from '@mui/material/Button';
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FilterSpareGridOld from 'src/components/SparesTab/components/SpareForm/components/FliterSpareGridOld';

interface InjectedProps {
  filterSparePopup: boolean;
  onCancelInventoryPopup: any;
  onSelectInventory: any;
  onAddClick: any;
  keyValue?: string | null;
}

const FilteredSparePopupOld: FC<InjectedProps> = ({
  filterSparePopup,
  onCancelInventoryPopup,
  onSelectInventory,
  onAddClick,
  keyValue,
}) => (
  <Dialog
    open={filterSparePopup}
    onClose={onCancelInventoryPopup}
    fullWidth
    maxWidth="md"
    scroll="paper"
    aria-labelledby="scroll-dialog-title"
    aria-describedby="scroll-dialog-description"
  >
    <DialogTitle sx={{ m: 0, p: 4 }} style={{ fontSize: '26px' }}>
      <span className="ml-2">Select Spare</span>
      {onCancelInventoryPopup ? (
        <IconButton
          aria-label="close"
          onClick={onCancelInventoryPopup}
          sx={{
            position: 'absolute',
            right: 10,
            top: 14,
            color: (theme) => theme.palette.grey[400],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
    <DialogContent dividers style={{ maxHeight: '60vh' }}>
      <span className="ml-2 mb-2"> To select spare click/tap on row</span>
      <FilterSpareGridOld
        keyValue={keyValue}
        onSelect={onSelectInventory}
      />
    </DialogContent>
    <DialogActions sx={{ m: 0, p: 3 }}>
      <Button onClick={onCancelInventoryPopup} className="w-32">
        Cancel
      </Button>

      <Button
        onClick={onAddClick}
        className="flex justify-end ml-4 mr-3"
        variant="contained"
      >
        Search more spare from inventory
      </Button>
    </DialogActions>
  </Dialog>
);

export default FilteredSparePopupOld;
