import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import PageHeader from 'src/components/UI/PageHeader';
import './home.scss';
import { useAppState } from 'src/contexts/app-state';
import InventoryChartTile from './component/InventoryChartTile';
import EquipmentChartTile from './component/EquipmentChartTile';
import VesselTile from './component/VesselTile';

const Home = () => {
  const [eqTileDisplay, seEqTileDisplay] = useState(false);
  const [invTileDisplay, seInvTileDisplay] = useState(false);
  const { settingsPersonal } = useAppState();

  const init = async () => {
    if (settingsPersonal.fldEQ != null) {
      if (settingsPersonal.fldNoRights != true && settingsPersonal?.fldEQ > 0) {
        seEqTileDisplay(true);
      }
    }
    if (settingsPersonal.fldInv != null) {
      if (settingsPersonal.fldNoRights != true && settingsPersonal?.fldInv > 0) {
        seInvTileDisplay(true);
      }
    }

  };

  useEffect(() => {
    if (settingsPersonal) {
      init();
    }
  }, [settingsPersonal]);

  const renderView = () => (
    <Grid container spacing={5}>
      {invTileDisplay && (
        <Grid item xs={12} lg={4}>
          <InventoryChartTile />
        </Grid>
      )}
      <Grid item xs={12} sm={6} lg={4}>
        <VesselTile />
      </Grid>
      {eqTileDisplay && (
        <Grid item xs={12} sm={6} lg={4}>
          <EquipmentChartTile />
        </Grid>
      )}
    </Grid>
  );

  return (
    <div className="flex overflow-auto">
      <div className="flex flex-grow flex-col overflow-auto">
        <PageHeader title="Dashboard" />
        <div className="p-1 min-h-screen overflow-auto ">{renderView()}</div>
      </div>
    </div>
  );
};

export default Home;
