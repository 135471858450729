import React, { useState, FC, useCallback, MutableRefObject } from 'react';
import '../style.css'
import { pick, size } from 'lodash';
import { IconButton } from '@mui/material';
import { InventoryDocument } from 'src/pages/InventoryPage/rxdb';
import { getDatabase } from 'src/rxdb';
import { TypeComputedProps, TypeSortInfo } from '@inovua/reactdatagrid-enterprise/types';
import { Download } from '@mui/icons-material';
import LicensedReactDataGrid from 'src/components/UI/LicensedReactDataGrid';
import { MultipleLocationDocument } from 'src/rxdb/collections/MultipleLocations/schema';
import WarningDialog from 'src/components/UI/WarningDialog';
import { isEmpty } from 'lodash';

interface Props {
    initialValue: InventoryDocument;
    onDelete: (item: MultipleLocationDocument) => void;
    loadingGrid: boolean;
    onSelect: (item: MultipleLocationDocument) => void;
}
const transformData = async (item: any) => ({
    ...pick(item, ['PKey', 'ProductID', 'Amount', 'fldLocHierarchy', 'UseBy']),
    original: item,
});
const InvStorageGrid: FC<Props> = ({
    initialValue,
    onDelete,
    loadingGrid,
    onSelect,
}) => {
    const [loading, setLoading] = useState<boolean>(loadingGrid);
    const [isDeleting, setIsDeleting] = useState(false);
    const [deleteSelected, setDeleteSelected] = useState<any>();

    const loadData = async ({
        skip,
        limit,
    }: {
        sortInfo?: TypeSortInfo;
        skip?: number;
        limit?: number;
    }): Promise<{ data: any[]; count: number }> => {
        const db = await getDatabase();

        const length = size(await db.inveq.find().exec());

        let item: any = await db.tblmultiplelocations
            .find({
                selector: {
                    ProductID: initialValue.ProductID,
                },
            })
            .exec();
        item = item.filter((i: any) => !i.deletedAt);
        const data = await Promise.all(item.map(transformData));

        for (const dataItem of data) {
            dataItem.fldLocHierarchy = await getLocNodes(dataItem, db);
        }
        return { data, count: length };
    };

    const getLocNodes = async (item: any, db: any) => {
        const locArrayData = [];
        let node: any = {
            PKey: '',
            fldLocation: '',
            ParentID: item.fldLocHierarchy,
        };
        while (node.ParentID) {
            node = await db.tbllocationshierarchy
                .findOne({
                    selector: {
                        PKey: node.ParentID,
                    },
                })
                .exec();
            locArrayData.push(node.fldLocation);
        }
        return locArrayData.reverse().join(' >> ');
    };

    const init = async (ref: MutableRefObject<TypeComputedProps | null>) => {
        const db = await getDatabase();

        db.tblmultiplelocations.$.subscribe(async (ev: any) => {
            ref.current?.reload();
        });
    };

    const onReady = (ref: MutableRefObject<TypeComputedProps | null>) => {
        init(ref);
    };

    const onLoadingChange = (status: boolean) => {
        setLoading(status);
    };

    const rowDeletePress = (item: any) => {
        setDeleteSelected(item.original);
        setIsDeleting(true);
    };
    const handleDeleteOk = () => {
        onDelete(deleteSelected);
        setIsDeleting(false);
        setDeleteSelected(undefined);
    };
    const handleDeleteCancel = () => {
        setIsDeleting(false);
        setDeleteSelected(undefined);
    };
    const rowEdit = (item: any) => {
        onSelect(item.original);
    };

    const dataSource = useCallback(loadData, []);

    const columns = [
        {
            name: 'Amount',
            header: 'Amount',
            defaultWidth: 95,
            headerAlign: 'start' as any,
            textAlign: 'end' as any,
            onRender: (cellProps: any, { data }: { data: any }) => {
                if (
                    !isEmpty(data?.UseBy)
                    && new Date() > new Date(data?.UseBy)
                ) {
                    cellProps.style.borderLeft = 'red 3px solid';
                } else {
                    cellProps.style.borderLeft = '#e4e3e2 3px solid';
                }
            },
        },
        {
            name: 'fldLocHierarchy',
            header: 'Location',
            flex: 1,
        },
        {
            id: 'icons',
            defaultWidth: 95,
            render: ({ data }: any) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <IconButton
                        onClick={() => rowEdit(data)}
                        size="small"
                        aria-label="Receive item"
                    >
                        <Download fontSize="inherit" color="primary" />	
                    </IconButton>
                </div>
            ),
        },
    ];

    return (
        <>
            <div className="flex flex-col flex-grow inv-storage-grid">
                <LicensedReactDataGrid
                    onLoadingChange={onLoadingChange}
                    onReady={onReady}
                    rowHeight={40}
                    loading={loading}
                    idProperty="ProductID"
                    columns={columns}
                    dataSource={dataSource}
                />
            </div>
            <WarningDialog
                visible={isDeleting}
                title="Delete Warning"
                content="Are you sure you wish to delete record?"
                okText='Yes'
                color='error'
                onOk={handleDeleteOk}
                onCancel={handleDeleteCancel}
            />
        </>
    );
};

export default InvStorageGrid;