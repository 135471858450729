import React, { FC, useEffect, useState } from 'react';
import { Control, UseFormWatch } from 'react-hook-form';
import { LogEntryDocument } from 'src/pages/LogEntryPage/rxdb';
import { isArray, isNil, last } from 'lodash';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
  useMediaQuery,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { getDatabase } from '../../../../rxdb';
import RichTextEditor from '../../../UI/Forms/RichTextEditor';
import Checkbox from '../../../UI/Forms/Checkbox';
import DatePicker from '../../../UI/Forms/DatePicker';
import Input from '../../../UI/Forms/Input';
import { CrewDocument } from '../../../../rxdb/collections/Crew/schema';
import Select from '../../../UI/Forms/Select';
import ListDefaultAutocomplete from '../../../UI/Forms/ListDefaultAutocomplete';
import CurrenciesDropdownOld from '../../../Dropdowns/CurrenciesDropdown/indexOld';
import CategoryDropdownOld from '../../../Dropdowns/CategoryDropdown/indexOld';
import EquipmentDropdownOld from '../../../Dropdowns/EquipmentDropdown/indexOld';
import LocationDropdownOld from '../../../Dropdowns/LocationDropdown/indexOld';
import { useAppState } from 'src/contexts/app-state';
import MultiSelect from 'src/components/UI/Forms/MultiSelect';
import { isValidDateFormat } from 'src/utils/format-dates';
import CompaniesDropdownOld from 'src/components/Dropdowns/CompaniesDropdown/indexOld';

interface LogEntrySummaryProps {
  initialValue: LogEntryDocument;
  onChange: (field: string, value: any) => void;
  control: Control;
  watch: UseFormWatch<any>;
  isNotCompletion?: boolean;
  disableEdit?: boolean;
}

const LogEntrySummaryFormOld: FC<LogEntrySummaryProps> = ({
  initialValue,
  onChange,
  control,
  watch,
  isNotCompletion,
  disableEdit = false,
}) => {
  const [assignments, setAssignments] = useState<string[]>([]);
  const { settingsPersonal } = useAppState();
  const isMobile = useMediaQuery('(max-width: 420px)')
  const [fldCountHours, setFldCountHours] = useState<boolean>(false)

  const init = async () => {
    const db = await getDatabase();
    db.crew.find({
      selector: {
        fldIsGuest: {$eq: false},// remove guest from list
        deletedBy: {$eq: null},// Deleted filter
        DateFire: {$eq: null},// released crew filter
     }, 
    }).$.subscribe((data: any) => {
      const crewProfileData = data.map((item: any) => ({ fldCrewID: item.fldCrewID, name: `${item.fldFirst} ${item.fldLast}` }));
      setAssignments(crewProfileData);
    });
    if (initialValue?.EqKey != null) {
      db.equipment.findOne({ selector: {EqKey: initialValue.EqKey} })
        .$.subscribe((equipment) => {
            setFldCountHours(equipment?.fldCountHours ?? false);
        });
    }
  };

  useEffect(() => {
    if (isNil(initialValue)) return;

    init();
  }, [initialValue]);

  const equipment = watch('Equipment', null);
  const category = watch('fldSRHKey', null);

  return (
    <div className="mt-3">
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="general-information"
          id="general-information"
        >
          General Information
        </AccordionSummary>
        <AccordionDetails>
          <div className="mt-3 flex flex-col md:flex-row">
            <DatePicker
              rules={{
                required: '*Date value is required for this field!',
                validate: (value) => isValidDateFormat(value) || 'Please enter a valid date format dd-MMM-yyyy',
              }}
              name="LogDate"
              control={control}
              label="Date Completed"
            />
            {fldCountHours && (
              <div className={isMobile ? "mt-3" : "ml-3 w-1/4"}>
                <Input
                  control={control}
                  name="fldHours"
                  inputProps={{
                    label: 'Equipment Hours',
                    sx: {
                      '& .MuiOutlinedInput-root': {
                        '& .MuiOutlinedInput-input': {
                          textAlign: 'right',
                        },
                      },
                    },
                  }}
                />
              </div>
            )}
          </div>

          <div className="mt-3">
            <CategoryDropdownOld
              disabled={!isNotCompletion}
              name="fldSRHKey"
              label="Category"
              control={control}
              onChange={onChange}
              sourceForm="logentry"
              rules={{
                required: true,
              }}
              allDepts={settingsPersonal?.fldAllDepts > 0}
            />
          </div>

        {!isNil(equipment) && (
          <div className="mt-3">
            <EquipmentDropdownOld
              disabled={!isNotCompletion}
              label="Equipment"
              control={control}
              category={(isArray(category) ? last(category) : category) || null}
              onChange={onChange}
            />
          </div>
        )}

          <div className="mt-3">
            <LocationDropdownOld
              disabled={!isNotCompletion}
              control={control}
              label="Location"
              name="fldLocHierarchy"
              onChange={onChange}
              sourceForm="logentry"
              allDepts={settingsPersonal?.fldAllDepts > 0}
            />
          </div>

          <div className="my-4">
            <RichTextEditor
              control={control}
              name="fldHTML"
              onChange={onChange}
            />
          </div>

          <div className="mt-3">
            <FormGroup row>
              <Checkbox
                control={control}
                name="fldRestricted"
                label="Restricted to HoD"
              />
              <Checkbox
                control={control}
                name="fldSMS"
                label="Critical Log Entry (SMS Required)"
              />
            </FormGroup>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion className="mt-3" defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="additional-details"
          id="additional-details"
        >
          Additional Details
        </AccordionSummary>
        <AccordionDetails>
          <div className="mt-3">
            <MultiSelect
              control={control}
              name='fldPerformedBy'
              keyExpr='fldPerformedBy'
              displayExpr='name'
              options={assignments}
              rules={{
                required: 'Performed By is required!',
              }}
              label='Performed By'
              onChange={onChange}
            />
          </div>

          <div className="mt-6">
            <ListDefaultAutocomplete
              disabled = {disableEdit}
              onChange={onChange}
              control={control}
              name="Department"
              label="Department"
              listName="Department"
            />
          </div>

          {/* <div className="mt-6">
            <Select
              selectProps={{
                renderItem: (option: CrewDocument) => (
                  <MenuItem key={option.fldCrewID} value={option.fldCrewID}>
                    {option.fldFirst}
                    {' '}
                    {option.fldLast}
                  </MenuItem>
                ),
              }}
              rules={{
                required: 'Entered By is required!',
              }}
              name="fldEnteredBy"
              control={control}
              label="Entered By"
              options={assignments}
            />
          </div> */}

          <div className="mt-6">
            <ListDefaultAutocomplete
              disabled
              onChange={onChange}
              control={control}
              name="fldWorkList"
              label="Worklist Name"
              listName="Worklist Name"
            />
          </div>

          <div className="mt-6">
            <CompaniesDropdownOld
              control={control}
              label="Company/Contractor"
              name="fldCompany"
              onChange={onChange}
            />
          </div>

          <div className="mt-6">
            <div className="flex flex-row items-center">
              <Input
                control={control}
                name="fldTime"
                inputProps={{
                  type: 'number',
                  inputProps: { min: 1 },
                  label: 'Time to Complete',
                  className: 'mr-6',
                  sx: {
                    '& .MuiOutlinedInput-root': {
                      '& .MuiOutlinedInput-input': {
                        textAlign: 'right',
                      },
                    },
                  },
                }}
              />
              <Select
                control={control}
                name="fldTimeQty"
                options={['Hours', 'Minutes']}
              />
            </div>
          </div>

          <div className="mt-6 flex flex-col md:flex-row">
            <Input
              control={control}
              name="fldCost"
              inputProps={{
                label: 'Cost',
                sx: {
                  marginRight: 7,
                  '& .MuiOutlinedInput-root': {
                    '& .MuiOutlinedInput-input': {
                      textAlign: 'right',
                    },
                  },
                },
              }}
            />

            <CurrenciesDropdownOld
              label="Currency"
              onChange={onChange}
              control={control}
              name="Curr"
            />
          </div>

          <div className="mt-6">
            <Checkbox
              control={control}
              name="fldIsWarranty"
              label="Warranty Item"
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default LogEntrySummaryFormOld;
