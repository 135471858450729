import { parseISO } from 'date-fns';
import { head, get, mapValues, isNil, chunk, isEmpty } from 'lodash';
import {Client, createClient} from 'graphql-ws';
import moment from 'moment';
import idb from 'pouchdb-adapter-idb';
import mdb from 'pouchdb-adapter-memory';
import mapReduce from 'pouchdb-mapreduce';
import { addRxPlugin, createRxDatabase, RxDatabase } from 'rxdb';
import {
  RxDBReplicationGraphQLPlugin,
  RxGraphQLReplicationState,
} from 'rxdb/plugins/replication-graphql';
import { RxDBValidatePlugin } from 'rxdb/plugins/validate';
import { RxDBUpdatePlugin } from 'rxdb/plugins/update';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { CrewProfiles, Equipment, InvBarCodes, InvEq, TblContactManager } from 'src/generated/graphql';
import {
  CertificatesMutation,
  CertificatesQuery,
  CertificatesSubscription,
} from 'src/rxdb/collections/Certificates/queries';
import {
  CertificatesCollection,
  certificatesCollectionMethods,
  certificatesDocMethods,
  certificatesSchema,
} from 'src/rxdb/collections/Certificates/rxdb';
import {
  equipmentMutation,
  equipmentQuery,
  equipmentSubscription,
} from 'src/pages/EquipmentPage/queries';
import {
  EquipmentCollection,
  equipmentCollectionMethods,
  equipmentDocMethods, EquipmentDocument, equipmentIndexes,
  equipmentSchema,
} from 'src/pages/EquipmentPage/rxdb';
import {
  inventoryMutation,
  inventoryQuery,
  inventorySubscription,
} from 'src/pages/InventoryPage/queries';
import {
  InventoryCollection,
  inventoryCollectionMethods,
  inventoryDocMethods,
  inventorySchema,
} from 'src/pages/InventoryPage/rxdb';
import {
  LogEntryMutation,
  LogEntryQuery,
  logEntrySubscription,
} from 'src/pages/LogEntryPage/queries';
import {
  LogEntryCollection,
  logentryCollectionMethods,
  logentryDocMethods,
  logentrySchema,
} from 'src/pages/LogEntryPage/rxdb';
import {
  workIssuesMutation,
  workIssuesQuery,
  workissuesSubscription,
} from 'src/rxdb/collections/WorkIssues/queries';
import {
  WorkIssuesCollection,
  workIssuesCollectionMethods,
  workIssuesDocMethods,
  workIssuesSchema,
} from 'src/rxdb/collections/WorkIssues/rxdb';
import {
  CurrenciesQuery,
  CurrenciesSubscription,
} from '../api/queries/Currencies/queries';
import {
  CurrenciesCollection,
  currenciesCollectionMethods,
  currenciesDocMethods,
  currenciesSchema,
} from '../api/queries/Currencies/rxdb';
// import { statesQuery, statesSubscription } from '../api/queries/States/queries';
// import {
//   StatesCollection,
//   statesCollectionMethods,
//   statesDocMethods,
//   StatesSchema,
// } from '../api/queries/States/rxdb';
import {
  tblDDListDefaultMutation,
  tblDDListDefaultsQuery,
  tblDDListDefaultsSubscription,
} from '../api/queries/tblDDListDefaults/queries';
import {
  TblDdListDefaultsCollection,
  tblDDListDefaultsCollectionMethods,
  tblDDListDefaultsDocMethods,
  tblDDListDefaultsSchema,
} from '../api/queries/tblDDListDefaults/rxdb';
import {
  tblDL4CellRefsPullQuery,
  tblDL4CellRefsSubscription,
} from 'src/rxdb/collections/DL4CellRefs/queries';
import {
  TblDl4CellRefsCollection,
  tblDl4CellRefsCollectionMethods,
  tblDl4CellRefsDocMethods,
  tblDL4CellRefsSchema,
} from 'src/rxdb/collections/DL4CellRefs/schema';
import {
  tblMonSysTagsPullQuery,
  tblMonSysTagsSubscription,
} from 'src/rxdb/collections/tblMonSysTags/queries';
import {
  TblMonSysTagsCollection,
  tblMonSysTagsCollectionMethods,
  tblMonSysTagsDocMethods,
  tblMonSysTagsSchema,
} from 'src/rxdb/collections/tblMonSysTags/schema';
import {
  tblDocumentationMutation,
  tblDocumentationQuery,
  tblDocumentationSubscription,
} from '../api/queries/tblDocumentation/queries';
import {
  TblDocumentationCollection,
  tblDocumentationCollectionMethods,
  tblDocumentationDocMethods,
  tblDocumentationSchema,
} from '../api/queries/tblDocumentation/rxdb';
import {
  tblDocumentCrossReferenceMutation,
  tblDocumentCrossReferenceQuery,
  tblDocumentCrossReferenceSubscription,
} from '../api/queries/tblDocumentCrossReference/queries';
import {
  TblDocumentCrossReferenceCollection,
  tblDocumentCrossReferenceSchema,
} from '../api/queries/tblDocumentCrossReference/rxdb';
import {
  tblRecycleBinQuery,
  tblRecycleBinSubscription,
} from '../api/queries/tblRecycleBin/queries';
import {
  TblRecycleBinCollection,
  tblRecycleBinCollectionMethods,
  tblRecycleBinDocMethods,
  tblRecycleBinSchema,
} from '../api/queries/tblRecycleBin/rxdb';
import {
  tblShipsParticularsQuery,
  tblShipsParticularsMutation,
  tblShipsParticularsSubscription,
} from '../api/queries/tblShipsParticulars/queries';
import {
  TblShipsParticularsCollection,
  tblShipsParticularsCollectionMethods,
  tblShipsParticularsDocMethods,
  tblShipsParticularsSchema,
} from '../api/queries/tblShipsParticulars/rxdb';
// import {
//   vDDAllContactsQuery,
//   vDDAllContactsSubscription,
// } from '../api/queries/vDDAllContactsDistinct/queries';
// import {
//   VDdAllContactsDistinctCollection,
//   vDdAllContactsDistinctCollectionMethods,
//   vDdAllContactsDistinctDocMethods,
//   vDdAllContactsDistinctSchema,
// } from '../api/queries/vDDAllContactsDistinct/rxdb';
import {
  tblSystemsHierarchyMutation,
  tblSystemsHierarchyQuery,
  tblSystemsHierarchySubscription,
} from '../components/Dropdowns/CategoryDropdown/queries';
import {
  TblSystemsHierarchyCollection,
  tblSystemsHierarchyCollectionMethods,
  tblSystemsHierarchyDocMethods,
  tblSystemsHierarchySchema,
} from '../components/Dropdowns/CategoryDropdown/rxdb';
import {
  tblLocationsHierarchyQuery,
  tblLocationsHierarchyMutation,
  tblLocationsHierarchySubscription,
} from '../components/Dropdowns/LocationDropdown/queries';
import {
  TblLocationsHierarchyCollection,
  tblLocationsHierarchyCollectionMethods,
  tblLocationsHierarchyDocMethods,
  tblLocationsHierarchySchema,
} from '../components/Dropdowns/LocationDropdown/rxdb';
import {
  tblSparesUsedMutation,
  tblSparesUsedQuery,
  tblSparesUsedSubscription,
} from '../components/SparesTab/queries';
import {
  TblSparesUsedCollection,
  tblSparesUsedCollectionMethods,
  tblSparesUsedDocMethods,
  tblSparesUsedSchema,
} from '../components/SparesTab/rxdb';
import {NODE_URL, NODE_WS_URL} from '../consts';
import {
  anyReportsMutation,
  anyReportsPullQuery,
  anyReportsSubscription,
} from './collections/AnyReports/queries';
import anyReportsSchema, {
  AnyReportCollection,
} from './collections/AnyReports/schema';
import {
  anyReportHistoryMutation,
  anyReportHistorySubscription,
  anyReportsHistoryPullQuery,
} from './collections/AnyReportsHistory/queries';
import anyReportHistorySchema, {
  AnyReportHistoryCollection,
} from './collections/AnyReportsHistory/schema';
import {
  commentsMutation,
  commentsPullQuery,
  commentsSubscription,
} from './collections/Comments/queries';
import commentsSchema, {
  CommentCollection,
} from './collections/Comments/schema';
import {
  crewProfilesMutation,
  crewProfilesSubscription,
  crewPullQuery,
} from './collections/Crew/queries';
import crewSchema, { CrewCollection } from './collections/Crew/schema';
import {
  documentRevisionPullQuery,
  documentRevisionsSubscription,
  tblDocumentRevisionsMutation,
} from './collections/DocumentRevisions/queries';
import documentRevisionSchema, {
  DocumentRevisionCollection,
} from './collections/DocumentRevisions/schema';
import {
  hrsOfRestPullMutation,
  hrsOfRestPullQuery,
  hrsOfRestSubscription,
} from './collections/HoursOfRest/queries';
import hoursOfRestSchema, {
  HrsOfRestCollection,
  HrsOfRestDocument,
} from './collections/HoursOfRest/schema';
import {
  invBarCodesMutation,
  invBarCodesPullQuery,
} from './collections/InvBarCodes/queries';
import invBarCodesSchema, {
  InvBarCodesCollection,
} from './collections/InvBarCodes/schema';
import {
  invEqMutation,
  invEqPullQuery,
  invEqSubscription,
} from './collections/InvEq/queries';
import invEqSchema, { InvEqCollection } from './collections/InvEq/schema';
import {
  multipleLocationsMutation,
  multipleLocationsPullQuery,
  multipleLocationsSubscription,
} from './collections/MultipleLocations/queries';
import tblMultipleLocations, {
  MultipleLocationCollection,
} from './collections/MultipleLocations/schema';
import {
  orderItemsPullQuery,
  orderItemsMutation,
  orderItemsSubscription,
} from './collections/OrderItems/queries';
import orderItemsSchema, {
  OrderItemsCollection,
} from './collections/OrderItems/schema';
import {
  ordersPullQuery,
  ordersMutation,
  ordersSubscription,
} from './collections/Orders/queries';
import { 
  OrdersCollection,
  ordersCollectionMethods,
  ordersDocMethods,
  ordersSchema,
 } from './collections/Orders/schema';
import {
  tblRunningLogPullQuery,
  tblRunningLogMutation,
  tblRunningLogSubscription
} from './collections/RunningLog/queries';
import tblRunningLogSchema, { TblRunningLogCollection } from './collections/RunningLog/schema';
import {
  tblSchedChkPullQuery,
  tblSchedChkMutation,
  tblSchedChkSubscription
} from './collections/SchedChk/queries';
import tblSchedChkSchema, { TblSchedChkCollection } from './collections/SchedChk/schema';
import {
  tblSchedChkDescPullQuery,
  tblSchedChkDescMutation,
  tblSchedChkDescSubscription
} from './collections/SchedChkDesc/queries';
import tblSchedChkDescSchema, { TblSchedChkDescCollection } from './collections/SchedChkDesc/schema';
import {
  schedMaintMutation,
  schedMaintQuery,
  schedMaintSubscription,
} from './collections/SchedMaint/queries';
import tblSchedMainSchema, {
  SchedMaintCollection,
} from './collections/SchedMaint/schema';
import {
  schedMaintEqMutation,
  schedMaintEqQuery,
  schedMaintEqSubscription,
} from './collections/SchedMaintEQ/queries';
import tblSchedMainEqSchema, {
  SchedMaintEQCollection, SchedMaintEQDocument,
} from './collections/SchedMaintEQ/schema';
import {
  tblSchedMaintPartsMutation,
  tblSchedMaintPartsQuery,
  tblSchedMaintPartsSubscription,
} from './collections/SchedMaintParts/queries';
import tblSchedMainPartsSchema, {
  TblSchedMaintPartsCollection,
} from './collections/SchedMaintParts/schema';
import {
  settingsPersonalPullQuery,
  settingsPersonalSubscription,
} from './collections/SettingsPersonal/queries';
import {
  settingsApplicationPullQuery,
  settingsApplicationSubscription,
  tblSettingsApplicationMutation,
} from './collections/SettingsApplication/queries';
import settingsPersonalSchema, {
  SettingsPersonalCollection,
  SettingsPersonalDocument,
} from './collections/SettingsPersonal/schema';
import settingsApplicationSchema, {
  SettingsApplicationCollection,
} from './collections/SettingsApplication/schema';

import {
  dayLogsPullQuery,
  tblLogCustomMutation,
  dayLogsSubscription,
} from './collections/DayLogCustom/queries';
import dayLogsSchema, {
  EngineerDayLogCustomCollection,
} from './collections/DayLogCustom/schema';

import tblRlMetaGrpsNeqSchema, {
  TblRlMetaGrpsNeqCollection,
} from './collections/RLMetaGrpsNEQ/schema';
import {
  tblRlMetaGrpsNeqPullQuery,
  tblRlMetaGrpsNeqMutation,
  tblRlMetaGrpsNeqSubscription,
} from './collections/RLMetaGrpsNEQ/queries';
import tblRlEnumsSchema, {
  TblRlEnumsCollection,
} from './collections/RLEnums/schema';
import {
  tblRlEnumsPullQuery,
  tblRlEnumsSubscription,
} from './collections/RLEnums/queries';

import tblRlDataSchema, {
  TblRlDataCollection,
} from './collections/RLData/schema';
import {
  tblRlDataPullQuery,
  tblRlDataMutation,
  tblRlDataSubscription,
} from './collections/RLData/queries';
import { RxReplicationState } from 'rxdb/dist/types/types';
import {
  TblGridSavedViewsCollection,
  tblGridSavedViewsCollectionMethods,
  tblGridSavedViewsDocMethods,
  tblGridSavedViewsSchema
} from './collections/GridSavedView/schema';
import {
  tblGridSavedViewsQuery,
  tblGridSavedViewsMutation,
  tblGridSavedViewsSubscription
} from './collections/GridSavedView/queries';
// import { TblContactManagerCollection, TblContactManagerCollectionMethods, TblContactManagerDocMethods, tblContactManagerSchema } from './collections/tblContactManager/rxdb';
// import { tblContactManagersQuery, tblContactManagerSubscription } from './collections/tblContactManager/queries';
import {logger} from "../helpers/logger";
import { normalizeDateFormValue } from 'src/helpers';
addRxPlugin(RxDBUpdatePlugin);
addRxPlugin(RxDBValidatePlugin);
addRxPlugin(RxDBReplicationGraphQLPlugin);
addRxPlugin(mapReduce);
import * as Sentry from '@sentry/react';

type Collections = {
  comments: CommentCollection;
  documentrevision: DocumentRevisionCollection;
  crew: CrewCollection;
  workissues: WorkIssuesCollection;
  inventory: InventoryCollection;
  engineerdaylog: EngineerDayLogCustomCollection;
  tbllocationshierarchy: TblLocationsHierarchyCollection;
  tblsystemshierarchy: TblSystemsHierarchyCollection;
  equipment: EquipmentCollection;
  certificates: CertificatesCollection;
  tblddlistdefaults: TblDdListDefaultsCollection;
  tblshipsparticulars: TblShipsParticularsCollection;
  tblrecyclebin: TblRecycleBinCollection;
  logentry: LogEntryCollection;
  // vddallcontacts: VDdAllContactsDistinctCollection;
  currencies: CurrenciesCollection;
  tblsparesused: TblSparesUsedCollection;
  // states: StatesCollection;
  tbldocumentation: TblDocumentationCollection;
  tbldl4cellrefs: TblDl4CellRefsCollection;
  tblmonsystags: TblMonSysTagsCollection;
  tbldocumentcrossreference: TblDocumentCrossReferenceCollection;
  inveq: InvEqCollection;
  tblschedchk: TblSchedChkCollection;
  tblschedchkdesc: TblSchedChkDescCollection;
  tblschedmainteq: SchedMaintEQCollection;
  tblschedmaintparts: TblSchedMaintPartsCollection;
  tblschedmaint: SchedMaintCollection;
  tblmultiplelocations: MultipleLocationCollection;
  tblanyreports: AnyReportCollection;
  tblanyreportshistory: AnyReportHistoryCollection;
  hoursofrest: HrsOfRestCollection;
  tblrunninglog: TblRunningLogCollection;
  tblrldata: TblRlDataCollection;
  tblrlenums: TblRlEnumsCollection;
  tblrlmetagrpsneq: TblRlMetaGrpsNeqCollection;
  tblsettingspersonal: SettingsPersonalCollection;
  tblsettingsapplication: SettingsApplicationCollection;
  orders: OrdersCollection;
  orderitems: OrderItemsCollection;
  invbarcodes: InvBarCodesCollection;
  tblgridsavedviews: TblGridSavedViewsCollection;
  // tblcontactmanager: TblContactManagerCollection;
};

export type TDIDb = RxDatabase<Collections>;

const syncURL = `${NODE_URL}/graphql`;

const batchSize = 5;

let dbPromise: Promise<any> | null = null;

function migrateFunc(version: any) {
  const result: any = {};
  for (let index = 1; index <= version; index++) {
    result[index] = function (oldDoc: any) {
      console.log('migrate is processing');
      return null;
    };
  }
  return result;
}

export const createDb = async (test?: boolean) => {
  if (!test) {
    console.log('DatabaseService: creating database..');
  }

  addRxPlugin(test ? mdb : idb);

  const db: TDIDb = await createRxDatabase<Collections>({
    name: 'db',
    adapter: test ? 'memory' : 'idb',
    ignoreDuplicate: true,
    pouchSettings: {
      revs_limit: 1, // Keep only one revision all the time.
      auto_compaction: true,
    },
    // password: "|8S_~|nC1>Vf&-9",
    // queryChangeDetection: true
  });

  if (!test) {
    console.log('DatabaseService: created database');
  }

  (window as any).db = db; // write to window for debugging

  const collections: any = await db.addCollections({
    hoursofrest: {
      schema: hoursOfRestSchema,
      autoMigrate: false,
      migrationStrategies: migrateFunc(hoursOfRestSchema.version),
    },
    tblsettingspersonal: {
      schema: settingsPersonalSchema,
      autoMigrate: false,
      migrationStrategies: migrateFunc(settingsPersonalSchema.version),
    },
    tblsettingsapplication: {
      schema: settingsApplicationSchema,
      autoMigrate: false,
      migrationStrategies: migrateFunc(settingsApplicationSchema.version),
    },
    engineerdaylog: {
      schema: dayLogsSchema,
      autoMigrate: false,
      migrationStrategies: migrateFunc(dayLogsSchema.version),
    },
    tblanyreportshistory: {
      schema: anyReportHistorySchema,
      autoMigrate: false,
      migrationStrategies: migrateFunc(anyReportHistorySchema.version),
    },
    tblanyreports: {
      schema: anyReportsSchema,
      autoMigrate: false,
      migrationStrategies: migrateFunc(anyReportsSchema.version),
    },
    tblmultiplelocations: {
      schema: tblMultipleLocations,
      autoMigrate: false,
      migrationStrategies: migrateFunc(tblMultipleLocations.version),
    },
    tblschedmainteq: {
      schema: tblSchedMainEqSchema,
      autoMigrate: false,
      migrationStrategies: migrateFunc(tblSchedMainEqSchema.version),
    },
    tblschedmaintparts: {
      schema: tblSchedMainPartsSchema,
      autoMigrate: false,
      migrationStrategies: migrateFunc(tblSchedMainPartsSchema.version),
    },
    tblschedmaint: {
      schema: tblSchedMainSchema,
      autoMigrate: false,
      migrationStrategies: migrateFunc(tblSchedMainSchema.version),
    },
    tblrunninglog:{
      schema: tblRunningLogSchema,
      autoMigrate: false,
      migrationStrategies: migrateFunc(tblRunningLogSchema.version),
    },
    tblschedchk:{
      schema: tblSchedChkSchema,
      autoMigrate: false,
      migrationStrategies: migrateFunc(tblSchedChkSchema.version),
    },
    tblschedchkdesc:{
      schema: tblSchedChkDescSchema,
      autoMigrate: false,
      migrationStrategies: migrateFunc(tblSchedChkDescSchema.version),
    },
    comments: {
      schema: commentsSchema,
      autoMigrate: false,
      migrationStrategies: migrateFunc(commentsSchema.version),
    },
    orders: {
      schema: ordersSchema,
      methods: ordersDocMethods,
      statics: ordersCollectionMethods,
      autoMigrate: false,
      migrationStrategies: migrateFunc(ordersSchema.version),
    },
    orderitems: {
      schema: orderItemsSchema,
      autoMigrate: false,
      migrationStrategies: migrateFunc(orderItemsSchema.version),
    },
    invbarcodes: {
      schema: invBarCodesSchema,
      autoMigrate: false,
      migrationStrategies: migrateFunc(invBarCodesSchema.version),
    },

    documentrevision: {
      schema: documentRevisionSchema,
      methods: {},
      statics: {},
      autoMigrate: false,
      migrationStrategies: migrateFunc(documentRevisionSchema.version),
    },
    crew: {
      schema: crewSchema,
      methods: {},
      statics: {},
      autoMigrate: false,
      migrationStrategies: migrateFunc(crewSchema.version),
    },
    inveq: {
      schema: invEqSchema,
      methods: {},
      statics: {},
      autoMigrate: false,
      migrationStrategies: migrateFunc(invEqSchema.version),
    },
    inventory: {
      schema: inventorySchema,
      methods: inventoryDocMethods,
      statics: inventoryCollectionMethods,
      autoMigrate: false,
      migrationStrategies: migrateFunc(inventorySchema.version),
    },
    workissues: {
      schema: workIssuesSchema,
      methods: workIssuesDocMethods,
      statics: workIssuesCollectionMethods,
      autoMigrate: false,
      migrationStrategies: migrateFunc(workIssuesSchema.version),
    },
    tbllocationshierarchy: {
      schema: tblLocationsHierarchySchema,
      methods: tblLocationsHierarchyDocMethods,
      statics: tblLocationsHierarchyCollectionMethods,
      autoMigrate: false,
      migrationStrategies: migrateFunc(tblLocationsHierarchySchema.version),
    },
    tblsystemshierarchy: {
      schema: tblSystemsHierarchySchema,
      methods: tblSystemsHierarchyDocMethods,
      statics: tblSystemsHierarchyCollectionMethods,
      autoMigrate: false,
      migrationStrategies: migrateFunc(tblSystemsHierarchySchema.version),
    },
    equipment: {
      schema: equipmentSchema,
      methods: equipmentDocMethods,
      statics: equipmentCollectionMethods,
      autoMigrate: false,
      migrationStrategies: migrateFunc(equipmentSchema.version),
    },
    certificates: {
      schema: certificatesSchema,
      methods: certificatesDocMethods,
      statics: certificatesCollectionMethods,
      autoMigrate: false,
      migrationStrategies: migrateFunc(certificatesSchema.version),
    },
    logentry: {
      schema: logentrySchema,
      methods: logentryDocMethods,
      statics: logentryCollectionMethods,
      autoMigrate: false,
      migrationStrategies: migrateFunc(logentrySchema.version),
    },
    tblddlistdefaults: {
      schema: tblDDListDefaultsSchema,
      methods: tblDDListDefaultsDocMethods,
      statics: tblDDListDefaultsCollectionMethods,
      autoMigrate: false,
      migrationStrategies: migrateFunc(tblDDListDefaultsSchema.version),
    },
    tblshipsparticulars: {
      schema: tblShipsParticularsSchema,
      methods: tblShipsParticularsDocMethods,
      statics: tblShipsParticularsCollectionMethods,
      autoMigrate: false,
      migrationStrategies: migrateFunc(tblShipsParticularsSchema.version),
    },
    tblrecyclebin: {
      schema: tblRecycleBinSchema,
      methods: tblRecycleBinDocMethods,
      statics: tblRecycleBinCollectionMethods,
      autoMigrate: false,
      migrationStrategies: migrateFunc(tblRecycleBinSchema.version),
    },
    tblrldata: {
      schema: tblRlDataSchema,
      methods: {},
      statics: {},
      autoMigrate: false,
      migrationStrategies: migrateFunc(tblRlDataSchema.version),
    },
    tblrlenums: {
      schema: tblRlEnumsSchema,
      methods: {},
      statics: {},
      autoMigrate: false,
      migrationStrategies: migrateFunc(tblRlEnumsSchema.version),
    },
    tblrlmetagrpsneq: {
      schema: tblRlMetaGrpsNeqSchema,
      methods: {},
      statics: {},
      autoMigrate: false,
      migrationStrategies: migrateFunc(tblRlMetaGrpsNeqSchema.version),
    },
    // vddallcontacts: {
    //   schema: vDdAllContactsDistinctSchema,
    //   methods: vDdAllContactsDistinctDocMethods,
    //   statics: vDdAllContactsDistinctCollectionMethods,
    //   autoMigrate: false,
    //   migrationStrategies: migrateFunc(vDdAllContactsDistinctSchema.version),
    // },
    currencies: {
      schema: currenciesSchema,
      methods: currenciesDocMethods,
      statics: currenciesCollectionMethods,
      autoMigrate: false,
      migrationStrategies: migrateFunc(currenciesSchema.version),
    },
    tblsparesused: {
      schema: tblSparesUsedSchema,
      methods: tblSparesUsedDocMethods,
      statics: tblSparesUsedCollectionMethods,
      autoMigrate: false,
      migrationStrategies: migrateFunc(tblSparesUsedSchema.version),
    },
    // states: {
    //   schema: StatesSchema,
    //   methods: statesDocMethods,
    //   statics: statesCollectionMethods,
    //   autoMigrate: false,
    //   migrationStrategies: migrateFunc(StatesSchema.version),
    // },
    tbldl4cellrefs: {
      schema: tblDL4CellRefsSchema,
      methods: tblDl4CellRefsDocMethods,
      statics: tblDl4CellRefsCollectionMethods,
      autoMigrate: false,
      migrationStrategies: migrateFunc(tblDL4CellRefsSchema.version),
    },
    tblmonsystags: {
      schema: tblMonSysTagsSchema,
      methods: tblMonSysTagsDocMethods,
      statics: tblMonSysTagsCollectionMethods,
      autoMigrate: false,
      migrationStrategies: migrateFunc(tblMonSysTagsSchema.version),
    },
    tbldocumentation: {
      schema: tblDocumentationSchema,
      methods: tblDocumentationDocMethods,
      statics: tblDocumentationCollectionMethods,
      autoMigrate: false,
      migrationStrategies: migrateFunc(tblDocumentationSchema.version),
    },
    tbldocumentcrossreference: {
      schema: tblDocumentCrossReferenceSchema,
      methods: {},
      statics: {},
      autoMigrate: false,
      migrationStrategies: migrateFunc(tblDocumentCrossReferenceSchema.version),
    },
    tblgridsavedviews: {
      schema: tblGridSavedViewsSchema,
      methods: tblGridSavedViewsDocMethods,
      statics: tblGridSavedViewsCollectionMethods,
      autoMigrate: false,
      migrationStrategies: migrateFunc(tblGridSavedViewsSchema.version),
    },
    // tblcontactmanager: {
    //   schema: tblContactManagerSchema,
    //   methods: TblContactManagerDocMethods,
    //   statics: TblContactManagerCollectionMethods,
    //   autoMigrate: false,
    //   migrationStrategies: migrateFunc(tblContactManagerSchema.version),
    // }
  });

  return db;
};

export class MigrationHandler {
  db: TDIDb;

  constructor(db: TDIDb) {
    this.db = db;
  }

  async start(onProgress: (response: any) => void) {
    const collections = Object.values(this.db.collections);

    await Promise.all(
      collections.map(async (collection: any) => {
        const { name } = collection;
        const needed = await collection.migrationNeeded();
        if (!needed) {
          onProgress({
            name,
            loading: false,
            completed: true,
          });
          return;
        }

        const migrationState$ = collection.migrate(100); // 10 is the batch-size, how many docs will run at parrallel

        migrationState$.subscribe(
          (state: any) => {
            return onProgress({
              name,
              loading: true,
              completed: false,
            });
          },
          (error: any) =>
            onProgress({
              name,
              loading: false,
              completed: true,
            }),
          (done: any) =>
            onProgress({
              name,
              loading: false,
              completed: true,
            })
        );
      })
    );
  }
}

type ReplicationControlWithOrder = {
  start: () => RxGraphQLReplicationState;
  order: number;
};

export class GraphQLReplicator {
  replications: RxGraphQLReplicationState[] = [];

  db: TDIDb;

  subscriptionClient: any;

  constructor(db: TDIDb) {
    this.db = db;
    this.subscriptionClient = null;
  }

  async start(
    token: string,
    user: CrewProfiles | null,
    onProgress: (response: any) => void
  ) {
    logger('RxDB').debug('Replicator restarted...');
    await this.stop();

    if (this.subscriptionClient) {
      this.subscriptionClient.close();
    }
    // Uncomment below line to disable syncing while in development
    // const replications: any[];
    // And Comment this line out
    const replications = await this.setupGraphQLReplication(token, user);

    // Just to show in UI in proper order.
    // const replicationsSorted = this.replications.sort((a, b) => a.collection.name.localeCompare(b.collection.name));
    const replicationsSorted = replications.sort((a, b) => a.order - b.order);

    const chunks = chunk(replicationsSorted, 5);

    const trackReplication = async (replication: RxGraphQLReplicationState) => {
      const { name } = replication.collection;

      onProgress({
        name,
        loading: true,
        completed: false,
      });

      replication.error$.subscribe((error) => {
        console.error('Replication error', error);
        onProgress({
          name,
          error: JSON.stringify(error),
          loading: false,
          completed: false,
          failed: true,
        });
      });

      await replication.awaitInitialReplication();

      onProgress({
        name,
        loading: false,
        completed: true,
      });
    };

    for (const replicationControls of chunks) {
      // const sorted = replications.sort((a, b) => a.collection.name.localeCompare(b.collection.name));

      // TODO: Replace `.map` with `for ( of ) ` in order to make it run NOT IN parallel
      await Promise.all(
        replicationControls.map(async (control) => {
          const replication = await control.start();
          this.replications.push(replication);
          await trackReplication(replication);
        })
      );
    }
  }

  // Manually trigger sync for all replications when updating data with the new .net api 
  manualSync() {
    console.log('hit manual sync');
    if (this.replications.length === 0) {
      console.error('No replications available to sync.');
      return;
    }
    this.replications.forEach((replication) => {
      replication.run();
    });
  }

  async stop() {
    logger('RxDB').debug('Cancelling all replications...');
    if (isEmpty(this.replications)) return true;

    await Promise.all(this.replications.map((repl) => repl.cancel()));

    return true;
  }

  setupGraphQLReplication(
    token: string,
    user: CrewProfiles | null
  ): ReplicationControlWithOrder[] {
    logger('RxDB').debug('Replicator setup...');
    const settings = {
      waitForLeadership: true,
      url: syncURL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      live: true,
      /**
       * Because the websocket is used to inform the client
       * when something has changed,
       * we can set the liveIntervall to a high value
       */
      liveInterval: 1000 * 60 * 10, // 10 minutes
      deletedFlag: 'deleted',
    };

    const wsConfig = {
      reconnect: true,
      connectionParams: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      timeout: 1000 * 60,
      reconnectionAttempts: 10000,
      inactivityTimeout: 10 * 1000,
      lazy: false,
    };

    const wsClient = createClient({
      url: NODE_WS_URL,
      retryAttempts: 10000,
      shouldRetry: () => true,
      lazy: false,
      connectionParams: {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    });

    const getDataSyncReplicator = (
      db: any,
      pullQuery: any,
      pushQuery: any,
      subscriptionQuery: any,
      section: string,
      modifier: any,
      order: number,
    ) => {
      const start = (): RxGraphQLReplicationState => {
        const startTime = moment();
        const replicationState = db.syncGraphQL({
          ...settings,
          pull: {
            modifier,
            queryBuilder: pullQuery,
          },
          push: {
            queryBuilder: pushQuery,
          },
        });

        replicationState.initialReplicationComplete$.subscribe({
          next(data: { errors: any; }) {
            if (!data.errors) {
              if (data) {
                const endTime = moment();
                logger('RxDB').debug(
                  `Initial replication complete for ${section} in ${endTime.diff(startTime, 'seconds')} seconds - start time : ${startTime} , endTime: ${endTime}. Subscribing to events now...`,
                  data
                );
                Sentry.setMeasurement(`repl-${section}`, endTime.diff(startTime, 'seconds'), 'second');

                const next = (data: any) => {
                  if (!data.errors) {
                    replicationState.run();
                    logger('RxDB').debug(
                      `${section} subscription emitted => trigger run`,
                      data
                    );
                  } else {
                    logger('RxDB').error(`Error in ${section} subscription`, data);
                  }
                };

                wsClient.subscribe({ query: subscriptionQuery }, {
                  next,
                  error(error) {
                    logger('RxDB').error(`${section} sub got error:`);
                    logger('RxDB').trace(error);
                  },
                  complete(): void {}
                })
              }
            } else {
              logger('RxDB').error(`Error in ${section} subscription`, data);
            }
          },
          error(error: any) {
            logger('RxDB').error(`${section} sub got error:`);
            logger('RxDB').trace(error);
          },
        });


        return replicationState;
      };

      return {
        start,
        order,
      };
    };

    const modifierInvEq = (doc: InvEq & { id: string }) => {
      doc.id = `${doc.EqKey}--${doc.ProductID}`;
      return doc;
    };

    const modifierInvBarCodes = (doc: InvBarCodes & { id: string }) => {
      doc.id = `${doc.ProductID}--${doc.fldTritonBC}`;
      // doc.accessDate = +new Date(head(doc.updatedAt)?.fldAccessDate || 0);
      return doc;
    };

    const modifierSettingsPersonalDocument = (doc: SettingsPersonalDocument) =>
      doc;
    const modifierHrsOfRest = (doc: HrsOfRestDocument) => {
      doc.fldDate = +new Date(parseISO(doc.fldDate));
      return doc;
    };
    const equipmentModifier = (doc: Equipment) => {
      return mapValues(doc, (d, key) => {
        if (equipmentIndexes.indexOf(key) !== -1) return isNil(d) ? '' : d;

        return d;
      });
    };
    // prettier-ignore
    const replications: any = [
      getDataSyncReplicator(this.db.crew, crewPullQuery, crewProfilesMutation, crewProfilesSubscription, 'crewProfiles', null, 0),
      getDataSyncReplicator(this.db.tblsettingspersonal, settingsPersonalPullQuery(user), null, settingsPersonalSubscription, 'settingsPersonal', modifierSettingsPersonalDocument, 1),
      getDataSyncReplicator(this.db.tblsettingsapplication, settingsApplicationPullQuery, tblSettingsApplicationMutation, settingsApplicationSubscription, 'settingsApplication', null, 2),
      getDataSyncReplicator(this.db.tbldocumentation, tblDocumentationQuery, tblDocumentationMutation, tblDocumentationSubscription, 'tblDocumentation', null, 3),
      getDataSyncReplicator(this.db.tblsystemshierarchy, tblSystemsHierarchyQuery, tblSystemsHierarchyMutation, tblSystemsHierarchySubscription, 'tblSystemsHierarchy', null, 4),
      getDataSyncReplicator(this.db.tblshipsparticulars, tblShipsParticularsQuery, tblShipsParticularsMutation, tblShipsParticularsSubscription, 'tblShipsParticulars', null, 5),
      getDataSyncReplicator(this.db.tblddlistdefaults, tblDDListDefaultsQuery, tblDDListDefaultMutation, tblDDListDefaultsSubscription, 'tblDDListDefault', null, 6),
      getDataSyncReplicator(this.db.tbldocumentcrossreference, tblDocumentCrossReferenceQuery, tblDocumentCrossReferenceMutation, tblDocumentCrossReferenceSubscription, 'tblDocumentCrossReference', null, 7),
      getDataSyncReplicator(this.db.workissues, workIssuesQuery, workIssuesMutation, workissuesSubscription, 'workissues', null, 8),
      getDataSyncReplicator(this.db.currencies, CurrenciesQuery, null, CurrenciesSubscription, 'Currencies', null, 9),
      getDataSyncReplicator(this.db.inventory, inventoryQuery, inventoryMutation, inventorySubscription, 'inventory', null, 10),
      getDataSyncReplicator(this.db.equipment, equipmentQuery, equipmentMutation, equipmentSubscription, 'equipment', equipmentModifier, 11),
      getDataSyncReplicator(this.db.certificates, CertificatesQuery, CertificatesMutation, CertificatesSubscription, 'Certificates', null, 12),
      getDataSyncReplicator(this.db.comments, commentsPullQuery, commentsMutation, commentsSubscription, 'comments', null, 13),
      // getDataSyncReplicator(this.db.states, statesQuery, null, statesSubscription, 'states', null, 14),
      getDataSyncReplicator(this.db.tblrlenums, tblRlEnumsPullQuery, null, tblRlEnumsSubscription, 'Enums', null, 15),
      getDataSyncReplicator(this.db.tblrlmetagrpsneq, tblRlMetaGrpsNeqPullQuery, tblRlMetaGrpsNeqMutation, tblRlMetaGrpsNeqSubscription, 'Enum Groups', null, 16),
      getDataSyncReplicator(this.db.tbllocationshierarchy, tblLocationsHierarchyQuery(), tblLocationsHierarchyMutation, tblLocationsHierarchySubscription, 'tblLocationsHierarchy', null, 17),
      getDataSyncReplicator(this.db.tblmultiplelocations, multipleLocationsPullQuery, multipleLocationsMutation, multipleLocationsSubscription, 'multipleLocations', null, 18),
      getDataSyncReplicator(this.db.orders, ordersPullQuery, ordersMutation, ordersSubscription, 'orders', null, 19),
      getDataSyncReplicator(this.db.orderitems, orderItemsPullQuery, orderItemsMutation, orderItemsSubscription, 'orderItems', null, 20),
      getDataSyncReplicator(this.db.invbarcodes, invBarCodesPullQuery, invBarCodesMutation, inventorySubscription, 'invBarCodes', modifierInvBarCodes, 21),
      getDataSyncReplicator(this.db.inveq, invEqPullQuery, invEqMutation, invEqSubscription, 'inveq', modifierInvEq, 22),
      getDataSyncReplicator(this.db.hoursofrest, hrsOfRestPullQuery(user), hrsOfRestPullMutation, hrsOfRestSubscription, 'HrsOfRest', modifierHrsOfRest, 23),
      getDataSyncReplicator(this.db.tblanyreportshistory, anyReportsHistoryPullQuery, anyReportHistoryMutation, anyReportHistorySubscription, 'anyReportHistory', null, 24),
      getDataSyncReplicator(this.db.tblanyreports, anyReportsPullQuery, anyReportsMutation, anyReportsSubscription, 'tblAnyReports', null, 25),
      getDataSyncReplicator(this.db.tblsparesused, tblSparesUsedQuery, tblSparesUsedMutation, tblSparesUsedSubscription, 'tblSparesUsed', null, 26),
      getDataSyncReplicator(this.db.tblschedmaint, schedMaintQuery, schedMaintMutation, schedMaintSubscription, 'schedMaint', null, 27),
      getDataSyncReplicator(this.db.tblschedmainteq, schedMaintEqQuery, schedMaintEqMutation, schedMaintEqSubscription, 'schedMaintEq', null, 28),
      getDataSyncReplicator(this.db.tblschedmaintparts, tblSchedMaintPartsQuery, tblSchedMaintPartsMutation, tblSchedMaintPartsSubscription, 'tblSchedMaintParts', null, 29),
      getDataSyncReplicator(this.db.documentrevision, documentRevisionPullQuery, tblDocumentRevisionsMutation, documentRevisionsSubscription, 'documentRevisions', null, 30),
      getDataSyncReplicator(this.db.tblrecyclebin, tblRecycleBinQuery, null, tblRecycleBinSubscription, 'tblRecycleBin', null, 31),
      getDataSyncReplicator(this.db.tbldl4cellrefs, tblDL4CellRefsPullQuery, null, tblDL4CellRefsSubscription, 'tblDL4CellRefs', null, 32),
      getDataSyncReplicator(this.db.engineerdaylog, dayLogsPullQuery, tblLogCustomMutation, dayLogsSubscription, 'engineerdaylog', null, 33),
      getDataSyncReplicator(this.db.tblrunninglog, tblRunningLogPullQuery, tblRunningLogMutation, tblRunningLogSubscription, 'tblRunningLog', null, 34),
      getDataSyncReplicator(this.db.tblrldata, tblRlDataPullQuery, tblRlDataMutation, tblRlDataSubscription, 'Data', null, 35),
      getDataSyncReplicator(this.db.tblgridsavedviews, tblGridSavedViewsQuery, tblGridSavedViewsMutation, tblGridSavedViewsSubscription, 'tblGridSavedViews', null, 36),
      // getDataSyncReplicator(this.db.tblcontactmanager, tblContactManagersQuery, null, tblContactManagerSubscription, 'tblContactManager', null, 37),
      getDataSyncReplicator(this.db.tblschedchk, tblSchedChkPullQuery, tblSchedChkMutation, tblSchedChkSubscription, 'tblSchedChk', null, 38),
      getDataSyncReplicator(this.db.tblschedchkdesc, tblSchedChkDescPullQuery, tblSchedChkDescMutation, tblSchedChkDescSubscription, 'tblSchedChkDesc', null, 39),
      getDataSyncReplicator(this.db.tblmonsystags, tblMonSysTagsPullQuery, null, tblMonSysTagsSubscription, 'tblMonSysTags', null, 40),
      getDataSyncReplicator(this.db.logentry, LogEntryQuery, LogEntryMutation, logEntrySubscription, 'logEntry', null, 41),
    ];

    return replications;
  }
}

export const getDatabase = (): Promise<TDIDb> => {
  if (!dbPromise) dbPromise = createDb();
  return dbPromise;
};

