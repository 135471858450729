import { ApolloError, gql, useMutation } from '@apollo/client';
import { LogEntry, QueryLogEntriesArgs } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { GET_LOG_ENTRIES_WITH_COUNTS } from './useGetLogEntriesWithCounts';
import { GET_LOG_ENTRIES_WITH_NO_COUNTS } from './useGetLogEntriesWithNoCounts';
import { LogEntryMutationResponse } from './useUpsertLogEntry';

export const DELETE_LOGENTRY = gql`
  mutation DeleteLogEntry($logEntryId: String!) {
    deleteLogEntry(logEntryId: $logEntryId) {
      logEntry {
        pkey
        deletedAt
        deletedBy
      }
    }
  }
`;

interface DeleteLogEntryResult {
  removeLogEntry: (logEntryId: string, variables: QueryLogEntriesArgs, callerComponent: string) => Promise<LogEntryMutationResponse>;
  removeLogEntryLoading: boolean;
}

export const useDeleteLogEntry = (): DeleteLogEntryResult => {
  const [deleteLogEntry, { loading }] = useMutation(DELETE_LOGENTRY, {
    onError: (error: ApolloError) => {
      logger('DeleteLogEntry').error('Error deleting log entry -->', error.message);
    }
  });

  const removeLogEntry = async (logEntryId: string, queryVariables: QueryLogEntriesArgs, callerComponent: string): Promise<LogEntryMutationResponse> => {
    const refetchQueries = [];
    if (callerComponent === 'HistoryTab') {
      refetchQueries.push({
        query: GET_LOG_ENTRIES_WITH_NO_COUNTS,
        variables: queryVariables,
      });
    } else {
      refetchQueries.push(
        {
          query: GET_LOG_ENTRIES_WITH_COUNTS,
          variables: queryVariables,
        }
      );
    }

    const response = await deleteLogEntry({ 
      variables: { logEntryId },
      refetchQueries: refetchQueries,
    });
    const responseData = response.data?.deleteLogEntry?.logEntry;
    if (responseData) {
      logger('DeleteLogEntry').info('Log entry deleted successfully', response.data);
      return {
        responseData,
        responseMessage: `Log entry removed successfully!`
      };
    } else {
      return {
        responseMessage: `Failed to remove log entry!`,
      };
    }
  };

  return { 
    removeLogEntry, 
    removeLogEntryLoading: loading, 
  };
};
