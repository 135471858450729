import React, { FC } from 'react';

interface InjectedProps {
  color?: string;
  width?: number;
  height?: number;
  className?: string;
}

const Logo: FC<InjectedProps> = ({
  color = 'currentColor',
  width = '40%',
  height = '40%',
  className = '',
}) => (
  <div className={className}>
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="-15 -15 154 170"
      style={{maxWidth: '150px'}}
    >
      <g transform="translate(-761.891 -259.268)">
        <g transform="translate(761.891 259.268)">
          <g transform="translate(16.7 41.557)">
            <path
              className="a"
              d="M273.419,180.327c-.906.064-1.82.165-2.727.243-.154.013-.31.037-.466.054-.085.094-.163.188-.253.28l-1.344,1.366a52.907,52.907,0,0,1-27.073,17.894v.216c.156-.036.309-.07.468-.11l3.981-.925c1.418-.325,2.874-.8,4.41-1.224.762-.228,1.551-.424,2.327-.69l2.356-.814,2.4-.837,2.381-.972c.792-.333,1.6-.635,2.382-1l2.325-1.106c1.562-.7,3.013-1.577,4.465-2.354.726-.389,1.4-.856,2.078-1.27.646-.417,1.3-.795,1.895-1.234.041-.107.084-.213.118-.322.107-.356.218-.709.333-1.059l.341-1.048.261-1.067c.084-.355.18-.706.257-1.061l.186-1.069c.146-.653.208-1.311.293-1.964C274.348,180.285,273.883,180.31,273.419,180.327Z"
              transform="translate(-189.489 -156.594)"
            />
            <path
              className="a"
              d="M241.216,168.219l-.138-3.572-3.778-.358-3.183-.3c-1.049-.045-2.1-.093-3.153-.114-1.045,0-2.079.036-3.121.054s-2.06.113-3.093.167-2.044.192-3.062.3-2.034.228-3.037.382c-2.024.253-4,.658-6,1.02-1.972.413-3.953.805-5.892,1.314-1.955.468-3.881,1-5.812,1.527-1.913.575-3.826,1.145-5.719,1.762-1.906.584-3.767,1.284-5.645,1.935q-2.8,1-5.566,2.111c-1.85.714-3.677,1.481-5.494,2.268l-2.722,1.183-2.7,1.242q-2.692,1.244-5.34,2.581c-1.774.869-3.518,1.807-5.262,2.734-1.736.949-3.468,1.9-5.172,2.907q-2.566,1.488-5.073,3.089c-.837.53-1.666,1.073-2.486,1.636s-1.644,1.111-2.444,1.707l2.885-.738q1.443-.363,2.869-.754l5.692-1.567,5.656-1.6,5.626-1.629,11.2-3.259,11.149-3.183,5.567-1.519c1.85-.518,3.707-.985,5.562-1.459s3.7-.958,5.557-1.372l5.556-1.26c1.85-.376,3.707-.735,5.551-1.07.924-.184,1.847-.335,2.768-.469s1.847-.3,2.764-.4c.76-.093,1.525-.208,2.284-.292.156-.017.313-.041.468-.054.911-.079,1.829-.179,2.739-.244.466-.016.933-.042,1.4-.071-.085.653-.147,1.311-.294,1.964l-.186,1.069c-.077.354-.173.706-.258,1.061l-.262,1.067-.343,1.048c-.116.35-.227.7-.335,1.059-.035.109-.078.216-.119.322-.092.24-.191.477-.281.718l-.414,1.043-.209.524-.251.5c-.339.671-.654,1.362-.985,2.05l-1.159,1.981a19.765,19.765,0,0,1-1.254,1.952c-.451.632-.86,1.308-1.348,1.924L223.442,193c-.254.306-.481.642-.752.934l-.815.88-1.635,1.8c-.56.59-1.159,1.139-1.745,1.717s-1.185,1.146-1.772,1.746c.765-.315,1.524-.654,2.285-1s1.552-.66,2.28-1.074,1.458-.835,2.182-1.273l1.093-.655c.365-.22.7-.483,1.043-.729l2.062-1.525c.7-.509,1.294-1.125,1.947-1.693a22.014,22.014,0,0,0,1.874-1.806l1.763-1.943c.533-.695,1.079-1.388,1.6-2.1l.4-.534.357-.56.709-1.129c.231-.38.486-.75.7-1.143l.62-1.192.609-1.2.526-1.246c.173-.417.356-.832.519-1.255l.443-1.292c.306-.858.512-1.755.746-2.646.119-.445.191-.908.286-1.363s.175-.917.235-1.391.135-.941.165-1.438c.018-.248.041-.481.046-.756S241.232,168.636,241.216,168.219Z"
              transform="translate(-147.332 -149.196)"
            />
            <path
              className="a"
              d="M173.317,164.369c.8-.664,1.632-1.288,2.434-1.936.827-.624,1.634-1.262,2.464-1.873l2.484-1.824c.843-.588,1.666-1.2,2.514-1.771s1.684-1.163,2.539-1.725l2.564-1.68c1.72-1.1,3.453-2.183,5.208-3.229.871-.533,1.755-1.045,2.641-1.554s1.769-1.023,2.661-1.52l2.688-1.475,2.71-1.432c.9-.48,1.821-.925,2.729-1.387.587-.3,1.188-.586,1.78-.865.7.456,1.407.916,2.123,1.358,1.113.69,2.236,1.368,3.377,2.024s2.292,1.3,3.463,1.912,2.358,1.21,3.59,1.746c-.832-1.055-1.72-2.039-2.6-3.026s-1.8-1.929-2.733-2.86-1.867-1.848-2.818-2.749-1.918-1.782-2.89-2.656l-.818-.737-1.3.417c-1.09.351-2.063.717-3.076,1.1s-1.986.8-2.967,1.229-1.947.881-2.91,1.344-1.916.944-2.855,1.452-1.877,1.019-2.8,1.555-1.835,1.094-2.738,1.664-1.789,1.169-2.675,1.765l-1.317.914-1.289.955c-.856.641-1.705,1.29-2.528,1.974s-1.65,1.36-2.442,2.082-1.583,1.439-2.345,2.19-1.517,1.506-2.233,2.3c-.738.771-1.422,1.6-2.116,2.406-.669.836-1.347,1.656-1.966,2.528-.31.435-.628.861-.929,1.3l-.871,1.342c-.3.441-.576.893-.831,1.363s-.529.922-.777,1.394c.835-.669,1.617-1.37,2.432-2.036C171.691,165.676,172.509,165.023,173.317,164.369Z"
              transform="translate(-156.873 -137.11)"
            />
          </g>
          <path
            className="a"
            d="M223.809,129.415a34.306,34.306,0,0,1,11.358-.266,49.912,49.912,0,0,0-94.035,32.058,35.711,35.711,0,0,1,9.163-6.332,39.778,39.778,0,0,1,73.514-25.46Z"
            transform="translate(-127.234 -78.676)"
          />
          <path
            className="a"
            d="M228.349,294.384q-1.449.073-2.913.073t-2.913-.073a21.981,21.981,0,0,1-1.227,4.842,11.7,11.7,0,0,0-.891,3.531,5.1,5.1,0,0,0,10.061,0,11.708,11.708,0,0,0-.891-3.521,21.994,21.994,0,0,1-1.227-4.852Z"
            transform="translate(-163.435 -167.04)"
          />
          <path
            className="a"
            d="M122.376,238.447a11.852,11.852,0,0,0-3.5.993,4.7,4.7,0,0,0-1.17,6.5,5.389,5.389,0,0,0,2.976,2.49,3.961,3.961,0,0,0,3.23-.251,11.751,11.751,0,0,0,2.641-2.556,22.04,22.04,0,0,1,3.592-3.5q-1.587-2.447-2.922-5.043A22.091,22.091,0,0,1,122.376,238.447Z"
            transform="translate(-116.881 -140.942)"
          />
          <path
            className="a"
            d="M118.885,129.089a11.9,11.9,0,0,0,3.5.989,22.052,22.052,0,0,1,4.831,1.37q1.339-2.593,2.933-5.043a22.046,22.046,0,0,1-3.582-3.486,11.705,11.705,0,0,0-2.638-2.553,3.955,3.955,0,0,0-3.232-.267,5.386,5.386,0,0,0-2.981,2.485A4.711,4.711,0,0,0,118.885,129.089Z"
            transform="translate(-116.886 -87.583)"
          />
          <path
            className="a"
            d="M329.358,239.435a11.892,11.892,0,0,0-3.5-.988,22.085,22.085,0,0,1-4.831-1.371,58.255,58.255,0,0,1-2.933,5.043,22.14,22.14,0,0,1,3.582,3.486,11.7,11.7,0,0,0,2.638,2.553,3.943,3.943,0,0,0,3.232.267,5.385,5.385,0,0,0,2.981-2.485,4.711,4.711,0,0,0-1.173-6.5Z"
            transform="translate(-207.355 -140.942)"
          />
          <path
            className="a"
            d="M222.524,73.251q1.449-.072,2.913-.073t2.913.073a22,22,0,0,1,1.227-4.852,11.709,11.709,0,0,0,.891-3.521,5.1,5.1,0,0,0-10.061,0A11.693,11.693,0,0,0,221.3,68.4a22,22,0,0,1,1.227,4.852Z"
            transform="translate(-163.435 -60.596)"
          />
          <path
            className="a"
            d="M327.56,120.1a3.962,3.962,0,0,0-3.23.264,11.726,11.726,0,0,0-2.641,2.556,22.152,22.152,0,0,1-3.592,3.486q1.586,2.447,2.922,5.043a22.051,22.051,0,0,1,4.832-1.37,11.877,11.877,0,0,0,3.5-.994,4.7,4.7,0,0,0,1.18-6.5,5.39,5.39,0,0,0-2.976-2.485Z"
            transform="translate(-207.355 -87.583)"
          />
          <g transform="translate(22.36 95.621)">
            <g transform="translate(24.523 0)">
              <path
                className="a"
                d="M204.793,239.3v12.462h-2.765V236.538h22.259a5.643,5.643,0,0,1,5.61,5.61v9.617h-2.765v-9.617a2.839,2.839,0,0,0-2.845-2.845Zm12.362,2.765H214.39v9.7h2.765Z"
                transform="translate(-202.028 -236.538)"
              />
              <path
                className="a"
                d="M279.1,244.02a4.3,4.3,0,0,1,1.3,3.136,4.557,4.557,0,0,1-1.3,3.226,4.166,4.166,0,0,1-3.145,1.383H259.422V249h16.529a1.545,1.545,0,0,0,1.2-.571,1.882,1.882,0,0,0,.5-1.272,1.711,1.711,0,0,0-1.7-1.7H263.89a4.317,4.317,0,0,1-3.156-1.3,4.462,4.462,0,0,1,3.156-7.613h13.764V239.3H263.89a1.7,1.7,0,0,0,0,3.406h12.061A4.271,4.271,0,0,1,279.1,244.02Z"
                transform="translate(-227.947 -236.538)"
              />
            </g>
            <g transform="translate(0 0)">
              <path
                className="a"
                d="M177.2,269.2a4.855,4.855,0,0,1-1.294,1.769,4.63,4.63,0,0,1-3.145,1.172h-10.7v2.765h10.7A7.228,7.228,0,0,0,177.778,273,7.7,7.7,0,0,0,180.1,269.2Z"
                transform="translate(-159.457 -251.29)"
              />
              <path
                className="a"
                d="M172.69,251.765h5.538V236.538h-2.764V249H162.922a2.839,2.839,0,0,1-2.845-2.845v-9.616h-2.765v9.616a5.645,5.645,0,0,0,5.61,5.61Z"
                transform="translate(-157.312 -236.538)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
);

export default Logo;
