// Styles
import '../../../App.css';
import '../../../theme/styles.css';
import '../styles.css';
// React components
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { isNil, size, last, isArray, isEmpty, isString } from 'lodash';
import { useForm } from 'react-hook-form';
// Third party components
import { DeleteTwoTone, Undo } from '@mui/icons-material';
import { Alert, AlertColor, IconButton, Snackbar } from '@mui/material';
// TDI components
import Tabs from '../../UI/Tabs';
import StickyAppBar from '../../UI/StickyAppBar';
import WarningDialog from 'src/components/UI/WarningDialog';
import { useAppState } from 'src/contexts/app-state';
import LogEntrySummaryForm from './component/LogEntrySummaryForm';
import RecordEditWarningCard from 'src/components/UI/RecordEditWarningCard';
import { InjectedDrawerProps } from 'src/components/PageDrawer';
import LogEntryEquipmentHoursDialog from './component/LogEntryEquipmentHoursDialog';
import SparesTab from 'src/components/SparesTab';
import AttachmentTab from 'src/modules/Attachments';
import Comments from 'src/modules/Comments';
// GraphQL
import { useAuth } from 'src/contexts/auth';
import { TblSchedChk } from '../../../generated/graphql';
import { RecordType, Equipment, LogEntry, LogEntryInput, AttachmentType, QueryLogEntriesArgs } from 'src/generated/dotnet.graphql';
import { useUpsertLogEntry } from 'src/hooks/logEntries/useUpsertLogEntry';
import { useDeleteLogEntry } from 'src/hooks/logEntries/useDeleteLogEntry';
// utils
import { normalizeDateFormValue, normalizeDateTime } from 'src/helpers';
import { extractChecks, validateForm } from './utils';
import LogEntryChecklistTab from './component/LogEntryChecklistTab';
import { logger } from 'src/helpers/logger';
import { useGetLogEntryCounts } from 'src/hooks/logEntries/getLogEntryCounts';
import { utcToTimeZone } from 'src/utils/format-dates';

interface LogEntryDetailFormProps extends Partial<InjectedDrawerProps> {
  initialValue: LogEntry;
  isCreate?: boolean;
  onSave: (responseData: LogEntry, responseMessage: string, isCreated: boolean) => void;
  onDelete?: (responseData: LogEntry, responseMessage: string) => void;
  onCancel: () => void;
  refetchQueryVariables: QueryLogEntriesArgs;
  callerComponent: string;
  moduleReadOnly: boolean;
  onUndo?: () => void;
  type?: string;
  isReport?: boolean;
  isLogAndReset?: boolean;
}

interface LogEntryDetailFormRef {
  onSaveClick: () => void;
  onDeleteClick: () => void;
}

const LogEntryDetailForm = forwardRef<LogEntryDetailFormRef, LogEntryDetailFormProps>(({ 
  initialValue,
  onCancel,
  onSave,
  refetchQueryVariables: queryVariables,
  callerComponent,
  onDelete,
  onUndo,
  moduleReadOnly = false,
  isCreate = false,
  type,
  setFormIsDirty,
  isReport = false,
  isLogAndReset = false,
}: LogEntryDetailFormProps, ref) => {
  const { control, setValue, handleSubmit, getValues, reset, watch, formState } = useForm<any>({ 
    defaultValues: {
      fldHtml: initialValue.fldHtml || '',
      logDate: normalizeDateFormValue(utcToTimeZone(initialValue.logDate)),
      equipment: typeof initialValue?.equipment === 'object' && initialValue?.equipment !== null ? initialValue.equipment.uniqueName || null : initialValue?.equipment || null,
      fldSrhkey: initialValue.fldSrhkey,
      fldLocHierarchy: initialValue.fldLocHierarchy,
      fldWorkList: initialValue?.fldWorkList || null,
      department: initialValue?.department || null,
      fldEnteredBy: initialValue?.fldEnteredBy || null,
      fldPerformedBy: isEmpty(initialValue.fldPerformedBy) ? [] : initialValue.fldPerformedBy?.split(', '),
      fldCompany: initialValue?.fldCompany || null,
      fldTime: initialValue?.fldTime || '',
      fldTimeQty: initialValue?.fldTimeQty || '',
      fldCost: initialValue?.fldCost || null,
      curr: initialValue?.curr || null,
      fldIsWarranty: initialValue?.fldIsWarranty || false,
      fldHours: initialValue?.fldHours || null,
      eqKey: initialValue.eqKey || null,
      fldCrewId: initialValue?.fldCrewId || null,
      fldRestricted: initialValue?.fldRestricted || false,
      fldSms: initialValue.fldSms || false,
    },
  });

  const { user } = useAuth();
  const { settingsPersonal, syncRxdb } = useAppState();
  const formInitialValues = useRef<any>({});
  const [isDeleting, setIsDeleting] = useState(false);
  const [recordReadOnly, setRecordReadOnly] = useState(false);
  const [eqWarningDialog, setEqWarningDialog] = useState(false);
  const [saveData, setSaveData] = useState<any>();
  const [eqHours, setEqHours] = useState<number | null>(null);
  const [eqHoursCompletedAt, setEqHoursCompletedAt] = useState<number | null>(null);
  const [currentEquipment, setCurrentEquipment] = useState<any>(initialValue.equipment);
  const [sparesCount, setSparesCount] = useState<number>(initialValue.sparesUsedCount || 0);
  const [documentsCount, setDocumentsCount] = useState<number>();
  const [photosCount, setPhotosCount] = useState<number>(initialValue.photosCount || 0);
  const [commentsCount, setCommentsCount] = useState<number>(initialValue.commentsCount || 0);
  const [snackbar, setSnackbar] = useState<{ message: string; severity: AlertColor }>();
  const { upsertLogEntry } = useUpsertLogEntry();
  const { removeLogEntry } = useDeleteLogEntry();
  const { getLogEntryCounts } = useGetLogEntryCounts();
  const checks: TblSchedChk[] = extractChecks(initialValue.checks); // RXDBRemoval - change Node checks type with dotnet checks type

  useImperativeHandle(ref, () => ({
    onSaveClick: () => {
      handleSubmit(onSaveClick)();
    },
    onDeleteClick: () => {
      handleDelete();
    }
  }));
  
  const setInitialValues = async () => {
    const defaultValues = {
      ...getValues(),
    };

    formInitialValues.current = defaultValues;
    reset(defaultValues);
  };

  const setLogEntryCounts = async () => {
    // get logentry counts
    const queryResult = await getLogEntryCounts({ variables: { pKey: initialValue.pkey } });
    const logEntryCounts = queryResult.data?.logEntry;
    if (logEntryCounts) {
      setDocumentsCount(logEntryCounts.documentsCount || 0);
    } else {
      logger('GetLogEntry').error(`Log entry not found with id: ${initialValue.pkey}`);
    }
  }

  const setRecordReadOnlyPermission = async ()=>{
    if(settingsPersonal.fldAllDepts != 2 && user?.Department != initialValue.department){
      setRecordReadOnly(true)
    }
    if(!settingsPersonal.fldDeptHead && initialValue.fldRestricted) {
      setRecordReadOnly(true)
    }
  }

  useEffect(()=>{
    if(settingsPersonal){
      setRecordReadOnlyPermission()
    }
  },[settingsPersonal])

  useEffect(() => {
    setInitialValues();
    return () => {
      formInitialValues.current = {};
    };
  }, []);

  useEffect(() => {
    // Don't get the counts on a new creation LogEntry as the primary key does not exist yet
    if (!isCreate) {
      setLogEntryCounts();
    }
  }, [isCreate]);

  const onChange = async (name: string, value: any) => {
    try {
      // If it's equipment change -> populate location based on it.
      if (name === 'equipment') {
        const equipment = value as Equipment;
        setCurrentEquipment(equipment) // grab the equipment object for changing hours functionality
        setValue('fldLocHierarchy', equipment?.fldLocHierarchy);
        setValue('fldSrhkey', equipment?.fldSrhkey);
        setValue(name, equipment, { shouldDirty: true })
        return;
      }
      let shouldDirty = true;

      if (name === 'fldSrhkey') {
        const updatedValue = (isArray(value) ? last(value) : value) || null;
        if (initialValue.fldSrhkey === updatedValue) {
          shouldDirty = false;
        }
      }

      if (name === 'fldLocHierarchy') {
        const updatedValue = (isArray(value) ? last(value) : value) || null;
        if (initialValue.fldLocHierarchy === updatedValue) {
          shouldDirty = false;
        }
      }
      setValue(name, value, { shouldDirty: shouldDirty });
    } catch (error) {
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  const onSaveClick = async (data: any)=>{
    if(data.equipment && isCreate){
      if(currentEquipment.fldCountHours && (isNil(data.fldHours) || isNaN(parseInt(data.fldHours)))){
        setEqHours(currentEquipment.hours)
        setSaveData(data)
        setEqWarningDialog(true)
      } else {
        handleSave(data)
      }
    } else {
      handleSave(data)
    }
  }

  const onChangeHours = (newHours: number)=>{
    setEqHoursCompletedAt(newHours);
  }

  const onContinueAnyWay = async()=>{
    const updatedSaveDataWithInputHours = {...saveData, fldHours: setEqHoursCompletedAt} 
    handleSave(updatedSaveDataWithInputHours)
    setEqWarningDialog(false)
  }

  const onEnterEqHours = async()=>{
    setSaveData(null)
    setEqWarningDialog(false)
  }

  const handleSave = async (data: any) => {

    if (!validateForm(data, setSnackbar)) return;

    const {
      logDate,
      fldSrhkey,
      fldLocHierarchy,
      fldWorkList,
      department,
      eqKey,
      equipment,
      fldHours,
      fldEnteredBy,
      fldPerformedBy,
      fldCompany,
      fldTime,
      fldTimeQty,
      fldCost,
      curr,
      fldIsWarranty,
      fldHtml,
      fldCrewId,
      fldRestricted,
      fldSms,
    } = data;

    const document: LogEntryInput = {
      curr: curr?.curr || null,
      department: department ? typeof department === 'object' ? department.fldMember : department : null,
      eqKey: (typeof equipment === 'object' && equipment !== null) ? equipment.eqKey : eqKey || null,
      fldCompany: fldCompany?.name || fldCompany || null,
      fldCost: parseInt(fldCost) || 0,
      fldEnteredBy,
      fldCrewId: fldCrewId || user?.fldCrewID,
      fldHours: parseInt(fldHours),
      fldHtml,
      fldIsWarranty,
      fldLocHierarchy: (isArray(fldLocHierarchy) ? last(fldLocHierarchy) : fldLocHierarchy) || null,
      fldPerformedBy: fldPerformedBy?.join(', ') || null,
      fldRestricted,
      fldSms,
      fldSrhkey: (isArray(fldSrhkey) ? last(fldSrhkey) : fldSrhkey) || null,
      fldTime: fldTime ? parseInt(fldTime, 10) : 0,
      fldTimeQty,
      fldWorkList: isString(fldWorkList) ? fldWorkList : fldWorkList?.fldMember || null ,
      logDate: logDate ? normalizeDateTime(logDate) : null,
      pKey: initialValue.pkey || null, // Set primary key, so we will be able to upsert.
      dayLogType: 1,
      description: fldHtml, // should not send this
    };

    if (isLogAndReset) {
      console.log('to be implemented when gets to be called from Scheduling module!')
    //   await updateSpares(spares, db, document);
    }

    const { responseData, responseMessage } = await upsertLogEntry(document, isCreate, queryVariables, callerComponent);

    if (responseData) {
      onSave(responseData as LogEntry, responseMessage, isCreate);

      const updatedFormValues = { 
        ...getValues(),
        fldHours: responseData?.fldHours,
      };     
      reset(updatedFormValues);
    }

    syncRxdb(); // TODO - Temporary solution for hybrid functionality with rxdb - remove after refactor to .net api 
  };

  const handleDelete = () => {
    setIsDeleting(true)
  };

  const handleDeleteOk = async () =>{
    const { responseData, responseMessage } = await removeLogEntry(initialValue.pkey, queryVariables, callerComponent);
    responseData && onDelete && onDelete(responseData, responseMessage);
    setIsDeleting(false);
  };

  const handleDeleteCancel = () =>{
    setIsDeleting(false);
  }

  const handleCancelUndo = () => {
    if (isCreate) {
      setFormIsDirty && setFormIsDirty(false) // set dirty form to false so you can navigate to other module
      return onCancel();
    }
    reset(formInitialValues.current);
  };

  const handleOk = (isEditing: boolean) => {
    if (isEditing && !validateForm(getValues(), setSnackbar) && !recordReadOnly) return;
    if (isEditing && !recordReadOnly) return; // We will send submit action that will be handled in HandleSave.
    handleCancel();
  };

  const hasValuesBeenChanged = formState.isDirty
    && (size(formState.dirtyFields) > 0 || size(formState.touchedFields) > 0);
  const isEditing = hasValuesBeenChanged || isCreate;

  useEffect(() => {
    setFormIsDirty && setFormIsDirty(hasValuesBeenChanged);
  }, [hasValuesBeenChanged]);

  const formClass = type === 'Dialog'
  ? 'relative bg-white flex-grow'
  : 'relative bg-white pt-14 md:pt-19 flex-grow';

  return (
    <form
      id="logentry-details-form"
      className={`${formClass}`}
      onSubmit={handleSubmit(onSaveClick)}
    >
      <div className="bg-white h-full flex-grow pt-6">
        <div className="px-6 h-full">
          {(moduleReadOnly || recordReadOnly) && (
              <RecordEditWarningCard recordType='Log Entry.' />
            )}
          <div className="mb-20">
            <Tabs
              tabs={[
                {
                  label: 'Summary',
                  component: (
                    <LogEntrySummaryForm
                      watch={watch}
                      control={control}
                      initialValue={initialValue}
                      onChange={onChange}
                      isNotCompletion
                      readOnly = {recordReadOnly}
                      isCreate={isCreate}
                    />
                  ),
                },
                ...(!isNil(initialValue.checks)
                ? 
                  [
                    {
                      label: `Check List (${checks.length || 0})`,
                      disabled: isCreate,
                      component: (
                        <LogEntryChecklistTab 
                          checks={checks}
                        />
                      ),
                    },
                  ]
                : []),
                {
                  label: `Spares (${sparesCount})`,
                  disabled: isCreate,
                  component: (
                    <SparesTab
                      recordId={initialValue.pkey}
                      recordType={RecordType.LogEntry}
                      recordTypeName={initialValue.__typename!}
                      eqKey={initialValue.eqKey ?? undefined}
                      isReport={isReport}
                      readOnly={moduleReadOnly || recordReadOnly}
                      setSparesCount={setSparesCount}
                    />
                  ),
                },
                {
                  label: documentsCount === undefined ? 'Attachments' : `Attachments (${documentsCount})`,
                  disabled: isCreate,
                  component: (
                    <AttachmentTab
                      recordId={initialValue.pkey}
                      recordType={RecordType.LogEntry}
                      recordTypeName={initialValue.__typename!}
                      attachmentType={AttachmentType.Document}
                      fldSrhkey={initialValue.fldSrhkey}
                      setAttachmentsCount={setDocumentsCount}
                      readOnly={moduleReadOnly || recordReadOnly} 
                    />
                  ),
                },

                {
                  label: `Photos (${photosCount})`,
                  disabled: isCreate,
                  component: (
                    <AttachmentTab
                      recordId={initialValue.pkey}
                      recordType={RecordType.LogEntry}
                      recordTypeName={initialValue.__typename!}
                      attachmentType={AttachmentType.Photo}
                      fldSrhkey={initialValue.fldSrhkey}
                      setAttachmentsCount={setPhotosCount}
                      readOnly={moduleReadOnly || recordReadOnly} 
                    />
                  ),
                },
                {
                  label: `Comments (${commentsCount})`,
                  disabled: isCreate,
                  component: (
                    <Comments
                      recordId={initialValue.pkey}
                      recordType={RecordType.LogEntry}
                      recordTypeName={initialValue.__typename!}
                      setCommentsCount={setCommentsCount}
                      readOnly={recordReadOnly}
                    />
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>

      {type !== 'Dialog' && (
        <StickyAppBar
          cancelText="Cancel"
          okType={isEditing ? 'submit' : 'button'}
          okText = {isEditing ? (isCreate ? 'Create' : 'Save') : 'Close'}
          onOk={() => handleOk(isEditing)}
          disabled={(moduleReadOnly || recordReadOnly) && isEditing}
          onCancel={isEditing ? () => handleCancelUndo() : undefined}
        >
          {!moduleReadOnly && !recordReadOnly && !isNil(initialValue.pkey) && (
            <IconButton
              onClick={handleDelete}
              color="error"
              aria-label="Delete item"
            >
              <DeleteTwoTone />
            </IconButton>
          )}
        </StickyAppBar>
      )}

      <WarningDialog
        visible={isDeleting}
        title="Delete Warning"
        color="error"
        okText='Yes'
        content="Are you sure you wish to delete record?"
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />

      <LogEntryEquipmentHoursDialog
        visible={eqWarningDialog}
        okText='Cancel'
        cancelText='Continue'
        color='warning'
        onOk={onEnterEqHours}
        onCancel={onContinueAnyWay}
        equipmentHours={eqHours}
        onChange={onChangeHours}
        eqHoursCompletedAt={eqHoursCompletedAt}
      />

      <Snackbar
        open={!!snackbar}
        autoHideDuration={2000}
        onClose={() => setSnackbar(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
      </Snackbar>
    </form>
  );
});

export default LogEntryDetailForm;