import React from 'react';
import { styled } from '@mui/system';
import TreeItem, { TreeItemProps } from '@mui/lab/TreeItem';
import { treeItemClasses } from '@mui/lab/TreeItem';

interface PseudoLineStyle {
  content: string;
  left: number;
  width: number;
  height: number;
  display: string;
  backgroundColor: string;
  position: string;
  top: number;
  transform: string;
}

const PseudoLine: PseudoLineStyle = {
  content: '""',
  left: -18,
  width: 18,
  height: 1,
  display: 'block',
  backgroundColor: '#1185df',
  position: 'absolute',
  top: 26,
  transform: 'translateY(-50%)',
};

const StyledTreeItem = styled((props: TreeItemProps) => <TreeItem {...props} />)(
  ({ theme }) => ({
    [`& .${treeItemClasses.content}`]: {
      padding: '5px 6px',
      marginTop: 3,
      marginBottom: 3,
      borderBottomLeftRadius: 5,
      borderTopLeftRadius: 5,
      borderLeft: '5px solid #1185df',
    },
    [`& .${treeItemClasses.iconContainer}`]: {
      '& .close': {
        opacity: 0.3,
      },
    },
    [`& .${treeItemClasses.group} .${treeItemClasses.root}`]: {
      position: 'relative',
      '&::before': PseudoLine,
    },
    [`& .${treeItemClasses.group} .MuiButton-root`]: {
      position: 'relative',
      '&::before': {
        ...PseudoLine,
        width: 18,
        top: 21,
      },
    },
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 3,
      marginTop: 5,
      marginBottom: 5,
      paddingLeft: 18,
      borderLeft: '1px solid #1185df',
    },
  })
);

export default StyledTreeItem;
