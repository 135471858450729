import { FC, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Button,
  Tooltip,
  Box,
  TextField,
  IconButton,
} from "@mui/material";
import React from "react";
import moment from "moment";
import { isNil } from "lodash";
import DateRangeTwoToneIcon from '@mui/icons-material/DateRangeTwoTone';
import EventAvailableTwoToneIcon from '@mui/icons-material/EventAvailableTwoTone';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

interface GridDateFilterDialogProps extends Partial<DialogProps> {
fieldName: string;
  filterValue: any;
  onChange: any;
}

const GridDateFilterDialog: FC<GridDateFilterDialogProps> = ({
    fieldName,
  filterValue,
  onChange,
  
}) => {

    const [open, setOpen] = useState(false);
    const [dateRangeFilter, setDateRangeFilter] = useState({
      start: filterValue.value.start || null,
      end: filterValue.value.end || null,
    });
    
    const handleApply = () => {
      onChange({
        name: fieldName,
        operator: 'inrange',
        type: 'date',
        value: {
          start: dateRangeFilter.start ? moment(dateRangeFilter.start).utc().format('DD-MMM-YYYY') : null,
          end: dateRangeFilter.end ? moment(dateRangeFilter.end).utc().format('DD-MMM-YYYY') : null,
        },
      });
      setOpen(false);
    };

    const handleClear = () => {
      onChange({
        name: fieldName,
        operator: 'inrange',
        type: 'date',
        value: '',
      });
      setOpen(false);
    }

    return (
        <>
          <Tooltip title='Filter Date'>
            <Button 
            onClick={() => setOpen(true)} 
            color={(!isNil(filterValue.value.start) || !isNil(filterValue.value.end)) ? 'info' : 'inherit'}
            startIcon={(!isNil(filterValue.value.start) || !isNil(filterValue.value.end)) ?
                  <EventAvailableTwoToneIcon />
                  :
                  <DateRangeTwoToneIcon />} 
            />
          </Tooltip>
          
          <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Filter by Date Range</DialogTitle>
            <DialogContent>
              <div className='p-3 '>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <DesktopDatePicker
                      label="Start Date"
                      inputFormat="dd-MMM-yyyy"
                      value={dateRangeFilter.start}
                      onChange={(date) => setDateRangeFilter({ ...dateRangeFilter, start: date })}
                      renderInput={(params) => <TextField {...params} variant="outlined" />}
                    />
                    <DesktopDatePicker
                      label="End Date"
                      inputFormat="dd-MMM-yyyy"
                      value={dateRangeFilter.end}
                      onChange={(date) => setDateRangeFilter({ ...dateRangeFilter, end: date })}
                      renderInput={(params) => <TextField {...params} variant="outlined" />}
                    />
                  </Box>
                </LocalizationProvider>
              </div>
            </DialogContent>
            <DialogActions>
              <Tooltip title="Clear & close filter popup">
                <IconButton
                  onClick={handleClear}
                  // color="error"
                  aria-label="Clear"
                >
                  <HighlightOffTwoToneIcon />
                </IconButton>
              </Tooltip>
              <Box
                sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}
              >
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button onClick={handleApply} variant="contained">Apply</Button>
              </Box>
            </DialogActions>
          </Dialog>
        </>
      );
};

export default GridDateFilterDialog;
