import { RxCollection, RxJsonSchema, RxDocument } from 'rxdb';
import { Currencies } from '../../../generated/graphql';

export type CurrenciesDocType = Currencies;

export type CurrenciesDocMethods = {};

export type CurrenciesDocument = RxDocument<
  CurrenciesDocType,
  CurrenciesDocMethods
>;

export type CurrenciesCollectionMethods = {};

export type CurrenciesCollection = RxCollection<
  CurrenciesDocType,
  CurrenciesDocMethods,
  CurrenciesCollectionMethods
>;

export const currenciesSchema: RxJsonSchema<CurrenciesDocType> = {
  title: 'Currencies ',
  description: 'describes a Currencies item',
  version: 1,
  keyCompression: false,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    Abbreviation: {
      type: ['string', 'null'],
    },
    Curr: {
      type: ['string', 'null'],
    },
    ID: {
      type: 'string',
      primary: true,
    },
    Symbol: {
      type: ['string', 'null'],
    },
    SymbolBefore: {
      type: ['boolean', 'null'],
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
    updatedAt: {
      type: 'string',
    },
  },
  required: [],
  additionalProperties: false,
};

export const currenciesDocMethods: CurrenciesDocMethods = {};

export const currenciesCollectionMethods: CurrenciesCollectionMethods = {};
