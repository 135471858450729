import { RxCollection, RxJsonSchema, RxDocument } from 'rxdb';
import { TblRecycleBin } from '../../../generated/graphql';

export type TblRecycleBinDocType = TblRecycleBin;

export type TblRecycleBinDocMethods = {};

export type TblRecycleBinDocument = RxDocument<
  TblRecycleBinDocType,
  TblRecycleBinDocMethods
>;

export type TblRecycleBinCollectionMethods = {};

export type TblRecycleBinCollection = RxCollection<
  TblRecycleBinDocType,
  TblRecycleBinDocMethods,
  TblRecycleBinCollectionMethods
>;

export const tblRecycleBinSchema: RxJsonSchema<TblRecycleBinDocType> = {
  title: 'tblRecycleBin ',
  description: 'describes a tblRecycleBin item',
  version: 0,
  keyCompression: false,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    PKey: {
      type: 'string',
      primary: true,
    },
    fldDateDeleted: {
      type: ['string', 'null'],
    },
    fldDeletedBy: {
      type: ['string', 'null'],
    },
    fldRecordKey: {
      type: ['string', 'null'],
    },
    fldTable: {
      type: ['string', 'null'],
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    updatedAt: {
      type: 'string',
    },
  },
  required: [],
  additionalProperties: false,
};

export const tblRecycleBinDocMethods: TblRecycleBinDocMethods = {};

export const tblRecycleBinCollectionMethods: TblRecycleBinCollectionMethods = {};
