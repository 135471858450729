import {
  isEmpty, isNil,
} from 'lodash';

export const validateForm = (data: any, setSnackbar: any) => {
  const { DocTitle, fldSRHKey } = data;

  if (isNil(DocTitle) || isEmpty(DocTitle) || isNil(fldSRHKey)) {
    setSnackbar({
      open: true,
      message: 'Please fill required field(s). Check form field(s) marked with red color',
      type: 'error',
    });
    return false;
  }

  // if (isNil(fldSRHKey)) {
  //   setSnackbar({
  //     open: true,
  //     message: 'Category is required',
  //     type: 'error',
  //   });
  //   return false;
  // }

  return true;
};
