import React, { FC, useState } from "react";
import { isNil } from "lodash";
import {
  Button,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  Snackbar,
  Alert,
  Portal,
} from "@mui/material";
import EqHoursUtilityGrid from "./EqHoursUtilityGrid";
import { EquipmentDocument } from "src/pages/EquipmentPage/rxdb";
import { getDatabase } from "src/rxdb";
import { Equipment } from "src/generated/graphql";
import { CustomDialog } from "src/helpers/modals";

interface InjectedProps {
  visible: boolean;
  defaultEquipment: EquipmentDocument;
  onCancel?: () => void;
}

const EqHoursUtility: FC<InjectedProps> = ({
  visible,
  onCancel,
  defaultEquipment,
}) => {
  const [selectedItem, setSelectedItem] = useState<EquipmentDocument>();
  const [eqitem, setEqItem] = useState<Equipment>(defaultEquipment.toJSON());
  const [snackBar, setSnackbar] = useState({
    open: false,
    type: "success",
    message: "",
  });

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: "",
      type: "success",
    });
  };
  const handleCancel = () => {
    onCancel && onCancel();
    setSnackbar({
      open: false,
      type: "success",
      message: "",
    });
  };

  const onSelectItem = (item: EquipmentDocument) => {
    setSelectedItem(item);
  };

  const handleRemove = async () => {
    const db = await getDatabase();
    const document = {
      ...eqitem,
      fldParent: null,
      updatedAt: new Date().toISOString(),
    };

    try {
      const res = await db.collections.equipment.upsert(document);
      setSnackbar({
        open: true,
        type: "success",
        message: "Equipment Parent Removed",
      });

      onCancel && onCancel();
    } catch (e: any) {
      setSnackbar({
        open: true,
        type: "error",
        message: e.message,
      });
    }
  };

  const handleSave = async () => {
    const db = await getDatabase();
    const document = {
      ...eqitem,
      fldParent: selectedItem?.EqKey,
      updatedAt: new Date().toISOString(),
    };

    try {
      const res = await db.collections.equipment.upsert(document);

      setSnackbar({
        open: true,
        type: "success",
        message: "Hours updated",
      });
      onCancel && onCancel();
    } catch (e: any) {
      setSnackbar({
        open: true,
        type: "error",
        message: e.message,
      });
    }
  };

  return (
    <>
      <CustomDialog
        scroll="paper"
        fullWidth
        maxWidth="md"
        open={visible}
        onClose={handleCancel}
      >
        <DialogTitle>
          <span className="font-bold text-2xl">
            Equipment With Hours Counters
          </span>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 4 }}>
          <div className="flex flex-grow h-full">
            <EqHoursUtilityGrid
              darken
              onSelect={onSelectItem}
              defaultSelected={defaultEquipment}
            />
          </div>
        </DialogContent>
        <DialogActions sx={{ px: 4, pb: 4, justifyContent: "space-between" }}>
          <Box
            sx={{ justifyContent: "flex-start", flexGrow: 1, display: "flex" }}
          >
            {!isNil(defaultEquipment?.fldParent) && (
              <Button
                className="mr-2"
                variant="contained"
                onClick={handleRemove}
              >
                Remove Selected
              </Button>
            )}
          </Box>
          <Box
            sx={{ justifyContent: "flex-end", flexGrow: 1, display: "flex" }}
          >
            <Button className="mr-2" variant="contained" onClick={handleSave}>
              save
            </Button>
            <Button className="mr-2" onClick={handleCancel} variant="text">
              Close
            </Button>
          </Box>
        </DialogActions>
      </CustomDialog>
      <Portal>
        <Snackbar
          open={snackBar.open}
          autoHideDuration={2000}
          onClose={onSnackbarClose}
        >
          <Alert severity={snackBar.type as any} sx={{ width: "100%" }}>
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Portal>
    </>
  );
};

export default EqHoursUtility;
