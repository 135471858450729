import {
  isNil,
} from 'lodash';
import { TblSparesUsedDocument } from 'src/components/SparesTab/rxdb';
import { TDIDb } from 'src/rxdb';

export const validateFormOld = (data: any, setSnackbar: any) => {
  const { LogDate, fldSRHKey, fldPerformedBy } = data;

  if (isNil(fldSRHKey) || isNil(LogDate) || isNil(fldPerformedBy)) {
    setSnackbar({
      open: true,
      message: 'Please fill required field(s). Check form field(s) marked with red color',
      type: 'error',
    });
    return false;
  }

  return true;
};

export const updateSpares = async (spares: TblSparesUsedDocument[], db: TDIDb, document: any) => {
  await Promise.all(
    spares.map(async (spare) => {
      // TODO: Handle case when multiple locations per ProductID can be found
      const location = await db.tblmultiplelocations
        .findOne({
          selector: {
            ProductID: spare.ProductID,
          },
        })
        .exec();

      await db.tblsparesused.atomicUpsert({
        ...spare.toJSON(),
        LogKey: document.PKey,
      });

      if (!isNil(location)) {
        await location.atomicPatch({
          Amount: Math.max(
            (location?.Amount || 0) - (spare.Amount || 0),
            0
          ),
        });
      }

      return spare;
    })
  );
}
