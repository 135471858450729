import React, { useEffect, useState } from 'react';
import {
  Card,
  Typography,
  CardContent,
  CardHeader,
  Divider,
  Button,
  CircularProgress
} from '@mui/material';
import { Link } from 'react-router-dom';
import { InventoryDocument } from "../../InventoryPage/rxdb";
import { useAuth } from "../../../contexts/auth";
import { useAppState } from "../../../contexts/app-state";
import { getJWT } from 'src/storage';
import { INVENTORY_REPORT_URL } from 'src/consts'
import BarChartUI from './BarChartUI'

const COLOR_OPTION = {
  good: ["#8884d8", '#22b1ff', '#22b14c', '#a349a4', '#d0b630'],
  below: ['#e03141']
}

const InventoryChartTile = () => {

  const { settingsPersonal } = useAppState();
  const [loading, setLoading] = useState<boolean>(true);
  const [report, setReport] = useState<any>([]);

  useEffect(() => {
    handleLoadLocal()
  }, [])

  const handleLoadLocal = () => {
    const data = localStorage.getItem("inventory-report-data")
    if (data) {
      setReport(JSON.parse(data).Type)
    }
  }

  useEffect(() => {
    if (settingsPersonal) {
      handleLoadData();
    }
  }, [settingsPersonal]);

  const handleLoadData = async () => {
    try {
      setLoading(true);
      const token = getJWT();
      const resultUpdate = await fetch(INVENTORY_REPORT_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`
        },
        body: "",
        redirect: 'follow'
      })

      const updateData = await resultUpdate.json();
      const dataStr: any = Object.values(updateData[0])[0]
      const reportData = JSON.parse(dataStr);
      setReport(reportData.Type || []);
      setLoading(false);
      if ((reportData.Type || []).length > 0) {
        localStorage.setItem("inventory-report-data", JSON.stringify(reportData))
      }
    } catch (error) {
      setLoading(false);
    }
  }

  const inventoryChart = () => (
    <div className="flex items-center justify-center mt-3">
      {report?.length > 0 && <BarChartUI
        COLOR_OPTION={COLOR_OPTION}
        width="100%"
        height={300}
        data={report}
        minKey={"Below"}
        maxKey={"Good"}
        nameKey={"Type"}
      />}
      {report?.length === 0 && <div className="flex w-full h-80 justify-center items-center">No Data Available</div>}
    </div>
  );

  return (
    <Card
      elevation={3}
      sx={{ borderTop: 4, borderColor: '#0088FE',height: 425, 
      '@media (min-width: 1200px) and (max-width: 1400px)': {
        height: 450,
      }, }}
    >
      <CardContent sx={{ padding: 2 }}>
        <CardHeader
          sx={{ padding: 1 }}
          //   ToDo: menu list will implement later
          //   action={
          //     <IconButton aria-label="settings">
          //       <MoreVertIcon />
          //     </IconButton>
          //   }
          title={(
            <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
              Spares and Inventory below reorder level
            </Typography>
          )}
        />
        <Divider />
        {loading && <div className="flex items-center justify-center w-full h-72">
          <CircularProgress />
        </div>}
        {!loading && inventoryChart()}
        <Divider className="mt-2" />
        <div className="flex justify-between">
          <div className="flex items-center">
            <Typography sx={{ fontSize: 14 }}>On Order</Typography>
          </div>
          <div className="flex items-center">
            <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
              {/* {report.total} */}
            </Typography>
            <Typography sx={{ fontSize: 14, marginX: 2 }}>items</Typography>
            <Button size="small" component={Link} to="/inventories/inventory">View</Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default InventoryChartTile;
