import { omit } from 'lodash';
import { TblSchedChk } from '../../../generated/graphql';
import {logger} from "../../../helpers/logger";

const fields = `
  PKey
  FKey
  EqKey
  fldLocHierarchy
  DescKey
  fldTitle
  fldIndex
  deletedAt
  deletedBy
  isRecoverable
  updatedAt
  autoId
  Completed`;

export const tblSchedChkPullQuery = (doc: TblSchedChk) => {
  if (!doc) {
    doc = {
      autoId: 0,
      PKey: '',
      updatedAt: new Date(0).toISOString(),
    };
  }
  logger('TblSchedChk').info(
    `pull query triggered - TblSchedChk:  { ${new Date().toISOString()} - ID: ${doc.autoId}}`,
  );
  return {
    query: `
        query getTblSchedChk($autoId: Int, $filterDate: Date) {
          tblSchedChk(
            where:{
              _and: [
                {
                  _or: [
                    {
                      isRecoverable: {
                        _is_null: true
                      }
                    },
                    {
                      isRecoverable: {
                        _eq: true
                      }
                    }
                  ]
                },
                {
                  _or: [
                    {
                      updatedAt: {_gt: $filterDate},                
                    },
                    {
                      autoId: {_gt: $autoId}
                      updatedAt: {_gte: $filterDate},
                    }
                  ]
                }
              ]                                    
            },
            order_by: [
              {updatedAt: asc},
              {autoId: asc}
            ],
              limit: 500
          ) {
            ${fields}            
          }
        }
      `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
    },
  };
};

export const tblSchedChkMutation = (doc: any) => {
  const query = `
    mutation insertTblSchedChk($input: TblSchedChkInput!) {
      upsertTblSchedChk(input: $input) {
        ${fields}
      }
    }
  `;

  const variables = {
    input: omit(doc, ['updatedAt', 'deletedAt', 'id']),
  };

  return {
    query,
    variables,
  };
};

export const tblSchedChkSubscription = `
  subscription onTblSchedChkChanged {
    tblSchedChk {
      ${fields}
    }
  }
`;
