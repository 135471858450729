import React, {
  useState,
  createContext,
  useContext,
  useCallback,
  useEffect,
  FC,
} from 'react';
import { isEmpty } from 'lodash';
import { CircularProgress } from '@mui/material';
import { getDatabase } from 'src/rxdb';
import { getJWT, setJWT, clearJWT } from '../storage';
import getSession from '../requests/getSession';
import { CrewProfiles } from '../generated/graphql';
import {logger} from "../helpers/logger";
//import { unregister } from 'src/serviceWorkerRegistration';

export type AuthProps = {
  token: string | undefined;
  user: CrewProfiles | null;
  signOut: () => void;
  resetApplication: () => void;
  refreshApplication: () => void;
  handleToken: (t: string, user?: any) => void;
  isSynced: boolean;
  setIsSynced: (t: boolean) => void;
  setUser: (userProfile: any) => void;
};

export type AuthProviderProps = {
  children?: JSX.Element;
};

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [token, setToken] = useState<string | undefined>(getJWT());
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isSynced, setIsSynced] = useState<boolean>(false);

  const cleanUp = async () => {
    // setToken(undefined);
    setLoading(false);
    setUser(null);
    clearJWT();
  };
  const onTokenVerificationError = (e: any) => {
    // If we are online and got an error - logout user.
    if (navigator.onLine) return signOut();

    logger('AuthProvider').info('Your are offline!');
  };

  const verifyToken = async () => {
    setLoading(true);
    try {
      // Call only when necessary
      if (token && !isEmpty(token)) {
        const session = await getSession(token);
        setUser(session);
      }

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      onTokenVerificationError(e);
    }
  };

  useEffect(() => {
    verifyToken();
  }, [token]);

  const handleToken = (t: string, user?: CrewProfiles) => {
    setToken(t);
    setJWT(t);
  };

  const signOut = useCallback(async () => {
    // console.log('signing out');
    cleanUp();
    const db = await getDatabase();
    //remove db - done to allow next logging in .
    await db.remove();
    await localStorage.clear();
    window.location.reload();
    // console.log('signed out successfully');
  }, []);

  const resetApplication = async () => {
    try {
      console.log('Resetting Application');
      const db = await getDatabase();
      await db.destroy();
      await db.remove();
      await localStorage.clear();
      //unregister();
      window.location.reload();
      console.log('Resetting Application Done');
    } catch (e) {
      console.log('error ', e)
    }
  };

  const refreshApplication = async () => {
    try {
      console.log('Refreshing Application');
      //unregister();
      window.location.reload();
      console.log('Refreshing Application Done');
    } catch (e) {
      console.log('error ', e)
    }
  };
  

  return (
    <AuthContext.Provider
      value={{
        token,
        user,
        setUser,
        signOut,
        resetApplication,
        refreshApplication,
        handleToken,
        isSynced,
        setIsSynced
      }}
    >
      {loading ? (
        <div className="absolute flex justify-center items-center inset-0	">
          <CircularProgress />
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

const AuthContext = createContext<AuthProps>({
  signOut: () => { },
  resetApplication: () => { },
  refreshApplication: () => { },
  token: '',
  user: null,
  handleToken: () => { },
  isSynced: false,
  setIsSynced: () => { },
  setUser: () => { }
});
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
