import React, { FC, ReactNode } from 'react';
import {
  Autocomplete as MaterialAutocomplete,
  createFilterOptions,
  FormControl,
  TextField,
} from '@mui/material';
import { isEqual, isNil, isString, get } from 'lodash';
import { Control, RegisterOptions, useController } from 'react-hook-form';
import { v4 } from 'uuid';
import { getDatabase } from 'src/rxdb';

const filter = createFilterOptions<any>();

type InjectedProps = {
  control: Control;
  name: string;
  rules?: RegisterOptions;
  placeholder?: string;
  label?: string;
  options: any[];
  loading?: boolean;
  onChange: (name: string, value: any) => void;
  groupBy?: (option: any) => string;
  freeSolo?: boolean;
  displayExpr: string;
  keyExpr: string;
  collection?: string; // Use this in order to create Item after pressing submit
  defaultPayload?: object;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any
  ) => ReactNode;
  disabled?: boolean;
  variant?: any;
  size?: any;
};

const Autocomplete: FC<InjectedProps> = ({
  name,
  control,
  rules,
  onChange,
  placeholder,
  label,
  loading,
  options,
  groupBy,
  freeSolo,
  displayExpr,
  keyExpr,
  collection,
  defaultPayload,
  renderOption,
  disabled,
  variant,
  size = 'small',
}) => {
  const {
    field: { value = null, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const createNewOption = async (option: any) => {
    try {
      if (option.collection === "tblddlistdefaults") {
        const db = await getDatabase();
        const document = {
          PKey: option.PKey,
          fldListName: option.fldListName,
          fldMember: option.inputValue,
          fldT4Reqd: option.fldT4Reqd,
          fldIndex: option.fldIndex
        }
        const res = await db.collections.tblddlistdefaults.upsert(document);
      }
    } catch (error) {
      console.log("error --- ", error)
    }
  }
  const filterOptions = (options: any, params: any) => {
    const filtered = filter(options, params);
    const matched = filtered.find(item => item[displayExpr] === params.inputValue);

    if (!matched && params.inputValue !== '') {
      filtered.push({
        ...defaultPayload,
        isCreate: true,
        inputValue: params.inputValue,
        [keyExpr]: v4(),
        [displayExpr]: `Add "${params.inputValue}"`,
        collection, // Access collection by it's name
      });
    }

    return filtered;
  };
  const handleChange = async (event: any, newValue: any) => {
    if (newValue && newValue.inputValue) {
      onChange(name, {
        ...newValue,
        [displayExpr]: newValue.inputValue,
      });
    } else {
      onChange(name, newValue);
    }

    if (newValue?.isCreate) {
      createNewOption(newValue)
    }
  };
  const getOptionLabel = (option: any) => {
    // e.g value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    if (option.inputValue) {
      return option.inputValue;
    }
    return option[displayExpr];
  };

  const isOptionEqualToValue = (opt: any, value: any) => {
    if (isNil(opt)) return true;

    if (isString(value)) return isEqual(get(opt, displayExpr), value);

    return isEqual(get(opt, keyExpr), get(value, keyExpr));
  };

  const renderItem = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any,
  ) => {
    if (renderOption) return renderOption(props, option);

    return (
      <li {...props} key={props.id}>
        {option[displayExpr]}
      </li>
    );
  };

  return (
    <FormControl error={!!error} fullWidth>
      <MaterialAutocomplete
        isOptionEqualToValue={isOptionEqualToValue}
        disabled={disabled}
        value={value}
        clearOnBlur
        handleHomeEndKeys
        freeSolo={freeSolo}
        groupBy={groupBy}
        loading={loading}
        options={options}
        getOptionLabel={getOptionLabel}
        onChange={handleChange}
        filterOptions={freeSolo ? filterOptions : undefined}
        renderOption={renderItem}
        size={size}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={ref}
            label={label}
            autoComplete='off'
            variant={variant}
            placeholder={placeholder}
            error={!!error}
            helperText={error?.message}
          />
        )}
        
      />
    </FormControl>
  );
};

export default Autocomplete;
