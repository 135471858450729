import { ReactNode } from 'react';
import { RoutePath } from '../../../routes';

export type NavConfig = {
  path?: string;
  label: string;
  node: ReactNode;
  children?: NavConfig[];
  hide?: boolean;
  external?: boolean;
};

export const createNavigationConfig = (path: string | undefined, label: string, node?: string): NavConfig => ({
  path,
  node: node || label,
  label,
});

const menuItems: NavConfig[] = [
  {
    path: RoutePath.HOME,
    label: 'Home',
    node: 'Home',
  },
  {
    label: 'Tasks',
    node: 'Tasks',
    children: [
      {
        path: RoutePath.TASKS_WORKISSUES,
        node: 'workissues',
        label: 'All Tasks',
      },
      {
        path: RoutePath.TASKS_INSPECTIONS,
        node: 'task-inspections',
        label: 'Inspections Tasks',
      },
      {
        path: RoutePath.TASKS_CERTIFICATES,
        node: 'Certificates Tasks',
        label: 'Certificates Tasks',
      },
    ],
  },

  // { path: RoutePath.PROFILE, node: 'Accounts', label: 'Accounts' },
  { path: RoutePath.EQUIPMENT, node: 'Equipment', label: 'Equipment', hide: true },
  {
    label: 'Spares & Inventory',
    node: 'Spares & Inventory',
    children: [
      {
        path: RoutePath.INVENTORIES_HOME,
        node: 'inventory',
        label: 'General Inventory',
      },
      {
        path: RoutePath.INVENTORIES_BEVERAGES,
        node: 'beverages',
        label: 'Beverages',
      },
      {
        path: RoutePath.INVENTORIES_UNIFORMS,
        node: 'uniforms',
        label: 'Uniforms',
      },
      {
        path: RoutePath.INVENTORIES_STORES,
        node: 'stores',
        label: 'Dry & Cold Stores',
      },
      {
        path: RoutePath.INVENTORIES_MEDICAL,
        node: 'medical-inventory',
        label: 'Medical',
      },
    ],
  }, 
  {
    label: 'Events & Schedules',
    node: 'Events & Schedules',
    children: [
      {
        path: RoutePath.SCHEDULES_EQUIPMENT,
        node: 'equipment-schedules',
        label: 'Equipment Schedules',
      },
      {
        path: RoutePath.EVENT_SCHEDULING,
        node: 'event-scheduling',
        label: 'Event Scheduling',
      },
      {
        path: RoutePath.DRILL_SCHEDULING,
        node: 'drill-scheduling',
        label: 'Drill Scheduling',
      },
      {
        path: RoutePath.INSPECTIONS,
        node: 'inspections',
        label: 'Inspections',
      }
    ],
  },
  { path: RoutePath.PURCHASING, node: 'Purchasing', label: 'Purchasing', hide: true },
  {
    node: 'Logs',
    label: 'Logs',
    children: [
      {
        path: RoutePath.LOGS_ENG_DAY,
        node: "Engineer's Day Log",
        label: "Engineer's Day Log",
      },
      {
        path: RoutePath.LOGS_MAINTENANCE,
        node: 'Maintenance History',
        label: 'Maintenance History',
      },
      {
        path: RoutePath.LOGS_RUNNING,
        node: "Running Logs",
        label: "Running Logs",
      },
      {
        node: 'QuickReports',
        label: 'Quick Reports',
        children: [
          {
            path: RoutePath.LOGS_CRITICAL_REPORT,
            node: "Critical Systems Report",
            label: "Critical Systems Report",
          },
          {
            path: RoutePath.LOGS_MAINTENANCE_REPORT,
            node: "Maintenance Report",
            label: "Maintenance Report",
          },
          {
            path: RoutePath.LOGS_FORECASTING,
            node: "Forecasting Report",
            label: "Forecasting Report",
          },
        ]
      },
      
      // {
      //   path: RoutePath.LOGS_ENGINEERING,
      //   node: 'Engineering Rounds',
      //   label: 'Engineering Rounds',
      // },
    ],
  },
  {
    node: 'Crew & Guest Data',
    label: 'Crew & Guest Data',
    children: [
      { path: RoutePath.CREW_PROFILES, node: 'profiles', label: 'Crew' },
      { path: RoutePath.CREW_GUESTS, node: 'guests', label: 'Guest' },
      {
        path: `${RoutePath.CERTIFICATES}/crewprofile`,
        node: 'CrewCertificates',
        label: 'Crew Certificates',
      },
      // { path: RoutePath.DWR, node: 'DWR', label: 'DWR' },
      { path: RoutePath.HOURS_OF_REST, node: 'hor', label: 'Hours Of Rest' },
    ],
  },
  {
    node: 'Documentation Libraries',
    label: 'Documentation Libraries',
    children: [
      {
        path: `${RoutePath.LIBRARIES_HOME}/reference`,
        node: 'Vessel Reference',
        label: 'Vessel Reference',
      },
      {
        path: `${RoutePath.LIBRARIES_HOME}/drawings`,
        node: 'Drawings',
        label: 'Drawings',
      },
      {
        path: `${RoutePath.LIBRARIES_HOME}/sms`,
        node: 'Safety Management System',
        label: 'Safety Management System',
      },
      {
        path: `${RoutePath.LIBRARIES_HOME}/isps`,
        node: 'ISPS',
        label: 'ISPS',
      },
      {
        path: `${RoutePath.LIBRARIES_HOME}/photos`,
        node: 'Photos',
        label: 'Photos',
      },
      {
        path: `${RoutePath.CERTIFICATES}/library`,
        node: 'LibraryCertificates',
        label: 'Certificates',
      },
    ],
  },
];

export default menuItems;
