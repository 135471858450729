import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from "@mui/material/Checkbox";
import { Control, Controller, UseFormWatch } from 'react-hook-form';
import DatePicker from 'src/components/UI/Forms/DatePicker';
import Input from 'src/components/UI/Forms/Input';
import { isNil } from 'lodash';
import { isValidDateFormat } from 'src/utils/format-dates';
import Autocomplete from 'src/components/UI/Forms/Autocomplete';
import { TimeMeasureOptions, WeekDayOptions } from 'src/consts';

const RecurTypeEnum = {
    WEEKDAYS: "48",
    REGENERATE: "47",
}

export const getNthDayInWeek = (date: Date, day: number): Date => {

    const dateCopy = new Date(date.getTime());
    const selectedDayDate = new Date(
        dateCopy.setDate(
          dateCopy.getDate() + ((7 - dateCopy.getDay() + 1) % 7 || 7),
        ),
      );
    selectedDayDate.setDate(selectedDayDate.getDate()-1)
    selectedDayDate.setDate(selectedDayDate.getDate()+day)
    return selectedDayDate;
}

interface WeeklyScheduleProps {
    form: any;
    initialValue: any;
    onChange: (field: string, value: any) => void;
    control: Control;
    watch: UseFormWatch<any>;
    getValues: any;
    disableEdit?: boolean;
    setValue: any;
}

const WeeklyScheduleForm: FC<WeeklyScheduleProps> = ({
    onChange,
    initialValue,
    control,
    watch,
    setValue,
}) => {
    const [initialRender, setInitialRender] = useState<boolean>(true);
    const [checkedValues, setCheckedValues] = useState<string[]>([]);
    const scheduleType = watch("scheduleType");
    const simpleCheck = watch("simpleCheck");
    const recurPatt = watch("RecurPattern");
    const RecurPatternWeeklyEveryDay = watch("RecurPatternWeeklyEveryDay");
    const RecurPatternWeeklyRegenerate = watch("RecurPatternWeeklyRegenerate");
    const RecurType = watch("RecurType");

    let RecurPattern: string = "";
    switch (RecurType) {
        case RecurTypeEnum.WEEKDAYS: RecurPattern = RecurPatternWeeklyEveryDay; break;
        case RecurTypeEnum.REGENERATE: RecurPattern = RecurPatternWeeklyRegenerate; break;
    }
   
    useEffect(() => {
        if (scheduleType?.id === "1" || scheduleType === "Weekly") {
            if (initialValue.RecurType) {  
                setValue("RecurType", initialValue.RecurType === 47 ? RecurTypeEnum.REGENERATE : RecurTypeEnum.WEEKDAYS);
                setValue("RecurPatternWeeklyRegenerate", initialValue.RecurType === 47 ? parseInt(initialValue.RecurPattern[0], 10) || null : null)
                setValue("RecurPatternWeeklyEveryDay", initialValue.RecurType === 48 ? parseInt(initialValue.RecurPattern[0], 10) || null : null)
                setValue("simpleCheck", initialValue.RecurType === 47 ? true : false);
                setValue("fldDateTrigger", initialValue.fldDateTrigger);
                setValue("fldTimeWarn", initialValue.fldTimeWarn);
                setValue("fldTimeMeasureWarn", initialValue.fldTimeMeasureWarn);
                
                if(!isNil(initialValue.RecurPattern) && initialValue.RecurPattern.length > 1 && !isNil(recurPatt)){
                    setValue("weekDays",recurPatt.slice(1))
                    setCheckedValues(recurPatt.slice(1))
                }
            } else {
                setValue("RecurType", RecurTypeEnum.REGENERATE);
                setValue("simpleCheck", true);
                setValue("fldDateTrigger", "");
                setValue("fldTimeWarn", "");
                setValue("fldTimeMeasureWarn", "");
            }
            setTimeout(() => {
                setInitialRender(false);
            });
        }

    }, [scheduleType, initialValue]);

    useEffect(() => {
        if(!initialRender) {
            let selectedWeek: number = 0;
            let selectedRegenerate: number = 0
            let today = new Date();
            switch (RecurType) {
                case RecurTypeEnum.WEEKDAYS: {
                    selectedWeek = Number(RecurPatternWeeklyEveryDay);
                    setValue("RecurPatternWeeklyRegenerate","") 
                    break;
                }
                case RecurTypeEnum.REGENERATE: {
                    selectedRegenerate = Number(RecurPatternWeeklyRegenerate); 
                    setValue("RecurPatternWeeklyEveryDay","") 
                    break;
                }
            }
            if(checkedValues.length === 0){
                setValue("fldDateTrigger",null)
            }
            if(selectedRegenerate > 0){
                today.setDate(today.getDate() + selectedRegenerate * 7);
                setValue("fldDateTrigger", moment(today).format("DD-MMM-YYYY"));
            }
            if (selectedWeek > 0 && checkedValues.length > 0) {
                const firstSelectedDay = checkedValues.sort()
                today.setDate(today.getDate() + (selectedWeek - 1) * 7);
                today = getNthDayInWeek(today,parseInt(firstSelectedDay[0],10) -1)
                setValue("fldDateTrigger", moment(today).format("DD-MMM-YYYY"));
            }
        }
    }, [RecurType, RecurPatternWeeklyEveryDay, RecurPatternWeeklyRegenerate,checkedValues]);

    useEffect(() => {
        if(simpleCheck) {
            setValue("RecurType", RecurTypeEnum.REGENERATE);
        }
    }, [simpleCheck]);
    
    const handleSelectDay = (checkedName:any) =>{
        const newNames = checkedValues?.includes(checkedName)
        ? checkedValues?.filter(name => name !== checkedName)
        : [...(checkedValues ?? []), checkedName];
      setCheckedValues(newNames);
        setValue("weekDays",newNames)
        onChange("weekDays",newNames)
      return newNames;
    }

    return (
        <div>
            {scheduleType?.id === "1" || scheduleType === "Weekly"
                ? <Box>
                    {!simpleCheck
                        ? <Box>
                            <div className="pt-4">
                                <Box display="flex" alignItems="center" alignContent="center" className='mt-4'>
                                    <Box className='mr-2'>
                                        <Radio
                                            checked={RecurType === RecurTypeEnum.WEEKDAYS}
                                            onChange={event => onChange("RecurType", event.target.value)}
                                            value={RecurTypeEnum.WEEKDAYS}
                                            name="RecurType"
                                        />
                                        Recur every &nbsp;
                                    </Box>
                                    <Box display="flex" alignItems="center" className='mr-2'>
                                        <Input
                                            name="RecurPatternWeeklyEveryDay"
                                            inputProps={{
                                                label: '',
                                                type: 'number',
                                                inputProps: {
                                                    min: 1,
                                                },
                                                sx: { width: "100px" }
                                            }}
                                            control={control}
                                            disabled={(RecurType != RecurTypeEnum.WEEKDAYS)}
                                            rules={{ required: RecurType === RecurTypeEnum.WEEKDAYS }}
                                        />
                                        <p>&nbsp; week(s) on</p>
                                    </Box>
                                    
                                </Box>
                                <Box display="flex" className='mt-4 ml-6' >                                       
                                    {         
                                    // TODO: extract to component if we use groupcheckbox again in other form                           
                                        <FormControl component="fieldset" variant="standard">                                               
                                            <FormGroup row={true}>
                                                <Controller
                                                    name="weekDays"
                                                    control={control}
                                                    rules={{required:true}}
                                                    render={({ field }) => (
                                                    <>
                                                        {WeekDayOptions.map((weekDayOption) => {
                                                            return (
                                                                <FormControlLabel
                                                                    key={weekDayOption.value}
                                                                    label={weekDayOption.label}
                                                                    disabled={(RecurType != RecurTypeEnum.WEEKDAYS)}
                                                                    control={
                                                                        <Checkbox
                                                                            value={weekDayOption.value}                                                                        
                                                                            checked={checkedValues.includes(weekDayOption.value)}
                                                                            onChange={(event, checked) => {
                                                                                handleSelectDay(weekDayOption.value);
                                                                            }} 
                                                                        />
                                                                    } 
                                                                />
                                                            );
                                                        })}
                                                    </>
                                                    )}
                                                />
                                            </FormGroup>
                                        </FormControl>                                        
                                     }
                                </Box>
                            </div>
                        </Box>
                        : null
                    }
                    <div className="pt-4">
                        <Box display="flex" alignItems="center" alignContent="center" className='mr-2'>
                            <Box>
                                <Radio
                                    checked={RecurType === RecurTypeEnum.REGENERATE}
                                    onChange={event => onChange("RecurType", event.target.value)}
                                    value={RecurTypeEnum.REGENERATE}
                                    name="RecurType"
                                />
                                Regenerate &nbsp;
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Input
                                    name="RecurPatternWeeklyRegenerate"
                                    inputProps={{
                                        label: '',
                                        type: 'number',
                                        inputProps: {
                                            min: 1,
                                        },
                                        sx: { width: "100px" }
                                    }}
                                    control={control}
                                    disabled={(RecurType != RecurTypeEnum.REGENERATE)}
                                    rules={{ required: RecurType === RecurTypeEnum.REGENERATE }}
                                />
                                <p>&nbsp; week(s)</p>
                            </Box>
                        </Box>
                    </div>
                    <div className="pt-4">
                        <Box>
                            <DatePicker
                                name="fldDateTrigger"
                                control={control}
                                label="Date Next Due"
                                rules={{
                                    required: '*Date value is required for this field!',
                                    validate: (value) => isValidDateFormat(value) || 'Please enter a valid date format dd-MMM-yyyy',
                                  }}
                            />
                        </Box>
                    </div>
                    <div className="pt-4">
                        <Box display="flex" alignItems="center" alignContent="center">
                            <Box>
                                Remind me &nbsp;
                            </Box>
                            <Box display="flex" alignItems="center" marginRight={2}>
                                <Input
                                    name="fldTimeWarn"
                                    inputProps={{
                                        label: '',
                                        type: 'number',
                                        inputProps: {
                                            min: 1,
                                        },
                                        sx: {
                                            width: "100px",
                                            '& .MuiOutlinedInput-root': {
                                              '& .MuiOutlinedInput-input': {
                                                textAlign: 'right',
                                              },
                                            },
                                          },
                                    }}
                                    control={control}
                                />
                            </Box>
                            <Box display="flex" alignItems="center" width={150}>
                                <Autocomplete
                                    onChange={onChange}
                                    label="Time Measure"
                                    options={TimeMeasureOptions}
                                    control={control}
                                    name="fldTimeMeasureWarn"
                                    keyExpr="id"
                                    displayExpr="timeMeasure"
                                />
                            </Box>
                            <Box>
                                &nbsp; before due
                            </Box>
                        </Box>
                    </div>
                </Box>
                : null
            }
        </div>
    )
};

export default WeeklyScheduleForm;
