import { RxCollection, RxJsonSchema, RxDocument } from 'rxdb';
import { Comments } from '../../../generated/graphql';

export type CommentDocType = Comments;

export type CommentDocMethods = {};

export type CommentDocument = RxDocument<
  CommentDocType,
  CommentDocMethods
  >;

export type CommentCollectionMethods = {};

export type CommentCollection = RxCollection<
  CommentDocType,
  CommentDocMethods,
  CommentCollectionMethods
  >;

//   DateEntered

export const schema: RxJsonSchema<CommentDocType> = {
  title: 'Document Revision ',
  description: 'Comment',
  version: 1,
  keyCompression: false,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    id: {
      type: 'string',
      primary: true,
    },
    createdAt: {
      type: 'string',
    },
    replyId: {
      type: ['string', 'null'],
    },
    replies: {
      type: 'array',
      ref: 'comments',
      items: {
        type: 'string',
      },
    },
    content: {
      type: ['string'],
    },
    referenceIssueId: {
      type: ['string', 'null'],
      ref: 'workissues',
    },
    referenceIssueType: {
      type: ['string', 'null'],
      ref: 'workissues',
    },
    reportId: {
      type: ['string', 'null'],
      ref: 'tblanyreports',
    },
    authorId: {
      type: 'string',
      ref: 'crew',
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
    updatedAt: {
      type: 'string',
    },
  },
  required: [],
  indexes: ['createdAt'],
  additionalProperties: false,
} as any;

export default schema;
