import { RxCollection, RxJsonSchema, RxDocument } from 'rxdb';
import { TblSchedMaint } from '../../../generated/graphql';

export type SchedMaintType = TblSchedMaint;

export type SchedMaintDocMethods = {};

export type SchedMaintDocument = RxDocument<
  SchedMaintType,
  SchedMaintDocMethods
  >;

export type SchedMaintCollectionMethods = {};

export type SchedMaintCollection = RxCollection<
  SchedMaintType,
  SchedMaintDocMethods,
  SchedMaintCollectionMethods
  >;

export const schema: RxJsonSchema<SchedMaintType> = {
  title: 'Sched Maint Collection',
  description: 'Sched Maint',
  version: 1,
  keyCompression: false,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    DateEntered: {
      type: ['string', 'null'],
    },
    Department: {
      type: ['string', 'null'],
    },
    RecurInterval: {
      type: ['number', 'null'],
    },
    RecurPattern: {
      type: ['string', 'null'],
    },
    RecurType: {
      type: ['number', 'null'],
    },
    fldAssignedTo: {
      type: ['string', 'null'],
    },
    fldCSM: {
      type: ['boolean', 'null'],
    },
    fldDuration: {
      type: ['number', 'null'],
    },
    fldHTML: {
      type: ['string', 'null'],
    },
    fldHourInterval: {
      type: ['number', 'null'],
    },
    fldHourLock: {
      type: ['boolean', 'null'],
    },
    fldHourWarn: {
      type: ['number', 'null'],
    },
    fldHoursKey: {
      type: ['string', 'null'],
    },
    fldIsCheckList: {
      type: ['boolean', 'null'],
    },
    fldLinkID: {
      type: ['string', 'null'],
    },
    fldListType: {
      type: ['string', 'null'],
    },
    fldNotes: {
      type: ['string', 'null'],
    },
    fldPlainText: {
      type: ['string', 'null'],
    },
    fldQuotedCurrency: {
      type: ['string', 'null'],
    },
    fldQuotedPrice: {
      type: ['number', 'null'],
    },
    fldRTF: {
      type: ['string', 'null'],
    },
    fldSMS: {
      type: ['boolean', 'null'],
    },
    fldSRHKey: {
      type: ['string', 'null'],
    },
    fldSchedType: {
      type: ['string', 'null'],
    },
    fldSteps: {
      type: ['string', 'null'],
    },
    fldSubject: {
      type: 'string',
    },
    fldTTLWork: {
      type: ['string', 'null'],
    },
    fldTimeInterval: {
      type: ['number', 'null'],
    },
    fldTimeMeasure: {
      type: ['string', 'null'],
    },
    fldTimeMeasureWarn: {
      type: ['string', 'null'],
    },
    fldTimeWarn: {
      type: ['number', 'null'],
    },
    fldToolsReqd: {
      type: ['string', 'null'],
    },
    fldUserDefined: {
      type: ['string', 'null'],
    },
    PKey: {
      type: 'string',
      primary: true,
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
    updatedAt: {
      type: 'string',
    },
  },
  required: [],
  additionalProperties: false,
};

export default schema;
