import { RxCollection, RxJsonSchema, RxDocument } from 'rxdb';
import { WorkIssues } from 'src/generated/graphql';

export type WorkIssuesDocType = WorkIssues;

export type WorkIssuesDocMethods = {};

export type WorkIssuesDocument = RxDocument<
  WorkIssuesDocType,
  WorkIssuesDocMethods
>;

export type WorkIssuesCollectionMethods = {};

export type WorkIssuesCollection = RxCollection<
  WorkIssuesDocType,
  WorkIssuesDocMethods,
  WorkIssuesCollectionMethods
>;

export const workIssuesSchema: RxJsonSchema<WorkIssuesDocType> = {
  title: 'workissues ',
  description: 'describes a workissue task items',
  version: 1,
  keyCompression: false,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    AssignedTo: {
      type: ['string', 'null'],
    },
    Company: {
      type: ['string', 'null'],
      ref: 'vddallcontacts',
    },
    Completed: {
      type: ['boolean', 'null'],
    },
    DateCompleted: {
      type: ['string', 'null'],
    },
    DateDue: {
      type: ['string', 'null'],
    },
    DateEntered: {
      type: ['string', 'null'],
    },
    DateStart: {
      type: ['string', 'null'],
    },
    DateWarn: {
      type: ['string', 'null'],
    },
    Department: {
      type: ['string', 'null'],
      ref: 'tblddlistdefaults',
    },
    EqKey: {
      type: ['string', 'null'],
      ref: 'equipment',
    },
    Equipment: {
      type: ['string', 'null'],
    },
    JobNumber: {
      type: 'string',
      primary: true,
    },
    Checks: {
      type: ['string', 'null'],
    },
    Notes: {
      type: ['string', 'null'],
    },
    PercentComplete: {
      type: ['number', 'null'],
    },
    Subject: {
      type: 'string',
    },
    WorkItem: {
      type: ['boolean', 'null'],
    },
    fldActualCost: {
      type: ['number', 'null'],
    },
    fldActualCurrency: {
      type: ['string', 'null'],
    },
    fldActualWork: {
      type: ['string', 'null'],
    },
    fldCustJob: {
      type: ['string', 'null'],
    },
    fldDayChk: {
      type: ['number', 'null'],
    },
    fldDeptCreator: {
      type: ['string', 'null'],
    },
    fldDuration: {
      type: ['number', 'null'],
    },
    fldHTML: {
      type: ['string', 'null'],
    },
    fldHasBeenLogged: {
      type: ['boolean', 'null'],
    },
    fldHours: {
      type: ['number', 'null'],
    },
    fldIsCAR: {
      type: ['boolean', 'null'],
    },
    fldIsCheckList: {
      type: ['boolean', 'null'],
    },
    fldIsWarranty: {
      type: ['boolean', 'null'],
    },
    fldLocHierarchy: {
      type: ['string', 'null'],
      description: 'Locations foreign key',
      ref: 'tbllocationshierarchy',
    },
    fldLogOnDone: {
      type: ['boolean', 'null'],
    },
    fldPaid: {
      type: ['boolean', 'null'],
    },
    fldParentID: {
      type: ['string', 'null'],
    },
    fldPriority: {
      type: ['string', 'null'],
      ref: 'tblddlistdefaults',
    },
    fldQuotedCurrency: {
      type: ['string', 'null'],
    },
    fldQuotedPrice: {
      type: ['number', 'null'],
    },
    fldSMS: {
      type: ['boolean', 'null'],
    },
    fldSMSID: {
      type: ['string', 'null'],
      ref: 'certificates',
    },
    fldSMSType: {
      type: ['string', 'null'],
    },
    fldSchedMaintKey: {
      type: ['string', 'null'],
      ref: 'tblschedmainteq',
    },
    fldStatus: {
      type: ['string', 'null'],
      ref: 'tblddlistdefaults',
    },
    fldTTLWork: {
      type: ['string', 'null'],
    },
    fldWorkList: {
      type: ['string', 'null'],
      ref: 'tblddlistdefaults',
    },
    fldSRHKey: {
      description: 'Categories foreign key. TblSystemHierarchy',
      type: ['string', 'null'],
      ref: 'tblsystemshierarchy',
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    updatedAt: {
      type: 'string',
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
    // updatedAt_aggregate: {},
  },
  indexes: ['Subject', 'updatedAt'],
  required: [],
  additionalProperties: false,
};

export const workIssuesDocMethods: WorkIssuesDocMethods = {};

export const workIssuesCollectionMethods: WorkIssuesCollectionMethods = {};
