import { ApolloQueryResult, gql, useQuery  } from "@apollo/client";
import { Inventory, CollectionSegmentInfo, QueryInventoryArgs, InventoryFilterInput, InventorySortInput } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";

export const GET_INVENTORY = gql`
  query GetInventory($skip: Int, $take: Int, $where: InventoryFilterInput, $order: [InventorySortInput!]) {
    inventory(skip: $skip, take: $take, where: $where, order: $order) {
      items {
        productName
        productId
        manufacturer
        fldPartNumber
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

interface InventoryResult {
  data: Inventory[];
  currentCount: number;
  totalCount: number;
  pageInfo: CollectionSegmentInfo;
  loading: boolean;
  loadData: (skip: number, take: number,  where: InventoryFilterInput, order: InventorySortInput[]) => void;
}

export const useGetInventory = ({ skip, take, where, order }: QueryInventoryArgs): InventoryResult => {

  const { data, loading, error, refetch } = useQuery(GET_INVENTORY, {
    variables: { skip, take, where, order },
    notifyOnNetworkStatusChange: true,
  });

  const loadData = (skip: number, take: number, where: InventoryFilterInput, order: InventorySortInput[]) => {
    logger('GetInventory').debug('Query variables', {skip: skip, take: take, where: where, order: order});     
    refetch({ skip: skip, take: take, where: where, order: order })
      .then((result: ApolloQueryResult<any>) => {
        if (result.data && result.data.inventory) {
          const { items, pageInfo } = result.data.inventory;
          logger('GetInventory').info('Inventory fetched successfully', {
            recordsCount: items?.length,
            pageInfo: pageInfo,
          });
        } else {
          logger('GetInventory').warning('No data received in query result');
        }
      }).catch((apolloError) => {
        logger('GetInventory').error('Error re-fetching inventory -->', apolloError.message);
      });
  };
  
  if (error) {
    logger('GetInventory').error('Error fetching inventory -->', error.message);
  }

  return {
    data: data?.inventory?.items || [],
    totalCount: data?.inventory?.totalCount || 0,
    currentCount: data?.inventory?.items?.length || 0,
    pageInfo: data?.inventory?.pageInfo,
    loading,
    loadData,
  };
};