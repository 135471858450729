import { Box, Radio } from "@mui/material";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { Control, UseFormWatch } from "react-hook-form";
import Autocomplete from "src/components/UI/Forms/Autocomplete";
import DatePicker from "src/components/UI/Forms/DatePicker";
import Input from "src/components/UI/Forms/Input";
import { MonthDayOptions, MonthOrdinalOptions, TimeMeasureOptions } from "src/consts";
import { isValidDateFormat } from "src/utils/format-dates";

interface MonthlyScheduleProps {
    form: any;
    initialValue: any;
    onChange: (field: string, value: any) => void;
    control: Control;
    watch: UseFormWatch<any>;
    getValues: any;
    disableEdit?: boolean;
    setValue: any;
}

const RecurTypeEnum = {
    EVERY: "56",
    EVERY_MONTHS: "55",
    REGENERATE: "54",
}

export const getNthDayInMonth = (date: Date, nth: number, day: number): Date => {
    let d = new Date(date.getFullYear(), date.getMonth());
    d.setDate(1 + (7 - d.getDay() + day) % 7 + (nth - 1) * 7);
    return d;
}

export const getLastDayOfMonth = (date: Date, day: number): Date => {
    let d = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let dd: number = d.getDate() - (d.getDay() - day);
    if (dd > d.getDate()) {
        dd -= 7;
    }
    d.setDate(dd);
    return d;
}

const MonthlyScheduleForm: FC<MonthlyScheduleProps> = ({
    form,
    initialValue,
    onChange,
    control,
    watch,
    getValues,
    disableEdit = false,
    setValue
}) => {
    const [initialRender, setInitialRender] = useState<boolean>(true);
    const scheduleType = watch("scheduleType");
    const simpleCheck = watch("simpleCheck");
    const RecurPatternEveryDay = watch("RecurPatternEveryDay");
    const RecurPatternEveryMonth = watch("RecurPatternEveryMonth");
    const RecurPatternEveryMonthOrdinal = watch("RecurPatternEveryMonthOrdinal");
    const RecurPatternEveryMonthDay = watch("RecurPatternEveryMonthDay");
    const RecurPatternEveryMonthMonths = watch("RecurPatternEveryMonthMonths");
    const RecurPatternRegenerate = watch("RecurPatternRegenerate");
    const RecurType = watch("RecurType");

    useEffect(() => {
        if (scheduleType?.id === "2" || scheduleType === "Monthly") {
            let RecurTypeValue
            switch (initialValue.RecurType) {
                case 56:{
                    RecurTypeValue = RecurTypeEnum.EVERY
                    break;
                }
                case 55:{
                    RecurTypeValue = RecurTypeEnum.EVERY_MONTHS
                    break;
                }
                case 54:{
                    RecurTypeValue = RecurTypeEnum.REGENERATE
                    break;
                }
                default:
                    break;
            }
            if (initialValue.RecurType) {
                setValue("RecurType", RecurTypeValue);
                setInitialRender(true);
                setValue("RecurPatternEveryDay", RecurTypeValue === RecurTypeEnum.EVERY ? (initialValue.RecurPattern.split(",")[0] ?? "") : "");
                setValue("RecurPatternEveryMonth", RecurTypeValue === RecurTypeEnum.EVERY ? (initialValue.RecurPattern.split(",")[1] ?? "") : "");
                setValue("RecurPatternEveryMonthOrdinal", RecurTypeValue === RecurTypeEnum.EVERY_MONTHS ? (MonthOrdinalOptions.find((d: any) => d.id.toString() === initialValue.RecurPattern.split(",")[0]) ?? "") : "");
                setValue("RecurPatternEveryMonthDay", RecurTypeValue === RecurTypeEnum.EVERY_MONTHS ? (MonthDayOptions.find((d: any) => d.id.toString() === initialValue.RecurPattern.split(",")[1]) ?? "") : "");
                setValue("RecurPatternEveryMonthMonths", RecurTypeValue === RecurTypeEnum.EVERY_MONTHS ? (initialValue.RecurPattern.split(",")[2] ?? "") : "");
                setValue("RecurPatternRegenerate", RecurTypeValue === RecurTypeEnum.REGENERATE ? initialValue.RecurPattern : "");
                setValue("simpleCheck", RecurTypeValue === RecurTypeEnum.REGENERATE);
                setValue("fldDateTrigger", initialValue.fldDateTrigger);
                setValue("fldTimeWarn", initialValue.fldTimeWarn);
                setValue("fldTimeMeasureWarn", initialValue.fldTimeMeasureWarn);
            }
            else {
                setValue("RecurType", RecurTypeEnum.REGENERATE);
                setValue("simpleCheck", true);
                setValue("fldDateTrigger", "");
                setValue("fldTimeWarn", "");
                setValue("fldTimeMeasureWarn", "");
            }
            setTimeout(() => {
                setInitialRender(false);
            });
        }
    }, [initialValue, scheduleType]);

    useEffect(() => {
        if(!initialRender) {
            let today = new Date();
            let changeDate: boolean = false;
            switch (RecurType) {
                case RecurTypeEnum.EVERY:
                    if (Number(RecurPatternEveryDay) && Number(RecurPatternEveryMonth)) {
                        changeDate = true;
                        today.setDate(Number(RecurPatternEveryDay));
                        today.setMonth(today.getMonth() + Number(RecurPatternEveryMonth));
                    }
                    setValue("RecurPatternEveryMonthOrdinal","")
                    setValue("RecurPatternEveryMonthDay","")
                    setValue("RecurPatternEveryMonthMonths","")
                    setValue("RecurPatternRegenerate","")
                    break;
                case RecurTypeEnum.EVERY_MONTHS:
                    if (RecurPatternEveryMonthOrdinal && RecurPatternEveryMonthDay && Number(RecurPatternEveryMonthMonths)) {
                        changeDate = true;
                        today.setMonth(today.getMonth() + Number(RecurPatternEveryMonthMonths));
                        if (Number(RecurPatternEveryMonthOrdinal.id) === 4) {
                            today = getLastDayOfMonth(today, Number(RecurPatternEveryMonthDay.id) - 1);
                        }
                        else {
                            today = getNthDayInMonth(today, Number(RecurPatternEveryMonthOrdinal.id) + 1, Number(RecurPatternEveryMonthDay.id) - 1);
                        }
                    }
                    setValue("RecurPatternEveryDay","")
                    setValue("RecurPatternEveryMonth","")
                    setValue("RecurPatternRegenerate","")
                    break;
                case RecurTypeEnum.REGENERATE:
                    if (Number(RecurPatternRegenerate)) {
                        changeDate = true;
                        today.setMonth(today.getMonth() + Number(RecurPatternRegenerate));
                    }
                    setValue("RecurPatternEveryDay","")
                    setValue("RecurPatternEveryMonth","")
                    setValue("RecurPatternEveryMonthOrdinal","")
                    setValue("RecurPatternEveryMonthDay","")
                    setValue("RecurPatternEveryMonthMonths","")
                    break;
            }
            if (changeDate) {
                setValue("fldDateTrigger", moment(today).format("DD-MMM-YYYY"));
            }
        }
    }, [RecurType, RecurPatternEveryDay, RecurPatternEveryMonth, RecurPatternEveryMonthOrdinal, RecurPatternEveryMonthDay, RecurPatternEveryMonthMonths, RecurPatternRegenerate]);

    useEffect(() => {
        if (simpleCheck) {
            setValue("RecurType", RecurTypeEnum.REGENERATE);
        }
    }, [simpleCheck]);

    return (
        <div>
            {scheduleType?.id === "2" || scheduleType === "Monthly"
                ? <Box>
                    {!simpleCheck
                        ? <Box>
                            <div className="pt-4">
                                <Box display="flex">
                                    <Box>
                                        <Radio
                                            checked={RecurType === RecurTypeEnum.EVERY}
                                            onChange={event => onChange("RecurType", event.target.value)}
                                            value={RecurTypeEnum.EVERY}
                                            name="RecurType"
                                        />
                                    </Box>
                                    <Box display="flex" alignItems="center" flexWrap="wrap" gap={2}>
                                        <Box>Day</Box>
                                        <Box display="flex" alignItems="center">
                                            <Input
                                                name="RecurPatternEveryDay"
                                                inputProps={{
                                                    label: '',
                                                    type: 'number',
                                                    inputProps: {
                                                        min: 1,
                                                        max: 31,
                                                    },
                                                    sx: { width: "70px" }
                                                }}
                                                control={control}
                                                disabled={(RecurType != RecurTypeEnum.EVERY)}
                                                rules={{ required: RecurType === RecurTypeEnum.EVERY }}
                                            />
                                        </Box>
                                        <Box>of every</Box>
                                        <Box display="flex" alignItems="center">
                                            <Input
                                                name="RecurPatternEveryMonth"
                                                inputProps={{
                                                    label: '',
                                                    type: 'number',
                                                    inputProps: {
                                                        min: 1,
                                                    },
                                                    sx: { width: "70px" }
                                                }}
                                                control={control}
                                                disabled={(RecurType != RecurTypeEnum.EVERY)}
                                                rules={{ required: RecurType === RecurTypeEnum.EVERY }}
                                            />
                                        </Box>
                                        <Box>month(s)</Box>
                                    </Box>
                                </Box>
                            </div>
                            <div className="pt-4">
                                <Box display="flex">
                                    <Box>
                                        <Radio
                                            checked={RecurType === RecurTypeEnum.EVERY_MONTHS}
                                            onChange={event => onChange("RecurType", event.target.value)}
                                            value={RecurTypeEnum.EVERY_MONTHS}
                                            name="RecurType"
                                        />
                                    </Box>
                                    <Box display="flex" alignItems="center" flexWrap="wrap" gap={2}>
                                        <Box>The</Box>
                                        <Box sx={{ width: "120px" }}>
                                            <Autocomplete
                                                onChange={onChange}
                                                label={""}
                                                options={MonthOrdinalOptions}
                                                control={control}
                                                name={"RecurPatternEveryMonthOrdinal"}
                                                keyExpr="id"
                                                displayExpr="label"
                                                disabled={(RecurType != RecurTypeEnum.EVERY_MONTHS)}
                                                rules={{ required: RecurType === RecurTypeEnum.EVERY_MONTHS }}
                                            />
                                        </Box>
                                        <Box sx={{ width: "140px" }}>
                                            <Autocomplete
                                                onChange={onChange}
                                                label={""}
                                                options={MonthDayOptions}
                                                control={control}
                                                name={"RecurPatternEveryMonthDay"}
                                                keyExpr="id"
                                                displayExpr="label"
                                                disabled={(RecurType != RecurTypeEnum.EVERY_MONTHS)}
                                                rules={{ required: RecurType === RecurTypeEnum.EVERY_MONTHS }}
                                            />
                                        </Box>
                                        <Box>every</Box>
                                        <Box>
                                            <Input
                                                name="RecurPatternEveryMonthMonths"
                                                inputProps={{
                                                    label: '',
                                                    type: 'number',
                                                    inputProps: {
                                                        min: 1,
                                                    },
                                                    sx: { width: "70px" }
                                                }}
                                                control={control}
                                                disabled={(RecurType != RecurTypeEnum.EVERY_MONTHS)}
                                                rules={{ required: RecurType === RecurTypeEnum.EVERY_MONTHS }}
                                            />
                                        </Box>
                                        <Box>month(s)</Box>
                                    </Box>
                                </Box>
                            </div>
                        </Box>
                        : null
                    }
                    <div className="pt-4">
                        <Box display="flex" alignItems="center">
                            <Box>
                                <Radio
                                    checked={RecurType === RecurTypeEnum.REGENERATE}
                                    onChange={event => onChange("RecurType", event.target.value)}
                                    value={RecurTypeEnum.REGENERATE}
                                    name="RecurType"
                                />
                            </Box>
                            <Box display="flex" alignItems="center" flexWrap="wrap" gap={2}>
                                <Box>Regenerate</Box>
                                <Box>
                                    <Input
                                        name="RecurPatternRegenerate"
                                        inputProps={{
                                            label: '',
                                            type: 'number',
                                            inputProps: {
                                                min: 1,
                                            },
                                            sx: { width: "70px" }
                                        }}
                                        control={control}
                                        disabled={(RecurType != RecurTypeEnum.REGENERATE)}
                                        rules={{ required: RecurType === RecurTypeEnum.REGENERATE }}
                                    />
                                </Box>
                                <Box>month(s)</Box>
                            </Box>
                        </Box>
                    </div>
                    <div className="pt-4">
                        <Box>
                            <DatePicker
                                name="fldDateTrigger"
                                control={control}
                                label="Date Next Due"
                                rules={{
                                    required: '*Date value is required for this field!',
                                    validate: (value) => isValidDateFormat(value) || 'Please enter a valid date format dd-MMM-yyyy',
                                  }}
                            />
                        </Box>
                    </div>
                    <div className="pt-4">
                        <Box display="flex" alignItems="center" flexWrap="wrap" gap={2}>
                            <Box>
                                Remind me
                            </Box>
                            <Box display="flex" alignItems="center" marginRight={2}>
                                <Input
                                    name="fldTimeWarn"
                                    inputProps={{
                                        label: '',
                                        type: 'number',
                                        inputProps: {
                                            min: 1,
                                        },
                                        sx: {
                                            width: "100px",
                                            '& .MuiOutlinedInput-root': {
                                                '& .MuiOutlinedInput-input': {
                                                textAlign: 'right',
                                                },
                                            },
                                        },
                                    }}
                                    control={control}
                                />
                            </Box>
                            <Box display="flex" alignItems="center" width={150}>
                                <Autocomplete
                                    onChange={onChange}
                                    label="Time Measure"
                                    options={TimeMeasureOptions}
                                    control={control}
                                    name="fldTimeMeasureWarn"
                                    keyExpr="id"
                                    displayExpr="timeMeasure"
                                />
                                </Box>
                                <Box>
                                &nbsp; before due
                                </Box>
                        </Box>
                    </div>
                </Box>
                : null
            }
        </div>
    )
}

export default MonthlyScheduleForm
