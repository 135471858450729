import { RxCollection, RxJsonSchema, RxDocument } from 'rxdb';
import { TblAnyReport } from '../../../generated/graphql';

export type AnyReportType = TblAnyReport;

export type AnyReportDocMethods = {};

export type AnyReportDocument = RxDocument<
  AnyReportType,
  AnyReportDocMethods
  >;

export type AnyReportCollectionMethods = {};

export type AnyReportCollection = RxCollection<
  AnyReportType,
  AnyReportDocMethods,
  AnyReportCollectionMethods
  >;

export const schema: RxJsonSchema<AnyReportType> = {
  title: 'Any Reports Collection',
  description: 'Any reports',
  version: 1,
  keyCompression: false,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    PKey: {
      type: 'string',
      primary: true,
    },
    fldCompletedBy: {
      type: ['string', 'null'],
      ref: 'crew',
    },
    fldCompletedDate: {
      type: ['string', 'null'],
    },
    fldCreatedBy: {
      type: ['string', 'null'],
      ref: 'crew',
    },
    fldCreatedByName: {
      type: 'string',
    },
    fldDateCreated: {
      type: ['string', 'null'],
    },
    fldDateReceived: {
      type: ['string', 'null'],
    },
    fldDateSent: {
      type: ['string', 'null'],
    },
    fldNoView: {
      type: ['boolean', 'null'],
    },
    fldParent: {
      type: ['string', 'null'],
    },
    fldRemark: {
      type: ['string', 'null'],
    },
    fldReportFile: {
      type: 'string',
    },
    fldReportNumber: {
      type: ['string', 'null'],
    },
    fldReportPassword: {
      type: ['string', 'null'],
    },
    fldReportType: {
      type: 'string',
    },
    fldSend: {
      type: ['boolean', 'null'],
    },
    fldVerifiedBy: {
      type: ['string', 'null'],
      ref: 'crew',
    },
    fldVerifiedDate: {
      type: ['string', 'null'],
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
    updatedAt: {
      type: 'string',
    },
  },
  required: ['fldReportType', 'fldReportFile', 'fldCreatedByName', 'PKey'],
  additionalProperties: false,
};

export default schema;
