import { RxCollection, RxJsonSchema, RxDocument } from 'rxdb';
import { InvBarCodes } from '../../../generated/graphql';

export type InvBarCodesDocType = InvBarCodes & { id: string };

export type InvBarCodesDocMethods = {};

export type InvBarCodesDocument = RxDocument<
  InvBarCodesDocType,
  InvBarCodesDocMethods
>;

export type InvBarCodesCollectionMethods = {};

export type InvBarCodesCollection = RxCollection<
  InvBarCodesDocType,
  InvBarCodesDocMethods,
  InvBarCodesCollectionMethods
>;

export const schema: RxJsonSchema<InvBarCodesDocType> = {
  title: 'InvBarCodes ',
  description: 'Inventory BarCodes',
  version: 1,
  keyCompression: false,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    ProductID: {
      type: 'string',
      ref: 'inventory',
    },
    fldTritonBC: {
      type: 'string',
    },
    fldDefault: {
      type: ['boolean', 'null'],
    },
    id: {
      type: 'string',
      primary: true,
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
    updatedAt: {
      type: 'string',
    },
  },
  indexes: ['id', 'updatedAt'],
  required: [],
  additionalProperties: false,
};

export default schema;
