import React from 'react';
import pdf from '@react-pdf/renderer';
const { View, StyleSheet, Font, Text, Image } = pdf;

// YSM Imported components
import { generateBarcodeImage } from './utils';

// Register the font files
Font.register({
    family: 'RobotoMono',
    fonts: [
      { src: `./fonts/RobotoMono-Regular.ttf` },
      { src: `./fonts/RobotoMono-Bold.ttf`, fontWeight: 'bold' }
    ],
  });

// Styles for the barcode container
const styles = StyleSheet.create({
  labelsClass: {
    fontFamily: 'RobotoMono',
    fontWeight: 'bold',
  },

  dataText: {
    fontFamily: 'RobotoMono',
  },
});

// Define the component
const LuerssenLabel = ({
  BCData,
  fontSize,
  labelColumnWidth,
  width,
}: {
  BCData: any;
  fontSize: number;
  labelColumnWidth: number;
  width: number;
}) => {
  return (
    <View>
      <View style={{ marginBottom: 5, minHeight: 50 }}>
        <View style={{ maxHeight: 30, width: '100%' }}>
          <Image
            style={{ margin: 1, marginTop: 2 }}
            src={generateBarcodeImage(
              BCData.fldLLPno,
              width,
              10,
              'CODE39'
            )}
          />
        </View>

        <View style={{ flexDirection: 'row', marginTop: 1, width: '100%' }}>
          <Text
            style={{
              ...styles.labelsClass,
              fontSize: 8,
              width: '25%',
            }}
          >
            Building
          </Text>

          <Text
            style={{
              ...styles.labelsClass,
              fontSize: 8,
              width: '25%',
            }}
          >
            Unit
          </Text>

          <Text
            style={{
              ...styles.labelsClass,
              fontSize: 8,
              width: '50%',
            }}
          >
            Pos
          </Text>
        </View>

        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            marginTop: 3,
          }}
        >
          <Text
            style={{
              ...styles.dataText,
              fontSize: 8,
              width: '25%',
            }}
          >
            {BCData.llBCdata[0].building}
          </Text>

          <Text
            style={{
              ...styles.dataText,
              fontSize: 8,
              width: '25%',
            }}
          >
            {BCData.llBCdata[0].unit}
          </Text>

          <Text
            style={{
              ...styles.dataText,
              fontSize: 8,
              width: '50%',
            }}
          >
            {BCData.llBCdata[0].pos}
          </Text>
        </View>

        <View style={{ flexDirection: 'row', marginTop: 7 }}>
          <Text
            style={{
              ...styles.labelsClass,
              fontSize: fontSize,
              width: labelColumnWidth,
            }}
          >
            Part:
          </Text>

          <Text
            style={{
              ...styles.dataText,
              fontSize: fontSize,
              width: '90%',
            }}
          >
            {BCData.fldPartNumber}
          </Text>
        </View>
      </View>

      {/* ProductName row */}
      <View style={{ flexDirection: 'row' }}>
        <Text
          style={{
            ...styles.dataText,
            fontSize: fontSize,
            width: '90%',
          }}
        >
          {BCData.ProductName}
        </Text>
      </View>

      {/* Location Row */}
      <View style={{ marginTop: 7 }}>
        <Text
          style={{
            ...styles.dataText,
            fontSize: fontSize,
            width: '90%',
          }}
        >
          {BCData.Manufacturer}
        </Text>

        <Text
          style={{
            fontSize: 8,
            width: '100%',
            marginBottom: 2,
          }}
        >
          {BCData.locationData}
        </Text>

        <Text
          style={{
            ...styles.labelsClass,
            marginTop: 3,
            fontSize: 7,
          }}
        >
          FCSM
        </Text>
      </View>
    </View>
  );
};

// Export the component
export default LuerssenLabel;