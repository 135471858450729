import React, {FC, useEffect} from 'react';
import { CellProps } from '@inovua/reactdatagrid-enterprise/types';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { Link } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import DangerousIcon from '@mui/icons-material/Dangerous';
import TaskIcon from "../../../assets/icon-tasks-blue.svg";
import EventSchedulesIcon from '../../../assets/icon-event-schedules.svg';

import {
  SchedMainEqWithSchedMainWithPendingTaskCount
} from "../../../pages/EquipmentSchedules/utils";

interface InjectedProps {
  data: SchedMainEqWithSchedMainWithPendingTaskCount;
  schedMaintEqs: any [];
}

const GridRowScheduleIcon: FC<InjectedProps> = ({ data, schedMaintEqs = [] }) => {
  const { fldDeferred, fldSMS, pendingTaskCount, fldCSM } = data as SchedMainEqWithSchedMainWithPendingTaskCount;
  const itemList = schedMaintEqs?.filter((item: any) => item.SchedKey === data.SchedKey);
  return (
    <div className="flex h-full">

      {fldDeferred && (
        <Tooltip title={`Suspended`}>
          <DoDisturbIcon color='error' style={{fontSize: 16, alignSelf: 'center',  fontWeight: 'bold'}} />
        </Tooltip>
      )}

      {fldSMS && (
        <Tooltip title={`Critical`}>
          <DangerousIcon color='error' style={{fontSize: 18, alignSelf: 'center', fontWeight: 'bold'}} />
        </Tooltip>
      )}
      {fldCSM && (
        <Tooltip title={`Continuous Survey Maintenance`}>
          <img src={EventSchedulesIcon} height="15" width="15"/>
        </Tooltip>
      )}
      {pendingTaskCount > 0 &&
        <Tooltip title={`${pendingTaskCount} task(s) not completed`}>
          <img src={TaskIcon} height="15" width="15"/>
        </Tooltip>
      }
      {itemList.length > 1 &&  <Link style={{ fontSize: 18 }} />}
    </div>
  )
};

export default GridRowScheduleIcon;
