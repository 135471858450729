import { omit } from 'lodash';
import { InvBarCodes } from '../../../generated/graphql';
import {logger} from "../../../helpers/logger";

const fields = `
  ProductID
  fldTritonBC
  fldDefault
  deletedAt
  updatedAt
  deletedBy
  isRecoverable
  autoId
`;

export const invBarCodesPullQuery = (doc: InvBarCodes) => {
  if (!doc) {
    doc = {
      autoId: 0,
      ProductID: '',
      fldTritonBC: '',
      updatedAt: new Date(0).toISOString(),
    };
  }
  logger('InvBarCodes').info(
    `pull query triggered - InvBarCodes: { ${new Date().toISOString()} - ID: ${doc.autoId} , updatedAt: ${doc.updatedAt}}`,
  );
  return {
    query: `
        query getInvBarCodes($autoId: Int, , $filterDate: Date) {
          InvBarCodes(
            where:{
              _and: [
                {
                  _or: [
                    {
                      isRecoverable: {
                        _is_null: true
                      }
                    },
                    {
                      isRecoverable: {
                        _eq: true
                      }
                    }
                  ]
                },
                {
                  _or: [
                    {
                      updatedAt: {_gt: $filterDate},                
                    },
                    {
                      autoId: {_gt: $autoId}
                      updatedAt: {_gte: $filterDate},
                    }
                  ]
                }
              ]                                    
            },
            order_by: [
              {updatedAt: asc},
              {autoId: asc}
            ],
            limit: 1000
          ) {
            ${fields}
          }
        }
      `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
    },
  };
};

export const invBarCodesMutation = (doc: any) => {
  const query = `
    mutation upsertInvBarCodes($input: InvBarCodesUpsertInput!) {
      upsertInvBarCodes(input: $input) {
        ${fields}
      }
    }
  `;

  const variables = {
    input: omit(doc, ['updatedAt', 'deletedAt', 'id']),
  };

  return {
    query,
    variables,
  };
};
