import React, {
  FC,
  useState,
  MutableRefObject,
  useCallback,
} from "react";
import { pick, size } from "lodash";
import Button from "@mui/material/Button";
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { TblSparesUsedDocument } from "../../rxdb";
import { getDatabase } from "../../../../rxdb";
import {
  TypeComputedProps,
  TypeSortInfo,
} from "@inovua/reactdatagrid-enterprise/types";
import LicensedReactDataGrid from "src/components/UI/LicensedReactDataGrid";

interface InjectedProps {
  visible: boolean;
  onCancel?: () => void;
  spare?: TblSparesUsedDocument;
}

const transformData = async (item: any) => ({
  ...pick(item, ["PKey", "ProductID", "Amount", "fldLocHierarchy"]),
  original: item,
});

const SpareLocationPopupOld: FC<InjectedProps> = ({
  spare,
  visible,
  onCancel,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const loadData = async ({
    skip,
    limit,
  }: {
    sortInfo?: TypeSortInfo;
    skip?: number;
    limit?: number;
  }): Promise<{ data: any[]; count: number }> => {
    const db = await getDatabase();

    const length = size(await db.tblmultiplelocations.find().exec());

    const item = await db.tblmultiplelocations
      .find({
        selector: {
          ProductID: spare?.ProductID,
        },
      })
      .exec();

    const data = await Promise.all(item.map(transformData));

    for (const dataItem of data) {
      dataItem.fldLocHierarchy = await getLocNodes(dataItem, db);
    }

    return { data, count: length };
  };

  const getLocNodes = async (item: any, db: any) => {
    const locArrayData = [];
    let node: any = {
      PKey: "",
      fldLocation: "",
      ParentID: item.fldLocHierarchy,
    };
    while (node.ParentID) {
      node = await db.tbllocationshierarchy
        .findOne({
          selector: {
            PKey: node.ParentID,
          },
        })
        .exec();
      locArrayData.push(node.fldLocation);
    }

    return locArrayData.reverse().join(" >> ");
  };

  const init = async (ref: MutableRefObject<TypeComputedProps | null>) => {
    const db = await getDatabase();

    db.tblmultiplelocations.$.subscribe(async (ev: any) => {
      ref.current?.reload();
    });
  };

  const onReady = (ref: MutableRefObject<TypeComputedProps | null>) => {
    init(ref);
  };

  const onLoadingChange = (status: boolean) => {
    setLoading(status);
  };

  const dataSource = useCallback(loadData, [spare]);

  const columns = [
    {
      name: "Amount",
      header: "Amount",
      defaultWidth: 95,
      headerAlign: 'start' as any,
      textAlign: "end" as any,
    },
    {
      name: "fldLocHierarchy",
      header: "Location",
      flex: 1,
    },
  ];

  return (
    <Dialog scroll="paper" fullWidth maxWidth="md" open={visible}>
      <DialogTitle sx={{ m: 0, p: 4 }} style={{ fontSize: "26px" }}>
        <span className="ml-2">Spare Location(s)</span>
        {onCancel ? (
          <IconButton
            aria-label="close"
            onClick={onCancel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent dividers>
        <div data-testid="data-grid" className="flex flex-col flex-grow m-2">
          <LicensedReactDataGrid
            onLoadingChange={onLoadingChange}
            onReady={onReady}
            rowHeight={40}
            loading={loading}
            idProperty="ProductID"
            columns={columns}
            dataSource={dataSource}
          />
        </div>
      </DialogContent>
      <DialogActions sx={{ m: 0, p: 3 }}>
        <Button onClick={onCancel} className="w-32">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SpareLocationPopupOld;
