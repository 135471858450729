import React, { FC, useEffect, useRef, useState } from 'react';
import { isNil } from 'lodash';
import {
  Alert,
  Snackbar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@mui/material';

import { WorkIssuesDocument } from 'src/rxdb/collections/WorkIssues/rxdb';
import { CertificateDocument } from 'src/rxdb/collections/Certificates/rxdb';
import { getDatabase } from '../../../../rxdb';
import GenericModal from '../../../UI/GenericModal';
import { Subscription } from 'rxjs';

interface CompleteCertificateTaskModalProps {
  issue: WorkIssuesDocument;
  visible: boolean;
  onClose: () => void;
  onCertificateInfoChange?: (isCreate: boolean) => void;
}

enum CertificateTaskModalContentType {
  OPTIONS,
  CANCEL,
  CREATE,
  EDIT,
}

const CompleteCertificateTaskModal: FC<CompleteCertificateTaskModalProps> = ({
  issue,
  visible,
  onClose,
  onCertificateInfoChange,
}) => {
  const [certificate, setCertificate] = useState<CertificateDocument | null>();
  const [existsInRecyclebin, setExistsInRecycleBin] = useState<boolean>(false);
  const [snackbar, setSnackbarConfig] = useState({
    visible: false,
    type: 'info' as any,
    message: '',
  });

  const [completeModalVisible, setCompleteModalVisible] = useState(false);
  // We will render content of modal based on this type.
  const [contentType, setContentType] = useState<CertificateTaskModalContentType>(CertificateTaskModalContentType.OPTIONS);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);
  
  const populateInfo = async (fldSMSID: string) => {
    const db = await getDatabase();

    const bin = await db.tblrecyclebin
      .findOne({ selector: { fldRecordKey: fldSMSID } })
      .exec();

    const binExists = !isNil(bin);

    const certificate = await db.certificates
      .findOne({ selector: { PKey: fldSMSID } })
      .exec();
    if (isMounted.current) {
      // Update content type. Show different UI.
      setContentType(
        !isNil(certificate) && binExists
          ? CertificateTaskModalContentType.CANCEL
          : CertificateTaskModalContentType.OPTIONS,
      );

      setCertificate(certificate);
      setExistsInRecycleBin(binExists);
    }
  };

  useEffect(() => {
    let isMounted = true; // Flag to track component mount status
    let subscription: Subscription; // Variable to store the subscription
  
    const init = async () => {
      subscription = (issue as any).fldSMSID$.subscribe((newId: string) => {
        if (isMounted) {
          populateInfo(newId);
        }
      });
    };
  
    init();
  
    // Cleanup function to unsubscribe from observable
    return () => {
      isMounted = false; // Mark component as unmounted
      if (subscription) {
        subscription.unsubscribe(); // Unsubscribe from the subscription
      }
    };
  }, [issue]);

  const handleCancelClick = () => {
    onClose();
    setContentType(CertificateTaskModalContentType.OPTIONS);
  };

  const handleCompleteTaskConfirm = () => {
    setCompleteModalVisible(false);
  };
  const handleCompleteTaskCancel = () => {
    setCompleteModalVisible(false);
  };
  const handleCompleteTaskClick = () => {
    onClose(); // Hide parent modal
    setCompleteModalVisible(true);
  };

  // Certificate create/edit
  const handleCertificateCreateClick = () => {
    onClose();
    onCertificateInfoChange && onCertificateInfoChange(true);
  };
  const handleCertificateEditClick = () => {
    onClose();
    onCertificateInfoChange && onCertificateInfoChange(false);
  };

  const onHiding = () => {
    setSnackbarConfig({
      visible: false,
      type: 'info',
      message: '',
    });
  };

  const cancelContent = () => {
    if (!isNil(certificate) && existsInRecyclebin) {
      return (
        <div className="mb-10">
          {`${certificate?.fldType} has been deleted and expiration date will be set to
          null, do you want to complete the task?`}
        </div>
      );
    }

    return null;
  };

  const optionsContent = () => (
    <div className="flex flex-col items-center justify-center">
      <Button title="" className="mb-4" onClick={handleCertificateCreateClick}>
        issue a new Certificate
      </Button>
      {!isNil(certificate) && certificate?.fldCertType !== 1 &&(
        <Button className="mb-4" onClick={handleCertificateEditClick}>
          update the existing certificate
        </Button>
      )}
      {existsInRecyclebin && (
        <Button className="mb-4" onClick={handleCompleteTaskClick}>
          Complete Task
        </Button>
      )}
    </div>
  );

  const displayContent = () => {
    switch (contentType) {
      case CertificateTaskModalContentType.OPTIONS:
        return optionsContent();
      case CertificateTaskModalContentType.CANCEL:
        return cancelContent();
    }
  };

  return (
    <>
      <Dialog scroll="paper" fullWidth maxWidth="md" open={visible}>
        <DialogTitle>
          <span className="font-bold text-2xl">Certificate Task</span>
        </DialogTitle>
        <Divider light />
        <DialogContent>{displayContent()}</DialogContent>
        <Divider light />
        <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
          <Box
            sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}
          >
            <Button
              variant="contained"
              onClick={handleCancelClick}
              color="secondary"
            >
              Close
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <GenericModal
        okButtonProps={{
          variant: 'contained',
        }}
        maxWidth="sm"
        onCancel={handleCompleteTaskCancel}
        onOk={handleCompleteTaskConfirm}
        okText="Yes"
        cancelText="No"
        visible={completeModalVisible}
        title="Complete Task"
      >
        {cancelContent()}
      </GenericModal>

      <Snackbar autoHideDuration={2000} onClose={onHiding}>
        <Alert severity={snackbar.type}>{snackbar?.message}</Alert>
      </Snackbar>
    </>
  );
};

export default CompleteCertificateTaskModal;
