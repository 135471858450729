import { gql, useQuery } from '@apollo/client';
import { Currency, QueryCurrenciesArgs, SortEnumType } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';

export const GET_CURRENCIES = gql`
  query GetCurrencies($order: [CurrencySortInput!]) {
    currencies(order: $order) {
      id
      abbreviation
      curr
    }
  }
`;

interface CurrenciesResult {
  data: Currency[];
  loading: boolean;
}

export const useGetCurrencies = (): CurrenciesResult => {
  const { data, loading, error } = useQuery(GET_CURRENCIES, {
    variables: { order: { curr: SortEnumType.Asc } },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    logger('GetCurrencies').error('Error fetching currencies -->', error.message);
  }

  return {
    data: data?.currencies || [],
    loading,
  };
};
