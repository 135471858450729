import React, { FC, useEffect, useState } from 'react';
import { Alert, Button } from '@mui/material';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
//import {useUpdateAvailableStatus} from "../../serviceWorkerUpdateAvailable";
import { useAuth } from 'src/contexts/auth';
import { LoadingButton } from '@mui/lab';
import { useRegisterSW } from 'virtual:pwa-register/react';
import { pwaInfo } from 'virtual:pwa-info';

console.log(pwaInfo);

interface InjectedProps {
  className?: string;
}

const UpdateAvailableReminder: FC<InjectedProps> = ({ className }) => {
  //const { updateAvailable } = useUpdateAvailableStatus();
  //const { refreshApplication } = useAuth();
  //const [isServiceWorkerInstalled, setIsServiceWorkerInstalled] = useState<boolean>(false);

  // How often to check for service worker updates in the background
  const swUpdateInterval = 5 * 60 * 1000;

  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swUrl, r) {
      if (swUpdateInterval <= 0) return;
      if (r?.active?.state === 'activated') {
        registerPeriodicSync(swUpdateInterval, swUrl, r);
      } else if (r?.installing) {
        r.installing.addEventListener('statechange', (e) => {
          const sw = e.target as ServiceWorker;
          if (sw.state === 'activated')
            registerPeriodicSync(swUpdateInterval, swUrl, r);
        });
      }
    },
  });

  const onRefresh = async () => {
    updateServiceWorker(true);
  };

  if (!needRefresh) return null;

  return (
    <div className={className}>
      <Alert
        severity="info"
        action={
          <Button
            onClick={onRefresh}
            variant="outlined"
            size="small"
            startIcon={<BrowserUpdatedIcon />}
          >
            Update
          </Button>
        }
      >
        There is a newer version of this app available!
      </Alert>
    </div>
  );
};

export default UpdateAvailableReminder;

/**
 * This function will register a periodic sync check every hour, you can modify the interval as needed.
 */
function registerPeriodicSync(
  period: number,
  swUrl: string,
  r: ServiceWorkerRegistration
) {
  if (period <= 0) return;

  setInterval(async () => {
    if ('onLine' in navigator && !navigator.onLine) return;

    const resp = await fetch(swUrl, {
      cache: 'no-store',
      headers: {
        cache: 'no-store',
        'cache-control': 'no-cache',
      },
    });

    if (resp?.status === 200) await r.update();
  }, period);
}
