import { RxCollection, RxJsonSchema, RxDocument } from 'rxdb';
import { TblSettingsApplication } from 'src/generated/graphql';

export type SettingsApplicationDocType = TblSettingsApplication;

export type SettingsApplicationDocMethods = {};

export type SettingsApplicationDocument = RxDocument<
  SettingsApplicationDocType,
  SettingsApplicationDocMethods
  >;

export type SettingsApplicationCollectionMethods = {};

export type SettingsApplicationCollection = RxCollection<
  SettingsApplicationDocType,
  SettingsApplicationDocMethods,
  SettingsApplicationCollectionMethods
  >;

export const schema: RxJsonSchema<SettingsApplicationDocType> = {
  title: 'SettingsApplication ',
  description: 'Settings Application',
  version: 1,
  keyCompression: false,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    PKey: {
      type: 'string',
      primary: true,
    },
    fldCrewID: {
      type: ['string', 'null'],
      ref: 'crew',
    },
    fldPref: {
      type: ['string', 'null'],
    },
    fldStatus: {
      type: ['boolean', 'null'],
    },
    fldSetting: {
      type: ['string', 'null'],
    },
    fldCallingRoutine: {
      type: ['string', 'null'],
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
    updatedAt: {
      type: 'string',
    },
  },
  indexes: [
    'PKey', 'updatedAt',
  ],
  additionalProperties: false,
};

export default schema;
