// React components
import { FC } from 'react';
import { useForm } from 'react-hook-form';

// MUI components
import { Button, DialogTitle, Dialog, DialogActions, DialogContent, Box } from '@mui/material';

// TDI components
import Input from 'src/components/UI/Forms/Input';

// Utils
import { CHAR_LIMIT } from 'src/consts';
import { handleCharLimitWarning } from 'src/utils';
import { getDatabase } from 'src/rxdb';
import { isEmpty } from 'lodash';
import { InventoryDocument } from 'src/pages/InventoryPage/rxdb';

export enum BarcodeActionType {
  Add = 'Add',
  Remove = 'Remove',
}
interface InjectedProps {
  visible: boolean;
  onCancel?: () => void;
  onSave: (item: any) => void;
  inventory: InventoryDocument;
  type: string;
}

const BarCode: FC<InjectedProps> = ({
  visible,
  onCancel,
  onSave,
  inventory,
  type,
}) => {
  const { 
    control, 
    handleSubmit, 
    reset 
  } = useForm<any>({ 
    defaultValues: { fldTritonBC: null } 
  });
  const { INVBARCODES } = CHAR_LIMIT;
  const handleCancel = () => {
    reset();
    onCancel && onCancel();
  };

  const handleSave = async (data: any) => {
    onSave(data);
    reset();
  };

  const validateBarcode = async ( inputBarcode: string ): Promise<string | undefined> => {
    const db = await getDatabase();

    const barcodeExists = async (selector: any): Promise<boolean> => {
      const items = await db.invbarcodes.find({ selector }).exec();
      return items.length !== 0;
    };
  
    const isBarcodeAssigned = async (selector: any): Promise<boolean> => {
      const item = await db.invbarcodes.findOne({ selector }).exec();
      return !!item;
    };
  
    if (type === BarcodeActionType.Add) {
      const selector = {
        fldTritonBC: inputBarcode.toUpperCase(),
        deletedAt: null,
      };

      if (await barcodeExists(selector)) {
        return `Barcode "${inputBarcode}" already exists, use the barcode search to find the related inventory.`;
      }
    }
  
    if (type === BarcodeActionType.Remove) {
      const selector = {
        ProductID: inventory.ProductID,
        fldTritonBC: inputBarcode.toUpperCase(),
        deletedAt: null,
      };

      if (!(await isBarcodeAssigned(selector))) {
        return `Barcode "${inputBarcode}" is not assigned to ${inventory.ProductName}, use the barcode search to find the related inventory.`;
      }
    }
  };

  return (
    <form className="relative bg-white flex-grow">
      <Dialog
        scroll="paper"
        fullWidth
        maxWidth="sm"
        open={visible}
        onClose={handleCancel}
      >
        <DialogTitle>
          <span className="font-bold text-2xl">{`${type} Bar Code`}</span>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 4 }}>
          <div>
            <span>
            <span>
              {`Enter the barcode you would like to ${type === 'Add' ? 'add to' : 'remove from'} "${inventory.ProductName}".`}
            </span>

            </span>
            <div className="pt-5">
              <Input
                control={control}
                name="fldTritonBC"
                rules={{ 
                  required: true, 
                  maxLength: INVBARCODES.fldTritonBC,
                  validate: async (value) => {
                    const str = await validateBarcode(value);
                    if (!isEmpty(str)) {
                      return str;
                    }
                  } 
                }}
                warning={(value) => handleCharLimitWarning(value, INVBARCODES.fldTritonBC)}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
          <Box
            sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}
          >
            <Button
              onClick={handleSubmit(handleSave)}
              className="mr-3"
              variant="contained"
            >
              Ok
            </Button>
            <Button className="mr-2" onClick={handleCancel}>
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default BarCode;
