import { RxCollection, RxJsonSchema, RxDocument } from 'rxdb';
import { TblShipsParticulars } from '../../../generated/graphql';

export type TblShipsParticularsDocType = TblShipsParticulars;

export type TblShipsParticularsDocMethods = {};

export type TblShipsParticularsDocument = RxDocument<
  TblShipsParticularsDocType,
  TblShipsParticularsDocMethods
>;

export type TblShipsParticularsCollectionMethods = {};

export type TblShipsParticularsCollection = RxCollection<
  TblShipsParticularsDocType,
  TblShipsParticularsDocMethods,
  TblShipsParticularsCollectionMethods
>;

export const tblShipsParticularsSchema: RxJsonSchema<TblShipsParticularsDocType> = {
  title: 'tblShipsParticulars ',
  description: 'describes a tblShipsParticulars item',
  version: 1,
  keyCompression: false,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    PKey: {
      type: 'string',
      primary: true,
    },
    fldDataVersion: {
      type: ['number', 'null'],
    },
    fldDBaseID: {
      type: ['number', 'null'],
    },
    fldNextReplica: {
      type: ['number', 'null'],
    },
    fldRunMaint: {
      type: ['string', 'null'],
    },
    fldMaintPrompt: {
      type: ['string', 'null'],
    },
    fldMaintPromptPeriod: {
      type: ['number', 'null'],
    },
    fldMonitoringSystem: {
      type: ['string', 'null'],
    },
    fldTemplate: {
      type: ['boolean', 'null'],
    },
    fldDeliveryDate: {
      type: ['string', 'null'],
    },
    fldRegNum: {
      type: ['string', 'null'],
    },
    fldVesselName: {
      type: ['string', 'null'],
    },
    fldAdminName: {
      type: ['string', 'null'],
    },
    fldCallSign: {
      type: ['string', 'null'],
    },
    fldVesselType: {
      type: ['string', 'null'],
    },
    fldOfficialNum: {
      type: ['string', 'null'],
    },
    fldFlag: {
      type: ['string', 'null'],
    },
    fldPortOfReg: {
      type: ['string', 'null'],
    },
    fldMMSI: {
      type: ['string', 'null'],
    },
    fldAAIC: {
      type: ['string', 'null'],
    },
    fldEngineType: {
      type: ['string', 'null'],
    },
    fldMaxOut: {
      type: ['string', 'null'],
    },
    fldProps: {
      type: ['string', 'null'],
    },
    fldPower: {
      type: ['string', 'null'],
    },
    fldShorePower: {
      type: ['string', 'null'],
    },
    fldRudderType: {
      type: ['string', 'null'],
    },
    fldGroundTackle: {
      type: ['string', 'null'],
    },
    fldGrossTon: {
      type: ['number', 'null'],
    },
    fldNet: {
      type: ['number', 'null'],
    },
    fldMaxDisplacement: {
      type: ['number', 'null'],
    },
    fldFuelCapacity: {
      type: ['number', 'null'],
    },
    fldWaterCapacity: {
      type: ['number', 'null'],
    },
    fldLubeOil: {
      type: ['number', 'null'],
    },
    fldDirtyLube: {
      type: ['number', 'null'],
    },
    fldLOA: {
      type: ['number', 'null'],
    },
    fldLWaterline: {
      type: ['number', 'null'],
    },
    fldBeamMould: {
      type: ['number', 'null'],
    },
    fldDepth: {
      type: ['number', 'null'],
    },
    fldDraftAftLoad: {
      type: ['number', 'null'],
    },
    fldDraftForeLoad: {
      type: ['number', 'null'],
    },
    fldHeight: {
      type: ['number', 'null'],
    },
    fldMaxSpeed: {
      type: ['number', 'null'],
    },
    fldMaxCrew: {
      type: ['number', 'null'],
    },
    fldGuests: {
      type: ['number', 'null'],
    },
    fldMaxAngle: {
      type: ['number', 'null'],
    },
    fldHardOver1: {
      type: ['number', 'null'],
    },
    fldHardOver2: {
      type: ['number', 'null'],
    },
    fldBareSteer: {
      type: ['number', 'null'],
    },
    fldRudderAngle1Prop: {
      type: ['number', 'null'],
    },
    fldAnchorWeight: {
      type: ['number', 'null'],
    },
    fldChainLenPort: {
      type: ['number', 'null'],
    },
    fldChainLenStbd: {
      type: ['number', 'null'],
    },
    fldChainStern: {
      type: ['number', 'null'],
    },
    fldChainMeasure: {
      type: ['string', 'null'],
    },
    fldTurnInitSpeedStbd: {
      type: ['number', 'null'],
    },
    fldTurnS90Speed: {
      type: ['number', 'null'],
    },
    fldTurnS90Time: {
      type: ['number', 'null'],
    },
    fldTurnS180Speed: {
      type: ['number', 'null'],
    },
    fldTurnS180Time: {
      type: ['number', 'null'],
    },
    fldTurnS270Speed: {
      type: ['number', 'null'],
    },
    fldTurnS270Time: {
      type: ['number', 'null'],
    },
    fldTurnS360Speed: {
      type: ['number', 'null'],
    },
    fldTurnS360Time: {
      type: ['number', 'null'],
    },
    fldTurnP90Speed: {
      type: ['number', 'null'],
    },
    fldTurnP90Time: {
      type: ['number', 'null'],
    },
    fldTurnP180Speed: {
      type: ['number', 'null'],
    },
    fldTurnP180Time: {
      type: ['number', 'null'],
    },
    fldTurnP270Speed: {
      type: ['number', 'null'],
    },
    fldTurnP270Time: {
      type: ['number', 'null'],
    },
    fldTurnP360Speed: {
      type: ['number', 'null'],
    },
    fldTurnP360Time: {
      type: ['number', 'null'],
    },
    fldTotAdvS: {
      type: ['number', 'null'],
    },
    fldTotAdvSDist: {
      type: ['number', 'null'],
    },
    fldAdvS: {
      type: ['number', 'null'],
    },
    fldAdvSDist: {
      type: ['number', 'null'],
    },
    fldXferS: {
      type: ['number', 'null'],
    },
    fldXferSDist: {
      type: ['number', 'null'],
    },
    fldTactDiaS: {
      type: ['number', 'null'],
    },
    fldTactDiaSDist: {
      type: ['number', 'null'],
    },
    fldDiaS: {
      type: ['number', 'null'],
    },
    fldDiaSDist: {
      type: ['number', 'null'],
    },
    fldTotAdvP: {
      type: ['number', 'null'],
    },
    fldTotAdvPDist: {
      type: ['number', 'null'],
    },
    fldAdvP: {
      type: ['number', 'null'],
    },
    fldAdvPDist: {
      type: ['number', 'null'],
    },
    fldXferP: {
      type: ['number', 'null'],
    },
    fldXferPDist: {
      type: ['number', 'null'],
    },
    fldTactDiaP: {
      type: ['number', 'null'],
    },
    fldTactDiaPDist: {
      type: ['number', 'null'],
    },
    fldDiaP: {
      type: ['number', 'null'],
    },
    fldDiaPDist: {
      type: ['number', 'null'],
    },
    fldBowThrustPwr: {
      type: ['number', 'null'],
    },
    fldBowThstDelay: {
      type: ['number', 'null'],
    },
    fldBowTurnRate0: {
      type: ['number', 'null'],
    },
    fldBowThrstDelayRev: {
      type: ['number', 'null'],
    },
    fldBowMaxSpeedEffective: {
      type: ['number', 'null'],
    },
    fldSternThrustPwr: {
      type: ['number', 'null'],
    },
    fldSternThstDelay: {
      type: ['number', 'null'],
    },
    fldSternTurnRate0: {
      type: ['number', 'null'],
    },
    fldSternThrstDelayRev: {
      type: ['number', 'null'],
    },
    fldSternMaxSpeedEffective: {
      type: ['number', 'null'],
    },
    fldAgentAddress: {
      type: ['string', 'null'],
    },
    fldShippingAddress: {
      type: ['string', 'null'],
    },
    fldDPAName: {
      type: ['string', 'null'],
    },
    fldDPACompany: {
      type: ['string', 'null'],
    },
    fldDPAAddress: {
      type: ['string', 'null'],
    },
    fldDPAPhone: {
      type: ['string', 'null'],
    },
    fldDPAFax: {
      type: ['string', 'null'],
    },
    fldDPAEmail: {
      type: ['string', 'null'],
    },
    fldSMSTracking: {
      type: ['number', 'null'],
    },
    fldSMSFile: {
      type: ['string', 'null'],
    },
    fldSMSNoControl: {
      type: ['boolean', 'null'],
    },
    DateEntered: {
      type: ['string', 'null'],
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
    updatedAt: {
      type: 'string',
    },
  },
  required: [],
  additionalProperties: false,
};

export const tblShipsParticularsDocMethods: TblShipsParticularsDocMethods = {};

export const tblShipsParticularsCollectionMethods: TblShipsParticularsCollectionMethods = {};
