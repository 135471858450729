import React, {FC} from 'react';
import {Box, LinearProgress} from "@mui/material";
import {AccessTime, Cancel, CheckCircle} from "@mui/icons-material";
import Grid from "@mui/material/Grid";

interface InjectedProps {
  completed: boolean;
  loading: boolean;
  error?: string;
  failed: boolean;
  title: string;
}

const ReplicationProgressItem: FC<InjectedProps> = ({ completed, loading, error, failed, title }) => {
  return (
    <Grid item xs={12} md={3}>
      <Box
        sx={{ px: 1, mb: 1 }}
        className="flex items-center w-full flex-grow"
      >
        <div className="flex flex-row items-center my-2 w-full">
          {/* <span className="text-5xl">
              <ViewInAr fontSize="inherit" />
            </span> */}
          <div className="ml-4 mr-6 w-full">
            <p className="mb-2 text-xs">{`${title}...`}</p>
            <LinearProgress
              value={completed ? 100 : 0}
              variant={loading ? 'indeterminate' : 'determinate'}
            />

            {error && (
              <p className="text-xs mt-2" style={{ color: '#f60000' }}>
                {error}
              </p>
            )}
          </div>
        </div>

        {completed && <CheckCircle fontSize="small" color="primary" />}

        {failed && <Cancel fontSize="small" color="error" />}

        {loading && <AccessTime fontSize="small" />}
      </Box>
    </Grid>
  )
};

export default ReplicationProgressItem;
