import { SvgIcon } from '@mui/material';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import SettingsIcon from '@mui/icons-material/Settings';
import ListAltIcon from '@mui/icons-material/ListAlt';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import Tools from '../../../assets/icon-tools.svg';
import ToolsGrey from '../../../assets/icon-tools-grey.svg';
import Inventory from '../../../assets/icon-spares-inventory.svg';
import Data from '../../../assets/icon-data.svg';
import Dashboard from '../../../assets/icon-dashboard.svg';
import Account from '../../../assets/icon-account.svg';
import Log from '../../../assets/icon-log.svg';
import Task from '../../../assets/icon-tasks.svg';
import Documents from '../../../assets/icon-drawings-documents.svg';
import { ICON } from '../../../consts';
import { ShoppingCart } from '@mui/icons-material';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';

export const getIcon = (label: string, className?: string) => {
  switch (label) {
    case ICON.HOME:
      return <img className={className} src={Dashboard} alt="" />;
    case ICON.TASKS:
      return <img className={className} src={Task} alt="" />;
    case ICON.PURCHASING:
      return <ShoppingCart sx={{ color: "#fff" }} />;
    case ICON.LOGS:
      return <img className={className} src={Log} alt="" />;
    case ICON.ALL_TASKS:
      return null;
    case ICON.CHECKLISTS:
      return null;
    case ICON.ACCOUNTS:
      return <img className={className} src={Account} alt="" />;
    case ICON.EQUIPMENT:
      if(className === 'GREY') return <img className={className} src={ToolsGrey} alt="" />;
      return <img className={className} src={Tools} alt="" />;
    case ICON.MAINTENANCE_LOG:
      return null;
    case ICON.ENGINEERING_ROUNDS:
      return null;
    case ICON.CERTIFICATES:
      return null;
    case ICON.DWR:
      return null;
    case ICON.SETTINGS:
      return <SettingsIcon className={className} />;
    case ICON.YMS_SETTINGS:
      return <SettingsIcon className={className} />;
    case ICON.EVENTS_SCHEDULES:
      return <EventRepeatIcon className={className} />;
    case ICON.LIST_UTILITY:
      return <ListAltIcon className={className} />;
    case ICON.CATEGORY_UTILITY:
      return <ListAltIcon className={className} />;
    case ICON.LOCATIONS_UTILITY:
      return <ListAltIcon className={className} />;
    case ICON.RUNNING_LOG_GROUPS_UTILITY:
      return <ListAltIcon className={className} />;
    case ICON.RECYCLE_BIN:
      return <DeleteSweepIcon className={className} />;
    case ICON.CREW_GUEST_DATA:
      return <img className={className} src={Data} alt="" />;
    case ICON.SPARE_AND_INVENTORY:
      return <img className={className} src={Inventory} alt="" />;
    case ICON.SMS_FORMS:
    case ICON.ISPS_FORMS:
      return <DocumentScannerIcon className={className} />;
    case ICON.LIBRARIES:
      return <img className={className} src={Documents} alt="" />;
    case ICON.REPORT_ISSUES:
      return <BugReportOutlinedIcon className={className} />;
    case ICON.CRITICAL_SYSTEM_REPORT:
      return null;
    case ICON.MAINTENANCE_REPORT:
      return null;
    case ICON.FORECASTING:
      return null;
    default:
  }
};
