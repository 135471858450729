import { gql, LazyQueryExecFunction, OperationVariables, useLazyQuery  } from "@apollo/client";
import { Equipment, QueryEquipmentArgs, SortEnumType } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";

export const GET_EQUIPMENT_LIST = gql`
  query GetEquipmentList($where: EquipmentFilterInput, $order: [EquipmentSortInput!]) {
    equipment(where: $where, order: $order) {
      eqKey
      fldCountHours
      fldHoursOffset
      fldLocHierarchy
      fldSrhkey
      hours
      uniqueName
    }
  }
`
interface EquipmentListData {
  equipment: Equipment[];
}

interface EquipmentListResult {
  getEquipmentList: LazyQueryExecFunction<EquipmentListData, OperationVariables>;
  loading: boolean;
}

export const useGetEquipmentList = ({ where }: QueryEquipmentArgs): EquipmentListResult => {

  const [ getEquipmentList, { loading, error }] = useLazyQuery(GET_EQUIPMENT_LIST, {
    variables: { 
      where, 
      order: { uniqueName: SortEnumType.Asc } },
    notifyOnNetworkStatusChange: true,
  });
  
  if (error) {
    logger('GetEquipmentList').error('Error fetching equipment list for dropdown -->', error.message);
  }

  return {
    getEquipmentList: getEquipmentList,
    loading
  };
};