import { isEmpty, isNil } from 'lodash';

export const validateForm = (data: any, setSnackbar: any) => {
  const { Subject, fldSRHKey, Department } = data;

  if (isNil(Subject) || isEmpty(Subject) || isNil(fldSRHKey) || isNil(Department)) {
    setSnackbar({
      open: true,
      message: 'Please fill required field(s). Check form field(s) marked with red color',
      type: 'error',
    });
    return false;
  }
  return true;
};