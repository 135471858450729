import React, { FC, useEffect, useState } from 'react';
import { Control, UseFormWatch } from 'react-hook-form';
import { isNil } from 'lodash';

// MUI Components
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

// TDI Componenents
import Checkbox from 'src/components/UI/Forms/Checkbox';
import Input from 'src/components/UI/Forms/Input';
import DatePicker from 'src/components/UI/Forms/DatePicker';
import { OrdersDocument } from 'src/rxdb/collections/Orders/schema';
import RichTextEditor from '../../../UI/Forms/RichTextEditor';
import Autocomplete from '../../../UI/Forms/Autocomplete';
import OrderItemsForm from 'src/components/dataentry/orderItemsForm';
import Select from '../../../UI/Forms/Select';

// Utilities
import { toJSON } from '../../../../utils';

// Data
import { getDatabase } from '../../../../rxdb';
import { TblDdListDefaultsDocument } from '../../../../api/queries/tblDDListDefaults/rxdb';
import { CrewDocument } from '../../../../rxdb/collections/Crew/schema';
import { Orders } from '../../../../generated/graphql';
import { useAppState } from 'src/contexts/app-state';
import { isValidDateFormat } from 'src/utils/format-dates';

interface OrdersGenSummaryProps {
  form: Orders;
  initialValue: OrdersDocument;
  onChange: (field: string, value: any) => void;
  control: Control;
  watch: UseFormWatch<any>;
  getValues: any;
  setPOItems: (data: any) => void;
  onOrderItemUpdate: (isUpdate: boolean) => void;
  disableEdit? : boolean;
  editFlag?: boolean;
}

const OrdersGenSummary: FC<OrdersGenSummaryProps> = ({
  form,
  onChange,
  initialValue,
  control,
  watch,
  getValues,
  setPOItems,
  onOrderItemUpdate,
  disableEdit = false,
  editFlag = false,
}) => {
  const [departments, setDepartments] = useState<TblDdListDefaultsDocument[]>([]);
  const [statuses, setStatuses] = useState<TblDdListDefaultsDocument[]>([]);
  const [orderedBy, setOrderedBy] = useState<CrewDocument[]>([]);
  const init = async () => {
    const db = await getDatabase();

    db.tblddlistdefaults.find({ selector: { deletedBy: { $eq: null } }}).$.subscribe((defaults) => {
      const departments = defaults.filter((d) => d.fldListName === 'Department' && d.fldMember !== "");
      const statuses = defaults.filter((d) => d.fldListName === 'Order Status' && d.fldMember !== "");
      setDepartments(toJSON(departments));
      setStatuses(toJSON(statuses));
    });

    db.crew.find({selector: {      
      $and: [
        { fldIsGuest: false},
        { DateFire: { $eq: null }},
        { deletedBy: { $eq: null }},
      ],
    }}).$.subscribe((data: any) => {
      const crewProfileData = data.map((item: any) => ({
        fldCrewID: item.fldCrewID,
        name: `${item.fldFirst} ${item.fldLast}`,
      }));
      setOrderedBy(crewProfileData);
    });
  };

  useEffect(() => {
    if (isNil(initialValue)) return;

    init();
  }, [initialValue]);

  // TODO ??Shouldn't this be it's own component?  It just gets used over and over
  const renderListDefaultBox = (
    items: TblDdListDefaultsDocument[],
    fieldName: string,
    fldListName: string,
    freeSolo = false,
    customListName: any = null
  ) => (
    <Autocomplete
      defaultPayload={{
        fldListName,
        fldT4Reqd: true,
      }}
      displayExpr="fldMember"
      keyExpr="PKey"
      onChange={onChange}
      freeSolo={freeSolo}
      options={items}
      label={customListName || fldListName}
      control={control}
      name={fieldName}
      disabled={fldListName === "Department" ? disableEdit: false}
      collection="tblddlistdefaults"
    />
  );

  const onOrderItemUpdated = (isUpdated: boolean) => {
    if (isUpdated) {
      onOrderItemUpdate(isUpdated);
    }
  };

  return (
    <div className="mt-3">
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="general-information"
          id="general-information"
        >
          General Information
        </AccordionSummary>
        <AccordionDetails>
          <div className="w-full flex flex-col">
            <div className="pt-4">
              <div className="w-full flex flex-col">
                <FormGroup row>
                  <DatePicker
                    name="OrderDate"
                    control={control}
                    label="Order Date"
                    rules={{
                      required: '*Date value is required for this field!',
                      validate: (value) => isValidDateFormat(value) || 'Please enter a valid date format dd-MMM-yyyy',
                    }}
                  />

                  <div className="ml-5 flex-grow">
                    {renderListDefaultBox(
                      statuses,
                      'fldStatus',
                      'Order Status',
                      false
                    )}
                  </div>
                </FormGroup>
              </div>
            </div>

            <div className="mt-3">
              {/* <Select
                keyExpr="name"
                valueExpr="name"
                name="OrderedBy"
                label="Ordered By"
                displayExpr="name"
                control={control}
                options={orderedBy}
              /> */}
              <Autocomplete
                onChange={onChange}
                label='Ordered By'
                options={orderedBy}
                keyExpr='name'
                displayExpr="name"
                name={"OrderedBy"}
                control={control}
                freeSolo={true}
              />
            </div>

            <div className="mt-3">
              {renderListDefaultBox(
                departments,
                'Department',
                'Department',
                true
              )}
            </div>
          </div>

          {/* TODO add fldHTML */}
          <div className="my-4">
            <RichTextEditor
              control={control}
              name="fldHTML"
              onChange={onChange}
            />
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded className="mt-3">
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="Items On Order"
          id="itemsOnOrder"
        >
          Items List
        </AccordionSummary>

        <AccordionDetails>
          <div>
            <OrderItemsForm
              form={form}
              onChange={onChange}
              initialValue={initialValue}
              control={control}
              watch={watch}
              getValues={getValues}
              setPOItems={setPOItems}
              onSave={onOrderItemUpdated}
              editFlag={editFlag}
              disableEdit={disableEdit}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default OrdersGenSummary;
