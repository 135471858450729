declare global {
    interface Window {
        env: any;
    }
}

type EnvType = {
    REACT_APP_NODE_WS_URL: string;
    REACT_APP_NODE_URL: string;
    REACT_APP_DOTNET_URL: string;
    REACT_APP_SYNCFUSION_URL: string;
    REACT_APP_SENTRY_DSN: string;
    REACT_APP_SENTRY_ENVIRONMENT: string;
};

const env: EnvType = { ...process.env, ...window.env };
export default env;