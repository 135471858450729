import { RxCollection, RxJsonSchema, RxDocument } from 'rxdb';
import { TblDdListDefaults } from '../../../generated/graphql';

export type TblDdListDefaultsDocType = TblDdListDefaults;

export type TblDdListDefaultsDocMethods = {};

export type TblDdListDefaultsDocument = RxDocument<
  TblDdListDefaultsDocType,
  TblDdListDefaultsDocMethods
>;

export type TblDdListDefaultsCollectionMethods = {};

export type TblDdListDefaultsCollection = RxCollection<
  TblDdListDefaultsDocType,
  TblDdListDefaultsDocMethods,
  TblDdListDefaultsCollectionMethods
>;

export const tblDDListDefaultsSchema: RxJsonSchema<TblDdListDefaultsDocType> = {
  title: 'tblDDListDefaults ',
  description: 'describes a tblDDListDefaults item',
  version: 1,
  keyCompression: false,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    PKey: {
      type: 'string',
      primary: true,
    },
    fldListName: {
      type: ['string', 'null'],
    },
    fldMember: {
      type: ['string', 'null'],
    },
    fldT4Reqd: {
      type: ['boolean', 'null'],
    },
    fldIndex: {
      type: ['number', 'null'],
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
    updatedAt: {
      type: 'string',
    },
  },
  required: [],
  additionalProperties: false,
};

export const tblDDListDefaultsDocMethods: TblDdListDefaultsDocMethods = {};

export const tblDDListDefaultsCollectionMethods: TblDdListDefaultsCollectionMethods = {};
