import { isEmpty, isNil } from 'lodash';
import { TblSystemsHierarchyState } from '../../Dropdowns/CategoryDropdown/store';
import { Certificate } from '../../../generated/graphql';

export const getNodePathByNode = (curNode: any, list: any) => {
  if (isEmpty(curNode) || isNil(curNode)) return null;

  const nodeKeyList: string[] = [];
  nodeKeyList.unshift(curNode?.PKey);
  let node = curNode;

  while (node?.ParentID != null) {
    const temp = list.find((l: any) => l?.PKey === node?.ParentID);
    if (temp) {
      nodeKeyList.unshift(temp?.PKey);
    }
    node = temp;
  }
  return nodeKeyList;
};

export const certToEditFormData = (
  type: string,
  certificate: any,
  tblsystemshierarchy: TblSystemsHierarchyState,
) => {
  const { values: categoryList } = tblsystemshierarchy;

  // TODO: will modify to existingEquipment?.fldSRHKey, below is hard-coded fldSRHKey
  const existingCategory = categoryList.find(
    (v: any) => v.PKey === '0AAA2FA5-4AD4-4318-AEDD-C191200B8714',
  );

  if (type === 'new') {
    return {
      ...defaultCertificate,
      ...certificate,
      PKey: null,
      DateIssue: null,
      DateExpire: null,
      fldCertNum: null,
      category: getNodePathByNode(existingCategory, categoryList),
    };
  }

  return {
    ...certificate,
    category: getNodePathByNode(existingCategory, categoryList),
  };
};

export const defaultCertificate: Certificate = {
  autoId: 0,
  DateEntered: null,
  DateExpire: null,
  DateIssue: null,
  PKey: '',
  fldArchive: false,
  fldCertNum: null,
  fldCertType: null,
  fldCrewID: null,
  fldIssue: null,
  fldNationality: null,
  fldNotes: null,
  fldResponsible: null,
  fldSMS: false,
  fldSRHKey: null,
  fldTimeWarn: null,
  fldTimeWarnMeasure: null,
  fldType: null,
  updatedAt: null,
};

export const validateForm = (data: any, setSnackbar: any, certificateType:any) => {
  const { fldType, fldSRHKey, fldResponsible, fldCrewID } = data;
  if(certificateType === 'CREWPROFILE'){
    if (isNil(fldType) || isEmpty(fldType) || isNil(fldSRHKey) ) {
      setSnackbar({
        open: true,
        message: 'Please fill required field(s). Check form field(s) marked with red color',
        type: 'error',
      });
      return false;
    }
  }

  if(certificateType=== 'LIBRARY'){
    if (isNil(fldType) || isEmpty(fldType) || isNil(fldSRHKey) ) {
      setSnackbar({
        open: true,
        message: 'Please fill required field(s). Check form field(s) marked with red color',
        type: 'error',
      });
      return false;
    }
  }

  return true;
};
