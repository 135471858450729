// React Components
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';

// MUI components
import { Button, DialogTitle, Dialog, DialogActions, DialogContent, Box, Snackbar, Alert } from '@mui/material';

// TDI components
import Input from 'src/components/UI/Forms/Input';

// Data
import { getDatabase } from 'src/rxdb';
import { OrderItemsDocument } from 'src/rxdb/collections/OrderItems/schema';

//Utils
import { normalizeDateFormValue } from 'src/helpers';

interface InjectedProps {
    visible: boolean;
    initialValue: any;
    storageInitialValue: any;
    onCancel?: () => void;
    onSave?: () => void;
    title?: string;
    selectedItemOrderID?: string;
}

const InvStorageAddAmountDialog: FC<InjectedProps> = ({
    visible,
    onCancel,
    initialValue,
    storageInitialValue,
    onSave,
    title,
    selectedItemOrderID = "",
}) => {
    const {
        control,
        setValue,
        handleSubmit,
        getValues,
        reset,
        watch,
        formState,
    } = useForm<any>({
        defaultValues: {
            fldLocHierarchy: storageInitialValue.fldLocHierarchy,
            SerialNum: storageInitialValue.SerialNum,
            Amount: 0,
            fldMaxHldgLoc: storageInitialValue.fldMaxHldgLoc,
            UseBy: normalizeDateFormValue(storageInitialValue.UseBy),
            fldDefault: storageInitialValue.fldDefault,
            PKey: storageInitialValue.PKey,
        },
    });
    const [snackBar, setSnackbar] = useState({
        open: false,
        type: 'success',
        message: '',
    });

    const onSnackbarClose = () => {
        setSnackbar({
            open: false,
            message: '',
            type: 'success',
        });
    };
    const handleCancel = () => {
        onCancel && onCancel();
    };

    const handleSave = async (data: any) => {
        const db = await getDatabase();
        const { PKey, Amount } = data;

        let orderitem: OrderItemsDocument[] = [];
        let OrderID: string = initialValue.OrderID ?? selectedItemOrderID ?? "";
        let selector: any = {
            ProductID: initialValue.ProductID,
            deletedBy: {
                $eq: null
            },
        }
        if (OrderID) {
            selector.OrderID = OrderID;
        }
        orderitem = await db.orderitems
            .find({
                selector: {
                    OrderID: OrderID,
                    ProductID: initialValue.ProductID,
                    deletedBy: {
                        $eq: null
                    },
                },
            })
            .exec() ?? [];

        const oldAmount: number = Number(storageInitialValue.Amount) ?? 0;

        const document = {
            Amount: parseFloat(Amount) + oldAmount || null,
            ProductID: initialValue.ProductID,
            PKey,
            updatedAt: new Date().toISOString(),
        } as any;

        const AmountValue = watch("Amount");
        try {
            const res = await db.tblmultiplelocations.upsert(document);
            if(orderitem.length > 0) {
                let AmtReceived: number = (Number(orderitem[0].AmtReceived) ?? 0) + Number(AmountValue);
                await orderitem[0].update({
                    $set: {
                        AmtReceived: AmtReceived,
                    }
                });
            }
            onSave && onSave();
            reset();
        } catch (e: any) {
            setSnackbar({
                open: true,
                type: 'error',
                message: e.message,
            });
        }
    };

    return (
        <>
            <form
                className="relative bg-white flex-grow"
            >
                <Dialog
                    scroll="paper"
                    fullWidth
                    maxWidth="md"
                    open={visible}
                    onClose={handleCancel}
                >
                    <DialogTitle>
                        <span className="font-bold text-2xl">{title}</span>
                    </DialogTitle>
                    <DialogContent dividers sx={{ p: 4 }}>
                        <div>
                            <div className="pt-5 w-1/4">
                                <Input
                                    name="Amount"
                                    inputProps={{
                                        label: 'Amount *',
                                        type: 'number',
                                        inputProps: {
                                            min: 0,
                                            inputMode: 'decimal',
                                            pattern: '([0-9]+)?[,\\.]?[0-9]*',
                                            step: 1,
                                            style: { textAlign: 'end' },
                                        },
                                    }}
                                    control={control}
                                    rules={{
                                        min: 0,
                                        required: true,
                                    }}
                                />
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
                        <Box
                            sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}
                        >
                            <Button
                                onClick={handleSubmit(handleSave)}
                                className="mr-3"
                                variant="contained"
                            >
                                Save
                            </Button>
                            <Button className="mr-2" onClick={handleCancel}>
                                Cancel
                            </Button>
                        </Box>
                    </DialogActions>
                </Dialog>
            </form>

            <Snackbar
                open={snackBar.open}
                autoHideDuration={2000}
                onClose={onSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
                    {snackBar.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default InvStorageAddAmountDialog;
