import React from 'react';
import { Control } from 'react-hook-form';
import AutocompleteInput from 'src/components/UI/Forms/AutocompleteInput';
import { BCLSizes } from 'src/consts';

interface BarcodeFormProps {
    options: string[];
    control: Control;
}

const BarcodeForm: React.FC<BarcodeFormProps> = ({ options, control }) => {
  return (
    <div>
      <div style={{ display: 'block' }}>
        <AutocompleteInput
          displayExpr="fldLocHierarchy"
          valueExpr="PKey"
          options={options ? options : []}
          label="Select Location"
          placeholder="Select Location"
          control={control}
          name="PKey"
        />
      </div>
      <div style={{ display: 'block', marginTop:'10px'}}>
        <AutocompleteInput
          displayExpr="labelSize"
          valueExpr="labelID"
          options={BCLSizes}
          label="Select Label Size"
          placeholder="Select Label Size"
          control={control}
          name="labelID"
        />
      </div>
    </div>
  );
};

export default BarcodeForm;
