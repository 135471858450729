import React, {
  useState,
  FC,
  useRef,
  useCallback,
  MutableRefObject,
} from 'react';
import { isEmpty, pick, size } from 'lodash';
import {
  Alert, Snackbar, IconButton, Button, useTheme, useMediaQuery,
} from '@mui/material';
import { getDatabase, TDIDb } from 'src/rxdb';
import {
  TypeComputedProps,
  TypeSortInfo,
} from '@inovua/reactdatagrid-enterprise/types';
import { DeleteTwoTone, Edit } from '@mui/icons-material';
import { EquipmentDocument } from 'src/pages/EquipmentPage/rxdb';
import LicensedReactDataGrid from 'src/components/UI/LicensedReactDataGrid';
import { InvEqDocument } from 'src/rxdb/collections/InvEq/schema';
import InvSapreEqEditDialog from './InvSapreEqEditDialog';
import InvSapreAddDialog from './InvSpareAddDialog';
import WarningDialog from 'src/components/UI/WarningDialog';
import './style.css';

interface Props {
  keyName?: string;
  keyValue: string | null;
  disableEdit: boolean;
}

const filter = [
  {
    name: 'UniqueName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'Manufacturer',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'ModelNumber',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  // { name: "ModelNumber", operator: "contains", type: "select", value: "" },
];

const transformData = async (item: any) => ({
  ...pick(item, ['EqKey', 'ProductID']),
  UniqueName: (await item.populate('EqKey'))?.UniqueName,
  Manufacturer: (await item.populate('EqKey'))?.Manufacturer,
  // fldPartNumber: (await item.populate("EqKey"))?.fldPartNumber,
  ModelNumber: (await item.populate('EqKey'))?.ModelNumber,
  original: item,
});

const getKeys = async (item: any) => {
  const n = { ...pick(item, ['EqKey']) };
  return [n.EqKey];
};

const getRegexByOperator = (operator: string, value: string) => {
  switch (operator) {
    case 'contains':
      return new RegExp(`.*${value}`, 'i');
    case 'startsWith':
      return new RegExp(`^${value}`, 'i');
    case 'endsWith':
      return new RegExp(`${value}$`, 'i');
    case 'eq':
      return new RegExp(`${value}`, 'i');
    default:
  }
};

const getSelectorByFilterName = async (
  name: string,
  value: string,
  operator: string,
  db: TDIDb,
) => {
  switch (name) {
    case 'UniqueName':
      const productname = await db.equipment
        .find({
          selector: {
            UniqueName: { $regex: getRegexByOperator(operator, value) },
          },
        })
        .exec();
      return {
        EqKey: { $in: productname.map((e) => e.primary) },
      };

    case 'Manufacturer':
      const manufacturers = await db.equipment
        .find({
          selector: {
            Manufacturer: { $regex: getRegexByOperator(operator, value) },
          },
        })
        .exec();
      return {
        EqKey: { $in: manufacturers.map((e) => e.primary) },
      };

    // case "fldPartNumber":
    //   const fldPartNumbers = await db.inventory
    //     .find({
    //       selector: {
    //         fldPartNumber: { $regex: getRegexByOperator(operator, value) },
    //       },
    //     })
    //     .exec();
    //   return {
    //     ProductID: { $in: fldPartNumbers.map((e) => e.primary) },
    //   };

    case 'ModelNumber':
      const modelNumbers = await db.equipment
        .find({
          selector: {
            ModelNumber: { $regex: getRegexByOperator(operator, value) },
          },
        })
        .exec();
      return {
        EqKey: { $in: modelNumbers.map((e) => e.primary) },
      };

    default:
      return {};
  }
};

const InvSpares: FC<Props> = ({ keyName, keyValue, disableEdit=false }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const highlightedRowId = useRef<string>('-1');
  const [gridRef, setGridRef] = useState<any>(null);
  const [selectedEquipment, setSelectedEquipment] = useState<EquipmentDocument>();
  const [editPopupVisible, setEditPopupVisible] = useState<boolean>(false);
  const [addPopupVisible, setAddPopupVisible] = useState<boolean>(false);
  const [gridData, setGridData] = useState<any>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteSelected, setDeleteSelected] = useState<any>();
  const isMobile = useMediaQuery('(max-width: 420px)')
  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const loadData = async ({
    skip,
    limit,
    filterValue,
  }: {
    sortInfo?: TypeSortInfo;
    skip?: number;
    limit?: number;
    filterValue?: any;
  }): Promise<{ data: any[]; count: number }> => {
    const db = await getDatabase();

    let selector = {
      ProductID: keyValue,
      deletedAt: {
        $eq: null
      },
    };

    await Promise.all(
      filterValue.map(async (v: any) => {
        if (isEmpty(v.value)) return v;

        const s = await getSelectorByFilterName(
          v.name,
          v.value,
          v.operator,
          db,
        );

        selector = {
          ...selector,
          ...s,
        };
        return v;
      }),
    );

    const items = await db.inveq
      .find({
        selector,
        skip,
        limit,
      })
      .exec();

    const length = size(items);

    const data = await Promise.all(items.map(transformData));

    const dataKey = await Promise.all(items.map(getKeys));
    let newDataKey: any = [];

    dataKey.map((a: any) => {
      newDataKey = [...newDataKey, ...a];
      setGridData(newDataKey);
    });

    return { data, count: length };
  };

  const init = async (ref: MutableRefObject<TypeComputedProps | null>) => {
    const db = await getDatabase();

    db.inveq.$.subscribe(async (ev) => {
      if (ev.operation === 'INSERT' || ev.operation === 'UPDATE') {
        highlightedRowId.current = ev.documentId;
        ref.current?.reload();
      }
      if (ev.operation === 'DELETE') {
        highlightedRowId.current = '-1';
        ref.current?.reload();
      }
    });
  };

  const onReady = (ref: MutableRefObject<TypeComputedProps | null>) => {
    setGridRef(ref);
    init(ref);
  };

  const onLoadingChange = (status: boolean) => {
    // If loading completed - check if there any items that needs to be highlighted.
    if (!status && highlightedRowId.current !== '-1') {
      gridRef?.current?.scrollToId(highlightedRowId.current);
      gridRef?.current?.setSelectedById(highlightedRowId.current, true);
      highlightedRowId.current = '-1';
    }
    setLoading(status);
  };

  const handleDelete = async (item: InvEqDocument) => {
    try {
      await item.remove();
      setSnackbar({
        open: true,
        message: 'Spare successfully removed',
        type: 'success',
      });
    } catch (e: any) {
      setSnackbar({
        open: true,
        message: e.message,
        type: 'error',
      });
    }
  };

  const rowDeletePress = (item: any) => {
    setDeleteSelected(item.original);
    setIsDeleting(true);
  };

  const handleDeleteOk = () =>{
    handleDelete(deleteSelected);
    setIsDeleting(false);
    setDeleteSelected(undefined);
  };

  const handleDeleteCancel = () =>{
    setIsDeleting(false);
    setDeleteSelected(undefined);
  };


  const rowEdit = async (item: any) => {
    const db = await getDatabase();

    const equipmentItem: any = await db.equipment
      .find({
        selector: {
          EqKey: item.EqKey,
        },
      })
      .exec();

    setSelectedEquipment(equipmentItem[0]);
    setEditPopupVisible(true);
  };

  const editSave = () => {
    setSelectedEquipment(undefined);
    setEditPopupVisible(false);
    setSnackbar({
      open: true,
      message: 'Equipment has been updated!',
      type: 'success',
    });
  };

  const editCancel = () => {
    setSelectedEquipment(undefined);
    setEditPopupVisible(false);
  };

  const handleAddClick = () => {
    setAddPopupVisible(true);
  };

  const addCancel = () => {
    setAddPopupVisible(false);
  };

  const addSave = () => {
    setAddPopupVisible(false);
    setSnackbar({
      open: true,
      type: 'success',
      message: 'Spares has been added!',
    });
  };

  const dataSource = useCallback(loadData, []);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const columns = [
    {
      name: 'UniqueName',
      header: 'Equipment',
      defaultFlex: 1,
    },
    {
      name: 'Manufacturer',
      header: 'Manufacturer',
      defaultFlex: 1,
      visible: !isMobile,
    },
    {
      name: 'ModelNumber',
      header: 'Model Number',
      defaultFlex: 1,
      visible: !isMobile,
    },
    {
      id: 'actions',
      header: 'Actions',
      defaultFlex: 0.35,
      render: ({ data }: any) => (
        <div>
          <IconButton
            onClick={() => rowEdit(data)}
            size="small"
            aria-label="Edit item"
            disabled={disableEdit}
          >
            <Edit fontSize="inherit" />
          </IconButton>
          <IconButton
            onClick={() => rowDeletePress(data)}
            size="small"
            color="error"
            aria-label="Delete item"
            disabled={disableEdit}
          >
            <DeleteTwoTone fontSize="inherit" />
          </IconButton>
        </div>
      ),
    },
  ];
 
  return (
    <>
      <div className="pt-5">
        <div className="flex justify-end mb-6">
          <Button 
            variant="contained" 
            onClick={handleAddClick} 
            disabled={disableEdit}
          >
            Add
          </Button>
        </div>

        <div data-testid="data-grid" className="flex flex-col flex-grow inv-spare">
          <LicensedReactDataGrid
            onLoadingChange={onLoadingChange}
            defaultLimit={100}
            livePagination
            onReady={onReady}
            rowHeight={40}
            loading={loading}
            defaultFilterValue={filter}
            idProperty="EqKey"
            // theme="default-dark"
            columns={columns}
            dataSource={dataSource}
          />
        </div>
      </div>
      <WarningDialog
        visible={isDeleting}
        title="Delete Warning"
        content="Are you sure you wish to delete record?"
        okText='Yes'
        color='error'
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />
      <InvSapreEqEditDialog
        visible={editPopupVisible}
        initialValue={selectedEquipment}
        onSave={editSave}
        onCancel={editCancel}
      />
      <InvSapreAddDialog
        initialValue={keyValue}
        visible={addPopupVisible}
        eqKeyData={gridData}
        onSave={addSave}
        onCancel={addCancel}
      />
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        onClose={onSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
          {snackBar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default InvSpares;
