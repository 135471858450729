import { TypeComputedProps } from '@inovua/reactdatagrid-enterprise/types';
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {
  IconButton, Link, Stack, Tooltip,
} from '@mui/material';
import { format } from 'date-fns';
import { isNil } from 'lodash';
import {
  default as React,
  FC,
  MutableRefObject,
  useEffect,
  useState,
} from 'react';
import { uuid } from 'uuidv4';
import WarningDialog from 'src/components/UI/WarningDialog';
import LicensedReactDataGrid from '../../../../components/UI/LicensedReactDataGrid';
import { DOWNLOAD_URL } from '../../../../consts';
import { TblDocumentRevisions } from '../../../../generated/graphql';
import { getDatabase } from '../../../../rxdb';
import { DocumentRevisionDocument } from '../../../../rxdb/collections/DocumentRevisions/schema';
import { toJSON } from '../../../../utils';
import { IAttachmentState } from '../..';

interface InjectedProps {
  onReady?: (ref: MutableRefObject<TypeComputedProps | null>) => void;
  documentId?: string | null;
  onEdit: (data: IAttachmentState) => void;
  onDelete?: (key: string) => void;
  disabled?: boolean;
}

const DocumentHistory: FC<InjectedProps> = ({
  onReady,
  onEdit,
  onDelete,
  documentId,
  disabled = false,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  // const [data, setData] = useState<DocumentRevisionDocument[]>([]);
  const [data, setData] = useState<TblDocumentRevisions[]>([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteSelected, setDeleteSelected] = useState<any>();

  const transformToIAttachment = (
    revision?: DocumentRevisionDocument | null,
  ): IAttachmentState => {
    if (!revision) {
      return {
        PKey: '',
        fldFKey: '',
        fldFileName: '',
        fldPage: 0,
        fldNotes: '',
        fldRevisionDate: '',
        fldApprovedByPM: '',
        deletedAt: null,
      };
    }
    return {
      PKey: revision?.PKey || uuid(),
      fldFKey: revision?.fldFKey,
      fldFileName: revision?.fldFileName,
      fldNotes: revision?.fldNotes,
      fldPage: revision?.fldPage,
      fldRevision: revision?.fldRevision,
      fldRevisionDate: revision?.fldRevisionDate,
      fldApprovedByPM: revision?.fldApprovedByPM,
      deletedAt: revision?.deletedAt,
    };
  };

  const rowDeletePress = (item: any) => {
    setDeleteSelected(item);
    setIsDeleting(true);
  };

  const handleDeleteOk = () =>{
    onDelete && onDelete(deleteSelected);
    setIsDeleting(false);
    setDeleteSelected(undefined);
  };

  const handleDeleteCancel = () =>{
    setIsDeleting(false);
    setDeleteSelected(undefined);
  };

  const columns = [
    {
      name: 'fldRevisionDate',
      header: 'Date',
      headerAlign: 'start' as any,
      textAlign: 'end' as any,
      render: ({ data }: any) => {
        if (isNil(data.fldRevisionDate)) return null;

        return format(new Date(data.fldRevisionDate), 'dd-MMM-yyyy');
      },
    },
    {
      name: 'fldRevision',
      header: 'Revision',
      defaultWidth: 100,
    },
    {
      name: 'fldFileName',
      header: 'File Name',
      flex: 1,
      render: ({ data }: any) => (
        <Tooltip title={`${data.fldFileName}`} placement="bottom">
          <Link href={`${DOWNLOAD_URL}/${encodeURIComponent(data.fldFileName)}`} target="_blank">
            {`${data.fldFileName}`}
          </Link>
        </Tooltip>
      ),
    },
    {
      name: 'actions',
      sortable: false,
      header: 'Actions',
      defaultWidth: 100,
      render: ({ data }: any) => (
        <Stack direction="row" alignItems="center" spacing={1}>
          {!disabled && <IconButton
            aria-label="edit"
            size="small"
            onClick={() => onEdit(transformToIAttachment(data))}
          >
            <EditOutlinedIcon color="primary" fontSize="inherit" />
          </IconButton>}
          {!disabled &&<IconButton
            aria-label="delete"
            size="small"
            onClick={() =>  rowDeletePress(transformToIAttachment(data).PKey)}
          >
            <DeleteTwoTone color="error" fontSize="inherit" />
          </IconButton>}
        </Stack>
      ),
    },
  ];

  const getDocumentHistory = async () => {
    setLoading(true);
    const db = await getDatabase();

    if (isNil(documentId)) {
    }

    db.documentrevision
      .find({
        selector: {
          fldFKey: documentId,
          deletedBy: {
            $eq: null,
          },
        },
      })
      .$.subscribe((data) => {
        setData(toJSON(data));
        setLoading(false);
      });
  };

  useEffect(() => {
    getDocumentHistory();
  }, [documentId]);

  const handleRowClick = () => {};
  const onLoadingChange = (loading: boolean) => setLoading(loading);

  return (
    <>
      <LicensedReactDataGrid
        onRowClick={handleRowClick}
        onLoadingChange={onLoadingChange}
        defaultSortInfo={[{name: 'fldRevisionDate', dir: -1}]}
        onReady={onReady}
        rowHeight={40}
        loading={loading}
        idProperty="fldFKey"
        columns={columns}
        dataSource={data}
      />
      <WarningDialog
        visible={isDeleting}
        title="Delete Warning"
        content="Are you sure you wish to delete record?"
        okText='Yes'
        color='error'
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />
    </>
  );
};

export default DocumentHistory;
