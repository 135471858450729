import React from 'react';
import pdf from '@react-pdf/renderer';
const { View, StyleSheet, Font, Text } = pdf;
import { getPlainTextFromHtml } from 'src/utils';

// Register the font files
Font.register({
    family: 'Roboto',
    fonts: [
      { src: `./fonts/Roboto-Regular.ttf` },
      { src: `./fonts/Roboto-Bold.ttf`, fontWeight: 'bold' },
      { src: `./fonts/Roboto-Italic.ttf`, fontStyle: 'italic' },
      { src: `./fonts/Roboto-BoldItalic.ttf`, fontWeight: 'bold', fontStyle: 'italic' },
    ],
  });

const borderColor = '#000000';
const styles = StyleSheet.create({
  itemsTable: {
    fontFamily: 'Roboto',
    fontSize: 7,
    width: '100%',
    paddingLeft: 0, // Add this line to remove left padding
  },
  itemsTableRow: {
    flexDirection: 'row',
    // justifyContent: 'flex-start',
    backgroundColor: '#C3C3C3',
    fontWeight: 'bold',
    paddingTop: 3,
    paddingBottom: 3,
    minHeight: 15,
  },

  row: {
    marginTop: 5,
    fontFamily: 'Roboto',
    fontSize: 7,
    width: '100%',
    flexDirection: 'row',
    // justifyContent: 'flex-start',
    // alignItems: 'flex-start',
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    minHeight: 15,
    paddingBottom: 2,
  },
  productName: {
    width: 200,
    textAlign: 'left',
    paddingLeft: 5,
  },
  manufacturer: {
    width: 100,
    textAlign: 'left',
  },
  modelNum: {
    width: 100,
    textAlign: 'left',
  },
  partNum: {
    width: 100,
    textAlign: 'left',
  },
  productDescription: {
    width: '100%',
    textAlign: 'left',
    paddingRight: 2,
  },
  amount: {
    width: 50,
    textAlign: 'left',
  },

  amountQty: {
    width: 50,
    textAlign: 'right',
  },

  footerLabel: {
    fontWeight: 'bold',
    fontStyle: 'italic',
  },

  footerView: {
    marginTop: 5,
    paddingLeft: 0, // Add this line to remove left padding
    fontFamily: 'Roboto',
    fontSize: 9.75,
    fontStyle: 'italic',
    lineHeight: 2,
  },
});

const OrderItemRow = ({ POItems }: { POItems: any }) => {
  return (
    <View
      style={{
        ...styles.itemsTable,
        maxHeight: '75%',
      }}
    >
      <View style={styles.itemsTableRow}>
        <Text style={styles.productName}>Product Name</Text>
        <Text style={styles.manufacturer}>Manufacturer</Text>
        <Text style={styles.modelNum}>Model #</Text>
        <Text style={styles.partNum}>Part #</Text>
        <Text style={styles.productDescription}>Description</Text>
        <Text style={styles.amount}>Amount</Text>
      </View>
      {POItems.map((POItem: any) => (
        <View style={{ ...styles.row }} key={POItem.PKey}>
          <Text style={styles.productName}>{POItem.ProductName}</Text>
          <Text style={styles.manufacturer}>{POItem.Manufacturer}</Text>
          <Text style={styles.modelNum}>{POItem.ModelNumber}</Text>
          <Text style={styles.partNum}>{POItem.PartNumber}</Text>
          <Text style={styles.productDescription}>{getPlainTextFromHtml(POItem.ProductDescription)}</Text>
          <Text style={styles.amountQty}>{parseFloat(POItem.Amount).toFixed(2)}</Text>
        </View>
      ))}
    </View>
  );
};

export default OrderItemRow;