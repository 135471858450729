import { RxCollection, RxJsonSchema, RxDocument } from 'rxdb';
import { Orders } from '../../../generated/graphql';

export type OrdersDocType = Orders;

export type OrdersDocMethods = {};

export type OrdersDocument = RxDocument<
  OrdersDocType,
  OrdersDocMethods
>;

export type OrdersCollectionMethods = {};

export type OrdersCollection = RxCollection<
  OrdersDocType,
  OrdersDocMethods,
  OrdersCollectionMethods
  >;

export const ordersSchema: RxJsonSchema<OrdersDocType> = {
  title: 'Orders ',
  description: 'Orders',
  version: 4,
  keyCompression: false,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    OrderID: {
      type: 'string',
      primary: true,
    },
    OrderDate: {
      type: 'string',
    },
    PurchaseOrderNumber: {
      type: ['string', 'null'],
    },
    Supplier: {
      type: ['string', 'null'],
    },
    Department: {
      type: ['string', 'null'],
    },
    OrderedBy: {
      type: ['string', 'null'],
    },
    Contact: {
      type: ['string', 'null'],
    },
    NoView: {
      type: ['boolean', 'null'],
    },
    MyDesc: {
      type: ['string', 'null'],
    },
    OrderName: {
      type: 'string',
    },
    OrderSent: {
      type: ['boolean', 'null'],
    },
    SupplierID: {
      type: ['string', 'null'],
    },
    fldHTML: {
      type: ['string', 'null'],
    },
    fldType: {
      type: ['string', 'null'],
    },
    fldStatus: {
      type: ['string', 'null'],
    },
    PromisedByDate: {
      type: ['string', 'null'],
    },
    DateEntered: {
      type: ['string', 'null'],
    },
    // fldApprovals: {
    //   type: ['string', 'null'],
    // },
    // fldSRHKey: {
    //   type: ['string', 'null'],
    //   ref: 'tblsystemshierarchy',
    // },
    deletedAt: {
      type: ['string', 'null'],
    },
    updatedAt: {
      type: 'string',
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
  },
  indexes: ['OrderName', 'OrderDate', 'updatedAt'],
  required: [],
  additionalProperties: false,
};

export const ordersDocMethods: OrdersDocMethods = {};

export const ordersCollectionMethods: OrdersCollectionMethods = {};
