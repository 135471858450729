import { RxCollection, RxJsonSchema, RxDocument } from 'rxdb';
import { TblSystemsHierarchy } from '../../../generated/graphql';

export type TblSystemsHierarchyDocType = TblSystemsHierarchy;

export type TblSystemsHierarchyDocMethods = {};

export type TblSystemsHierarchyDocument = RxDocument<
  TblSystemsHierarchyDocType,
  TblSystemsHierarchyDocMethods
>;

export type TblSystemsHierarchyCollectionMethods = {};

export type TblSystemsHierarchyCollection = RxCollection<
  TblSystemsHierarchyDocType,
  TblSystemsHierarchyDocMethods,
  TblSystemsHierarchyCollectionMethods
>;

export const tblSystemsHierarchySchema: RxJsonSchema<TblSystemsHierarchyDocType> = {
  title: 'tblSystemsHierarchy ',
  description: 'describes a tblSystemsHierarchy item',
  version: 1,
  keyCompression: false,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    PKey: {
      type: 'string',
      primary: true,
    },
    ParentID: {
      type: ['string', 'null'],
    },
    fldCategory: {
      type: ['string', 'null'],
    },
    fldDepts: {
      type: ['string', 'null'],
    },
    fldNoView: {
      type: ['boolean', 'null'],
    },
    fldTags: {
      type: ['string', 'null'],
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
    updatedAt: {
      type: 'string',
    },
  },
  required: [],
  additionalProperties: false,
};

export const tblSystemsHierarchyDocMethods: TblSystemsHierarchyDocMethods = {};

export const tblSystemsHierarchyCollectionMethods: TblSystemsHierarchyCollectionMethods = {};
