import React, { FC, useState } from 'react';
import { isNil } from 'lodash';
import SnackbarContext, {SnackbarConfig} from './SnackbarContext';
import {Alert, Snackbar} from "@mui/material";

const SnackbarProvider: FC<any> = ({ children }) => {
  const [config, setConfig] = useState<SnackbarConfig>();

  const show = (config: SnackbarConfig) => {
    setConfig(config);
  };

  const close = () => setConfig(undefined);

  return (
    <SnackbarContext.Provider
      value={{
        showSnackbar: show,
        closeSnackbar: close,
      }}
    >
      {children}

      <Snackbar
        open={!isNil(config)}
        autoHideDuration={2000}
        onClose={close}
      >
        <Alert severity={config?.type as any} sx={{ width: '100%' }}>
          {config?.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;
