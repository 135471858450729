import { omit } from 'lodash';
import { TblLocationsHierarchy } from '../../../generated/graphql';
import {logger} from "../../../helpers/logger";

const fields = `
  ParentID
  fldDepts
  fldNoView
  fldTags
  PKey
  fldLocation
  deletedAt
  updatedAt
  deletedBy
  isRecoverable
  autoId
`;

export const tblLocationsHierarchyQuery = () => (doc: TblLocationsHierarchy) => {
  if (!doc) {
    doc = {
      autoId: 0,
      PKey: '',
      fldLocation: '',
      updatedAt: new Date(0).toISOString(),
    };
  }
  logger('TblLocationsHierarchy').info(
    `pull query triggered - TblLocationsHierarchy: { ${new Date().toISOString()} - ID: ${doc.autoId}, updatedAt: ${doc.updatedAt} }`,
  );
  return {
    query: `
        query GetAllTblLocationsHierarchy($autoId: Int, $filterDate: Date) {
          tblLocationsHierarchy(
            where:{
              _and: [
                {
                  _or: [
                    {
                      isRecoverable: {
                        _is_null: true
                      }
                    },
                    {
                      isRecoverable: {
                        _eq: true
                      }
                    }
                  ]
                },
                {
                  _or: [
                    {
                      updatedAt: {_gt: $filterDate},                
                    },
                    {
                      autoId: {_gt: $autoId}
                      updatedAt: {_gte: $filterDate},
                    }
                  ]
                }
              ]                                    
            },
            order_by: [
              {updatedAt: asc},
              {autoId: asc}
            ],
            limit: 500     
          ) {
            ${fields}           
          }
        }
      `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
    },
  };
};

export const tblLocationsHierarchyMutation = (doc: any) => {
  const query = `
    mutation upsertTblLocationsHierarchy($input: TblLocationsHierarchyUpsertInput!) {
      upsertTblLocationsHierarchy(input: $input) {
        ${fields}
      }
    }
  `;
  const variables = {
    input: omit(doc, ['updatedAt','deletedAt']),
  };

  return {
    query,
    variables,
  };
};

export const tblLocationsHierarchySubscription = `
  subscription onTblLocationsHierarchyChanged {
    tblLocationsHierarchy {
      ParentID
      fldDepts
      fldNoView
      fldTags
      PKey
      fldLocation
      updatedAt
    }
  }
`;
