import { AlertColor } from '@mui/material';
import env from './env';
const dev_env = import.meta.env;
const isDevelopment = import.meta.env.MODE === 'development';

export const NODE_URL = isDevelopment ? `${dev_env.VITE_NODE_URL}` : `${env.REACT_APP_NODE_URL}` || 'http://localhost:3001';
export const DOTNET_URL = isDevelopment ? `${dev_env.VITE_DOTNET_URL}` : `${env.REACT_APP_DOTNET_URL}` || 'http://localhost:5196';
export const SYNCFUSION_URL = isDevelopment ? `${dev_env.VITE_SYNCFUSION_URL}` : `${env.REACT_APP_SYNCFUSION_URL}` || 'http://localhost:3002';
export const NODE_WS_URL = isDevelopment ? `${dev_env.VITE_NODE_WS_URL}` : `${env.REACT_APP_NODE_WS_URL}` || 'ws://localhost:3001/graphql';
export const SENTRY_DSN = isDevelopment ? `${dev_env.VITE_SENTRY_DSN}` : `${env.REACT_APP_SENTRY_DSN}` || 'undefined';
export const SENTRY_ENVIRONMENT = isDevelopment ? `${dev_env.VITE_SENTRY_ENVIRONMENT}` : `${env.REACT_APP_SENTRY_ENVIRONMENT}` || 'undefined';

export const UPLOAD_URL = `${NODE_URL}/files/upload`;
export const DOWNLOAD_URL = `${NODE_URL}/files/download`;
export const ENG_LOG_URL = `${NODE_URL}/englogfile`;
export const REPORT_URL = `${NODE_URL}/sp/spKPIEQ`;
export const AMCS_EQ_URL = `${NODE_URL}/amcseq`;
export const AMCS_ENGLOG_URL = `${NODE_URL}/amcsenglog`;
export const AMCS_RL_URL = `${NODE_URL}/amcsrl`;
export const HEALTH_CHECK_URL = `${NODE_URL}/healthcheck`;
export const INVENTORY_REPORT_URL = `${NODE_URL}/sp/spKPIInv`
// export const ENG_LOG_URL = `${'http://localhost:3001'}/englogfile`;

export const CERTIFICATE_TOAST_MSG = 
  'Task cannot be marked as complete without confirming a new expiration date. \
  Ensure Completion fields are not indicating completion.\
    1. Percent complete MUST be < 100.\
    2. Status <> "Completed" or "Complete". \
    3. Date Completed must be empty. \
    4. [WorrkIssues].[Completed] = 0';

export const scheduleDeleteWarningMessage = `
  Are you sure you wish to delete record(s)? <br /><br />
  Deleting this schedule will make any task generated by this schedule invalid. To avoid this, please delete related tasks before deleting the schedule.
`;

export const equipmentDeleteWarningMessage = `
  Are you sure you wish to delete record(s)? <br /><br />
  Deleting Equipment with outstanding Tasks and/or active Schedules will result in invalid tasks that cannot be completed. To avoid this, please delete all related schedules and tasks before deleting the Equipment.
`;

export const taskDeleteWarningMessage = `
  Are you sure you wish to delete record(s)? <br /><br />
  If the Task is derived from a schedule that is due or overdue or a Certificate that has expired, a new task will be automatically generated. To avoid this, please update the due date, expiration date, or suspend the schedule.
`;

export const GRID_LIMIT = 50;

export const SEVERITY: Record<string, AlertColor> = {
  ERROR: 'error',
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning'
};


export const BCLSizes = [
  {
    labelID: 1,
    labelSize: '2x4'
  },
  {
    labelID: 2,
    labelSize: '2x3'
  },
  {
    labelID: 3,
    labelSize: '1x3'
  },
  {
    labelID: 4,
    labelSize: '3x3'
  },
  {
    labelID: 5,
    labelSize: '3x4'
  },
  {
    labelID: 6,
    labelSize: '1.25x2.25'
  },
  {
    labelID: 7,
    labelSize: '1.25x3.5'
  },
];

export const TimeMeasureOptions: any[] = [
  {
    timeMeasure: "Day(s)",
    id: "0"
  },
  {
    timeMeasure: "Week(s)",
    id: "1"
  },
  {
    timeMeasure: "Month(s)",
    id: "2"
  },
  {
    timeMeasure: "Year(s)",
    id: "3"
  }
];

export const WeekDayOptions: any[] = [
  {
      label: "Sunday",
      value: "1"
  },
  {
      label: "Monday",
      value: "2"
  },
  {
      label: "Tuesday",
      value: "3"
  },
  {
      label: "Wednesday",
      value: "4"
  },
  {
      label: "Thursday",
      value: "5"
  },
  {
      label: "Friday",
      value: "6"
  },
  {
      label: "Saturday",
      value: "7"
  },
];

export const MonthOrdinalOptions: any[] = [
  {
      id: 0,
      label: "First",
  },
  {
      id: 1,
      label: "Second",
  },
  {
      id: 2,
      label: "Third",
  },
  {
      id: 3,
      label: "Fourth",
  },
  {
      id: 4,
      label: "Last",
  },
];

export const MonthDayOptions: any[] = [
  {
      id: 1,
      label: "Sunday",
  },
  {
      id: 2,
      label: "Monday",
  },
  {
      id: 3,
      label: "Tuesday",
  },
  {
      id: 4,
      label: "Wednesday",
  },
  {
      id: 5,
      label: "Thursday",
  },
  {
      id: 6,
      label: "Friday",
  },
  {
      id: 7,
      label: "Saturday",
  },
];

export const MonthOptions: any[] = [
  {
      id: 1,
      label: "January",
  },
  {
      id: 2,
      label: "February",
  },
  {
      id: 3,
      label: "March",
  },
  {
      id: 4,
      label: "April",
  },
  {
      id: 5,
      label: "May",
  },
  {
      id: 6,
      label: "June",
  },
  {
      id: 7,
      label: "July",
  },
  {
      id: 8,
      label: "August",
  },
  {
      id: 9,
      label: "September",
  },
  {
      id: 10,
      label: "October",
  },
  {
      id: 11,
      label: "November",
  },
  {
      id: 12,
      label: "December",
  },
];

export const ScheduleOptions: any[] = [
  {
    scheduleType: "Daily",
    id: "0"
  },
  {
    scheduleType: "Weekly",
    id: "1"
  },
  {
    scheduleType: "Monthly",
    id: "2"
  },
  {
    scheduleType: "Yearly",
    id: "3"
  },
  {
    scheduleType: "Event Based",
    id: "4"
  }
];

// enum
export enum FileExtensionType {
  PDF = 'PDF',
  XLS = 'XLS',
  JPG = 'JPG',
  MSG = 'MSG',
  DOCX = 'DOCX',
  DOC = 'DOC',
  PNG = 'PNG',
  DWG = 'DWG',
  XLSX = 'XLSX',
  WMV = 'WMV',
  BMP = 'BMP',
  JPEG = 'JPEG',
  TIF = 'TIF',
  RTF = 'RTF',
}

export const INVENTORY = {
  GENERAL: { fldDataType: '0', Name: 'General'},
  BEVERAGES: { fldDataType: '1', Name: 'Beverage'},
  DRY_STORES: { fldDataType: '2', Name: 'Dry'},
  UNIFORM: { fldDataType: '3', Name: 'Uniform'},
  MEDICAL: { fldDataType: '4', Name: 'Medical'},
}

export const CHAR_LIMIT = {
  ORDERS: {
    OrderName: 50,
    PurchaseOrderNumber: 30,
  },
  WORKISSUES: {
    Subject: 255,
    fldCustJob: 50,
  },
  EQUIPMENT: {
    UniqueName: 255,
    ModelNumber: 50,
    SerialNum: 50,
    ArrangementNum: 50,
    Rating: 50,
    ArtNum: 50,
  },
  INVENTORY: {
    ProductName: 255,
    fldPartnumber: 100,
    ModelNumber: 50,
  },
  TBLSCHEDMAINT: {
    fldSubject: 255,
  },
  TBLSCHEDCHK: {
    fldTitle: 255,
  },
  CERTIFICATES: {
    fldType: 255,
    fldCertNum: 50,
    fldIssue: 100,
  },
  CREWPROFILES: {
    fldFirst: 50,
    fldMiddle: 50,
    fldLast: 50,
    fldPlaceOfBirth: 25,
    fldAddress: 255,
    fldDL: 50,
    fldReligion: 50,
    fldMarital: 50,
    fldBUPA: 50,
    fldAllergies: 255,
    fldBloodABO: 3,
    fldBloodRH: 3,
    fldNOK1: 255,
    fldNOK1Mobile: 25,
    fldNOK1Email: 100,
    fldPhone1: 25,
    fldPhone2: 25,
    fldNOK2: 255,
    fldNOK2Mobile: 25,
    fldNOK2Email:100,
    fldSalary: 15,
    fldCurrentPay: 15,
    fldBank: 255,
    fldBankContact: 100,
    fldBankPhone: 100,
    fldBankFax: 100,
    fldBankEmail: 100,
    fldBankRouting: 255,
    fldAcct: 50,
    fldWire: 255,
    fldBankSwift: 100,
    fldBankIBAN: 100,
    fldBankCurr: 25,
  },
  TBLANYREPORT: {
    fldReportNumber: 50,
    fldRemark: 255,
  },
  TBLDOCUMENTATION: {
    DocTitle: 255,
    fldPage: 50,
    fldNumber: 255,
  },
  TBLDOCUMENTREVISIONS: {
    fldRevision: 255,
  },
  TBDDLISTDEFAULTS: {
    fldMember: 100,
  },
  MULTIPLELOCATIONS: {
    SerialNum: 50,
  },
  INVBARCODES: {
    fldTritonBC: 255,
  }
}

export const ICON = {
  HOME: 'Home',
  TASKS: 'Tasks',
  LOGS: 'Logs',
  ALL_TASKS: 'All Tasks',
  CHECKLISTS: 'Checklists',
  ACCOUNTS: 'Accounts',
  EQUIPMENT: 'Equipment',
  PURCHASING: 'Purchasing',
  MAINTENANCE_LOG: 'Maintenance History',
  ENGINEERING_ROUNDS: 'Engineering Rounds',
  CERTIFICATES_DOCUMENTS: 'Certificates & Documents',
  CERTIFICATES: 'Certificates',
  DWR: 'DWR',
  CREW_GUEST_DATA: 'Crew & Guest Data',
  SPARE_AND_INVENTORY: 'Spares & Inventory',
  SMS_FORMS: 'SMS Forms & Reports',
  ISPS_FORMS: 'ISPS Forms & Reports',
  LIBRARIES: 'Documentation Libraries',
  SETTINGS: 'Settings',
  YMS_SETTINGS: 'YMS Settings',
  LIST_UTILITY: 'List Utility',
  CATEGORY_UTILITY: 'Category Utility',
  LOCATIONS_UTILITY: 'Locations Utility',
  RUNNING_LOG_GROUPS_UTILITY: 'Running Log Utility',
  RECYCLE_BIN: 'Recycle Bin',
  EVENTS_SCHEDULES: 'Events & Schedules',
  REPORT_ISSUES: 'Report Issues',
  CRITICAL_SYSTEM_REPORT: 'Critical Systems Report',
  MAINTENANCE_REPORT:' Maintenance Report',
  FORECASTING:'Forecasting Report'
};

export const ATTACHMENTS = {
  UPLOAD_PHOTO: 'Upload Photo',
  UPLOAD_ATTACHMENT: 'Upload Attachment',
  ATTACH_PHOTOS: 'Add Photo',
  ATTACH_FILES: 'Add Attachment',
};

export const CHECKLIST = {
  ADD_ITEM: 'Add Inspection Item',
  EDIT_ITEM: 'Edit Inspection Item',
};

export const HOURS_OF_WORK_TYPE = {
  REST: '0',
  DUTY_WATCH: '2',
  BRIDGE_WATCH: '1',
  OTHER_WORK: '3',
  NEUTRAL: '9',
};

export const DOCUMENT_LIBRARY_TYPES = {
  DRAWINGS: { PATH: 'drawings', TYPE: 'DRAWINGS', DISPLAY_NAME: 'Drawings' },
  VESSEL_REFERENCE: { PATH: 'reference', TYPE: null, DISPLAY_NAME: 'Vessel Reference' },
  PHOTOS: { PATH: 'photos', TYPE: 'PHOTOS', DISPLAY_NAME: 'Photos' },
  SMSDOCS: { PATH: 'sms', TYPE: 'SMSDOCS', DISPLAY_NAME: 'SMS Documents' },
  IPSDOCS: { PATH: 'isps', TYPE: 'ISPSDOCS', DISPLAY_NAME: 'ISPS Documents' },
};

export const CERTIFICATES_TYPE = {
  LIBRARY: { PATH: 'library', TYPE: 'LIBRARY' },
  CREWPROFILE: { PATH: 'crewprofile', TYPE: 'CREWPROFILE' },
};

export const CREW_REPORTS = {
  MANIFEST: 'Crew Manifest',
  // USCG_MANIFEST: 'USCG Crew Manifest',
  IMO_MANIFEST: 'IMO Crew Manifest',
  ILO_HOR: 'ILO Hours of Rest Form',
};

export const GUEST_REPORTS = {
  MANIFEST: 'Guest Manifest',
  IMO_MANIFEST: 'IMO Guest Manifest',
};

type UpdateRecordsFields = {
  tableName: string;
  fieldName: string;
  columnName: string;
};

export type UpdateRecordsOptionsType = {
  [key: string]: UpdateRecordsFields;
};

export const PSPDF_LICENSE = 'iYS2quKWi1O003yaxXMr2ywoYpRQ8pGJLZKeUmUjDGnVj6z4aeVTitIiIvG13QdFSKCnuxMbtgLrYnRpdI9auUflz0EVLd-PHKkDYKviKlh4L2NkFLM-fW6DxWAf7PLbf39PcHob0pUUuqISCrXP5wM6JZrKYqK5gDxbcaiMxt_QELtgJGXnGH-tu55aDyctsdGzqVqzVlIS5tNknZhWHI8n0RS3vYWVZYTZMn7wzYRDAOTWrOYmg7hnN6vMgz9Na3nIxwL0JQvU1_We0-bDsI_ENge3ltmfAvMiVkUtc_JuY7V8vPrs7eOqjzTkB4FSeBTNeLDa8mJ0AuPj1hZa26bSGQHlVmQb06Ne7l6Qw-9tbd51PCgMDtcyBsJC3gUMKVYdWVeHH9TD_BTLikQiwbwGP1HJG9r7-OJBL9pXsGZKHcxrvmd_JDAurhH2M-PTh4LXFZ3hx42HhaFuEsPPLgL89B8YxAYUYujKhKB05KSe4CRurWdSdIZdWEYsbYgYGaUY5OAQIAtH-KgYDdxq4V8FBDBhw7J_QoMZN8m8dMYNRU4xs8FKru-DDC4IGlvYmn1zI2Zp1ZZjDqJEbcrEhv3pDoGuoMHHds2kLcn2VZ84hPtaq3b2Ba2Zx-d0v_Mw'
export const SYNCFUSION_LICENSE = 'ORg4AjUWIQA/Gnt2UVhhQlVBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5RdE1jXXxfcnFcQ2JV;Mgo+DSMBMAY9C3t2UVhhQlVBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5RdE1jXXxfcnFcQGhZ'
export const GRID_LICENSE = 'AppName=TritonDevelopmentApp,Company=TritonDevelopment,ExpiryDate=2024-12-11T00:00:00.000Z,LicenseDeveloperCount=1,LicenseType=single_app,Ref=TritonDevelopmentLicenseRef,Z=180108368118239178621361641905-2089817017117813633-2124690865'