import { TblMonSysTags } from '../../../generated/graphql';
import {logger} from "../../../helpers/logger";

const fields = `
PKey
EQKey
deletedAt
deletedBy
isRecoverable
fldTag
updatedAt
autoId
`;

export const tblMonSysTagsPullQuery = (doc: TblMonSysTags) => {
  if (!doc) {
    doc = {
      autoId: 0,
      PKey: '',
      updatedAt: new Date(0).toISOString(),
    };
  }
  logger('tblMonSysTags').info(
    `pull query triggered - tblMonSysTags:  { ${new Date().toISOString()} - ID: ${doc.autoId
    }, updatedAt: ${doc.updatedAt}`,
  );
  return {
    query: `
    query gettblMonSysTags($autoId: Int,  $filterDate: Date) {
      tblMonSysTags(
        where:{ 
          _and: [
            {
              _or: [
                {
                  isRecoverable: {
                    _is_null: true
                  }
                },
                {
                  isRecoverable: {
                    _eq: true
                  }
                }
              ]
            },
            {
              _or: [
                {
                  updatedAt: {_gt: $filterDate},                
                },
                {
                  autoId: {_gt: $autoId}
                  updatedAt: {_gte: $filterDate},
                }
              ]
            }
          ]                                    
        },
          order_by: [
            {updatedAt: asc},
            {autoId: asc}
          ],
          limit: 500
        ) {
          ${fields}           
      }
   }
      `,
    variables: {
      autoId: doc?.autoId,
      filterDate: doc.updatedAt,
    },
  };
};
export const tblMonSysTagsSubscription = `
  subscription onTblMonSysTagsChanged {
    tblMonSysTags {
      ${fields}      
    }
  }
`;
