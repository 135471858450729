import React, { FC } from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  Symbols,
  Surface,
} from 'recharts';

interface InjectedProps {
  data: any;
  dataKey?: string;
  nameKey?: string;
  innerRadius?: number;
  outerRadius?: number;
  paddingAngle?: number;
  cy?: number;
  width?: number;
  height?: number;
  COLORS?: any['#0f85e0'];
}

const PieChartUI: FC<InjectedProps> = ({
  data,
  innerRadius,
  outerRadius,
  dataKey,
  nameKey,
  paddingAngle,
  cy,
  width,
  height,
  COLORS,
}) => {
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: '#ffff',
            padding: '5px',
            border: '1px solid #cccc',
          }}
        >
          <label>{`${payload[0].name} : ${payload[0].value}`}</label>
        </div>
      );
    }

    return null;
  };

  const renderLegend = (props: any) => {
    const { payload } = props;

    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'max-content max-content',
          gridColumnGap: '2em',
        }}
      >
        {payload.map((entry: any, index: any) => (
          <span className="flex" key={entry.color}>
            <Surface width={15} height={15} className="mr-1">
              <Symbols
                cx={4}
                cy={12}
                type="square"
                size={90}
                fill={entry.color}
              />
            </Surface>
            <span style={{ fontSize: '14px', color: `${entry.color}` }}>
              {entry.value}
            </span>
          </span>
        ))}
      </div>
    );
  };

  return (
    <PieChart width={width} height={height}>
      <Pie
        data={data}
        color="#000000"
        dataKey={`${dataKey}`}
        nameKey={nameKey}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        paddingAngle={paddingAngle}
        fill="#8884d8"
        cy={cy}
      >
        {data.map((entry: any, index: any) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS?.length]} />
        ))}
      </Pie>
      <Tooltip content={CustomTooltip} />
      <Legend
        layout="vertical"
        wrapperStyle={{ bottom: 10 }}
        content={renderLegend}
      />
    </PieChart>
  );
};

export default PieChartUI;
