import { isEmpty, isNil, pick } from 'lodash';
import { SchedMaintEQDocument } from 'src/rxdb/collections/SchedMaintEQ/schema';

export const validateForm = (data: any, setSnackbar: any) => {
  const { fldSubject, Department, Equipment,scheduleType } = data;

  if (isNil(fldSubject) || isEmpty(fldSubject) || isNil(Department) || isNil(Equipment)|| isEmpty(scheduleType) || isNil(scheduleType)) {
    setSnackbar({
      open: true,
      message: 'Please fill required field(s). Check form field(s) marked with red color',
      type: 'error',
    });
    return false;
  }

  // if (isNil(fldSRHKey)) {
  //   setSnackbar({
  //     open: true,
  //     message: 'Category is required',
  //     type: 'error',
  //   });
  //   return false;
  // }

  // if (isNil(Department)) {
  //   setSnackbar({
  //     open: true,
  //     message: 'Department is required',
  //     type: 'error',
  //   });
  //   return false;
  // }

  return true;
};

export const transformData = async (issue: SchedMaintEQDocument) => {
  const Equipment = await issue.populate('EqKey');
  const SchedMaint = await issue.populate('SchedKey');

  return {
    ...pick(issue, [
      'PKey',
      'EqKey',
      'SchedKey',
      'DateDue',
      'fldHoursTrigger',
      'fldDateTrigger',
      'DateEntered',
      'fldDeferred',
      'fldDeferredDate',
      'fldHoursCompleted',
      'fldIndex',
      'fldIterations',
      'fldRunOnce',
    ]),
    Equipment: Equipment?.UniqueName,
    Hours: Equipment?.Hours,
    fldSRHKey: Equipment?.fldSRHKey,
    fldSubject: SchedMaint?.fldSubject,
    Department: SchedMaint?.Department,
    fldAssignedTo: SchedMaint?.fldAssignedTo,
    fldListType: SchedMaint?.fldListType,
    fldHourInterval: SchedMaint?.fldHourInterval,
    fldSchedType: SchedMaint?.fldSchedType,
    RecurType: SchedMaint?.RecurType,
    RecurPattern: SchedMaint?.RecurPattern,
    fldTimeWarn: SchedMaint?.fldTimeWarn,
    scedMaintPkey: SchedMaint?.PKey,
    fldHourWarn:SchedMaint?.fldHourWarn ,
    fldTimeMeasureWarn:SchedMaint?.fldTimeMeasureWarn,
    fldHourLock: SchedMaint?.fldHourLock,
    fldCSM: SchedMaint?.fldCSM,
    fldSMS: SchedMaint?.fldSMS,
    fldUserDefined : SchedMaint?.fldUserDefined,
    fldDuration: SchedMaint?.fldDuration,
    fldIsCheckList: SchedMaint?.fldIsCheckList,
    fldLinkID: SchedMaint?.fldLinkID,

    // Department: (await issue.populate('Department'))?.fldMember,
    // fldWorkList: (await issue.populate('fldWorkList'))?.fldMember,
    original: issue,
  };
};
