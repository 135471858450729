import React, { FC } from 'react';
import {
  Checkbox as MaterialCheckbox,
  FormControlLabel,
  TextFieldPropsSizeOverrides,
} from '@mui/material';

import { Control, RegisterOptions, useController } from 'react-hook-form';
import { OverridableStringUnion } from '@mui/types';

interface InjectedProps {
  control: Control;
  name: string;
  defaultValue?: any;
  rules?: RegisterOptions;
  label?: string;
  size?: OverridableStringUnion<
    'small' | 'medium',
    TextFieldPropsSizeOverrides
  >;
  onChange?: any;
  disabled?: boolean;
}

const Checkbox: FC<InjectedProps> = ({
  name,
  control,
  defaultValue,
  rules,
  label,
  size,
  onChange,
  disabled = false,
}) => {
  const {
    field: { ref, value, ...inputProps },
  } = useController({
    name,
    control,
    defaultValue,
    rules,
  });

  const handleChange = (evt: any) => {
    onChange && onChange(evt);

    const { onChange: onDefaultChange } = inputProps;
    onDefaultChange(evt);
  }

  return (
    <FormControlLabel
      control={(
        <MaterialCheckbox
          {...inputProps}
          inputRef={ref}
          size={size}
          checked={defaultValue || value}
          onChange={handleChange}
          disabled = {disabled}
        />
      )}
      label={label || ''}
    />
  );
};

export default Checkbox;
