import React, { FC } from 'react';
import { WorkIssuesDocument } from 'src/rxdb/collections/WorkIssues/rxdb';
import GenericModal from '../../../UI/GenericModal';

interface CompleteCertificateTaskModalProps {
  issue: WorkIssuesDocument;
  visible: boolean;
  onSave: () => void;
  onCancel: () => void;
}

const RecurringTaskModal: FC<CompleteCertificateTaskModalProps> = ({
  issue,
  visible,
  onSave,
  onCancel,
}) => {
  const handleCompleteClick = async () => {
    const certificate = await issue.populate('fldSMSID');

    // Set certificate expiration to NULL
    await certificate?.atomicPatch({
      DateExpire: null,
    });
    onSave();
  };

  return (
    <GenericModal
      okButtonProps={{ variant: 'contained' }}
      okText="Yes"
      onOk={handleCompleteClick}
      cancelText="No"
      onCancel={onCancel}
      visible={visible}
      title="Recurring Task"
    >
      <div>
        <div className="mb-10">
          {`[${issue?.Subject}] has been deleted and expiration date will be set to
          null, do you want to complete the task?`}
        </div>
      </div>
    </GenericModal>
  );
};

export default RecurringTaskModal;
