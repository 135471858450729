import React, { FC, useEffect, useState } from 'react';
import { Control } from 'react-hook-form';
import { uniqBy } from 'lodash';
import { TblLocationsHierarchyDocument } from './rxdb';
import { getDatabase } from '../../../rxdb';
import TreeSelectOld from '../../UI/Forms/TreeSelect/indexOld';
import { useAuth } from 'src/contexts/auth';

type LocationProps = {
  label: string;
  control: Control;
  name: string;
  onChange: (field: string, value: any) => void;
  equipment?: any;
  disabled?: boolean;
  defaultValue?: string | null;
  allDepts?: boolean;
  sourceForm?: string;
  dropdownHeight?: number;
};

const LocationDropdownOld: FC<LocationProps> = ({
  control,
  name,
  label,
  onChange,
  equipment,
  disabled,
  defaultValue,
  sourceForm,
  allDepts = false,
  dropdownHeight,
}) => {
  const { user } = useAuth();
  const [locations, setLocations] = useState<TblLocationsHierarchyDocument[]>(
    [],
  );

  const init = async () => {
    const db = await getDatabase();
   
    const withGivenDefault = await db.tbllocationshierarchy
    .find({ selector: { PKey: { $eq: defaultValue },fldLocation: { $ne: null }, deletedBy:{ $eq: null} } })
    .exec();
    const basedOnTagNull = await db.tbllocationshierarchy
      .find({ selector: { fldTags: { $eq: null },fldLocation: { $ne: null }, deletedBy:{ $eq: null} } })
      .exec();
    const basedOnTagLike = await db.tbllocationshierarchy
      .find({ selector: { fldTags: { $regex: new RegExp('work', 'i') }, fldLocation: { $ne: null }, deletedBy:{ $eq: null} } })
      .exec();
    const basedOnDepartmentNull = await db.tbllocationshierarchy
      .find({ selector: { fldDepts: { $eq: null }, fldLocation: { $ne: null }, deletedBy:{ $eq: null} } })
      .exec();
    const basedOnDepartmentLike = user?.Department
      ? await db.tbllocationshierarchy
        .find({
          selector: {
            fldDepts: { $regex: new RegExp(user?.Department || '', 'i') },
            fldLocation: { $ne: null },
            deletedBy:{ $eq: null}
          },
        })
        .exec()
      : [];

    const location = [
      ...withGivenDefault,
      ...basedOnTagNull,
      ...basedOnTagLike,
      ...basedOnDepartmentNull,
      ...basedOnDepartmentLike,
    ];

    return(uniqBy(location, 'PKey'));
  };

  const handleFilter = (item: any) => {
    switch (sourceForm) {
      case 'task':
        return getTaskCondition(item);
      case 'logentry':
        return getLogEntryCondition(item);
      case 'equipment':
        return getEquipmentCondition(item);
      case 'event-scheduling':
        return getEventSchedCondition(item);
      case 'drill-scheduling':
        return getDrillSchedCondition(item);
      case 'general-inventory':
        return getGenInvCondition(item);
      case 'beverages':
        return getNoteTwoCondition(item, 'BEVERAGES');
      case 'uniforms':
        return getNoteTwoCondition(item, 'UNIFORMS');
      case 'stores':
        return getNoteTwoCondition(item, 'STORES');
      case 'medical':
        return getNoteTwoCondition(item, 'MEDICAL');
      case 'inspections':
        return getInspectionsCondition(item);
      case 'ISPSDOCS':
        return getISPSCondition(item);
      case 'PHOTOS':
        return getTaskCondition(item);
      case 'DRAWINGS':
        return getTaskCondition(item);
      case 'SMSDOCS':
        return getSMSCondition(item);
      default:
        return true;
    }
  };

  const getSMSCondition = (item: any) => {
    if (item.fldTags && item.fldTags.includes('SMSDOCS')) {
      return true;
    }
    return false;
  };

  const getISPSCondition = (item: any) => {
    if (item.fldTags && item.fldTags.includes('ISPSDOCS')) {
      return true;
    }
    return false;
  };

  const getTaskCondition = (item: any) => {
    if (allDepts) {
      if (!item.fldTags || item.fldTags.includes('WORKISSUES')) {
        return true;
      }
      return false;
    }
    if (
      (!item.fldTags || item.fldTags.includes('WORKISSUES'))
      && (!item.fldDepts || item.fldDepts.includes((user?.Department || '').toUpperCase()))
    ) {
      return true;
    }
    //Adding below condition to handle if user do not have permission to see applied category but have permission to see task then it will enable that category only for the task
    if (item.PKey === defaultValue){
      return true
    }
    return false;
  };

  const getLogEntryCondition = (item: any) => {
    if (allDepts) {
      if (!item.fldTags || item.fldTags.includes('LOGENTRY')) {
        return true;
      }
      return false;
    }
    if (
      (!item.fldTags || item.fldTags.includes('LOGENTRY'))
      && (!item.fldDepts || item.fldDepts.includes((user?.Department || '').toUpperCase()))
    ) {
      return true;
    }
    return false;
  };

  const getEquipmentCondition = (item: any) => {
    if (allDepts) {
      if (!item.fldTags || item.fldTags.includes('EQUIPMENT')) {
        return true;
      }
      return false;
    }
    if (
      (!item.fldTags || item.fldTags.includes('EQUIPMENT'))
      && (!item.fldDepts || item.fldDepts.includes((user?.Department || '').toUpperCase()))
    ) {
      return true;
    }
    return false;
  };

  const getEventSchedCondition = (item: any) => {
    if (allDepts) {
      if (!item.fldTags || item.fldTags.includes('EVENT_SCHEDULING')) {
        return true;
      }
      return false;
    }
    if (
      (!item.fldTags || item.fldTags.includes('EVENT_SCHEDULING'))
      && (!item.fldDepts || item.fldDepts.includes((user?.Department || '').toUpperCase()))
    ) {
      return true;
    }
    return false;
  };

  const getDrillSchedCondition = (item: any) => {
    if (allDepts) {
      if (!item.fldTags || item.fldTags.includes('DRILL_SCHEDULING')) {
        return true;
      }
      return false;
    }
    if (
      (!item.fldTags || item.fldTags.includes('DRILL_SCHEDULING'))
      && (!item.fldDepts || item.fldDepts.includes((user?.Department || '').toUpperCase()))
    ) {
      return true;
    }
    return false;
  };

  const getGenInvCondition = (item: any) => {
    if (allDepts) {
      if (!item.fldTags || item.fldTags.includes('INVENTORY')) {
        return true;
      }
      return false;
    }
    if (
      (!item.fldTags || item.fldTags.includes('INVENTORY'))
      && (!item.fldDepts || item.fldDepts.includes((user?.Department || '').toUpperCase()))
    ) {
      return true;
    }
    return false;
  };

  const getInspectionsCondition = (item: any) => {
    if (allDepts) {
      if (!item.fldTags || item.fldTags.includes('INSPECTIONS')) {
        return true;
      }
      return false;
    }
    if (
      (!item.fldTags || item.fldTags.includes('INSPECTIONS'))
      && (!item.fldDepts || item.fldDepts.includes((user?.Department || '').toUpperCase()))
    ) {
      return true;
    }
    return false;
  };

  const getNoteTwoCondition = (item: any, field: string) => {
    if (item.fldTags && item.fldTags.includes(field)) {
      return true;
    }
    return false;
  };

  // useEffect(() => {
  //   init();
  // }, [equipment]);

  useEffect(() => {
    let isMounted = true; // Variable to track if component is mounted

    const fetchData = async () => {
      try {
        const locations = await init();
        if (isMounted) {
          // Update state only if component is mounted
          setLocations(locations);
        }
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    };

    fetchData();

    // Cleanup function
    return () => {
      isMounted = false; // Set to false when component unmounts
    };
  }, []); // Empty dependency array means this effect runs only once, similar to componentDidMount

  const data = locations.filter(handleFilter).map((elem: any) => ({
    PKey: elem.PKey,
    ParentID: elem.ParentID,
    fldLocation: elem.fldLocation,
    expanded: false,
  }));

  const sortedData = data?.sort((a: any, b: any) => a.fldLocation.localeCompare(b.fldLocation));

  return (
    <div data-testid="data-grid">
      <TreeSelectOld
        disabled={disabled}
        keyExpr="PKey"
        parentIdExpr="ParentID"
        displayExpr="fldLocation"
        label="Location"
        onChange={onChange}
        options={sortedData}
        control={control}
        name={name}
        valueExpr="PKey"
        dropdownHeight={dropdownHeight}
      />
    </div>
  );
};

export default LocationDropdownOld;
