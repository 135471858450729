import { omit } from 'lodash';
import { TblRlMetaGrpsNeq } from '../../../generated/graphql';
import {logger} from "../../../helpers/logger";

const fields = `
EqKey,
PKey,
deletedAt,
fldDataType,
fldDecPL,
fldEQIdx,
fldGrpCaption,
fldGrpIdx,
fldMax,
fldMin,
fldMonSysTag,
fldRdgCaption,
fldRdgIdx,
fldUnit,
fldVariance,
deletedAt
deletedBy
isRecoverable
updatedAt
autoId
`;

export const tblRlMetaGrpsNeqPullQuery = (doc: TblRlMetaGrpsNeq) => {
  if (!doc) {
    doc = {
      autoId: 0,
      PKey: '',
      fldGrpCaption: '',
      fldRdgCaption: '',
      updatedAt: new Date(0).toISOString(),
    };
  }
  logger('TblRlMetaGrpsNeq').info(
    `pull query triggered - TblRlMetaGrpsNeq:  { ${new Date().toISOString()} - ID: ${doc.autoId}}`,
  );
  return {
    query: `
        query getTblRlMetaGrpsNeq($autoId: Int, $filterDate: Date) {
          tblRLMetaGrpsNEQ(
            where:{
              _and: [
                {
                  _or: [
                    {
                      isRecoverable: {
                        _is_null: true
                      }
                    },
                    {
                      isRecoverable: {
                        _eq: true
                      }
                    }
                  ]
                },
                {
                  _or: [
                    {
                      updatedAt: {_gt: $filterDate},                
                    },
                    {
                      autoId: {_gt: $autoId}
                      updatedAt: {_gte: $filterDate},
                    }
                  ]
                }
              ]                                    
            },
            order_by: [
              {updatedAt: asc},
              {autoId: asc}
            ],
            limit: 500
          ) {
            ${fields}
          }
        }
      `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
    },
  };
};

export const tblRlMetaGrpsNeqMutation = (doc: any) => {
  const query = `
    mutation InsertTblRLMetaGrpsNEQ($input: TblRLMetaGrpsNEQUpsertInput!) {
      upsertTblRLMetaGrpsNEQ(input: $input) {
        ${fields}
      }
    }
  `;

  const variables = {
    input: omit(doc, ['updatedAt', 'deletedAt', 'id']),
  };

  return {
    query,
    variables,
  };
};

export const tblRlMetaGrpsNeqSubscription = `
  subscription onTblRlMetaGrpsNeqChanged {
    tblRLMetaGrpsNEQ {
      ${fields}
    }
  }
`;
