import './style.css';
import '@inovua/reactdatagrid-enterprise/index.css';
import { FC, MutableRefObject, useEffect } from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import { TypeComputedProps, TypeDataGridProps, TypeSortInfo } from '@inovua/reactdatagrid-enterprise/types';
import { useHistory } from 'react-router-dom';
import { useAppState } from 'src/contexts/app-state';
import { GRID_LICENSE } from 'src/consts';
import * as Sentry from '@sentry/react';

interface InjectedProps {
  onLoadingChange?: (loading: boolean) => void;
  groupByEnabled?: boolean;
  sourceRoot?: boolean;
}

const LicensedReactDataGrid: FC<Partial<TypeDataGridProps> & InjectedProps> = ( props ) => {
  const { groupByEnabled = true, sourceRoot = false } = props;
  const { tableState, setTableState } = useAppState();
  const history = useHistory();

  if (!sourceRoot) {
    return (
      <ReactDataGrid
        {...(props as any)}
        style={{ borderColor: '#ededed', ...props.style }}
        className="rounded"
        licenseKey={GRID_LICENSE}
      />
    );
  }
  
  useEffect(() => {
    if (props.selected) {
      onSetSelected(props.selected);
    }
  }, [props?.selected]);

  const onSetSelected = (selected: any) => {
    const currentPath = history.location.pathname;
    const currentTableState = tableState && tableState[currentPath] ? tableState[currentPath] : {};
    currentTableState['selected'] = selected;

    setTableState({
      ...tableState,
      [currentPath]: currentTableState,
    });
  };

  const handleColumnOrderChange = (columnOrder: string[]) => {
    const currentPath = history.location.pathname;
    const currentTableState = tableState && tableState[currentPath] ? tableState[currentPath] : {};
    currentTableState['columnOrder'] = columnOrder;

    setTableState({
      ...tableState,
      [currentPath]: currentTableState,
    });

    if (props.onColumnOrderChange) {
      props.onColumnOrderChange(columnOrder);
    }
  };

  const handleSortInfoChange = (sortInfo: TypeSortInfo) => {
    const currentPath = history.location.pathname;
    const currentTableState = tableState && tableState[currentPath] ? tableState[currentPath] : {};
    currentTableState['sortInfo'] = sortInfo;

    setTableState({
      ...tableState,
      [currentPath]: currentTableState,
    });

    if (props.onSortInfoChange) {
      props.onSortInfoChange(sortInfo);
    }
  };

  const handleFilterValueChange = (filterInfo: any[]) => {
    const currentPath = history.location.pathname;
    const currentTableState = tableState && tableState[currentPath] ? tableState[currentPath] : {};
    currentTableState['filterInfo'] = filterInfo;
  
    setTableState({
      ...tableState,
      [currentPath]: currentTableState,
    });
  
    if (props.onFilterValueChange) {
      props.onFilterValueChange(filterInfo);
    }
  };

  const handleSelectionChange = ({ selected }: any) => {
    const currentPath = history.location.pathname;
    const currentTableState = tableState && tableState[currentPath] ? tableState[currentPath] : {};
    currentTableState['selected'] = selected;

    setTableState({
      ...tableState,
      [currentPath]: currentTableState,
    });

    if (props.onSelectionChange) {
      props.onSelectionChange({ selected })
    };
  };

  const handleGroupByChange = (groupBy: any) => {
    const currentPath = history.location.pathname;
    const currentTableState = tableState && tableState[currentPath] ? tableState[currentPath] : {};
    currentTableState['groupBy'] = groupBy;

    setTableState({
      ...tableState,
      [currentPath]: currentTableState,
    });

    if (props.onGroupByChange) {
      props.onGroupByChange(groupBy)
    };
  };

  const handleReady = (ref: MutableRefObject<TypeComputedProps | null>) => {
    const currentPath = history.location.pathname;
    const currentTableState = tableState && tableState[currentPath] ? tableState[currentPath] : {};
    currentTableState['filterInfo'] = ref.current?.computedFilterValue;
    currentTableState['groupBy'] = ref.current?.computedGroupBy;

    setTableState({
      ...tableState,
      [currentPath]: currentTableState,
    });

    if (ref && props.onReady) {
      props.onReady(ref)
    };
  };

  const currentPath = history.location.pathname;
  const currentTableState = tableState && tableState[currentPath] ? tableState[currentPath] : {};

  return (
    <>
      <ReactDataGrid
        {...(props as any)}
        style={{ borderColor: '#ededed', ...props.style }}
        defaultSortInfo={currentTableState.sortInfo || props.defaultSortInfo}
        columnOrder={currentTableState.columnOrder}
        filterValue={currentTableState.filterInfo}
        selected={currentTableState.selected}
        groupBy={groupByEnabled ? currentTableState.groupBy : null}
        onReady={handleReady}
        onSortInfoChange={handleSortInfoChange}
        onColumnOrderChange={handleColumnOrderChange}
        onFilterValueChange={handleFilterValueChange}
        onSelectionChange={handleSelectionChange}
        onGroupByChange={handleGroupByChange}
        className="rounded"
        licenseKey={GRID_LICENSE}
      />
    </>
  );
};

export default Sentry.withProfiler(LicensedReactDataGrid);
