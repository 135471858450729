import { FC, useState } from 'react';
import { IconButton } from '@mui/material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { useAppState } from 'src/contexts/app-state';
import { withSnackbar } from 'src/components/UI/SnackbarHOC';
import { useAuth } from 'src/contexts/auth';
import UpdateRecordPopup from './component/UpdateRecordPopup';
import { UpdateRecordsOptionsType } from 'src/consts';

interface UpdateRecordButtonProps {
  visible: boolean;
  records: any;
  onSelect: (data: any) => void;
  options: UpdateRecordsOptionsType;
  keyField?: any;
  snackbarShowMessage: any;
}

const UpdateRecordButton: FC<UpdateRecordButtonProps> = ({
  visible,
  records,
  onSelect,
  options,
  keyField = 'original',
  snackbarShowMessage,
}) => {
  const [isUpdatePopupOpen, setIsUpdatePopupOpen] = useState(false);
  const { user } = useAuth();
  const { settingsPersonal } = useAppState();

  const handleUpdatePopup = async () => {
    setIsUpdatePopupOpen(true);
  };

  const handleUpdatePopupCancel = () => {
    setIsUpdatePopupOpen(false);
  };

  const handleSubmit = async (payload: any) => {
    var allRecordNotUpdated = false;
    const fieldName = payload.fieldName;
    const columnName = payload.columnName;
    const value = payload.value;
    try {
      const recordList: any = Object.values(records || {});
      for (const record of recordList) {
        if (record[keyField]?.fldRestricted && !settingsPersonal.fldDeptHead) {
          allRecordNotUpdated = true;
        } else if (settingsPersonal.fldAllDepts === 1 && record[keyField]?.Department != user?.Department) {
          allRecordNotUpdated = true;
        } else {
          try {
            const originalRecord = record.original;
            originalRecord?.atomicPatch({
              [fieldName]: value,
            });
          } catch (e: any) {
            console.log('Error performing atomic patch on UpdateRecords:', e);
          }

          snackbarShowMessage(
            `"${columnName}" updated on all selected record(s)`,
            'success',
            3000
          );
        }
      }

      onSelect({});
    } catch (error) {
      console.log('update error ---', error);
    }

    if (allRecordNotUpdated) {
      snackbarShowMessage(
        'You do not have permission to delete record(s), Some record(s) are not deleted',
        'error',
        5000
      );
    }

    setIsUpdatePopupOpen(false);
  };

  if (!visible) {
    return null;
  }

  return (
    <>
      <IconButton
        onClick={handleUpdatePopup}
        aria-label="Update Records"
        color="inherit"
      >
        <AutoFixHighIcon />
      </IconButton>

      <UpdateRecordPopup
        visible={isUpdatePopupOpen}
        onCancel={handleUpdatePopupCancel}
        onSubmit={handleSubmit}
        options={options}
        saveLoading={false}
        recordsNumber={Object.keys(records).length}
      />
    </>
  );
};

export default withSnackbar(UpdateRecordButton);
