import './style.css';
import { useState, FC, useCallback, useEffect } from 'react';
import { isEmpty, isNil, pick, size } from 'lodash';
import { TypeFilterValue, TypeSingleSortInfo, TypeSortInfo } from '@inovua/reactdatagrid-enterprise/types';
import LicensedReactDataGrid from 'src/components/UI/LicensedReactDataGrid';
import { useGetInventory } from 'src/hooks/inventory/useGetInventory';
import { Inventory, InventoryFilterInput, InventorySortInput, SortEnumType } from 'src/generated/dotnet.graphql';

const filter = [
  {
    name: 'productName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'manufacturer',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'fldPartNumber',
    operator: 'contains',
    type: 'select',
    value: '',
  },
];

const getSelectorByFilterName = async (
  name: string,
  value: any,
  operator: string
) => {

  switch (name) { 
    case 'pkey': {
      return {
        pkey: { in: value },
      };
    }
    case 'productName':
    case 'manufacturer':
    case 'fldPartNumber': {
      return {
        [name]: { contains: value },
      };
    }
    default:
      return {};
  }
};

type InventoryGridProps = {
  onSelect: (inventory: Inventory) => void;
  fldDataType: string;
};

const InventoryGrid: FC<InventoryGridProps> = ({ onSelect, fldDataType }) => {
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(100);
  const [filterValue, setFilterValue] = useState<any>({ fldDataType: { eq: fldDataType }});
  const [sortValue, setSortValue] = useState<any>({ productName: SortEnumType.Asc });
  const { data, totalCount, loading, loadData } = useGetInventory({ skip: skip, take: limit, where: filterValue, order: sortValue });

  useEffect(() => {
    loadData(skip, limit, filterValue, sortValue)
  }, [skip, limit, filterValue, sortValue])
  
  const dataSource = async () => {
    return {
      data,
      count: totalCount,
    };
  };

  const onSortInfoChange = (value: TypeSortInfo) => {
    const sortInfo = value as TypeSingleSortInfo
    if (isNil(sortInfo)) return;

    let sortPayload: InventorySortInput[];
    const sortDirection = sortInfo.dir === 1 ? SortEnumType.Asc : SortEnumType.Desc;
    const [field, subField] = sortInfo.name.split('.');

    if (subField) {
        // Handle nested objects
        sortPayload = [{
            [field]: {
                [subField]: sortDirection,
            },
        }];
    } else {
        // Handle non-nested objects
        sortPayload = [{
            [sortInfo.name]: sortDirection,
        }];
    }

    setSortValue(sortPayload)
  }

  const onFilterValueChange = async (filterValue: TypeFilterValue) => {
    if (isNil(filterValue)) return;

    const selectors = await Promise.all(
      filterValue.map(async (v: { value: any; name: any; operator: any }) => {
        if (isEmpty(v.value)) return null;
  
        const selector = await getSelectorByFilterName(v.name, v.value, v.operator);
        return selector;
      })
    );
  
    // Filter out null values
    const validSelectors = selectors.filter((selector) => selector !== null) as InventoryFilterInput[];

    // Combine the valid selectors into a single `where` object using `and`
    const filterPayload: InventoryFilterInput = validSelectors.length > 1
      ? { and: validSelectors }
      : validSelectors[0] || {};
      
    setFilterValue(filterPayload);
  };

  const onRowClick = useCallback(({ data }) => {
    onSelect(data);
  }, []);

  const columns = [
    {
      name: 'productName',
      header: 'Spares',
      flex: 1,
    },
    {
      name: 'manufacturer',
      header: 'Manufacturer',
      flex: 1,
    },
    {
      name: 'fldPartNumber',
      header: 'Part Number',
      flex: 1,
    },
  ];

  return (
    <>
      <div data-testid="data-grid" className="flex flex-col flex-grow spare-inventory-grid mt-2">
        <LicensedReactDataGrid
          idProperty="productId"
          skip={skip}
          onSkipChange={setSkip}
          limit={limit}
          onLimitChange={setLimit}
          pagination='remote'
          rowHeight={40}
          loading={loading}
          defaultFilterValue={filter}
          onFilterValueChange={onFilterValueChange}
          onSortInfoChange={onSortInfoChange}
          allowUnsort={false}
          onRowClick={onRowClick}
          columns={columns}
          dataSource={dataSource}
        />
      </div>
    </>
  );
};

export default InventoryGrid;
