import { FC, useState } from 'react';
import {
  Button,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  Snackbar,
  Alert,
  Autocomplete as MaterialAutocomplete,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { getDatabase } from 'src/rxdb';
import RelatedItemAddGrid from './RelatedItemAddGrid';
import { uuid } from 'uuidv4';
import { CustomDialog } from 'src/helpers/modals';

const dropDownOption = [
  'Certificates',
  'Equipment',
  'General Inventory',
  'Beverages Inventory',
  'Uniforms Inventory',
  'Dry & Regrigerated Stores',
  'Medical Inventory',
  'Equipment Schedules',
  'Orders',
];

interface InjectedProps {
  visible: boolean;
  initialValue: any;
  docName: any;
  onSave: () => void;
  onCancel?: () => void;
  gridKeyData?: any;
}

const RelatedDocAddDialog: FC<InjectedProps> = ({
  initialValue,
  docName,
  visible,
  onCancel,
  onSave,
  gridKeyData,
}) => {
  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });
  const [processing, setProcessing] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<any>(null);
  const [selectedOption, setSelectedOptions] = useState<any>(null);

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const onDropDownValueChange = (event: any, newValue: any) => {
    setSelectedOptions(newValue);
  };

  const handleSave = async () => {
    const db = await getDatabase();

    setProcessing(true);
    selectedItems.map(async (key: any) => {
      let tableName;
      switch (selectedOption) {
        case 'Equipment': {
          tableName = 'Equipment';
          break;
        }
        case 'General Inventory': {
          tableName = 'Inventory';
          break;
        }
        case 'Beverages Inventory': {
          tableName = 'Inventory';
          break;
        }
        case 'Uniforms Inventory': {
          tableName = 'Inventory';
          break;
        }
        case 'Dry & Regrigerated Stores': {
          tableName = 'Inventory';
          break;
        }
        case 'Medical Inventory': {
          tableName = 'Inventory';
          break;
        }
        case 'Certificates': {
          tableName = 'Certificates';
          break;
        }
        case 'Orders': {
          tableName = 'Orders';
          break;
        }
        case 'Equipment Schedules': {
          tableName = 'TblSchedMaintEq';
          break;
        }
        default: {
          tableName = undefined;
          break;
        }
      }

      const newItem = {
        fldFKey: initialValue,
        fldRecordKey: key,
        fldTableName: tableName,
        fldIsFavorite: false,
        fldVolume: null,
        fldFigure: null,
        fldPage: null,
        fldPageNum: null,
        DateEntered: null,
        PKey: uuid(),
        updatedAt: new Date().toISOString(),
      } as any;
      try {
        await db.collections.tbldocumentcrossreference.upsert(newItem);
      } catch (e: any) {
        setSnackbar({
          open: true,
          type: 'error',
          message: e.message,
        });
        setProcessing(false);
      }
    });
    setProcessing(false);
    setSelectedOptions(null);
    onSave();
  };

  const handleCancel = () => {
    onCancel && onCancel();
    setSelectedOptions(null);
    setProcessing(false);
    setSnackbar({
      open: false,
      type: 'success',
      message: '',
    });
  };

  const handleSelect = (items: any) => {
    setSelectedItems(items);
  };

  return (
    <>
      <CustomDialog
        scroll="paper"
        fullWidth
        maxWidth="md"
        open={visible}
        onClose={handleCancel}
      >
        <DialogTitle>
          <span className="font-bold text-2xl">{`Associated Item for ${docName}`}</span>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 0 }}>
          <div>
            <MaterialAutocomplete
              className="flex m-4 mt-5"
              disablePortal
              id="combo-box-demo"
              options={dropDownOption}
              onChange={onDropDownValueChange}
              // sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Relate Item From" autoComplete='off' size="small" />
              )}
            />
            {!selectedOption && (
              <div className="w-full my-40 flex justify-center items-center">
                Please Select a List Name
              </div>
            )}
            {selectedOption && (
              <RelatedItemAddGrid
                selectedOption={selectedOption}
                gridKeyData={gridKeyData}
                onSelect={handleSelect}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
          <Box
            sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}
          >
            <Button className="mr-2" onClick={handleCancel}>
              Cancel
            </Button>
            <LoadingButton
              loading={processing}
              type="submit"
              onClick={handleSave}
              variant="contained"
            >
              Save
            </LoadingButton>
          </Box>
        </DialogActions>
      </CustomDialog>

      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        onClose={onSnackbarClose}
      >
        <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
          {snackBar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default RelatedDocAddDialog;
