import React, { FC, useEffect, useState } from 'react';
import { isString } from 'lodash';
import { useForm } from 'react-hook-form';
import { Button, DialogTitle, Dialog, DialogActions, DialogContent, Box, Snackbar, Alert, Grid, Portal, IconButton } from '@mui/material';
import { Add } from '@mui/icons-material';
import { getDatabase } from 'src/rxdb';
import { InventoryDocument } from 'src/pages/InventoryPage/rxdb';
import { OrdersDocument } from 'src/rxdb/collections/Orders/schema';
import { TblDdListDefaults } from 'src/generated/graphql';
import Input from 'src/components/UI/Forms/Input';
import Autocomplete from 'src/components/UI/Forms/Autocomplete';
import CurrenciesDropdownOld from 'src/components/Dropdowns/CurrenciesDropdown/indexOld';
import RichTextEditor from 'src/components/UI/Forms/RichTextEditor';
import InventoryPopupOld from 'src/components/SparesTab/components/SparePopup/components/InventoryPopupOld';
import { toJSON } from 'src/utils';
import { useAppState } from 'src/contexts/app-state';

export enum ReceivedSelectedItemEnum {
  UseDefaults,
  ChooseAmount,
  ChooseLocationAndAmount,
};

interface InjectedProps {
  visible: boolean;
  initialValue: OrdersDocument;
  orderItemInitialValue: any;
  onCancel?: () => void;
  onSave?: (item:any, isCreated?: boolean) => void;
  title?: string;
  receiveItemOption?: ReceivedSelectedItemEnum | undefined;
}

const inventoryTypeOptions: any[] = [
  {
    InventoryType: "General Inventory",
    id: "0",
    dbFieldName: 'fldInv',
  },
  {
    InventoryType: "Beverages",
    id: "1",
    dbFieldName: 'fldInvBev',
  },
  {
    InventoryType: "Dry & Refrigerated Stores",
    id: "2",
    dbFieldName: 'fldInvStores',
  },
  {
    InventoryType: "Uniforms",
    id: "3",
    dbFieldName: "fldInvUni",
  },
  {
    InventoryType: "Medical Inventory",
    id: "4",
    dbFieldName: "fldInvMed",
  },
];

const defaultInventoryType: any = inventoryTypeOptions[0];

const OrderItemAddDialog: FC<InjectedProps> = ({
  visible,
  onCancel,
  initialValue,
  orderItemInitialValue,
  onSave,
  title,
  receiveItemOption,
}) => {
  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState,
  } = useForm<any>({
    defaultValues: {
      ProductName: orderItemInitialValue.ProductName || '',
      Manufacturer: orderItemInitialValue.Manufacturer,
      ModelNumber: orderItemInitialValue.ModelNumber,
      fldPartNumber: orderItemInitialValue.PartNumber,
      fldSize: orderItemInitialValue.fldSize,
      fldColor: orderItemInitialValue.fldColor,
      Qty: orderItemInitialValue.Qty,
      UnitPrice: orderItemInitialValue.UnitPrice,
      QCurrency: orderItemInitialValue.QCurrency,
      Amount: orderItemInitialValue.Amount,
      ProductDescription: orderItemInitialValue.ProductDescription,
      ProductDescriptionHTML: orderItemInitialValue.ProductDescription,
      PKey: orderItemInitialValue.PKey,
      ReceivedAmount: 0,
    },
  });

  const { settingsPersonal } = useAppState();
  const [inventoryType, setInventoryType] = useState<string>(defaultInventoryType.id);
  const [qtyList, setQtyList] = useState<InventoryDocument[]>([]);
  const [inventoryList, setInventoryList] = useState<InventoryDocument[]>([]);
  const [colorList, setColorList] = useState<InventoryDocument[]>([]);
  const [sizeList, setSizeList] = useState<InventoryDocument[]>([]);
  const [manufacturerLabel, setManufacturerLabel] = useState<string>("Manufacturer");
  const [modelNumberLabel, setModelNumberLabel] = useState<string>("Model Number");
  const [ProductID, setProductID] = useState<string>(orderItemInitialValue.ProductID);
  const [inventoryPopup, setInventoryPopup] = useState(false);

  const filteredInvOptions = inventoryTypeOptions.filter((it) => {
    return settingsPersonal[it.dbFieldName] === 2;
  })

  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });

  const onChange = async (name: string, value: any) => {
    setValue(name, value, { shouldDirty: true });
  };

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const handleCancel = () => {
    onCancel && onCancel();
  };

  const handleSave = async (data: any) => {
    const db = await getDatabase();

    let {
      Amount,
      PKey,
      fldDataType,
      ProductName,
      Manufacturer,
      ModelNumber,
      fldPartNumber,
      Qty,
      UnitPrice,
      QCurrency,
      ProductDescriptionHTML,
      fldSize,
      fldColor
    } = data;

    if (!orderItemInitialValue.autoId) {
      // Initialize the selector with common properties
      let selector: {
        OrderID: string;
        deletedBy: { $eq: null };
        ProductName?: string;
        ProductID?: string;
      } = {
        OrderID: initialValue.OrderID,
        deletedBy: { $eq: null },
      };

      // ProductID is empty means this is an new created item, noninventory
      if (ProductID === "") {
        // Noninventory items we check for name duplicate
        selector = { ...selector, ProductName };
      } else {
        // Inventory item we check for ProductID duplicate
        selector = { ...selector, ProductID };
      }
  
      const items = await db.orderitems
        .find({ selector })
        .exec();

      if (items && items.length > 0) {
        // Item is being created with already existing product ID
        setSnackbar({
          open: true,
          type: "error",
          message: "Duplicate items of inventory are not permitted on the same PO",
        });
        return;
      }
    }

    const QtyValue = isString(Qty)
      ? Qty
      : Qty?.fldMember || null;

    const QCurrencyValue = isString(QCurrency)
      ? QCurrency
      : QCurrency?.Curr || null;

    const ManufacturerValue = isString(Manufacturer)
      ? Manufacturer
      : Manufacturer?.Manufacturer || null;

    const fldColorValue = isString(fldColor)
      ? fldColor
      : fldColor?.fldMember || null;

    const fldSizeValue = isString(fldSize)
      ? fldSize
      : fldSize?.fldMember || null;

    const document = {
      Amount: parseFloat(Amount) || 0,
      OrderID: initialValue.OrderID,
      ProductID: ProductID,
      PKey,
      fldDataType: inventoryType,
      ProductName,
      Manufacturer: ManufacturerValue,
      ModelNumber,
      fldColor: fldColorValue,
      fldSize: fldSizeValue,
      PartNumber: fldPartNumber,
      Qty: QtyValue,
      UnitPrice: parseFloat(UnitPrice) || 0,
      QCurrency: QCurrencyValue,
      ProductDescription: ProductDescriptionHTML,
      AmtReceived: orderItemInitialValue.AmtReceived,
      updatedAt: new Date().toISOString(),
    } as any;

    try {
      const res = await db.orderitems.upsert(document);

      onSave && onSave(res);
      reset();
    } catch (e: any) {
      setSnackbar({
        open: true,
        type: 'error',
        message: e.message,
      });
    }
  };

  const onChangeInventoryType = (name: string, value: any) => {
    setInventoryType(value.id);
    onChange(name, value);
  }

  const loadInventoryList = async (dataType: string) => {
    const db = await getDatabase();
    let inventory: any[] = await db.inventory
      .find({
        selector: {
          fldDataType: dataType,
          deletedAt: null,
        },
      })
      .exec();
    inventory.sort((a: InventoryDocument, b: InventoryDocument) => a.ProductName > b.ProductName ? 1 : -1);
    setInventoryList([...inventory]);
  }

  const setInputLabels = (inventoryTypeValue: string) => {
    let manufacturerLabel: string = "";
    let modelNumberLabel: string = "";

    switch (inventoryTypeValue) {
      case "0": manufacturerLabel = "Manufacturer";
        modelNumberLabel = "Model Number";
        break;
      case "1": manufacturerLabel = "Maker / Bottler";
        modelNumberLabel = "Year / Vintage";
        break;
      case "3": manufacturerLabel = "Manufacturer";
        modelNumberLabel = "Style";
        break;
      case "2":
      case "4": manufacturerLabel = "Manufacturer";
        break;
    }

    setManufacturerLabel(manufacturerLabel);
    setModelNumberLabel(modelNumberLabel);
  }

  useEffect(() => {
    (async () => {
      const db = await getDatabase();
      db.tblddlistdefaults.find({ selector: { deletedBy: { $eq: null } }}).$.subscribe((defaults: TblDdListDefaults[]) => {
        const qtyList = defaults.filter((d: TblDdListDefaults) => d.fldListName === "Qty" && d.fldMember !== "");
        const colorList = defaults.filter((d: TblDdListDefaults) => d.fldListName === "Colors" && d.fldMember !== "");
        const sizeList = defaults.filter((d: TblDdListDefaults) => d.fldListName === "Sizes" && d.fldMember !== "");
        setQtyList(toJSON(qtyList));
        setColorList(toJSON(colorList));
        setSizeList(toJSON(sizeList));
      });
      db.inventory.$.subscribe(async (ev: any) => {
        loadInventoryList(inventoryType);
      });
    })();

    // set default value for inventory type
    let inventoryTypeValue: any = inventoryTypeOptions.find((v: any) => v.id === orderItemInitialValue.fldDataType) ?? defaultInventoryType;
    setValue("fldDataType", inventoryTypeValue.InventoryType);
    setInventoryType(inventoryTypeValue.id);
  }, []);

  useEffect(() => {
    setInputLabels(inventoryType);
    loadInventoryList(inventoryType);
  }, [inventoryType]);

  const renderColorOption = (props: React.HTMLAttributes<HTMLLIElement>, option: any) => {
    return (
      <li {...props} key={props.id}>
        <span
          style={{
            backgroundColor: option["fldMember"],
            display: "inline-block",
            height: "20px",
            marginRight: "8px",
            width: "20px",
          }}
        ></span>
        {option["fldMember"]}
      </li>
    )
  }

  const amount = watch('Amount', 0);
  const costPerUnit = watch('UnitPrice', 0);
  let extCost: number = Number((amount * costPerUnit).toFixed(2));
  if (isNaN(extCost)) extCost = 0;
  setValue("ExtendedCost", extCost);

  const onChangeProductName = (name: string, product: InventoryDocument) => {
    if (typeof product === "string") {
      setProductID("");
      onChange(name, product);
      onChange("Manufacturer", "");
      onChange("fldPartNumber", "");
      onChange("ModelNumber", "");
      onChange("Qty", "");
    }
    else {
      setProductID(product?.ProductID ?? "");
      onChange(name, product?.ProductName ?? "");
      onChange("Manufacturer", product?.Manufacturer ?? "");
      onChange("fldPartNumber", product?.fldPartNumber ?? "");
      onChange("ModelNumber", product?.ModelNumber ?? "");
      onChange("Qty", product?.Qty ?? "");
    }
  }

  const manufacturerList: InventoryDocument[] = [];
  inventoryList.forEach((value: InventoryDocument) => {
    if (manufacturerList.filter((v: InventoryDocument) => v.Manufacturer === value.Manufacturer).length === 0) {
      manufacturerList.push(toJSON(value));
    }
  });

  const addNewItem = () => {
    setInventoryPopup(true);
  }

  const onSelectInventory = (inventory: InventoryDocument) => {
    onChangeProductName("ProductName", inventory);
    setInventoryPopup(false);
  }

  return (
    <>
      <form
        className="relative bg-white flex-grow"
        onSubmit={handleSubmit(handleSave)}
      >
        <Dialog
          scroll="paper"
          fullWidth
          maxWidth="md"
          open={visible}
          onClose={handleCancel}
        >
          <DialogTitle>
            <span className="font-bold text-2xl">{title} Item Details</span>
          </DialogTitle>
          <DialogContent dividers sx={{ p: 4 }}>
            <Grid container spacing={5}>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  onChange={onChangeInventoryType}
                  label={"Inventory Type *"}
                  options={filteredInvOptions}
                  control={control}
                  name={"fldDataType"}
                  keyExpr="id"
                  displayExpr="InventoryType"
                  rules={{ required: true }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={5} mt={1}>
              <Grid item xs={12}>
                <Box position={"relative"}>
                  <IconButton
                    aria-label="Add Item"
                    size="small"
                    onClick={addNewItem}
                    sx={{ position: "absolute", top: "2px", right: "60px", zIndex: 2 }}
                  >
                    <Add />
                  </IconButton>
                  <Autocomplete
                    onChange={(name: string, value: any) => { onChangeProductName(name, value) }}
                    label={"Product Name *"}
                    options={inventoryList}
                    control={control}
                    freeSolo
                    name={"ProductName"}
                    keyExpr="autoId"
                    displayExpr="ProductName"
                    rules={{ required: true }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  onChange={onChange}
                  label={manufacturerLabel}
                  options={manufacturerList}
                  control={control}
                  name={"Manufacturer"}
                  keyExpr="autoId"
                  displayExpr="Manufacturer"
                  freeSolo
                />
              </Grid>
              {inventoryType === "0" || inventoryType === "4"
                ? <Grid item xs={12}>
                  <Input
                    name="fldPartNumber"
                    inputProps={{
                      label: 'Part Number',
                      InputLabelProps: { shrink: true },
                    }}
                    control={control}
                  />
                </Grid>
                : null
              }
              {inventoryType !== "4" && inventoryType !== "2"
                ? <Grid item xs={12}>
                  <Input
                    name="ModelNumber"
                    inputProps={{
                      label: modelNumberLabel,
                      InputLabelProps: { shrink: true },
                    }}
                    control={control}
                  />
                </Grid>
                : null
              }
              {inventoryType === "3"
                ? <>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      onChange={onChange}
                      label={"Size"}
                      options={sizeList}
                      control={control}
                      name={"fldSize"}
                      keyExpr="autoId"
                      displayExpr="fldMember"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      onChange={onChange}
                      label={"Color"}
                      options={colorList}
                      control={control}
                      name={"fldColor"}
                      keyExpr="autoId"
                      displayExpr="fldMember"
                      renderOption={renderColorOption}
                    />
                  </Grid>
                </>
                : null
              }
              <Grid item xs={12} md={6}>
                <Input
                  name="Amount"
                  inputProps={{
                    label: 'Amount *',
                    type: 'number',
                    inputProps: {
                      min: 0,
                      inputMode: 'decimal',
                      step: 1,
                    },
                  }}
                  control={control}
                  rules={{
                    min: 0,
                    required: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  onChange={onChange}
                  label={"Unit"}
                  options={qtyList}
                  control={control}
                  name={"Qty"}
                  keyExpr="autoId"
                  displayExpr="fldMember"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  name="UnitPrice"
                  inputProps={{
                    label: 'Cost Per Unit',
                    type: "number",
                    inputProps: {
                      min: 0,
                      inputMode: 'decimal',
                      step: 0.01,
                    },
                  }}
                  rules={{
                    min: 0,
                  }}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CurrenciesDropdownOld
                  label="Currency"
                  onChange={onChange}
                  control={control}
                  name="QCurrency"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  name="ExtendedCost"
                  inputProps={{
                    label: 'Extended Cost',
                  }}
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <RichTextEditor
                  control={control}
                  name="ProductDescriptionHTML"
                  onChange={onChange}
                  label="Product Description"
                />
              </Grid>
            </Grid>

            <InventoryPopupOld
              fldDataType={inventoryType}
              inventoryPopup={inventoryPopup}
              onCancelInventoryPopup={() => setInventoryPopup(false)}
              onSelectInventory={onSelectInventory}
            />

          </DialogContent>
          <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
            <Box  sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}>
              <Button className="mr-2" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                onClick={handleSubmit(handleSave)}
                className="mr-3"
                variant="contained"
              >
                Save
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      </form>
      <Portal>
        <Snackbar
          open={snackBar.open}
          autoHideDuration={2000}
          onClose={onSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Portal>
    </>
  );
};

export default OrderItemAddDialog;
