import {
  FC, useEffect, useRef, useState,
} from 'react';
import { Subscription } from 'rxjs';
import { Image } from '@mui/icons-material';
import { Icon } from '@mui/material';
import paperClip from '../../../../../assets/icon-paperclip.svg';
import {MangoQuerySelector} from "rxdb/dist/types/types";
import {getAttachmentCount} from "../../../../../helpers/attachments";
import AddIcon from '@mui/icons-material/Add';

interface AttachmentIconProps {
  selector?: MangoQuerySelector;
  showErrorIcon?: boolean;
}

const GridRowAttachmentIcon: FC<AttachmentIconProps> = ({ selector, showErrorIcon }) => {
  const [attCount, setAttCount] = useState<number>(0);
  const [photoCount, setPhotoCount] = useState<number>(0);
  const activeSubscription = useRef<Subscription>();

  const updateCount = (aCount: number, pCount: number) => {
    setAttCount(aCount);
    setPhotoCount(pCount);
  };

  const getDocumentCount = async () => {
    if (selector) {
      activeSubscription.current = await getAttachmentCount(selector, updateCount);
    }
  };

  useEffect(() => {
    getDocumentCount();

    return () => {
      activeSubscription.current?.unsubscribe();
    }
  }, [selector]);

  return (
    <div className="flex">
      {attCount > 0 && (
        <Icon>
          <img src={paperClip} alt="Attachment icon" />
        </Icon>
      )}
      {photoCount > 0 && <Image />}

      {showErrorIcon && <AddIcon color='error'/>}
    </div>
  );
};

export default GridRowAttachmentIcon;
