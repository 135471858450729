import { isNil } from 'lodash';
import { TblSparesUsedDocument } from 'src/components/SparesTab/rxdb';
import { TblSchedChk } from 'src/generated/graphql';
import { TDIDb } from 'src/rxdb';

// RXDBRemoval - change Node checks type with dotnet checks type
export const extractChecks = (checks: string | undefined | null): TblSchedChk[] => {
  if (checks !== undefined && checks !== null) {
    const parsedChecks = JSON.parse(checks);
    parsedChecks.sort((a: { fldTitle?: string }, b: { fldTitle?: string }) => { 
      if (a.fldTitle && b.fldTitle) {
        return a.fldTitle.localeCompare(b.fldTitle);
      }
      return 0;
    });
    return parsedChecks;
  }
  return [];
}

export const validateForm = (data: any, setSnackbar: any) => {
  const {logDate, fldSrhkey, fldPerformedBy } = data;

  if (isNil(fldSrhkey) || isNil(logDate) || isNil(fldPerformedBy)) {
    setSnackbar({
      open: true,
      message: 'Please fill required field(s). Check form field(s) marked with red color',
      type: 'error',
    });
    return false;
  }

  return true;
};

export const updateSpares = async (spares: TblSparesUsedDocument[], db: TDIDb, document: any) => {
  await Promise.all(
    spares.map(async (spare) => {
      // TODO: Handle case when multiple locations per ProductID can be found
      const location = await db.tblmultiplelocations
        .findOne({
          selector: {
            ProductID: spare.ProductID,
          },
        })
        .exec();

      await db.tblsparesused.atomicUpsert({
        ...spare.toJSON(),
        LogKey: document.PKey,
      });

      if (!isNil(location)) {
        await location.atomicPatch({
          Amount: Math.max(
            (location?.Amount || 0) - (spare.Amount || 0),
            0
          ),
        });
      }

      return spare;
    })
  );
}
