import { useCallback, useEffect, useRef, useState } from "react";
import { Alert, Button, CircularProgress, Grid, IconButton, Tooltip, useMediaQuery } from "@mui/material";
import { v4 as uuid } from 'uuid';
import './../../../App.css'
// import './index.css';
import {
  IgrExcelXlsxModule,
  IgrExcelCoreModule,
  IgrExcelModule,
  CellReferenceMode,
} from "igniteui-react-excel";
import {
  IgrSpreadsheetModule,
  IgrSpreadsheet,
  SpreadsheetCell,
  SpreadsheetAction,
} from "igniteui-react-spreadsheet";
import { isEmpty, isNil, isString } from 'lodash';
import { ExcelUtility } from "./ExcelUtility";
import { useHistory } from "react-router-dom";
import { getDatabase } from "src/rxdb";
import ErrorDialog from "src/components/UI/ErrorDialog";
import { formatDateISOshort, newFormatDate } from "src/utils/format-dates";
import { DeleteTwoTone } from "@mui/icons-material";
import { withSnackbar } from "src/components/UI/SnackbarHOC";
import { LoadingButton } from "@mui/lab";
import WarningDialog from "src/components/UI/WarningDialog";
import Slider from '@mui/material/Slider';
import ZoomOut from '@mui/icons-material/ZoomOut';
import ZoomIn from '@mui/icons-material/ZoomIn';
import { useAppState } from 'src/contexts/app-state';
import moment from "moment";
import { useAuth } from "src/contexts/auth";
import RestoreTwoToneIcon from '@mui/icons-material/RestoreTwoTone';
import { AMCS_ENGLOG_URL } from "src/consts";
import { AMCSBadge } from "src/components/UI/AMCSBadge";
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import withFormProgressTracking, { WithFormProgressTrackingProps } from 'src/hoc/withFormProgressTracking';
import { logDateFormat, updateFilename } from "../utils";

IgrExcelCoreModule.register();
IgrExcelModule.register();
IgrExcelXlsxModule.register();
IgrSpreadsheetModule.register();

interface Props extends WithFormProgressTrackingProps  {
  url: string;
  pkey?: string;
  snackbarShowMessage: (
    message: string,
    variant: "success" | "error",
    duration: number
  ) => void;
  create: boolean;
  setSavingState?: (value: boolean) => void;
  setLogDateTitle: (logDate: string) => void;
}

const SpreadsheetOverview = (props: Props) => {
  const history = useHistory();
  const { user } = useAuth(); 
  const { settingsPersonal } = useAppState();
  const spreadsheet = useRef<IgrSpreadsheet | null>(null);
  const [isSpreadsheetLoaded, setIsSpreadsheetLoaded] = useState(false);
  const [enableSave, setEnableSave] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDuplicateDay, setIsDuplicateDay] = useState(false);
  const [isInRecycleBin, setIsInRecycleBin] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [fileError, setFileError] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isNewSelection, setIsNewSelection] = useState(false);
  const [zoomValue, setZoomValue] = useState<any>(100);
  const [disableEdit, setDisableEdit] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [fatchingFail, setFatchingFail] = useState(false);
  const [amcsButtonDisplay, setAmcsButtonDisplay] = useState(false);
 
  const closeError = useCallback(() => {
    setErrorMsg("")
    setIsSaving(false)
  }, []);
  const closeWarning = useCallback(() => {setIsDuplicateDay(false)
  setIsInRecycleBin(false)
  }, []);

  const confirmDelete = useCallback(() => setIsDeleting(true), []);

  const setRecordEditPermission = async ()=>{
    if(settingsPersonal.fldLogsDay === 1){
      setDisableEdit(true)
    }
  }
  
  const setAMCButtonPermission = async()=>{
    const db = await getDatabase()
    const buttonShow = await db.tblsettingsapplication.findOne({
      selector: {
        fldPref: "MONSYS"
      }
    }).exec()
    if(buttonShow){
      setAmcsButtonDisplay(true)
    }
  }

  useEffect(()=>{
    setAMCButtonPermission()
  },[])

  useEffect(()=>{
    if(settingsPersonal){
      setRecordEditPermission()
    }
  },[settingsPersonal])


  const handleDeleteOk = useCallback(async () => {
    if (props.pkey) {
      const db = await getDatabase();
      const item = await db.engineerdaylog
        .findOne({ selector: { PKey: { $eq: props.pkey } } })
        .exec();

      if (!item) return;
      await item.remove();
      setIsDeleting(false);
      setTimeout(() => history.goBack(), 2000);
      props.snackbarShowMessage("Day Log Item Deleted", "success", 2000);
    }
  }, []);

  const handleDeleteCancel = useCallback(() => setIsDeleting(false), []);

  const [savingValidationWarning, setSavingValidationWarning] = useState<boolean>(false);
  const handleSavingValidationWarning = () => {
    setSavingValidationWarning(false)
  }

  const handleBack = useCallback(async () => {
    const isEditMode = spreadsheet?.current?.isInEditMode;
    if(isEditMode) {
      setSavingValidationWarning(true)
    } else {
      if (props.pkey) {
        const db = await getDatabase();
        const item = await db.engineerdaylog
          .findOne({ selector: { PKey: { $eq: props.pkey } } })
          .exec();
        await item?.atomicPatch({ isLocked: false });
      }
      history.push("/logs/engday-log");
    }
  }, [history]);

  const checkInternetConnection = async () => {
    const headers = new Headers();
    headers.append('cache-control', 'no-cache');
    headers.append('pragma', 'no-cache');
    try {
        await fetch('https://google.com/', { method: 'HEAD', headers, mode: 'no-cors' });
        return true;
    } catch (error) {
        if (error instanceof TypeError) {
            return false;
        }
        throw error;
    }
  };

  const handleSave = useCallback(async () => {
    const internetStatus = await checkInternetConnection()
    if (!internetStatus) {
      setErrorMsg("You are currently offline. Please connect to the internet and try again.");
      return;
    }
    setIsSaving(true);
    if (!spreadsheet?.current) {
      return;
    }
    const sheet = spreadsheet.current?.activeWorksheet;
    const originalDate = sheet.getCell("REPORTDATE").getText();

    spreadsheet.current?.executeAction(SpreadsheetAction.ExitEditModeAndUpdateActiveCell);

    const location = String(sheet?.getCell("VESSELLOCATION")?.value || '') ;
    const mode = String(sheet?.getCell("VESSELMODE")?.value || '') ;
    const dateText = sheet.getCell("REPORTDATE").getText();
    const isoDate = formatDateISOshort(dateText);

    const formattedDate = moment
      .utc(isoDate)
      .utcOffset(0)
      .set({ hour: 0, minute: 0, second: 0 })
      .toISOString();    

    const db = await getDatabase();
    const existingDate = await db.engineerdaylog.find({
      selector:{
        fldLogDate: { $eq: formattedDate },
        deletedBy:{$eq: null},
        isRecoverable:{$eq: null},
      }
    }).exec()

    const existingItem = await db.engineerdaylog
      .findOne({ selector: { PKey: { $eq: props.pkey } } })
      .exec();
        
    if (existingItem) {
      if (existingDate.length > 0 && existingDate[0].PKey !== existingItem.PKey) {
        sheet.getCell("REPORTDATE").value = originalDate; // Reset date
        setIsDuplicateDay(true);
        setIsSaving(false);
        return;
      }
    } else if (existingDate.length > 0) {
      sheet.getCell("REPORTDATE").value = originalDate; // Reset date
      setIsDuplicateDay(true);
      setIsSaving(false);
      return;
    }

    // if there is no pkey, it is a new entry
    if (!props.pkey) {
      const recycleBinCheck = await db.engineerdaylog.find({
        selector:{
          fldLogDate: { $eq: formattedDate },
          isRecoverable:{$eq: true},
        }
      }).exec()

      if (recycleBinCheck.length > 0) {
        setIsInRecycleBin(true);
        setIsSaving(false);
        return;
      }

      const day = moment.utc(isoDate).format('YYYYMMDD')
      const templateName = await db.tblsettingsapplication.find({
        selector: {
          fldPref: "DLFILE"
        }
      }).exec()
   
      const fileName = templateName[0].fldSetting;
      let fileExtension = fileName?.split('.').pop() || 'XLS';
      const filename = `ENG_${day}.${fileExtension}`;
    
      const doc= {
        PKey: uuid(),
        fldReportFile: filename,
        fldReportType: "ENG",
        isLocked: false,
        fldApproxLocation: location || null,
        fldMode: mode || null,
        fldLogDate: dateText,
        fldCreatedBy: user?.fldCrewID || '',
        fldCreatedByName: user ? `${user.fldFirst} ${user.fldLast}` : '',
        fldDateCreated: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      }
      try {
        await db.collections.engineerdaylog.upsert(doc)
      }catch(e:any){
        return setErrorMsg(e);
      }
    }

    const refs = await db.tbldl4cellrefs.find().exec();

    const cells = refs
      .filter((r) => r.fldDataType === "0" && r.fldCell && r.EqKey)
      .map((r) => {
        const addrs = r.fldCell ? r.fldCell.split(",") : [""];
        const [firstAddr, secondAddr] = addrs;
        let hours = 0;
        const firstHours = sheet.getCell(firstAddr).value;
        if (firstHours) {
          if(isString(firstHours)){
            hours = 0
          } else {
          hours = firstHours;
          }
        }
        if (!isNil(secondAddr)){
          const secondHours = sheet.getCell(secondAddr).value;  
          if (secondHours) {
            if(isString(secondHours)){
              hours = 0
            } else {
            hours = secondHours;
            }
          }
        }
        return {
          equipment: r.EqKey || "",
          hours,
        };
      });
   
    try { 
      await ExcelUtility.saveToUrl(spreadsheet.current.workbook, {
        pkey: props.pkey,
        location,
        mode,
        date: formatDateISOshort(dateText),
        cells,
      });

      if (props.pkey && existingItem) {  
        const day = moment.utc(isoDate).format('YYYYMMDD')
        const updatedFilename = updateFilename(existingItem.fldReportFile, day)

        const document = { 
          isLocked: false, 
          fldMode: mode, 
          fldApproxLocation: location, 
          fldLogDate: dateText,
          fldReportFile: updatedFilename,
        };

        await existingItem?.atomicPatch(document);

        setEnableSave(false)
        props.setLogDateTitle(` - ${logDateFormat(dateText)}`)
        props.setSavingState && props.setSavingState(false)
        props.setFormIsDirty && props.setFormIsDirty(false)
      }

      props.snackbarShowMessage("Day Log Saved", "success", 2000);
      if(props.create){
        props.setFormIsDirty && props.setFormIsDirty(false) // Fix TD-2279 [Arience] First time saving a Day Log you get prompted there are unsaved changes.
        handleBack();
      }
    } catch (e: any) {
      return setErrorMsg(e);
    }
    setIsSaving(false);
  }, [history, spreadsheet]);

  useEffect(() => {
    // lock the file entry in the db when the document is dirtied
    if (!enableSave) return;
    const lock = async () => {
      const db = await getDatabase();
      const item = await db.engineerdaylog
        .findOne({ selector: { PKey: { $eq: props.pkey } } })
        .exec();
      await item?.atomicPatch({ isLocked: true });
    };
    lock();
  }, [enableSave]);

  // this will handle the window onclick event
  const handleClick = useCallback(() => {
    const s = spreadsheet?.current;
    if (!s) return;
    const selectedCell = s.activeCell;
    if (!selectedCell) return;

    // if it is new, we can skip, since the activecellchanged event will handle this case
    if (isNewSelection) {
      setIsNewSelection(false); // the selection is no longer new after it has fired once
      return;
    }
    // subsequent onclick that make it here should be repeat clicks of the current selection
    // which is the functionality we need to toggle the current selection as intended
    // this is all fallout from not having a generic onclick/onpress event to hook into from the spreadsheet library
    toggleCheckmarks(s, selectedCell);
  }, [spreadsheet, isNewSelection]);

  // this listener is a workaround for the spreadsheet library not exposing an onclick handler
  useEffect(() => {
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  useEffect(() => {
    ExcelUtility.loadFromUrl(props.url)
      .then((w) => {
        setIsSpreadsheetLoaded(true);
        if (!spreadsheet?.current) return;
        // TODO: This is a hack to get the spreadsheet to hide items - doesn't work as component props.
        // https://codesandbox.io/s/github/IgniteUI/igniteui-react-examples/tree/master/samples/excel/spreadsheet/overview?fontsize=14&hidenavigation=1&theme=dark&view=preview&file=/src/index.tsx
        setTimeout(() => {
          if(spreadsheet.current) {
            spreadsheet.current.areHeadersVisible = false;
            spreadsheet.current.isFormulaBarVisible= false;
            spreadsheet.current.areGridlinesVisible= false;
          }
        });
        spreadsheet.current.workbook = w;

        // if this is a new document (no PKey) set todays date
        if (!props.pkey) {
          const cell =
            spreadsheet.current.activeWorksheet.getCell("REPORTDATE");
          if (cell) {
            cell.value = newFormatDate();
          }
        }

        // 'listen' to spreadsheet, enable save if edited
        spreadsheet.current.workbookDirtied = () => {
          setEnableSave(true);
          props.setSavingState && props.setSavingState(true)
          props.setFormIsDirty && props.setFormIsDirty(true)
        }
        spreadsheet.current.editModeEntered = () => {
          setEnableSave(true);
          props.setSavingState && props.setSavingState(true)
          props.setFormIsDirty && props.setFormIsDirty(true)
        }
        
        // business requirements dictate that we need to add some special interactivity
        // to particular ranges in the eng day log worksheet. This is using active cell changed, which
        // is the closest analog to an 'onclick' I could find in the docs
        // so this interaction isn't quite right, but should suffice until a better method is identified
        // we have added a global onclick event, and we track selected state to prevent duplicate toggles from occuring
        spreadsheet.current.activeCellChanged = (s, e) => {
          // this is a new selection, since the active changed. This will cause the onclick eventto be skipped
          // we have to do it this way, since we do not have a browser event object to stop propagation
          setIsNewSelection(true);
      
          toggleCheckmarks(s, e.newValue);
        };
      })
      .catch((e) => {
        setIsSpreadsheetLoaded(true);
        setFileError(true);
        console.error(e.message);
      });
  }, [props.url, spreadsheet, isSpreadsheetLoaded]);

  const OnFatchCancleClick = () =>{
    setFatchingFail(false)
  }
  
  const isNumber = (value:string) =>{
    return /^-?\d*\.?\d+$/.test(value);
  }

  const handleAMCSClick = async () => {
    setIsFetching(true)
    const db = await getDatabase()
    const objectData = await db.tbldl4cellrefs.find().exec()
    let body
    try {
      try {
        const response = await fetch(AMCS_ENGLOG_URL)
        body = await response.json();
      } catch (e) {
        setFatchingFail(true)
      }

      if (body.errors || isEmpty(body)) {
        setFatchingFail(true)
        return;
      }

      for (const reading of body) {
        const matchingObject = objectData.find(obj => obj.fldTag === reading.tag);

        if (matchingObject?.fldCell && spreadsheet.current) {
          let selectedCellValue = matchingObject.fldCell;
          if (matchingObject.fldCell.includes(',')) {
            const currentTime = new Date();
            const hours = currentTime.getHours();
            const minutes = currentTime.getMinutes();
            const cellValues = matchingObject.fldCell.split(',');
            if (hours < 12 || (hours === 12 && minutes === 0)) {
              // System time is before 12:00 PM
              selectedCellValue = cellValues[0];
            } else {
              // System time is after 12:00 PM
              selectedCellValue = cellValues[1];
            }
            }
          const cell = spreadsheet.current.activeWorksheet.getCell(selectedCellValue);
          if (cell) {
            if(isNumber(reading.value)){
              cell.value = parseFloat(reading.value)
            } else{
            cell.value = reading.value
            }
          }
        }
      }
      props.snackbarShowMessage("Day log data updated!", "success", 2000);
    } catch (e) {
      setFatchingFail(true)
    }
    setIsFetching(false)
  }

  const isTabletVertical = useMediaQuery('(max-width: 820px)');
  const isTabletHorizontal = useMediaQuery('(max-width: 1368px)');

  return (
    <Grid container className="w-full h-full">

      <Grid item xs={isTabletVertical ? 11 : 12} md={isTabletHorizontal ? 11 : 11.5}>
        {/* p-2 and h-2 are .5rem each, for the button section below, so we need to calc(100% - 1rem) the remaining height */}
        <div
          className="items-center justify-center w-full flex"
          style={{
            height: 'calc(100% - 3rem)',
          }}
        >
          {!isSpreadsheetLoaded ? (
            <CircularProgress />
          ) : fileError ? (
            <Alert severity="error">
              There was an issue loading the file. Please contact support for more information (<a id='contactLink' href="mailto:support@yms360.com">support@yms360.com</a>).
            </Alert>
          ) : (
            <IgrSpreadsheet
              ref={spreadsheet}
              height="100%"
              width="100%"
              zoomLevel={zoomValue}
              areGridlinesVisible={false}
              areHeadersVisible={false}
              isFormulaBarVisible={false}
            />
          )}
        </div>
      </Grid>

      <Grid item xs={isTabletVertical ? 1 : 12} md={isTabletHorizontal ? 1 : 0.5}>
        <Grid container className="mt-20 mr-2" direction="column" alignItems="center" justifyContent="center">
          <IconButton
            onClick={() => {
              if (zoomValue <= 195) setZoomValue(zoomValue + 5);
            }}
            aria-label="Zoom in"
            disabled={isSaving || !isSpreadsheetLoaded}
          >
            <ZoomIn />
          </IconButton>
          
          <Slider
            orientation="vertical"
            aria-label="Volume"
            min={50}
            max={200}
            step={5}
            value={zoomValue}
            className="h-32"
            disabled={isSaving || !isSpreadsheetLoaded}
            onChange={(
              event: Event,
              value: number | number[],
              activeThumb: number
            ) => {
              // console.log('zoom', value);
              setZoomValue(value);
            }}
          />

          <IconButton
            onClick={() => {
              if (zoomValue >= 55) setZoomValue(zoomValue - 5);
            }}
            aria-label="Zoom out"
            disabled={isSaving || !isSpreadsheetLoaded}
          >
            <ZoomOut />
          </IconButton>

          {!disableEdit && (<LoadingButton
            // variant="contained"
            onClick={handleSave}
            disabled={!enableSave}
            loading={isSaving}
          >
            <SaveTwoToneIcon/>
          </LoadingButton>)}

          <Button onClick={handleBack} disabled={isSaving}>
            <CloseTwoToneIcon/>
          </Button>

          {amcsButtonDisplay && 
            <Tooltip title='Poll AMCS'>
              <IconButton 
              onClick={handleAMCSClick}
              disabled={isFetching}
              style={{ marginBottom: '5px' }}
              >
                <AMCSBadge color="secondary" badgeContent="AMCS" >
                  <RestoreTwoToneIcon/>
                </AMCSBadge>
              </IconButton>
            </Tooltip>
          }
          
          {!disableEdit && props.pkey &&(<IconButton
            onClick={confirmDelete}
            color="error"
            aria-label="Delete item"
            disabled={isSaving || !isSpreadsheetLoaded}
          >
            <DeleteTwoTone />
          </IconButton>)}
        </Grid>
      </Grid>

      <ErrorDialog
        onClose={closeError}
        okText={'Ok'}
        visible={Boolean(errorMsg)}
        content={errorMsg}
        title="Error"
      />
      <ErrorDialog
        onClose={closeWarning}
        okText={'Ok'}
        visible={isDuplicateDay}
        content="There is already a document for selected day"
        title="Error"
      />
      <ErrorDialog
        onClose={closeWarning}
        okText={'Ok'}
        visible={isInRecycleBin}
        content="An entry for the specified date is in the Recycle bin, you need too either restore and use the existing entry OR permanently delete and create a new entry. "
        title="Error"
      />
      <WarningDialog
        visible={isDeleting}
        title="Delete Warning"
        okText="Yes"
        color="error"
        content="Are you sure you wish to delete, this action can not be undone."
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />
      <WarningDialog
        visible={fatchingFail}
        title="Server error"
        content="Something went wrong. Check your connection and try again. If the problem persists, please contact support"
        okText='Yes'
        color='error'
        onCancel={OnFatchCancleClick}
      />
      <WarningDialog
        visible={savingValidationWarning}
        title="Warning: Unsaved changes."
        content="Please save changes before switching tabs."
        cancelText="Close"
        color="warning"
        onOk={handleSavingValidationWarning}
        disabled={true}
        onCancel={handleSavingValidationWarning}
      />  
    </Grid>
  );
};

const toggleCheckmarks = (s: IgrSpreadsheet, selectedCell: SpreadsheetCell) => {
    // there are two ranges to check, it is recommended to check dlornrrange first for unknown reasons
    const dlnr = s.activeWorksheet.getRegion("DLOKNRRANGE");
    const oknrs = s.activeWorksheet.getRegions("OKNR");
  
    // Create a flag to keep track of whether we found the selected cell in any of the regions
    let foundInAnyRegion = false;
  
    // Check each region in the array
    if(oknrs){
      for (const oknr of oknrs) {
        if (
          oknr.firstColumn <= selectedCell.column &&
          oknr.firstRow <= selectedCell.row &&
          oknr.lastColumn >= selectedCell.column &&
          oknr.lastRow >= selectedCell.row
        ){
          foundInAnyRegion = true;
    
          // e.newValue returns a SpreadSheet Cell, which doesn't have any capability to get or set values
          // which would be ideal. What we need is a WorkSheet Cell, but unfortunately there doesn't
          // appear to be a direct way to convert between the two types
          // So here we use row and column indexes, convert them to 1 based indexes (excel's format),
          // and then explicitly use the R1C1 reference mode
          const cell = s.activeWorksheet.getCell(
            `R${selectedCell.row + 1}C${selectedCell.column + 1}`,
            CellReferenceMode.R1C1
          );
    
          // there are two columns of values, left is checkmarks, and right is x's
          // this is a checklist, and we are making it so that you only need to
          // click on the cell to set or remove these marks
    
          // note that we always clear the 'other' cell, as it does not make sense to have both values set
          // also, wingding font is used, which is why we are setting variations of u
          // ü is a checkmark
          // û is a x mark
    
          // this is the left side, checkmark side
          if (oknr.firstColumn === selectedCell.column) {
            if (cell.value === "ü") {
              cell.value = "";
            } else {
              cell.value = "ü";
            }
    
            const other = s.activeWorksheet.getCell(
              `R${selectedCell.row + 1}C${selectedCell.column + 2}`,
              CellReferenceMode.R1C1
            );
            other.value = "";
          }
    
          // this is the right side, x side
          if (oknr.lastColumn === selectedCell.column) {
            if (cell.value === "û") {
              cell.value = "";
            } else {
              cell.value = "û";
            }
    
            const other = s.activeWorksheet.getCell(
              `R${selectedCell.row + 1}C${selectedCell.column}`,
              CellReferenceMode.R1C1
            );
            other.value = "";
          }
        }
      }
  
    // Check the DLORNRRANGE if the cell was not found in any OKNR region
      if (!foundInAnyRegion && dlnr) {
        if (
          dlnr.firstColumn <= selectedCell.column &&
          dlnr.firstRow <= selectedCell.row &&
          dlnr.lastColumn >= selectedCell.column &&
          dlnr.lastRow >= selectedCell.row
        ) {
          const cell = s.activeWorksheet.getCell(
            `R${selectedCell.row + 1}C${selectedCell.column + 1}`,
            CellReferenceMode.R1C1
          );
    
          if (cell.value === "ü") {
            cell.value = "";
          } else {
            cell.value = "ü";
          }
        }
      }
    }
  // we have an additional range to check, but it is a single column with only checkmarks

  const simpleChksRegions = s.activeWorksheet.getRegions("DLSIMPLECHKS");
  if(simpleChksRegions){
    for (const simpleChks of simpleChksRegions) {
      if (
        simpleChks.firstColumn <= selectedCell.column &&
        simpleChks.firstRow <= selectedCell.row &&
        simpleChks.lastColumn >= selectedCell.column &&
        simpleChks.lastRow >= selectedCell.row
      ) {
        const cell = s.activeWorksheet.getCell(
          `R${selectedCell.row + 1}C${selectedCell.column + 1}`,
          CellReferenceMode.R1C1
        );

        if (cell.value === "ü") {
          cell.value = "";
        } else {
          cell.value = "ü";
        }
      }
    }
  }
};

export default withFormProgressTracking(withSnackbar(SpreadsheetOverview));
