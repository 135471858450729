import React, { FC, useEffect, useState } from 'react';
import { isNil, isArray, last } from 'lodash';
import { Control, RegisterOptions } from 'react-hook-form';
import Autocomplete from 'src/components/UI/Forms/Autocomplete';
import { getDatabase } from '../../../rxdb';
import { toJSON } from '../../../utils';

type EquipmentProps = {
  onChange: (field: string, value: any) => void;
  form?: any;
  disabled?: boolean;
  value?: any; // TODO: FIX ME,
  category?: string | null;
  label?: string;
  control: Control;
  defaultValue?: string | null;
  rules?: RegisterOptions
};

const EquipmentDropdownOld: FC<EquipmentProps> = ({
  category,
  defaultValue,
  label,
  onChange,
  control,
  disabled,
  rules
}) => {
  const [equipment, setEquipment] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const init = async () => {
    try {
      const db = await getDatabase();

      const updatedCategory = isArray(category) ? last(category) : category;

      const selector: any = {
        deletedAt: null
      };

      if (!isNil(updatedCategory)) {
        if (!isNil(defaultValue)) {
          selector.$or = [
            { fldSRHKey: { $eq: updatedCategory } },
            { EqKey: { $eq: defaultValue } },
          ];
        } else selector.fldSRHKey = { $eq: updatedCategory };
      }

      const equipmentData = await db.equipment
        .find({
          selector,
        })
        .exec();

      return equipmentData?.sort((a: any, b: any) => a.UniqueName.localeCompare(b.UniqueName));
    } catch (error) {
      console.error("Error fetching equipment:", error);
      return [];
    }
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const sortedData = await init();
        if (isMounted) {
          setEquipment(sortedData);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching equipment:", error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [category, defaultValue]);

  return (
    <Autocomplete
      disabled={disabled}
      displayExpr="UniqueName"
      onChange={onChange}
      loading={loading}
      label={label}
      groupBy={(o: any) => o.group}
      options={equipment}
      control={control}
      name="Equipment"
      keyExpr="EqKey"
      rules={rules}
    />
  );
};

export default EquipmentDropdownOld;
