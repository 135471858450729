import { omit } from 'lodash';
import { TblSystemsHierarchy } from '../../../generated/graphql';
import {logger} from "../../../helpers/logger";

const fields = `
  PKey
  ParentID
  fldCategory
  fldDepts
  fldNoView
  fldTags
  deletedAt
  deletedBy
  isRecoverable
  updatedAt
  autoId
`;

export const tblSystemsHierarchyQuery = (doc: TblSystemsHierarchy) => {
  if (!doc) {
    doc = {
      autoId: 0,
      PKey: '',
      fldCategory: '',
      updatedAt: new Date(0).toISOString(),
    };
  }
  logger('TblSystemsHierarchy').info(
    `pull query triggered - TblSystemsHierarchy: { ${new Date().toISOString()} - ID: ${doc.autoId}, updatedAt: ${doc.updatedAt} }`,
  );
  return {
    query: `
        query GetLatestTblSystemHierachy($autoId: Int, $filterDate: Date) {
          tblSystemsHierarchy(
            where:{
              _and: [
                {
                  _or: [
                    {
                      isRecoverable: {
                        _is_null: true
                      }
                    },
                    {
                      isRecoverable: {
                        _eq: true
                      }
                    }
                  ]
                },
                {
                  _or: [
                    {
                      updatedAt: {_gt: $filterDate},                
                    },
                    {
                      autoId: {_gt: $autoId}
                      updatedAt: {_gte: $filterDate},
                    }
                  ]
                }
              ]                                    
            },
            order_by: [
              {updatedAt: asc},
              {autoId: asc}
            ],
            limit: 500  
          ) {
            ${fields}           
          }
        }
      `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
    },
  };
};

export const tblSystemsHierarchyMutation = (doc: any) => {
  const query = `
    mutation upsertTblSystemsHierarchy($input: TblSystemsHierarchyUpsertInput!) {
      upsertTblSystemsHierarchy(input: $input) {
        ${fields}
      }
    }
  `;

  const variables = {
    input: omit(doc, ['updatedAt','deletedAt']),
  };

  return {
    query,
    variables,
  };
};

export const tblSystemsHierarchySubscription = `
  subscription onTblSystemsHierarchyChanged {
    tblSystemsHierarchy {
      ${fields}      
    }
  }
`;
