/* eslint-disable @typescript-eslint/ban-types */
import { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';
import { TblRlData } from '../../../generated/graphql';

export type TblRlDataDocType = TblRlData;

export type TblRlDataDocMethods = {};

export type TblRlDataDocument = RxDocument<
  TblRlDataDocType,
  TblRlDataDocMethods
>;

export type TblRlDataCollectionMethods = {};

export type TblRlDataCollection = RxCollection<
  TblRlDataDocType,
  TblRlDataDocMethods,
  TblRlDataCollectionMethods
>;

export const schema: RxJsonSchema<TblRlDataDocType> = {
  keyCompression: false,
  title: 'RL Data',
  description: 'TblRlData',
  version: 4,
  type: 'object',
  properties: {
    autoId: {
      type: 'number',
    },
    PKey: {
      type: 'string',
      primary: true,
    },
    FKey: {
      type: 'string',
    },
    fldYMSTakenAt: {
      type: 'number',
    },
    fldCrewId: {
      type: ['string', 'null'],
      ref: 'crew',
    },
    readings: {
      type: ['string', 'null'],
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
    updatedAt: {
      type: 'string',
    },
  },
  indexes: ['PKey'],
  required: [],
  additionalProperties: false,
};

export default schema;
