import { FC, useEffect, useState } from 'react';
import { Button, DialogTitle, DialogActions, DialogContent, Box, Snackbar, Alert, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import InventoryDetailForm from '../../inventoryDE/InventoryDetailForm';
import { CustomDialog } from 'src/helpers/modals';
import { useAppState } from 'src/contexts/app-state';
import { useAuth } from 'src/contexts/auth';
import { AccessType } from 'src/utils/permissions';

interface InjectedProps {
  visible: boolean;
  initialValue: any;
  onSave: () => void;
  editFlag: boolean;
  onCancel?: () => void;
}

const EqSapreInventoryEditDialog: FC<InjectedProps> = ({
  initialValue,
  visible,
  onCancel,
  onSave,
  editFlag
}) => {
  const { user } = useAuth();
  const { settingsPersonal } = useAppState();
  const [processing, setProcessing] = useState<boolean>(false);
  const [disableEdit, setDisableEdit] = useState(false);
  const [disableEditDepartment, setDisableEditDepartment] = useState(false);
  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });
  
  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const handleSave = () => {
    onSave();
  };

  const handleStorageUpdate = () =>{};

  const handleCancel = () => {
    onCancel && onCancel();
    setProcessing(false);
    setSnackbar({
      open: false,
      type: 'success',
      message: '',
    });
  };

  const handleDelete = () => {};
  
  const getAccessType = () => {
    if (settingsPersonal) {
      if (settingsPersonal.fldInv === 1) {
        return AccessType.READ_ONLY;
      }
      if (settingsPersonal.fldInv === 0) {
        return AccessType.NO_ACCESS;
      }
      if (settingsPersonal.fldInv === 2) {
        return AccessType.FULL_ACCESS;
      }
    } else {
      return AccessType.FULL_ACCESS;
    }
  };

  const setDeptHeadPermission = async ()=>{
    if(settingsPersonal.fldAllDepts === 1 && user?.Department != initialValue?.Department){
      setDisableEdit(true)
    }
    if(settingsPersonal.fldAllDepts != 2 ){
      setDisableEditDepartment(true)
    }
  }

  useEffect(()=>{
    if(settingsPersonal){
      setDeptHeadPermission()
    }
  },[settingsPersonal])

  return (
    <>
      <CustomDialog
        scroll="paper"
        fullWidth
        maxWidth="md"
        open={visible}
        onClose={handleCancel}
      >
        <DialogTitle>
          <span className="font-bold text-2xl">Edit Inventory</span>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 0 }}>
          {!!initialValue && (
            <InventoryDetailForm
              initialValue={initialValue}
              onCancel={handleCancel}
              onSave={handleSave}
              onDelete={handleDelete}
              type="Dialog"
              editFlag={getAccessType() !== AccessType.FULL_ACCESS}
              isCreated={false}
              storageUpdate= {handleStorageUpdate}
            />
          )}
        </DialogContent>
        <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
          <Tooltip
            title={getAccessType() !== AccessType.FULL_ACCESS ? 'You do not have permission to save this document' : ''}
          >
            <Box
              sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}
            >
              <Button className="mr-2" onClick={handleCancel}>
                Cancel
              </Button>
              <LoadingButton
                disabled={disableEdit || disableEditDepartment || getAccessType() !== AccessType.FULL_ACCESS}
                loading={processing}
                type="submit"
                form="Inventory-Edit-form"
                variant="contained"
              >
                Save
              </LoadingButton>
            </Box>
          </Tooltip>
        </DialogActions>
      </CustomDialog>

      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        onClose={onSnackbarClose}
      >
        <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
          {snackBar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EqSapreInventoryEditDialog;
