import './styles.scss';
import { FC, useState } from 'react';
import { isNil } from 'lodash';
import { Alert, AlertColor, Snackbar } from '@mui/material';
import Comment from '../Comment/index';
import { AddCommentInput, CommentSortInput, Comment as CommentType, RecordType } from 'src/generated/dotnet.graphql';
import CommentInput from '../CommentInput';
import { useAuth } from '../../../../contexts/auth';
import { useAddComment } from 'src/hooks/comments/useAddCommentsForRecord';
import { SEVERITY } from 'src/consts';

interface InjectedProps {
  comment: CommentType;
  recordId: string;
  recordType: RecordType;
  recordTypeName: string;
  readOnly?: boolean;
}

const CommentReplies: FC<InjectedProps> = ({ comment, recordId, recordType, recordTypeName, readOnly = false }) => {
  const { user } = useAuth();
  const { addComment } = useAddComment(recordId, recordType, recordTypeName, true);
  const [replies, setReplies] = useState<CommentType[]>(comment.replies || []);
  const [snackbar, setSnackbar] = useState<{ message: string; severity: AlertColor }>();

  const onSubmit = async (content: string) => {
    if (!user) return;

    const payload: AddCommentInput = {
      recordId,
      recordType,
      parentId: comment.id,
      content,
    };
  
    const { responseData, responseMessage } = await addComment(payload);

    if (responseData) {
      setReplies([...replies, responseData]);
    }

    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  const renderRepliesList = () => replies.map((comment) => {
    return (
      <Comment 
        key={comment.id} 
        comment={comment} 
        recordId={recordId} 
        recordType={recordType}
        recordTypeName={recordTypeName}
        isReply={true}
        setReplies={setReplies}
        readOnly={readOnly}
        setSnackbar={(message, severity) => setSnackbar({ message, severity })}
      />
    )
  });

  return (
    <div className="w-full">
      {renderRepliesList()}

      <CommentInput
        className="pt-2 pb-2" 
        onSubmit={onSubmit} 
        readOnly={readOnly} 
      />

      <Snackbar
        open={!!snackbar}
        autoHideDuration={2000}
        onClose={() => setSnackbar(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
      </Snackbar>
    </div>
  );
};

export default CommentReplies;
