/* eslint-disable @typescript-eslint/ban-types */
import { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';
import { TblSchedChkDesc } from '../../../generated/graphql';

export type TblSchedChkDescDocType = TblSchedChkDesc;

export type TblSchedChkDescDocMethods = {};

export type TblSchedChkDescDocument = RxDocument<
  TblSchedChkDescDocType,
  TblSchedChkDescDocMethods
>;

export type TblSchedChkDescCollectionMethods = {};

export type TblSchedChkDescCollection = RxCollection<
  TblSchedChkDescDocType,
  TblSchedChkDescDocMethods,
  TblSchedChkDescCollectionMethods
>;

export const schema: RxJsonSchema<TblSchedChkDescDocType> = {
  keyCompression: false,
  title: 'Sched Checks_Descriptions',
  description: 'TblSchedChkDesc',
  version: 1,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    PKey: {
      type: 'string',
      primary: true,
    },
    fldDescriptions: {
      type: ['string', 'null'],
    },
    updatedAt: {
      type: 'string',
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },

  },
  indexes: ['PKey'],
  required: [],
  additionalProperties: false,
};

export default schema;
