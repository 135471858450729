import { useState, FC, useCallback, useEffect } from 'react';
import { pick } from 'lodash';
import { Box, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, IconButton, Typography } from '@mui/material';
import { InventoryDocument } from 'src/pages/InventoryPage/rxdb';
import { getDatabase } from 'src/rxdb';
import { DeleteTwoTone, Edit } from '@mui/icons-material';
import { MultipleLocationDocument } from 'src/rxdb/collections/MultipleLocations/schema';
import WarningDialog from 'src/components/UI/WarningDialog';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { upsertAmountValue } from 'src/pages/InventoryPage/utils';

interface Props {
    initialValue: InventoryDocument;
    onDelete: (item: MultipleLocationDocument) => void;
    loadingGrid: boolean;
    onSelect: (item: MultipleLocationDocument) => void;
    disableEdit: boolean;
}

const transformData = async (item: any) => ({
    ...pick(item, ['PKey', 'ProductID', 'Amount', 'fldLocHierarchy', 'UseBy']),
    original: item,
});

const InvStorageCardsGrid: FC<Props> = ({
    initialValue,
    onDelete,
    loadingGrid,
    onSelect,
    disableEdit=false,
}) => {
    const [loading, setLoading] = useState<boolean>(loadingGrid);
    const [isDeleting, setIsDeleting] = useState(false);
    const [deleteSelected, setDeleteSelected] = useState<any>();
    const [data, setData] = useState<any[]>();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = useCallback(async () => {
      setLoading(true)
        try {
            const db = await getDatabase();
    
            let item = await db.tblmultiplelocations
                .find({
                    selector: {
                        ProductID: initialValue.ProductID,
                    },
                })
                .exec();
            item = item.filter((i: any) => !i.deletedAt);
            
            const transformedData = await Promise.all(item.map(transformData));
    
            for (const dataItem of transformedData) {
                dataItem.fldLocHierarchy = await getLocNodes(dataItem, db);
            }
    
            setData(transformedData);
        } catch (error) {
            console.error('Error loading data:', error);
        }
        setLoading(false)
    }, [initialValue]);

    const getLocNodes = async (item: any, db: any) => {
        const locArrayData = [];
        let node: any = {
            PKey: '',
            fldLocation: '',
            ParentID: item.fldLocHierarchy,
        };
        while (node.ParentID) {
            node = await db.tbllocationshierarchy
                .findOne({
                    selector: {
                        PKey: node.ParentID,
                    },
                })
                .exec();
            locArrayData.push(node.fldLocation);
        }

        return locArrayData.reverse().join(' >> ');
    };

    const cardDeletePress = (item: any) => {
        setDeleteSelected(item.original);
        setIsDeleting(true);
    };

    const handleDeleteOk = () => {
        onDelete(deleteSelected);
        if(data) {
          // Filter out the deleted item from the data array and data state
          const updatedData = data.filter((item) => item.PKey !== deleteSelected.PKey);
          setData(updatedData);
        }
        setIsDeleting(false);
        setDeleteSelected(undefined);
    };

    const handleDeleteCancel = () => {
        setIsDeleting(false);
        setDeleteSelected(undefined);
    };

    const cardEdit = (item: any) => {
        onSelect(item.original);
    };

    const CommonGridUI = (title: string, value: any) => (
        <>
          <Grid item xs={3}>
            <Typography sx={{ fontSize: 12 }}>{title}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography
              sx={{ fontSize: 12, fontWeight: 700 }}
              className="flex justify-start"
            >
              {value}
            </Typography>
          </Grid>
        </>
      );

    const handleAmountValue = (item: any, data: any[], increase: boolean): void => {
      const updatedData = upsertAmountValue(item, data, increase)
      setData(updatedData);
    }

    return (
      <>
      {
        loading ? (
          <div className="flex items-center justify-center w-full">
              <CircularProgress />
          </div>
        ) : 
       (<Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 2, sm: 4, md: 12 }}
        >
          {data?.map((item) => (
            <Grid item xs={2} sm={4} md={4} key={item.PKey}>
              <Card elevation={3} sx={{ height: 135 }}>
                <CardContent sx={{ padding: 2 }}>
                  <CardHeader
                    sx={{
                        padding: 1,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                    title={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                            onClick={() => handleAmountValue(item, data, true)}
                            size="small"
                            aria-label="Increase item amount"
                            disabled={disableEdit}
                        >
                            <AddRoundedIcon fontSize="inherit" color={disableEdit ? 'disabled' : 'primary'}/>
                        </IconButton>
                        <IconButton
                            onClick={() => handleAmountValue(item, data, false)}
                            size="small"
                            aria-label="Decrease item amount"
                            disabled={disableEdit}
                        >
                            <RemoveRoundedIcon fontSize="inherit" color={disableEdit ? 'disabled' : 'error'} />
                        </IconButton>
                      </div>
                    }
                    action={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                            onClick={() => cardEdit(item)}
                            size="small"
                            aria-label="Edit item"
                            disabled={disableEdit}
                        >
                            <Edit fontSize="inherit" color={disableEdit ? 'disabled' : "primary"} />
                        </IconButton>
                        <IconButton
                            color="error"
                            aria-label="Delete task"
                            className="ml-2"
                            size="small"
                            onClick={() => cardDeletePress(item)}
                            disabled={disableEdit}
                        >
                            <DeleteTwoTone fontSize="inherit" color={disableEdit ? 'disabled' : 'error'} />
                        </IconButton>
                      </div>
                    }
                  />
                  <Divider className="mb-3" />
                  <Box sx={{ width: '100%' }}>
                    <Grid container rowSpacing={2} columnSpacing={2}>
                      {CommonGridUI('Location:', item.fldLocHierarchy)}
                    </Grid>
                  </Box>
                  <Box sx={{ width: '100%' }}>
                    <Grid container rowSpacing={2} columnSpacing={2}>
                      {CommonGridUI('Amount:', item.Amount ? item.Amount : 0)}
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}     
        <WarningDialog
          visible={isDeleting}
          title="Delete Warning"
          content="Are you sure you wish to delete record?"
          okText="Yes"
          color="error"
          onOk={handleDeleteOk}
          onCancel={handleDeleteCancel}
        />
      </>
    );
};

export default InvStorageCardsGrid;