import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import {
    BlobProvider,
} from "@react-pdf/renderer";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from '@mui/material/CircularProgress';
import PSPDFKit from "./PSPDFKit";
import { AppBar, Toolbar, Box, IconButton } from "@mui/material";

type PrintButtonOldProps = {
    generatePDF: any;
    enable: Boolean;
    title?: string;
    recordsCount?: number;
};
const PrintButtonOld: FC<PrintButtonOldProps> = ({ generatePDF, enable, title, recordsCount }) => {
    const [open, setOpen] = React.useState(false);
    const [isPdfReady, setIsPdfReady] = React.useState(false);
    const [document, setDocument] = React.useState<any>();
    const createPDF = async () => {
        setDocument(generatePDF());
        setIsPdfReady(true);
    }
    useEffect(() => {
        if (enable && open) {
            createPDF();
        }
    }, [enable, open]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <IconButton disabled={!enable} onClick={handleClickOpen} aria-label="Print excel">
                <PrintOutlinedIcon />
            </IconButton>
            <Dialog
                fullScreen={true}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>{title}</div>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent className="p-0">
                    {isPdfReady && <BlobProvider document={document}>
                        {({ blob, url, loading, error }) => {
                            if (blob) {
                                return <PSPDFKit blob={blob} />;
                            }

                            if (error) {
                                return error;
                            }

                            return (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    {`The PDF is rendering ${recordsCount} records ...`}
                                </div>
                            );
                        }}
                    </BlobProvider>
                    }
                    {!isPdfReady && <div className="flex justify-center	items-center w-full h-screen">
                        <CircularProgress />
                    </div>}
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default PrintButtonOld;