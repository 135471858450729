import { ApolloError, DocumentNode, gql, useMutation } from '@apollo/client';
import { Attachment, AttachmentInput, AttachmentType, RecordType } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { GET_ATTACHMENTS_FOR_RECORD } from './useGetAttachments';
import { useState } from 'react';
import { UpdateLogEntryDocumentCountCache, UpdateLogEntryPhotoCountCache } from '../fragments.graphql';

export const UPSERT_ATTACHMENT = gql`
  mutation UpsertAttachment($input: AttachmentInput!) {
    upsertAttachment(input: $input) {
      attachment {
        docTitle
        fldAltPath
        fldDescription
        fldFileName
        fldLibType
        fldNotes
        fldPage
        fldRevision
        fldRevisionDate
        fldShowInCentral
        fldSrhkey
        pKey
        recordId
        recordType
      }
    }
  }
`;

export interface AttachmentMutationResponse {
  responseData?: Attachment;
  responseMessage: string;
}

interface UpsertAttachmentResult {
  upsertAttachment: (input: AttachmentInput, isCreate: boolean) => Promise<AttachmentMutationResponse>;
  upsertAttachmentLoading: boolean;
  upsertAttachmentError?: ApolloError;
}

export const useUpsertAttachment = (recordId: string, recordType: RecordType, recordTypeName: string, attachmentType: AttachmentType): UpsertAttachmentResult => {
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [AddOrUpdateAttachment, { loading, error }] = useMutation(UPSERT_ATTACHMENT, {
    refetchQueries: [
      {
        query: GET_ATTACHMENTS_FOR_RECORD,
        variables: {
          recordId,
          recordType,
          attachmentType
        } 
      }
    ],
    onError: (error: ApolloError) => {
      logger('UpsertAttachment').error(`Error ${isCreate ? 'adding' : 'updating'} ${attachmentType} for ${recordType}: ${recordId} -->`, error.message);
    },
    update: (cache, { data }) => {
      if (isCreate) { // if is update we dont modify the spareCount value in the cache
        if (data?.upsertAttachment?.attachment) {
        
          const recordCacheId = cache.identify({
            __typename: recordTypeName,
            pkey: recordId,
          });
  
          let fragmentSelection: DocumentNode | null = null;

          switch (recordType) {
            case RecordType.LogEntry:
              fragmentSelection = 
                attachmentType === AttachmentType.Document 
                  ? UpdateLogEntryDocumentCountCache 
                  : UpdateLogEntryPhotoCountCache;
              break;
            case RecordType.Task:
            case RecordType.Equipment:
              break;
            default:
              break;
          }

          if (fragmentSelection) {
  
            const existingRecord = cache.readFragment<any>({
              id: recordCacheId,
              fragment: fragmentSelection,
            });
    
            if (existingRecord) {
              const updatedCount = 
                attachmentType === AttachmentType.Document 
                ? { documentsCount: existingRecord.documentsCount + 1 }
                : { photosCount: existingRecord.photosCount + 1 };
    
              cache.writeFragment({
                id: recordCacheId,
                fragment: fragmentSelection,
                data: updatedCount,
              });
    
            } else {
              logger('Cache-UpsertAttachment').warning(`${recordType}: ${recordId} not found in cache`);
            }
          } else {
            logger('Cache-UpsertAttachment').warning(`Query fragment not valid --> ${fragmentSelection}`);
          }  
        } else {
          logger('Cache-UpsertAttachment').warning(`${recordType}: ${recordId} cache update failed --> No response from upsert mutation`);
        }
      }
    },
  });

  const upsertAttachment = async (input: AttachmentInput, isCreate: boolean): Promise<AttachmentMutationResponse> => {
    setIsCreate(isCreate);
    const attachmentTypeDisplay = attachmentType === AttachmentType.Photo ? 'Photo' : 'Document';
    const response = await AddOrUpdateAttachment({ variables: { input } });
    const responseData = response.data?.upsertAttachment?.attachment;
    if (responseData) {
      logger('UpsertAttachment').info(`${attachmentType} ${isCreate ? 'added' : 'updated'} successfully`, response.data.upsertAttachment);
      return {
        responseData,
        responseMessage: `${attachmentTypeDisplay} ${isCreate ? 'added' : 'updated'} successfully!`
      };
    } else {
      return {
        responseMessage: `Failed to ${isCreate ? 'add' : 'update'} ${attachmentTypeDisplay}!`,
      };
    };
  };

  return { 
    upsertAttachment, 
    upsertAttachmentLoading: loading, 
    upsertAttachmentError: error,
  };
};