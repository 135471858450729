// @ts-nocheck
// React Components
import { FC, useCallback, useState, useEffect, useRef, forwardRef, useImperativeHandle  } from 'react';
import { pick, get, size, isEmpty, isNil, groupBy } from 'lodash';
import { MangoQuerySortDirection } from 'rxdb/dist/types/types/rx-query';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import { utcToZonedTime } from 'date-fns-tz';
import moment from 'moment';
// TDI Components
import LicensedReactDataGrid from '../../../components/UI/LicensedReactDataGrid';
// Utils
import { exportCSV, exportExcel, getRegexByOperator } from '../../../utils';
// Data
import { getDatabase, TDIDb } from '../../../rxdb';
import { EngineerDayLogCustomDocument } from '../rxdb';
// MUI components
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';

interface LogEntryGridRef {
  handleExport: (type: string) => void;
}

type LogEntryGridProps = {
  darken: boolean;
  onSelect: (issue: EngineerDayLogCustomDocument) => void;
  sourceRoot?: boolean;
};

const filter = [
  {
    name: 'fldLogDate',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'fldLogDate',
    operator: 'inrange',
    type: 'date',
    value: '',
  },
  {
    name: 'fldMode',
    operator: 'eq',
    type: 'select',
    value: '',
  },
  {
    name: 'fldApproxLocation',
    operator: 'contains',
    type: 'string',
    value: '',
  },
];

const transformData = async (issue: EngineerDayLogCustomDocument) => ({
  ...pick(issue, ['PKey', 'fldLogDate', 'fldMode', 'fldApproxLocation']),
  month: moment(issue.fldLogDate).format('MMMM'),
  year: moment(issue.fldLogDate).format('YYYY'),
  original: issue,
});

const getSelectorByFilterName = async (
  name: string,
  value: string,
  operator: string,
  db: TDIDb
) => {
  switch (name) {
    case 'LogEntry':
      return {
        Subject: { $regex: getRegexByOperator(operator, value) },
      };
    case 'fldMode': {
      return {
        fldMode: { $regex: getRegexByOperator(operator, value) },
      };
    }
    case 'fldApproxLocation': {
      return {
        fldApproxLocation: { $regex: getRegexByOperator(operator, value) },
      };
    }
    case 'fldLogDate': {
      if (!isEmpty(value.start) && (isNil(value.end) || isEmpty(value.end))) {
        const dateformate = moment(value?.start)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        return {
          fldLogDate: { $gte: dateformate },
        };
      }
      if (!isEmpty(value.end) && (isNil(value.start) || isEmpty(value.start))) {
        const dateformate = moment(value?.end)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        return {
          fldLogDate: { $lte: dateformate },
        };
      }
      if (!isEmpty(value.start) && !isEmpty(value.end)) {
        const startdateformate = moment(value?.start)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        const enddateformate = moment(value?.end)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        return {
          $and: [
            { fldLogDate: { $gte: startdateformate } },
            { fldLogDate: { $lte: enddateformate } },
          ],
        };
      }
      return {};
    }

    default:
      return {};
  }
};

let currentCount = 0;

const loadData = async ({
  skip,
  limit,
  filterValue,
  sortInfo
}: {
  sortInfo: any;
  skip: number;
  limit: number;
  filterValue: any;
}): Promise<{ data: any[]; count: number }> => {
  try {
    const db = await getDatabase();
    let preSort = {
      fldLogDate: 'desc' as MangoQuerySortDirection,
    };
  
    if (!isNil(sortInfo)) {
      preSort = {
        [sortInfo.name]:
          sortInfo.dir === 1
            ? ('asc' as MangoQuerySortDirection)
            : ('desc' as MangoQuerySortDirection),
      };
    }
  
    const sort = [
      {
        ...preSort,
      },
    ];

    // TODO: Handle filtervalue.OPERATOR
    let selector = {
      deletedBy: {
        $eq: null,
      },
    };

    await Promise.all(
      filterValue.map(async (v) => {
        if (isEmpty(v.value)) return v;

        const s = await getSelectorByFilterName(
          v.name,
          v.value,
          v.operator,
          db
        );

        selector = {
          ...selector,
          ...s,
        };
        return v;
      })
    );

    const length = size(await db.engineerdaylog.find({ selector }).exec());
    currentCount = length;
    const items = await db.engineerdaylog
      .find({
        selector,
        skip,
        limit,
        sort,
      })
      .exec();

    const data = await Promise.all(items.map(transformData));
    return { data, count: length };
  } catch (e) {
    console.error(e.message);
    return { data: [], count: 0 };
  }
};

const EngineersDayLogGrid = forwardRef<LogEntryGridRef, LogEntryGridProps>(
  (
    { 
      onSelect,
      sourceRoot = false,
    }: LogEntryGridProps,
    ref
  ) => {
  const [loading, setLoading] = useState<boolean>(false);
  const highlightedRowId = useRef<string>('-1');
  const [gridRef, setGridRef] = useState<any>(null);
  // const [allDepartment, setAllDepartment] = useState<TblDdListDefaultsDocument>([]);
  const [collapsedGroups, setCollapsedGroups] = useState({});
  const [groupCollapsedStatus, setGroupCollapsedStatus] = useState(false);

  const [exportDialog, setExportDialog] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  // const [groupByFlag, setGroupByFlag] = useState(false);

  const handleGetCount = async () => {
    const db = await getDatabase();
    let selector = {};
    const length = size(await db.engineerdaylog.find({ selector }).exec());
    setTotalCount(length);
  };

  useImperativeHandle(ref, () => ({
    handleExport: (type) => {
      handleDataExport(type);
    },
  }));

  useEffect(() => {
    handleGetCount();
  }, [loading]);

  // Find if any filters applied to grid
  const areFiltersActive = gridRef?.current.computedFilterValue.some(
    (f) => !isEmpty(f.value)
  );

  const currentFilters = get(gridRef, ['current', 'computedFilterValue'], []);

  const init = async (ref: MutableRefObject<TypeComputedProps | null>) => {
    try {
      const db = await getDatabase();
      // grouping not working with pagination
      // if(ref?.current?.computedGroupBy.includes('month')||ref?.current?.computedGroupBy.includes('year') ){
      //   setGroupByFlag(true)
      // }
      // const alldpt = (
      //   await db.tblddlistdefaults
      //     .find({ selector: { fldListName: { $eq: 'Department' } } })
      //     .exec()
      // ).map((e: any) => e.fldMember);

      // setAllDepartment(alldpt);
      
      // Always reload grid on new item / update / delete.
      db.engineerdaylog.$.subscribe(async (ev) => {
        if (ev.operation === 'INSERT' || ev.operation === 'UPDATE') {
          highlightedRowId.current = ev.documentId;
          ref?.current?.reload();
        }
        if (ev.operation === 'DELETE') {
          highlightedRowId.current = '-1';
          ref?.current?.reload();
        }
      });
    } catch (e) {
      console.error(e.message);
    }
  };

  // useEffect(() => {
  //   init();
  // }, []);

  const onReady = (ref: MutableRefObject<TypeComputedProps | null>) => {
    setGridRef(ref);
    init(ref);
  };

  const onLoadingChange = (status: boolean) => {
    // If loading completed - check if there any items that needs to be highlighted.
    if (!status && highlightedRowId.current !== '-1') {
      gridRef?.current?.scrollToId(highlightedRowId.current);
      gridRef?.current?.setSelectedById(highlightedRowId.current, true);
      highlightedRowId.current = '-1';
    }
    setLoading(status);
  };

  const dataSource = useCallback(loadData, []);

  const onRowClick = useCallback(({ data }) => {
    // TD-1448 fix
    if(!data.__group) {
      onSelect(data.original);
    }
  }, []);

  const exportData = (type = 'CSV' | 'xlsx', withFilters = true) => {
    setExportDialog(null);

    switch (type) {
      case 'CSV':
        return onExportToCSV(withFilters);
      case 'xlsx':
        return onExportToExcel(withFilters);
      default:
    }
  };

  const getRows = async (issue: any) => {
    const rows =  issue.map((data: any) =>{
      const logDateFormatted = data.fldLogDate ? moment(data.fldLogDate).format('DD-MMM-YYYY') : '';
      return {
        ...pick(data, [
          'fldMode', 'fldApproxLocation'
        ]),
        fldLogDate: logDateFormatted,
      };
    }   
    );
    return rows
  };

  const onExportToExcel = async (withFilters: boolean) => {
    const { data } = await loadData({
      filterValue: withFilters ? currentFilters : [],
    });

    const columnsData = gridRef.current.visibleColumns.map((c: any) => ({
      header: c.header,
      key: c.id,
    }));
    const columns = columnsData.filter(item => {
      return item.header && typeof item.header === 'string';
    });

    const rows = await getRows(data)

    return exportExcel(columns, rows);
  };

  const onExportToCSV = async (withFilters: boolean) => {
    const columns = gridRef.current.visibleColumns;

    const { data } = await loadData({
      filterValue: withFilters ? currentFilters : [],
    });

    const rows = await getRows(data)
    return exportCSV(columns, rows);
  };

  const handleDataExport = (type = 'CSV' | 'xlsx') => {
    if (areFiltersActive) {
      setExportDialog({
        visible: true,
        type,
        title: type === 'CSV' ? 'CSV' : 'Excel',
      });

      return;
    }

    exportData(type, false);
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const renderGroupTitle = (value, { data }) => {
    const columns = data.fieldPath.map((col) =>
      col === data.name ? col.toUpperCase() : col
    );
    let path = columns && columns.length && columns.join('>');
    let dataValue = data.value;
    if (path.includes('fldLogDate')) {
      dataValue = moment(data.value).format('DD-MMM-YYYY');
    }

    return data.value === 'null' ? `No ${path} Assigned` : dataValue;
  };

  const CollapseButton = () => {
    if (groupCollapsedStatus) {
      setGroupCollapsedStatus(false);
      return gridRef.current.collapseAllGroups();
    }
    if (!groupCollapsedStatus) {
      setGroupCollapsedStatus(true);
      return gridRef.current.expandAllGroups();
    }
  };

  // grouping not working with pagination
  // const groupByMonth = async () =>{
  //   const grouped = gridRef?.current?.computedGroupBy
  //   if(!groupByFlag){   
  //   await gridRef?.current.setGroupBy(grouped.concat(['year','month']))
  //   await gridRef?.current.setSortInfo([{ name: 'year', dir: -1 }, { name: 'month', dir: stringMonthSort }, { name: 'fldLogDate', dir: -1 }])
  //   await gridRef?.current?.reload()
  //   setGroupByFlag(true)
  //   }
  //   else{
  //     setGroupByFlag(false)
  //     const filterGroup = grouped.filter((e)=> e != 'month').filter((n)=> n!='year')
  //     await gridRef?.current.setGroupBy(filterGroup)
  //     await gridRef?.current.setSortInfo([{ name: 'fldLogDate', dir: -1 }])
  //     await gridRef?.current?.reload()
  //   }
  // };

  const columns = [
    {
      name: 'fldLogDate',
      header: 'Date',
      defaultFlex: isDesktop ? 0.5 : undefined,
      headerAlign: 'start' as any,
      textAlign: 'end' as any,
      dateFormat: 'DD-MMM-YYYY',
      filterEditor: DateFilter,
      filterEditorProps: (props, { index }) => {
        return { placeholder: index == 1 ? 'End...' : 'Start...' };
      },
      render: ({ value, cellProps: { dateFormat } }) => {
        if (!isNil(value)) {
          return moment(utcToZonedTime(value)).format(dateFormat);
        }
      },
    },
    {
      name: 'year',
      header: 'Log Year',
      defaultFlex: isDesktop ? 1 : undefined,
      defaultVisible: false,
    },
    {
      name: 'month',
      header: 'Log Month',
      defaultFlex: isDesktop ? 1 : undefined,
      defaultVisible: false,
    },
    {
      name: 'fldMode',
      header: 'Mode',
      defaultFlex: isDesktop ? 1 : undefined,
      sortable: false,
    },
    {
      name: 'fldApproxLocation',
      header: 'Location',
      defaultFlex: isDesktop ? 1 : undefined,
      sortable: false,
    },
  ];

  const footerRows = [
    {
      position: 'end',
      render: {
        fldLogDate: () => {

          const style = {
            paddingLeft: 20,
          };

          return (
            <div style={style}>
              Total Records: {currentCount}/{totalCount}
            </div>
          );
        },
      },
      colspan: {
        fldLogDate: (_, computedProps) => computedProps.visibleColumns.length,
      },
    },
  ];

  return (
    <div data-testid="engdayloggrid" className="flex flex-col flex-grow">
      <div className="flex flex-row items-center justify-end">
        {/* grouping not working with pagination */}
        {/* {!isEmpty(gridRef?.current.computedGroupBy) &&
          (groupCollapsedStatus ? (
            <div>
              <Tooltip title="Collapse All">
                <IconButton onClick={CollapseButton}>
                  <UnfoldLessIcon />
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            <div>
              <Tooltip title="Expand All">
                <IconButton onClick={CollapseButton}>
                  <UnfoldMoreIcon />
                </IconButton>
              </Tooltip>
            </div>
          ))}
        {groupByFlag ? (
          <Tooltip title="Remove Group by Year/month">
            <IconButton onClick={groupByMonth}>
              <EventBusyIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Group by Year/month of Report Date">
            <IconButton onClick={groupByMonth}>
              <CalendarMonthIcon />
            </IconButton>
          </Tooltip>
        )} */}
      </div>
      <LicensedReactDataGrid
        onRowClick={onRowClick}
        onLoadingChange={onLoadingChange}
        defaultLimit={25}
        pagination
        livePagination
        onReady={onReady}
        rowHeight={40}
        rowExpandHeight={300}
        // defaultGroupBy={[]} // groupBy not working with pagination
        onGroupByChange={() => gridRef.current.collapseAllGroups()}
        collapsedGroups={collapsedGroups}
        onGroupCollapseChange={setCollapsedGroups}
        loading={loading}
        enableSelection
        defaultFilterValue={filter}
        idProperty="PKey"
        // theme="default-dark"
        columns={columns}
        dataSource={dataSource}
        renderGroupTitle={renderGroupTitle}
        // defaultSortInfo={[{ name: 'fldLogDate', dir: -1 }]}
        footerRows={footerRows}
        sourceRoot={sourceRoot}
      />

      <Dialog maxWidth="xs" open={Boolean(exportDialog?.visible || false)}>
        <DialogTitle>
          Export data to
          {exportDialog?.title}
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            You have filters applied. Would you like to export with current
            filters?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-end' }}>
          <Button
            autoFocus
            onClick={() => exportData(exportDialog?.type, false)}
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={() => exportData(exportDialog?.type)}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default EngineersDayLogGrid;