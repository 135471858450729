import React, {
  useState,
  FC,
  useRef,
  useCallback,
  MutableRefObject,
} from 'react';
import { pick, size } from 'lodash';
import {
  Alert,
  Snackbar,
  IconButton,
  Button,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { getDatabase, TDIDb } from 'src/rxdb';
import {
  TypeComputedProps,
  TypeSortInfo,
} from '@inovua/reactdatagrid-enterprise/types';
import { DeleteTwoTone, Edit } from '@mui/icons-material';
import LicensedReactDataGrid from 'src/components/UI/LicensedReactDataGrid';
import WarningDialog from 'src/components/UI/WarningDialog';
import RelatedItemEditDialog from './RelatedItemEditDialog';
import RelatedDocAddDialog from './RelatedAddDialog';
import 'src/components/dataentry/inventoryDE/component/style.css';
import { populateSchedMaintEQData } from 'src/pages/EquipmentSchedules/utils';

interface Props {
  keyName?: string;
  keyValue: string | null;
}

const transformData = async (item: any) => {
  const db = await getDatabase();
  let tableName
  switch(item.original.fldTableName){
    case 'TblSchedMaintEq': {
      tableName = 'Equipment Schedules'
      break;
    }
    case 'Orders' :{
      tableName = 'Purchasing'
      break;
    }
    case 'Inventory' :{
      const selectedItem = await db.inventory
        .find({
          selector: {
            ProductID: item.original.fldRecordKey,
          },
        })
        .exec();
      if (selectedItem[0]?.fldDataType === '0') {
       tableName = 'General Inventory'
      }
      if (selectedItem[0]?.fldDataType === '1') {
        tableName = 'Beverage';
      }
      if (selectedItem[0]?.fldDataType === '2') {
        tableName = 'Dry & Refrigerated Stores';
      }
      if (selectedItem[0]?.fldDataType === '3') {
        tableName ='Uniform';
      }
      if (selectedItem[0]?.fldDataType === '4') {
        tableName = 'Medical Inventory';
      }
    
      break;
    }
    default:{
      tableName = item.original.fldTableName
      break;
    }
  }
  
  return {
    ...pick(item, ['relatedName', 'original']),
    PKey: item.original.PKey,
    fldFKey: item.original.fldFKey,
    fldRecordKey: item.original.fldRecordKey,
    fldTableName: tableName,
  };
};

const getKeys = async (item: any) => {
  const n = { ...pick(item, ['fldRecordKey']) };
  return [n.fldRecordKey];
};

const RelatedTab: FC<Props> = ({ keyName, keyValue }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const highlightedRowId = useRef<string>('-1');
  const [gridRef, setGridRef] = useState<any>(null);
  const [selectedRowItem, setSelectedRowItem] = useState<any>();
  const [editPopupVisible, setEditPopupVisible] = useState<boolean>(false);
  const [addPopupVisible, setAddPopupVisible] = useState<boolean>(false);
  const [gridData, setGridData] = useState<any>(null);
  const [eqData, setEqData] = useState<any>(null);
  const [tableName, setTableName] = useState<any>();
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteSelected, setDeleteSelected] = useState<any>();
  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };
  const getName = async (item: any) => {
    const db = await getDatabase();
    switch (item.fldTableName) {
      case 'EQ':
      case 'Equipment Lists':
      case 'Equipment': {
        const temp = await db.equipment
          .find({
            selector: {
              EqKey: { $eq:item.fldRecordKey },
              deletedAt: { $eq: null},
            },
          })
          .exec();

        return {
          relatedName: temp[0]?.UniqueName || "Unrecognized Record",
          original: item,
        };
      }
      case 'ENINVENTORYGENERAL':
      case 'INVENTORY':
      case 'Spares and General Inventory':
      case 'Uniforms Inventory':
      case 'Inventory': {
        const temp = await db.inventory
          .find({
            selector: {
              ProductID: { $eq:item.fldRecordKey},
              deletedAt: { $eq: null},
            },
          })
          .exec();
        return {
          relatedName: temp[0]?.ProductName || "Unrecognized Record",
          original: item,
        };
      }
      case 'Purchase Orders':
      case 'Orders': {
        const temp = await db.orders
          .find({
            selector: {
              OrderID: { $eq:item.fldRecordKey},
              deletedAt: { $eq: null},
            },
          })
          .exec();
        return {
          relatedName: temp[0]?.OrderName || "Unrecognized Record",
          original: item,
        };
      }
      case 'Equipment Maintenance':
      case 'TblSchedMaintEq':{
        const temp = await db.tblschedmainteq
          .find({
            selector: {
              PKey: { $eq:item.fldRecordKey},
              deletedAt: { $eq: null},
            },
          })
          .exec();
          const data = await Promise.all(temp.map(populateSchedMaintEQData));
          return {
            relatedName: data[0]?.fldSubject || "Unrecognized Record",
            original: item,
          };
      }
      case 'Certificates & Documents':
      case 'Certificates': {
        const temp = await db.certificates
          .find({
            selector: {
              PKey: { $eq:item.fldRecordKey},
              deletedAt: { $eq: null},
            },
          })
          .exec();
        return {
          relatedName: temp[0]?.fldType || "Unrecognized Record",
          original: item,
        };
      }
      case 'WORKISSUE': {
        const temp = await db.workissues
          .find({
            selector: {
              EqKey: { $eq:item.fldRecordKey},
              deletedAt: { $eq: null},
            },
          })
          .exec();
        return {
          relatedName: temp[0]?.Subject || "Unrecognized Record",
          original: item,
        };
      } 

      default:
        return {
          relatedName: "Unrecognized Record",
          original: item,
        };
    }
  };

  const loadData = async ({
    skip,
    limit,
    filterValue,
  }: {
    sortInfo?: TypeSortInfo;
    skip?: number;
    limit?: number;
    filterValue?: any;
  }): Promise<{ data: any[]; count: number }> => {
    const db = await getDatabase();

    let selector = {
      fldFKey: keyValue,
    };

    const items = await db.tbldocumentcrossreference
      .find({
        selector,
        skip,
        limit,
      })
      .exec();

    const length = size(items);

    setEqData(null);

    const temp = await Promise.all(items.map(getName));
    const data = await Promise.all(temp.map(transformData));

    const dataKey = await Promise.all(items.map(getKeys));
    let newDataKey: any = [];

    dataKey.map((a: any) => {
      newDataKey = [...newDataKey, ...a];
      setGridData(newDataKey);
    });

    return { data, count: length };
  };

  const init = async (ref: MutableRefObject<TypeComputedProps | null>) => {
    const db = await getDatabase();

    db.tbldocumentcrossreference.$.subscribe(async (ev) => {
      if (ev.operation === 'INSERT' || ev.operation === 'UPDATE') {
        highlightedRowId.current = ev.documentId;
        ref.current?.reload();
      }
      if (ev.operation === 'DELETE') {
        highlightedRowId.current = '-1';
        ref.current?.reload();
      }
    });
  };

  const onReady = (ref: MutableRefObject<TypeComputedProps | null>) => {
    setGridRef(ref);
    init(ref);
  };

  const onLoadingChange = (status: boolean) => {
    // If loading completed - check if there any items that needs to be highlighted.
    if (!status && highlightedRowId.current !== '-1') {
      gridRef?.current?.scrollToId(highlightedRowId.current);
      gridRef?.current?.setSelectedById(highlightedRowId.current, true);
      highlightedRowId.current = '-1';
    }
    setLoading(status);
  };

  const handleDelete = async (item: any) => {
    const db = await getDatabase();

    const docCrossRef = await db.tbldocumentcrossreference
      .findOne({
        selector: {
          PKey: item.PKey,
        },
      })
      .exec();

    try {
      await docCrossRef?.remove();
      setSnackbar({
        open: true,
        message: 'Related Item successfully removed',
        type: 'success',
      });
    } catch (e: any) {
      setSnackbar({
        open: true,
        message: e.message,
        type: 'error',
      });
    }
  };

  const rowDeletePress = (item: any) => {
    setDeleteSelected(item.original);
    setIsDeleting(true);
  };

  const handleDeleteOk = () =>{
    handleDelete(deleteSelected);
    setIsDeleting(false);
    setDeleteSelected(undefined);
  };

  const handleDeleteCancel = () =>{
    setIsDeleting(false);
    setDeleteSelected(undefined);
  };
  

  const rowEdit = async (item: any) => {
    const db = await getDatabase();

    let selectedItem: any = {};
    if (item.original.fldTableName === 'Equipment') {
      selectedItem = await db.equipment
        .find({
          selector: {
            EqKey: item.fldRecordKey,
          },
        })
        .exec();
      setTableName(item.original.fldTableName);
    }

    if (item.original.fldTableName === 'Certificates') {
      selectedItem = await db.certificates
        .find({
          selector: {
            PKey: item.fldRecordKey,
          },
        })
        .exec();
      setTableName(item.original.fldTableName);
    }

    if (item.original.fldTableName === 'Orders') {
      selectedItem = await db.orders
        .find({
          selector: {
            OrderID: item.fldRecordKey,
          },
        })
        .exec();
      setTableName(item.original.fldTableName);
    }

    if (item.original.fldTableName === 'TblSchedMaintEq') {
      const temp = await db.tblschedmainteq
        .find({
          selector: {
            PKey: item.fldRecordKey,
          },
        })
        .exec();
       
      const scheduleItem = await Promise.all(temp.map(populateSchedMaintEQData));
    
      let scheduleType;
      let simpleCheck = false;
      switch(scheduleItem[0].RecurType){
        case 64:{
          scheduleType = 'Daily'
          break;
        }
        case 62:{
          scheduleType = 'Daily';
          simpleCheck = true;
          break;
        }
        case 63:{
          scheduleType = 'Daily'
          break;
        }
        case 47:{
          scheduleType = 'Weekly'
          simpleCheck = true;
          break;
        }
        case 48:{
          scheduleType = 'Weekly'
          break;
        }
        case 54:{
          scheduleType = 'Monthly'
          simpleCheck = true;
          break;
        }
        case 55:{
          scheduleType = 'Monthly'
          break;
        }
        case 56:{
          scheduleType = 'Monthly'
          break;
        }
        case 49:{
          scheduleType = 'Yearly'
          simpleCheck = true;
          break;
        }
        case 50:{
          scheduleType = 'Yearly'
          break;
        }
        case 51:{
          scheduleType = 'Yearly'
          break;
        }
        case 100:{
          scheduleType = 'Event Based'
          break;
        }
        default:{
          scheduleType = 'Event Based'
          break;
        }

      }
      selectedItem = [{
        ...scheduleItem[0],
        scheduleType: scheduleType,
        simpleCheck: simpleCheck,
      }]
      setTableName(item.original.fldTableName);
    }

    if (item.original.fldTableName === 'Inventory') {
      selectedItem = await db.inventory
        .find({
          selector: {
            ProductID: item.fldRecordKey,
          },
        })
        .exec();
      if (selectedItem[0]?.fldDataType === '0') {
        setTableName('General');
      }
      if (selectedItem[0]?.fldDataType === '1') {
        setTableName('Beverage');
      }
      if (selectedItem[0]?.fldDataType === '2') {
        setTableName('Dry');
      }
      if (selectedItem[0]?.fldDataType === '3') {
        setTableName('Uniform');
      }
      if (selectedItem[0]?.fldDataType === '4') {
        setTableName('Medical');
      }
    }

    const temp = selectedItem[0];

    setSelectedRowItem(temp);
    setEditPopupVisible(true);
  };

  const editSave = () => {
    setSelectedRowItem(undefined);
    setEditPopupVisible(false);
    setSnackbar({
      open: true,
      message: 'Item has been updated!',
      type: 'success',
    });
  };

  const editCancel = () => {
    setSelectedRowItem(undefined);
    setEditPopupVisible(false);
  };

  const handleAddClick = () => {
    setAddPopupVisible(true);
  };

  const addCancel = () => {
    setAddPopupVisible(false);
  };

  const addSave = () => {
    setAddPopupVisible(false);
    setSnackbar({
      open: true,
      type: 'success',
      message: 'Related Item has been added!',
    });
  };

  const dataSource = useCallback(loadData, []);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const columns = [
    {
      name: 'relatedName',
      header: 'Associated Record',
      defaultFlex: isDesktop ? 1 : undefined,
    },
    {
      name: 'fldTableName',
      header: 'Module',
      // defaultVisible: false,
      // sortable:false,
      defaultFlex: isDesktop ? 1 : undefined,
    },
    {
      id: 'icons',
      defaultWidth: 80,
      render: ({ data }: any) => (
        <div>
          <IconButton
            onClick={() => rowEdit(data)}
            size="small"
            aria-label="Edit item"
          >
            <Edit fontSize="inherit" />
          </IconButton>
          <IconButton
            onClick={() => rowDeletePress(data)}
            size="small"
            color="error"
            aria-label="Delete item"
          >
            <DeleteTwoTone fontSize="inherit" />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="pt-5">
        <div className="flex justify-end mb-6">
          <Button variant="contained" onClick={handleAddClick}>
            Add
          </Button>
        </div>

        <div
          data-testid="data-grid"
          className="flex flex-col flex-grow retaled-tab-grid"
        >
          <LicensedReactDataGrid
            onLoadingChange={onLoadingChange}
            // defaultLimit={100}
            // livePagination
            onReady={onReady}
            rowHeight={40}
            loading={loading}
            idProperty="PKey"
            enableSelection={false}
            showColumnMenuTool={false}
            disableGroupByToolbar
            defaultGroupBy={["fldTableName"]}
            columns={columns}
            stickyGroupRows
            dataSource={dataSource}
            defaultSortInfo={[
              {name: 'relatedName', dir:1}
            ]}
          />
        </div>
      </div>
      <WarningDialog
        visible={isDeleting}
        title="Delete Warning"
        content="Are you sure you wish to delete record?"
        okText='Yes'
        color='error'
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />
      <RelatedItemEditDialog
        visible={editPopupVisible}
        initialValue={selectedRowItem}
        onSave={editSave}
        onCancel={editCancel}
        tableName={tableName}
      />
      <RelatedDocAddDialog
        initialValue={keyValue}
        docName={keyName}
        visible={addPopupVisible}
        gridKeyData={gridData}
        onSave={addSave}
        onCancel={addCancel}
      />
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        onClose={onSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
          {snackBar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default RelatedTab;
