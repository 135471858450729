import { format } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import moment from "moment";

export const formatDate = (dateString: string) => format(new Date(dateString), 'MM-dd-yyyy');
export const newFormatDate = () => format(new Date(), 'dd-MMM-yyyy');
export const formatDateISO = (dateString: string) => format(new Date(dateString), "yyyy-MM-dd'T'HH:mm:ss")
export const toISODate = (date: Date | null) => moment(date).format('YYYY-MM-DDTHH:mm:ss');
export const formatDateISOshort = (dateString: string) => moment(dateString, 'DD-MMM-yyyy').format('YYYY-MM-DD');
export const formatDateCustom = (dateString: string, formatStr: string) => format(new Date(dateString), formatStr);

export const formatReportDate = (dateString: string) => {
  if (!dateString) {
    return '';
  }
  const date = extractUTCDate(new Date(dateString));
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  };
  const formattedDate = date.toLocaleDateString('en-US', options)
  const parts = formattedDate.split(' ');
  const day = parts[1].replace(',', '');
  const month = parts[0].toUpperCase();
  const year = parts[2];

  return `${day}-${month}-${year}`;
};

export const formatReportHeaderDate = (dateString: string) => {
  if (!dateString) {
    return '';
  }

  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    year: 'numeric',
  };

  return date.toLocaleDateString('en-US', options);
}

export const extractUTCDate = (date: Date) => {
    // Get UTC values
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth();
    const day = date.getUTCDate();
    // Create a new Date with UTC values
    const utcDate = new Date(year, month, day);
    return utcDate;  
}

export const isValidDateFormat = (dateString: any) =>  {
  return(!isNaN(new Date(dateString).getDate()));
};

export const utcToTimeZone = (d: Date): Date => {
  const utcDate = utcToZonedTime(d, 'UTC');
  return utcDate;
}

export const timeZoneToUTC = (d: Date): Date => {
  const timeZoneDate = zonedTimeToUtc(d, 'UTC');
  return timeZoneDate;
}

export const convertDateNumberToDateString = (inputNumber: number): string => {
  // Convert number to string
  const inputString: string = inputNumber.toString();

  // Extract year, month, and day from the input string
  const year: string = inputString.substring(0, 4);
  const month: string = inputString.substring(4, 6);
  const day: string = inputString.substring(6, 8);

  // Define an array of month names
  const monthNames: string[] = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  // Get the month name from the array
  const monthName: string = monthNames[parseInt(month, 10) - 1];

  // Construct the formatted date string
  const formattedDate: string = `${day}-${monthName}-${year}`;

  return formattedDate;
}

export const convertDateToDateNumber = (date: Date): number => {
  // Extract year, month, and day from the date object
  const year: number = date.getFullYear();
  const month: number = date.getMonth() + 1; // Months are 0-indexed, so we add 1
  const day: number = date.getDate();

  // Ensure month and day are two digits by adding leading zeros if needed
  const monthString: string = month < 10 ? '0' + month : month.toString();
  const dayString: string = day < 10 ? '0' + day : day.toString();

  // Concatenate the components to form the YYYYMMDD string
  const dateString: string = `${year}${monthString}${dayString}`;

  // Convert the string to a number
  const dateNumber: number = parseInt(dateString, 10);

  return dateNumber;
}

export const convertDateToTimeNumber = (date: Date): number => {
  // Extract hour and minute from the date object
  const hour: number = date.getHours();
  const minute: number = date.getMinutes();

  // Ensure hour and minute are two digits by adding leading zeros if needed
  const hourString: string = hour < 10 ? '0' + hour : hour.toString();
  const minuteString: string = minute < 10 ? '0' + minute : minute.toString();

  // Concatenate the components to form the HHMM string
  const timeString: string = `${hourString}${minuteString}`;

  // Convert the string to a number
  const timeNumber: number = parseInt(timeString, 10);

  return timeNumber;
}

export const convertTimeNumberToTimeString = (input: number): string => {
  // Convert the number to a string
  let timeString = input.toString();
  
  // Pad the string with zeros to make it 4 characters long
  while (timeString.length < 4) {
      timeString = '0' + timeString;
  }
  
  // Extract hours and minutes from the string
  const hours = timeString.substring(0, 2);
  const minutes = timeString.substring(2, 4);
  
  // Concatenate hours and minutes with a colon between them
  return `${hours}:${minutes}`;
}

export const convertTimeNumberToDate = (input: number): Date => {
  // Convert the number to a string
  let timeString = input.toString();
  
  // Pad the string with zeros to make it 4 characters long
  while (timeString.length < 4) {
      timeString = '0' + timeString;
  }
  
  // Extract hours and minutes from the string
  const hours = parseInt(timeString.substring(0, 2), 10);
  const minutes = parseInt(timeString.substring(2, 4), 10);
  
  // Create a new Date object for the current date
  const date = new Date();
  
  // Set the hours and minutes
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(0);
  date.setMilliseconds(0);
  
  return date;
}