import { FC } from 'react';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

interface InjectedProps {
  visible: boolean;
  onCancel: () => void;
  onLink: () => void;
  onUpload: () => void;
}

const UploadValidationPopup: FC<InjectedProps> = ({
  visible,
  onCancel,
  onLink,
  onUpload,
}) => (
  <Dialog
    open={visible}
    fullWidth
    maxWidth="sm"
    scroll="paper"
    aria-labelledby="scroll-dialog-title"
    aria-describedby="scroll-dialog-description"
  >
    <DialogTitle sx={{ m: 0, p: 4 }} style={{ fontSize: '20px' }}>
      <span className="ml-2"><b>Duplicate File Name</b></span>
      {onCancel ? (
        <IconButton
          aria-label="close"
          onClick={onCancel}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
    <DialogContent dividers>
      <span><b>Link</b> - Don't upload the duplicate file and create a link to the existing file already uploaded to YMS.</span>
      <br/>
      <span><b>Upload</b> - Updates the “Library Title“ in the attachment pop over which allows you to upload the duplicate file.</span>
    </DialogContent>
    <DialogActions sx={{ m: 0, p: 3 }}>
      <Button onClick={onLink} className="w-32" variant="contained">
        Link
      </Button>
      <Button onClick={onUpload} className="w-32" variant="contained">
        Upload
      </Button>
      <Button onClick={onCancel} className="w-32">
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
);

export default UploadValidationPopup;
