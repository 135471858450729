import { RxCollection, RxJsonSchema, RxDocument } from 'rxdb';
import { TblAnyReportHistory } from '../../../generated/graphql';

export type AnyReportHistoryType = TblAnyReportHistory;

export type AnyReportHistoryDocMethods = {};

export type AnyReportHistoryDocument = RxDocument<
  AnyReportHistoryType,
  AnyReportHistoryDocMethods
  >;

export type AnyReportHistoryCollectionMethods = {};

export type AnyReportHistoryCollection = RxCollection<
  AnyReportHistoryType,
  AnyReportHistoryDocMethods,
  AnyReportHistoryCollectionMethods
  >;

export const schema: RxJsonSchema<AnyReportHistoryType> = {
  title: 'Any Reports History Collection',
  description: 'Any reports history',
  version: 1,
  keyCompression: false,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    PKey: {
      type: 'string',
      primary: true,
    },
    FKey: {
      type: 'string',
    },
    fldAction: {
      type: ['string', 'null'],
    },
    fldDateRevision: {
      type: ['string', 'null'],
    },
    fldLastAccessedBy: {
      type: ['string', 'null'],
    },
    fldLastAccessedByName: {
      type: 'string',
    },
    fldLastAccessedDate: {
      type: ['string', 'null'],
    },
    fldReportFile: {
      type: 'string',
    },
    fldReportPassword: {
      type: ['string', 'null'],
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
    updatedAt: {
      type: 'string',
    },
  },
  required: ['FKey', 'fldReportFile', 'fldLastAccessedByName', 'PKey'],
  additionalProperties: false,
};

export default schema;
