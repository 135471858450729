import React, { FC, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { isNil } from 'lodash';
import Button from '@mui/material/Button';
import {
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert,
  Grid,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { IAttachmentState } from '../index';
import { ATTACHMENTS } from '../../../consts';
import FileUploadFormOld from '../../Attachments/component/FileUploadFormOld';

interface InjectedProps {
  initialValues?: IAttachmentState;
  visible: boolean;
  setUploadedFile: any;
  uploadedFile: any;
  type: string;
  onCancel?: () => void;
  onSubmit: (payload: IAttachmentState) => void;
}

type FilePopupForm = {
  PKey: string;
  fldFKey: any;
  fldRevision: any;
  fldPage: any;
  fldNotes: any;
  fldRevisionDate: any;
  fldFileName: any;
  fldApprovedByPM: any;
};

const filedefaultValue = {
  PKey: '',
  fldFKey: '',
  fldRevision: 0,
  fldPage: 0,
  fldNotes: '',
  fldRevisionDate: new Date(),
  fldApprovedByPM: new Date(),
  fldFileName: '',
};

const DocumentRevisionPopup: FC<InjectedProps> = ({
  initialValues,
  visible,
  setUploadedFile,
  uploadedFile,
  type,
  onCancel,
  onSubmit,
}) => {
  const {
    control, handleSubmit, reset, getValues,
  } = useForm<FilePopupForm>({
    mode: 'onBlur',
  });
  const [alert, setAlert] = useState(false);

  const setInitialValue = async () => {
    // Dont do anything special
    if (isNil(initialValues)) return reset(filedefaultValue);

    const payload = {
      PKey: initialValues.PKey,
      fldFKey: initialValues.fldFKey,
      fldRevision: initialValues.fldRevision,
      fldPage: initialValues.fldPage,
      fldNotes: initialValues.fldNotes,
      fldRevisionDate: initialValues.fldRevisionDate,
      fldApprovedByPM: initialValues.fldApprovedByPM,
      fldFileName: initialValues.fldFileName,
    };

    updateFormData(payload);
  };

  useEffect(() => {
    setInitialValue();
  }, [initialValues]);

  const updateFormData = (payload: any) => {
    if (!isNil(payload)) {
      reset(payload);
    }
  };

  const onSaveClicked = (data: FilePopupForm) => {
    if (!uploadedFile.url && initialValues && !initialValues.fldFileName) {
      setAlert(true);
      return;
    }

    const payload: any = {
      PKey: data.PKey,
      fleFKey: data.fldFKey,
      fldFileName: data.fldFileName,
      fldPage: data.fldPage === 'undefined' ? null : parseInt(data.fldPage),
      fldNotes: data.fldNotes,
      fldRevision: data.fldRevision,
      fldRevisionDate: data.fldRevisionDate,
      fldApprovedByPM: data.fldApprovedByPM,
    };
    onSubmit(payload);
  };

  const onCancelClick = () => {
    reset(filedefaultValue);
    setAlert(false);
    onCancel && onCancel();
  };

  return (
    <div>
      <form>
        <Dialog
          open={visible}
          onClose={onCancelClick}
          fullWidth
          maxWidth="md"
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle sx={{ m: 0, p: 4 }} style={{ fontSize: '16px' }}>
            <span className="ml-2">
              {type === 'photo'
                ? ATTACHMENTS.ATTACH_PHOTOS
                : 'Revision Information'}
            </span>
            {onCancel ? (
              <IconButton
                aria-label="close"
                onClick={onCancelClick}
                sx={{
                  position: 'absolute',
                  right: 10,
                  top: 14,
                  color: (theme) => theme.palette.grey[400],
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>
          <DialogContent dividers style={{ maxHeight: '60vh' }}>
            {alert && (
              <Alert severity="error" className="mb-5">
                File is empty
              </Alert>
            )}
            {/* <Controller
              name="DocTitle"
              control={control}
              defaultValue=""
              rules={{ required: "Library Title required" }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  required
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  className="mb-8 w-full"
                  label="Library Title"
                ></TextField>
              )}
            /> */}
            <Grid container spacing={2}>
              <Grid item xs={6} sm={2}>
                <Controller
                  name="fldRevision"
                  control={control}
                  defaultValue={0}
                  rules={{ required: 'Revision number required' }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      required
                      type="number"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      label="Revision #"
                      className="mb-8"
                      autoComplete='off'
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={2}>
                <Controller
                  name="fldPage"
                  control={control}
                  defaultValue={0}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      type="number"
                      value={value}
                      onChange={onChange}
                      className="mb-8"
                      label="Page #"
                      autoComplete='off'
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Controller
                  name="fldRevisionDate"
                  control={control}
                  defaultValue={new Date()}
                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="Revision Date"
                        inputFormat="dd-MMM-yyyy"
                        value={value}
                        onChange={onChange}
                        renderInput={(params) => <TextField {...params} autoComplete='off' size="small" />}
                        className="mb-8"
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Controller
                  name="fldApprovedByPM"
                  control={control}
                  defaultValue={new Date()}
                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="Approved Date"
                        inputFormat="dd-MMM-yyyy"
                        value={value}
                        onChange={onChange}
                        renderInput={(params) => <TextField {...params} autoComplete='off' size="small" />}
                        className="mb-8"
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
            </Grid>
            <Controller
              name="fldNotes"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  className="mb-8 w-full"
                  label="Revision Notes"
                  autoComplete='off'
                  rows={3}
                  multiline
                  size="small"
                />
              )}
            />

            <FileUploadFormOld
              setUploadedFile={setUploadedFile}
              uploadedFile={uploadedFile}
              type={type}
              attachmentValue={initialValues}
            />
          </DialogContent>
          <DialogActions sx={{ m: 0, p: 3 }}>
            <Button
              onClick={handleSubmit(onSaveClicked)}
              className="ml-4 mr-3 w-32"
              variant="contained"
            >
              Save
            </Button>
            <Button onClick={onCancelClick} className="w-32">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
};

export default DocumentRevisionPopup;
