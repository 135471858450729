import { Box, Radio } from "@mui/material";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { Control, UseFormWatch } from "react-hook-form";
import Autocomplete from "src/components/UI/Forms/Autocomplete";
import DatePicker from "src/components/UI/Forms/DatePicker";
import Input from "src/components/UI/Forms/Input";
import { TimeMeasureOptions } from "src/consts";
import { isValidDateFormat } from "src/utils/format-dates";

interface DailyScheduleProps {
    form: any;
    initialValue: any;
    onChange: (field: string, value: any) => void;
    control: Control;
    watch: UseFormWatch<any>;
    getValues: any;
    disableEdit?: boolean;
    setValue: any;
}

const RecurTypeEnum = {
    EVERY: "64",
    EVERY_WEEKDAYS: "63",
    REGENERATE: "62",
}

const DailyScheduleForm: FC<DailyScheduleProps> = ({
    form,
    initialValue,
    onChange,
    control,
    watch,
    getValues,
    disableEdit = false,
    setValue
}) => {
    const [initialRender, setInitialRender] = useState<boolean>(true);
    const scheduleType = watch("scheduleType");
    const simpleCheck = watch("simpleCheck");
    const RecurPatternEvery = watch("RecurPatternEvery");
    const RecurPatternRegenerate = watch("RecurPatternRegenerate");
    const RecurType = watch("RecurType");

    useEffect(() => {
        if (scheduleType?.id === "0" || scheduleType === "Daily") {
          const RecurTypeValue: string = initialValue.RecurType?.toString() === RecurTypeEnum.EVERY
                                            ? RecurTypeEnum.EVERY
                                            : initialValue.RecurType?.toString() === RecurTypeEnum.EVERY_WEEKDAYS
                                                ? RecurTypeEnum.EVERY_WEEKDAYS
                                                : RecurTypeEnum.REGENERATE
          if (initialValue.RecurType) {
              setValue("RecurType", RecurTypeValue);
              setValue("RecurPatternEvery", RecurTypeValue === RecurTypeEnum.EVERY ? initialValue.RecurPattern : "");
              setValue("RecurPatternRegenerate", RecurTypeValue === RecurTypeEnum.REGENERATE ? initialValue.RecurPattern : "");
              setValue("simpleCheck", RecurTypeValue === RecurTypeEnum.REGENERATE);
              setValue("fldDateTrigger", initialValue.fldDateTrigger);
              setValue("fldTimeWarn", initialValue.fldTimeWarn);
              setValue("fldTimeMeasureWarn", initialValue.fldTimeMeasureWarn);
          }
          else {
              setValue("RecurType", RecurTypeEnum.REGENERATE);
              setValue("simpleCheck", true);
              setValue("fldDateTrigger", "");
              setValue("fldTimeWarn", "");
              setValue("fldTimeMeasureWarn", "");
          }
          setTimeout(() => {
            setInitialRender(false);
          });
        }
    }, [scheduleType, initialValue]);

    useEffect(() => {
      if(!initialRender) {
        let selectedDays: number = 0;
        let today = new Date();
        switch (RecurType) {
            case RecurTypeEnum.EVERY: {
              selectedDays = Number(RecurPatternEvery);
              setValue("RecurPatternRegenerate","");
              break;
            }
            case RecurTypeEnum.EVERY_WEEKDAYS: {
              selectedDays = 1;
              switch (today.getDay()) {
                case 5: selectedDays = 3; break;
                case 6: selectedDays = 2; break;
              }
              setValue("RecurPatternEvery","");
              setValue("RecurPatternRegenerate","");
              break;
            }
            case RecurTypeEnum.REGENERATE: {
              selectedDays = Number(RecurPatternRegenerate);
              setValue("RecurPatternEvery","");
              break;
            }
        }
        if (selectedDays > 0) {
            today.setDate(today.getDate() + selectedDays);
            setValue("fldDateTrigger", moment(today).format("DD-MMM-YYYY"));
        }
      }
    }, [RecurType, RecurPatternEvery, RecurPatternRegenerate]);

    useEffect(() => {
        if(simpleCheck) {
            setValue("RecurType", RecurTypeEnum.REGENERATE);
        }
    }, [simpleCheck]);

    return (
        <div>
            {scheduleType?.id === "0" || scheduleType === "Daily"
            ? <Box>
                {!simpleCheck
                  ? <Box>
                      <div className="pt-4">
                        <Box display="flex" alignItems="center" alignContent="center">
                          <Box>
                            <Radio
                              checked={RecurType === RecurTypeEnum.EVERY}
                              onChange={event => onChange("RecurType", event.target.value)}
                              value={RecurTypeEnum.EVERY}
                              name="RecurType"
                            />
                            Every &nbsp;
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Input
                              name="RecurPatternEvery"
                              inputProps={{
                                label: '',
                                type: 'number',
                                inputProps: {
                                  min: 1,
                                },
                                sx: { width: "100px" }
                              }}
                              control={control}
                              disabled={(RecurType != RecurTypeEnum.EVERY)}
                              rules={{ required: RecurType === RecurTypeEnum.EVERY }}
                            />
                            <p>&nbsp; Day(s)</p>
                          </Box>
                        </Box>
                      </div>
                      <div className="pt-4">
                        <Box>
                          <Radio
                            checked={RecurType === RecurTypeEnum.EVERY_WEEKDAYS}
                            onChange={event => onChange("RecurType", event.target.value)}
                            value={RecurTypeEnum.EVERY_WEEKDAYS}
                            name="RecurType"
                          />
                          Every Weekday
                        </Box>
                      </div>
                    </Box>
                  : null
                }
                <div className="pt-4">
                  <Box display="flex" alignItems="center" alignContent="center">
                    <Box>
                      <Radio
                        checked={RecurType === RecurTypeEnum.REGENERATE}
                        onChange={event => onChange("RecurType", event.target.value)}
                        value={RecurTypeEnum.REGENERATE}
                        name="RecurType"
                      />
                      Regenerate &nbsp;
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Input
                        name="RecurPatternRegenerate"
                        inputProps={{
                          label: '',
                          type: 'number',
                          inputProps: {
                            min: 1,
                          },
                          sx: { width: "100px" }
                        }}
                        control={control}
                        disabled={(RecurType != RecurTypeEnum.REGENERATE)}
                        rules={{ required: RecurType === RecurTypeEnum.REGENERATE }}
                      />
                      <p>&nbsp; Day(s)</p>
                    </Box>
                  </Box>
                </div>
                <div className="pt-4">
                  <Box>
                    <DatePicker
                      name="fldDateTrigger"
                      control={control}
                      label="Date Next Due"
                      rules={{
                        required: '*Date value is required for this field!',
                        validate: (value) => isValidDateFormat(value) || 'Please enter a valid date format dd-MMM-yyyy',
                      }}
                    />
                  </Box>
                </div>
                <div className="pt-4">
                  <Box display="flex" alignItems="center" alignContent="center">
                    <Box>
                      Remind me &nbsp;
                    </Box>
                    <Box display="flex" alignItems="center" marginRight={2}>
                      <Input
                        name="fldTimeWarn"
                        inputProps={{
                          label: '',
                          type: 'number',
                          inputProps: {
                            min: 1,
                          },                        
                          sx: {
                            width: "100px",
                            '& .MuiOutlinedInput-root': {
                              '& .MuiOutlinedInput-input': {
                                textAlign: 'right',
                              },
                            },
                          },
                        }}
                        control={control}
                      />
                    </Box>
                    <Box display="flex" alignItems="center" width={150}>
                      <Autocomplete
                        onChange={onChange}
                        label="Time Measure"
                        options={TimeMeasureOptions}
                        control={control}
                        name="fldTimeMeasureWarn"
                        keyExpr="id"
                        displayExpr="timeMeasure"
                      />
                    </Box>
                    <Box>
                      &nbsp; before due
                    </Box>
                  </Box>
                </div>
              </Box>
            : null
          }
        </div>
    )
}

export default DailyScheduleForm
