import React, { FC } from 'react';
import {
  DatePicker as MaterialDatePicker,
  LocalizationProvider,
} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField, TextFieldPropsSizeOverrides } from '@mui/material';
import { Control, RegisterOptions, useController } from 'react-hook-form';
import moment from 'moment';
import { OverridableStringUnion } from '@mui/types';
import enLocale from 'date-fns/locale/en-US';
import { DatePickerView } from '@mui/lab/DatePicker/shared';
import { isNull } from 'lodash';


interface InjectedProps {
  control: Control;
  name: string;
  defaultValue?: any;
  rules?: RegisterOptions;
  label?: string;
  views?: DatePickerView[];
  inputFormat?: string;
  size?: OverridableStringUnion<
    'small' | 'medium',
    TextFieldPropsSizeOverrides
  >;
  minDate?: any;
  fullWidth?: boolean;
  disabled?: boolean;
}

const DatePicker: FC<InjectedProps> = ({
  name,
  control,
  defaultValue,
  rules,
  label,
  views,
  inputFormat = "dd-MMM-yyyy",
  size = 'small',
  minDate,
  fullWidth = false,
  disabled = false,
}) => {
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
    rules,
  });

  const handleOnChange = (value: any) => {
    return inputProps.onChange(!isNull(value) ? moment(value).toDate() : null) 
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={enLocale}>
      <MaterialDatePicker
        {...inputProps}
        views={views}
        onChange={handleOnChange}
        ref={ref}
        label={label}
        minDate={minDate}
        inputFormat={inputFormat}
        mask={""}
        disabled = {disabled}
        renderInput={(params) => (
          <TextField
            ref={ref}
            inputRef={ref}
            size={size}
            fullWidth={fullWidth}
            {...params}
            // TODO: Why did Andrei put this class on this control?
            // className="flex-grow w-full"
            autoComplete='off'
            error={!!error}
            helperText={error?.message}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
