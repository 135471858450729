import React, { FC, useEffect, useState } from 'react';
import { isNil, isEmpty, isString } from 'lodash';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Checkbox as MaterialCheckbox,
  FormControlLabel,
} from '@mui/material';
import { Info } from '@mui/icons-material';
import Checkbox from 'src/components/UI/Forms/Checkbox';
import { Control, UseFormWatch } from 'react-hook-form';
import Input from 'src/components/UI/Forms/Input';
import Autocomplete from '../../../UI/Forms/Autocomplete';
import WeeklyScheduleForm from './WeeklySchedule';
import DailyScheduleForm from './DailySchedule';
import MonthlyScheduleForm from './MonthlySchedule';
import YearlyScheduleForm from './YearlySchedule';
import EventBasedSchedule from './EventBasedSchedule';
import { getDatabase } from '../../../../rxdb';

const RecurTypeEnum = {
  EVERY: "64",
  EVERY_WEEKDAYS: "63",
  REGENERATE: "62",
}

const scheduleOptionsWithHours: any[] = [
  {
    scheduleType:"Hours Only",
    id:"5"
  },
  {
    scheduleType: "Daily",
    id: "0"
  },
  {
    scheduleType: "Weekly",
    id: "1"
  },
  {
    scheduleType: "Monthly",
    id: "2"
  },
  {
    scheduleType: "Yearly",
    id: "3"
  },
  {
    scheduleType: "Event Based",
    id: "4"
  }
];

const scheduleOptions: any[] = [
  {
    scheduleType: "Daily",
    id: "0"
  },
  {
    scheduleType: "Weekly",
    id: "1"
  },
  {
    scheduleType: "Monthly",
    id: "2"
  },
  {
    scheduleType: "Yearly",
    id: "3"
  },
  {
    scheduleType: "Event Based",
    id: "4"
  }
];

interface ScheduleProps {
  form: any;
  initialValue: any;
  onChange: (field: string, value: any) => void;
  control: Control;
  watch: UseFormWatch<any>;
  getValues: any;
  disableEdit?: boolean;
  setValue: any;
}

const ScheduleForm: FC<ScheduleProps> = ({
  form,
  onChange,
  initialValue,
  control,
  watch,
  setValue,
  getValues,
  disableEdit = false,
}) => {
  const [showLimitIterations, setShowLimitIterations] = useState<boolean>(initialValue?.fldIterations > 0);
  const [showIntervalInfo, setShowIntervalInfo] = useState<boolean>(false);
  const [showRemindInfo, setShowRemindInfo] = useState<boolean>(false);
  const [showSimpleCheckbox, setShowSimpleCheckbox] = useState(true);
  const [disableRemindMe,setDisableRemindMe] = useState(true);
  const fldSchedulePattern = watch("fldSchedulePattern");
  const fldHourInterval = watch("fldHourInterval");
  const fldHoursTrigger = watch("fldHoursTrigger");
  const scheduleType = watch("scheduleType");
  const fldHourWarn = watch("fldHourWarn");

  useEffect(()=>{
    if (!isNil(initialValue.fldHoursTrigger) && initialValue.fldHoursTrigger !== -1){
      setDisableRemindMe(false)
    }
  })

  useEffect(()=>{
    if (isEmpty(fldHourInterval) || parseInt(fldHourInterval,10) <= 0){
      setDisableRemindMe(true)
    } else {
      setDisableRemindMe(false)
    }
  },[fldHourInterval])

  useEffect(()=>{
    if(scheduleType === 'Hours Only' || scheduleType?.scheduleType === 'Hours Only'){
      // TODO: Make it better
      // Currently when you switch from Hours only to Daily and back, RecurType will stay as Daily...
      // Fix that by resetting
      setValue('RecurType', 0);
      setValue('RecurPattern', undefined);
      setValue('RecurInterval', undefined);
      setShowSimpleCheckbox(false)
    } else if (scheduleType === "Event Based" || scheduleType?.scheduleType === "Event Based"){
      setShowSimpleCheckbox(false)
    } else {
      setShowSimpleCheckbox(true)
    }
  },[scheduleType, initialValue.fldCountHours])

  const handleNumberIterationsChange = (e: any) => {
    const checked = e.target.checked;
    setShowLimitIterations(checked);

    const iterations = initialValue?.fldIterations >= 0 ? initialValue.fldIterations : null;

    onChange('fldIterations', checked ? iterations : null)
  }

  return (
    <div><div>
      <Grid container spacing={5}>
        <Grid item xs={12} md={4}>
          <Autocomplete
            onChange={onChange}
            label={"Schedule Pattern"}
            options={initialValue.fldCountHours ? scheduleOptionsWithHours : scheduleOptions}
            control={control}
            name={"scheduleType"}
            keyExpr="id"
            displayExpr="scheduleType"
            rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={6}>
          {showSimpleCheckbox && !isNil(getValues('scheduleType')) && 
            <Checkbox
              control={control}
              name="simpleCheck"
              label="Simple"
            />
          }
        </Grid>
      </Grid>
    </div>
    {scheduleType?.id === "0" || scheduleType === "Daily"
      ? <div>
          <DailyScheduleForm
            watch={watch}
            control={control}
            initialValue={initialValue}
            form={form}
            onChange={onChange}
            getValues={getValues}
            setValue={setValue}
            disableEdit={disableEdit}
          />
        </div>
      : null
    }
    { scheduleType?.id === "1" || scheduleType === "Weekly" ? <div>
      <WeeklyScheduleForm
        watch={watch}
        control={control}
        initialValue={initialValue}
        form={form}
        onChange={onChange}
        getValues={getValues}
        setValue={setValue}
        disableEdit={disableEdit}
      />

    </div>: null}
    {scheduleType?.id === "2" || scheduleType === "Monthly"
      ? <div>
          <MonthlyScheduleForm
            watch={watch}
            control={control}
            initialValue={initialValue}
            form={form}
            onChange={onChange}
            getValues={getValues}
            setValue={setValue}
            disableEdit={disableEdit}
          />
        </div>
      : null
    }
    {scheduleType?.id === "3" || scheduleType === "Yearly"
      ? <div>
          <YearlyScheduleForm
            watch={watch}
            control={control}
            initialValue={initialValue}
            form={form}
            onChange={onChange}
            getValues={getValues}
            setValue={setValue}
            disableEdit={disableEdit}
          />
        </div>
      : null
    }
    {scheduleType?.id === "4" || scheduleType === "Event Based"
      ? <div>
          <EventBasedSchedule
            watch={watch}
            control={control}
            initialValue={initialValue}
            form={form}
            onChange={onChange}
            getValues={getValues}
            setValue={setValue}
            disableEdit={disableEdit}
          />
        </div>
      : null
    }
    {
    // (scheduleType?.id === "5" || scheduleType === "Hours Only") &&
    // changing above condition as requested in TD-1206 - show hours related fields if fldCountHours = true, no matter the schedule pattern 
    initialValue.fldCountHours && !isNil(getValues('scheduleType')) &&
      <React.Fragment>
        <div className="pt-4">
          <Box display={"flex"} alignItems="center" gap={2}>
            <Box flex={1} maxWidth="30%">
              <Input
                name="fldHourInterval"
                inputProps={{
                  label: 'Perform Every',
                  type: 'number',
                  inputProps: {
                    min: 1,
                  },
                  sx: {
                    '& .MuiOutlinedInput-root': {
                      '& .MuiOutlinedInput-input': {
                        textAlign: 'right',
                      },
                    },
                  },
                }}
                control={control}
              //   rules={{
              //     required: fldSchedulePattern?.fldMember === "Hours Only"
              //   }}
              />
            </Box>
            <Box mr={3}>Hours</Box>
            <Box>
              <Checkbox
                control={control}
                name="fldHourLock"
                label="Lock Hours"
                disabled={disableRemindMe}
              />
            </Box>
            <Box>
              <IconButton size="small" onClick={() => setShowIntervalInfo(true)}>
                <Info fontSize="inherit" />
              </IconButton>
            </Box>
          </Box>
        </div>
        <div className="pt-4">
          <Box display={"flex"} alignItems="center" gap={2}>
            <Box maxWidth={"30%"}>
              <Input
                name="fldHoursTrigger"
                inputProps={{
                  label: 'Hours Next Due',
                  type:'number',
                  InputLabelProps: { shrink: Boolean(fldHoursTrigger) },
                  sx: {
                    '& .MuiOutlinedInput-root': {
                      '& .MuiOutlinedInput-input': {
                        textAlign: 'right',
                      },
                    },
                  },
                }}
                control={control}
                disabled={disableRemindMe}
                rules={{
                  required: Boolean(fldHourInterval) || fldSchedulePattern?.fldMember === "Hours Only"
                }}
              />
            </Box>
            <Box>{`Current Equipment hours: ${initialValue.Hours}`}</Box>
          </Box>
        </div>
        <div className="pt-4">
          <Box display={"flex"} alignItems="center" gap={2}>
            <Box flex={1} maxWidth="30%">
              <Input
                name="fldHourWarn"
                inputProps={{
                  label: 'Remind Me',
                  type:"number",
                  sx: {
                    '& .MuiOutlinedInput-root': {
                      '& .MuiOutlinedInput-input': {
                        textAlign: 'right',
                      },
                    },
                  },
                }}
                control={control}
                rules={{
                  validate: () => {
                    if ((parseInt(fldHourInterval,10) < parseInt(fldHourWarn,10))) {
                      return 'Remind hours should be smaller than Perform Every hours';
                    }
                  },
                }}
                disabled={disableRemindMe}
              />
            </Box>
            <Box>Hours before due</Box>
            <Box>
              <IconButton size="small" onClick={() => setShowRemindInfo(true)}>
                <Info fontSize="inherit" />
              </IconButton>
            </Box>
          </Box>
        </div>
        </React.Fragment>
      }
      <div className="pt-2">
        <Box display={"flex"} alignItems="center" gap={2}>
          <Box flex={1}>
            <FormControlLabel
              control={
                <MaterialCheckbox checked={showLimitIterations} onChange={handleNumberIterationsChange} />
              }
              label="Limit number of iterations"
            />
          </Box>
        </Box>
        <div className="pt-2">
          {showLimitIterations && (
            <Box display={"flex"} alignItems="center" gap={2}>
              <Box flex={1} maxWidth="30%">
                <Input
                  name="fldIterations"
                  inputProps={{
                    label: 'Perform this task',
                    type:'number',
                    sx: {
                      '& .MuiOutlinedInput-root': {
                        '& .MuiOutlinedInput-input': {
                          textAlign: 'right',
                        },
                      },
                    },
                  }}
                  control={control}
                />
              </Box>
              <Box flex={1}>times (empty means unlimited)</Box>
            </Box>
          )}
        </div>
      </div>
      <Dialog
        scroll="paper"
        fullWidth
        maxWidth="md"
        open={showIntervalInfo}
        onClose={() => setShowIntervalInfo(false)}
      >
        <DialogContent dividers>
          <b>Locked Hours:</b>
          <br/>
          <small>When you lock the hours on a schedule, the next due hours will follow the set pattern regardless of the equipment hours when you complete the associated task. For instance, if a schedule is due every 500 hours, completing the task will update the due hours to 1000, 1500, 2000, and so on.</small>
          <br/>
          <br/>
          <b>Unlocked Hours:</b>
          <br/>
          <small>When hours are unlocked, completing the task associated will calculate the next due hours based on the equipment hours when  you completed the task. For example, if a task is set for every 500 hours and the current equipment hours are 1200, the next due hours will be calculated as 1200 + 500 = 1700 hours.</small>
        </DialogContent>
        <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
          <Box
            sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}
          >
            <Button onClick={() => setShowIntervalInfo(false)}>
              Close
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        scroll="paper"
        fullWidth
        maxWidth="md"
        open={showRemindInfo}
        onClose={() => setShowRemindInfo(false)}
      >
        <DialogContent dividers>
          <b>Remind Me:</b>
          <br/>
          <small>If you would like this schedule to trigger a task to the task list before it becomes due, enter a “Remind Me” hours.</small>
          <br/>
          <small>Leaving the “Remind Me” blank will trigger the task only when the equipment hours are equal to or exceed the Hours Next Due.</small>
        </DialogContent>
        <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
          <Box
            sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}
          >
            <Button onClick={() => setShowRemindInfo(false)}>
              Close
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  )
};

export default ScheduleForm;
