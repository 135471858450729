import { SchedMaintEQDocument } from 'src/rxdb/collections/SchedMaintEQ/schema';
import { size, isEmpty, omitBy, isNil, pick } from 'lodash';
import {buildSelectorWithSelectOperator, getOperator, getRxDbDateOperator, getSortParams} from "../../utils";
import {EquipmentDocument} from "../EquipmentPage/rxdb";
import {SchedMaintDocument} from "../../rxdb/collections/SchedMaint/schema";
import moment from "moment";
import {addDays, startOfDay} from "date-fns";
import { TypeFilterValue, CellProps, TypeSingleFilterValue } from '@inovua/reactdatagrid-enterprise/types';
import {WorkIssuesDocument} from "../../rxdb/collections/WorkIssues/rxdb";
import {TDIDb} from "src/rxdb";
import { getRecurrencePerSchedMaint } from 'src/utils';
import { logger } from 'src/helpers/logger';
import {TblSchedMaintEq} from "../../generated/graphql";
import { normalizeDateTime } from 'src/helpers';

export enum SchedRecurringType {
  HoursOnly = 'Hours Only',
  HoursDaily = 'Hours/Daily',
  HoursWeekly = 'Hours/Weekly',
  HoursMonthly = 'Hours/Monthly',
  HoursYearly = 'Hours/Yearly',
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Yearly = 'Yearly',
  HoursOnDemand = 'Hours/On Demand',
  OnDemand = 'On Demand',
}

export enum SchedTimeFrame {
  Overdue = 'Overdue',
  DueIn90OrMore = 'Due in 90 days or more',
  DueIn6090 = 'Due in 60-90 days',
  DueIn3060 = 'Due in 30-60 days',
  DueInLessThan30 = 'Due in less than 30 days',
  DueInWithin7 = 'Due in within 7 days'
}

export type InterfaceScheduleWithSchedMaint = SchedMaintEQDocument & Partial<SchedMaintDocument> & { timeFrame?: string };
export type SchedMaintWithPendingTaskCount = SchedMaintDocument & { pendingTaskCount: number };
export type SchedMainEqWithSchedMainWithPendingTaskCount = InterfaceScheduleWithSchedMaint & { pendingTaskCount: number };

export const defaultSchedEqSelector = {
  deletedBy: {
    $eq: null
  },
};
export const defaultSchedMaintSelector = {
  fldSchedType: 'PM',
  fldIsCheckList: false,
  deletedBy: {
    $eq: null
  },
};
export const defaultEquipmentSelector = {
  deletedBy: {
    $eq: null
  },
};

export const validateForm = (data: any, setSnackbar: any) => {
  const { fldSubject, Department, Equipment } = data;
  if (isNil(fldSubject) || isEmpty(fldSubject) || isNil(Department) || isNil(Equipment)) {
    setSnackbar({
      open: true,
      message: 'Please fill required field(s). Check form field(s) marked with red color',
      type: 'error',
    });
    return false;
  }
  return true;
};

export type SchedMaintEQDocumentPopulated = SchedMaintEQDocument & {
  Equipment: String,
  Hours: Number,
  fldSubject: String,
  Department: String,
  fldAssignedTo: String,
  fldListType: Number,
  fldHourInterval: Number,
  fldSchedType: String,
  RecurType: Number,
  RecurPattern: String,
  fldTimeWarn: Number,
  fldIsCheckList: Boolean
};

export const flattenResults = (issue: SchedMaintEQDocument, Equipment?: EquipmentDocument, SchedMaint?: Partial<SchedMaintWithPendingTaskCount>) => {
  return {
    ...pick(issue, [
      'PKey',
      'EqKey',
      'SchedKey',
      'DateDue',
      'fldHoursTrigger',
      'fldDateTrigger',
      'DateEntered',
      'fldDeferred',
      'fldDeferredDate',
      'fldHoursCompleted',
      'fldIndex',
      'fldIterations',
      'fldRunOnce',
      'fldLocHierarchy',
      'fldSRHKey',
    ]),
    Equipment: Equipment?.UniqueName,
    Hours: Equipment?.Hours,
    fldCountHours: Equipment?.fldCountHours,
    pendingTaskCount: SchedMaint?.pendingTaskCount,
    fldSubject: SchedMaint?.fldSubject,
    Department: SchedMaint?.Department,
    fldAssignedTo: SchedMaint?.fldAssignedTo,
    fldListType: SchedMaint?.fldListType,
    fldHourInterval: SchedMaint?.fldHourInterval,
    fldSchedType: SchedMaint?.fldSchedType,
    RecurType: SchedMaint?.RecurType,
    // We use it on EquipmentSchedule grid, allows us to enable grouping
    timeFrame: getSchedMaintTimeFrameLabel(issue),
    RecurPattern: SchedMaint?.RecurPattern,
    fldTimeWarn: SchedMaint?.fldTimeWarn,
    scedMaintPkey: SchedMaint?.PKey,
    fldHourWarn:SchedMaint?.fldHourWarn ,
    fldTimeMeasureWarn:SchedMaint?.fldTimeMeasureWarn,
    fldHourLock: SchedMaint?.fldHourLock,
    fldCategory: SchedMaint?.fldHourLock,
    fldCSM: SchedMaint?.fldCSM,
    fldSMS: SchedMaint?.fldSMS,
    fldUserDefined : SchedMaint?.fldUserDefined,
    fldDuration: SchedMaint?.fldDuration,
    fldIsCheckList: SchedMaint?.fldIsCheckList,
    fldLinkID: SchedMaint?.fldLinkID,
    fldHTML: SchedMaint?.fldHTML,
    original: issue,
  };
};


export const populateSchedMaintEQData = async (issue: SchedMaintEQDocument & { original?: SchedMaintEQDocument } ) => {
  //TODO: need to remove this method and should be used only from this file.
  const Equipment = issue.original ? await issue.original?.populate('EqKey') : await issue.populate('EqKey');
  const SchedMaint = issue.original ?  await issue.original?.populate('SchedKey') : await issue.populate('SchedKey');

  return flattenResults(issue, Equipment, SchedMaint);
};

export const getSchedMaintFilterSelector = (filter: TypeFilterValue) => {
  const getSelector = (filter: TypeSingleFilterValue) => {
    switch (filter.name) {
      case 'RecurType': {
        return buildSelectorWithSelectOperator(filter, getSchedMaintEqualSelectorByRecurringType, getSchedMaintNotEqualSelectorByRecurringType);
      }
      case 'fldHourInterval': {
        return {
          fldHourInterval: getOperator(filter),
        };
      }
      case 'fldSubject':
        return {
          fldSubject: getOperator(filter),
        };
      case 'fldListType': {
        return {
          fldListType: getOperator(filter),
        };
      }
      case 'fldAssignedTo': {
        return {
          fldAssignedTo: getOperator(filter),
        };
      }
      case 'fldSchedType': {
        return {
          fldSchedType: getOperator(filter),
        };
      }
      case 'Department': {
        return {
          Department: getOperator(filter),
        };
      }

      default:
        return {};
    }
  };

  return filter?.reduce<any>((acc, current) => {
    const s = omitBy(getSelector(current), isEmpty);

    acc = {
      ...acc,
      ...s,
    };

    return acc;

  }, defaultSchedMaintSelector);
};
export const getEquipmentFilterSelector = (filter: TypeFilterValue) => {
  const getSelector = (filter: TypeSingleFilterValue) => {
    switch (filter.name) {
      case 'Hours': {
        return {
          Hours: getOperator(filter),
        };
      }
      case 'Equipment': {
        return {
          UniqueName: getOperator(filter),
        };
      }

      default:
        return {};
    }
  };

  return filter?.reduce((acc, current) => {
    const s = omitBy(getSelector(current), isEmpty);

    acc = {
      ...acc,
      ...s,
    };

    return acc;

  }, defaultEquipmentSelector);
};
export const getSchedMaintEqFilterSelector = (filter: TypeFilterValue, equipmentKeys: string[], schedMaintKeys: string[]) => {
  const getSelector = (filter: TypeSingleFilterValue) => {
    switch (filter.name) {
      case 'timeFrame': {
        return getSchedMaintSelectorByTimeFrame(filter.value);
      }
      case 'fldHoursTrigger':
        return {
          fldHoursTrigger: getOperator(filter),
        };

      case 'fldDateTrigger':
        return getRxDbDateOperator(filter.operator, 'fldDateTrigger', filter.value)
      default:
        return {};
    }
  };

  // In order to make pagination work we need to have filtration based on Join table instead of separate queries
  const $and: any[] = [defaultSchedEqSelector];

  filter?.map((f) => {
    const s = omitBy(getSelector(f), isEmpty);

    if (isEmpty(s)) return f;

    $and.push(s);

  });

  // Include SchedMain selector
  $and.push({ SchedKey: { $in: schedMaintKeys } });

  // Include Equipments
  $and.push({ EqKey: { $in: equipmentKeys } });

  return ({
    $and
  });
};

export const mergeEquipmentAndSchedMainResults = (joinTable: SchedMaintEQDocument[], equipment: EquipmentDocument[], schedMain: SchedMaintDocument[], pendingTasks: WorkIssuesDocument[] = []) => {
  return joinTable.map(jt => {
    const Equipment = equipment.find(e => e.EqKey === jt.EqKey);
    const SchedMain = schedMain.find(e => e.PKey === jt.SchedKey) as SchedMaintWithPendingTaskCount;

    SchedMain.pendingTaskCount = size(pendingTasks.filter(t => t.fldSchedMaintKey === jt.PKey));

    return flattenResults(jt, Equipment, SchedMain);
  })
};

export const getSchedMaintNotEqualSelectorByRecurringType = (type: TypeFilterValue) => {
  switch (type as unknown as SchedRecurringType) {
    case SchedRecurringType.HoursOnly: return ({ RecurType: { $gt: 0 }  });
    case SchedRecurringType.HoursDaily: return ({ RecurType: { $nin: [62, 63, 64] } });
    case SchedRecurringType.HoursWeekly: return ({ RecurType: { $nin: [47, 48] } });
    case SchedRecurringType.HoursMonthly: return ({ RecurType: { $nin: [54, 55, 56] } });
    case SchedRecurringType.HoursYearly: return ({ RecurType: { $nin: [49, 50, 51] } });
    case SchedRecurringType.Daily: return ({ RecurType: { $nin: [62, 63, 64] } });
    case SchedRecurringType.Weekly: return ({ RecurType: { $nin: [47, 48] } });
    case SchedRecurringType.Monthly: return ({ RecurType: { $nin: [54, 55, 56] } });
    case SchedRecurringType.Yearly: return ({ RecurType: { $nin: [49, 50, 51] } });
    case SchedRecurringType.HoursOnDemand: return ({ RecurType: { $lt: 100 } });
    case SchedRecurringType.OnDemand: return ({ RecurType: { $lt: 100 } });
    default: return ({})
  }
};

export const getSchedMaintEqualSelectorByRecurringType = (type: TypeFilterValue) => {
  switch (type as unknown as SchedRecurringType) {
    case SchedRecurringType.HoursOnly: return ({ $and: [{ fldHourInterval: { $gt: 0 } }, { RecurType: { $lte: 0 } }] });
    case SchedRecurringType.HoursDaily: return ({ $and: [{ fldHourInterval: { $gt: 0 } }, { RecurType: { $in: [62, 63, 64] } }] });
    case SchedRecurringType.HoursWeekly: return ({ $and: [{ fldHourInterval: { $gt: 0 } }, { RecurType: { $in: [47, 48] } }] });
    case SchedRecurringType.HoursMonthly: return ({ $and: [{ fldHourInterval: { $gt: 0 } }, { RecurType: { $in: [54, 55, 56] } }] });
    case SchedRecurringType.HoursYearly: return ({ $and: [{ fldHourInterval: { $gt: 0 } }, { RecurType: { $in: [49, 50, 51] }  }] });
    case SchedRecurringType.Daily: return ({ $and: [{ fldHourInterval: { $lte: 0 } }, { RecurType: { $in: [62, 63, 64] } }] });
    case SchedRecurringType.Weekly: return ({ $and: [{ fldHourInterval: { $lte: 0 } }, { RecurType: { $in: [47, 48] } }] });
    case SchedRecurringType.Monthly: return ({ $and: [{ fldHourInterval: { $lte: 0 } }, { RecurType: { $in: [54, 55, 56] } }] });
    case SchedRecurringType.Yearly: return ({ $and: [{ fldHourInterval: { $lte: 0 } }, { RecurType: { $in: [49, 50, 51] } }] });
    case SchedRecurringType.HoursOnDemand: return ({ $and: [{ fldHourInterval: { $gt: 0 } }, { RecurType: { $gte: 100 } }] });
    case SchedRecurringType.OnDemand: return ({ $and: [{ fldHourInterval: { $lte: 0 } }, { RecurType: { $gte: 100 }  }] });
    default: return ({})
  }
};
export const getSchedMaintRecurringTypeLabel = (sched : SchedMaintDocument) => {
  if (isNil(sched)) return 'Unknown';

  const { RecurType } = sched;
  if (isNil(RecurType)) return 'Unknown';

  const fldHourInterval = sched.fldHourInterval || 0;

  if(fldHourInterval >= 0 && RecurType <= 0) {
    return 'Hours Only'
  } else if(fldHourInterval > 0 && RecurType >= 62 && RecurType <= 64) {
    return 'Hours/Daily'
  } else if(fldHourInterval > 0 && (RecurType === 47 || RecurType === 48)) {
    return 'Hours/Weekly'
  } else if(fldHourInterval > 0 && RecurType >= 54 && RecurType <= 56) {
    return 'Hours/Monthly'
  } else if(fldHourInterval > 0 && RecurType >= 49 && RecurType <= 51) {
    return 'Hours/Yearly'
  } else if(fldHourInterval <= 0 && RecurType >= 62 && RecurType <= 64) {
    return 'Daily'
  } else if(fldHourInterval <= 0 && (RecurType === 47 || RecurType === 48)) {
    return 'Weekly'
  } else if(fldHourInterval <= 0 && RecurType >= 54 && RecurType <= 56) {
    return 'Monthly'
  } else if(fldHourInterval <= 0 && RecurType >= 49 && RecurType <= 51) {
    return 'Yearly'
  } else if(fldHourInterval > 0 && RecurType >= 100) {
    return 'Hours/On Demand'
  } else if(fldHourInterval <= 0 && RecurType >= 100) {
    return 'On Demand'
  } 
  return 'Unknown'
};

export const getSchedMaintSelectorByTimeFrame = (type: SchedTimeFrame) => {
  const getFormat = (date: Date) => moment.utc(startOfDay(date)).toISOString();

  switch (type) {
    case SchedTimeFrame.Overdue: return ({ fldDateTrigger: { $lt: getFormat(new Date()), $ne: null } });
    case SchedTimeFrame.DueIn90OrMore: return ({ fldDateTrigger: { $gte: getFormat(addDays(new Date(), 90)) } });
    case SchedTimeFrame.DueIn6090: return ({
      $and: [
        { fldDateTrigger: { $gte: getFormat(addDays(new Date(), 60)) } },
        { fldDateTrigger: { $lt: getFormat(addDays(new Date(), 91)) } }
      ]
    });
    case SchedTimeFrame.DueIn3060: return ({
      $and: [
        { fldDateTrigger: { $gte: getFormat(addDays(new Date(), 30)) } },
        { fldDateTrigger: { $lt: getFormat(addDays(new Date(), 61)) } }
      ]
    });
    case SchedTimeFrame.DueInLessThan30: return ({
      $and: [
        { fldDateTrigger: { $gte: getFormat(new Date()) } },
        { fldDateTrigger: { $lte: getFormat(addDays(new Date(), 30)) } }
      ]
    });
    case SchedTimeFrame.DueInWithin7: return ({
      $and: [
        { fldDateTrigger: { $gte: getFormat(new Date()) } },
        { fldDateTrigger: { $lte: getFormat(addDays(new Date(), 8)) } }
      ]
    });
    default: return ({})
  }
};

export const getSchedMaintTimeFrameLabel = (sched: SchedMaintEQDocument) => {
  const { fldDateTrigger } = sched;

  if(fldDateTrigger) {
    if(moment(fldDateTrigger).isBefore(moment(), "day")) {
      return 'Overdue'
    } else if(moment(fldDateTrigger).diff(moment(), 'day') >= 90) {
      return 'Due in 90 days or more'
    } else if(moment(fldDateTrigger).diff(moment(), 'day') >= 60 && moment(fldDateTrigger).diff(moment(), 'day') < 90) {
      return 'Due in 60-90 days'
    } else if(moment(fldDateTrigger).diff(moment(), 'day') >= 30 && moment(fldDateTrigger).diff(moment(), 'day') < 60) {
      return 'Due in 30-60 days'
    } else if(moment(fldDateTrigger).diff(moment(), 'day') >= 8 && moment(fldDateTrigger).diff(moment(), 'day') < 30) {
      return 'Due in less than 30 days'
    } else if(moment(fldDateTrigger).diff(moment(), 'day') <= 8) {
      return 'Due in within 7 days'
    }
  }
  return '-'
};

export const onRender = (cellProps: any, { data }: { data: SchedMaintEQDocument }) => {
  const { fldDateTrigger } = data;

    if(moment(fldDateTrigger).isBefore(moment(), "day")) {
      cellProps.style.borderLeft = 'red 3px solid';
    } else if(moment(fldDateTrigger).diff(moment(), 'day') <= 8) {
      cellProps.style.borderLeft = 'yellow 3px solid';
    }
}

export const getSchedMaintResultsForSelector = async (db: TDIDb, selector: any) => {
  try {
    const data = await db
      .tblschedmaint
      .find({
        selector
      })
      .exec();

    return ({
      keys: data.map(j => j.PKey),
      data,
    })
  } catch (e) {
      logger('EventsAndSchedules').trace(e);
    return ({
      keys: [],
      data: []
    })
  }
};

export const getEquipmentResultsForSelector  = async (db: TDIDb, selector: any) => {
  const data = await db
    .equipment
    .find({
      selector,
    })
    .exec();

  return ({
    keys: data.map(j => j.EqKey),
    data
  })
};

export const getSchedMaintEqTotal = async (db: TDIDb) => {
  const { keys: schedMaintKeys } = await getSchedMaintResultsForSelector(db, defaultSchedMaintSelector);
  const { keys: equipmentKeys } = await getEquipmentResultsForSelector(db, defaultEquipmentSelector);

  return size(await db.tblschedmainteq.find({ selector: getSchedMaintEqFilterSelector([], equipmentKeys, schedMaintKeys) }).exec());
};

export const getSchedMaintEquipmentData = async(db: TDIDb, filterValue: any) => {
  const { data: schedMaintResults, keys: schedMaintKeys } = await getSchedMaintResultsForSelector(db, getSchedMaintFilterSelector(filterValue));
  const { data: equipmentResults, keys: equipmentKeys } = await getEquipmentResultsForSelector(db, getEquipmentFilterSelector(filterValue));

  // Make sure our SchedMaint and Equipment exists in our Join table (replicating old code)
  try {
    const joinTable = await db
    .tblschedmainteq
    .find({
      selector: getSchedMaintEqFilterSelector(filterValue, equipmentKeys, schedMaintKeys),
    })
    .exec();

  const schedMaintEqKeys = joinTable.map(j => j.PKey)
  const workissues = await db
    .workissues
    .find({
      selector: {
        $and: [
          { fldSchedMaintKey: {$in:  schedMaintEqKeys}},
          { Completed: { $eq: false }},
          { deletedAt: { $eq: null }},
        ],
      },
    })
    .exec();
  const results = mergeEquipmentAndSchedMainResults(joinTable, equipmentResults, schedMaintResults, workissues) as unknown as SchedMainEqWithSchedMainWithPendingTaskCount;

  // Current records count based on filter
  const length = size(await db.tblschedmainteq.find({ selector: getSchedMaintEqFilterSelector(filterValue, equipmentKeys, schedMaintKeys) }).exec());
  return {
    data: results,
    count: length,
    total: await getSchedMaintEqTotal(db),   // Record count without any filters applied
  };
  } catch (e) {
    logger('EquipmentSchduleUtils').error(e);
    return ({
      data: [],
      count: 0,
      total: 0
    })
  }
}


export const deActivateSchedules = async (rowSelected: any, deleteRelatedWorkIssue= false) => {
  try {
    let items = rowSelected;
    if (typeof rowSelected === 'function') {
      items = rowSelected();
    }
    await Promise.all(Object.values(items || {}).map((item: any) => {
      return item.original.atomicPatch({
        fldDeferred:true,
        fldDeferredDate : normalizeDateTime(new Date()),
      });
    }))
    
  } catch (e) {
    logger('EventsAndSchedules-deactivateSchedules').error(e)
    throw e
  }
}

export const activateSchedules = async (rowSelected: any) => {
  try {
    let items = rowSelected;
    if (typeof rowSelected === 'function') {
      items = rowSelected();
    }
    console.log('activate schedule', items)
    await Promise.all(Object.values(items || {}).map(async (item: any) => {
      console.log('Activating schedules:', item.PKey, item.fldDeferred, item.fldDeferredDate);
      // // fldDeferredDate : moment().toISOString()
      // TODO: Badrish - Need to call getRecurrencePerSchedMaint and update the fldDeferredDate
      let sched;
      if(item.RecurPattern)  sched = await getRecurrencePerSchedMaint(item, undefined);
      return item.original.atomicPatch({
        fldDeferred:false,
        fldDeferredDate: null,
        fldDateTrigger: sched && sched.fldDateTrigger,
      });
    }))
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const deleteSchedEqMaintRelatedWorkIssuePermanently = async (db: TDIDb, rowSelected: any, deletedByUser: any) => {
  try {
    let items = rowSelected;
    if (typeof rowSelected === 'function') {
      items = rowSelected();
    }
    console.log('activate schedule', items)
    await Promise.all(Object.values(items || {}).map(async (item: any) => {
      const workissue = await db.workissues.findOne({
        selector: {
          fldSchedMaintKey: {
            $eq: item.PKey,
          },
        }
      }).exec();
      if(workissue) {
        workissue.atomicPatch({
          Completed: true,
          deletedAt: new Date().toISOString(),
          deletedBy: deletedByUser?.fldCrewID,
          isRecoverable: false
        });
      }
    }))
  } catch (e) {
    console.error(e)
    throw e  
  }
}

export const sortRecurTypeAscending = (v1: number, v2: number, col: any, a: SchedMaintDocument, b: SchedMaintDocument) => {
  return getSchedMaintRecurringTypeLabel(a).localeCompare(getSchedMaintRecurringTypeLabel(b));
};