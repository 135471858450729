import { gql, useQuery } from '@apollo/client';
import { GroupCountOfTblSystemsHierarchy, LogEntryFilterInput } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';

export const GET_LOG_ENTRY_COUNT_BY_CATEGORY = gql`
  query GetLogEntryCountsByCategory($filterInput: LogEntryFilterInput) {
    logEntryCountsByCategory(filterInput: $filterInput) {
      count
      item {
        fldCategory
        fldDepts
        fldNoView
        fldTags
        parentId
        pkey
      }
    }
  }
`;

interface CategoriesResult {
  logEntryCountsByCategory: GroupCountOfTblSystemsHierarchy[];
  refetchLogEntryCountsByCategory: any;
  loading: boolean;
}

export const useGetLogEntryCountsByCategory = (filterInput: LogEntryFilterInput): CategoriesResult => {
  const { data, loading, error, refetch } = useQuery(GET_LOG_ENTRY_COUNT_BY_CATEGORY, {
    variables: { filterInput },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    logger('GetLogEntryCountsByCategory').error('Error fetching categories -->', error.message);
  }

  return {
    logEntryCountsByCategory: data?.logEntryCountsByCategory || [],
    refetchLogEntryCountsByCategory: refetch,
    loading,
  };
};
