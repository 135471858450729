import { FC, useEffect, useState } from 'react';
import { isNil, isArray, last } from 'lodash';
import { Control, RegisterOptions } from 'react-hook-form';
import Autocomplete from 'src/components/UI/Forms/Autocomplete';
import { useGetEquipmentList } from 'src/hooks/equipment/useGetEquipmentList';
import { Equipment } from 'src/generated/dotnet.graphql';

type EquipmentProps = {
  onChange: (field: string, value: any) => void;
  form?: any;
  disabled?: boolean;
  value?: any; // TODO: FIX ME,
  category?: string | null;
  label?: string;
  control: Control;
  defaultValue?: string | null;
  rules?: RegisterOptions
};

const EquipmentDropdown: FC<EquipmentProps> = ({
  category,
  defaultValue,
  label,
  onChange,
  control,
  disabled,
  rules
}) => {
  const [equipmentList, setEquipmentList] = useState<Equipment[]>([]);
  const [filterValue, setFilterValue] = useState<any>(null);

  useEffect(() => {
    const updateFilterValue = () => {
      const updatedCategory = isArray(category) ? last(category) : category;

      if (!isNil(updatedCategory)) {
        if (!isNil(defaultValue)) {
          const selector = {
            or: [
              { fldSrhkey: { eq: updatedCategory } },
              { eqKey: { eq: defaultValue } }
            ]
          };
          setFilterValue(selector);
        } else {
          const selector = { fldSrhkey: { eq: updatedCategory } };
          setFilterValue(selector);
        }
      } else {
        setFilterValue(null);
      }
    };

    updateFilterValue();
  }, [category, defaultValue]);

  const { getEquipmentList, loading } = useGetEquipmentList({ where: filterValue });

  useEffect(() => {
    const fetchEquipment = async () => {
      const queryResult = await getEquipmentList({ variables: { where: filterValue } });
      const data = queryResult?.data?.equipment || [];
      setEquipmentList(data);
    }

    fetchEquipment();
  }, [filterValue]);

  return (
    <Autocomplete
      disabled={disabled}
      displayExpr="uniqueName"
      onChange={onChange}
      loading={loading}
      label={label}
      groupBy={(o: any) => o.group}
      options={equipmentList}
      control={control}
      name="equipment"
      keyExpr="eqKey"
      rules={rules}
    />
  );
};

export default EquipmentDropdown;
