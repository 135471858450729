// React Components
import { useState, useEffect } from 'react';
import { isNil, pick } from 'lodash';
import { useHistory } from 'react-router';

//Context
import ReplicationProvider from 'src/contexts/Replication/ReplicationProvider';

// Style
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { MoreVert } from '@mui/icons-material';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import MailIcon from '@mui/icons-material/Mail';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Close from '@mui/icons-material/Close';
import ForumTwoToneIcon from '@mui/icons-material/ForumTwoTone';
import MuiAppBar from '@mui/material/AppBar';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonIcon from '@mui/icons-material/Person';
import CachedIcon from '@mui/icons-material/Cached';
import ErrorIcon from '@mui/icons-material/Error';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import StarHalfOutlinedIcon from '@mui/icons-material/StarHalfOutlined';
import Popover from '@mui/material/Popover';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import { AppBarProps as MuiAppBarProps } from '@material-ui/core';
import { Divider } from '@mui/material';
import Logo from '../../assets/icon-logo.svg';

// Utils
import { useAuth } from '../../contexts/auth';
import { getDatabase } from 'src/rxdb';
import { format, startOfDay, formatDistance } from 'date-fns';
import { DOWNLOAD_URL } from 'src/consts';
//import { unregister } from 'src/serviceWorkerRegistration';

const drawerWidth = 300;
const drawerMiniWidth = 61;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const StyledLogo = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 2),
  color: 'white',
  flexDirection: 'row',
  fontsize: 20,
  fontWeight: 500,
  background: theme.palette.text.primary, // '#0a2041',
  marginLeft: '-12px',
  marginRight: '12px',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  backgroundColor: 'white',
  zIndex: theme.zIndex.drawer + 1,
  width: `calc(100% - ${drawerMiniWidth}px)`,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    backgroundColor: 'white',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
//
// const Search = styled('div')(({ theme }) => ({
//   position: 'relative',
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   '&:hover': {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginRight: theme.spacing(2),
//   marginLeft: 0,
//   width: '100%',
//   [theme.breakpoints.up('sm')]: {
//     marginLeft: theme.spacing(3),
//     width: 'auto',
//   },
// }));
//
// const SearchIconWrapper = styled('div')(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: '100%',
//   position: 'absolute',
//   pointerEvents: 'none',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
// }));
//
// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: 'inherit',
//   '& .MuiInputBase-input': {
//     padding: theme.spacing(1, 1, 1, 0),
//     // vertical padding + font size from searchIcon
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create('width'),
//     width: '100%',
//     [theme.breakpoints.up('md')]: {
//       width: '20ch',
//     },
//   },
// }));

const StyledIcon = styled(IconButton)(({ theme }) => ({
  '& .css-1bpuqvv-MuiSvgIcon-root': {
    color: '#002041',
    backgroundColor: '#002041',
  },
}));

const transformData = async (item: any) => {
  const authorUser = await item.populate('authorId');
  return {
    ...pick(item, ['id', 'content', 'updatedAt']),
    createdBy: `${authorUser?.fldFirst} ${authorUser?.fldLast}`,
    original: item,
    fldPicture: authorUser?.fldPicture,
  };
};

const SearchAppBar = ({ handleDrawerOpen, open }: any) => {
  const history = useHistory();
  const { user, signOut, resetApplication } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [commentAnchorEl, setCommentAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [commentList, setCommentList] = useState<any>([]);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isCommentMenuOpen = Boolean(commentAnchorEl);

  useEffect(() => {
    if (user) {
      handleLoadComments(user.fldCrewID);
    }
  }, [user]);

  const handleLoadComments = async (userId: any) => {
    try {
      const db = await getDatabase();
      const selector = {
        authorId: {
          $ne: userId,
        },
      };

      db.comments.find({ selector }).$.subscribe(async (items) => {
        const today = startOfDay(new Date());
        const filteredItems = items.filter((item: any) => {
          const updatedDate = new Date(item.updatedAt);
          if (updatedDate > today) {
            return true;
          } else {
            return false;
          }
        });
        const rowData = await Promise.all(filteredItems.map(transformData));
        setCommentList(rowData || []);
      });
    } catch (error) {}
  };

  const handleHoRClick = (event: any) => {
    history.push('/crew/hor?source=profile');
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleProfileMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCommentMenuOpen = (event: any) => {
    setCommentAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleCommentMenuClose = () => {
    setCommentAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleLogoutClick = () => {
    signOut && signOut();
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleProfileClick = () => {
    history.push('/profile');
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: any) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const stringToColor = (string: any) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const stringAvatar = (name: any) => ({
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  });

  const commentId = 'primary-comment-menu';
  const renderComments = (
    <Popover
      id={commentId}
      open={isCommentMenuOpen}
      anchorEl={commentAnchorEl}
      onClose={handleCommentMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box sx={{ minWidth: '450px' }}>
        <Box
          sx={{
            padding: '18px',
            borderBottom: '1px solid #ededed',
            fontSize: '18px',
            fontWeight: 'bold',
          }}
        >
          Comments
        </Box>
        <Box sx={{ padding: '18px', overflowY: 'auto', maxHeight: '500px' }}>
          {commentList.map((item: any) => (
            <div className="flex my-4">
              {isNil(item?.fldPicture) ? (
                <Avatar {...stringAvatar(`${item?.createdBy}`)} />
              ) : (
                <Avatar src={`${DOWNLOAD_URL}/${item?.fldPicture}`} />
              )}
              <div className="ml-2.5">
                <div className="flex items-center">
                  <div className="text-sm font-bold">{item.createdBy}</div>
                  <div className="text-xs ml-2">
                    {formatDistance(new Date(`${item.updatedAt}`), new Date(), {
                      addSuffix: true,
                    })}
                  </div>
                </div>
                <div
                  className="text-sm"
                  dangerouslySetInnerHTML={{ __html: item.content }}
                />
              </div>
            </div>
          ))}
          {commentList.length === 0 && (
            <div className="flex my-4 justify-center">No Comments</div>
          )}
        </Box>
      </Box>
    </Popover>
  );
  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      sx={{ width: 300, fontSize: 14 }}
    >
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        {/* <MenuItem>
          <IconButton className="mr-4" size="large" aria-label="show 4 new mails" color="inherit">
            <Badge badgeContent={4} color="error">
              <MailIcon />
            </Badge>
          </IconButton>
          <p>Messages</p>
        </MenuItem> */}
        {/* <MenuItem>
          <IconButton
            className="mr-4"
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
          >
            <Badge badgeContent={17} color="error">
              <ForumTwoToneIcon />
            </Badge>
          </IconButton>
          <p>Notifications</p>
        </MenuItem> */}
      </Box>
      <MenuItem onClick={handleProfileClick}>
        <IconButton
          className="mr-4"
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <PersonIcon />
        </IconButton>
        <div className="text-base	">Profile</div>
      </MenuItem>
      <MenuItem onClick={handleHoRClick}>
        <IconButton
          className="mr-4"
          size="large"
          aria-label="individualHoR"
          color="inherit"
        >
          <QueryBuilderIcon />
        </IconButton>
        <div className="text-base	">Hours Of Rest</div>
      </MenuItem>
      <MenuItem onClick={handleLogoutClick}>
        <IconButton
          className="mr-4"
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <DirectionsRunIcon />
        </IconButton>
        <div className="text-base">Logout</div>
      </MenuItem>

      <Divider />
      {/* TODO: remove feedback button after beta round */}
      <MenuItem>
        <IconButton
          className="mr-4"
          size="large"
          aria-label="Submit Feedback"
          color="inherit"
        >
          <StarHalfOutlinedIcon />
        </IconButton>

        <a
          className="text-base"
          href="https://forms.office.com/pages/responsepage.aspx?id=iKkwTLZChESO80g8CG_jBv-z-ddiz8tMuqGsCK5ztLBUMTM3MUFURE00NE9UWFQ0VkpVTFJQQzI4Vi4u"
          target="_blank"
          rel="noopener noreferrer"
        >
          Submit Feedback
        </a>
      </MenuItem>
      <MenuItem onClick={resetApplication}>
        <IconButton className="mr-4" size="large" color="inherit">
          <CachedIcon />
        </IconButton>
        <div className="text-base">Reset Application</div>
      </MenuItem>
      {/* 
      // For sentry testing in development mode
      <MenuItem 
        onClick={() => {
          throw new Error('Sentry Test Error');
        }}
      >
        <IconButton className="mr-4" size="large" color="inherit">
          <ErrorIcon />
        </IconButton>
        <div className="text-base">Trigger Error</div>
      </MenuItem> */}
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem> */}
      {/* <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <ForumTwoToneIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem> */}
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
      <MenuItem onClick={handleLogoutClick}>
        <ListItemIcon>
          <DirectionsRunIcon sx={{ color: '#002041' }} fontSize="small" />
        </ListItemIcon>
        <div className="text-base">Logout</div>
      </MenuItem>
    </Menu>
  );

  return (
    <Box>
      <AppBar
        position="fixed"
        open={open}
        sx={{
          marginLeft: {
            xs: 0,
            md: open ? drawerWidth : 0,
          },
          width: {
            xs: '100%',
            md: open ? `calc(100% - ${drawerWidth}px)` : `100%`,
          },
        }}
      >
        <Toolbar>
          {!open && (
            <StyledLogo>
              <img width={40} height={40} src={Logo} alt="Logo" />
            </StyledLogo>
          )}
          <StyledIcon
            size="large"
            edge="start"
            aria-label="open drawer"
            sx={{
              display: {
                xs: 'inline-flex',
                md: open ? 'none' : 'inline-flex',
              },
            }}
            onClick={handleDrawerOpen}
          >
            {!open ? <MenuIcon /> : <Close />}
          </StyledIcon>
          {/* <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
            MUI
          </Typography>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search> */}
          <Box sx={{ flexGrow: 1 }} />
          <Box className="flex items-center">
            <ReplicationProvider />
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              {/* <StyledIcon size="large" aria-label="show 4 new mails" color="inherit">
                <Badge badgeContent={4} color="error">
                  <MailIcon />
                </Badge>
              </StyledIcon> */}
              <StyledIcon
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
                onClick={handleCommentMenuOpen}
              >
                <Badge badgeContent={commentList.length} color="error">
                  <ForumTwoToneIcon />
                </Badge>
              </StyledIcon>
            </Box>
            <Box sx={{ display: 'flex', flexGrow: 0 }}>
              <Button
                endIcon={
                  <MoreVert sx={{ display: { xs: 'block', md: 'none' } }} />
                }
                startIcon={
                  isNil(user?.fldPicture) ? (
                    <Avatar
                      {...stringAvatar(`${user?.fldFirst} ${user?.fldLast}`)}
                    />
                  ) : (
                    <Avatar
                      // alt="Remy Sharp"
                      src={`${DOWNLOAD_URL}/${user?.fldPicture}`}
                    />
                  )
                }
                className="normal-case text-black"
                onClick={handleProfileMenuOpen}
              >
                {`${user?.fldFirst} ${user?.fldLast}`}
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      {renderMobileMenu}
      {renderMenu}
      {renderComments}
    </Box>
  );
};

export default SearchAppBar;
