import './style.css'
import { FC,useState, useRef, MutableRefObject } from 'react';
import { IconButton, useMediaQuery } from '@mui/material';
import { DeleteTwoTone, Edit } from '@mui/icons-material';
import LocationOnTwoToneIcon from '@mui/icons-material/LocationOnTwoTone';
import { WorkIssuesDocument } from 'src/rxdb/collections/WorkIssues/rxdb';
import { TypeComputedProps, TypeFilterValue, TypeSortInfo } from '@inovua/reactdatagrid-enterprise/types';
import LicensedReactDataGrid from 'src/components/UI/LicensedReactDataGrid';
import WarningDialog from 'src/components/UI/WarningDialog';
import { TblSparesUsed } from 'src/generated/dotnet.graphql';

interface InjectedProps {
  issue?: WorkIssuesDocument;
  onEdit: (spare: TblSparesUsed) => void;
  onDelete: (spareKey: string) => void;
  onLocation: (spare: any) => void;
  showLocationIcon?: boolean;
  loading: boolean;
  spares?: TblSparesUsed[];
  readOnly?: boolean;
}

const SpareGrid: FC<InjectedProps> = ({
  onEdit,
  onDelete,
  issue,
  onLocation,
  showLocationIcon = true,
  spares,
  loading,
  readOnly
}) => {
  const [gridLoading, setGridLoading] = useState<boolean>(loading);
  const highlightedRowId = useRef<string>('-1');
  const [gridRef, setGridRef] = useState<any>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteSelected, setDeleteSelected] = useState<string>();
  const isMobile = useMediaQuery('(max-width: 420px)');

  const onReady = (ref: MutableRefObject<TypeComputedProps | null>) => {
    setGridRef(ref);
  };

  const onLoadingChange = (status: boolean) => {
    // If loading completed - check if there any items that needs to be highlighted.
    if (!status && highlightedRowId.current !== '-1') {
      // gridRef?.current?.scrollToId(highlightedRowId?.current || '-1');
      gridRef?.current?.setSelectedById(highlightedRowId.current, true);
      highlightedRowId.current = '-1';
    }
    setGridLoading(status);
  };

  const onEditClick= (item: TblSparesUsed) =>{
    onEdit(item)
  }

  const onDeleteClick= (spare: TblSparesUsed) =>{
    setDeleteSelected(spare.pkey);
    setIsDeleting(true);
  };

  const onLocationClick = (item: TblSparesUsed) =>{
    onLocation(item)
  }

  const handleDeleteOk = () =>{
    if (deleteSelected) {
      onDelete(deleteSelected);
      setIsDeleting(false);
      setDeleteSelected(undefined);
    }
  };

  const handleDeleteCancel = () =>{
    setIsDeleting(false);
    setDeleteSelected(undefined);
  };

  const columns = [
    {
      name: "inventory.productName",
      header: "Product Name",
      flex: 1,
      render: ({ data }: any) => <span>{data?.inventory.productName}</span>,
    },
    {
      name: "inventory.manufacturer",
      header: "Manufacturer",
      flex: 1,
      render: ({ data }: any) => <span>{data?.inventory.manufacturer}</span>,
      visible: !isMobile,
    },
    {
      name: "inventory.fldPartNumber",
      header: "Part Number",
      flex: 1,
      render: ({ data }: any) => <span>{data?.inventory.fldPartNumber}</span>,
      visible: !isMobile,
    },
    {
      name: "amount",
      header: "Amount",
      headerAlign: 'start' as any,
      textAlign: "end" as any,
      flex: 0.7,
      visible: !isMobile,
    },
    {
      id: "actions",
      header: 'Actions',
      width: 100,
      render: ({ data }: any) =>{
      return (
        <div className = "flex justify-center">
          <IconButton
            onClick={() => onEditClick(data)}
            size="small"
            aria-label="Edit item"
            disabled={readOnly}
          >
            <Edit fontSize="inherit" />
          </IconButton>
          <IconButton
            onClick={() => onDeleteClick(data)}
            size="small"
            color="error"
            aria-label="Delete item"
            disabled={readOnly}
          >
            <DeleteTwoTone fontSize="inherit" />
          </IconButton>
          {showLocationIcon && <IconButton
            onClick={() => onLocationClick(data)}
            size="small"
            color="primary"
            aria-label="Location"
          >
            <LocationOnTwoToneIcon fontSize="inherit" />
          </IconButton>}
        </div>
      )
      },
    },
  ];

  return (
    <>
      <div data-testid="data-grid" className="flex flex-col flex-grow spare-grid">
          <LicensedReactDataGrid
            onLoadingChange={onLoadingChange}
            defaultLimit={100}
            livePagination
            onReady={onReady}
            rowHeight={40}
            loading={gridLoading}
            idProperty="pkey"
            showHoverRows={false}
            columns={columns}
            dataSource={spares}
          />
        </div>
        <WarningDialog
          visible={isDeleting}
          title="Delete Warning"
          content="Are you sure you wish to delete record?"
          okText='Yes'
          color='error'
          onOk={handleDeleteOk}
          onCancel={handleDeleteCancel}
        />
      </>
  )
};

export default SpareGrid;
