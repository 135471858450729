import React from 'react';
import pdf from '@react-pdf/renderer';
const { Document, Page, View, StyleSheet, Font, Text } = pdf;

// Import Components for different report sections
import OrderItemRow from './orderItemRow';

// Register the font files
Font.register({
    family: 'Roboto',
    fonts: [
      { src: `./fonts/Roboto-Regular.ttf` },
      { src: `./fonts/Roboto-Bold.ttf`, fontWeight: 'bold' },
      { src: `./fonts/Roboto-Italic.ttf`, fontStyle: 'italic' },
      { src: `./fonts/Roboto-BoldItalic.ttf`, fontWeight: 'bold', fontStyle: 'italic' },
    ],
  });

// Styles for the report
const contentWidth = '100%';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 25,
    paddingBottom: 50,
  },
  title: {
    marginTop: 0,
    fontSize: 12,
    fontFamily: 'Roboto',
    fontWeight: 'bold', // Corrected fontWeight value
    fontStyle: 'italic', // Corrected fontStyle value
  },
  hdrTable1: {
    marginTop: 10,
    fontFamily: 'Roboto',
    fontSize: 9.75,
    width: 75,
    paddingLeft: 0, // Add this line to remove left padding
  },
  tableRow: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginBottom: 0,
    padding: 0,
  },
  shipToWrapper: { 
    flex: 1, 
    flexDirection: 'row-reverse', 
    alignItems: 'flex-start' 
  },
  shipToContainer: {
    marginLeft: 'auto',
    fontFamily: 'Roboto',
    fontSize: 9.75,
  },
  shipToContent: {
    flexDirection: 'row',
    width: 300,
    height: 100,
    backgroundColor: '#DFDFDF',
  },
  hdrTable1Col1: {
    width: 75,
    paddingLeft: 0,
  },
  hdrTable1Col2: {
    width: 250,
    paddingLeft: 0,
  },
  hdrTableCol3: {
    width: '11%',
    paddingLeft: 0,
  },
  hdrTableCol4: {
    width: '30%',
    paddingLeft: 0,
  },
  hdrTableCol5: {
    width: '4%',
    paddingLeft: 0,
  },
  htdTableCol6: {
    width: '10%',
    paddingLeft: 0,
  },

  hdrTable4: {
    marginTop: 0,
    fontFamily: 'Roboto',
    fontSize: 9.75,
    width: contentWidth,
    paddingLeft: 0, // Add this line to remove left padding
    borderBottom: '1pt solid black',
  },
  hdrTable2Col1: {
    width: '10%',
    paddingLeft: 0,
  },
  hdrTable2Col2: {
    width: '10%',
    paddingLeft: 0,
  },
  hdrTable2Col3: {
    width: '10%',
    paddingLeft: 0,
  },
  hdrTable2Col4: {
    width: '10%',
    paddingLeft: 0,
  },
  hdrTable2Col5: {
    width: '11%',
    paddingLeft: 0,
  },
  hdrTable2Col6: {
    width: '10%',
    paddingLeft: 0,
  },
  hdrTable2Col7: {
    width: '10%',
    paddingLeft: 0,
  },
  hdrTable2Col8: {
    width: '15%',
    paddingLeft: 0,
  },

  listHdr1: {
    marginTop: 3,
    fontFamily: 'Roboto',
    fontSize: 9.75,
    width: '100%',
    paddingLeft: 0, // Add this line to remove left padding
  },
  lstHdrLabel: {
    width: '100%',
    paddingLeft: 0,
  },
  lstHdrData: {
    width: '80%',
    paddingLeft: 0,
    fontWeight: 'bold',
  },

  pageNumber: {
    width: contentWidth,
    position: 'absolute',
    fontSize: 8,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'right',
  },
});


// Component to generate the report
const PurchaseReport = ( data: any) => {
  return (
    <Document>
      <Page
        orientation='landscape'
        size={data.PO.pageSize || 'LETTER'}
        style={styles.page}
      >
        <Text style={styles.title}>
          Purchase Order Request for {data.VesselSpecs.fldVesselName}
        </Text>
        <View style={{ flexDirection: 'row' }}>
          <View style={styles.hdrTable1}>
            <View style={styles.tableRow}>
              <Text style={styles.hdrTable1Col1}>TO (Supplier):</Text>
              <Text style={styles.hdrTable1Col1}>Phone: </Text>
              <Text style={styles.hdrTable1Col1}>Email:</Text>
              <Text style={styles.hdrTable1Col1}>Attention:</Text>
              <Text style={styles.hdrTable1Col1}>Ordered By:</Text>
              <Text style={styles.hdrTable1Col1}>Order Name:</Text>
              <Text style={styles.hdrTable1Col1}>Budget Code:</Text>
            </View>
          </View>
          <View style={styles.hdrTable1}>
            <View style={styles.tableRow}>
              <Text style={styles.hdrTable1Col2}>{data.PO.Supplier || " "}</Text>
              <Text style={styles.hdrTable1Col2}>{data.PO.ContactPhone || " "}</Text>
              <Text style={styles.hdrTable1Col2}>{data.PO.ContactEmail || " "}</Text>
              <Text style={styles.hdrTable1Col2}>{data.PO.Contact || " "}</Text>
              <Text style={styles.hdrTable1Col2}>{data.PO.OrderedBy || " "}</Text>
              <Text style={styles.hdrTable1Col2}>{data.PO.OrderName || " "}</Text>
              <Text style={styles.hdrTable1Col2}>{data.PO.BudgetCode || " "}</Text>
            </View>
          </View>
          <View style={styles.shipToWrapper}>
            <View style={styles.shipToContainer}>
              <View style={styles.shipToContent}>
                <Text> SHIP TO: </Text>
                <Text>{data.VesselSpecs.fldShippingAddress}</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={{ ...styles.hdrTable4, marginTop: 5 }} />
        <OrderItemRow POItems={data.POItems} />
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `page ${pageNumber} of ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default PurchaseReport;