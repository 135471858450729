import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { isEmpty, isNil, isString, size } from 'lodash';
import { IconButton, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Alert, Portal, Snackbar, Autocomplete as MaterialAutocomplete, FormGroup, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from 'src/components/UI/Forms/Checkbox';
import { getDatabase } from 'src/rxdb';
import { ATTACHMENTS, CHAR_LIMIT } from 'src/consts';
import Autocomplete from 'src/components/UI/Forms/Autocomplete';
import Input from 'src/components/UI/Forms/Input';
import FileUploadFormOld from './FileUploadFormOld';
import DatePicker from 'src/components/UI/Forms/DatePicker';
import UploadValidationPopup from './UploadValidationPopup';
import { TblDocumentationDocument } from 'src/pages/LibrariesPage/rxdb';
import { uuid } from 'uuidv4';
import { escapeStringRegexp, handleCharLimitWarning } from 'src/utils';
import { isValidDateFormat } from 'src/utils/format-dates';
import { IAttachmentState } from '../indexOld';

interface InjectedProps {
  initialValue?: any;
  attachmentValue?: IAttachmentState;
  recordKey?: string | null;
  tableName?: string;
  visible: boolean;
  setUploadedFile: any;
  uploadedFile: any;
  type: string;
  onCancel?: () => void;
  onSubmit: (payload: IAttachmentState) => void;
  saveLoading: boolean;
  maxFiles?: number;
  disableCheckbox?: boolean;
}

type FilePopupForm = {
  PKey: string;
  fldFKey: any;
  DocTitle: any;
  fldRevision: any;
  fldPage: any;
  fldShowInCentral: any;
  fldDescription: any;
  fldNotes: any;
  fldRevisionDate: any;
  fldRecordKey: any;
  fldFileName: any;
  fldLibtype: any;
};

const filedefaultValue = {
  PKey: '',
  fldFKey: '',
  DocTitle: '',
  fldRevision: 0,
  fldPage: 0,
  fldShowInCentral: false,
  fldDescription: '',
  fldNotes: '',
  fldRevisionDate: new Date(),
  fldRecordKey: '',
  fldFileName: '',
  fldLibtype: '',
};

const libTypeOptions = [
  {
    id: 0,
    type: 'DRAWINGS',
    label: 'Drawings',
  },
  /* TD-1086 - remove the option to add an attachment to Photos, ISPS or Safety Management System libraries
  {
    id: 1,
    type: 'ISPSDOCS',
    label: 'ISPS',
  },
  {
    id: 2,
    type: 'PHOTOS',
    label: 'Photos',
  },
  {
    id: 3,
    type: 'SMSDOCS',
    label: 'Safety Management System',
  },
  */
  {
    id: 2,
    type: null,
    label: 'Vessel Reference',
  },
];

const AttachmentPopupOld: FC<InjectedProps> = ({
  initialValue,
  attachmentValue,
  recordKey,
  tableName,
  visible,
  setUploadedFile,
  uploadedFile,
  type,
  onCancel,
  onSubmit,
  saveLoading,
  maxFiles = 0,
  disableCheckbox= false,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    register,
    setValue,
  } = useForm<any>({
    mode: 'onBlur',
    shouldFocusError: true,
  });
  const { ref, ...inputProps } = register('DocTitle', {
    required: 'Field required',
  });
  const [libTypeSelected, setLibTypeSelected] = useState<any>(null);
  const [document, setDocument] = useState<TblDocumentationDocument>();
  const [showLibTypeDropdown, setShowLibTypeDropdown] = useState<boolean>(false);
  const [isCheckFormVisible, setIsCheckFormVisible] = useState<boolean>(false);
  const [alert, setAlert] = useState(false);
  const { TBLDOCUMENTATION, TBLDOCUMENTREVISIONS } = CHAR_LIMIT;
  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const setInitialValue = async () => {
    // Dont do anything special
    if (isNil(attachmentValue)) return reset(filedefaultValue);

    if (attachmentValue.fldShowInCentral) {
      setShowLibTypeDropdown(true);
    }
    if (attachmentValue.fldLibtype === 'SMSDOCS') {
      setLibTypeSelected('Safety Management System');
    }
    if (attachmentValue.fldLibtype === 'PHOTOS') {
      setLibTypeSelected('Photos');
    }
    if (attachmentValue.fldLibtype === 'DRAWINGS') {
      setLibTypeSelected('Drawings');
    }
    if (attachmentValue.fldLibtype === null) {
      setLibTypeSelected('Vessel Reference');
    }

    const payload = {
      PKey: attachmentValue.PKey,
      fldFKey: attachmentValue.fldFKey,
      DocTitle: attachmentValue.DocTitle,
      fldRevision: attachmentValue.fldRevision,
      fldPage: attachmentValue.fldPage,
      fldShowInCentral: attachmentValue.fldShowInCentral,
      fldDescription: attachmentValue.fldDescription,
      fldNotes: attachmentValue.fldNotes,
      fldRevisionDate: attachmentValue.fldRevisionDate ? new Date(attachmentValue.fldRevisionDate) : null,
      fldRecordKey: attachmentValue.fldRecordKey,
      fldFileName: attachmentValue.fldFileName,
      fldLibtype: attachmentValue.fldLibtype,
    };

    updateFormData(payload);
  };

  useEffect(() => {
    setInitialValue();
  }, [attachmentValue]);

  const updateFormData = (payload: any) => {
    if (!isNil(payload)) {
      reset(payload);
    }
  };

  const onChange = async (name: string, value: any) => {
    let shouldDirty = true;

    setValue(name, value, { shouldDirty: shouldDirty });
  };

  const updateFormAfterFileUpload = async () => {
    setValue(
      'DocTitle',
      isNil(attachmentValue?.DocTitle) 
        ? isEmpty(getValues().DocTitle) 
          ? uploadedFile.name 
          : getValues().DocTitle 
        : attachmentValue?.DocTitle
      );
    };

  useEffect(() => {
    const fetchData = async () => {
      if (!isEmpty(uploadedFile.name)) {
        updateFormAfterFileUpload();
        await onTitleCheck();
      }
    };
  
    fetchData();
  }, [uploadedFile]);

  const handleChangeLibDropdown = (evt: any) => {
    setShowLibTypeDropdown(evt.target.checked);
  };

  const onSaveClicked = async (data: FilePopupForm) => {
    if (!uploadedFile.url && attachmentValue && !attachmentValue.fldFileName) {
      setAlert(true);
      return;
    }
    //Note: Add below code for check file is uploaede or not for add new
    if (isNil(attachmentValue)){
      if(uploadedFile.url === ''){
        setAlert(true);
        return;
      }
    }
    const libType =
      type === 'photo'
        ? 'PHOTOS'
        : isString(data.fldLibtype)
        ? data.fldLibtype
        : !isNil(data.fldLibtype) ? data.fldLibtype.type : null;
    const payload: any = {
      PKey: data.PKey,
      fldFKey: data.fldFKey,
      fldRecordKey: data.fldRecordKey,
      fldFileName: data.fldFileName,
      DocTitle: data.DocTitle,
      fldRevision: data.fldRevision || null,
      fldPage: data.fldPage || null,
      fldShowInCentral: data.fldShowInCentral,
      fldDescription: data.fldDescription,
      fldNotes: data.fldNotes,
      fldRevisionDate: data.fldRevisionDate,
      fldLibtype: libType,
    };
    onSubmit(payload);
    setLibTypeSelected(undefined);
    setShowLibTypeDropdown(false);
    setAlert(false)
    reset(filedefaultValue);
  };

  const handleChange = (newData: any) => {
    setLibTypeSelected(newData);
    setValue('fldLibtype', newData);
  };

  const onCancelClick = () => {
    reset(filedefaultValue);
    setLibTypeSelected(undefined);
    setShowLibTypeDropdown(false);
    setAlert(false);
    onCancel && onCancel();
  };

  const handleOnCancel = () => {
    setIsCheckFormVisible(false)
  }

  const handleOnUpload = async () => {
    const titleCheck = await onTitleCheck();
    setValue(
      'DocTitle',
      titleCheck
    );
    setIsCheckFormVisible(false);
  };

  const handleOnLink = async () => {
    if(document) {
      const db = await getDatabase();
      const documentCrossResponse = await db.tbldocumentcrossreference.upsert({
          PKey: uuid(),
          fldFKey: document.PKey,
          fldRecordKey: recordKey,
          fldTableName: tableName,
      });

      let schedMaint: any = await db.collections.tblschedmaint.findOne({
        selector: {
          PKey: initialValue.SchedKey,
        }
      }).exec();
      let fldHTML: string = "OPEN ATTACHED PROCEDURE FOR INSTRUCTIONS. " + (schedMaint.fldHTML ?? "");
      await schedMaint.atomicPatch({
          fldLinkID: documentCrossResponse.fldFKey,
          fldHTML: fldHTML,
      });
      initialValue.fldLinkID = documentCrossResponse.fldFKey;
      initialValue.fldHTML = fldHTML;
    }
    handleOnCancel();
    onCancelClick();
  }

  const onTitleCheck = async () => {
    const DocTitles = await getValues().DocTitle;
  
    if (isEmpty(DocTitles) || DocTitles === attachmentValue?.DocTitle) {
      return '';
    }
  
    const db = await getDatabase();
    const escapedDocTitles = escapeStringRegexp(DocTitles);
  
    const items = await db.tbldocumentation.find({
      selector: {
        $or: [
            { DocTitle: { $eq: DocTitles } },
            { DocTitle: { $regex: new RegExp(`${escapedDocTitles} - copy`) } },
        ],
      },
    }).exec();

    items.sort((a, b) => (a.DocTitle < b.DocTitle ? -1 : a.DocTitle > b.DocTitle ? 1 : 0));

    let result = '';
  
    if (size(items) > 0) {
      const initialDocument = items.find(i => i.DocTitle === DocTitles);
      const numDuplicates = items.length - 1;
  
      result = `${initialDocument?.DocTitle} - copy${numDuplicates > 0 ? ` (${numDuplicates})` : ''}`;
  
      setIsCheckFormVisible(true);
      setDocument(initialDocument);
    }
  
    return result;
  };

  const showDropdown = type === 'attachments' && showLibTypeDropdown;

  return (
    <div>
      <form>
        <Dialog
          open={visible}
          fullWidth
          maxWidth="md"
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle sx={{ m: 0, p: 4 }} style={{ fontSize: '16px' }}>
            <span className="ml-2">
              {type === 'photo'
                ? ATTACHMENTS.ATTACH_PHOTOS
                : ATTACHMENTS.ATTACH_FILES}
            </span>
            {onCancel ? (
              <IconButton
                aria-label="close"
                onClick={onCancelClick}
                sx={{
                  position: 'absolute',
                  right: 10,
                  top: 14,
                  color: (theme) => theme.palette.grey[400],
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>
          <DialogContent dividers style={{ maxHeight: '60vh' }}>
            {alert && (
              <Alert severity="error" className="mb-5">
                File is empty
              </Alert>
            )}
            <form
              id="Attachment-popup"
              className="relative bg-white flex-grow"
              onSubmit={handleSubmit(onSaveClicked)}
            >
              <div className="bg-white h-full flex-grow">
                <div className="h-full">
                  <div className="mb-6">
                    <div className="mb-3">
                      <Input
                        inputProps={{
                          size: 'small',
                          label: 'Library Title',
                        }}
                        rules={{
                          required: 'Library Title required',
                          maxLength: TBLDOCUMENTATION.DocTitle,
                          validate: async () => {
                            const str = await onTitleCheck();
                            if (!isEmpty(str)) {
                              return str;
                            }
                          }
                        }}
                        warning={(value) => handleCharLimitWarning(value, TBLDOCUMENTATION.DocTitle)}
                        control={control}
                        name="DocTitle"
                      />
                    </div>
                    <div>
                    <FormGroup row>
                      <div className="mr-5 mb-3">
                        <Input
                          inputProps={{
                            size: 'small',
                            label: 'Revision #',
                            style: { textAlign: 'end' },
                          }}
                          rules={{ maxLength: TBLDOCUMENTREVISIONS.fldRevision }}
                          warning={(value) => handleCharLimitWarning(value, TBLDOCUMENTREVISIONS.fldRevision)}
                          control={control}
                          name="fldRevision"
                        />
                      </div>
                      <div className="mr-5 mb-3">
                        <Input
                          inputProps={{
                            size: 'small',
                            label: 'Page #',
                            style: { textAlign: 'end' },
                          }}
                          rules={{ maxLength: TBLDOCUMENTATION.fldPage }}
                          warning={(value) => handleCharLimitWarning(value, TBLDOCUMENTATION.fldPage)}
                          control={control}
                          name="fldPage"
                        />
                      </div>
                      <div className="mb-3">
                        <DatePicker
                          name="fldRevisionDate"
                          control={control}
                          label="Revision Date"
                          rules={{
                            validate: (value) => isValidDateFormat(value) || 'Please enter a valid date format dd-MMM-yyyy',
                          }}
                        />
                      </div>
                    </FormGroup>

                    </div>
                    {!disableCheckbox && <div>
                      <Checkbox
                        control={control}
                        name="fldShowInCentral"
                        label="Include in Documentation Libraries"
                        onChange={handleChangeLibDropdown}
                      />
                    </div>}
                    {showDropdown && (
                      <div className="mt-4">
                        <MaterialAutocomplete
                          disablePortal
                          id="File-type"
                          value={libTypeSelected}
                          options={libTypeOptions}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            return option.label;
                          }}
                          // renderOption={(props, option: any) => <li {...props}>{option}</li>}
                          onChange={(event, newValue: any) => {
                            handleChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Library Type"
                              autoComplete="off"
                              size="small"
                            />
                          )}
                        />
                      </div>
                    )}
                    <div className="mt-3">
                      <Input
                        inputProps={{
                          size: 'small',
                          label: 'Description',
                        }}
                        rows={2}
                        control={control}
                        name="fldDescription"
                        multiline={true}
                      />
                    </div>
                    <div className="mt-3">
                      <Input
                        inputProps={{
                          size: 'small',
                          label: 'Revision Notes',
                        }}
                        control={control}
                        name="fldNotes"
                        rows={2}
                        multiline={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <UploadValidationPopup 
                visible={isCheckFormVisible} 
                onCancel={handleOnCancel} 
                onLink={handleOnLink}      
                onUpload={handleOnUpload}      
              />

            </form>
            <FileUploadFormOld
              setUploadedFile={setUploadedFile}
              uploadedFile={uploadedFile}
              type={type}
              attachmentValue={attachmentValue}
              maxFiles={maxFiles}
            />
          </DialogContent>
          <DialogActions sx={{ m: 0, p: 3 }}>
            <LoadingButton
              onClick={handleSubmit(onSaveClicked)}
              loading={saveLoading}
              className="ml-4 mr-3 w-32"
              variant="contained"
            >
              Save
            </LoadingButton>
            <Button onClick={onCancelClick} className="w-32">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Portal>
          <Snackbar
            open={snackBar.open}
            autoHideDuration={2000}
            onClose={onSnackbarClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
              {snackBar.message}
            </Alert>
          </Snackbar>
        </Portal>
      </form>
    </div>
  );
};

export default AttachmentPopupOld;
