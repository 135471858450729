import { RxCollection, RxJsonSchema, RxDocument } from 'rxdb';
import { TblLogCustom } from 'src/generated/graphql';

export type EngineerDayLogCustomDocType = TblLogCustom;

export type EngineerDayLogCustomDocMethods = {};

export type EngineerDayLogCustomDocument = RxDocument<
  EngineerDayLogCustomDocType,
  EngineerDayLogCustomDocMethods
  >;

export type EngineerDayLogCustomCollectionMethods = {};

export type EngineerDayLogCustomCollection = RxCollection<
  EngineerDayLogCustomDocType,
  EngineerDayLogCustomDocMethods,
  EngineerDayLogCustomCollectionMethods
  >;

export const schema: RxJsonSchema<EngineerDayLogCustomDocType> = {
  title: 'EngineerDay ',
  description: 'Engineer Day Log',
  version: 4,
  keyCompression: false,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    PKey: {
      type: 'string',
      primary: true,
    },
    fldReportType: {
      type: ['string', 'null'],
    },
    fldLogDate: {
      type: 'string',
    },
    fldLogTime: {
      type: ['string', 'null'],
    },
    fldTimeZone: {
      type: ['string', 'null'],
    },
    fldMode: {
      type: ['string', 'null'],
    },
    fldApproxLocation: {
      type: ['string', 'null'],
    },
    fldRemark: {
      type: ['string', 'null'],
    },
    fldReportFile: {
      type: ['string', 'null'],
    },
    fldDateCreated: {
      type: ['string', 'null'],
    },
    fldCreatedBy: {
      type: ['string', 'null'],
    },
    fldCreatedByName: {
      type: ['string', 'null'],
    },
    fldParent: {
      type: ['string', 'null'],
    },
    fldSend: {
      type: ['boolean', 'null'],
    },
    fldNoView: {
      type: ['boolean', 'null'],
    },
 
    deletedAt: {
      type: ['string', 'null'],
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
    updatedAt: {
      type: 'string',
    },
    isLocked: {
      type: ['boolean', 'null'],
    },
  },
  indexes: [
    'PKey', 'updatedAt', 'fldLogDate',
  ],
  additionalProperties: false,
};

export default schema;
