import React, { ChangeEvent, FC, useState } from 'react';
import { isNull } from 'lodash';
import { TextField, TextFieldProps, TextFieldPropsSizeOverrides, } from '@mui/material';
import { Control, RegisterOptions, useController } from 'react-hook-form';
import { OverridableStringUnion } from '@mui/types';

type InjectedProps = {
  control: Control;
  name: string;
  defaultValue?: any;
  rules?: RegisterOptions;
  inputProps?: TextFieldProps;
  size?: OverridableStringUnion<
    'small' | 'medium',
    TextFieldPropsSizeOverrides
  >;
  rows?: any;
  multiline?: any;
  disabled?: boolean;
  warning?: (value: string) => string | undefined;
};

const Input: FC<InjectedProps> = ({
  control,
  name,
  defaultValue,
  rules,
  inputProps: textFieldProps,
  size = 'small',
  rows = 1,
  multiline= false,
  disabled = false,
  warning,
}) => {
  const {
    field: { ref, value, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
    rules,
  });
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    // Validate the input value and get the error message
    const newErrorMessage = warning ? warning(inputValue) : undefined;
    setErrorMessage(newErrorMessage);
    // Call the original onChange function from useController
    inputProps.onChange(inputValue);
    // Trigger onBlur to re-validate and display the error
    inputProps.onBlur();
  };

  return (
    <TextField
      {...textFieldProps}
      {...inputProps}
      value={isNull(value) ? '' : value}
      inputRef={ref}
      fullWidth
      error={!!errorMessage || !!error}
      helperText={errorMessage || error?.message}
      autoComplete='off'
      rows={rows}
      multiline={multiline}
      size={size}
      disabled={disabled}
      onChange={handleChange}
    />
  );
};

export default Input;
