import { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';
import { TblRlEnums } from '../../../generated/graphql';

export type TblRlEnumsDocType = TblRlEnums;

export type TblRlEnumsDocMethods = {};

export type TblRlEnumsDocument = RxDocument<
  TblRlEnumsDocType,
  TblRlEnumsDocMethods
>;

export type TblRlEnumsCollectionMethods = {};

export type TblRlEnumsCollection = RxCollection<
  TblRlEnumsDocType,
  TblRlEnumsDocMethods,
  TblRlEnumsCollectionMethods
>;

//   DateEntered

export const schema: RxJsonSchema<TblRlEnumsDocType> = {
  keyCompression: false,
  title: 'TblRlEnums ',
  description: 'TblRlEnums',
  version: 1,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    PKey: {
      type: 'string',
      primary: true,
    },
    fldEnumName: {
      type: ['string', 'null'],
    },
    fldDataTypeName: {
      type: ['string', 'null'],
    },
    fldDataTypeNumber: {
      type: ['number', 'null'],
    },
    Summable: {
      type: ['boolean', 'null'],
    },
    fldNumberOfDecimals: {
      type: ['number', 'null'],
    },
    ValueList: {
      type: ['string', 'null'],
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
    updatedAt: {
      type: 'string',
    },
  },
  indexes: ['PKey'],
  required: [],
  additionalProperties: false,
};

export default schema;
