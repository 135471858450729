import { RxCollection, RxJsonSchema, RxDocument } from 'rxdb';
import { Inventory } from '../../generated/graphql';

export type InventoryDocType = Inventory;

export type InventoryDocMethods = {};

export type InventoryDocument = RxDocument<
  InventoryDocType,
  InventoryDocMethods
>;

export type InventoryCollectionMethods = {};

export type InventoryCollection = RxCollection<
  InventoryDocType,
  InventoryDocMethods,
  InventoryCollectionMethods
>;

export const inventorySchema: RxJsonSchema<InventoryDocType> = {
  title: 'inventory ',
  description: 'describes an inventory item',
  version: 2,
  keyCompression: false,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    ProductID: {
      type: 'string',
      primary: true,
      ref: 'invbarcodes',
    },
    ProductName: {
      type: 'string',
    },
    ProductDescription: {
      type: ['string', 'null'],
    },
    UnitsOnOrder: {
      type: ['number', 'null'],
    },
    ReOrderLevel: {
      type: ['number', 'null'],
    },
    ModelNumber: {
      type: ['string', 'null'],
    },
    Department: {
      type: ['string', 'null'],
    },
    ReOrder: {
      type: ['boolean', 'null'],
    },
    Manufacturer: {
      type: ['string', 'null'],
    },
    Qty: {
      type: ['string', 'null'],
    },
    SearchMod: {
      type: ['string', 'null'],
    },
    SearchMaker: {
      type: ['string', 'null'],
    },
    SearchPart: {
      type: ['string', 'null'],
    },
    Weight: {
      type: ['number', 'null'],
    },
    PowerRequired: {
      type: ['string', 'null'],
    },
    WeightQty: {
      type: ['string', 'null'],
    },
    Bonded: {
      type: ['boolean', 'null'],
    },
    NoView: {
      type: ['boolean', 'null'],
    },
    fldConsumable: {
      type: ['boolean', 'null'],
    },
    fldUseQty: {
      type: ['string', 'null'],
    },
    fldFactor: {
      type: ['number', 'null'],
    },
    fldOrderQty: {
      type: ['string', 'null'],
    },
    fldOrderAmt: {
      type: ['number', 'null'],
    },
    fldDataType: {
      type: ['string', 'null'],
    },
    fldRegion: {
      type: ['string', 'null'],
    },
    fldCountry: {
      type: ['string', 'null'],
    },
    fldSize: {
      type: ['string', 'null'],
    },
    fldColor: {
      type: ['string', 'null'],
    },
    fldLLBC: {
      type: ['string', 'null'],
    },
    fldPartNumber: {
      type: ['string', 'null'],
    },
    fldGuestType: {
      type: ['string', 'null'],
    },
    fldSMS: {
      type: ['boolean', 'null'],
    },
    fldRx: {
      type: ['boolean', 'null'],
    },
    fldSRHKey: {
      type: ['string', 'null'],
    },
    ReOrderAmt: {
      type: ['number', 'null'],
    },
    DateEntered: {
      type: ['string', 'null'],
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    updatedAt: {
      type: 'string',
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
  },
  indexes: ['ProductName', 'updatedAt'],
  required: [],
  additionalProperties: false,
};

export const inventoryDocMethods: InventoryDocMethods = {};

export const inventoryCollectionMethods: InventoryCollectionMethods = {};
