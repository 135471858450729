// @ts-nocheck
import { useCallback, useState, MutableRefObject, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { isNil, pick, get, size, isEmpty } from 'lodash';
import moment from 'moment';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { TypeComputedProps, TypeSortInfo } from '@inovua/reactdatagrid-enterprise/types';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import '../styles.css';
import { MangoQuerySortDirection } from 'rxdb/dist/types/types/rx-query';
import {exportCSV, exportExcel, getRegexByOperator} from 'src/utils';

// Data
import { CertificateDocument } from 'src/rxdb/collections/Certificates/rxdb';
import { getDatabase } from 'src/rxdb';
import { useAppState } from 'src/contexts/app-state';
import GridRowAttachmentIcon from 'src/components/UI/LicensedReactDataGrid/components/GridRowAttachmentIcon';
import LicensedReactDataGrid from 'src/components/UI/LicensedReactDataGrid';
import DeleteRecordBtnOld from 'src/components/DeleteRecordBtn/indexOld';

type LibraryCertificatesGridProps = {
  darken: boolean;
  onSelect: (item: CertificateDocument) => void;
  sourceRoot?: boolean;
};

const filter = [
  {
    name: 'fldType',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'fldCertNum',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'fldResponsible',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'DateExpire',
    operator: 'inrange',
    type: 'date',
    value: '',
  },
  {
    name: 'DateIssue',
    operator: 'inrange',
    type: 'date',
    value: '',
  },
];

const transformData = async (item: CertificateDocument) => ({
  ...pick(item, [
    'PKey',
    'fldType',
    'DateExpire',
    'DateIssue',
    'fldCertNum',
    'fldResponsible',
  ]),
  month: moment(item.DateExpire).format('MMMM'),
  year: moment(item.DateExpire).format('YYYY'),
  original: item,
});

const getSelectorByFilterName = async (
  name: string,
  value: string,
  operator: string,
) => {
  switch (name) {
    case 'fldType':
      return {
        fldType: { $regex: getRegexByOperator(operator, value) },
      };

    case 'fldCertNum':
      return {
        fldCertNum: { $regex: getRegexByOperator(operator, value) },
      };
    case 'fldResponsible': {
      return {
        fldResponsible: { $regex: getRegexByOperator(operator, value) },
      };
    }
    case 'DateExpire': {
      if (!isEmpty(value.start) && (isNil(value.end) || isEmpty(value.end))) {
        const dateformate = moment(value?.start)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        return {
          DateExpire: { $gte: dateformate },
        };
      }
      if (!isEmpty(value.end) && (isNil(value.start) || isEmpty(value.start))) {
        const dateformate = moment(value?.end)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        return {
          DateExpire: { $lte: dateformate },
        };
      }
      if (!isEmpty(value.start) && !isEmpty(value.end)) {
        const startdateformate = moment(value?.start)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        const enddateformate = moment(value?.end)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        return {
          DateExpire: { 
            $gte: startdateformate,
            $lte: enddateformate
          },
        };
      }
      return {};
    }
    case 'DateIssue': {
      if (!isEmpty(value.start) && (isNil(value.end) || isEmpty(value.end))) {
        const dateformate = moment(value?.start)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        return {
          DateIssue: { $gte: dateformate },
        };
      }
      if (!isEmpty(value.end) && (isNil(value.start) || isEmpty(value.start))) {
        const dateformate = moment(value?.end)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        return {
          DateIssue: { $lte: dateformate },
        };
      }
      if (!isEmpty(value.start) && !isEmpty(value.end)) {
        const startdateformate = moment(value?.start)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        const enddateformate = moment(value?.end)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        return {
          DateIssue: { 
            $gte: startdateformate,
            $lte: enddateformate
          },
        };
      }
      return {};
    }
    default:
      return {};
  }
};

let currentCount = 0;

const loadData = async ({
  skip,
  limit,
  filterValue,
  sortInfo,
}: {
  sortInfo: TypeSortInfo;
  skip: number;
  limit: number;
  filterValue: any;
}): Promise<{ data: any[]; count: number }> => {
  const db = await getDatabase();
  let preSort = {
    fldType: 'asc' as MangoQuerySortDirection,
  }

  if (!isNil(sortInfo)) {
    if (sortInfo.name === 'fldType') {
      preSort = {
        [sortInfo.name]:
          sortInfo.dir === 1
            ? ('asc' as MangoQuerySortDirection)
            : ('desc' as MangoQuerySortDirection),
      };
    }
  }
  
  const sort = [
    {
      ...preSort,
    },
  ];

  // TODO: Handle filtervalue.OPERATOR

  let selector = {
    fldArchive: {
      $eq: false,
    },
    deletedBy: { // TD-1891 filter out deleted records
      $eq: null,
    },
    fldCrewID: {
      $eq: null,
    }
  };

  await Promise.all(
    filterValue.map(async (v) => {
      if (isEmpty(v.value)) return v;

      const s = await getSelectorByFilterName(
        v.name,
        v.value,
        v.operator,
      );

      selector = {
        ...selector,
        ...s,
      };
      return v;
    })
  );

  const rawData = await db.certificates.find({ selector }).exec()
  const length = size(rawData);
  currentCount = length;

  const items = await db.certificates
    .find({
      selector,
      skip,
      limit,
      sort,
    })
    .exec();

  const data = await Promise.all(items.map(transformData));
  return { data, count: length };
};

interface LibraryCertificatesGridRef {
  handleExport: (type: string) => void;
}

const LibraryCertificatesGrid = forwardRef<LibraryCertificatesGridRef, LibraryCertificatesGridProps>(({ onSelect, sourceRoot = false }: LibraryCertificatesGridProps, ref) => {
    const { settingsPersonal } = useAppState();
    const [loading, setLoading] = useState<boolean>(false);
    const highlightedRowId = useRef<string>('-1');
    const [gridRef, setGridRef] = useState<any>(null);
    const [exportDialog, setExportDialog] = useState(null);
    const [collapsedGroups, setCollapsedGroups] = useState({});
    const [groupCollapsedStatus, setGroupCollapsedStatus] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [rowSelected, setRowSelected] = useState(null);
    const [groupByFlag, setGroupByFlag] = useState(false);

    const isActionable =
      Object.values(rowSelected || {}).filter((item: any) => item).length > 0;
      
      useImperativeHandle(ref, () => ({
        handleExport: (type) => {
          handleDataExport(type);
        },
      }));
      
      const handleSelectionChange = useCallback(({ selected }) => {
        setRowSelected(selected);
      }, []);

    const handleGetCount = async () => {
      const db = await getDatabase();
      let selector = {
        fldArchive: {
          $eq: false,
        },
        deletedBy: { // TD-1891 filter out deleted records
          $eq: null,
        },
        fldCrewID: {
          $eq: null,
        }
      };
      const data = await db.certificates.find({ selector }).exec();
      setTotalCount(size(data));
    };

    useEffect(() => {
      handleGetCount();
    }, [loading]);

    // Find if any filters applied to grid
    const areFiltersActive = gridRef?.current.computedFilterValue.some(
      (f) => !isEmpty(f.value)
    );
    const currentFilters = get(gridRef, ['current', 'computedFilterValue'], []);

    const init = async (ref: MutableRefObject<TypeComputedProps | null>) => {
      const db = await getDatabase();
      if(ref?.current?.computedGroupBy.includes('month')||ref?.current?.computedGroupBy.includes('year') ){
        setGroupByFlag(true)
      }
      // Always reload grid on new item / update / delete.
      db.certificates.$.subscribe(async (ev) => {
        if (ev.operation === 'INSERT' || ev.operation === 'UPDATE') {
          highlightedRowId.current = ev.documentId;
          ref.current?.reload();
        }
        if (ev.operation === 'DELETE') {
          highlightedRowId.current = '-1';
          ref.current?.reload();
        }
      });
    };

    const onReady = (ref: MutableRefObject<TypeComputedProps | null>) => {
      setGridRef(ref);
      init(ref);
    };

    const onLoadingChange = (status: boolean) => {
      // If loading completed - check if there any tasks that needs to be highlighted.
      if (!status && highlightedRowId.current !== '-1') {
        gridRef?.current?.scrollToId(highlightedRowId.current);
        gridRef?.current?.setSelectedById(highlightedRowId.current, true);
        highlightedRowId.current = '-1';
      }
      setLoading(status);
    };

    const dataSource = useCallback(loadData, []);

    const onRowClick = useCallback(
      ({ data }) => {
        if (Object.keys(rowSelected || {}).length < 2) {
          onSelect(data.original);
        }
      },
      [rowSelected]
    );

    const exportData = (type = 'CSV' | 'xlsx', withFilters = true) => {
      setExportDialog(null);

      switch (type) {
        case 'CSV':
          return onExportToCSV(withFilters);
        case 'xlsx':
          return onExportToExcel(withFilters);
        default:
      }
    };

    const getRows = async (issue: any) => {
      const rows =  issue.map((data: any) =>{
        const dateExpireFormatted = data.DateExpire ? moment(data.DateExpire).format('DD-MMM-YYYY') : '';
        const dateIssueFormatted = data.DateIssue ? moment(data.DateIssue).format('DD-MMM-YYYY') : '';
        return {
          ...pick(data, [
            'fldType', 'fldCertNum', 'IssuedTo','fldResponsible',
          ]),
          DateIssue: dateIssueFormatted,
          DateExpire: dateExpireFormatted,
        };
      }   
      );
      return rows
    };

    const onExportToExcel = async (withFilters: boolean) => {
      const { data } = await loadData({ filterValue: withFilters ? currentFilters : [] });

      const columnsData = gridRef.current.visibleColumns.map((c: any) => ({
        header: c.header,
        key: c.id,
      }));
      const columns = columnsData.filter(item => {
        return item.header && typeof item.header === 'string';
      });
      const rows = await getRows(data)

      return exportExcel(columns, rows);
    };

    const onExportToCSV = async (withFilters: boolean) => {
      const columns = gridRef.current.visibleColumns;
      const { data } = await loadData({ filterValue: withFilters ? currentFilters : [] });
      const rows = await getRows(data)

      return exportCSV(columns, rows);
    };

    const handleDataExport = (type = 'CSV' | 'xlsx') => {
      if (areFiltersActive) {
        setExportDialog({
          visible: true,
          type,
          title: type === 'CSV' ? 'CSV' : 'Excel',
        });

        return;
      }

      exportData(type, false);
    };

    const renderGroupTitle = (value, { data }) => {
      const columns = data.fieldPath.map((col) =>
        col === data.name ? col.toUpperCase() : col
      );
      const path = columns && columns.length && columns.join('>');
      let dataValue = data.value;
      if (path.includes('DateExpire') || path.includes('DateIssue')) {
        dataValue = moment(data.value).format('DD-MMM-YYYY');
      }
      return isNil(data.value) ? `No ${path} Assigned` : dataValue;
    };

    const CollapseButton = () => {
      if (groupCollapsedStatus) {
        setGroupCollapsedStatus(false);
        return gridRef.current.collapseAllGroups();
      }
      if (!groupCollapsedStatus) {
        setGroupCollapsedStatus(true);
        return gridRef.current.expandAllGroups();
      }
    };

    const groupByMonth = async () =>{
      const grouped = gridRef?.current?.computedGroupBy
      if(!groupByFlag){      
      await gridRef?.current.setGroupBy(grouped.concat(['year','month']))
      await gridRef?.current.setSortInfo([{ name: 'year', dir: -1 }, { name: 'month', dir: -1 }])
      await gridRef?.current?.reload()
      setGroupByFlag(true)
      }
      else{
        setGroupByFlag(false)
        const filterGroup = grouped.filter((e)=> e != 'month').filter((n)=> n!='year')
        await gridRef?.current.setGroupBy(filterGroup)
        await gridRef?.current.setSortInfo([{ name: 'fldType', dir: 1 }])
        await gridRef?.current?.reload()
      }
    };

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

    const columns = [
      {
        id: 'icons',
        defaultWidth: 65,
        render: ({ data }: any) => (
            <GridRowAttachmentIcon selector={{ fldRecordKey: data.PKey }} />
        ),
        onRender: (cellProps: any, { data }: { data: CertificateDocument }) => {
          if (
            !isEmpty(data?.DateExpire) &&
            new Date().toISOString() > data?.DateExpire
          ) {
            cellProps.style.borderLeft = 'red 3px solid';
          } else if (
            !isEmpty(data?.DateExpire) &&
            new Date().toISOString() < data?.DateExpire &&
            data?.DateExpire <= moment().add(3, 'months').toISOString()
          ) {
            cellProps.style.borderLeft = '#fcba03 3px solid';
          } else {
            cellProps.style.borderLeft = '#e4e3e2 3px solid';
          }
        },
      },
      {
        name: 'fldType',
        header: 'Type',
        defaultFlex: isDesktop ? 1 : undefined,
      },
      {
        name: 'fldCertNum',
        header: 'Number',
        defaultFlex: isDesktop ? 1 : undefined,
      },
      {
        name: 'DateIssue',
        header: 'Issue Date',
        headerAlign: 'start' as any,
        textAlign: 'end' as any,
        defaultFlex: isDesktop ? 1 : undefined,
        dateFormat: 'DD-MMM-YYYY',
        filterEditor: DateFilter,
        filterEditorProps: (props, { index }) => {
          return { placeholder: index == 1 ? 'End...' : 'Start...' };
        },
        render: ({ value, cellProps: { dateFormat } }) => {
          if (!isNil(value)) {
            return moment(value).format(dateFormat);
          }
        },
      },
      {
        name: 'DateExpire',
        header: 'Expires',
        headerAlign: 'start' as any,
        textAlign: 'end' as any,
        defaultFlex: isDesktop ? 1 : undefined,
        dateFormat: 'DD-MMM-YYYY',
        filterEditor: DateFilter,
        filterEditorProps: (props, { index }) => {
          return { placeholder: index == 1 ? 'End...' : 'Start...' };
        },
        render: ({ value, cellProps: { dateFormat } }) => {
          if (!isNil(value)) {
            return moment(value).format(dateFormat);
          }
        },
      },
      {
        name: 'year',
        header: 'Expires Year',
        defaultFlex: isDesktop ? 1 : undefined,
        defaultVisible: false,
      },
      {
        name: 'month',
        header: 'Expires Month',
        defaultFlex: isDesktop ? 1 : undefined,
        defaultVisible: false,
      },
      {
        name: 'fldResponsible',
        header: 'Responsible',
        defaultFlex: isDesktop ? 1 : undefined,
      },
    ];

    const footerRows = [
      {
        position: 'end',
        render: {
          icons: () => {
  
            const style = {
              paddingLeft: 20,
            };
  
            return (
              <div style={style}>
                Total Records: {currentCount}/{totalCount}
              </div>
            );
          },
        },
        colspan: {
          icons: (_, computedProps) => computedProps.visibleColumns.length,
        },
      },
    ];

    return (
      <div data-testid="data-grid" className="flex flex-col flex-grow">
        <div className="flex flex-row items-center justify-end">
          {!isEmpty(gridRef?.current.computedGroupBy) &&
            (groupCollapsedStatus ? (
              <div>
                <Tooltip title="Collapse All">
                  <IconButton onClick={CollapseButton}>
                    <UnfoldLessIcon />
                  </IconButton>
                </Tooltip>
              </div>
            ) : (
              <div>
                <Tooltip title="Expand All">
                  <IconButton onClick={CollapseButton}>
                    <UnfoldMoreIcon />
                  </IconButton>
                </Tooltip>
              </div>
          ))}
          { groupByFlag ? (
            <Tooltip title="Remove Group by Year/month">
              <IconButton onClick={groupByMonth}>
                <EventBusyIcon />
              </IconButton>
            </Tooltip>) : (
            <Tooltip title="Group by Year/month of Expires Date">
              <IconButton onClick={groupByMonth}>
                <CalendarMonthIcon />
              </IconButton>
            </Tooltip>)
          }
          {settingsPersonal?.fldCerts === 2 && (
            <DeleteRecordBtnOld
              visible={isActionable}
              records={rowSelected}
              onSelect={setRowSelected}
            />
          )}
        </div>
        <LicensedReactDataGrid
          onRowClick={onRowClick}
          onLoadingChange={onLoadingChange}
          defaultLimit={5000}
          livePagination
          onReady={onReady}
          rowHeight={40}
          defaultGroupBy={[]}
          onGroupByChange={() => gridRef.current.collapseAllGroups()}
          collapsedGroups={collapsedGroups}
          onGroupCollapseChange={setCollapsedGroups}
          loading={loading}
          enableSelection
          defaultFilterValue={filter}
          idProperty="PKey"
          columns={columns}
          dataSource={dataSource}
          renderGroupTitle={renderGroupTitle}
          footerRows={footerRows}
          selected={rowSelected}
          onSelectionChange={handleSelectionChange}
          checkboxColumn
          sourceRoot={sourceRoot}
        />

        <Dialog maxWidth="xs" open={exportDialog?.visible || false}>
          <DialogTitle>
            Export data to
            {exportDialog?.title}
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              You have filters applied. Would you like to export with current
              filters?
            </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'flex-end' }}>
            <Button
              autoFocus
              onClick={() => exportData(exportDialog?.type, false)}
            >
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => exportData(exportDialog?.type)}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
);

export default LibraryCertificatesGrid;
