import React, {FC, lazy, Suspense} from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Login from 'src/pages/Login';
import postLogin from '../../requests/postLogin';
// import {CircularProgress} from "@mui/material";

interface InjectedProps {
  handleToken: (t: string) => void;
}
// const Spinner = () => <div className="flex flex-grow items-center justify-center"><CircularProgress /></div>;

// const CreateAccountForm = lazy(() => import('../../components/create-account-form/create-account-form'));
// const ChangePasswordForm = lazy(() => import('../../components/change-password-form/change-password-form'));
// const ResetPasswordForm = lazy(() => import('../../components/reset-password-form/reset-password-form'));

const UnauthenticatedLayout: FC<InjectedProps> = (props) => {
  const { handleToken } = props;
  return (
    <Switch>
      <Route exact path="/login">
        <Login tokenCallback={handleToken} postLogin={postLogin} />
      </Route>
      <Redirect to="/login" />
    </Switch>
  );
};

export default UnauthenticatedLayout;
