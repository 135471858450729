import moment from "moment";

const createMonthsMap = () => {
  const monthsOrder: Record<string, number> = {
    'January': 0, 'February': 1, 'March': 2, 'April': 3,
    'May': 4, 'June': 5, 'July': 6, 'August': 7,
    'September': 8, 'October': 9, 'November': 10, 'December': 11
  };
  return new Map(Object.entries(monthsOrder));
};

export const stringMonthSort = (month1: string, month2: string): number => {
  const monthsMap = createMonthsMap();
  return monthsMap.get(month2)! - monthsMap.get(month1)!;
};

export const updateFilename = (filename: string, newDate: string): string => {
  // Regular expression to match date in the format YYYYMMDD
  const datePattern = /\d{8}/;
  // Replace the matched date with the newDate
  const updatedFilename = filename.replace(datePattern, newDate);
  return updatedFilename;
}

export const logDateFormat = (value:string) =>{
  let date
  if(value.endsWith('Z')){
    date = value.slice(0,-1)
  } else {
    date = value
  }
  return moment(date).format('MMM DD, YYYY');
}