import { NODE_URL } from '../consts';

const postLogin = async (username: string, password: string) => {
  const response = await fetch(`${NODE_URL}/auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username,
      password,
    }),
  });
  if(!response.ok){
    return Promise.reject(response); 
  }
  const data = await response.json();
  // console.log(data);
  return data;
};

export default postLogin;
