import { gql, useQuery } from '@apollo/client';
import { TblSystemsHierarchy } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';

export const GET_CATEGORIES = gql`
  query GetCategories {
    categories {
        pkey
        parentId
        fldCategory
        fldTags
        fldDepts
    }
  }
`;

interface CategoriesResult {
  data: TblSystemsHierarchy[];
  loading: boolean;
}

export const useGetCategories = (): CategoriesResult => {
  const { data, loading, error } = useQuery(GET_CATEGORIES, {
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    logger('GetCategories').error('Error fetching categories -->', error.message);
  }

  return {
    data: data?.categories || [],
    loading,
  };
};
