import moment, { Moment } from 'moment';

export const normalizeDateTime = (field?: string | Date | Moment | null) => {
    if (!field) return null;
    return moment(field).format('YYYY-MM-DD');
};

export const normalizeDateTimeWithDetails = (field?: string | Date | Moment | null) => {
    if (!field) return null;
    return moment(field).format('YYYY-MM-DD HH:mm:ss');
};

export const normalizeDateFormValue = (value: any, controlled: boolean = true)  => {
    if (!value) return controlled ? null : undefined;

    return moment(value).toDate();
};