import { ThemeOptions } from '@mui/material/styles';

const themeOptions: ThemeOptions = {
  spacing: 4,
  palette: {
    mode: 'light',
    primary: {
      main: '#1185df',
    },
    secondary: {
      main: '#e6f3fc',
    },
    background: {
      default: '#fff',
      paper: '#fff',
    },
    error: {
      main: '#f60000',
    },
    warning: {
      main: '#feba49',
    },
    info: {
      main: '#117acb',
    },
    success: {
      main: '#00d14d',
    },
    text: {
      primary: '#002041',
      secondary: '#acacac',
    },
  },
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    h1: {
      fontSize: '24pt',
    },
    h2: {
      fontSize: '20pt',
    },
    body1: {
      fontSize: 14,
      fontWeight: 500,
      fontFamily: "'Open Sans', sans-serif",
    },
    body2: {
      fontSize: '12pt',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto, Open Sans, Arial, sans-serif',
        },
        contained: {
          fontFamily: 'Roboto, Open Sans, Arial, sans-serif',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#002041',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          color: '#002041',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: 'grey',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          color: '#002041',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:before': {
            content: 'none',
          },
          backgroundColor: 'white',
          boxShadow: 'none',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: 14,
          fontWeight: 'bold',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#24344d',
          '&.Mui-checked': {
            color: '#24344d',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 12,
          paddingBottom: 12,
          backgroundColor: 'white',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          fontWeight: 'bold',
          fontSize: 16,
        },
        root: {
          backgroundColor: '#f2f9fd',
          borderRadius: 3,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: '#ededed',
          boxShadow: 'none',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 16,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          fontSize: 16,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'flex-start',
        },
      },
    },
  },
};

export default themeOptions;
