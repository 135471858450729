import { FC } from 'react';
import { Control } from 'react-hook-form';
import { useAuth } from 'src/contexts/auth';
import { useGetLocations } from 'src/hooks/locations/useGetLocations';
import TreeSelect from 'src/components/UI/Forms/TreeSelect';

type LocationProps = {
  label: string;
  control: Control;
  name: string;
  onChange: (field: string, value: any) => void;
  equipment?: any;
  disabled?: boolean;
  defaultValue?: string | null;
  allDepts?: boolean;
  sourceForm?: string;
  dropdownHeight?: number;
};

const LocationDropdown: FC<LocationProps> = ({
  control,
  name,
  label,
  onChange,
  equipment,
  disabled,
  defaultValue,
  sourceForm,
  allDepts = false,
  dropdownHeight,
}) => {
  const { user } = useAuth();
  const { data } = useGetLocations();

  const handleFilter = (item: any) => {
    switch (sourceForm) {
      case 'task':
        return getTaskCondition(item);
      case 'logentry':
        return getLogEntryCondition(item);
      case 'equipment':
        return getEquipmentCondition(item);
      case 'event-scheduling':
        return getEventSchedCondition(item);
      case 'drill-scheduling':
        return getDrillSchedCondition(item);
      case 'general-inventory':
        return getGenInvCondition(item);
      case 'beverages':
        return getNoteTwoCondition(item, 'BEVERAGES');
      case 'uniforms':
        return getNoteTwoCondition(item, 'UNIFORMS');
      case 'stores':
        return getNoteTwoCondition(item, 'STORES');
      case 'medical':
        return getNoteTwoCondition(item, 'MEDICAL');
      case 'inspections':
        return getInspectionsCondition(item);
      case 'ISPSDOCS':
        return getISPSCondition(item);
      case 'PHOTOS':
        return getTaskCondition(item);
      case 'DRAWINGS':
        return getTaskCondition(item);
      case 'SMSDOCS':
        return getSMSCondition(item);
      default:
        return true;
    }
  };

  const getSMSCondition = (item: any) => {
    if (item.fldTags && item.fldTags.includes('SMSDOCS')) {
      return true;
    }
    return false;
  };

  const getISPSCondition = (item: any) => {
    if (item.fldTags && item.fldTags.includes('ISPSDOCS')) {
      return true;
    }
    return false;
  };

  const getTaskCondition = (item: any) => {
    if (allDepts) {
      if (!item.fldTags || item.fldTags.includes('WORKISSUES')) {
        return true;
      }
      return false;
    }
    if (
      (!item.fldTags || item.fldTags.includes('WORKISSUES'))
      && (!item.fldDepts || item.fldDepts.includes((user?.Department || '').toUpperCase()))
    ) {
      return true;
    }
    //Adding below condition to handle if user do not have permission to see applied category but have permission to see task then it will enable that category only for the task
    if (item.PKey === defaultValue){
      return true
    }
    return false;
  };

  const getLogEntryCondition = (item: any) => {
    if (allDepts) {
      if (!item.fldTags || item.fldTags.includes('LOGENTRY')) {
        return true;
      }
      return false;
    }
    if (
      (!item.fldTags || item.fldTags.includes('LOGENTRY'))
      && (!item.fldDepts || item.fldDepts.includes((user?.Department || '').toUpperCase()))
    ) {
      return true;
    }
    return false;
  };

  const getEquipmentCondition = (item: any) => {
    if (allDepts) {
      if (!item.fldTags || item.fldTags.includes('EQUIPMENT')) {
        return true;
      }
      return false;
    }
    if (
      (!item.fldTags || item.fldTags.includes('EQUIPMENT'))
      && (!item.fldDepts || item.fldDepts.includes((user?.Department || '').toUpperCase()))
    ) {
      return true;
    }
    return false;
  };

  const getEventSchedCondition = (item: any) => {
    if (allDepts) {
      if (!item.fldTags || item.fldTags.includes('EVENT_SCHEDULING')) {
        return true;
      }
      return false;
    }
    if (
      (!item.fldTags || item.fldTags.includes('EVENT_SCHEDULING'))
      && (!item.fldDepts || item.fldDepts.includes((user?.Department || '').toUpperCase()))
    ) {
      return true;
    }
    return false;
  };

  const getDrillSchedCondition = (item: any) => {
    if (allDepts) {
      if (!item.fldTags || item.fldTags.includes('DRILL_SCHEDULING')) {
        return true;
      }
      return false;
    }
    if (
      (!item.fldTags || item.fldTags.includes('DRILL_SCHEDULING'))
      && (!item.fldDepts || item.fldDepts.includes((user?.Department || '').toUpperCase()))
    ) {
      return true;
    }
    return false;
  };

  const getGenInvCondition = (item: any) => {
    if (allDepts) {
      if (!item.fldTags || item.fldTags.includes('INVENTORY')) {
        return true;
      }
      return false;
    }
    if (
      (!item.fldTags || item.fldTags.includes('INVENTORY'))
      && (!item.fldDepts || item.fldDepts.includes((user?.Department || '').toUpperCase()))
    ) {
      return true;
    }
    return false;
  };

  const getInspectionsCondition = (item: any) => {
    if (allDepts) {
      if (!item.fldTags || item.fldTags.includes('INSPECTIONS')) {
        return true;
      }
      return false;
    }
    if (
      (!item.fldTags || item.fldTags.includes('INSPECTIONS'))
      && (!item.fldDepts || item.fldDepts.includes((user?.Department || '').toUpperCase()))
    ) {
      return true;
    }
    return false;
  };

  const getNoteTwoCondition = (item: any, field: string) => {
    if (item.fldTags && item.fldTags.includes(field)) {
      return true;
    }
    return false;
  };

  const locations = data.filter(handleFilter).map((elem: any) => ({
    pkey: elem.pkey,
    parentId: elem.parentId,
    fldLocation: elem.fldLocation,
    expanded: false,
  }));

  return (
    <div data-testid="data-grid">
      <TreeSelect
        disabled={disabled}
        keyExpr="pkey"
        parentIdExpr="parentId"
        displayExpr="fldLocation"
        label="Location"
        onChange={onChange}
        options={locations}
        control={control}
        name={name}
        valueExpr="pkey"
        dropdownHeight={dropdownHeight}
      />
    </div>
  );
};

export default LocationDropdown;
