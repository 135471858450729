import { RxCollection, RxJsonSchema, RxDocument } from 'rxdb';
import { CrewProfiles } from '../../../generated/graphql';

export type CrewDocType = CrewProfiles;

export type CrewDocMethods = {};

export type CrewDocument = RxDocument<
  CrewDocType,
  CrewDocMethods
>;

export type CrewCollectionMethods = {};

export type CrewCollection = RxCollection<
  CrewDocType,
  CrewDocMethods,
  CrewCollectionMethods
>;

//   DateEntered

export const schema: RxJsonSchema<CrewDocType> = {
  title: 'Crew ',
  description: 'Crew profiles',
  version: 5,
  keyCompression: false,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    DateEntered: {
      type: ['string', 'null'],
    },
    DateExpire: {
      type: ['string', 'null'],
    },
    DateFire: {
      type: ['string', 'null'],
    },
    DateHire: {
      type: ['string', 'null'],
    },
    DateIssue: {
      type: ['string', 'null'],
    },
    DatePayRev: {
      type: ['string', 'null'],
    },
    DateVac: {
      type: ['string', 'null'],
    },
    Department: {
      type: ['string', 'null'],
      ref: 'tblddlistdefaults',
    },
    DepartmentHead: {
      type: ['string', 'null'],
    },
    NoView: {
      type: ['boolean', 'null'],
    },
    CrewPassword: {
      type: ['string', 'null'],
    },
    CrewUsername: {
      type: ['string', 'null'],
    },
    fldAcct: {
      type: ['string', 'null'],
    },
    fldAddress: {
      type: ['string', 'null'],
    },
    fldAllergies: {
      type: ['string', 'null'],
    },
    fldBUPA: {
      type: ['string', 'null'],
    },
    fldBank: {
      type: ['string', 'null'],
    },
    fldBankContact: {
      type: ['string', 'null'],
    },
    fldBankCurr: {
      type: ['string', 'null'],
    },
    fldBankEmail: {
      type: ['string', 'null'],
    },
    fldBankFax: {
      type: ['string', 'null'],
    },
    fldBankIBAN: {
      type: ['string', 'null'],
    },
    fldBankPhone: {
      type: ['string', 'null'],
    },
    fldBankRouting: {
      type: ['string', 'null'],
    },
    fldBankSwift: {
      type: ['string', 'null'],
    },
    fldCertsOnBoard: {
      type: ['boolean', 'null'],
    },
    fldBloodRH: {
      type: ['string', 'null'],
    },
    fldBloodABO: {
      type: ['string', 'null'],
    },
    fldCurrent: {
      type: ['boolean', 'null'],
    },
    fldCrewID: {
      type: 'string',
      primary: true,
    },
    fldCrewFamiliar: {
      type: ['string', 'null'],
    },
    fldChestMeas: {
      type: ['string', 'null'],
    },
    fldChest: {
      type: ['number', 'null'],
    },
    fldCurrentPay: {
      type: ['number', 'null'],
    },
    fldDOB: {
      type: ['string', 'null'],
    },
    fldDL: {
      type: ['string', 'null'],
    },
    fldDefaultPassport: {
      type: ['string', 'null'],
    },
    fldDiet: {
      type: ['string', 'null'],
    },
    fldFax1: {
      type: ['string', 'null'],
    },
    fldFax2: {
      type: ['string', 'null'],
    },
    fldFirst: {
      type: 'string',
    },
    fldFood: {
      type: ['string', 'null'],
    },
    fldIsSO: {
      type: ['boolean', 'null'],
    },
    fldIsGuest: {
      type: ['boolean', 'null'],
    },
    fldIsAdmin: {
      type: ['boolean', 'null'],
    },
    fldInseamMeas: {
      type: ['string', 'null'],
    },
    fldInseam: {
      type: ['number', 'null'],
    },
    fldHeightMeas: {
      type: ['string', 'null'],
    },
    fldHeight: {
      type: ['number', 'null'],
    },
    fldIsSSO: {
      type: ['boolean', 'null'],
    },
    fldIsWatchkeeper: {
      type: ['boolean', 'null'],
    },
    fldIssue: {
      type: ['string', 'null'],
    },
    fldLast: {
      type: ['string', 'null'],
    },
    fldLaundryNum: {
      type: ['number', 'null'],
    },
    fldMarital: {
      type: ['string', 'null'],
    },
    fldMedConsent: {
      type: ['boolean', 'null'],
    },
    fldNOK1: {
      type: ['string', 'null'],
    },
    fldNAMEUPPER: {
      type: ['string', 'null'],
    },
    fldMiddle: {
      type: ['string', 'null'],
    },
    fldMeds: {
      type: ['string', 'null'],
    },
    fldMedNotes: {
      type: ['string', 'null'],
    },
    fldMedInstruct: {
      type: ['string', 'null'],
    },
    fldNOK1Mobile: {
      type: ['string', 'null'],
    },
    fldNOK1Email: {
      type: ['string', 'null'],
    },
    fldPersonalNotes: {
      type: ['string', 'null'],
    },
    fldPassport: {
      type: ['string', 'null'],
    },
    fldNeckMeas: {
      type: ['string', 'null'],
    },
    fldNeck: {
      type: ['number', 'null'],
    },
    fldNationality: {
      type: ['string', 'null'],
    },
    fldNOK2Mobile: {
      type: ['string', 'null'],
    },
    fldNOK2Email: {
      type: ['string', 'null'],
    },
    fldNOK2: {
      type: ['string', 'null'],
    },
    fldRemarks: {
      type: ['string', 'null'],
    },
    fldReligion: {
      type: ['string', 'null'],
    },
    fldPosition: {
      type: ['string', 'null'],
    },
    fldPlaceOfBirth: {
      type: ['string', 'null'],
    },
    fldPicture: {
      type: ['string', 'null'],
    },
    fldPhone2: {
      type: ['string', 'null'],
    },
    fldPhone1: {
      type: ['string', 'null'],
    },
    fldWire: {
      type: ['string', 'null'],
    },
    fldWeightMeas: {
      type: ['string', 'null'],
    },
    fldWeight: {
      type: ['number', 'null'],
    },
    fldWaistMeas: {
      type: ['string', 'null'],
    },
    fldWaist: {
      type: ['number', 'null'],
    },
    fldVacMeas: {
      type: ['string', 'null'],
    },
    fldVacDays: {
      type: ['number', 'null'],
    },
    fldStationBill: {
      type: ['string', 'null'],
    },
    fldShoeSize: {
      type: ['number', 'null'],
    },
    fldShoeMeas: {
      type: ['string', 'null'],
    },
    fldSeaDis: {
      type: ['boolean', 'null'],
    },
    fldSalary: {
      type: ['number', 'null'],
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    updatedAt: {
      type: 'string',
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
  },
  indexes: ['fldFirst'],
  required: [],
  additionalProperties: false,
};

export default schema;
