import { omit } from 'lodash';
import { v4 } from 'uuid';
import { Comments } from '../../../generated/graphql';
import {logger} from "../../../helpers/logger";

const fields = `
    id
    createdAt
    updatedAt
    content
    replyId
    referenceIssueId
    referenceIssueType
    authorId
    deletedAt
    deletedBy
    isRecoverable
    autoId
`;

export const commentsPullQuery = (doc: Comments) => {
  if (!doc) {
    doc = {
      autoId: 0,
      id: v4(),
      content: '',
      authorId: '',
      updatedAt: new Date(0).toISOString(),
      createdAt: new Date(0).toISOString(),
    };
  }
  logger('Comments').info(
    `pull query triggered -Comments:  { ${new Date().toISOString()} - ID: ${doc.autoId}, updatedAt: ${doc.updatedAt} }`,
  );
  return {
    query: `
        query getComments($autoId: Int, $filterDate: Date) {
          Comments(
            where:{ 
              _and: [
                {
                  _or: [
                    {
                      isRecoverable: {
                        _is_null: true
                      }
                    },
                    {
                      isRecoverable: {
                        _eq: true
                      }
                    }
                  ]
                },
                {
                  _or: [
                    {
                      updatedAt: {_gt: $filterDate},                
                    },
                    {
                      autoId: {_gt: $autoId}
                      updatedAt: {_gte: $filterDate},
                    }
                  ]
                }
              ]                                    
            },
            order_by: [
              {updatedAt: asc},
              {autoId: asc}],
            limit: 500
          ) {
            ${fields}
          }
        }
      `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
    },
  };
};

export const commentsMutation = (doc: any) => {
  const query = `
    mutation upsertComment($input: CommentInput!) {
      upsertComment(input: $input) {
        ${fields}
      }
    }
  `;

  const variables = {
    input: omit(doc, ['deletedAt']),
  };

  return {
    query,
    variables,
  };
};

export const commentsSubscription = `
  subscription onCommentsChanged {
    Comments {
      ${fields}
    }
  }
`;
