import { omit } from 'lodash';
import { Orders } from '../../../generated/graphql';
import {logger} from "../../../helpers/logger";

const fields = `
OrderID
OrderDate
PurchaseOrderNumber
Supplier
Department
OrderedBy
Contact
NoView
MyDesc
OrderName
OrderSent
SupplierID
fldHTML
fldType
fldStatus
PromisedByDate
DateEntered
deletedAt
updatedAt
deletedBy
isRecoverable
autoId
`;

export const ordersPullQuery = (doc: Orders) => {
  if (!doc) {
    doc = {
      autoId: 0,
      OrderID: '',
      OrderDate: '',
      updatedAt: new Date(0).toISOString(),
    };
  }
  logger('Orders').info(
    `pull query triggered - Orders: { ${new Date().toISOString()} -  ID: ${doc.autoId} - updatedAt: ${doc.updatedAt} }`,
  );
  return {
    query: `
        query getOrders($autoId: Int, $filterDate: Date) {
          Orders(
            where:{
              _and: [
                {
                  _or: [
                    {
                      isRecoverable: {
                        _is_null: true
                      }
                    },
                    {
                      isRecoverable: {
                        _eq: true
                      }
                    }
                  ]
                },
                {
                  _or: [
                    {
                      updatedAt: {_gt: $filterDate},                
                    },
                    {
                      autoId: {_gt: $autoId}
                      updatedAt: {_gte: $filterDate},
                    }
                  ]
                }
              ]                                    
            },
            order_by: [
              {updatedAt: asc},
              {autoId: asc}
            ],
            limit: 1000
          ) {
            ${fields}
          }
        }
      `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
    },
  };
};

export const ordersMutation = (doc: any) => {
  const query = `
    mutation InsertOrders($input: OrdersUpsertInput!) {
      upsertOrders(input: $input) {
        ${fields}
      }
    }
  `;

  const variables = {
    input: omit(doc, ['updatedAt', 'deletedAt']),
  };

  return {
    query,
    variables,
  };
};


export const ordersSubscription = `
  subscription onOrdersChanged {
    Orders {
      ${fields}
    }
  }
`;
