export const getNodeList = (curNode: any) => {
  const nodeKeyList: string[] = [];
  // Note: currently check three level up parent node
  curNode.forEach((itm: any) => {
    nodeKeyList.unshift(itm?.key);
    if (itm?.parent) {
      nodeKeyList.unshift(itm?.parent?.key);
      if (itm?.parent?.parent) {
        nodeKeyList.unshift(itm?.parent?.parent?.key);
      }
    }
  });
  return nodeKeyList;
};
