import { TblRlEnums } from '../../../generated/graphql';
import {logger} from "../../../helpers/logger";

const fields = `
PKey
fldEnumName
fldDataTypeName
fldDataTypeNumber
Summable
fldNumberOfDecimals
ValueList
deletedAt
deletedBy
isRecoverable
updatedAt
autoId
`;

export const tblRlEnumsPullQuery = (doc: TblRlEnums) => {
  if (!doc) {
    doc = {
      autoId: 0,
      PKey: '',
      Summable: false,
      fldDataTypeName: '',
      fldNumberOfDecimals: 0,
      fldDataTypeNumber: 0,
      fldEnumName: '',
      updatedAt: new Date(0).toISOString(),
    };
  }
  logger('TblRlEnums').info(
    `pull query triggered - TblRlEnums:  { ${new Date().toISOString()} - ID: ${doc.autoId}}`,
  );
  return {
    query: `
        query getTblRlEnums($autoId: Int, $filterDate: Date) {
          tblRLEnums(
            where:{
              _and: [
                {
                  _or: [
                    {
                      isRecoverable: {
                        _is_null: true
                      }
                    },
                    {
                      isRecoverable: {
                        _eq: true
                      }
                    }
                  ]
                },
                {
                  _or: [
                    {
                      updatedAt: {_gt: $filterDate},                
                    },
                    {
                      autoId: {_gt: $autoId}
                      updatedAt: {_gte: $filterDate},
                    }
                  ]
                }
              ]                                    
            },
            order_by: [
              {updatedAt: asc},
              {autoId: asc}
            ],
            limit: 500
          ) {
            ${fields}            
          }
        }
      `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
    },
  };
};

export const tblRlEnumsSubscription = `
  subscription onTblRlEnumsChanged {
    tblRLEnums {
      ${fields}
    }
  }
`;
