import { omit } from 'lodash';
import { TblSchedChkDesc } from '../../../generated/graphql';
import {logger} from "../../../helpers/logger";

const fields = `
  PKey
  fldDescriptions
  deletedAt
  deletedBy
  isRecoverable
  updatedAt
  autoId`;

export const tblSchedChkDescPullQuery = (doc: TblSchedChkDesc) => {
  if (!doc) {
    doc = {
      autoId: 0,
      PKey: '',
      updatedAt: new Date(0).toISOString(),
    };
  }
  logger('TblSchedChkDesc').info(
    `pull query triggered - TblSchedChkDesc:  { ${new Date().toISOString()} - ID: ${doc.autoId}}`,
  );
  return {
    query: `
        query getTblSchedChkDesc($autoId: Int, $filterDate: Date) {
          tblSchedChkDesc(
            where:{
              _and: [
                {
                  _or: [
                    {
                      isRecoverable: {
                        _is_null: true
                      }
                    },
                    {
                      isRecoverable: {
                        _eq: true
                      }
                    }
                  ]
                },
                {
                  _or: [
                    {
                      updatedAt: {_gt: $filterDate},                
                    },
                    {
                      autoId: {_gt: $autoId}
                      updatedAt: {_gte: $filterDate},
                    }
                  ]
                }
              ]                                    
            },
            order_by: [
              {updatedAt: asc},
              {autoId: asc}
            ],
              limit: 500
          ) {
            ${fields}            
          }
        }
      `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
    },
  };
};

export const tblSchedChkDescMutation = (doc: any) => {
  const query = `
    mutation insertTblSchedChkDesc($input: TblSchedChkDescInput!) {
      upsertTblSchedChkDesc(input: $input) {
        ${fields}
      }
    }
  `;

  const variables = {
    input: omit(doc, ['updatedAt', 'deletedAt', 'id']),
  };

  return {
    query,
    variables,
  };
};

export const tblSchedChkDescSubscription = `
  subscription onTblSchedChkDescChanged {
    tblSchedChkDesc {
      ${fields}
    }
  }
`;
