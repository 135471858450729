import { omit } from 'lodash';
import { TblShipsParticulars } from '../../../generated/graphql';
import {logger} from "../../../helpers/logger";

const fields = `
PKey
fldDataVersion
fldDBaseID
fldNextReplica
fldRunMaint
fldMaintPrompt
fldMaintPromptPeriod
fldMonitoringSystem
fldTemplate
fldDeliveryDate
fldRegNum
fldVesselName
fldAdminName
fldCallSign
fldVesselType
fldOfficialNum
fldFlag
fldPortOfReg
fldMMSI
fldAAIC
fldEngineType
fldMaxOut
fldProps
fldPower
fldShorePower
fldRudderType
fldGroundTackle
fldGrossTon
fldNet
fldMaxDisplacement
fldFuelCapacity
fldWaterCapacity
fldLubeOil
fldDirtyLube
fldLOA
fldLWaterline
fldBeamMould
fldDepth
fldDraftAftLoad
fldDraftForeLoad
fldHeight
fldMaxSpeed
fldMaxCrew
fldGuests
fldMaxAngle
fldHardOver1
fldHardOver2
fldBareSteer
fldRudderAngle1Prop
fldAnchorWeight
fldChainLenPort
fldChainLenStbd
fldChainStern
fldChainMeasure
fldTurnInitSpeedStbd
fldTurnS90Speed
fldTurnS90Time
fldTurnS180Speed
fldTurnS180Time
fldTurnS270Speed
fldTurnS270Time
fldTurnS360Speed
fldTurnS360Time
fldTurnP90Speed
fldTurnP90Time
fldTurnP180Speed
fldTurnP180Time
fldTurnP270Speed
fldTurnP270Time
fldTurnP360Speed
fldTurnP360Time
fldTotAdvS
fldTotAdvSDist
fldAdvS
fldAdvSDist
fldXferS
fldXferSDist
fldTactDiaS
fldTactDiaSDist
fldDiaS
fldDiaSDist
fldTotAdvP
fldTotAdvPDist
fldAdvP
fldAdvPDist
fldXferP
fldXferPDist
fldTactDiaP
fldTactDiaPDist
fldDiaP
fldDiaPDist
fldBowThrustPwr
fldBowThstDelay
fldBowTurnRate0
fldBowThrstDelayRev
fldBowMaxSpeedEffective
fldSternThrustPwr
fldSternThstDelay
fldSternTurnRate0
fldSternThrstDelayRev
fldSternMaxSpeedEffective
fldAgentAddress
fldShippingAddress
fldDPAName
fldDPACompany
fldDPAAddress
fldDPAPhone
fldDPAFax
fldDPAEmail
fldSMSTracking
fldSMSFile
fldSMSNoControl
DateEntered
deletedAt
deletedBy
isRecoverable
updatedAt
autoId
`;

export const tblShipsParticularsQuery = (doc: TblShipsParticulars) => {
  if (!doc) {
    doc = {
      autoId: 0,
      PKey: '',
      // fldT4Reqd: false,
      updatedAt: new Date(0).toISOString(),
    };
  }
  logger('TblShipsParticulars').info(
    `pull query triggered - TblShipsParticulars:  { ${new Date().toISOString()} - ID: ${doc.autoId} }`,
  );
  return {
    query: `
        query GetLatestTblShipsParticulars($autoId: Int, $filterDate: Date) {
          tblShipsParticulars (
            where:{
              _and: [
                {
                  _or: [
                    {
                      isRecoverable: {
                        _is_null: true
                      }
                    },
                    {
                      isRecoverable: {
                        _eq: true
                      }
                    }
                  ]
                },
                {
                  _or: [
                    {
                      updatedAt: {_gt: $filterDate},                
                    },
                    {
                      autoId: {_gt: $autoId}
                      updatedAt: {_gte: $filterDate},
                    }
                  ]
                }
              ]                                    
            },
            order_by: [
              {updatedAt: asc},
              {autoId: asc}
            ],
            limit: 500
          ) {
            ${fields}
          }
        }
      `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
    },
  };
};

export const tblShipsParticularsMutation = (doc: any) => {
  const query = `
        mutation upsertShipsParticulars($input: ShipsParticularsUpsertInput!) {
          upsertShipsParticulars(input: $input) {
            ${fields}
          }
        }
    `;
  const variables = {
    input: omit(doc, ['deletedAt', 'updatedAt']),
  };

  return {
    query,
    variables,
  };
};

export const tblShipsParticularsSubscription = `
  subscription tblShipsParticularsChanged {
    tblShipsParticulars {
      ${fields}
    }
  }
`;
