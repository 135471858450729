import { FC } from 'react';
import Button from '@mui/material/Button';
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CustomDialog } from 'src/helpers/modals';
import SpareInventoryGridOld from '../../SpareForm/components/indexOld';

interface InjectedProps {
  inventoryPopup: boolean;
  onCancelInventoryPopup: any;
  onSelectInventory: any;
  fldDataType?: string;
}

const InventoryPopupOld: FC<InjectedProps> = ({
  inventoryPopup,
  onCancelInventoryPopup,
  onSelectInventory,
  fldDataType,
}) => (
  <CustomDialog scroll="paper" fullWidth maxWidth="md" open={inventoryPopup}>
    <DialogTitle sx={{ m: 0, p: 4 }} style={{ fontSize: '26px' }}>
      <span className="ml-2">Inventory</span>
      {onCancelInventoryPopup ? (
        <IconButton
          aria-label="close"
          onClick={onCancelInventoryPopup}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
    <DialogContent dividers>
      <span className="ml-2"><b><small>To select spare, click/tap on a row.</small></b></span>
      <SpareInventoryGridOld
        fldDataType={fldDataType}
        onSelect={onSelectInventory}
      />
    </DialogContent>
    <DialogActions sx={{ m: 0, p: 3 }}>
      <Button onClick={onCancelInventoryPopup} className="w-32">
        Cancel
      </Button>
    </DialogActions>
  </CustomDialog>
);

export default InventoryPopupOld;
