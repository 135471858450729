import { FC, useEffect, useRef, useState } from "react";
import { isNil } from "lodash";
import PSPDFKitWeb, { Instance } from "pspdfkit";
import { DOWNLOAD_URL, PSPDF_LICENSE } from "../../consts";

interface FileViewerPdfProps {
  url: string;
}

const FileViewerPdf = ({ url }: FileViewerPdfProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isNil(ref)) return;

    const loadPdf = async () => {
      if (isNil(url) || isNil(ref?.current)) return;

      try {
        await PSPDFKitWeb.load({
          document: url,
          container: ref.current,
          licenseKey: PSPDF_LICENSE,
          // baseurl is neccesary to avoid a chunk loading error for some unspecified reason
          // https://pspdfkit.com/guides/web/troubleshooting/common-issues/#chunk-failing-to-load
          baseUrl: `${window.location.protocol}//${window.location.host}/`,
        });
      } catch (e) {
        console.error(e);
      }
    };

    loadPdf();

    return () => {
      if (!isNil(ref?.current)) {
        PSPDFKitWeb.unload(ref.current);
      }
    };
  }, [url, ref]);

  return (
    <div className="w-full h-full" data-testid="file-viewer-pdf">
      <div ref={ref} className="w-full h-full" />
    </div>
  );
};

export default FileViewerPdf;
