/* eslint-disable @typescript-eslint/ban-types */
import { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';
import { TblSchedChk } from '../../../generated/graphql';

export type TblSchedChkDocType = TblSchedChk;

export type TblSchedChkDocMethods = {};

export type TblSchedChkDocument = RxDocument<
  TblSchedChkDocType,
  TblSchedChkDocMethods
>;

export type TblSchedChkCollectionMethods = {};

export type TblSchedChkCollection = RxCollection<
  TblSchedChkDocType,
  TblSchedChkDocMethods,
  TblSchedChkCollectionMethods
>;

export const schema: RxJsonSchema<TblSchedChkDocType> = {
  keyCompression: false,
  title: 'Sched Checks',
  description: 'TblSchedChk',
  version: 1,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    PKey: {
      type: 'string',
      primary: true,
    },
    FKey: {
      type: ['string', 'null'],
    },
    EqKey: {
      type: ['string', 'null'],
    },
    fldLocHierarchy: {
      type: ['string', 'null'],
    },
    DescKey: {
      type: ['string', 'null'],
    },
    fldTitle: {
      type: ['string', 'null'],
    },
    fldIndex: {
      type: ['number', 'null'],
    },
    updatedAt: {
      type: 'string',
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
    Completed: {
      type: ['boolean', 'null']
    },
  },
  indexes: ['PKey'],
  required: [],
  additionalProperties: false,
};

export default schema;
