import { RxCollection, RxJsonSchema, RxDocument } from 'rxdb';
import { TblHrsOfRest } from '../../../generated/graphql';

export type HrsOfRestDocType = TblHrsOfRest;

export type HrsOfRestDocMethods = {};

export type HrsOfRestDocument = RxDocument<
  HrsOfRestDocType,
  HrsOfRestDocMethods
  >;

export type HrsOfRestCollectionMethods = {};

export type HrsOfRestCollection = RxCollection<
  HrsOfRestDocType,
  HrsOfRestDocMethods,
  HrsOfRestCollectionMethods
  >;

export const schema: RxJsonSchema<HrsOfRestDocType> = {
  title: 'HrsOfRest ',
  description: 'HrsOfRest',
  version: 1,
  keyCompression: false,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    PKey: {
      type: 'string',
      primary: true,
    },
    fldComment: {
      type: ['string', 'null'],
    },
    fldCrewID: {
      type: ['string', 'null'],
      ref: 'crew',
    },
    fldData: {
      type: ['string', 'null'],
    },
    fldDate: {
      type: 'number',
    },
    fldVerifiedBy: {
      type: ['string', 'null'],
      ref: 'crew',
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
    updatedAt: {
      type: 'string',
    },
  },
  indexes: [
    'fldDate',
  ],
  required: [
    'fldDate',
  ],
  additionalProperties: false,
};

export default schema;
