import React, {useEffect, useRef, useState} from 'react';
import {Tab} from "@mui/material";
import {Subscription} from "rxjs";
import {MangoQuerySelector} from "rxdb/dist/types/types";
import {getAttachmentCount} from "../../../../helpers/attachments";

interface InjectedProps {
  selector?: MangoQuerySelector<any>;
  type: 'attachments' | 'photos';
  label?: string;
}

const AttachmentTabButton = ({ selector, type, label = 'Attachments', ...rest }: InjectedProps) => {
  const [count, setCount] = useState<number>(0);
  const activeSubscription = useRef<Subscription>();

  const updateCount = (aCount: number, pCount: number) =>
    setCount(type === 'photos'? pCount : aCount);

  const getDocumentCount = async () => {
    activeSubscription.current = await getAttachmentCount(selector, updateCount);
  };

  useEffect(() => {
    getDocumentCount();
    return () => {
      activeSubscription.current?.unsubscribe();
    }
  }, []);

  return (
    <Tab key="AttachmentTabButton" label={`${label} (${count})`} {...rest} />
  )
};

export default AttachmentTabButton;
