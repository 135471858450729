// React Components
import { FC, useState } from 'react';
import { last, isArray } from 'lodash';
import { useForm } from 'react-hook-form';
import moment from 'moment';

// MUI components
import { Button, DialogTitle, Dialog, DialogActions, DialogContent, Box, Snackbar, Alert } from '@mui/material';
import LocationDropdownOld from 'src/components/Dropdowns/LocationDropdown/indexOld';

// TDI components
import Input from 'src/components/UI/Forms/Input';
import DatePicker from 'src/components/UI/Forms/DatePicker';
import Checkbox from 'src/components/UI/Forms/Checkbox';

// Data
import { getDatabase } from 'src/rxdb';
import { OrderItemsDocument } from 'src/rxdb/collections/OrderItems/schema';

//Utils
import { normalizeDateFormValue } from 'src/helpers';
import { useAppState } from 'src/contexts/app-state';
import { handleCharLimitWarning } from 'src/utils';
import { CHAR_LIMIT } from 'src/consts';
import { isValidDateFormat } from 'src/utils/format-dates';

interface InjectedProps {
    visible: boolean;
    initialValue: any;
    storageInitialValue: any;
    onCancel?: () => void;
    onSave?: () => void;
    title?: string;
    selectedItemOrderID?: string;
}

const InvStorageAddDialog: FC<InjectedProps> = ({
    visible,
    onCancel,
    initialValue,
    storageInitialValue,
    onSave,
    title,
    selectedItemOrderID = "",
}) => {
    const {
        control,
        setValue,
        handleSubmit,
        getValues,
        reset,
        watch,
        formState,
    } = useForm<any>({
        defaultValues: {
            fldLocHierarchy: storageInitialValue.fldLocHierarchy,
            SerialNum: storageInitialValue.SerialNum,
            Amount: storageInitialValue.Amount,
            fldMaxHldgLoc: storageInitialValue.fldMaxHldgLoc,
            UseBy: normalizeDateFormValue(storageInitialValue.UseBy),
            fldDefault: storageInitialValue.fldDefault,
            PKey: storageInitialValue.PKey,
        },
    });
    const { settingsPersonal } = useAppState();
    const { MULTIPLELOCATIONS } = CHAR_LIMIT;
    const [snackBar, setSnackbar] = useState({
        open: false,
        type: 'success',
        message: '',
    });

    const onChange = async (name: string, value: any) => {
        setValue(name, value, { shouldDirty: true });
    };

    const onSnackbarClose = () => {
        setSnackbar({
            open: false,
            message: '',
            type: 'success',
        });
    };
    const handleCancel = () => {
        onCancel && onCancel();
    };

    const handleSave = async (data: any) => {
        const db = await getDatabase();

        const {
            fldLocHierarchy,
            SerialNum,
            Amount,
            fldMaxHldgLoc,
            UseBy,
            fldDefault,
            PKey,
        } = data;

        if (fldDefault) {
            try {
                const query = db.tblmultiplelocations.find({
                    selector: {
                        $and: [
                            {
                                ProductID: {
                                    $eq: initialValue.ProductID
                                }
                            },
                            {
                                fldDefault: {
                                    $eq: true
                                }
                            },
                            {
                                PKey: {
                                    $ne: PKey
                                }
                            }
                        ]
                    }
                });
                await query.update({
                    $set: {
                        fldDefault: false
                    }
                })
            } catch (error: any) {
                setSnackbar({
                    open: true,
                    type: 'error',
                    message: error.message,
                });
            }
        }

        let orderitem: OrderItemsDocument[] = [];
        let OrderID: string = initialValue.OrderID ?? selectedItemOrderID ?? "";
        let selector: any = {
            ProductID: initialValue.ProductID,
            deletedBy: {
                $eq: null
            },
        }
        if (OrderID) {
            selector.OrderID = OrderID;
        }
        orderitem = await db.orderitems
            .find({
                selector: {
                    OrderID: OrderID,
                    ProductID: initialValue.ProductID,
                    deletedBy: {
                        $eq: null
                    },
                },
            })
            .exec() ?? [];
            
        if (fldLocHierarchy && fldLocHierarchy !== '') {
            const locationItem = await db.tblmultiplelocations.find({
                selector: {
                    $and: [
                        {
                            ProductID: {
                                $eq: initialValue.ProductID
                            }
                        },
                        {
                            fldLocHierarchy: {
                                $eq: isArray(fldLocHierarchy) ? last(fldLocHierarchy) : fldLocHierarchy
                            }
                        },
                        {
                            PKey: {
                                $ne: PKey
                            }
                        },
                        {
                            deletedAt: {
                                $eq: null
                            },
                        }
                    ]
                }
            }).exec()

            if (locationItem && locationItem.length > 0) {
                await locationItem[0].update({
                    $set: {
                        Amount: Number(locationItem[0].Amount) + Number(Amount)
                    }
                })
                if(orderitem.length > 0 && orderitem[0].AmtReceived) {
                    await orderitem[0].update({
                        $set: {
                            AmtReceived: Number(orderitem[0].AmtReceived) + Number(Amount)
                        }
                    })
                }
                onSave && onSave();
                reset();
                return;
            }
        }
        const document = {
            fldLocHierarchy: isArray(fldLocHierarchy)
                ? last(fldLocHierarchy)
                : fldLocHierarchy,
            SerialNum,
            Amount: parseFloat(Amount) || null,
            fldMaxHldgLoc: parseFloat(fldMaxHldgLoc) || null,
            UseBy: moment(UseBy).isValid() ? new Date(UseBy).toISOString() : null,
            fldDefault,
            ProductID: initialValue.ProductID,
            PKey,
            updatedAt: new Date().toISOString(),
        } as any;

        const AmountValue = watch("Amount");
        try {
            const oldAmount: number = Number(storageInitialValue.Amount) ?? 0;
            const res = await db.tblmultiplelocations.upsert(document);
            if(orderitem.length > 0) {
                let AmtReceived: number = (Number(orderitem[0].AmtReceived) ?? 0) + Number(AmountValue) - oldAmount;
                await orderitem[0].update({
                    $set: {
                        AmtReceived: AmtReceived,
                    }
                });
            }
            onSave && onSave();
            reset();
        } catch (e: any) {
            setSnackbar({
                open: true,
                type: 'error',
                message: e.message,
            });
        }
    };

    return (
        <>
            <form
                className="relative bg-white flex-grow"
            //   onSubmit={handleSubmit(handleSave)}
            >
                <Dialog
                    scroll="paper"
                    fullWidth
                    maxWidth="md"
                    open={visible}
                    onClose={handleCancel}
                >
                    <DialogTitle>
                        <span className="font-bold text-2xl">
                            {title}
                            {' '}
                            storage location
                        </span>
                    </DialogTitle>
                    <DialogContent dividers sx={{ p: 4 }}>
                        <div>
                            <div className="pt-5">
                                <LocationDropdownOld
                                    control={control}
                                    label="Location"
                                    name="fldLocHierarchy"
                                    sourceForm='general-inventory'
                                    onChange={onChange}
                                    allDepts={settingsPersonal?.fldAllDepts > 0}
                                />
                            </div>
                            <div className="pt-5">
                                <Input
                                    inputProps={{
                                        label: 'Serial Number',
                                    }}
                                    rules={{ maxLength: MULTIPLELOCATIONS.SerialNum }}
                                    warning={(value) => handleCharLimitWarning(value, MULTIPLELOCATIONS.SerialNum)}
                                    control={control}
                                    name="SerialNum"
                                />
                            </div>
                            <div className="pt-5 w-1/4">
                                <Input
                                    name="Amount"
                                    inputProps={{
                                        label: 'Amount',
                                        type: 'number',
                                        inputProps: {
                                            min: storageInitialValue.Amount ?? 0,
                                            inputMode: 'decimal',
                                            pattern: '([0-9]+)?[,\\.]?[0-9]*',
                                            step: 1,
                                            style: { textAlign: 'end' },
                                        },
                                    }}
                                    control={control}
                                    rules={{
                                        min: 0,
                                    }}
                                />
                            </div>
                            <div className="pt-5 w-1/4">
                                <Input
                                    name="fldMaxHldgLoc"
                                    inputProps={{
                                        label: 'Max',
                                        type: 'number',
                                        inputProps: {
                                            min: 0,
                                            style: { textAlign: 'end' },
                                        },
                                    }}
                                    control={control}
                                    rules={{
                                        min: 0,
                                    }}
                                />
                            </div>
                            <div className="pt-5">
                                <DatePicker
                                    name="UseBy"
                                    control={control}
                                    label="Expire Date"
                                    rules={{
                                        validate: (value) => isValidDateFormat(value) || 'Please enter a valid date format dd-MMM-yyyy',
                                      }}
                                />
                            </div>

                            <div className="pt-5">
                                <Checkbox
                                    control={control}
                                    name="fldDefault"
                                    label="This is the default location"
                                />
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
                        <Box
                            sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}
                        >
                            <Button
                                onClick={handleSubmit(handleSave)}
                                className="mr-3"
                                variant="contained"
                            >
                                Save
                            </Button>
                            <Button className="mr-2" onClick={handleCancel}>
                                Cancel
                            </Button>
                        </Box>
                    </DialogActions>
                </Dialog>
            </form>

            <Snackbar
                open={snackBar.open}
                autoHideDuration={2000}
                onClose={onSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
                    {snackBar.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default InvStorageAddDialog;
