// @ts-nocheck
import React, {
  FC,
  useCallback,
  useState,
  MutableRefObject,
  useRef,
  useEffect,
} from 'react';
import './style.css';
import LicensedReactDataGrid from 'src/components/UI/LicensedReactDataGrid';
import { pick, size, isEmpty, isNil } from 'lodash';
import { Snackbar, Alert } from '@mui/material';

// Data
import { EquipmentDocument } from 'src/pages/EquipmentPage/rxdb';
import { getDatabase, TDIDb } from 'src/rxdb';
import {getRegexByOperator} from "../../../../utils";

type EqHoursUtilityGridProps = {
  darken: boolean;
  onSelect: (item: EquipmentDocument) => void;
  defaultSelected: EquipmentDocuent;
};

const filter = [
  {
    name: 'UniqueName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
];

const transformData = async (item: EquipmentDocument) => ({
  ...pick(item, ['EqKey', 'UniqueName', 'fldHoursOffset', 'Hours']),
  original: item,
});

const getSelectorByFilterName = async (
  name: string,
  value: string,
  operator: string,
  db: TDIDb,
) => {
  switch (name) {
    case 'UniqueName':
      return {
        UniqueName: { $regex: getRegexByOperator(operator, value) },
      };

    default:
      return {};
  }
};

const loadData = async ({
  skip,
  limit,
  filterValue,
}: {
  sortInfo: TypeSortInfo;
  skip: number;
  limit: number;
  filterValue: any;
}): Promise<{ data: any[]; count: number }> => {
  const db = await getDatabase();

  let selector = {
    fldCountHours: true,
    Hours :{
      $gt: 0,
    }
  };

  await Promise.all(
    filterValue.map(async (v) => {
      if (isEmpty(v.value)) return v;

      const s = await getSelectorByFilterName(v.name, v.value, v.operator, db);

      selector = {
        ...selector,
        ...s,
      };
      return v;
    }),
  );

  const length = size(await db.equipment.find({ selector }).exec());

  const items = await db.equipment
    .find({
      selector,
      skip,
      limit,
    })
    .exec();

  const data = await Promise.all(items.map(transformData));

  return { data, count: length };
};

const EqHoursUtilityGrid: FC<EqHoursUtilityGridProps> = ({defaultSelected,onSelect }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const highlightedRowId = useRef<string>('-1');
  const [gridRef, setGridRef] = useState<any>(null);

  const init = async () =>{
    const db = await getDatabase();

    if(!isNil(defaultSelected.fldParent)){
      const item = await db.equipment.find({
        selector:{
          EqKey: defaultSelected.fldParent,

        }
      }).exec()
      highlightedRowId.current = item[0].EqKey;
    } else {
      highlightedRowId.current = '-1';
    }
  };

  useEffect( () =>{
    init();
  },[defaultSelected.fldParent])

  const onReady = async (ref: MutableRefObject<TypeComputedProps | null>) => {
    setGridRef(ref);
  };

  const onLoadingChange = (status: boolean) => {
    // If loading completed - check if there any items that needs to be highlighted.
    if (!status && highlightedRowId.current !== '-1') {
      gridRef?.current?.scrollToId(highlightedRowId.current);
      gridRef?.current?.setSelectedById(highlightedRowId.current, true);
      highlightedRowId.current = '-1';
    }
    setLoading(status);
  };

  const dataSource = useCallback(loadData, []);

  const onRowClick = useCallback(({ data }) => {
    onSelect(data.original);
  }, []);

  const columns = [
    {
      name: 'UniqueName',
      header: 'Equipment Name',
      flex: 1,
      editable: false,
    },
  ];

  return (
    <>
      <div data-testid="data-grid" className="flex flex-col flex-grow equipment-hour-utility-grid">
        <LicensedReactDataGrid
          onRowClick={onRowClick}
          onLoadingChange={onLoadingChange}
          defaultLimit={5000}
          livePagination
          onReady={onReady}
          rowHeight={40}
          loading={loading}
          enableSelection
          defaultFilterValue={filter}
          idProperty="EqKey"
          columns={columns}
          dataSource={dataSource}
          defaultSortInfo={[
              {name: 'UniqueName', dir: 1}
            ]}
        />
      </div>
    </>
  );
};

export default EqHoursUtilityGrid;
