import { FC } from 'react';
import AddIcon from '@mui/icons-material/Add';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PhotoIcon from '@mui/icons-material/Photo';
import CommentIcon from '@mui/icons-material/Comment';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';

interface GridRowIconProps {
  hasAttachments?: boolean;
  hasPhotos?: boolean;
  hasComments?: boolean;
  hasSpares?: boolean;
  isCritical?: boolean;
}

const GridRowIcon: FC<GridRowIconProps> = ({ hasAttachments, hasPhotos, hasComments, hasSpares, isCritical }) => {
  return (
    <div className="flex">
      { hasAttachments && <AttachFileIcon color='info'/> }
      { hasPhotos && <PhotoIcon color='info'/>}
      { hasComments && <CommentIcon color='success'/>}
      { hasSpares && <SettingsSuggestIcon color='action'/>}
      { isCritical && <AddIcon color='error'/> } 
    </div>
  );
};

export default GridRowIcon;
