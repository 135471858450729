import { createContext, useCallback, useContext, useState } from "react";
import ErrorDialog from "src/components/UI/ErrorDialog";
import WarningDialog from "src/components/UI/WarningDialog";

interface WarningDialogParams {
  title: string;
  content: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}
interface ErrorDialogParams {
  title: string;
  content: string;
  onConfirm?: () => void;
}

export const DialogContext = createContext({
  showErrorDialog: (params: ErrorDialogParams) => {},
  showWarningDialog: (params: WarningDialogParams) => {},
});

export const DialogProvider = ({ children }: any) => {
  const [error, setError] = useState<ErrorDialogParams | null>(null);
  const [warning, setWarning] = useState<WarningDialogParams | null>(null);

  const cancelWarning = useCallback(() => {
    if (warning?.onCancel) warning.onCancel();

    setWarning(null);
  }, [warning]);

  const confirmWarning = useCallback(() => {
    if (warning?.onConfirm) warning.onConfirm();
    setWarning(null);
  }, [warning]);

  const closeError = useCallback(() => {
    if (error?.onConfirm) error.onConfirm();
    setError(null);
  }, [error]);

  return (
    <DialogContext.Provider
      value={{
        showErrorDialog: setError,
        showWarningDialog: setWarning,
      }}
    >
      {children}
      {warning?.content && (
        <WarningDialog
          visible={Boolean(warning.content)}
          title={warning.title}
          content={warning.content}
          onOk={confirmWarning}
          onCancel={cancelWarning}
        />
      )}
      {error?.content && (
        <ErrorDialog
          visible={Boolean(error)}
          title={error.title}
          content={error.content}
          onClose={closeError}
        />
      )}
    </DialogContext.Provider>
  );
};

export const useDialog = () => useContext(DialogContext);
