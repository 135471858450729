// @ts-nocheck
import React, {
  FC,
  useCallback,
  useState,
  MutableRefObject,
  useRef,
  useEffect,
} from 'react';
import LicensedReactDataGrid from '../../../UI/LicensedReactDataGrid';
import { pick, size, isEmpty } from 'lodash';

import TypeSortInfo from '@inovua/reactdatagrid-enterprise/types';
import { MangoQuerySortDirection } from 'rxdb/dist/types/types/rx-query';

// Data
import { EquipmentDocument } from 'src/pages/EquipmentPage/rxdb';
import { getDatabase, TDIDb } from '../../../../rxdb';
import './style.css';
import { populateSchedMaintEQData } from 'src/pages/EquipmentSchedules/utils';

type RelatedItemAddGridProps = {
  darken?: boolean;
  onSelect?: (item: any) => void;
  selectedOption?: any;
  gridKeyData?: any;
};

const equipmentFilter = [
  {
    name: 'UniqueName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
];

const certificatesFilter = [
  {
    name: 'fldType',
    operator: 'contains',
    type: 'string',
    value: '',
  },
];

const inventoryFilter = [
  {
    name: 'ProductName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
];

const ordersFilter = [
  {
    name: 'OrderName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
];

const equipmetScheduleFilter = [
  {
    name: 'fldSubject',
    operator: 'contains',
    type: 'string',
    value: '',
  },
];

const getRegexByOperator = (operator: string, value: string) => {
  switch (operator) {
    case 'contains':
      return new RegExp(`.*${value}`, 'i');
    case 'startsWith':
      return new RegExp(`^${value}`, 'i');
    case 'endsWith':
      return new RegExp(`${value}$`, 'i');
    default:
      return new RegExp();
  }
};

const getSelectorByFilterName = async (
  name: string,
  value: string,
  operator: string,
  db: TDIDb
) => {
  switch (name) {
    case 'UniqueName':
      return {
        UniqueName: { $regex: getRegexByOperator(operator, value) },
      };

    case 'ProductName':
      return {
        ProductName: { $regex: getRegexByOperator(operator, value) },
      };

    case 'fldType':
      return {
        fldType: { $regex: getRegexByOperator(operator, value) },
      };
    case 'OrderName':
      return {
        OrderName: { $regex: getRegexByOperator(operator, value) },
      };
    default:
      return {};
  }
};

const RelatedItemAddGrid: FC<RelatedItemAddGridProps> = ({
  onSelect,
  selectedOption,
  gridKeyData,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const highlightedRowId = useRef<string>('-1');
  const [gridRef, setGridRef] = useState<any>(null);
  const [selected, setSelected] = useState<any>(null);
  const [columns, setColumns] = useState<any>([]);

  const SetCoulmn = () => {
    switch (selectedOption) {
      case 'Equipment': {
        const column = [
          {
            name: 'UniqueName',
            header: 'Equipment Name',
            flex: 1,
          },
        ];
        setColumns(column);
        return;
      }
      case 'Equipment Schedules': {
        const column = [
          {
            name: 'fldSubject',
            header: 'Task Subject',
            flex: 1,
          },
        ];
        setColumns(column);
        return;
      }
      case 'General Inventory': {
        const column = [
          {
            name: 'ProductName',
            header: 'Inventory Name',
            flex: 1,
          },
        ];
        setColumns(column);
        return;
      }
      case 'Uniforms Inventory': {
        const column = [
          {
            name: 'ProductName',
            header: 'Inventory Name',
            flex: 1,
          },
        ];
        setColumns(column);
        return;
      }
      case 'Medical Inventory': {
        const column = [
          {
            name: 'ProductName',
            header: 'Inventory Name',
            flex: 1,
          },
        ];
        setColumns(column);
        return;
      }
      case 'Beverages Inventory': {
        const column = [
          {
            name: 'ProductName',
            header: 'Inventory Name',
            flex: 1,
          },
        ];
        setColumns(column);
        return;
      }
      case 'Dry & Regrigerated Stores': {
        const column = [
          {
            name: 'ProductName',
            header: 'Inventory Name',
            flex: 1,
          },
        ];
        setColumns(column);
        return;
      }
      case 'Certificates': {
        const column = [
          {
            name: 'fldType',
            header: 'Certificates Type',
            flex: 1,
          },
        ];
        setColumns(column);
        return;
      }
      case 'Orders': {
        const column = [
          {
            name: 'OrderName',
            header: 'Name',
            flex: 1,
          },
        ];
        setColumns(column);
        return;
      }
      default:
        return;
    }
  };

  useEffect(() => {
    SetCoulmn();
  }, [selectedOption]);

  const transformData = async (item: any) => {
    switch (selectedOption) {
      case 'Equipment':
        return {
          ...pick(item, ['EqKey', 'UniqueName']),
          originalEq: item,
        };
      case 'General Inventory':
        return {
          ...pick(item, ['ProductID', 'ProductName']),
          originalEq: item,
        };
      case 'Uniforms Inventory':
        return {
          ...pick(item, ['ProductID', 'ProductName']),
          originalEq: item,
        };
      case 'Beverages Inventory':
        return {
          ...pick(item, ['ProductID', 'ProductName']),
          originalEq: item,
        };
      case 'Medical Inventory':
        return {
          ...pick(item, ['ProductID', 'ProductName']),
          originalEq: item,
        };
      case 'Dry & Regrigerated Stores':
        return {
          ...pick(item, ['ProductID', 'ProductName']),
          originalEq: item,
        };
      case 'Certificates':
        return {
          ...pick(item, ['PKey', 'fldType']),
          originalEq: item,
        };
      case 'Orders':
        return {
          ...pick(item, ['OrderID', 'OrderName']),
          originalEq: item,
        };
      case 'Equipment Schedules':
        {
          const data = await populateSchedMaintEQData(item)
          return data;
        }
      default:
        return;
    }
  };

  const loadData = async ({
    skip,
    limit,
    filterValue,
  }: {
    sortInfo: TypeSortInfo;
    skip: number;
    limit: number;
    filterValue: any;
  }): Promise<{ data: any[]; count: number }> => {
    const db = await getDatabase();

    // TODO: Handle filtervalue.OPERATOR
    let selector = {};

    if (selectedOption === 'Equipment') {
      selector = {
        EqKey: { $nin: gridKeyData || [] },
        deletedAt:{ $eq: null},
      };
    }
    if (selectedOption === 'Equipment Schedules') {
      selector = {
        PKey: { $nin: gridKeyData || [] },
        deletedAt:{ $eq: null},
      };
    }
    if (selectedOption === 'Orders') {
      selector = {
        OrderID: { $nin: gridKeyData || [] },
        deletedAt:{ $eq: null},
      };
    }
    if (selectedOption === 'Certificates') {
      selector = {
        fldArchive: {
          $eq: false,
        },
        PKey: { $nin: gridKeyData || [] },
        deletedAt:{ $eq: null},
      };
    }

    if (selectedOption === 'General Inventory') {
      selector = {
        fldDataType: {
          $eq: '0',
        },
        ProductID: { $nin: gridKeyData || [] },
        deletedAt:{ $eq: null},
      };
    }

    if (selectedOption === 'Uniforms Inventory') {
      selector = {
        fldDataType: {
          $eq: '3',
        },
        ProductID: { $nin: gridKeyData || [] },
        deletedAt:{ $eq: null},
      };
    }

    if (selectedOption === 'Medical Inventory') {
      selector = {
        fldDataType: {
          $eq: '4',
        },
        ProductID: { $nin: gridKeyData || [] },
        deletedAt:{ $eq: null},
      };
    }

    if (selectedOption === 'Beverages Inventory') {
      selector = {
        fldDataType: {
          $eq: '1',
        },
        ProductID: { $nin: gridKeyData || [] },
        deletedAt:{ $eq: null},
      };
    }

    if (selectedOption === 'Dry & Regrigerated Stores') {
      selector = {
        fldDataType: {
          $eq: '2',
        },
        ProductID: { $nin: gridKeyData || [] },
        deletedAt:{ $eq: null},
      };
    }

    await Promise.all(
      filterValue.map(async (v) => {
        if (isEmpty(v.value)) return v;

        const s = await getSelectorByFilterName(
          v.name,
          v.value,
          v.operator,
          db,
          selectedOption
        );

        selector = {
          ...selector,
          ...s,
        };
        return v;
      })
    );

    let items;

    switch (selectedOption) {
      case 'Equipment': {
        items = await db.equipment
          .find({
            selector,
          })
          .exec();
        break;
      }
      case 'Orders': {
        items = await db.orders
          .find({
            selector,
          })
          .exec();
        break;
      }
      case 'General Inventory': {
        items = await db.inventory
          .find({
            selector,
          })
          .exec();
        break;
      }
      case 'Uniforms Inventory': {
        items = await db.inventory
          .find({
            selector,
          })
          .exec();
        break;
      }
      case 'Beverages Inventory': {
        items = await db.inventory
          .find({
            selector,
          })
          .exec();
        break;
      }
      case 'Medical Inventory': {
        items = await db.inventory
          .find({
            selector,
          })
          .exec();
        break;
      }
      case 'Dry & Regrigerated Stores': {
        items = await db.inventory
          .find({
            selector,
          })
          .exec();
        break;
      }
      case 'Certificates': {
        items = await db.certificates
          .find({
            selector,
          })
          .exec();
        break;
      }
      case 'Equipment Schedules': {
        items = await db.tblschedmainteq
          .find({
            selector,
          })
          .exec();
        break;
      }
      
      default:
        break;
    }

    const length = size(items);
    const data = await Promise.all(items.map(transformData));
    return { data, count: length };
  };

  const onReady = (ref: MutableRefObject<TypeComputedProps | null>) => {
    setGridRef(ref);
  };

  const onLoadingChange = (status: boolean) => {
    // If loading completed - check if there any items that needs to be highlighted.
    if (!status && highlightedRowId.current !== '-1') {
      gridRef?.current?.scrollToId(highlightedRowId.current);
      gridRef?.current?.setSelectedById(highlightedRowId.current, true);
      highlightedRowId.current = '-1';
    }
    setLoading(status);
  };

  const dataSource = useCallback(loadData, [selectedOption]);

  const onSelectionChange = useCallback(({ selected: selectedMap, data }) => {
    const newSelected = Object.keys(selectedMap).map((id) => id * 1);

    setSelected(newSelected);

    onSelect(Object.keys(selectedMap));
  }, []);

  return (
    <div
      data-testid="data-grid"
      className="flex flex-col flex-grow p-4 related-add-grid"
    >
      {selectedOption === 'Equipment' && (
        <LicensedReactDataGrid
          onLoadingChange={onLoadingChange}
          defaultLimit={5000}
          livePagination
          onReady={onReady}
          rowHeight={40}
          checkboxColumn
          defaultSelected={[]}
          loading={loading}
          onSelectionChange={onSelectionChange}
          defaultFilterValue={equipmentFilter}
          idProperty="EqKey"
          columns={columns}
          dataSource={dataSource}
          defaultSortInfo={[{ name: 'UniqueName', dir: 1 }]}
        />
      )}
      {selectedOption === 'Orders' && (
        <LicensedReactDataGrid
          onLoadingChange={onLoadingChange}
          defaultLimit={5000}
          livePagination
          onReady={onReady}
          rowHeight={40}
          checkboxColumn
          defaultSelected={[]}
          loading={loading}
          onSelectionChange={onSelectionChange}
          defaultFilterValue={ordersFilter}
          idProperty="OrderID"
          columns={columns}
          dataSource={dataSource}
          defaultSortInfo={[{ name: 'OrderName', dir: 1 }]}
        />
      )}
      {selectedOption === 'Equipment Schedules' && (
        <LicensedReactDataGrid
          onLoadingChange={onLoadingChange}
          defaultLimit={5000}
          livePagination
          onReady={onReady}
          rowHeight={40}
          checkboxColumn
          defaultSelected={[]}
          loading={loading}
          onSelectionChange={onSelectionChange}
          defaultFilterValue={equipmetScheduleFilter}
          idProperty="PKey"
          columns={columns}
          dataSource={dataSource}
          defaultSortInfo={[{ name: 'fldSubject', dir: 1 }]}
        />
      )}
      {selectedOption === 'Certificates' && (
        <LicensedReactDataGrid
          onLoadingChange={onLoadingChange}
          defaultLimit={5000}
          livePagination
          onReady={onReady}
          rowHeight={40}
          checkboxColumn
          defaultSelected={[]}
          loading={loading}
          onSelectionChange={onSelectionChange}
          defaultFilterValue={certificatesFilter}
          idProperty="PKey"
          columns={columns}
          dataSource={dataSource}
          defaultSortInfo={[{ name: 'fldType', dir: 1 }]}
        />
      )}
      {(selectedOption === 'General Inventory' ||
        selectedOption === 'Uniforms Inventory' ||
        selectedOption === 'Medical Inventory' ||
        selectedOption === 'Beverages Inventory' ||
        selectedOption === 'Dry & Regrigerated Stores') && (
        <LicensedReactDataGrid
          onLoadingChange={onLoadingChange}
          defaultLimit={5000}
          livePagination
          onReady={onReady}
          rowHeight={40}
          checkboxColumn
          defaultSelected={[]}
          loading={loading}
          onSelectionChange={onSelectionChange}
          defaultFilterValue={inventoryFilter}
          idProperty="ProductID"
          columns={columns}
          dataSource={dataSource}
          defaultSortInfo={[{ name: 'ProductName', dir: 1 }]}
        />
      )}
    </div>
  );
};

export default RelatedItemAddGrid;
