import { omit } from 'lodash';
import { WorkIssues } from 'src/generated/graphql';
import {logger} from "../../../helpers/logger";

const fields = `
  AssignedTo
  Company
  Completed
  DateCompleted
  DateDue
  DateEntered
  DateStart
  DateWarn
  Department
  EqKey
  Equipment
  JobNumber
  Checks
  Notes
  PercentComplete
  Subject
  WorkItem
  fldActualCost
  fldActualCurrency
  fldActualWork
  fldCustJob
  fldDayChk
  fldDeptCreator
  fldDuration
  fldHTML
  fldHasBeenLogged
  fldHours
  fldIsCAR
  fldIsCheckList
  fldIsWarranty
  fldLocHierarchy
  fldLogOnDone
  fldPaid
  fldParentID
  fldPriority
  fldQuotedCurrency
  fldQuotedPrice
  fldSMS
  fldSMSID
  fldSMSType
  fldSchedMaintKey
  fldStatus
  fldTTLWork
  fldWorkList
  fldSRHKey
  deletedAt
  updatedAt
  deletedBy
  isRecoverable
  autoId
`;

export const workIssuesQuery = (doc: WorkIssues) => {
  if (!doc) {
    doc = {
      autoId: 0,
      JobNumber: '',
      Subject: '',
      updatedAt: new Date(0).toISOString(),
    };
  }

  const sixMonthsAgo = new Date();
  sixMonthsAgo.setDate(sixMonthsAgo.getDate() - 180);

  logger('WorkIssues').info(
    `pull query triggered - WorkIssues: { ${new Date().toISOString()} - ID: ${doc.autoId}, updatedAt: ${doc.updatedAt} }`,
  );
  return {
    query: `
    query GetLatestWorkIssues($autoId: Int, $filterDate: Date, $sixMonthsAgo: Date) {
      WorkIssues(
        where: {
          _and: [
            {
              _or: [
                {
                  isRecoverable: {
                    _is_null: true
                  }
                },
                {
                  isRecoverable: {
                    _eq: true
                  }
                }
              ]
            },
            {
              _or: [
                {
                  updatedAt: {_gt: $filterDate},
                },
                {
                  updatedAt: {_gte: $filterDate},
                  autoId: {_gt: $autoId},
                }
              ]
            },
            {
              _or: [
                {
                  _or: [
                    {
                      Completed: {_eq: false}
                    },
                    {
                      Completed: {_eq: true},
                      DateCompleted: {_gt: $sixMonthsAgo}
                    }
                  ]
                }
              ]
            }
                        
            # RM_RXDB - this filter breaks the RXDB replication for task completion
            # {
            #   _or: [
            #     {
            #       Completed: { _is_null: true }
            #     },
            #     {
            #       Completed: { _eq: false }
            #     }
            #   ]
            # }
          ]
        },
        order_by: [
          {updatedAt: asc},
          {autoId: asc}
        ],
        limit: 1000
        ) {
        ${fields}        
      }
    }
  `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
      sixMonthsAgo: sixMonthsAgo.toISOString(),
    },
  };
};

export const workIssuesMutation = (doc: any) => {
  const query = `
    mutation InsertWorkIssues($input: WorkIssueUpsertInput!) {
      upsertWorkIssue(input: $input) {
        ${fields}
      }
    }
  `;

  const variables = {
    input: omit(doc, ['updatedAt', 'deletedAt', 'accessDate']),
  };

  return {
    query,
    variables,
  };
};

export const workissuesSubscription = `
  subscription onWorkIssuesChanged {
    WorkIssues {
     ${fields}      
    }
  }
`;
