import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Box } from "@mui/system";
import { isEmpty, pick, size } from "lodash";
import { useCallback, useRef, useState } from "react";
import LicensedReactDataGrid from "src/components/UI/LicensedReactDataGrid";
import { EquipmentDocument } from "src/pages/EquipmentPage/rxdb";
import { getDatabase, TDIDb } from "src/rxdb"

type LoadType = {
  skip: number;
  limit: number;
  filterValue: any;
}

const filter = [
  {
    name: 'UniqueName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'Manufacturer',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'ModelNumber',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'Department',
    operator: 'eq',
    type: 'select',
    value: '',
  },
];

const getRegexByOperator = (operator: string, value: string) => {
  switch (operator) {
    case 'contains':
      return new RegExp(`.*${value}`, 'i');
    case 'startsWith':
      return new RegExp(`^${value}`, 'i');
    case 'endsWith':
      return new RegExp(`${value}$`, 'i');
    default:
  }
};

const getSelectorByFilterName = async (
  name: string,
  value: string,
  operator: string,
  db: TDIDb,
) => {
  switch (name) {
    case 'UniqueName':
      return { UniqueName: { $regex: getRegexByOperator(operator, value) } };
    default:
      return {};
  }
};

const transformData = async (item: EquipmentDocument) => ({
  ...pick(item, ['EqKey', 'UniqueName', 'fldHoursOffset', 'Hours']),
  original: item,
});

type SelectedEquipmentDialogProps = {
  onSelect: (data: EquipmentDocument) => void
  onClose: () => void
  eqKeyName: string
  eqKey?: string
  isParent?: boolean
}

const SelectEquipmentDialog: React.FC<SelectedEquipmentDialogProps> = ({ onSelect, onClose, eqKeyName, eqKey, isParent }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const highlightedRowId = useRef<string>(eqKeyName ||'-1');
  const [gridRef, setGridRef] = useState<any>(null);

  const loadData = async ({ skip, limit, filterValue }: LoadType): Promise<{ data: any[], count: number }> => {
    const db = await getDatabase();
    
    let selector: Record<string, any> = { 
      fldParent: { $eq: null }, 
    };

    if (eqKey) {
      selector.EqKey = { $ne: eqKey }
    }

    if (isParent) {
      selector.fldCountHours = true;
    }
  
    await Promise.all(filterValue.map(async (v: any) => {
      if (isEmpty(v.value)) return v;
      const s = await getSelectorByFilterName(v.name, v.value, v.operator, db);
      selector = { ...selector, ...s };
      return v;
    }))
  
    const length = size(await db.equipment.find({ selector }).exec());
    const items = await db.equipment.find({ selector, skip, limit }).exec();
    const data = await Promise.all(items.map(transformData));
  
    return { data, count: length };
  }

  const init = async (ref: any) => {
    const db = await getDatabase();
    db.equipment.$.subscribe(async (ev) => {
      if (ev.operation === 'INSERT' || ev.operation === 'UPDATE') {
        highlightedRowId.current = ev.documentId;
        ref.current?.reload();
      }
      if (ev.operation === 'DELETE') {
        highlightedRowId.current = '-1';
        ref.current?.reload();
      }
    });
  }

  const dataSource = useCallback(loadData, []);

  const onRowClick = ({ data }: any) => {
    onSelect(data.original);
  };

  const onLoadingChange = (status: boolean) => {
    if (!status && highlightedRowId.current !== '-1') {
      gridRef?.current?.scrollToId(highlightedRowId.current);
      gridRef?.current?.setSelectedById(highlightedRowId.current, true);
      highlightedRowId.current = '-1';
    }
    setLoading(status);
  };

  const onReady = (ref: any) => {
    setGridRef(ref);
    init(ref);
  };

  const columns = [
    {
      name: 'UniqueName',
      header: 'Equipment Name',
      flex: 1,
      editable: false,
    }
  ];

  return (
    <Dialog fullWidth maxWidth="md" onClose={onClose} open={true}>
      <DialogTitle>Select Equipment</DialogTitle>
      <DialogContent>
        <Box display="flex" sx={{ height: 450 }}>
          <LicensedReactDataGrid
            onRowClick={onRowClick}
            onLoadingChange={onLoadingChange}
            defaultLimit={5000}
            livePagination
            onReady={onReady}
            rowHeight={40}
            loading={loading}
            enableSelection
            idProperty="EqKey"
            columns={columns}
            dataSource={dataSource}
            defaultFilterValue={filter}
            defaultSortInfo={[{ name: 'UniqueName', dir: 1 }]}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{ width: '100%' }} display="flex" justifyContent="flex-end">
          <Button onClick={() => onClose()}>Close</Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default SelectEquipmentDialog

