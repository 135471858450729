import { gql, useQuery } from "@apollo/client";
import { Attachment, AttachmentType, RecordType } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";
import { isNotNil } from "src/utils";

export const GET_ATTACHMENTS_FOR_RECORD = gql`
  query GetAttachments($recordId: String!, $recordType: RecordType!, $attachmentType: AttachmentType!) {
    attachmentsForRecord(recordId: $recordId, recordType: $recordType, attachmentType: $attachmentType) {
      docTitle
      fldAltPath
      fldDescription
      fldFileName
      fldLibType
      fldNotes
      fldPage
      fldRevision
      fldRevisionDate
      fldShowInCentral
      fldSrhkey
      pKey
      recordId
      recordType
    }
  }
`

interface AttachmentsResult {
  attachmentsData: Attachment[];
  attachmentsLoading: boolean;
  attachmentsCount: number;
}

export const useGetAttachments = (recordId: string, recordType: RecordType, attachmentType: AttachmentType): AttachmentsResult => {

  if (!isNotNil(recordId)) {
    return {
      attachmentsData: [],
      attachmentsLoading: false,
      attachmentsCount: 0
    };
  }

  const { data, loading, error } = useQuery(GET_ATTACHMENTS_FOR_RECORD, {
    variables: {
      recordId,
      recordType,
      attachmentType
    },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    logger('GetAttachments').error(`Error fetching ${attachmentType}'s for ${recordType}: ${recordId} -->`, error.message);
  }

  return {
    attachmentsData: data?.attachmentsForRecord || [],
    attachmentsLoading: loading,
    attachmentsCount: data?.attachmentsForRecord.length, 
  };
};

