import React, { useState, useEffect } from 'react';
import { Prompt } from 'react-router'; 

export interface WithFormProgressTrackingProps {
  setFormIsDirty: (status: boolean) => void;
  showPrompt: (onConfirm?: () => void, onCancel?: () => void) => void;
}

const MESSAGE = 'You have unsaved changes, continue?';

const withFormProgressTracking = (Component: React.ComponentType<any>): React.FC<any> => props => {
    const [isDirty, setFormIsDirty] = useState<boolean>(false);

    useEffect(() => {
        // Handle close tab
        if (isDirty) {
            window.onbeforeunload = () => true
          } else {
            window.onbeforeunload = null
          }
    });

    const showPrompt = (onConfirm?: () => void, onCancel?: () => void) => {
        if (!isDirty) return onConfirm && onConfirm(); // If form is in a good state - just continue

        const isConfirmed = confirm(MESSAGE);

        if (isConfirmed) {
          setFormIsDirty(false);
          return onConfirm && onConfirm();
        }
        onCancel && onCancel();
    };

  return (
    <>
        <Component
        {...props}
        setFormIsDirty={setFormIsDirty}
        showPrompt={showPrompt}
        />

        <Prompt
            when={isDirty}
            message={MESSAGE}
        />
    </>
  )
};

export default withFormProgressTracking;