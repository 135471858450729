// React Components
import {FC, useEffect, useRef, useState} from 'react';
import { isNil, map, size, filter, isEmpty, fromPairs } from 'lodash';
import {useLocation} from "react-router";

// Context
import ReplicationContext from './ReplicationContext';

// TDI Components
import ReplicationProgressItem from "../../components/UI/ReplicationProgressItem";

// Style
import {Alert, Button, Dialog, Stack} from "@mui/material";
import Grid from "@mui/material/Grid";
import {Close} from "@mui/icons-material";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { green, red } from '@mui/material/colors';
import Fab from '@mui/material/Fab';
import Snackbar from '@mui/material/Snackbar';
import AutorenewSharpIcon from '@mui/icons-material/AutorenewSharp';
import RefreshIcon from '@mui/icons-material/Refresh';
import CachedIcon from '@mui/icons-material/Cached';

// Data
import {CrewProfiles} from "../../generated/graphql";
import {getDatabase, GraphQLReplicator, MigrationHandler} from "../../rxdb";

// Utils
import {useAuth} from "../auth";
import {logger} from "../../helpers/logger";
import {RouteMapModule} from "../../routes";

interface ReplicationResponse {
  name: string;
  error: any;
  loading: boolean;
  completed: boolean;
}

const ReplicationProvider: FC<any> = ({ children }) => {
  const { token, user, setIsSynced, resetApplication, refreshApplication } = useAuth();
  const location = useLocation();
  const replicationRef = useRef<GraphQLReplicator>();
  // TODO: Revisit me. Maybe it should be stored somehwere.
  const progressRef = useRef({});
  const migrationRef: any = useRef({});

  const [progress, setProgress] = useState<{ [key: string]: any }>({});
  const [migrationProcess, setMigrationProgress] = useState<{ [key: string]: any }>({});
  const [titles, setTitles] = useState<{ [key: string]: any }>({});
  const [collections, setCollections] = useState<string[]>([]);
  const [isLeader, setLeader] = useState<boolean>(false);
  const [isMigrationProgress, setIsMigrationProgress] = useState<boolean>(false);
  const [isMigrated, setIsMigrated] = useState<boolean>(false);
  const [migModalOpen, setMigModalOpen] = useState(true);
  const [syncModalOpen, setSyncModalOpen] = useState(false);
  const [syncFailed, setSyncFailed] = useState(false);

  const initMigration = async () => {
    const database = await getDatabase();
    const migrationsHandler = new MigrationHandler(database);
    setTitles(
      fromPairs(
        map(database.collections, (val, key) => [
          key,
          val.schema.jsonSchema.title,
        ]),
      ),
    );

    setCollections(
      map(database.collections, (val, key) => key).sort((a, b) => a.localeCompare(b)),
    );

    setIsMigrationProgress(true);

    await migrationsHandler.start((response: any) => {
      setMigrationProgress({
        ...migrationRef.current,
        [response.name]: response,
      });

      migrationRef.current = {
        ...migrationRef.current,
        [response.name]: response,
      };
    })
  }

  const init = async (token: string, user: CrewProfiles | null) => {
    const database = await getDatabase();
    const replication = new GraphQLReplicator(database);

    await database.waitForLeadership();
    setLeader(true);

    setTitles(
      fromPairs(
        map(database.collections, (val, key) => [
          key,
          val.schema.jsonSchema.title,
        ]),
      ),
    );
    // Find all collection names
    setCollections(
      map(database.collections, (val, key) => key).sort((a, b) => a.localeCompare(b)),
    );
    const initialProgress = map(database
      .collections, (v) => v.name)
      .reduce<any>((acc, name) => {
          acc[name] = { name, completed: false, loading: false };

          return acc;
        },
        {});

    setProgress(initialProgress);
    progressRef.current = initialProgress;
    replicationRef.current = replication;

    replication.start(token, user, (response: ReplicationResponse) => {
      setProgress({
        ...progressRef.current,
        [response.name]: response,
      });

      progressRef.current = {
        ...progressRef.current,
        [response.name]: response,
      };
    });
    // setReplicationInProcess(false);
    setIsSynced(true);
    logger('ReplicationProvider').info('replication ended');
  };

  const handleCheckMigration = () => {
    let synced = true;

    collections.forEach((item: any) => {
      const flag: any = migrationProcess[item];
      if (!flag?.completed) {
        synced = false
      }
    })

    if (synced && collections.length > 0) {
      setIsMigrationProgress(false)
      setIsMigrated(true)
    }
  }

  useEffect(() => {
    if (!isNil(token) && !isNil(user)) {
      // Start replication. Pull all data from server to our local Database;
      if (isMigrated) {
        init(token, user);
      } else {
        initMigration();
      }
    }

    return () => {
      // Stop replication once we leave authorized view.
      if (replicationRef.current instanceof GraphQLReplicator) {
        replicationRef.current.stop();
      }
    };
  }, [token, user, isMigrated]);

  useEffect(() => {
    handleCheckMigration();
  }, [migrationProcess]);

  useEffect(() => {
    // Check for errors in the progress state
    let errorFound = false;
    for (const key in progress) {
      if (progress[key]?.error) {
        errorFound = true;
        break;
      }
    }
    // Update the syncFailed state based on whether an error was found
    setSyncFailed(errorFound);
  }, [progress]);

  // Reset syncFailed state when collections or migrationProcess changes
  useEffect(() => {
    setSyncFailed(false);
  }, [collections, migrationProcess]);

  const handleDetail = () => {
    setSyncModalOpen(true);
  }

  const renderMigrateItem = (collection: string) => {
    const item = migrationProcess[collection];
    const title = titles[collection];
    return (
      <ReplicationProgressItem
        key={`${collection}-migration-item`}
        title={`Migrate ${title}...`}
        completed={item?.completed || false}
        loading={item?.loading || false}
        failed={item?.failed || false}
      />
    );
  };

  const renderProgressItem = (collection: string) => {
    const item = progress[collection];
    const title = titles[collection];

    return (
      <ReplicationProgressItem
        key={`${collection}-progress-item`}
        title={`Sync ${title}...`}
        completed={item?.completed || false}
        loading={item?.loading || false}
        failed={item?.failed || false}
      />
    );
  };

  const getModuleName = () => {
    const pathname: any = location.pathname.split("/")[1];
    if (RouteMapModule[pathname]) {
      return {
        value: progress[RouteMapModule[pathname]]?.loading,
        label: RouteMapModule[pathname]
      }
    }
    return {
      value: false,
      label: ""
    }
  }

  const currentModule = getModuleName()

  const isReplicationInProcess = isLeader && (isEmpty(progress) || !!size(filter(progress, (v, k) => !v.completed)));

  const FabButtonSx = {
    bgcolor: syncFailed ? red[500] : green[500],
    '&:hover': {
      bgcolor: syncFailed ? red[700] : green[700],
    },
  };

  const CircularProgressSx = {
    color: syncFailed ? red[500] : green[500],
    position: 'absolute',
    top: -3.8,
    left: -4,
    zIndex: 1,
  };

  const snackBarAction = (
    <Stack direction="row" spacing={2}>
      <Button 
        variant="outlined" 
        size="small" 
        onClick={refreshApplication}
        startIcon={<RefreshIcon/>}
      >
        REFRESH
      </Button>
      <Button 
        variant="outlined" 
        size="small" 
        onClick={resetApplication}
        startIcon={<CachedIcon/>}
      >
        RESET
      </Button>
    </Stack>
  );
  
  return (
    <ReplicationContext.Provider value={{}}>
      {children}

      <Dialog
        fullWidth
        maxWidth="md"
        open={!isEmpty(collections) && isMigrationProgress && migModalOpen}
        scroll="paper"
        onClose={() => { }}
      >
        <Grid container spacing={4} className="p-8 relative">
          <div className="absolute top-8 right-2 cursor-pointer" onClick={() => setMigModalOpen(false)}><Close /></div>
          {collections.map(renderMigrateItem)}
        </Grid>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="md"
        open={!isEmpty(collections) && isReplicationInProcess && syncModalOpen}
        scroll="paper"
        onClose={() => { }}
      >
        <Grid container spacing={4} className="p-8 relative">
          <div className="absolute top-8 right-2 cursor-pointer" onClick={() => setSyncModalOpen(false)}><Close /></div>
          {collections.map(renderProgressItem)}
        </Grid>
      </Dialog>
      {isReplicationInProcess &&
        <Box sx={{ m: 1, position: 'relative' }}>
            <Fab
              aria-label="save"
              size="small"
              onClick={handleDetail}
              sx={FabButtonSx}
            >
          {syncFailed ? <Close fontSize="small" /> : <AutorenewSharpIcon fontSize="medium"/>}
          </Fab>
          <CircularProgress
            size={48}
            sx={CircularProgressSx}
          />
        </Box>
      }

      <Snackbar
        open={syncFailed} 
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
      >
        <Alert severity="error" action={snackBarAction}>
          YMS encountered a sync issue. Try to Refresh; if unsuccessful, perform a Reset.
        </Alert>
      </Snackbar>

      {currentModule?.value && (
        <Snackbar
          open={isReplicationInProcess} 
          anchorOrigin={{ vertical:'top', horizontal:'center' }} 
        >
          <Alert severity="info" className="mb-2" sx={{ textTransform: 'capitalize' }}>
            {currentModule.label} Sync is still in progress.
          </Alert>
        </Snackbar>
      )}
    </ReplicationContext.Provider>
  );
};

export default ReplicationProvider;
