import React, {
  FC, useEffect, useRef, useState,
} from 'react';
import { WorkIssuesDocument } from 'src/rxdb/collections/WorkIssues/rxdb';
import { Alert, Snackbar } from '@mui/material';
import { isNil } from 'lodash';
import { useForm } from 'react-hook-form';
import { EquipmentDocument } from 'src/pages/EquipmentPage/rxdb';
import DatePicker from '../../../UI/Forms/DatePicker';
import { getDatabase } from '../../../../rxdb';
import GenericModal from '../../../UI/GenericModal';
import { useAuth } from 'src/contexts/auth';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { normalizeDateTime } from 'src/helpers';
import { isValidDateFormat } from 'src/utils/format-dates';

interface CompleteExpireEquipmentTaskModalProps {
  issue: WorkIssuesDocument;
  visible: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const CompleteExpireEquipmentTaskModal: FC<CompleteExpireEquipmentTaskModalProps> = ({
  issue, visible, onClose, onSubmit,
}) => {
  const formRef = useRef<HTMLFormElement | null>(null);
  const { control, handleSubmit, reset } = useForm<any>({
    defaultValues: {
      fldExpireDate: new Date(),
    },
  });

  const { user } = useAuth();
  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [processing, setProcessing] = useState<boolean>(false);
  const [equipment, setEquipment] = useState<EquipmentDocument>();
  const [isEquipmentExistsInBin, setIsEquipmentExistsInBin] = useState<boolean>(false);

  const canUpdateExpiration = !isNil(equipment) && !isEquipmentExistsInBin;

  useEffect(() => {
    let isMounted = true; // Flag to track whether the component is mounted
  
    const init = async () => {
      try {
        const db = await getDatabase();
  
        const equipment = await issue.populate('EqKey');
  
        const bin = await db.tblrecyclebin
          .findOne({ selector: { fldRecordKey: equipment.EqKey } })
          .exec();
  
        if (isMounted) {
          setIsEquipmentExistsInBin(!isNil(bin));
          setEquipment(equipment);
          setLoading(false);
  
          reset({
            fldExpireDate: equipment?.fldExpireDate
              ? moment(equipment?.fldExpireDate).format()
              : new Date().toISOString(),
          });
        }
      } catch (e) {
        if (isMounted) {
          setLoading(false);
        }
      }
    };
  
    init(); // Call the init function
  
    return () => {
      isMounted = false; // Set the flag to false when unmounting to cancel async tasks
    };
  }, [issue?.JobNumber]);

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const onUpdateExpirationDateSubmit = async (data: any) => {
    const { fldExpireDate } = data;

    const db = await getDatabase();
    const initialValue = await issue.toJSON();
    const logEntryDocument = {
      LogDate: normalizeDateTime(new Date()),
      LogEntry: 'Equipment expiration updated.',
      fldHTML: 'Equipment expiration updated.',
      EqKey: initialValue?.EqKey || null,
      Equipment:equipment?.UniqueName,
      JobNumber: initialValue?.JobNumber,
      DayLogType: 2,
      fldPerformedBy: `${user?.fldFirst} ${user?.fldLast}`,
      fldEnteredBy:`${user?.fldFirst} ${user?.fldLast}`,
      Department: initialValue?.Department || null,
      fldCrewID: user?.fldCrewID,
      fldNoView:false,
      fldRestricted: false,
      fldIsWarranty: false,
      fldLocHierarchy: initialValue.fldLocHierarchy || null,
      fldSRHKey: initialValue.fldSRHKey || null,
      fldIsCheckList: false,
      fldWorkList: initialValue?.fldWorkList,
      PKey: uuid(),
      updatedAt: new Date().toISOString(),
    } as any

    try {
      await equipment?.atomicPatch({
        fldExpireDate: normalizeDateTime(fldExpireDate),
      });
  
      // TODO: Seems like i need to complete task?
      await issue?.atomicPatch({
        Completed: true,
        fldStatus: "Completed",
        PercentComplete: 100,
        DateCompleted: normalizeDateTime(new Date()),
      });

      await db.collections.logentry.upsert(logEntryDocument);

      setProcessing(false);
      setSnackbar({
        open: true,
        type: 'success',
        message: 'Success!',
      });
      onSubmit();
    } catch (e: any) {
      setProcessing(false);
      setSnackbar({
        open: true,
        type: 'error',
        message: e.message,
      });
    }
  };
  const onOkClick = async () => {
    setProcessing(true);
    // In this case submit form
    if (canUpdateExpiration) {
      // Submit form from outside
      formRef.current?.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true }),
      );
      return;
    }

    // Reset to null
    try {
      await equipment?.atomicPatch({
        fldExpireDate: null,
      });
      setProcessing(false);
      setSnackbar({
        open: true,
        type: 'success',
        message: 'Success!',
      });
      onSubmit();
    } catch (e: any) {
      setProcessing(false);
      setSnackbar({
        open: true,
        type: 'error',
        message: e.message,
      });
    }
  };
  const onCloseClick = () => {
    reset();
    onClose();
  };

  const cancelContent = () => (
    <div>
      <div className="mb-10">
        The
        {' '}
        {issue.Equipment}
        {' '}
        has been deleted and Expiration Date will be set
        to NULL. Do you want to cancel?
      </div>
    </div>
  );
  const optionsContent = () => (
    <form
      ref={formRef}
      onSubmit={(e) => {
        e.stopPropagation(); // Make sure parent form not submitted
        handleSubmit(onUpdateExpirationDateSubmit)(e);
      }}
    >
      <div className="mb-5">
        <p>
          Please provide a new date for the expiration of
          {' '}
          {issue.Equipment}
          .
          If you leave this blank, then the expiration date for
          {' '}
          {issue.Equipment}
          {' '}
          will be cleared
        </p>

        <div className="mt-8 w-full flex justify-center">
          <DatePicker
            name="fldExpireDate"
            control={control}
            label="New expiration date"
            rules={{
              validate: (value) => isValidDateFormat(value) || 'Please enter a valid date format dd-MMM-yyyy',
            }}
          />
        </div>
      </div>
    </form>
  );

  const displayContent = () => {
    if (loading) return null;

    if (canUpdateExpiration) return optionsContent();

    return cancelContent();
  };

  return (
    <>
      <GenericModal
        okButtonProps={{
          variant: 'contained',
          loading: processing,
        }}
        maxWidth="sm"
        onCancel={onCloseClick}
        onOk={onOkClick}
        okText={canUpdateExpiration ? 'Reset' : 'Yes'}
        cancelText={canUpdateExpiration ? 'Dismiss' : 'No'}
        visible={visible}
        title={`Reset ${issue.Equipment} Expiration`}
      >
        {displayContent()}
      </GenericModal>

      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        onClose={onSnackbarClose}
      >
        <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
          {snackBar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CompleteExpireEquipmentTaskModal;
