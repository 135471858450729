import { omit } from 'lodash';
import { TblDocumentation } from '../../../generated/graphql';
import {logger} from "../../../helpers/logger";

const fields = `
  DateEntered
  DocTitle
  PKey
  Received
  fldAddedBy
  fldDescription
  fldCompleteReqd
  fldDocFigure
  fldDocPage
  fldFileType
  fldLibtype
  fldNumber
  fldPassword
  fldSMSReport
  fldSRHKey
  fldShowInCentral
  fldStatus
  fldSpecRef
  fldStatusInternal
  fldTOC
  fldVerifiedReq
  deletedAt
  updatedAt
  deletedBy
  isRecoverable
  autoId
`;

export const tblDocumentationQuery = (doc: TblDocumentation) => {
  if (!doc) {
    doc = {
      autoId: 0,
      PKey: '',
      DocTitle: '',
      updatedAt: new Date(0).toISOString(),
    };
  }
  logger('tblDocumentation').info(
    `pull query triggered - tblDocumentation: { ${new Date().toISOString()} -  ID: ${doc.autoId} - updatedAt: ${doc.updatedAt} }`,
  );
  return {
    query: `
    query GetLatestTblDocumentation ($autoId: Int, $filterDate: Date){
      tblDocumentation(
        where:{ 
          _and: [
            {
              _or: [
                {
                  isRecoverable: {
                    _is_null: true
                  }
                },
                {
                  isRecoverable: {
                    _eq: true
                  }
                }
              ]
            },
            {
              _or: [
                {
                  updatedAt: {_gt: $filterDate},                
                },
                {
                  autoId: {_gt: $autoId}
                  updatedAt: {_gte: $filterDate},
                }
              ]
            }
          ]                                    
        },
        order_by: [
          {updatedAt: asc},
          {autoId: asc}
        ],
        limit: 1000
        ) 
        {
          ${fields}
      }
    }
  `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
    },
  };
};

export const tblDocumentationMutation = (doc: any) => {
  const query = `
        mutation upsertDocumentation($input: DocumentationInput!) {
          upsertDocumentation(input: $input) {
            ${fields}
          }
        }
    `;
  const variables = {
    input: omit(doc, ['deletedAt', 'updatedAt', 'tblDocumentation']),
  };

  return {
    query,
    variables,
  };
};

export const tblDocumentationSubscription = `
  subscription onTblDocumentationChanged {
    tblDocumentation {
      ${fields}
    }
  }
`;
