import { Alert } from "@mui/material";
import { split, last, lowerCase } from "lodash";
import { forwardRef } from "react";
import { useParams } from "react-router-dom";
import { AnyReportDocument } from "src/rxdb/collections/AnyReports/schema";
import FileViewerExcel from "./FileViewerExcel";
import FileViewerPdf from "./FileViewerPdf";
import FileViewerTxt from "./FileViewerTxt";
import FileViewerDocx from "./FileViewerDocx";
import { DocumentRevisionDocument } from "src/rxdb/collections/DocumentRevisions/schema";
import { AnyReportHistoryDocument } from "src/rxdb/collections/AnyReportsHistory/schema";

interface FileViewerRef {
  save: () => void;
}

interface FileViewerProps {
  url: string;
  report: AnyReportDocument;
  documentRevision?: DocumentRevisionDocument | null;
  reportHistory?: AnyReportHistoryDocument;
}

const FileViewer = forwardRef<FileViewerRef, FileViewerProps>(
  ({ url, report, documentRevision, reportHistory }, ref) => {
    return (
      <div className="w-full h-full" data-testid="file-viewer">
        <FileTypeSwitch url={url} ref={ref} report={report} documentRevision={documentRevision} reportHistory={reportHistory} />
      </div>
    );
  }
);

const FileTypeSwitch = forwardRef<FileViewerRef, FileViewerProps>(
  ({ url, report, documentRevision, reportHistory }, ref) => {
    const ext = lowerCase(last(split(url, ".")));
    switch (ext) {
      case "pdf":
        return <FileViewerPdf url={url} />;
      case ".xlsm":
      case "xltm":
      case "xltx":
      case "xlt":
      case "xlsx":
      case "xls":
        return <FileViewerExcel url={url} ref={ref} report={report} />;
      case "docx":
        return <FileViewerDocx url={url} ref={ref} report={report} documentRevision={documentRevision} reportHistory={reportHistory}/>;
      case "txt":
        return <FileViewerTxt url={url} />;
      default:
        return (
          <Alert severity="error">
            Unsupported file type:{" "}
            <a target="_blank" href={url}>
              Download here
            </a>
          </Alert>
        );
    }
  }
);

export default FileViewer;

export const FileViewerPage = () => {
  const { filename } = useParams<{ filename: string }>();
  const report = {} as AnyReportDocument;
  const documentRevision = {} as DocumentRevisionDocument;
  if (!filename) return <></>;
  return <FileViewer url={`/test-files/${filename}`} report={report} documentRevision={documentRevision} />;
};
