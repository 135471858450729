import { omit } from 'lodash';
import { TblRlData } from '../../../generated/graphql';
import {logger} from "../../../helpers/logger";

const fields = `
PKey
fldCrewId
FKey
fldYMSTakenAt
readings
deletedAt
deletedBy
isRecoverable
updatedAt
autoId
`;

export const tblRlDataPullQuery = (doc: TblRlData) => {
  if (!doc) {
    doc = {
      autoId: 0,
      PKey: '',
      FKey: '',
      fldCrewId: '',
      fldYMSTakenAt: 0,
      readings: '',
      deletedAt: '',
      updatedAt: new Date(0).toISOString(),
    };
  }
  logger('TblRlData').info(
    `pull query triggered - TblRlData:  { ${new Date().toISOString()} - ID: ${doc.autoId}}`,
  );
  return {
    query: `
        query getTblRlData($autoId: Int, $filterDate: Date) {
          tblRLData(
            where:{
              _and: [
                {
                  _or: [
                    {
                      isRecoverable: {
                        _is_null: true
                      }
                    },
                    {
                      isRecoverable: {
                        _eq: true
                      }
                    }
                  ]
                },
                {
                  _or: [
                    {
                      updatedAt: {_gt: $filterDate},                
                    },
                    {
                      autoId: {_gt: $autoId}
                      updatedAt: {_gte: $filterDate},
                    }
                  ]
                }
              ]                                    
            },
            order_by: [
              {updatedAt: asc},
              {autoId: asc}
            ],
              limit: 500
          ) {
            ${fields}            
          }
        }
      `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
    },
  };
};

export const tblRlDataMutation = (doc: any) => {
  const query = `
    mutation insertTblRlData($input: TblRlDataUpsertInput!) {
      upsertTblRlData(input: $input) {
        ${fields}
      }
    }
  `;

  const variables = {
    input: omit(doc, ['updatedAt', 'deletedAt', 'id']),
  };

  return {
    query,
    variables,
  };
};

export const tblRlDataSubscription = `
  subscription onTblRlDataChanged {
    tblRLData {
      ${fields}
    }
  }
`;
