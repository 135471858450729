import React, {
  useState,
  FC,
  useRef,
  useCallback,
  MutableRefObject,
} from 'react';
import {
  isEmpty, pick, size, isNil,
} from 'lodash';
import moment from 'moment';
import { Alert, Snackbar, useMediaQuery } from '@mui/material';
import { getDatabase, TDIDb } from 'src/rxdb';
import {
  TypeComputedProps,
  TypeSortInfo,
} from '@inovua/reactdatagrid-enterprise/types';
import LicensedReactDataGrid from 'src/components/UI/LicensedReactDataGrid';
import './style.css'

interface Props {
  keyValue: string | null;
}

const filter = [
  {
    name: 'Supplier',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'OrderName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
];

const transformData = async (item: any) => ({
  ...pick(item, ['PKey', 'OrderID', 'Amount', 'UnitPrice', 'ProductID']),
  Supplier: (await item.populate('OrderID'))?.Supplier,
  OrderDate: (await item.populate('OrderID'))?.OrderDate,
  OrderName: (await item.populate('OrderID'))?.OrderName,
  original: item,
});

const getRegexByOperator = (operator: string, value: string) => {
  switch (operator) {
    case 'contains':
      return new RegExp(`.*${value}`, 'i');
    case 'startsWith':
      return new RegExp(`^${value}`, 'i');
    case 'endsWith':
      return new RegExp(`${value}$`, 'i');
    case 'eq':
      return new RegExp(`${value}`, 'i');
    default:
  }
};

const getSelectorByFilterName = async (
  name: string,
  value: string,
  operator: string,
  db: TDIDb,
) => {
  switch (name) {
    case 'Supplier':
      const supplierName = await db.orders
        .find({
          selector: {
            Supplier: { $regex: getRegexByOperator(operator, value) },
          },
        })
        .exec();
      return {
        OrderID: { $in: supplierName.map((e) => e.primary) },
      };

    case 'OrderName':
      const ordersName = await db.orders
        .find({
          selector: {
            OrderName: { $regex: getRegexByOperator(operator, value) },
          },
        })
        .exec();
      return {
        OrderID: { $in: ordersName.map((e) => e.primary) },
      };

    default:
      return {};
  }
};

const InvOrderHistory: FC<Props> = ({ keyValue }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const highlightedRowId = useRef<string>('-1');
  const [gridRef, setGridRef] = useState<any>(null);
  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });
  const isTablet = useMediaQuery('(min-width: 700px) and (max-width: 1200px)');
  const isMobile = useMediaQuery('(max-width: 420px)');

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const loadData = async ({
    skip,
    limit,
    filterValue,
  }: {
    sortInfo?: TypeSortInfo;
    skip?: number;
    limit?: number;
    filterValue?: any;
  }): Promise<{ data: any[]; count: number }> => {
    const db = await getDatabase();

    let selector = {
      ProductID: keyValue,
    };

    await Promise.all(
      filterValue.map(async (v: any) => {
        if (isEmpty(v.value)) return v;

        const s = await getSelectorByFilterName(
          v.name,
          v.value,
          v.operator,
          db,
        );

        selector = {
          ...selector,
          ...s,
        };
        return v;
      }),
    );

    const items = await db.orderitems
      .find({
        selector,
        skip,
        limit,
      })
      .exec();

    const length = size(items);

    const data = await Promise.all(items.map(transformData));

    return { data, count: length };
  };

  const init = async (ref: MutableRefObject<TypeComputedProps | null>) => {
    const db = await getDatabase();

    db.orderitems.$.subscribe(async (ev) => {
      if (ev.operation === 'INSERT' || ev.operation === 'UPDATE') {
        highlightedRowId.current = ev.documentId;
        ref.current?.reload();
      }
      if (ev.operation==='DELETE') {
        highlightedRowId.current = '-1';
        ref.current?.reload();
      }
    });
  };

  const onReady = (ref: MutableRefObject<TypeComputedProps | null>) => {
    setGridRef(ref);
    init(ref);
  };

  const onLoadingChange = (status: boolean) => {
    // If loading completed - check if there any items that needs to be highlighted.
    if (!status && highlightedRowId.current !== '-1') {
      gridRef?.current?.scrollToId(highlightedRowId.current);
      gridRef?.current?.setSelectedById(highlightedRowId.current, true);
      highlightedRowId.current = '-1';
    }
    setLoading(status);
  };

  const dataSource = useCallback(loadData, []);

  const columns = [
    {
      name: 'Supplier',
      header: 'Supplier Name',
      defaultFlex: 1,
    },
    {
      name: 'OrderName',
      header: 'Order Name',
      defaultFlex: 0.75,
      visible: !isMobile,
    },
    {
      name: 'OrderDate',
      header: 'Order Date',
      dateFormat: 'DD-MMM-YYYY',
      defaultFlex: 0.75,
      visible: !(isTablet || isMobile),
      render: ({ value, cellProps: { dateFormat } }: any) => {
        if (!isNil(value)) {
          return moment(value).format(dateFormat);
        }
      },
    },
    {
      name: 'Amount',
      header: 'Amount',
      defaultFlex: 0.5,
      headerAlign: 'start' as any,
      textAlign: 'end' as any,
    },
    {
      name: 'UnitPrice',
      header: 'Unit Price',
      defaultFlex: 0.5,
      visible: !(isTablet || isMobile),
    },
  ];

  return (
    <>
      <div className="pt-5">
        {/* <div className="flex justify-end mb-6">
            <Button variant="contained" onClick={handleAddClick}>
              Add
            </Button>
          </div> */}

        <div data-testid="data-grid" className="flex flex-col flex-grow inv-order-history">
          <LicensedReactDataGrid
            onLoadingChange={onLoadingChange}
            defaultLimit={100}
            livePagination
            onReady={onReady}
            rowHeight={40}
            loading={loading}
            defaultFilterValue={filter}
            idProperty="PKey"
            // theme="default-dark"
            columns={columns}
            dataSource={dataSource}
          />
        </div>
      </div>

      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        onClose={onSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
          {snackBar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default InvOrderHistory;
