import { omit } from 'lodash';
import { Inventory } from '../../generated/graphql';
import {logger} from "../../helpers/logger";

const fields = `
  Bonded
  DateEntered
  Department
  Manufacturer
  ModelNumber
  NoView
  PowerRequired
  ProductDescription
  ProductID
  ProductName
  Qty
  ReOrder
  ReOrderAmt
  ReOrderLevel
  SearchMaker
  SearchPart
  SearchMod
  UnitsOnOrder
  Weight
  WeightQty
  fldColor
  fldConsumable
  fldCountry
  fldDataType
  fldFactor
  fldGuestType
  fldLLBC
  fldOrderAmt
  fldOrderQty
  fldPartNumber
  fldRegion
  fldRx
  fldSMS
  fldSRHKey
  fldSize
  fldUseQty
  deletedAt
  updatedAt
  deletedBy
  isRecoverable
  autoId
`;

export const inventoryQuery = (doc: Inventory) => {
  if (!doc) {
    doc = {
      autoId: 0,
      ProductID: '',
      ProductName: '',
      updatedAt: new Date(0).toISOString(),
    };
  }
  logger('Inventory').info(
    `pull query triggered - Inventory: { ${new Date().toISOString()} - ID: ${doc.autoId}, updatedAt: ${doc.updatedAt} }`,
  );
  return {
    query: `
      query GetLatestInventory($autoId: Int, $filterDate: Date) {
        Inventory(
          where:{ 
            _and: [
              {
                _or: [
                  {
                    isRecoverable: {
                      _is_null: true
                    }
                  },
                  {
                    isRecoverable: {
                      _eq: true
                    }
                  }
                ]
              },
              {
                _or: [
                  {
                    updatedAt: {_gt: $filterDate},                
                  },
                  {
                    autoId: {_gt: $autoId}
                    updatedAt: {_gte: $filterDate},
                  }
                ]   
              }
            ]                                 
          },
          order_by: [           
            {updatedAt: asc},
            {autoId: asc}
          ],
          limit: 1000
        ) {
          ${fields}          
        }
      }
    `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
    },
  };
};

export const inventoryMutation = (doc: any) => {
  const query = `
    mutation InsertInventory($input: InventoryUpsertInput!) {
      upsertInventory(input: $input) {
        ${fields}
      }
    }
  `;

  const variables = {
    input: omit(doc, ['updatedAt', 'deletedAt', 'accessDate']),
  };

  return {
    query,
    variables,
  };
};

export const inventorySubscription = `
  subscription onInventoryChanged {
    Inventory {
     ${fields}      
    }
  }
`;
