import { RxCollection, RxJsonSchema, RxDocument } from 'rxdb';

import { Equipment } from '../../generated/graphql';

export type EquipmentDocType = Equipment;

export type EquipmentDocMethods = {};

export type EquipmentDocument = RxDocument<
  EquipmentDocType,
  EquipmentDocMethods
>;

export type EquipmentCollectionMethods = {};

export type EquipmentCollection = RxCollection<
  EquipmentDocType,
  EquipmentDocMethods,
  EquipmentCollectionMethods
>;

export const equipmentIndexes = ["UniqueName", "Department"]
// , "Manufacturer", "ModelNumber", "Rating", "PowerRequired", "Notes", "Voltage", "Department", "Qty", "WeightQty", "SearchMod", "SearchMaker", "SerialNum", "ArtNumber", "ArrangementNum", "DrawingRef", "DistBoard", "Supplier"];

export const equipmentSchema: RxJsonSchema<EquipmentDocType> = {
  title: 'equipment ',
  description: 'describes a equipment item',
  version: 2,
  keyCompression: false,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    Amount: {
      type: ['number', 'null'],
    },
    ArrangementNum: {
      type: ['string', 'null'],
    },
    ArtNumber: {
      type: ['string', 'null'],
    },
    Breaker: {
      type: ['string', 'null'],
    },
    CircuitNumber: {
      type: ['string', 'null'],
    },
    DateEntered: {
      type: ['string', 'null'],
    },
    Department: {
      type: ['string', 'null'],
    },
    DistBoard: {
      type: ['string', 'null'],
    },
    DrawingRef: {
      type: ['string', 'null'],
    },
    EqKey: {
      type: 'string',
      primary: true,
    },
    Hours: {
      type: ['number', 'null'],
    },
    Manufacturer: {
      type: ['string', 'null'],
    },
    ModelNumber: {
      type: ['string', 'null'],
    },
    Name: {
      type: ['string', 'null'],
    },
    Notes: {
      type: ['string', 'null'],
    },
    PowerRequired: {
      type: ['string', 'null'],
    },
    Qty: {
      type: ['string', 'null'],
    },
    Rating: {
      type: ['string', 'null'],
    },
    SearchMaker: {
      type: ['string', 'null'],
    },
    SearchMod: {
      type: ['string', 'null'],
    },
    SerialNum: {
      type: ['string', 'null'],
    },
    Supplier: {
      type: ['string', 'null'],
    },
    UniqueName: {
      type: 'string',
    },
    Voltage: {
      type: ['string', 'null'],
    },
    Weight: {
      type: ['number', 'null'],
    },
    WeightQty: {
      type: ['string', 'null'],
    },
    fldCountHours: {
      type: ['boolean', 'null'],
    },
    fldDateReset: {
      type: ['string', 'null'],
    },
    fldDay: {
      type: ['number', 'null'],
    },
    fldExpireDate: {
      type: ['string', 'null'],
    },
    fldHoursKey: {
      type: ['string', 'null'],
    },
    fldHoursOffset: {
      type: ['number', 'null'],
    },
    fldHrsEst: {
      type: ['number', 'null'],
    },
    fldInService: {
      type: ['string', 'null'],
    },
    fldLocHierarchy: {
      type: ['string', 'null'],
      description: 'Locations foreign key',
      ref: 'tbllocationshierarchy',
    },
    fldMonth: {
      type: ['string', 'null'],
    },
    fldParent: {
      type: ['string', 'null'],
    },
    fldReportingType: {
      type: ['string', 'null'],
    },
    fldRestricted: {
      type: ['boolean', 'null'],
    },
    fldSMS: {
      type: ['boolean', 'null'],
    },
    fldYear: {
      type: ['string', 'null'],
    },
    fldSRHKey: {
      description: 'Categories foreign key. TblSystemHierarchy',
      type: ['string', 'null'],
      ref: 'tblsystemshierarchy',
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    updatedAt: {
      type: 'string',
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
  },
  indexes: ['UniqueName'],
  required: [],
  additionalProperties: false,
};

export const equipmentDocMethods: EquipmentDocMethods = {};

export const equipmentCollectionMethods: EquipmentCollectionMethods = {};
