import { isNil, size, last } from 'lodash';
import {FileExtensionType} from "../consts";
import {getDatabase} from "../rxdb";
import {MangoQuerySelector} from "rxdb/dist/types/types";
import {Subscription} from "rxjs";

export const getAttachmentCount = async (selector: MangoQuerySelector | undefined, onChange: (aCount: number, pCount: number) => void): Promise<Subscription> => {
  const db = await getDatabase();

  const updateCount = (results: any[]) => {
    const photos = size(
      results
        .filter((e) => e)
        .filter((extension) =>
          [
            FileExtensionType.JPEG,
            FileExtensionType.JPG,
            FileExtensionType.PNG,
            FileExtensionType.TIF,
            FileExtensionType.BMP,
          ].includes(extension!.toUpperCase() as any)
        )
    );

    const attachments = size(
      results
        .filter((e) => e)
        .filter(
          (extension) =>
            ![
              FileExtensionType.JPEG,
              FileExtensionType.JPG,
              FileExtensionType.PNG,
              FileExtensionType.TIF,
              FileExtensionType.BMP,
            ].includes(extension!.toUpperCase() as any)
        )
    );

    onChange(attachments, photos);
  };

  // Find schedules based on selector that passed outside
  return db.tbldocumentcrossreference
    .find({
      selector: selector || {},
    })
    .$
    .subscribe(async (attachments) => {
      const results = await Promise.all<string | undefined>(
        attachments.map(async (attachment) => {
          const revision = await db.documentrevision
            .findOne({
              selector: {
                fldFKey: attachment.fldFKey,
              },
            })
            .exec();

          if (revision && isNil(revision.deletedAt)) {
            return revision.fldFileName
              ? last(revision.fldFileName.split('.'))
              : undefined;
          }

          return undefined;
        })
      );

      updateCount(results);
    });
};
