import { omit } from 'lodash';
import { TblRunningLog } from '../../../generated/graphql';
import {logger} from "../../../helpers/logger";

const fields = `
  PKey
  fldYMSLogDate
  fldLogTime
  fldTimeZone
  fldVesselSpeed
  fldApproxLocation
  fldComment
  deletedAt
  isRecoverable
  deletedBy
  updatedAt
  autoId
`;

export const tblRunningLogPullQuery = (doc: TblRunningLog) => {
  if (!doc) {
    doc = {
      autoId: 0,
      PKey: '',
      fldYMSLogDate: 0,
      updatedAt: new Date(0).toISOString(),
    };
  }
  logger('TblRunningLog').info(
    `pull query triggered - TblRunningLog:  { ${new Date().toISOString()} - ID: ${doc.autoId}}`,
  );
  return {
    query: `
        query getTblRunningLog($autoId: Int, $filterDate: Date) {
          tblRunningLog(
            where:{
              _and: [
                {
                  _or: [
                    {
                      isRecoverable: {
                        _is_null: true
                      }
                    },
                    {
                      isRecoverable: {
                        _eq: true
                      }
                    }
                  ]
                },
                {
                  _or: [
                    {
                      updatedAt: {_gt: $filterDate},                
                    },
                    {
                      autoId: {_gt: $autoId}
                      updatedAt: {_gte: $filterDate},
                    }
                  ]
                }
              ]                                    
            },
            order_by: [
              {updatedAt: asc},
              {autoId: asc}
            ],
              limit: 500
          ) {
            ${fields}            
          }
        }
      `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
    },
  };
};

export const tblRunningLogMutation = (doc: any) => {
  const query = `
    mutation insertTblRunningLog($input: TblRunningLogInput!) {
      upsertTblRunningLog(input: $input) {
        ${fields}
      }
    }
  `;

  const variables = {
    input: omit(doc, ['updatedAt', 'deletedAt', 'id']),
  };

  return {
    query,
    variables,
  };
};

export const tblRunningLogSubscription = `
  subscription onTblRunningLogChanged {
    tblRunningLog {
      ${fields}
    }
  }
`;
