import '../App.css';
import '../theme/styles.css';
import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Alert, Snackbar, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import background from '../assets/wallpaper.png';
import Logo from '../components/UI/Logo';

interface Props {
  tokenCallback: Function;
  postLogin: Function;
}

const Login = ({ tokenCallback, postLogin }: Props) => {
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState<string>('');
  const { register, handleSubmit } = useForm();

  const isAuthenticationError = (e: any): boolean => {
    return e instanceof Response && e.status === 401;
  };

  const isNetworkError = (e: any): boolean => {
    return e instanceof TypeError && e.message === "Failed to fetch";
  };

  const handleLogin = async (data: any) => {
    try {
      const { token, user } = await postLogin(data.Username, data.Password);
      tokenCallback(token, user);
      if (token) {
        localStorage.setItem('token', token);
      }
    } catch (e) {
      let errorMessage = 'An error occurred.';
      if (isNetworkError(e)) {
        errorMessage = "If accessing an onboard instance of YMS, confirm you are connected to the vessel's network.\n";
        errorMessage += "If accessing an online instance of YMS, confirm you have access to the internet.\n";
        errorMessage += "Reach out to <a id='contactLink' href='mailto:support@yms360.com'>support@yms360.com</a> if you are still unable to login.";
      } else if (isAuthenticationError(e)) {
        errorMessage = "Unable to validate your username and password.\n";
        errorMessage += "If you have forgotten your password contact the onboard YMS Admin User.";
      }
      setMessage(errorMessage);
      setAlert(true);
    }
  };

  return (
    <div className="w-full h-full">
      <div
        className="min-h-screen flex items-center"
        style={{
          backgroundSize: 'cover',
          background: `url(${background}) no-repeat center/cover`,
        }}
      >
        <div className="bg-white lg:w-2/5 md:w-3/5 sm:w-4/5 w-4/5 mx-auto my-auto h-full p-8 justify-between flex flex-col">
          <div>
            <Logo className="flex justify-center text-primary-blue mb-6" />

            <div className="text-center mx-auto">
              <h1 className="TDImaintitle leading-none font-bold mb-4 mx-8">
                Welcome to Triton Yacht Management System!
              </h1>

              <p className="TDIsubtitle TDI-margin-bottom-big">
                The ONLY tool for your vessel's lifecycle
              </p>

              <p className="mb-4 text-2xl border-b-4 border-primary-blue pb-2 inline-block px-6">
                Log in
              </p>
            </div>
            <form onSubmit={handleSubmit(handleLogin)}>
              <div className="flex flex-col items-center mt-6 max-w-md mx-auto">
                <FormControl fullWidth>
                  <TextField
                    id="filled-username-input"
                    label="Username"
                    autoFocus
                    inputProps={{
                      // autocomplete:'off',
                      // autocorrect:'off',
                      autoCapitalize:'off',
                      spellCheck: 'false',
                    }}
                    // variant="filled"
                    {...register('Username', { required: true })}
                    className="mb-2 w-full"
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="filled-password-input"
                    label="Password"
                    type="password"
                    autoComplete="current-password"
                    // variant="filled"
                    {...register('Password', { required: true })}
                    className="mb-4 w-full"
                  />
                </FormControl>
                <Button
                  variant="contained"
                  size="large"
                  className="w-1/2"
                  type="submit"
                >
                  Log In
                </Button>
              </div>
            </form>
            <Snackbar
              open={alert}
              autoHideDuration={2000}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              onClose={() => setAlert(false)}
            >
              <Alert severity="error">
                <Typography variant="body2" component="div" sx={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: message }}/>
              </Alert>
            </Snackbar>
          </div>

          <footer className="text-center text-xs mt-6">
            Copyright© Triton Development Inc.
            {' '}
            {new Date().getFullYear()}
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Login;
