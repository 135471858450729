import React, {FC} from 'react';
import {IconButton} from "@mui/material";
import {DeleteTwoTone, Edit} from "@mui/icons-material";
import { CellProps } from '@inovua/reactdatagrid-enterprise/types';

interface InjectedProps extends CellProps {
  onEditClick: (data: any) => void;
  onDeleteClick: (data: any) => void;
  disabled?: boolean;
}

const GridRowActions: FC<InjectedProps> = ({ onEditClick, onDeleteClick, disabled, data }) => {
  const handleEditClick = () => onEditClick(data);
  const handleDeleteClick = () => onDeleteClick(data);

  return (
    <div className = "flex justify-center">
      {!disabled && (
        <IconButton
          onClick={handleEditClick}
          size="small"
          aria-label="Edit item"
         
        >
          <Edit fontSize="inherit" />
        </IconButton>
      )}

      {!disabled && (
        <IconButton
          onClick={handleDeleteClick}
          size="small"
          color="error"
          aria-label="Delete item"
          
        >
          <DeleteTwoTone fontSize="inherit" />
        </IconButton>
      )}
    </div>
  )
};

export default GridRowActions;
