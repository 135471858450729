import { FC } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Button,
  Box,
} from "@mui/material";
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import { LoadingButton } from "@mui/lab";

interface WarningDialogProps extends Partial<DialogProps> {
  visible: boolean;
  title: string;
  content: string;
  okText?: string;
  cancelText?: string;
  onOk?: () => void;
  onCancel: () => void;
  color?: any;
  disabled?: boolean;
  loading?: boolean;
}

const WarningDialog: FC<WarningDialogProps> = ({
  visible,
  title,
  content,
  cancelText = "Cancel",
  okText = "OK",
  color="error",
  onCancel,
  onOk,
  disabled = false,
  loading = false,
}) => {
  return (
    <Dialog
      open={visible}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="warning-dialog"
    >
      <DialogTitle id="alert-dialog-title">{(<WarningTwoToneIcon fontSize="small" color={color} className="mr-3"/>)}{title}</DialogTitle>
      <DialogContent>
      <DialogContentText 
        id="alert-dialog-description" 
        dangerouslySetInnerHTML={{ __html: content }} 
      />
      </DialogContent>
      <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
        {onOk && <Box sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}>
          {!disabled && 
          <LoadingButton loading={loading} data-testid="warning-dialog-confirm" className="mx-2" onClick={onOk}>
            {okText}
          </LoadingButton>}
        </Box>}
        <Button data-testid="warning-dialog-cancel" onClick={onCancel} variant="contained">{cancelText}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default WarningDialog;
