import { omit } from 'lodash';
import { TblGridSavedViews } from '../../../generated/graphql';
import {logger} from "../../../helpers/logger";

const fields = `
  PKey
  fldCrewID
  fldLayout
  fldModule
  fldViewName
  deletedAt
  updatedAt
  deletedBy
  isRecoverable
  autoId
`;

export const tblGridSavedViewsQuery = (doc: TblGridSavedViews) => {
  if (!doc) {
    doc = {
      autoId: 0,
      PKey: '',
      fldViewName: '',
      updatedAt: new Date(0).toISOString(),
    };
  }
  logger('TblGridSavedViews').info(
    `pull query triggered - TblGridSavedViews: { ${new Date().toISOString()} - ID: ${doc.autoId}, updatedAt: ${doc.updatedAt} }`,
  );
  return {
    query: `
      query GetLastestTblGridSavedViews($autoId: Int, $filterDate: Date) {
        tblGridSavedViews(
          where:{ 
            _and: [
              {
                _or: [
                  {
                    isRecoverable: {
                      _is_null: true
                    }
                  },
                  {
                    isRecoverable: {
                      _eq: true
                    }
                  }
                ]
              },
              {
                _or: [
                  {
                    updatedAt: {_gt: $filterDate},                
                  },
                  {
                    autoId: {_gt: $autoId}
                    updatedAt: {_gte: $filterDate},
                  }
                ]
              }
            ]                                    
          },
          order_by: [
              {updatedAt: asc},
              {autoId: asc}],          
          limit: 500
        ) {
            ${fields}
        }
      }
    `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
    },
  };
};

export const tblGridSavedViewsMutation = (doc: any) => {
  const query = `
    mutation InsertTblGridSavedViews($input: TblGridSavedViewsUpsertInput!) {
      upsertTblGridSavedViews(input: $input) {
        ${fields}
      }
    }
  `;

  const variables = {
    input: omit(doc, ['updatedAt', 'deletedAt']),
  };

  return {
    query,
    variables,
  };
};

export const tblGridSavedViewsSubscription = `
  subscription onTblGridSavedViewsChanged {
    tblGridSavedViews {
     ${fields}      
    }
  }
`;
