import { TblDl4CellRefs } from '../../../generated/graphql';
import {logger} from "../../../helpers/logger";

const fields = `
PKey
EqKey
deletedAt
deletedBy
isRecoverable
fldCell
fldDataType
fldTag
updatedAt
autoId
`;

export const tblDL4CellRefsPullQuery = (doc: TblDl4CellRefs) => {
  if (!doc) {
    doc = {
      autoId: 0,
      PKey: '',
      updatedAt: new Date(0).toISOString(),
    };
  }
  logger('tblDL4CellRefs').info(
    `pull query triggered - tblDL4CellRefs:  { ${new Date().toISOString()} - ID: ${doc.autoId
    }, updatedAt: ${doc.updatedAt}`,
  );
  return {
    query: `
    query getDL4CellRefs($autoId: Int,  $filterDate: Date) {
      tblDL4CellRefs(
        where:{ 
          _and: [
            {
              _or: [
                {
                  isRecoverable: {
                    _is_null: true
                  }
                },
                {
                  isRecoverable: {
                    _eq: true
                  }
                }
              ]
            },
            {
              _or: [
                {
                  updatedAt: {_gt: $filterDate},                
                },
                {
                  autoId: {_gt: $autoId}
                  updatedAt: {_gte: $filterDate},
                }
              ]
            }
          ]                                    
        },
          order_by: [
            {updatedAt: asc},
            {autoId: asc}
          ],
          limit: 500
        ) {
          ${fields}           
      }
   }
      `,
    variables: {
      autoId: doc?.autoId,
      filterDate: doc.updatedAt,
    },
  };
};
export const tblDL4CellRefsSubscription = `
  subscription onDl4CellRefsChanged {
    tblDL4CellRefs {
      ${fields}      
    }
  }
`;
