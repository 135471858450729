import { gql, useQuery } from '@apollo/client';
import { GroupCountOfTblLocationsHierarchy, LogEntryFilterInput } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';

export const GET_LOG_ENTRY_COUNT_BY_LOCATION = gql`
  query GetLogEntryCountsByLocation($filterInput: LogEntryFilterInput) {
    logEntryCountsByLocation(filterInput: $filterInput) {
      count
      item {
        fldDepts
        fldLocation
        fldNoView
        fldTags
        parentId
        pkey
      }
    }
  }
`;

interface LocationsResult {
  logEntryCountsByLocation: GroupCountOfTblLocationsHierarchy[];
  refetchLogEntryCountsByLocation: any;
  loading: boolean;
}

export const useGetLogEntryCountsByLocation = (filterInput: LogEntryFilterInput): LocationsResult => {
  const { data, loading, error, refetch } = useQuery(GET_LOG_ENTRY_COUNT_BY_LOCATION, {
    variables: { filterInput },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    logger('GetLogEntryCountsByLocation').error('Error fetching locations -->', error.message);
  }

  return {
    logEntryCountsByLocation: data?.logEntryCountsByLocation || [],
    refetchLogEntryCountsByLocation: refetch,
    loading,
  };
};
