import React, { useState, createContext } from 'react';

export type NavProps = {
  navigationData: any;
  setNavigationData: Function;
};
const NavigationContext = createContext<Partial<NavProps>>({});

const NavigationProvider = (props: any) => {
  const [navigationData, setNavigationData] = useState({});

  return (
    <NavigationContext.Provider
      value={{ navigationData, setNavigationData }}
      {...props}
    />
  );
};

export { NavigationProvider };
