import './styles.scss';
import React, { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@mui/material';
import SearchAppBar from '../MuiAppBar';
import Logo from '../../assets/icon-logo.svg';
import { appInfo } from '../../app-info';
import SidebarMenu from '../../components/UI/SidebarMenu';
import Footer from '../../components/Footer';
import UpdateAvailableReminder from "../../components/UpdateAvailableReminder";

const drawerWidth = 300;

const openedMixin = (theme: any) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: any) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `0`,
  [theme.breakpoints.up('sm')]: {
    width: `0`,
  },
  border: 0
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1.5, 1),
  color: 'white',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const StyledLogo = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 2),
  color: 'white',
  flexDirection: 'row',
  fontsize: 20,
  fontWeight: 500,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }: any) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const MuiSideNavBarLayout = ({ children }: any) => {
  const classes = useStyles();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (!isLargeScreen) {
      setOpen(false)
    }

    if (isLargeScreen) {
      setOpen(true)
    }
  }, [isLargeScreen])

  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleNavItemClick = () => {
    if (!isLargeScreen) return handleDrawerClose();
  };

  const renderDrawerContent = () => (
    <>
      <DrawerHeader>
        <StyledLogo>
          <img width={40} height={40} src={Logo} alt="Logo" />
          <div className="ml-4 text-xl font-medium	">{appInfo.title}</div>
        </StyledLogo>
        <IconButton
          onClick={handleDrawerClose}
          sx={{ color: (theme) => theme.palette.background.default }}
        >
          {theme.direction === 'rtl' ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>

      <SidebarMenu onClick={handleNavItemClick} />

      {open && (
        <footer className={classes.footer}>
          <Footer className="px-3 py-1 flex text-xs">
            Copyright © 2011-
            {new Date().getFullYear()}
            {' '}
            {appInfo.company}            
            {' '}
            <br />
            {`v${import.meta.env.PACKAGE_VERSION}`}
          </Footer>
        </footer>
      )}
    </>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <SearchAppBar open={open} handleDrawerOpen={handleDrawerOpen} />

      <MuiDrawer
        onClose={handleDrawerClose}
        variant="temporary"
        open={open}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { 
            boxSizing: 'border-box', 
            width: drawerWidth,
            // Scrollbar styles
            '&::-webkit-scrollbar': {
              width: 8,
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#CACACA',
              borderRadius: 5,
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#A8A8A8',
            },
          },
        }}
      >
        {renderDrawerContent()}
      </MuiDrawer>

      <Drawer
        sx={{
          display: { xs: 'none', md: 'block' },
          ...(open && {
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              '&::-webkit-scrollbar': {
                width: 8,
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#FFFFFF',
                borderRadius: 5,
                right: 2,
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#CACACA',
              },
            },
          }),
        }}
        variant="permanent"
        open={open}
      >
        {renderDrawerContent()}
      </Drawer>

      <Box component="main" className={`${classes.content} py-2 px-3 md:px-6`}>
        <DrawerHeader />
        <UpdateAvailableReminder className="mb-6" />
        {children}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  layout: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  footer: {
    width: '100%',
    // background: "red",
    boxShadow: 'none',
    textAlign: 'center',
    minHeight: '58px',
    alignItems: 'center',
    display: 'flex',
    margin: 'auto auto 0',
    color: 'white',
  },
  footerContent: {
    // border: "1px solid lime",
    // padding: "0 300px", // don't let sidebar hide footer content
    width: '100%',
    color: 'white',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    backgroundColor: 'white',
    flexGrow: 1,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  toolbar: theme.mixins.toolbar,
}));

export default MuiSideNavBarLayout;
