import { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';
import { TblRlMetaGrpsNeq } from '../../../generated/graphql';

export type TblRlMetaGrpsNeqDocType = TblRlMetaGrpsNeq;

export type TblRlMetaGrpsNeqDocMethods = {};

export type TblRlMetaGrpsNeqDocument = RxDocument<
  TblRlMetaGrpsNeqDocType,
  TblRlMetaGrpsNeqDocMethods
>;

export type TblRlMetaGrpsNeqCollectionMethods = {};

export type TblRlMetaGrpsNeqCollection = RxCollection<
  TblRlMetaGrpsNeqDocType,
  TblRlMetaGrpsNeqDocMethods,
  TblRlMetaGrpsNeqCollectionMethods
>;

//   DateEntered

export const schema: RxJsonSchema<TblRlMetaGrpsNeqDocType> = {
  keyCompression: false,
  title: 'TblRlMetaGrpsNeq ',
  description: 'TblRlMetaGrpsNeq',
  version: 1,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    PKey: {
      type: 'string',
      primary: true,
    },
    fldGrpIdx: {
      type: ['string', 'null'],
    },
    fldGrpCaption: {
      type: ['string', 'null'],
    },
    EqKey: {
      type: ['string', 'null'],
      ref: 'equipment',
    },
    fldRdgCaption: {
      type: ['string', 'null'],
    },
    fldDataType: {
      type: ['string', 'null'],
    },
    fldUnit: {
      type: ['string', 'null'],
    },
    fldRdgIdx: {
      type: ['number', 'null'],
    },
    fldDecPL: {
      type: ['number', 'null'],
    },
    fldMax: {
      type: ['string', 'null'],
    },
    fldMin: {
      type: ['string', 'null'],
    },
    fldVariance: {
      type: ['string', 'null'],
    },
    fldMonSysTag: {
      type: ['string', 'null'],
    },
    fldEQIdx: {
      type: ['number', 'null'],
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
    updatedAt: {
      type: 'string',
    },
  },
  indexes: ['PKey'],
  required: [],
  additionalProperties: false,
};

export default schema;
