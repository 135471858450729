import React, { useEffect, useState } from 'react';
import {
  Card,
  Typography,
  CardContent,
  CardHeader,
  Divider,
  Button,
  CircularProgress
} from '@mui/material';
import { size, uniqBy } from 'lodash';
import { Link } from 'react-router-dom';
import PieChartUI from './PieChartUI';
import { getDatabase } from "../../../rxdb";
import { EquipmentDocument } from "../../EquipmentPage/rxdb";
import { useAuth } from "../../../contexts/auth";
import { useAppState } from "../../../contexts/app-state";
import { getJWT } from 'src/storage';
import { REPORT_URL } from 'src/consts';
const COLORS = ['#22b14c', '#e03141', '#a349a4', '#d0b630', '#00a2e8']

interface ChartReport {
  countCritical: number;
  countExpired: number;
  countPlanned: number;
  countRepair: number;
  countNormal: number;
  total: number;
}

const EquipmentChartTile = () => {
  const { settingsPersonal } = useAppState();
  const [loading, setLoading] = useState<boolean>(false);
  const [report, setReport] = useState<ChartReport>({
    countCritical: 0,
    countExpired: 0,
    countPlanned: 0,
    countRepair: 0,
    countNormal: 0,
    total: 0,
  });

  useEffect(() => {
    handleLoadLocal()
  }, [])

  const handleLoadLocal = () => {
    const data = localStorage.getItem("report-data")
    if (data) {
      setReport(JSON.parse(data))
    }
  }

  const handleLoadData = async () => {
    try {
      setLoading(true)
      const token = getJWT();
      const result = await fetch(REPORT_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`
        },
        body: "",

        redirect: 'follow'
      })



      const data = await result.json();

      if (data) {
        let reportData = {
          countCritical: 0,
          countExpired: 0,
          countPlanned: 0,
          countRepair: 0,
          countNormal: 0,
          total: 0,
        }
        data.forEach((item: any) => {
          if (item.Key === 'Normal') {
            reportData.countNormal = item.Count;
          }

          if (item.Key === 'Critical') {
            reportData.countCritical = item.Count;
          }

          if (item.Key === 'Planned') {
            reportData.countPlanned = item.Count;
          }

          if (item.Key === 'Expired') {
            reportData.countExpired = item.Count;
          }

          if (item.Key === 'Repairs') {
            reportData.countRepair;
          }

          reportData.total = reportData.total + item.Count;
        })

        setReport(reportData)
        localStorage.setItem("report-data", JSON.stringify(reportData))
      }
      setLoading(false)
    } catch (error) {
      console.log("load api error ---", error)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (settingsPersonal) {
      handleLoadData()
    }
  }, [settingsPersonal]);

  const COLORS = ['#22b14c', '#e03141', '#a349a4', '#d0b630', '#00a2e8']

  const graphData = [
    {
      name: 'Normal',
      value: report.countNormal
    },
    {
      name: 'Critical',
      value: report.countCritical,
    },
    {
      name: 'Planned',
      value: report.countPlanned,
    },
    {
      name: 'Expired',
      value: report.countExpired,
    },
    {
      name: 'Repairs',
      value: report.countRepair,
    },
  ];

  const equipmentChart = () => (
    <div className="flex items-center justify-center">
      <PieChartUI
        data={graphData}
        dataKey="value"
        nameKey="name"
        innerRadius={30}
        outerRadius={80}
        paddingAngle={0}
        cy={120}
        width={300}
        height={315}
        COLORS={COLORS}
      />
    </div>
  );

  return (
    <Card
      data-testid="equipment-chart-tile"
      elevation={3}
      sx={{ borderTop: 4, borderColor: '#0088FE',height: 425, 
      '@media (min-width: 1200px) and (max-width: 1400px)': {
        height: 450,
      }, }}
    >
      <CardContent sx={{ padding: 2 }}>
        <CardHeader
          sx={{ padding: 1 }}
          //   ToDo: menu list will implement later
          //   action={
          //     <IconButton aria-label="settings">
          //       <MoreVertIcon />
          //     </IconButton>
          //   }
          title={(
            <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
              Equipment and System
            </Typography>
          )}
        />
        <Divider />
        {loading && <div className="flex items-center justify-center w-full h-72">
          <CircularProgress />
        </div>}
        {!loading && equipmentChart()}
        <Divider className="mt-2" />
        <div className="flex justify-between">
          <div className="flex items-center">
            <Typography sx={{ fontSize: 14 }}>Total</Typography>
          </div>
          <div className="flex items-center">
            <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
              {report.total}
            </Typography>
            <Typography sx={{ fontSize: 14, marginX: 2 }}>items</Typography>
            <Button size="small" component={Link} to="/equipment">
              View all equipment
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default EquipmentChartTile;
