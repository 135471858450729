import { saveAs } from 'file-saver';
import {
  Workbook, WorkbookFormat, WorkbookSaveOptions, WorkbookLoadOptions,
  IgrExcelXlsxModule, IgrExcelCoreModule, IgrExcelModule,
} from 'igniteui-react-excel';
import { getJWT } from '../../../storage';
import { ENG_LOG_URL } from 'src/consts';
import { getDatabase } from 'src/rxdb';
// const ENG_LOG_URL = 'http://localhost:3001/englogfile';

IgrExcelCoreModule.register();
IgrExcelModule.register();
IgrExcelXlsxModule.register();

export interface SaveParams {
  pkey?: string;
  location: string;
  mode: string;
  date: string;
  cells: { equipment: string, hours: number }[]
}

export class ExcelUtility {
  public static getExtension(format: WorkbookFormat): string {
    switch (format) {
      case WorkbookFormat.StrictOpenXml:
      case WorkbookFormat.Excel2007:
        return '.xlsx';
      case WorkbookFormat.Excel2007MacroEnabled:
        return '.xlsm';
      case WorkbookFormat.Excel2007MacroEnabledTemplate:
        return '.xltm';
      case WorkbookFormat.Excel2007Template:
        return '.xltx';
      case WorkbookFormat.Excel97To2003:
        return '.xls';
      case WorkbookFormat.Excel97To2003Template:
        return '.xlt';
      default: return '';
    }
  }

  public static load(file: File): Promise<Workbook> {
    return new Promise<Workbook>((resolve, reject) => {
      ExcelUtility.readFileAsUint8Array(file).then((a) => {
        Workbook.load(a, new WorkbookLoadOptions(), (w) => {
          resolve(w);
        }, (e) => {
          reject(e);
        });
      }, (e) => {
        reject(e);
      });
    });
  }

  public static loadFromUrl(url: string): Promise<Workbook> {
    return new Promise<Workbook>((resolve, reject) => {
      const req = new XMLHttpRequest();
      req.open('GET', url, true);
      const token = getJWT();
      req.setRequestHeader('authorization', `Bearer ${token}`);
      req.responseType = 'arraybuffer';
      req.onload = (d): void => {
        const data = new Uint8Array(req.response);
        Workbook.load(data, new WorkbookLoadOptions(), (w) => {
          resolve(w);
        }, (e) => {
          reject(e);
        });
      };
      req.send();
    });
  }

  static DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  public static saveToUrl(workbook: Workbook, params: SaveParams): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const opt = new WorkbookSaveOptions();
      opt.type = 'blob';
      const { pkey, location, mode, date, cells } = params;

      workbook.save(opt, async (d) => {
        const fileExt = ExcelUtility.getExtension(workbook.currentFormat);
        const myHeaders = new Headers();
        const token = getJWT();
        const db = await getDatabase();
        myHeaders.append('authorization', `Bearer ${token}`);

        const formdata = new FormData();
        formdata.append('Content-Type', 'multipart/form-data');
        formdata.append('attachment', d, 'filename');

        // mapped from named fields in spreadsheet
        if (location) formdata.append('location', location)
        if (mode) formdata.append('mode', mode)
        if (date) formdata.append('date', date)
        if (cells) formdata.append('cells', JSON.stringify(cells))

        const requestOptions = {
          method: pkey ? 'PUT' : 'POST',
          headers: myHeaders,
          body: formdata,
        };
        const url = pkey ? `${ENG_LOG_URL}/${pkey}` : ENG_LOG_URL;
        const response = await fetch(url, requestOptions);
        await db.equipment.find().exec()
        if (!response.ok) {
          const res = await response.json()
          if (!res?.errors) return reject("Unknown response error")
          const errors = res.errors.map((e: { msg: string, param: string }) => `${e.msg}: ${e.param}`).join(", ")
          return reject(errors)
        }

        resolve();
      }, (e) => {
        reject(e);
      });
    });
  }

  public static save(workbook: Workbook, fileNameWithoutExtension: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const opt = new WorkbookSaveOptions();
      opt.type = 'blob';

      workbook.save(opt, (d) => {
        const fileExt = ExcelUtility.getExtension(workbook.currentFormat);
        const fileName = fileNameWithoutExtension + fileExt;
        saveAs(d as Blob, fileName);
        resolve(fileName);
      }, (e) => {
        reject(e);
      });
    });
  }

  private static readFileAsUint8Array(file: File): Promise<Uint8Array> {
    return new Promise<Uint8Array>((resolve, reject) => {
      const fr = new FileReader();
      fr.onerror = (e): void => {
        reject(fr.error);
      };

      if (fr.readAsBinaryString) {
        fr.onload = (e): void => {
          const rs = (fr as any).resultString;
          const str: string = rs != null ? rs : fr.result;
          const result = new Uint8Array(str.length);
          for (let i = 0; i < str.length; i += 1) {
            result[i] = str.charCodeAt(i);
          }
          resolve(result);
        };
        fr.readAsBinaryString(file);
      } else {
        fr.onload = (e): void => {
          resolve(new Uint8Array(fr.result as ArrayBuffer));
        };
        fr.readAsArrayBuffer(file);
      }
    });
  }
}

export default { ExcelUtility };
