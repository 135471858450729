import { isNil, omit } from 'lodash';
import { CrewProfiles } from '../../../generated/graphql';
import {logger} from "../../../helpers/logger";

const fields = `    
    DateEntered
    DateExpire
    DateFire
    DateHire
    DateIssue
    DatePayRev
    DateVac
    Department
    DepartmentHead
    NoView
    fldAcct
    fldAddress
    fldAllergies
    fldBUPA
    fldBankContact
    fldBank
    fldBankCurr
    fldBankEmail
    fldBankFax
    fldBankPhone
    fldBankIBAN
    fldBankRouting
    fldBankSwift
    fldBloodABO
    fldBloodRH
    fldCertsOnBoard
    fldChest
    fldChestMeas
    fldCrewFamiliar
    fldCurrent
    fldCrewID
    fldCurrentPay
    fldDL
    fldDOB
    fldDefaultPassport
    fldDiet
    fldFax1
    fldFax2
    fldFood
    fldFirst
    fldHeight
    fldHeightMeas
    fldInseam
    fldInseamMeas
    fldIsSO
    fldIsGuest
    fldIsAdmin
    fldIsSSO
    fldIsWatchkeeper
    fldIssue
    fldLast
    fldLaundryNum
    fldMarital
    fldMedConsent
    fldMedInstruct
    fldMedNotes
    fldMeds
    fldMiddle
    fldNAMEUPPER
    fldNOK1
    fldNOK1Email
    fldNOK1Mobile
    fldNOK2
    fldNOK2Email
    fldNOK2Mobile
    fldNationality
    fldNeck
    fldNeckMeas
    fldPassport
    fldPersonalNotes
    fldPhone1
    fldPhone2
    fldPicture
    fldPlaceOfBirth
    fldPosition
    fldReligion
    fldRemarks
    fldSalary
    fldSeaDis
    fldShoeMeas
    fldShoeSize
    fldStationBill
    fldVacDays
    fldVacMeas
    fldWire
    fldWeightMeas
    fldWeight
    fldWaistMeas
    fldWaist
    CrewUsername
    deletedAt
    updatedAt
    deletedBy
    isRecoverable
    autoId
`;

export const crewPullQuery = (doc: CrewProfiles) => {
  if (!doc) {
    doc = {
      autoId: 0,
      fldCrewID: '',
      updatedAt: new Date(0).toISOString(),
    };
  }
  logger('Crew Profiles').info(
    `pull query triggered - Crew Profiles:  { ${new Date().toISOString()} - ID: ${doc.autoId}, updatedAt: ${doc.updatedAt} }`,
  );
  return {
    query: `
        query getProfiles($autoId: Int, $filterDate: Date) {
          CrewProfiles(
            where:{ 
              _and: [
                {
                  _or: [
                    {
                      isRecoverable: {
                        _is_null: true
                      }
                    },
                    {
                      isRecoverable: {
                        _eq: true
                      }
                    }
                  ]
                },
                {
                  _or: [
                    {
                      updatedAt: {_gt: $filterDate},                
                    },
                    {
                      autoId: {_gt: $autoId}
                      updatedAt: {_gte: $filterDate},
                    }
                  ]
                }
              ]                                    
            },
            order_by: [
              {updatedAt: asc},
              {autoId: asc}
            ],
            limit: 500
          ) {
            ${fields}            
          }
        }
      `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
    },
  };
};

export const crewProfilesMutation = (doc: any) => {
  const query = `
    mutation UpsertCrewProfile($input: CrewProfileUpsertInput!) {
      upsertCrewProfile(input: $input) {
        ${fields}
      }
    }
  `;

  let variables
  if(isNil(doc.CrewPassword)){
    variables = {
      input: omit(doc, ['updatedAt', 'deletedAt','CrewPassword']),
    };
  } else{
    variables = {
      input: omit(doc, ['updatedAt', 'deletedAt']),
    };
  }

  return {
    query,
    variables,
  };
};

export const crewProfilesSubscription = `
  subscription onCrewProfilesChanged {
    CrewProfiles {
      ${fields}      
    }
  }
`;
