import { omit } from 'lodash';
import { TblAnyReport } from '../../../generated/graphql';
import {logger} from "../../../helpers/logger";

const fields = `
  PKey
  fldCompletedBy
  fldCompletedDate
  fldCreatedBy
  fldCreatedByName
  fldDateCreated
  fldDateReceived
  fldDateSent
  fldNoView
  fldParent
  fldRemark
  fldReportFile
  fldReportNumber
  fldReportPassword
  fldReportType
  fldSend
  fldVerifiedBy
  fldVerifiedDate
  deletedAt
  deletedBy
  isRecoverable
  updatedAt
  autoId
`;

export const anyReportsPullQuery = (doc: TblAnyReport) => {
  if (!doc) {
    doc = {
      autoId: 0,
      fldCreatedByName: '',
      fldReportFile: '',
      fldReportType: '',
      PKey: '',
      updatedAt: new Date(0).toISOString(),
    };
  }
  logger('Any Reports').info(
    `pull query triggered - Any Reports:  { ${new Date().toISOString()} - ID: ${doc.autoId} }`,
  );
  return {
    query: `
        query getAnyReports ($autoId: Int, $filterDate: Date) {
              tblAnyReport (
                where:{ 
                  _and: [
                    {
                      _or: [
                        {
                          isRecoverable: {
                            _is_null: true
                          }
                        },
                        {
                          isRecoverable: {
                            _eq: true
                          }
                        }
                      ]
                    },
                    {
                      _or: [
                        {
                          updatedAt: {_gt: $filterDate},                
                        },
                        {
                          autoId: {_gt: $autoId}
                          updatedAt: {_gte: $filterDate},
                        }
                      ]
                    }
                  ]                                    
                },
                order_by: [
              {updatedAt: asc},
              {autoId: asc}],
                limit: 500) {
                ${fields}
           }
          }
      `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
    },
  };
};

export const anyReportsMutation = (doc: any) => {
  const query = `
    mutation tblAnyReport($input: AnyReportInput!) {
      upsertAnyReport(input: $input) {
        ${fields}
      }
    }
  `;

  const variables = {
    input: omit(doc, ['deletedAt']),
  };

  return {
    query,
    variables,
  };
};

export const anyReportsSubscription = `
  subscription onAnyReportsChanged {
    tblAnyReport {
      ${fields}
    }
  }
`;
