import React, { FC } from 'react';
import {
  AppBar,
  Box,
  Button,
  ButtonPropsVariantOverrides,
  Theme,
  Toolbar,
  Tooltip,
} from '@mui/material';
import { SxProps } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { OverridableStringUnion } from '@mui/types';

interface InjectedProps {
  sx?: SxProps<Theme>;
  okType?: 'button' | 'submit' | 'reset';
  okText?: string;
  cancelText?: string;
  onCancel?: () => void;
  onOk?: () => void;
  formId?: string;
  loading?: boolean;
  okVariant?: OverridableStringUnion<
    'text' | 'outlined' | 'contained',
    ButtonPropsVariantOverrides
  >;
  disabled?: boolean;
}

const StickyAppBar: FC<InjectedProps> = ({
  sx,
  okType = 'submit',
  okText = 'Save',
  okVariant = 'contained',
  onCancel,
  onOk,
  cancelText = 'Close',
  children = null,
  formId,
  loading,
  disabled = false,
}) => (
  <AppBar
    position="fixed"
    color="inherit"
    sx={
      sx || {
        left: 'auto',
        right: 0,
        width: { xs: '100vw', md: '50vw' },
        top: 'auto',
        bottom: 0,
      }
    }
  >
    <Tooltip
      title={disabled ? 'You do not have permission to save this document' : ''}
    >
      <Toolbar>
        <Tooltip
          title={
            disabled ? 'You do not have permission to save this document' : ''
          }
        >
          <LoadingButton
            loading={loading}
            form={formId}
            disabled={disabled}
            type={okType}
            onClick={onOk}
            className="mr-3 w-32"
            variant={okVariant}
          >
            {okText}
          </LoadingButton>
        </Tooltip>
        {onCancel && (
          <Button onClick={onCancel} className="w-32">
            {cancelText}
          </Button>
        )}
        <Box className="flex-grow" />
        {children}
      </Toolbar>
    </Tooltip>
  </AppBar>
);

export default StickyAppBar;
