import { omit } from 'lodash';
import { TblSchedMaintParts } from '../../../generated/graphql';
import {logger} from "../../../helpers/logger";

const fields = `
  DateEntered
  EQKey
  PKey
  fldSchedMaintKey
  fldPartName
  fldPartNumber
  Amount
  Qty
  ProductID
  ModelNumber
  deletedAt
  deletedBy
  isRecoverable
  updatedAt
  autoId
`;

export const tblSchedMaintPartsQuery = (doc: TblSchedMaintParts) => {
  if (!doc) {
    doc = {
      autoId: 0,
      PKey: '',
      fldSchedMaintKey: '',
      updatedAt: new Date(0).toISOString(),
    };
  }
  logger('TblSchedMaintParts').info(
    `pull query triggered - TblSchedMaintParts:  { ${new Date().toISOString()} - ID: ${doc.autoId} }`,
  );
  return {
    query: `
        query getTblSchedMaintParts ($autoId: Int, $filterDate: Date) {
              tblSchedMaintParts(
                where:{ 
                  _and: [
                    {
                      _or: [
                        {
                          isRecoverable: {
                            _is_null: true
                          }
                        },
                        {
                          isRecoverable: {
                            _eq: true
                          }
                        }
                      ]
                    },
                    {
                      _or: [
                        {
                          updatedAt: {_gt: $filterDate},                
                        },
                        {
                          autoId: {_gt: $autoId}
                          updatedAt: {_gte: $filterDate},
                        }
                      ]
                    }
                  ]                                    
                },
                order_by: [
              {updatedAt: asc},
              {autoId: asc}],            
                limit: 500) {
                ${fields}
           }
          }
      `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
    },
  };
};

export const tblSchedMaintPartsMutation = (doc: any) => {
  const query = `
    mutation upsertTblSchedMaintParts($input: tblSchedMaintPartsInput!) {
      upsertTblSchedMaintParts(input: $input) {
        ${fields}
      }
    }
  `;

  const variables = {
    input: omit(doc, ['updatedAt', 'deletedAt', 'accessDate']),
  };

  return {
    query,
    variables,
  };
};

export const tblSchedMaintPartsSubscription = `
  subscription onTblSchedMaintPartsChanged {
    tblSchedMaintParts {
      ${fields}
    }
  }
`;
