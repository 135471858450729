import React, { FC } from 'react';
import { Box } from '@mui/material';

interface InjectedProps {
  value: any;
  index: number;
  label?: string;
}

const TabPanel: FC<InjectedProps> = ({
  children, value, index, label,
}) => (
  <div
    className="h-full mt-3 pb-6"
    role="tabpanel"
    hidden={value !== index}
    id={label}
    aria-labelledby={label}
  >
    {value === index && (
      <Box sx={{ pt: 3 }} className="h-full">
        {children}
      </Box>
    )}
  </div>
);

export default TabPanel;
