import { FC } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Button,
  Box,
} from "@mui/material";
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

interface EqSchedPMConfDialogProps extends Partial<DialogProps> {
  visible: boolean;
  title: string;
  onChange: (event: any) => void;
  okText?: string;
  cancelText?: string;
  onOk: () => void;
  onCancel: () => void;
  color?: any;
}

const EqSchedPMConfDialog: FC<EqSchedPMConfDialogProps> = ({
  visible,
  title,
  onChange,
  cancelText = "Cancel",
  okText = "OK",
  color="error",
  onCancel,
  onOk,
}) => {
  return (
    <Dialog
      open={visible}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="warning-dialog"
    >
      <DialogTitle id="alert-dialog-title">{(<WarningTwoToneIcon fontSize="small" color={color} className="mr-3"/>)}{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <FormControl>
            <FormLabel id="eqschedconf-radio-buttons-group-label">An existing instance of this planned task is outstanding on your work list. You have either changed the recurrence type or the date this planned task is scheduled to be due is different than the new date you picked. Please choose one of the options below</FormLabel>
            <RadioGroup
              aria-labelledby="eqschedconf-radio-buttons-group-label"
              defaultValue="leave"
              name="radio-buttons-group"
              onChange={onChange} 
            >
              <FormControlLabel value="leave" control={<Radio />} label="Leave the existing outstanding task alone" />
              <FormControlLabel value="change" control={<Radio />} label="Change the existing task" />
              <FormControlLabel value="remove" control={<Radio />} label="Remove the task from the work list" />
            </RadioGroup>
          </FormControl>  
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
        <Box sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}>
          <Button data-testid="warning-dialog-confirm" className="mx-2" onClick={onOk}>
            {okText}
          </Button>
        </Box>
        <Button data-testid="warning-dialog-cancel" onClick={onCancel} variant="contained" autoFocus>{cancelText}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EqSchedPMConfDialog;
