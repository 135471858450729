import { FC, useState } from 'react';
import {
  Button,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import VesselDetailForm from './component/VesselEditForm';
import { CustomDialog } from 'src/helpers/modals';

interface InjectedProps {
  visible: boolean;
  initialValue: any;
  onSave: () => void;
  onCancel: () => void;
}

const VesselEditDialog: FC<InjectedProps> = ({
  initialValue,
  visible,
  onCancel,
  onSave,
}) => {
  const [saveButtonVisible, setSaveButtonVisible] = useState<boolean>(false);
  const [cancelButtonClick, setCancelButtonClick] = useState<boolean>(false);

  const handleSave = async () => {
    onSave();
  };

  const handleClose = () => {
    onCancel && onCancel();
  };

  const handleCancel = () => {
    setCancelButtonClick(true);
  };

  const handleSaveButtonStatus = (status:boolean) => {
    setSaveButtonVisible(status ? true : false);
    setCancelButtonClick(false);
  };

  return (
    <>
      <CustomDialog
        scroll="paper"
        fullWidth
        maxWidth="md"
        open={visible}
        onClose={handleCancel}
      >
        <DialogTitle>
          <span className="font-bold text-2xl">Edit vessel specifications</span>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 0 }}>
          <VesselDetailForm initialValue={initialValue} onSave={handleSave} saveButtonStatus={handleSaveButtonStatus} cancelClick={cancelButtonClick}/>
        </DialogContent>
        <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
          <Box
            sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}
          >
            {!saveButtonVisible &&
            (
              <Button variant="contained" className="mr-2" onClick={handleClose}>
                Close
              </Button>
            )}
            {saveButtonVisible
            && (
              <div>
                <Button className="mr-2" onClick={handleCancel}>
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  form="Vessel-Edit-Form"
                  variant="contained"
                >
                  Save
                </LoadingButton>
              </div>
            )}
          </Box>
        </DialogActions>
      </CustomDialog>
    </>
  );
};

export default VesselEditDialog;
