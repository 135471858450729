import { omit } from 'lodash';
import { TblSchedMaintEq } from '../../../generated/graphql';
import {logger} from "../../../helpers/logger";

const fields = `
  DateEntered
  EqKey
  PKey
  SchedKey
  fldDateTrigger
  fldDeferred
  fldDeferredDate
  fldHoursCompleted
  fldHoursTrigger
  fldIndex
  fldIterations
  fldRunOnce
  fldSRHKey
  fldLocHierarchy
  deletedAt
  deletedBy
  isRecoverable
  updatedAt
  autoId
`;

export const schedMaintEqQuery = (doc: TblSchedMaintEq) => {
  if (!doc) {
    doc = {
      autoId: 0,
      PKey: '',
      SchedKey: '',
      updatedAt: new Date(0).toISOString(),
    };
  }
  logger('TblSchedMaintEq').info(
    `pull query triggered - TblSchedMaintEq:  { ${new Date().toISOString()} - ID: ${doc.autoId} }`,
  );
  return {
    query: `
        query getTblSchedMaintEQ ($autoId: Int, $filterDate: Date) {
              tblSchedMaintEQ(
                where:{ 
                  _and: [
                    {
                      _or: [
                        {
                          isRecoverable: {
                            _is_null: true
                          }
                        },
                        {
                          isRecoverable: {
                            _eq: true
                          }
                        }
                      ]
                    },
                    {
                      _or: [
                        {
                          updatedAt: {_gt: $filterDate},                
                        },
                        {
                          autoId: {_gt: $autoId}
                          updatedAt: {_gte: $filterDate},
                        }
                      ]
                    }
                  ]                                    
                },
                order_by: [
                  {updatedAt: asc},
                  {autoId: asc}
                ],
                limit: 500
              ) {
                ${fields}
           }
          }
      `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
    },
  };
};

export const schedMaintEqMutation = (doc: any) => {
  const query = `
    mutation upsertSchedMaintEq($input: SchedMaintEqInput!) {
      upsertSchedMaintEq(input: $input) {
        ${fields}
      }
    }
  `;

  const variables = {
    input: omit(doc, ['updatedAt', 'deletedAt']),
  };

  return {
    query,
    variables,
  };
};

export const schedMaintEqSubscription = `
  subscription onSchedMaintEqChanged {
    tblSchedMaintEQ {
      ${fields}
    }
  }
`;
