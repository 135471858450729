import React from 'react';
import './App.css';
import { Router } from 'react-router-dom';
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import { AppStateProvider } from './contexts/app-state';
import { useScreenSizeClass } from './utils/media-query';
import UnauthenticatedLayout from './layouts/UnauthorizedLayout';
import AuthorizedLayout from './layouts/AuthorizedLayout';
import SnackbarProvider from './contexts/Snackbar/SnackbarProvider';
import { DialogProvider } from './contexts/dialog';
import { ApolloProvider } from '@apollo/client';
import apolloClient from './apollo';
import { createBrowserHistory } from 'history';
import { SENTRY_DSN, SENTRY_ENVIRONMENT } from 'src/consts';
import * as Sentry from '@sentry/react';

const history = createBrowserHistory();

// If Sentry env vars are not set, they will have a string value of 'undefined'
if (SENTRY_DSN === 'undefined' || SENTRY_ENVIRONMENT === 'undefined') {
  console.log('Sentry not configured');
} else {
  console.log(`Configuring Sentry: ${SENTRY_ENVIRONMENT}`);
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
    integrations: [
      //Sentry.reactRouterV5BrowserTracingIntegration({ history }),
      Sentry.replayIntegration(),
      Sentry.captureConsoleIntegration({
        levels: ['warn', 'error', 'debug', 'assert'],
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    profilesSampleRate: 1.0,
    debug: true,
  });
}

interface Props {
  test?: boolean;
}

const App = ({ test }: Props) => {
  const { token, handleToken } = useAuth();

  if (!token) {
    return <UnauthenticatedLayout handleToken={handleToken} />;
  }

  return (
    <div id="app-root" className="w-full">
      <ApolloProvider client={apolloClient}>
        <AuthorizedLayout />
      </ApolloProvider>
    </div>
  );
};

const AppWithProps = ({ test }: Props) => {
  const screenSizeClass = useScreenSizeClass();

  return (
    <Router history={history}>
      <AuthProvider>
        <AppStateProvider>
          <NavigationProvider>
            <div className={`app ${screenSizeClass}`} data-testid="app-root">
              <DialogProvider>
                <SnackbarProvider>
                  <App test={test} />
                </SnackbarProvider>
              </DialogProvider>
            </div>
          </NavigationProvider>
        </AppStateProvider>
      </AuthProvider>
    </Router>
  );
};
export default AppWithProps;
