import { NODE_URL } from '../consts';

const getSession = async (token: string) => {
  const response = await fetch(`${NODE_URL}/auth/me`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.json();
};

export default getSession;
