import { Alert, Button, Snackbar } from '@mui/material';
import React, { FC, useState, } from 'react';
import SpareGridOld from 'src/components/SparesTab/components/SpareGrid/indexOld';
import SparePopupOld from 'src/components/SparesTab/components/SparePopup/indexOld';
import { TblSparesUsedDocument } from 'src/components/SparesTab/rxdb';
import { normalizeDateTime } from 'src/helpers';
import { getDatabase } from 'src/rxdb';
import { uuid } from 'uuidv4';

interface ScheduleProps {
    issue: any;
    disableEdit: boolean;
}

type Severity = 'error' | 'success' | 'info' | 'warning' | undefined;

const SpareComponent: FC<ScheduleProps> = ({ issue, disableEdit=false }) => {
    const [isFormVisible, setFormVisible] = useState<boolean>(false);
    const [editableItem, setEditableItem] = useState<TblSparesUsedDocument>();
    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState<Severity>(undefined);

    const handleAddClick = () => {
        setEditableItem(undefined);
        setFormVisible(true);
    };

    const onEdit = async (spare: TblSparesUsedDocument) => {
        setEditableItem(spare);
        setFormVisible(true);
    };

    const handleDelete = async (spare: TblSparesUsedDocument) => {
        try {
            await spare.remove();

            setAlert(true);
            setAlertMessage('Spare Record successfully removed');
            setAlertSeverity('success');
        } catch (e: any) {
            setAlert(true);
            setAlertMessage(`${e.message}`);
            setAlertSeverity('error');
        }
    };
    const handleCreate = async (payload: any) => {
        const db = await getDatabase();        
        try {
            const document: any = {
                fldSchedMaintKey: issue.SchedKey,
                PKey: payload.PKey || uuid(),
                DateEntered: normalizeDateTime(new Date()),
                Amount: payload.Amount,
                ProductID: payload.ProductID,
                fldPartNumber: payload.PartNumber,
            };

            await db.tblschedmaintparts.upsert(document);

            setFormVisible(false);
            setAlert(true);
            setAlertMessage('Spare Record successfully created');
            setAlertSeverity('success');
        } catch (e: any) {
            console.error(e);
            setFormVisible(false);
            setAlert(true);
            setAlertMessage(`${e.message}`);
            setAlertSeverity('error');
        }
    };

    const onFormCancel = () => {
        setEditableItem(undefined);
        setFormVisible(false);
    };

    return (
        <div>
            <SpareGridOld
                onEdit={onEdit}
                onDelete={handleDelete}
                issue={issue}
                onLocation={() => { }}
                tableName="tblschedmaintparts"
                referenceKey="fldSchedMaintKey"
                referenceValue={issue.SchedKey}
                showLocationIcon={false}
            />
            <div className="flex justify-center mt-6">
                <Button variant="contained" onClick={handleAddClick} disabled={disableEdit} >
                    Add Spare
                </Button>
            </div>

            <SparePopupOld
                initialValues={editableItem}
                onCancel={onFormCancel}
                onSubmit={handleCreate}
                visible={isFormVisible}
                issue={issue}
            />

            <Snackbar
                open={alert}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={() => {
                    setAlert(false);
                }}
            >
                <Alert severity={alertSeverity}>{alertMessage}</Alert>
            </Snackbar>
        </div>
    )
};

export default SpareComponent;
