import { RxCollection, RxJsonSchema, RxDocument } from 'rxdb';
import { LogEntry } from '../../generated/graphql';

export type LogEntryDocType = LogEntry;
export type LogEntryDocMethods = {};

export type LogEntryDocument = RxDocument<LogEntryDocType, LogEntryDocMethods>;

export type LogEntryCollectionMethods = {};

export type LogEntryCollection = RxCollection<
  LogEntryDocType,
  LogEntryDocMethods,
  LogEntryCollectionMethods
>;

export const logentrySchema: RxJsonSchema<LogEntryDocType> = {
  title: 'logentry ',
  description: 'describes logentry items',
  version: 6,
  keyCompression: false,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    PKey: {
      type: 'string',
      primary: true,
    },
    LogEntry: {
      type: ['string', 'null'],
    },
    LogDate: {
      type: 'string',
    },
    Equipment: {
      type: ['string', 'null'],
    },
    MaintKey: {
      type: ['string', 'null'],
      ref: 'tblschedmainteq',
    },
    EqKey: {
      type: ['string', 'null'],
      ref: 'equipment',
    },
    JobNumber: {
      type: ['string', 'null'],
      ref: 'workissues',
    },
    Checks: {
      type: ['string', 'null'],
    },
    DayLogType: {
      type: ['number', 'null'],
    },
    fldCost: {
      type: ['number', 'null'],
    },
    Curr: {
      type: ['string', 'null'],
    },
    fldCompany: {
      type: ['string', 'null'],
      ref: 'vddallcontacts',
    },
    fldTime: {
      type: ['number', 'null'],
    },
    fldTimeQty: {
      type: ['string', 'null'],
    },
    fldHours: {
      type: ['number', 'null'],
    },
    fldPerformedBy: {
      type: ['string', 'null'],
      ref: 'crew',
    },
    fldEnteredBy: {
      type: ['string', 'null'],
      ref: 'crew',
    },
    fldGeoLocation: {
      type: ['string', 'null'],
    },
    Department: {
      type: ['string', 'null'],
      ref: 'tblddlistdefaults',
    },
    fldLogEntryTime: {
      type: ['string', 'null'],
    },
    fldCrewID: {
      type: ['string', 'null'],
      ref: 'crew',
    },
    fldNoView: {
      type: ['boolean', 'null'],
    },
    fldRestricted: {
      type: ['boolean', 'null'],
    },
    fldSMS: {
      type: ['boolean', 'null'],
    },
    fldBillable: {
      type: ['boolean', 'null'],
    },
    fldIsWarranty: {
      type: ['boolean', 'null'],
    },
    fldLocHierarchy: {
      type: ['string', 'null'],
      description: 'Locations foreign key',
      ref: 'tbllocationshierarchy',
    },
    DateEntered: {
      type: ['string', 'null'],
    },
    fldIsCheckList: {
      type: ['boolean', 'null'],
    },
    fldWorkList: {
      type: ['string', 'null'],
      ref: 'tblddlistdefaults',
    },
    fldHTML: {
      type: ['string', 'null'],
    },
    fldSRHKey: {
      description: 'Categories foreign key. TblSystemHierarchy',
      type: ['string', 'null'],
      ref: 'tblsystemshierarchy',
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    updatedAt: {
      type: 'string',
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
  },
  indexes: ['LogDate', 'updatedAt'],
  required: [],
  additionalProperties: false,
};

export const logentryDocMethods: LogEntryDocMethods = {};

export const logentryCollectionMethods: LogEntryCollectionMethods = {};
