import { FC, useState } from 'react';
import { Button, DialogTitle, DialogActions, DialogContent, Box, Snackbar, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { getDatabase } from 'src/rxdb';
import { InvEqDocument } from 'src/rxdb/collections/InvEq/schema';
import { CustomDialog } from 'src/helpers/modals';
import EqSpareAddInvGrid from './EqSpareAddInvGrid';
import { EquipmentDocument } from 'src/pages/EquipmentPage/rxdb';

interface InjectedProps {
  visible: boolean;
  initialValue: EquipmentDocument;
  onSave: () => void;
  onCancel?: () => void;
  eqKeyData?: any;
}

const EqSpareAddDialog: FC<InjectedProps> = ({
  initialValue,
  visible,
  onCancel,
  onSave,
  eqKeyData,
}) => {
  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });
  const [processing, setProcessing] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<any>(null);

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const handleSave = async () => {
    const db = await getDatabase();

    setProcessing(true);
    selectedItems.map(async (key: any) => {
      const newID = `${key}--${initialValue.EqKey}`;
      const existRecord = await db.inveq.findOne({
        selector:{
          id: {$eq: newID} 
        }
      }).exec()

      const newInvEq: InvEqDocument = await db.inveq.newDocument({
        EqKey: initialValue.EqKey,
        ProductID: key,
        id: newID,
      });

      try {
        if(existRecord){
          await existRecord.atomicPatch({
            deletedAt:null,
            deletedBy: null,
            isRecoverable: null,
          })
        } else{
          await db.collections.inveq.insert(newInvEq);
        }
        
      } catch (e: any) {
        setSnackbar({
          open: true,
          type: 'error',
          message: e.message,
        });
        setProcessing(false);
      }
    });
    setProcessing(false);
    onSave();
  };

  const handleCancel = () => {
    onCancel && onCancel();
    setProcessing(false);
    setSnackbar({
      open: false,
      type: 'success',
      message: '',
    });
  };

  const handleSelect = (items: any) => {
    setSelectedItems(items);
  };

  return (
    <>
      <CustomDialog
        scroll="paper"
        fullWidth
        maxWidth="md"
        open={visible}
        onClose={handleCancel}
      >
        <DialogTitle>
          <span className="font-bold text-2xl">{`Add spares for ${initialValue.UniqueName}`}</span>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 0 }}>
          <EqSpareAddInvGrid
            EqKey={initialValue.EqKey}
            onSelect={handleSelect}
            eqKeyData={eqKeyData}
          />
        </DialogContent>
        <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
          <Box
            sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}
          >
            <Button className="mr-2" onClick={handleCancel}>
              Cancel
            </Button>
            <LoadingButton
              loading={processing}
              type="submit"
              onClick={handleSave}
              variant="contained"
            >
              Save
            </LoadingButton>
          </Box>
        </DialogActions>
      </CustomDialog>

      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        onClose={onSnackbarClose}
      >
        <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
          {snackBar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EqSpareAddDialog;
