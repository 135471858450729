// Styles
import './style.css';

// React components
import React, { FC, useCallback, useState, MutableRefObject, useRef, useEffect } from 'react';
import { pick, size, isEmpty, isNil } from 'lodash';

// TDI components
import LicensedReactDataGrid from '../../../UI/LicensedReactDataGrid';

// Inovua components
import { TypeSortInfo, TypeComputedProps } from '@inovua/reactdatagrid-enterprise/types';

// Data
import { getDatabase, TDIDb } from '../../../../rxdb';
import { InventoryDocument } from 'src/pages/InventoryPage/rxdb';

type EqSpareAddInvGridProps = {
  darken?: boolean;
  onSelect?: (items: any[]) => void;
  EqKey?: string;
  eqKeyData?: any;
};

const filter = [
  {
    name: 'ProductName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'Manufacturer',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'ModelNumber',
    operator: 'contains',
    type: 'string',
    value: '',
  },
];

const transformData = async (item: InventoryDocument) => ({
  ...pick(item, ['ProductID', 'ProductName', 'ModelNumber', 'Manufacturer']),
  original: item,
});

const getRegexByOperator = (operator: string, value: string) => {
  switch (operator) {
    case 'contains':
      return new RegExp(`.*${value}`, 'i');
    case 'startsWith':
      return new RegExp(`^${value}`, 'i');
    case 'endsWith':
      return new RegExp(`${value}$`, 'i');
    default:
      return new RegExp('');
  }
};

const getSelectorByFilterName = async (
  name: string,
  value: string,
  operator: string,
  db: TDIDb,
) => {
  switch (name) {
    case 'ProductName':
      return {
        ProductName: { $regex: getRegexByOperator(operator, value) },
      };

    case 'Manufacturer':
      return {
        Manufacturer: { $regex: getRegexByOperator(operator, value) },
      };

    case 'ModelNumber':
      return {
        ModelNumber: { $regex: getRegexByOperator(operator, value) },
      };

    default:
      return {};
  }
};

const EqSpareAddInvGrid: FC<EqSpareAddInvGridProps> = ({
  onSelect,
  EqKey,
  eqKeyData,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const highlightedRowId = useRef<string>('-1');
  const [gridRef, setGridRef] = useState<any>(null);

  const loadData = async ({
    skip,
    limit,
    filterValue,
  }: {
    sortInfo: TypeSortInfo;
    skip: number;
    limit: number;
    filterValue: any;
  }): Promise<{ data: any[]; count: number }> => {
    const db = await getDatabase();
    // TODO: Handle filtervalue.OPERATOR
    let selector = {
      ProductID: { $nin: eqKeyData || [] },
      fldDataType: { $eq: '0' },
      deletedAt: {$eq: null},
    };

    await Promise.all(
      filterValue.map(async (v: any) => {
        if (isEmpty(v.value)) return v;

        const s = await getSelectorByFilterName(
          v.name,
          v.value,
          v.operator,
          db,
        );

        selector = {
          ...selector,
          ...s,
        };
        return v;
      }),
    );
    const length = size(await db.inventory.find({selector: {fldDataType: {$eq: '0'}, deletedAt: {$eq: null}}}).exec());

    const items = await db.inventory
      .find({
        selector,
        skip,
        limit,
        // sort,
      })
      .exec();

    const data = await Promise.all(items.map(transformData));
    return { data, count: length };
  };

  const init = async (ref: MutableRefObject<TypeComputedProps | null>) => {
    const db = await getDatabase();
    // Always reload grid on new item / update / delete.
    db.inventory.$.subscribe(async (ev) => {
      if (ev.operation === 'INSERT' || ev.operation === 'UPDATE') {
        highlightedRowId.current = ev.documentId;
        ref.current?.reload();
      }
      if (ev.operation === 'DELETE') {
        highlightedRowId.current = '-1';
        ref.current?.reload();
      }
    });
  };

  useEffect(() => {
    if (isNil(gridRef)) return;
    init(gridRef);
  }, [gridRef]);

  const onReady = (ref: MutableRefObject<TypeComputedProps | null>) => {
    setGridRef(ref);
  };

  const onLoadingChange = (status: boolean) => {
    // If loading completed - check if there any items that needs to be highlighted.
    if (!status && highlightedRowId.current !== '-1') {
      gridRef?.current?.scrollToId(highlightedRowId.current);
      gridRef?.current?.setSelectedById(highlightedRowId.current, true);
      highlightedRowId.current = '-1';
    }
    setLoading(status);
  };

  const dataSource = useCallback(loadData, []);

  const onSelectionChange = useCallback(({ selected: selectedMap, data }) => {
    onSelect && onSelect(Object.keys(selectedMap));
  }, []);

  const columns = [
    {
      name: 'ProductName',
      header: 'Product Name',
      flex: 1,
    },

    {
      name: 'Manufacturer',
      header: 'Manufacturer',
      flex: 1,
    },

    {
      name: 'ModelNumber',
      header: 'Model Number',
      flex: 1,
    },
  ];

  return (
    <div data-testid="data-grid" className="flex flex-col flex-grow p-4 inv-space-add-eq-grid">
      <LicensedReactDataGrid
        onLoadingChange={onLoadingChange}
        defaultLimit={5000}
        livePagination
        onReady={onReady}
        rowHeight={40}
        checkboxColumn
        defaultSelected={[]}
        loading={loading}
        onSelectionChange={onSelectionChange}
        defaultFilterValue={filter}
        idProperty="ProductID"
        columns={columns}
        dataSource={dataSource}
        defaultSortInfo={[
          { name: 'ProductName', dir: 1 },
          { name: 'Manufacturer', dir: 1 },
          { name: 'ModelNumber', dir: 1 }
        ]}
      />
    </div>
  );
};

export default EqSpareAddInvGrid;
