import { isEmpty, isNil } from 'lodash';

export const validateForm = (data: any, setSnackbar: any) => {
  const { ProductName, fldSRHKey, Department } = data;

  if (isNil(ProductName) || isEmpty(ProductName) || isNil(fldSRHKey) || isNil(Department)) {
    setSnackbar({
      open: true,
      message: 'Please fill required field(s). Check form field(s) marked with red color',
      type: 'error',
    });
    return false;
  }

  // if (isNil(fldSRHKey)) {
  //   setSnackbar({
  //     open: true,
  //     message: 'Category is required',
  //     type: 'error',
  //   });
  //   return false;
  // }

  // if (isNil(Department)) {
  //   setSnackbar({
  //     open: true,
  //     message: 'Department is required',
  //     type: 'error',
  //   });
  //   return false;
  // }

  return true;
};

export const validateFormForStorage = (data: any) => {
  const { ProductName, fldSRHKey, Department } = data;

  let message = '';

  if ((isNil(ProductName) || isEmpty(ProductName)) && isNil(fldSRHKey) && isNil(Department)) {
    message = 'Product Name, Category, Department'
  } else if ((isNil(ProductName) || isEmpty(ProductName)) && isNil(fldSRHKey)) {
    message = 'Product Name, Category'
  } else if ((isNil(ProductName) || isEmpty(ProductName)) && isNil(Department)) {
    message = 'Product Name, Department'
  } else if (isNil(fldSRHKey) && isNil(Department)) {
    message = 'Category, Department'
  } else if (isNil(ProductName) || isEmpty(ProductName)) {
    message = 'Product'
  } else if (isNil(fldSRHKey)) {
    message = 'Category'
  } else if (isNil(Department)) {
    message = 'Department'
  } else {
    message = ''
  }
  return message
}
