// @ts-nocheck
import React, {
  FC,
  useCallback,
  useState,
  MutableRefObject,
  useRef,
} from 'react';
import LicensedReactDataGrid from '../../../UI/LicensedReactDataGrid';
import { pick, size, isEmpty } from 'lodash';

import TypeSortInfo from '@inovua/reactdatagrid-enterprise/types';
import { MangoQuerySortDirection } from 'rxdb/dist/types/types/rx-query';

// Data
import { EquipmentDocument } from 'src/pages/EquipmentPage/rxdb';
import { getDatabase, TDIDb } from '../../../../rxdb';
import './style.css';

type InvSpareAddEqGridProps = {
  darken?: boolean;
  onSelect?: (item: EquipmentDocument) => void;
  ProductID?: string;
  eqKeyData?: any;
};

const filter = [
  {
    name: 'UniqueName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'Manufacturer',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'ModelNumber',
    operator: 'contains',
    type: 'string',
    value: '',
  },
];

const transformData = async (item: EquipmentDocument) => ({
  ...pick(item, ['EqKey', 'UniqueName', 'ModelNumber', 'Manufacturer']),
  // Department: (await eqitem.populate("Department"))?.fldMember,
  original: item,
});

const getRegexByOperator = (operator: string, value: string) => {
  switch (operator) {
    case 'contains':
      return new RegExp(`.*${value}`, 'i');
    case 'startsWith':
      return new RegExp(`^${value}`, 'i');
    case 'endsWith':
      return new RegExp(`${value}$`, 'i');
    default:
      return new RegExp();
  }
};

const getSelectorByFilterName = async (
  name: string,
  value: string,
  operator: string,
  db: TDIDb,
) => {
  switch (name) {
    case 'UniqueName':
      return {
        UniqueName: { $regex: getRegexByOperator(operator, value) },
      };

    case 'Manufacturer':
      return {
        Manufacturer: { $regex: getRegexByOperator(operator, value) },
      };

    case 'ModelNumber':
      return {
        ModelNumber: { $regex: getRegexByOperator(operator, value) },
      };

    default:
      return {};
  }
};

const InvSpareAddEqGrid: FC<InvSpareAddEqGridProps> = ({
  onSelect,
  ProductID,
  eqKeyData,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const highlightedRowId = useRef<string>('-1');
  const [gridRef, setGridRef] = useState<any>(null);
  const [selected, setSelected] = useState<any>(null);

  const loadData = async ({
    skip,
    limit,
    filterValue,
  }: {
    sortInfo: TypeSortInfo;
    skip: number;
    limit: number;
    filterValue: any;
  }): Promise<{ data: any[]; count: number }> => {
    const db = await getDatabase();

    // TODO: Handle filtervalue.OPERATOR
    let selector = {
      EqKey: { $nin: eqKeyData || [] },
    };

    await Promise.all(
      filterValue.map(async (v) => {
        if (isEmpty(v.value)) return v;

        const s = await getSelectorByFilterName(
          v.name,
          v.value,
          v.operator,
          db,
        );

        selector = {
          ...selector,
          ...s,
        };
        return v;
      }),
    );
    const length = size(await db.equipment.find().exec());

    const items = await db.equipment
      .find({
        selector,
        skip,
        limit,
        // sort,
      })
      .exec();

    const data = await Promise.all(items.map(transformData));
    return { data, count: length };
  };

  const init = async (ref: MutableRefObject<TypeComputedProps | null>) => {
    const db = await getDatabase();

    // // Always reload grid on new item / update / delete.
    db.equipment.$.subscribe(async (ev) => {
      if (ev.operation === 'INSERT' || ev.operation === 'UPDATE') {
        highlightedRowId.current = ev.documentId;
        ref.current?.reload();
      }
      if (ev.operation === 'DELETE') {
        highlightedRowId.current = '-1';
        ref.current?.reload();
      }
    });
  };

  const onReady = (ref: MutableRefObject<TypeComputedProps | null>) => {
    setGridRef(ref);
    init(ref);
  };

  const onLoadingChange = (status: boolean) => {
    // If loading completed - check if there any items that needs to be highlighted.
    if (!status && highlightedRowId.current !== '-1') {
      gridRef?.current?.scrollToId(highlightedRowId.current);
      gridRef?.current?.setSelectedById(highlightedRowId.current, true);
      highlightedRowId.current = '-1';
    }
    setLoading(status);
  };

  const dataSource = useCallback(loadData, []);

  const onSelectionChange = useCallback(({ selected: selectedMap, data }) => {
    // TODO: create array and send it to save/upsert to, once support array upsert

    const newSelected = Object.keys(selectedMap).map((id) => id * 1);

    setSelected(newSelected);

    onSelect(Object.keys(selectedMap));
  }, []);

  const columns = [
    {
      name: 'UniqueName',
      header: 'Equipment Name',
      flex: 1,
    },

    {
      name: 'Manufacturer',
      header: 'Manufacturer',
      flex: 1,
    },

    {
      name: 'ModelNumber',
      header: 'Model Number',
      flex: 1,
    },
  ];

  return (
    <div data-testid="data-grid" className="flex flex-col flex-grow p-4 inv-space-add-eq-grid">
      <LicensedReactDataGrid
        // onRowClick={onRowClick}
        onLoadingChange={onLoadingChange}
        defaultLimit={5000}
        livePagination
        onReady={onReady}
        rowHeight={40}
        checkboxColumn
        defaultSelected={[]}
        loading={loading}
        //   enableSelection
        onSelectionChange={onSelectionChange}
        defaultFilterValue={filter}
        idProperty="EqKey"
        // theme="default-dark"
        columns={columns}
        dataSource={dataSource}
        defaultSortInfo={[
          { name: 'UniqueName', dir: 1 },
          { name: 'Manufacturer', dir: 1 },
          { name: 'ModelNumber', dir: 1 }
        ]}
      />
    </div>
  );
};

export default InvSpareAddEqGrid;
