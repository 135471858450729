import { FC } from 'react';
import { Control, RegisterOptions } from 'react-hook-form';
import TreeSelect from '../../UI/Forms/TreeSelect/index';
import { useAuth } from '../../../contexts/auth';
import { useGetCategories } from 'src/hooks/categories/useGetCategories';

type CategoryProps = {
  label: string;
  control: Control;
  name: string;
  onChange: (field: string, value: any) => void;
  rules?: RegisterOptions;
  defaultValue?: string | null;
  disabled?: boolean;
  sourceForm?: string;
  allDepts?: boolean;
};

const CategoryDropdown: FC<CategoryProps> = ({
  control,
  name,
  label,
  onChange,
  rules,
  defaultValue,
  disabled,
  sourceForm,
  allDepts = false,
}) => {
  const { user } = useAuth();
  const { data } = useGetCategories();

  const handleFilter = (item: any) => {
    switch (sourceForm) {
      case 'task':
        return getTwoNotesCondition(item, 'WORKISSUES');
      case 'logentry':
        return getTwoNotesCondition(item, 'LOGENTRY');
      case 'equipment':
        return getTwoNotesCondition(item, 'EQUIPMENT');
      case 'event-scheduling':
        return getTwoNotesCondition(item, 'EVENT_SCHEDULING');
      case 'drill-scheduling':
        return getTwoNotesCondition(item, 'DRILL_SCHEDULING');
      case 'general-inventory':
        return getTwoNotesCondition(item, 'INVENTORY');
      case 'beverages':
        return getOneNoteCondition(item, 'BEVERAGES');
      case 'uniforms':
        return getOneNoteCondition(item, 'UNIFORMS');
      case 'stores':
        return getOneNoteCondition(item, 'STORES');
      case 'medical':
        return getOneNoteCondition(item, 'MEDICAL');
      case 'inspections':
        return getTwoNotesCondition(item, 'INSPECTIONS');
      case 'ISPSDOCS':
        return getOneNoteCondition(item, 'ISPSDOCS');
      case 'PHOTOS':
        return getOneNoteCondition(item, 'PHOTOS');
      case 'DRAWINGS':
        return getTwoNotesCondition(item, 'DRAWINGS');
      case 'SMSDOCS':
        return getOneNoteCondition(item, 'SMSDOCS');
      default:
        return true;
    }
  };

  const getOneNoteCondition = (item: any, field: string) => {
    if (item.fldTags && item.fldTags.includes(field)) {
      return true;
    }
    return false;
  };

  const getTwoNotesCondition = (item: any, field: string) => {
    if (allDepts) {
      if (!item.fldTags || item.fldTags.includes(field)) {
        return true;
      }
      return false;
    }
    if (
      (!item.fldTags || item.fldTags.includes(field))
      && (!item.fldDepts || item.fldDepts.includes((user?.Department || '').toUpperCase()))
    ) {
      return true;
    }
    return false;
  };

  const categories = data.filter(handleFilter).map((elem: any) => ({
    pkey: elem.pkey,
    parentId: elem.parentId,
    fldCategory: elem.fldCategory,
    expanded: false,
  }));

  return (
    <TreeSelect
      disabled={disabled}
      onChange={onChange}
      options={categories}
      control={control}
      name={name}
      valueExpr="pkey"
      keyExpr="pkey"
      parentIdExpr="parentId"
      displayExpr="fldCategory"
      label="Category"
      rules={rules}
    />
  );
};

export default CategoryDropdown;
