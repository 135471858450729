import { Box, Grid } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Control, UseFormWatch } from "react-hook-form";
import Autocomplete from "src/components/UI/Forms/Autocomplete";
import { TblDdListDefaults } from "src/generated/graphql";
import { InventoryDocument } from "src/pages/InventoryPage/rxdb";
import { getDatabase } from "src/rxdb";
import { toJSON } from "src/utils";

interface EventBasedScheduleProps {
    form: any;
    initialValue: any;
    onChange: (field: string, value: any) => void;
    control: Control;
    watch: UseFormWatch<any>;
    getValues: any;
    disableEdit?: boolean;
    setValue: any;
}

const EventBasedSchedule: FC<EventBasedScheduleProps> = ({
    onChange,
    control,
    watch,
    setValue
}) => {
    const [recurTypes, setRecurTypes] = useState<InventoryDocument[]>([]);
    const scheduleType = watch("scheduleType");

    useEffect(() => {
        (async () => {
            const db = await getDatabase();
            db.tblddlistdefaults.find({ selector: { deletedBy: { $eq: null } } }).$.subscribe((defaults: TblDdListDefaults[]) => {
                const recurTypeList = defaults.filter((d: TblDdListDefaults) => d.fldListName === "Recur Type" && d.fldMember !== "");
                setRecurTypes(toJSON(recurTypeList));
            });
        })();

        setValue("RecurType", "100");
        setValue("fldTimeMeasure", null);
        setValue("fldTimeWarnMeasure", null);
        setValue("fldDateTrigger", null);
    }, []);

        return (
            <div>
                {scheduleType?.id === "4" || scheduleType === "Event Based"
                    ? <Box>
                        <div className="pt-4">
                            <Grid container spacing={5}>
                                <Grid item xs={12} md={6}>
                                    <Autocomplete
                                        onChange={onChange}
                                        label={"Choose or Enter Event"}
                                        options={recurTypes}
                                        control={control}
                                        name={"fldUserDefined"}
                                        keyExpr="autoId"
                                        displayExpr="fldMember"
                                        rules={{ required: true }}
                                        freeSolo
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </Box>
                    : null
                }
            </div>
        )
    }

export default EventBasedSchedule
