import { FC } from 'react';
import { IconButton, Dialog, DialogContent, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface InjectedProps {
  popupVisible: boolean;
  onClose: any;
  imageSrc: any;
}

const ImagePreviewPopup: FC<InjectedProps> = ({
  imageSrc,
  popupVisible,
  onClose,
}) => (
  <Dialog
    open={popupVisible}
    onClose={onClose}
    fullWidth
    maxWidth="lg"
    scroll="paper"
    aria-labelledby="scroll-dialog-title"
    aria-describedby="scroll-dialog-description"
  >
    <DialogTitle sx={{ m: 0, p: 4 }} style={{ fontSize: '26px' }}>
      <span className="ml-2">Image Preview</span>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
    <DialogContent dividers>
      <img src={imageSrc} alt="src" />
    </DialogContent>
  </Dialog>
);

export default ImagePreviewPopup;
