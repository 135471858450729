// This group of imports should be in every data detail component
import '../../../App.css';
import '../../../theme/styles.css';
import '../styles.css';
import { v4 as uuid } from 'uuid';
import React, { FC, useEffect, useRef, useState } from 'react';
import { isNil, size, last, omit, isEmpty, isArray, isString, isNaN } from 'lodash';
import { RxDocument } from 'rxdb';

// Third party components go here
// check here for @mui/icon-materials values https://mui.com/components/material-icons/
import { Undo, DeleteTwoTone } from '@mui/icons-material';
import { Icon, Alert, IconButton, Snackbar } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { Subscription } from 'rxjs';
import { EquipmentDocument } from 'src/pages/EquipmentPage/rxdb';
import { WorkIssuesDocument } from 'src/rxdb/collections/WorkIssues/rxdb';
import { InventoryDocMethods, InventoryDocType } from 'src/pages/InventoryPage/rxdb';
import Tabs from '../../UI/Tabs';
import Input from '../../UI/Forms/Input';
import StickyAppBar from '../../UI/StickyAppBar';
import WarningDialog from 'src/components/UI/WarningDialog';
import { useAuth } from 'src/contexts/auth';
import { useAppState } from 'src/contexts/app-state';

// images
import LogIconAdd from '../../../assets/icon-log-add-primary.svg';
import TaskIconAdd from '../../../assets/icon-tasks-add-primary.svg';

// Reusable module components go here
import { getDatabase } from '../../../rxdb';
import CommentsOld from '../../../modules/Comments/indexOld';
import AttachmentTabOld from '../../../modules/Attachments/indexOld';
import { CHAR_LIMIT, equipmentDeleteWarningMessage, FileExtensionType } from '../../../consts';
import EqSpares from './component/EqSpares';

// All module specific components go here
import { Equipment } from '../../../generated/graphql';
import EqSummaryForm from './component/EqSummaryForm';
import EqOutStandingTasks from './component/EqOutStandingTasks';
import { validateForm } from './utils';
import EqNewTaskDialog from './component/EqNewTaskDialog';
import HistoryTab from 'src/components/HistoryTab';
import EqHoursUtility from './component/EqHoursUtility';
import RecordEditWarningCard from 'src/components/UI/RecordEditWarningCard';
import { filterNonNullStrings, handleCharLimitWarning, removeNonAlphanumericChars } from 'src/utils';
import Schedules from "../../../modules/Schedules";
import ScheduleTabButton from "../../../modules/Schedules/components/ScheduleTabButton";
import { normalizeDateTime, normalizeDateFormValue } from 'src/helpers';
import { InjectedDrawerProps } from 'src/components/PageDrawer';
import CompaniesDropdownOld from 'src/components/Dropdowns/CompaniesDropdown/indexOld';
import LogEntryFormDialog from '../logentryDE/LogEntryFormDialog';
import { LogEntry } from 'src/generated/dotnet.graphql';
import { AccessType, getModuleReadOnly } from 'src/utils/permissions';
import { useGetEquipmentById } from 'src/hooks/equipment/useGetEquipmentById';

const useStyles = makeStyles((theme) => ({
  staticTooltipLabel: {
    whiteSpace: "nowrap",
    maxWidth: 900,
  },
}));

interface Props extends Partial<InjectedDrawerProps> {
  initialValue: EquipmentDocument;
  onCancel: () => void;
  onSave: (eqitem: Equipment, isCreated: boolean, type?: string) => void;
  onDelete: (eqitem: EquipmentDocument) => void;
  onUndo?: () => void;
  type?: string;
  editFlag?: boolean;
  isCreated?: boolean;
}

const EqDetailForm: FC<Props> = ({
  initialValue,
  onCancel,
  onSave,
  onDelete,
  onUndo,
  type,
  editFlag = false,
  isCreated = false,
  setFormIsDirty,
}) => {
  const classes = useStyles();
  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState,
    register,
  } = useForm<any>({
    // For uncontrolled components keep empty string or undefined. Null wouldn't work.
    defaultValues: {
      UniqueName: initialValue.UniqueName || '',
      Manufacturer: initialValue.Manufacturer,
      ModelNumber: initialValue.ModelNumber,
      Hours: initialValue.Hours || 0,
      fldCountHours: initialValue.fldCountHours|| false,
      fldSRHKey: initialValue.fldSRHKey,
      fldLocHierarchy: initialValue.fldLocHierarchy,
      Department: initialValue?.Department || null,
      fldReportingType: initialValue?.fldReportingType || null,
      fldRestricted: initialValue.fldRestricted || null,
      fldSMS: initialValue.fldSMS || false,
      Notes: initialValue.Notes || '',
      Supplier: initialValue.Supplier,
      fldInService: normalizeDateFormValue(initialValue.fldInService),
      fldExpireDate: normalizeDateFormValue(initialValue.fldExpireDate),
      SerialNum: initialValue.SerialNum,
      ArrangementNum: initialValue.ArrangementNum,
      Rating: initialValue.Rating,
      ArtNumber: initialValue.ArtNumber,
      DateEntered: normalizeDateFormValue(initialValue?.DateEntered),
    },
  });

  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });

  const { settingsPersonal } = useAppState();
  const { user } = useAuth();
  const [oldEqUndo, setoldEqUndo] = useState<Equipment[]>([]);
  const [relatedLogEntriesKeys, setRelatedLogEntriesKeys] = useState<string[]>([]);
  const [eqitem, setEqItem] = useState<Equipment>(initialValue.toJSON());
  const [attachmentCount, setAttachmentCount] = useState<number>(0);
  const [historyAttachmentCount, setHistoryAttachmentCount] = useState<number>(0);
  const [photoCount, setPhotoCount] = useState<number>(0);
  const [spareCount, setSpareCount] = useState<number>(0);
  const [historyCount, setHistoryCount] = useState<number>(0);
  const [outStandingTasksCount, setOutStandingTasksCount] = useState<number>(0);
  const [commentsCount, setCommentsCount] = useState<number>(0);
  const activeSubscriptions = useRef<Subscription[]>([]);
  const formInitialValues = useRef<any>({});
  const [newtaskDialogVisible, setNewtaskDialogVisible] = useState<boolean>(false);
  const [selectedIssue, setSelectedIssue] = useState<WorkIssuesDocument>();
  const [open, setOpen] = React.useState(false);
  const [hoursDialog, setHoursDialog] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [disableEdit, setDisableEdit] = useState(false);
  const [disableEditDepartment, setDisableEditDepartment] = useState(false);

  // Temporary getting the equipment by ID for populating LogEntry form on "Add LogEntry" until refactoring equipment module with the new .net api
  const { getEquipmentById } = useGetEquipmentById();

  // START Refactored to the new .net api 
  const [selectedLogEntry, setSelectedLogEntry] = useState<LogEntry>();
  const logEntryModuleReadOnly = getModuleReadOnly(settingsPersonal.fldLogsMaint) !== AccessType.FULL_ACCESS;
  const [isLogEntryCreate, setIsLogEntryCreate] = useState<boolean>(false);

  const handleCreateLogEntry = async () => {
    setIsLogEntryCreate(true);

    // Temporary getting the equipment by ID for populating LogEntry form on "Add LogEntry" until refactoring equipment module with the new .net api
    const queryResult = await getEquipmentById({ variables: {eqKey: initialValue?.EqKey } });
    const equipmentById = queryResult?.data?.equipmentById;

    // TODO - revise initailValue's fields naming after refactor into dotnet api
    const logEntry: any = {
      logDate: new Date().toISOString(),
      fldPerformedBy: `${user?.fldFirst} ${user?.fldLast}`,
      equipment: equipmentById,
      department: initialValue?.Department,
      fldSrhkey: initialValue.fldSRHKey,
      fldLocHierarchy: initialValue.fldLocHierarchy,
      eqKey: initialValue?.EqKey,
    };
    setSelectedLogEntry(logEntry);
  };

  const handleSaveLogEntry = (responseData: LogEntry, responseMessage: string, isCreated: boolean) => {
    if (isLogEntryCreate) {
      setSelectedLogEntry(responseData);
      setIsLogEntryCreate(false)
    } else {
      setSelectedLogEntry(undefined);
    }
    // TODO - once eq module gets refactored on the new .net api, change snackbar to display common message from upsertLogentry mutation "responseMessage"
    setSnackbar({
      open: true,
      message: 'Log entry added successfully!',
      type: 'success',
    });
  };
  // END

  const { EQUIPMENT } = CHAR_LIMIT;
  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getDocumentCount = async () => {
    if (isNil(initialValue.EqKey)) {
      return;
    }

    const db = await getDatabase();

    const logEntries = await db.logentry.find({
      selector: {
        EqKey: initialValue.EqKey,
        deletedAt: { $eq: null }
      },
    }).exec();

    const keys = filterNonNullStrings(logEntries?.map(entry => entry.PKey));
    setRelatedLogEntriesKeys(keys);
    // setHistoryCount(size(logEntries)); TODO - Remove when refactor the whole component - not using rxdb for Logentries at this point

    activeSubscriptions.current = [
      db.tbldocumentcrossreference.find({
        selector: {
          fldRecordKey: { $in: [initialValue.EqKey, ...keys] },
          deletedAt: { $eq: null },
        },
      }).$.subscribe(async (attachments: any) => {
        const results = {
          logentryTable: [] as (string | undefined)[],
          equipmentTable: [] as (string | undefined)[],
        };

        await Promise.all(
          attachments.map(async (attachment: any) => {
            const revision = await db.documentrevision
              .findOne({
                selector: {
                  fldFKey: attachment.fldFKey,
                  deletedBy: null,
                },
              })
              .exec();

            const fileExtension = revision
              ? revision.fldFileName
                ? last(revision.fldFileName.split('.'))
                : undefined
              : undefined;

            if (attachment.fldTableName === 'LogEntry') {
              results.logentryTable.push(fileExtension);
            } else if (attachment.fldTableName === 'Equipment') {
              results.equipmentTable.push(fileExtension);
            }
          })
        );

        setPhotoCount(
          size(
            results.equipmentTable
              .filter((e) => e)
              .filter((extension) => [
                FileExtensionType.JPEG,
                FileExtensionType.JPG,
                FileExtensionType.PNG,
                FileExtensionType.TIF,
                FileExtensionType.BMP,
              ].includes(extension!.toUpperCase() as any)),
          ),
        );

        setAttachmentCount(
          size(
            results.equipmentTable
              .filter((e) => e)
              .filter((extension) => [
                FileExtensionType.PDF,
                FileExtensionType.XLS,
                FileExtensionType.XLSX,
                FileExtensionType.DOC,
                FileExtensionType.DOCX,
                FileExtensionType.RTF,
              ].includes(extension!.toUpperCase() as any)),
          ),
        );

        setHistoryAttachmentCount(
          size(
            results.logentryTable
              .filter((e) => e)
              .filter((extension) => [
                FileExtensionType.PDF,
                FileExtensionType.XLS,
                FileExtensionType.XLSX,
                FileExtensionType.DOC,
                FileExtensionType.DOCX,
                FileExtensionType.RTF,
              ].includes(extension!.toUpperCase() as any)),
          ),
        );
      }),
   
      db.comments
        .find({
          selector: {
            $and: [
              {referenceIssueId: isNil(initialValue.EqKey)  ? '' : initialValue.EqKey},
              {deletedAt: {$eq: null}},
            ],
          },
        })
        .$.subscribe((c: any) => {
          setCommentsCount(size(c));
        }),

      db.workissues
        .find({
          selector: {
            EqKey: initialValue.EqKey,
            Completed: { $eq: false },
            deletedAt: { $eq: null },
          },
        })
        .$.subscribe((c: any) => {
          setOutStandingTasksCount(size(c));
        }),

      db.inveq
        .find({
          selector: {
            EqKey: initialValue.EqKey,
            deletedAt: { $eq: null },
          },
        })
        .$.subscribe(async (inventoryItems: any) => {
          const results = await Promise.all<RxDocument<InventoryDocType, InventoryDocMethods> | null>(
            inventoryItems.map(async (invItem: any) => {
              const revision = await db.inventory
                .findOne({
                  selector: {
                    ProductID: invItem.ProductID,
                    deletedAt: { $eq: null },
                  },
                })
                .exec();
              return revision;
            }),
            );
          // Filter out null values from the results array
          const validResults = results.filter(result => result !== null);
          setSpareCount(size(validResults));
        }),
    ];
  };

  const setInitialValues = async () => {
    // const department = await initialValue.populate("Department");
    // const fldreportingtype = await initialValue.populate('fldReportingType');
    // const category = await initialValue.populate("fldSRHKey");

    const defaultValues = {
      ...getValues(),
      // fldSRHKey: category?.toJSON() || null, // avoid infinite loop.
      // Department: department?.toJSON() || null,
      // fldReportingType: fldreportingtype?.toJSON || null,
      fldLocHierarchy: initialValue.fldLocHierarchy || null,
    };

    formInitialValues.current = defaultValues;
    reset(defaultValues);
  };

  const onTaskComplete = async () =>{
    const db = await getDatabase()
    const eq = await db.equipment.findOne({
      selector:{
        EqKey: initialValue.EqKey
      }
    }).exec()
    setValue('Hours',eq?.Hours)
  }

  const setDeptHeadPermission = async ()=>{
    if(initialValue.fldRestricted && !settingsPersonal.fldDeptHead){
      setDisableEdit(true)
    }
    if(settingsPersonal.fldAllDepts === 1 && user?.Department != initialValue.Department){
      setDisableEdit(true)
    }
    if(settingsPersonal.fldAllDepts != 2 ){
      setDisableEditDepartment(true)
    }
  }

  useEffect(()=>{
    if(settingsPersonal){
      setDeptHeadPermission()
    }
  },[getValues().fldRestricted,settingsPersonal])

  useEffect(() => {
    getDocumentCount();
    setInitialValues();

    return () => {
      activeSubscriptions.current?.map((sub) => sub.unsubscribe());
      activeSubscriptions.current = [];
      formInitialValues.current = {};
    };
  }, []);

  const onChange = async (name: string, value: any) => {
    let shouldDirty = true;

    if (name === 'fldSRHKey') {
      const updatedValue = (isArray(value) ? last(value) : value) || null;
      if (initialValue.fldSRHKey === updatedValue) {
        shouldDirty = false;
      }
    }

    if (name === 'fldLocHierarchy') {
      const updatedValue = (isArray(value) ? last(value) : value) || null;
      if (initialValue.fldLocHierarchy === updatedValue) {
        shouldDirty = false;
      }
    }
    setValue(name, value, { shouldDirty: shouldDirty });
  };

  const handleCancel = () => {
    setoldEqUndo([]);
    onCancel();
  };

  const isCreation = isCreated;

  const handleSave = async (data: any) => {
    if (!validateForm(data, setSnackbar)) return;

    const db = await getDatabase();

    // Create items before creating Item.
    const getOrCreate = async (value: any, keyExpr: string) => {
      if (isNil(value)) return null;

      if (value.isCreate) {
        // Create item first and then proceed
        const collection = (db as any)[value.collection];

        // TODO: Hook up tblDefaults
        const result = await collection?.upsert(
          omit(value, ['inputValue', 'isCreate', 'collection']),
        );

        return result[keyExpr];
        // create value collection
      }

      return value[keyExpr];
    };

    const {
      UniqueName,
      Manufacturer,
      ModelNumber,
      Hours,
      fldCountHours,
      fldParent,
      fldSRHKey,
      fldLocHierarchy,
      Department,
      fldReportingType,
      fldRestricted,
      fldSMS,
      Notes,
      Supplier,
      fldInService,
      fldExpireDate,
      SerialNum,
      ArrangementNum,
      Rating,
      ArtNumber,
      DateEntered,
    } = data;

    const document = {
      ...eqitem,
      UniqueName,
      Manufacturer: typeof Manufacturer === 'object' ? Manufacturer?.DisplayMember : Manufacturer || null,
      ModelNumber,
      Hours: parseInt(Hours, 10) || null,
      fldCountHours,
      fldParent: fldParent || null,
      DateEntered,
      fldSRHKey: (isArray(fldSRHKey) ? last(fldSRHKey) : fldSRHKey) || null,
      fldLocHierarchy:
        (isArray(fldLocHierarchy) ? last(fldLocHierarchy) : fldLocHierarchy)
        || null,
      Department: isString(Department)
        ? Department
        : Department?.fldMember || null,
      fldReportingType: isString(fldReportingType)
        ? fldReportingType
        : fldReportingType?.fldMember || null,
      fldRestricted,
      fldSMS,
      Notes,
      Supplier: Supplier?.DisplayMember || Supplier || null,
      fldInService: !isNaN(fldInService) ? normalizeDateTime(fldInService) : null,
      fldExpireDate: !isNaN(fldExpireDate) ? normalizeDateTime(fldExpireDate) : null,
      SerialNum,
      ArrangementNum,
      Rating,
      ArtNumber,
      SearchMaker: isNil(Manufacturer)? null: typeof Manufacturer === 'object' ? removeNonAlphanumericChars(Manufacturer?.DisplayMember) : removeNonAlphanumericChars(Manufacturer) || null,
      SearchMod: isNil(ModelNumber)? null: removeNonAlphanumericChars(ModelNumber),
      updatedAt: new Date().toISOString(),
      // Since we are passing empty object from parent we should distinguish create/update actions.
      // In case of Update we have to pass primary key (EqKey)
      EqKey: initialValue.primary || uuid(), // Set primary key, so we will be able to upsert.
    } as any;

    try {
      setoldEqUndo([]);
      setEqItem(document)
      const res = await db.collections.equipment.upsert(document);

      onSave(res, isCreated);

      reset(getValues());
    } catch (e: any) {
      setSnackbar({
        open: true,
        type: 'error',
        message: e.message,
      });
    }
  };
  const handleDelete = () => {
    setIsDeleting(true)
  };

  const handleDeleteOk = () => {
    onDelete(initialValue);
    setIsDeleting(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleting(false);
  }

  // Is this the correct way to handle calling modals from the form?
  const handleAddTask = async () => {
    const db = await getDatabase();
    const department = await db.tblddlistdefaults
      .findOne({
        selector: {
          fldListName: 'Department',
          fldMember: initialValue?.Department,
        },
      })
      .exec();
    const newIssue: WorkIssuesDocument = await db.workissues.newDocument({
      JobNumber: uuid(),
      fldSRHKey: initialValue.fldSRHKey,
      fldLocHierarchy: initialValue.fldLocHierarchy,
      // Department: department?.PKey,
      Department: initialValue?.Department,
      EqKey: initialValue?.EqKey,
    });
    setSelectedIssue(newIssue);
    setNewtaskDialogVisible(true);
  };

  const handleHours = () => {
    setHoursDialog(true);
  }

  const handleUndo = () => {
    const item = last(oldEqUndo);
    setEqItem(item as Equipment);
    // Remove last step from our store
    setoldEqUndo(oldEqUndo.splice(-1));

    onUndo && onUndo();
  };

  const handleCancelUndo = () => {
    if (isCreation) {
      return onCancel();
    }
    reset(formInitialValues.current);
  };

  const handleOk = (isEditing: boolean) => {
    if (isEditing && !validateForm(getValues(), setSnackbar) &&!disableEdit) return;
    if (isEditing && !disableEdit) return; // We will send submit action that will be handled in HandleSave.

    handleCancel();
  };

  const handleCancelDialog = () => {
    setSelectedIssue(undefined);
    setNewtaskDialogVisible(false);
    setSelectedLogEntry(undefined);
    setHoursDialog(false);
    handleClose();
  };

  const onNewTaskSave = async () => {
    setSelectedIssue(undefined);
    setNewtaskDialogVisible(false);
    const db = await getDatabase();
    const payload = {
      ...eqitem,
      EqKey: initialValue.primary || uuid(),
      updatedAt: new Date().toISOString(),
    } as any;
    try {
      const res = await db.collections.equipment.upsert(payload);
      onSave(res, false, 'tasks');
      getDocumentCount();
    } catch (e: any) {
      setSnackbar({
        open: true,
        type: 'error',
        message: e.message,
      });
    }
    setSnackbar({
      open: true,
      message: 'New task has been created!',
      type: 'success',
    });
  };

  const onOutStandingTaskDelete = async (key: string) => {
    const db = await getDatabase();
    const payload = {
      ...eqitem,
      EqKey: initialValue.primary || uuid(),
      updatedAt: new Date().toISOString(),
    } as any;
    try {
      const res = await db.collections.equipment.upsert(payload);
      onSave(res, false, 'tasks');
      getDocumentCount();
    } catch (e: any) {
      setSnackbar({
        open: true,
        type: 'error',
        message: e.message,
      });
    }
  };

  const onOutStandingTaskCreate = async () => {
    const db = await getDatabase();
    const payload = {
      ...eqitem,
      EqKey: initialValue.primary || uuid(),
      updatedAt: new Date().toISOString(),
    } as any;
    try {
      const res = await db.collections.equipment.upsert(payload);
      onSave(res, false, 'tasks');
    } catch (e: any) {
      setSnackbar({
        open: true,
        type: 'error',
        message: e.message,
      });
    }
  };

  if (isNil(eqitem)) return null;

  const hasValuesBeenChanged = formState.isDirty
    && (size(formState.dirtyFields) > 0 || size(formState.touchedFields) > 0);

  const isEditing = hasValuesBeenChanged || isCreation;
    
  useEffect(() => {
    setFormIsDirty && setFormIsDirty(hasValuesBeenChanged);
  }, [hasValuesBeenChanged]);
  
  const actions = [
    {
      icon: (
        <Icon>
          <img src={TaskIconAdd} alt="" />
        </Icon>
      ),
      name: 'Add task',
      onclick: handleAddTask,
    },
    {
      icon: (
        <Icon>
          <img src={LogIconAdd} alt="" />
        </Icon>
      ),
      name: 'Add Log Entry',
      onclick: handleCreateLogEntry,
    },
    // hiding 'Equipment Hours' icon based on TD-1480
    // {
    //   icon: (
    //     <Icon>
    //       <img src={ClockIconAdd} alt="" />
    //     </Icon>
    //   ),
    //   name: 'Equipment Hours',
    //   onclick: handleHours,
    // },
  ];

  const relatedKeys = filterNonNullStrings([initialValue.EqKey]);

  const formClass = type === 'Dialog'
    ? 'relative bg-white flex-grow'
    : 'relative bg-white pt-14 md:pt-19 flex-grow';

  return (
    <>
      <form
        id="EquipmentDetailForm"
        className={`${formClass}`}
        onSubmit={handleSubmit(handleSave)}
      >
        <div className="bg-white h-full flex-grow pt-6">
          <div className="px-6 h-full flex flex-col">
            <div className="mb-6">
              {(disableEdit || editFlag) && (
                <RecordEditWarningCard />
              )}
              <div className="mui-textfield-header mb-2">
                <Input
                  inputProps={{
                    size: 'medium',
                    label: 'Equipment Name',
                    variant: 'standard',
                  }}
                  rules={{ required: true, maxLength: EQUIPMENT.UniqueName }}
                  warning={(value) => handleCharLimitWarning(value, EQUIPMENT.UniqueName)}
                  control={control}
                  name="UniqueName"
                />
              </div>

              <div className="mt-3">
                <CompaniesDropdownOld
                  control={control}
                  label="Manufacturer"
                  name="Manufacturer"
                  onChange={onChange}
                  variant="standard"
                  size="small"
                />
              </div>

              <div className="mt-3">
                <Input
                  inputProps={{
                    size: 'small',
                    label: 'Model Number',
                    variant: 'standard',
                  }}
                  rules={{ maxLength: EQUIPMENT.ModelNumber }}
                  warning={(value) => handleCharLimitWarning(value, EQUIPMENT.ModelNumber)}
                  defaultValue={initialValue.ModelNumber}
                  control={control}
                  name="ModelNumber"
                />
              </div>
            </div>

            <div className="mt-3">
              <Tabs
                tabs={[
                  {
                    label: 'Summary',
                    component: (
                      <EqSummaryForm
                        watch={watch}
                        control={control}
                        initialValue={initialValue}
                        form={eqitem}
                        onChange={onChange}
                        getValues={getValues}
                        setValue={setValue}
                        disableEdit = {disableEdit}
                      />
                    ),
                  },
                  {
                    label: `Tasks (${outStandingTasksCount})`,
                    component: (
                      <EqOutStandingTasks
                        initialValue={initialValue}
                        onDelete={onOutStandingTaskDelete}
                        onSave={onOutStandingTaskCreate}
                        count={outStandingTasksCount}
                        onTaskComplete={onTaskComplete}
                        disableEdit = {disableEdit}
                      />
                    ),
                  },
                  {
                    label: `Spares (${spareCount})`,
                    component: (
                      <EqSpares 
                        initialValue={initialValue}
                        disableEdit={disableEdit}
                        editFlag={editFlag}
                      />
                    ),
                  },
                  {
                    // label: `History (${historyCount})`, // TODO - temporary hide the count until refactoring Equipment mudule with the .net api
                    label: `History`,
                    component: (
                      <HistoryTab 
                        keyName={'eqKey'}
                        keyValue={initialValue.EqKey} 
                        setHistoryCount={setHistoryCount}
                        fldCountHours={initialValue.fldCountHours as boolean}
                        disableEdit={disableEdit} 
                      />
                    ),
                  },
                  {
                    label: (
                      <ScheduleTabButton 
                        selector={{ EqKey: initialValue.EqKey , deletedAt:{$eq:null} }} 
                      />
                    ),
                    component: (
                      <Schedules 
                        EqKey={initialValue.EqKey} 
                        selector={{ EqKey: initialValue.EqKey, deletedAt:{$eq:null} }} 
                        disableEdit={disableEdit} 
                      />
                    )
                  },
                  {
                    label: `Attachments (${attachmentCount})`,
                    component: (
                      <AttachmentTabOld
                        type="attachments"
                        primaryKey={initialValue.EqKey}
                        relatedKeys={relatedKeys}
                        allowedFileExtensions={['.pdf']}
                        disabled={editFlag}
                        SRHKey={initialValue.fldSRHKey}
                        tableName="Equipment"
                        disableEdit ={disableEdit}
                      />
                    ),
                  },
                  {
                    label: `History Attachments (${historyAttachmentCount})`,
                    component: (
                      <AttachmentTabOld
                        type="attachments"
                        primaryKey={initialValue.EqKey}
                        relatedKeys={relatedLogEntriesKeys}
                        allowedFileExtensions={['.pdf']}
                        disabled={editFlag}
                        SRHKey={initialValue.fldSRHKey}
                        tableName="Logentry"
                        disableEdit ={true} // set to true as we only need to display related attachments
                      />
                    ),
                  },
                  {
                    label: `Photos (${photoCount})`,
                    component: (
                      <AttachmentTabOld
                        type="photo"
                        primaryKey={initialValue.EqKey}
                        relatedKeys={relatedKeys}
                        SRHKey={initialValue.fldSRHKey}
                        allowedFileExtensions={[
                          '.jpg',
                          '.jpeg',
                          '.gif',
                          '.png',
                        ]}
                        disabled={editFlag}
                        tableName="Equipment"
                        disableEdit ={disableEdit}
                      />
                    ),
                  },
                  {
                    label: `Comments (${commentsCount})`,
                    component: (
                      <CommentsOld
                        selectorType="Equipment"
                        selectorKeyValue={initialValue.EqKey}
                        disableEdit={disableEdit}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </div>

        {type !== 'Dialog' && (
          <StickyAppBar
            cancelText="Cancel"
            okType={isEditing ? 'submit' : 'button'}
            okText={isEditing ? 'Save' : 'Close'}
            onOk={() => handleOk(isEditing)}
            disabled={(editFlag || disableEdit) && isEditing}
            onCancel={isEditing ? () => handleCancelUndo() : undefined}
          >
            {!disableEdit && !editFlag && !isNil(eqitem.EqKey) && (
              <Box sx={{ position: 'relative', height: 70 }}>
                <Backdrop open={open} />
                <SpeedDial
                  ariaLabel="SpeedDial tooltip example"
                  sx={{ position: 'absolute', bottom: 12, right: 12 }}
                  FabProps={{ size: 'small' }}
                  icon={(
                    <SpeedDialIcon
                      sx={{ fontSize: 'small' }}
                      icon={(<SpeedDialIcon />)}
                      openIcon={<CloseIcon />}
                    />
                  )}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  open={open}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      tooltipOpen
                      classes={classes}
                      onClick={action.onclick}
                    />
                  ))}
                </SpeedDial>
              </Box>
            )}
            {!editFlag && !isEmpty(oldEqUndo) && (
              <IconButton
                onClick={handleUndo}
                color="inherit"
                aria-label="Undo last step"
              >
                <Undo />
              </IconButton>
            )}
            {!editFlag && !isNil(eqitem.EqKey) && !disableEdit && (
              <IconButton
                onClick={handleDelete}
                color="error"
                aria-label="Delete item"
              >
                <DeleteTwoTone />
              </IconButton>
            )}
          </StickyAppBar>
        )}

        <WarningDialog
          visible={isDeleting}
          title="Delete Warning"
          content={equipmentDeleteWarningMessage}
          okText='Yes'
          color='error'
          onOk={handleDeleteOk}
          onCancel={handleDeleteCancel}
        />

        <Snackbar
          open={snackBar.open}
          autoHideDuration={2000}
          onClose={onSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
            {snackBar.message}
          </Alert>
        </Snackbar>
      </form>
      {hoursDialog && (
        <EqHoursUtility
          visible={hoursDialog}
          defaultEquipment={initialValue}
          onCancel={handleCancelDialog}
        />
      )}
      {!!selectedIssue && (
        <EqNewTaskDialog
          visible={newtaskDialogVisible}
          initialValue={selectedIssue}
          onSave={onNewTaskSave}
          onCancel={handleCancelDialog}
          title="Create New Task"
        />
      )}

      <LogEntryFormDialog 
        selectedItem={selectedLogEntry} 
        isCreate={isLogEntryCreate}
        onSave={handleSaveLogEntry} 
        onCancel={handleCancelDialog} 
        moduleReadOnly={logEntryModuleReadOnly}
        refetchQueryVariables={null}
        callerComponent='EqDetailForm'     
      />
    </>
  );
};

export default EqDetailForm;
