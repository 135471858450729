import { Alert } from "@mui/material";
import { useEffect, useState } from "react";
interface FileViewerTxtProps {
  url: string;
}

const FileViewerTxt = ({ url }: FileViewerTxtProps) => {
  const [content, setContent] = useState("");
  const [error, setError] = useState("");
  useEffect(() => {
    if (!url) return;
    const load = async () => {
      try {
        const res = await fetch(url);
        if (!res.ok) return setError(res.statusText);
        const body = await res.text();
        setContent(body);
      } catch (e: any) {
        setError(e.message);
      }
    };
    load();
  }, [url]);
  return (
    <div data-testid="file-viewer-txt">
      {error ? <Alert severity="error">{error}</Alert> : content}
    </div>
  );
};

export default FileViewerTxt;
