import { RxCollection, RxJsonSchema, RxDocument } from 'rxdb';

import { Certificate } from 'src/generated/graphql';

export type CertificatesDocType = Certificate;

export type CertificatesDocMethods = {};

export type CertificateDocument = RxDocument<
  CertificatesDocType,
  CertificatesDocMethods
>;

export type CertificatesCollectionMethods = {};

export type CertificatesCollection = RxCollection<
  CertificatesDocType,
  CertificatesDocMethods,
  CertificatesCollectionMethods
>;

export const certificatesSchema: RxJsonSchema<CertificatesDocType> = {
  title: 'certificates ',
  description: 'describes certificates items',
  version: 2,
  keyCompression: false,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    PKey: {
      type: 'string',
      primary: true,
    },
    fldType: {
      type: 'string',
    },
    DateIssue: {
      type: ['string', 'null'],
    },
    DateExpire: {
      type: ['string', 'null'],
    },
    fldCertNum: {
      type: ['string', 'null'],
    },
    fldCrewID: {
      type: ['string', 'null'],
      ref: 'crew',
    },
    fldSMS: {
      type: ['boolean', 'null'],
    },
    fldTimeWarn: {
      type: ['number', 'null'],
    },
    fldTimeWarnMeasure: {
      type: ['string', 'null'],
    },
    fldNotes: {
      type: ['string', 'null'],
    },
    fldResponsible: {
      type: ['string', 'null'],
      ref: 'tblddlistdefaults',
    },
    DateEntered: {
      type: ['string', 'null'],
    },
    fldCertType: {
      type: ['number', 'null'],
    },
    fldNationality: {
      type: ['string', 'null'],
      ref: 'tblddlistdefaults',
    },
    fldArchive: {
      type: ['boolean', 'null'],
    },
    fldIssue: {
      type: ['string', 'null'],
    },
    fldSRHKey: {
      type: ['string', 'null'],
      ref: 'tblsystemshierarchy',
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    updatedAt: {
      type: 'string',
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
  },
  indexes: ['fldType', 'updatedAt'],
  required: [],
  additionalProperties: false,
};

export const certificatesDocMethods: CertificatesDocMethods = {};

export const certificatesCollectionMethods: CertificatesCollectionMethods = {};
