import './styles.css';
import React, { useState } from 'react';
import { isNil } from 'lodash';
import { uuid } from 'uuidv4';
import { Alert, Snackbar, Button } from '@mui/material';
import DocumentHistoryGrid from './component/DocumentHistoryGrid';
import { getDatabase } from '../../rxdb';
import DocumentRevisionPopup from './component/DocumentRevisionPopup';
import { normalizeDateTime } from 'src/helpers';

export type AlertColor = 'success' | 'info' | 'warning' | 'error';
type Severity = 'error' | 'success' | 'info' | 'warning' | undefined;

interface Props {
  form?: any;
  data?: any;
  allowedFileExtensions: any;
  relatedKey: string;
  type?: string;
  disabled?: boolean;
}

export interface IAttachmentState {
  PKey: string;
  fldFKey: string | null;
  fldAltPath?: number | null;
  fldFileName?: string | null;
  fileExtension?: string;
  fldNotes?: string | null;
  fldPage?: any | null;
  fldRevision?: any | null;
  fldRevisionDate?: string | null;
  fldApprovedByPM?: string | null;
  deletedAt?: string | null;
}

interface IClickEvent {
  type: 'remove' | 'insert' | 'update';
  key: string;
  data?: IAttachmentState;
}

interface IFile {
  contentType: string;
  extension: string;
  key: string;
  name: string;
  url: string;
}

const defaultFile = {
  contentType: '',
  extension: '',
  key: '',
  name: '',
  url: '',
};

const DocumentRevision = ({
  relatedKey,
  allowedFileExtensions,
  type = 'attachments',
  disabled = false,
}: Props) => {
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<Severity>(undefined);
  const [uploadedFile, setUploadedFile] = useState<IFile>(defaultFile);

  const [isFormVisible, setFormVisible] = useState<boolean>(false);
  const [editableItem, setEditableItem] = useState<IAttachmentState>();

  const handleUpsert = async (data?: IAttachmentState) => {
    const db = await getDatabase();
    try {
      await db.documentrevision.upsert({
        PKey: data?.PKey || uuid(),
        fldFKey: relatedKey,
        fldFileName: data?.fldFileName || uploadedFile.key,
        fldNotes: data?.fldNotes,
        fldPage: String(data?.fldPage),
        fldRevision: String(data?.fldRevision),
        fldRevisionDate: normalizeDateTime(data?.fldRevisionDate || new Date()),
      });

      setEditableItem(undefined);
      setFormVisible(false);

      setAlert(true);
      setAlertMessage(
        !isNil(editableItem)
          ? 'Revision successfully updated'
          : 'Revision successfully created',
      );
      setAlertSeverity('success');
    } catch (e: any) {
      console.error(e);
      setAlert(true);
      setAlertMessage(e.message);
      setAlertSeverity('error');
    }
  };

  const handleDelete = async (key: string) => {
    const db = await getDatabase();
    try {
      const revision = await db.documentrevision
        .findOne({
          selector: {
            PKey: key,
          },
        })
        .exec();
      await revision?.remove();
      setAlert(true);
      setAlertMessage('Revision successfully removed');
      setAlertSeverity('success');
    } catch (e: any) {
      console.error(e);
      setAlert(true);
      setAlertMessage(e.message);
      setAlertSeverity('error');
    }
  };

  const handleAddClick = () => {
    setEditableItem(undefined);
    setFormVisible(true);
  };

  const onFormCancel = () => {
    setEditableItem(undefined);
    setFormVisible(false);
    setUploadedFile(defaultFile);
  };

  const onEdit = (data: IAttachmentState) => {
    setEditableItem(data);
    setFormVisible(true);
  };

  return (
    <>
      <div className="pt-5">
        <div className="flex justify-end mb-6">
          {!disabled && (
            <Button variant="contained" onClick={handleAddClick}>
              Add
            </Button>
          )}
        </div>
        <DocumentHistoryGrid
          documentId={relatedKey}
          // relatedKey={relatedKey}
          // allowedFileExtensions={allowedFileExtensions}
          // setUploadedFile={setUploadedFile}
          // type={type}
          onEdit={onEdit}
          onDelete={handleDelete}
          disabled={disabled}
        />
      </div>

      <DocumentRevisionPopup
        initialValues={editableItem}
        onCancel={onFormCancel}
        onSubmit={handleUpsert}
        visible={isFormVisible}
        setUploadedFile={setUploadedFile}
        uploadedFile={uploadedFile}
        type={type}
      />

      <Snackbar
        open={alert}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => {
          setAlert(false);
        }}
      >
        <Alert severity={alertSeverity}>{alertMessage}</Alert>
      </Snackbar>
    </>
  );
};

export default DocumentRevision;
