import { FC } from 'react';
import LicensedReactDataGrid from 'src/components/UI/LicensedReactDataGrid';
import { useMediaQuery, useTheme } from '@mui/material';
import { Typography, Card, CardContent, Grid, Box } from '@mui/material';
import { TblSchedChk } from 'src/generated/graphql';

const CommonGridUI = (title: string, value: any) => (
  <>
    <Grid item lg={2} sm={3}>
      <Typography
        sx={{ fontSize: 14, fontWeight: 700 }}
      >{`${title}:`}</Typography>
    </Grid>
    <Grid item lg={10} sm={9}>
      <Typography sx={{ fontSize: 13 }} className="flex">
        {title === 'Description' ? (
          <div dangerouslySetInnerHTML={{ __html: value }} />
        ) : (
          value
        )}
      </Typography>
    </Grid>
  </>
);

const renderRowDetails = ({ data }: any) => {
  const screenWidth = window.innerWidth;
  const isDesktop = screenWidth > 600;

  return (
    <>
      {isDesktop ? (
        <Card elevation={3} className="flex flex-row m-2">
          <CardContent className="flex-1 flex flex-col m-2">
            <Box sx={{ width: '100%' }}>
              <Grid container rowSpacing={3.5} columnSpacing={4}>
                {CommonGridUI('Description', data['fldDescriptions'])}
              </Grid>
            </Box>
          </CardContent>
        </Card>
      ) : (
        <div className="p-2 row-detail">
          <table>
            <tbody>
              <tr key={'fldDescriptions'} className="align-top">
                <td className="py-1 mr-2 pr-2">
                  <b>Description</b>
                </td>
                <td className="py-1">{data['fldDescriptions']}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

interface InjectedProps {
  checks: TblSchedChk[];
}

const LogEntryChecklistTab: FC<InjectedProps> = ({ checks }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const rowDetailHeight = isDesktop ? 170 : 270;
  const defaultExpandedRows = { 1: true };

  const columns = [
    {
      name: 'fldTitle',
      header: 'Inspections Title',
      defaultFlex: 1,
    },
    // use the bellow when we have data on the .net api
    // {
    //   name: 'equipment.uniqueName',
    //   header: 'Equipment',
    //   defaultFlex: 1,
    // },
    // {
    //   name: 'locationPath',
    //   header: 'Location',
    //   defaultFlex: 1,
    // },
  ];

  return (
    <div className="mt-12">
      <div
        data-testid="data-grid"
        className="flex flex-col flex-grow spare-grid"
      >
        <LicensedReactDataGrid
          livePagination
          rowHeight={40}
          idProperty="PKey"
          showHoverRows={false}
          columns={columns}
          dataSource={checks}
          // use the bellow when we have data on the .net api
          // renderRowDetails={renderRowDetails}
          // rowExpandHeight={rowDetailHeight}
          // defaultExpandedRows={defaultExpandedRows}
        />
      </div>
    </div>
  );
};

export default LogEntryChecklistTab;
