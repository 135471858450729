import '../../../../App.css';
import '../../../../theme/styles.css';
import '../../styles.css';
import React, { FC, useEffect, useState } from 'react';
import { isNil } from 'lodash';
import {
  Alert,
  Snackbar,
  Card,
  Typography,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Box,
  IconButton,
} from '@mui/material';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import moment from 'moment';
import { WorkIssuesDocument } from 'src/rxdb/collections/WorkIssues/rxdb';
import { EquipmentDocument } from 'src/pages/EquipmentPage/rxdb';
import { Edit } from '@mui/icons-material';
import { getDatabase } from '../../../../rxdb';
import EqNewTaskDialog from './EqNewTaskDialog';
import WarningDialog from 'src/components/UI/WarningDialog';
import CompleteTaskButton from 'src/components/CompleteTaskButton';

interface Props {
  initialValue: EquipmentDocument;
  onDelete?: (key: string) => void;
  onSave:()=> void;
  count?: number;
  onTaskComplete: () =>void;
  disableEdit: boolean;
}

const EqOutStandingTasks: FC<Props> = ({ initialValue, onDelete, count,onSave,onTaskComplete, disableEdit=false }) => {
  const [outStandingtTasks, setOutStandingTasks] = useState<WorkIssuesDocument[]>();
  const [selectedTask, setSelectedTask] = useState<WorkIssuesDocument>();
  const [editPopupVisible, setEditPopupVisible] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteSelected, setDeleteSelected] = useState<WorkIssuesDocument>();
  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });

  const init = async () => {
    const db = await getDatabase();
    const result = await db.workissues
      .find({
        selector: {
          EqKey: initialValue.EqKey,
          Completed: { $eq: false },
          deletedAt: { $eq: null },
        },
      })
      .exec();

    setOutStandingTasks(result);
  };

  useEffect(() => {
    init();
  }, [count]);

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const CommonGridUI = (title: string, value: any) => (
    <>
      <Grid item xs={5}>
        <Typography sx={{ fontSize: 12 }}>{title}</Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography
          sx={{ fontSize: 12, fontWeight: 700 }}
          className="flex justify-end"
        >
          {value}
        </Typography>
      </Grid>
    </>
  );

  const TaskDetail = (item: WorkIssuesDocument) => (
    <Box sx={{ width: '100%' }}>
      <Grid container rowSpacing={2} columnSpacing={2}>
        {CommonGridUI(
          'Date Due:',
          item.DateDue ? moment(item.DateDue).format('DD-MMM-YYYY') : '',
        )}
      </Grid>
    </Box>
  );

  const onEditClick = (item: any) => {
    setEditPopupVisible(true);
  };

  const handleDelete = async (issue: WorkIssuesDocument) => {
    setDeleteSelected(issue);
    setIsDeleting(true);
    // try {
    //   await issue.remove();
    //   init();
    //   onDelete && onDelete(issue?.JobNumber);
    //   setSnackbar({
    //     open: true,
    //     message: 'Issue successfully removed',
    //     type: 'success',
    //   });
    // } catch (e: any) {
    //   setSnackbar({
    //     open: true,
    //     message: e.message,
    //     type: 'error',
    //   });
    // }
  };

  const handleDeleteOk = async () =>{
    try {
      await deleteSelected?.remove();
      init();
      onDelete && onDelete(deleteSelected?.JobNumber || "");
      setSnackbar({
        open: true,
        message: 'Issue successfully removed',
        type: 'success',
      });
    } catch (e: any) {
      setSnackbar({
        open: true,
        message: e.message,
        type: 'error',
      });
    }
    setIsDeleting(false);
    setDeleteSelected(undefined);
  };

  const handleDeleteCancel = () =>{
    setIsDeleting(false);
    setDeleteSelected(undefined);
  }

  const handleCancelDialog = () => {
    setSelectedTask(undefined);
    setEditPopupVisible(false);
  };

  const handleCancelTaskButton = () =>{
    onTaskComplete()
  }

  const onNewTaskSave = () => {
    setSelectedTask(undefined);
    setEditPopupVisible(false);
    setSnackbar({
      open: true,
      message: 'Task has been updated!',
      type: 'success',
    });
    onSave();
    init();
  };

  return (
    <div>
      {!isNil(initialValue.EqKey) && (
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 2, sm: 4, md: 12 }}
        >
          {outStandingtTasks?.map((item) => (
            <Grid item xs={2} sm={4} md={4} key={item.JobNumber}>
              <Card elevation={3} sx={{ height: 135 }}>
                <CardContent sx={{ padding: 2 }}>
                  <CardHeader
                    sx={{ padding: 1 }}
                    action={
                      <IconButton
                        aria-label="Edit"
                        size="small"
                        onClick={(e) => {
                          setSelectedTask(item);
                          onEditClick(e);
                        }}
                        disabled={disableEdit}
                      >
                        <Edit fontSize="inherit" />
                      </IconButton>
                    }
                    title={(
                      <Typography sx={{ fontSize: 14, fontWeight: 700 }}>
                        {item.Subject}
                      </Typography>
                    )}
                  />
                  <Divider className="mb-3" />
                  {TaskDetail(item)}
                  {
                    <div className="flex items-end justify-end mt-6">
                      <div>
                        <CompleteTaskButton initialValue={item} onCancel={handleCancelTaskButton} disableEdit={disableEdit} />
                      </div>
                      <div>
                        <IconButton
                          // onClick={()=>handleDelete(item)}
                          color="error"
                          aria-label="Delete task"
                          className="ml-2"
                          size="small"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleDelete(item);
                          }}
                          disabled={disableEdit}
                        >
                          <DeleteTwoToneIcon fontSize="inherit" />
                        </IconButton>
                      </div>
                    </div>
                  }
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
      {!!selectedTask && (
        <EqNewTaskDialog
          visible={editPopupVisible}
          initialValue={selectedTask}
          onSave={onNewTaskSave}
          onCancel={handleCancelDialog}
          title="Update Task"
        />
      )}
      <WarningDialog
        visible={isDeleting}
        title="Delete Warning"
        content="Are you sure you wish to delete record?"
        okText='Yes'
        color='error'
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        onClose={onSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
          {snackBar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};
export default EqOutStandingTasks;
