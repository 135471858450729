import { FC } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Button,
} from "@mui/material";
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

interface ErrorDialogProps extends Partial<DialogProps> {
  visible: boolean;
  title: string;
  content: string;
  okText?: string;
  onClose?: () => void;
}

const ErrorDialog: FC<ErrorDialogProps> = ({
  visible,
  title,
  content,
  okText = "OK",
  onClose,
}) => {
  return (
    <Dialog
      open={visible}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="error-dialog"
    >
      <DialogTitle id="alert-dialog-title">{(<WarningTwoToneIcon fontSize="small" color='error' className="mr-3"/>)}{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button data-testid="error-dialog-confirm" onClick={onClose} autoFocus>
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;
