import { FC,useState, useRef, MutableRefObject, useCallback } from 'react';
import { IconButton, useMediaQuery } from '@mui/material';
import { DeleteTwoTone, Edit } from '@mui/icons-material';
import LocationOnTwoToneIcon from '@mui/icons-material/LocationOnTwoTone';
import { getDatabase, TDIDb } from 'src/rxdb';
import { TblSparesUsedDocument } from '../../rxdb';
import { WorkIssuesDocument } from 'src/rxdb/collections/WorkIssues/rxdb';
import { isEmpty, isNil, pick, size } from 'lodash';
import { TypeComputedProps, TypeSortInfo } from '@inovua/reactdatagrid-enterprise/types';
import LicensedReactDataGrid from 'src/components/UI/LicensedReactDataGrid';
import WarningDialog from 'src/components/UI/WarningDialog';
import './style.css'

interface InjectedProps {
  issue?: WorkIssuesDocument;
  onEdit: (spare: TblSparesUsedDocument) => void;
  onDelete: (spare: TblSparesUsedDocument) => void;
  onLocation: (spare: any) => void;
  tableName?: string;
  referenceKey?: string;
  referenceValue?: string;
  showLocationIcon?: boolean;
  isLogentryCreation?: boolean;
  spares?: TblSparesUsedDocument[];
}

const filter = [
  {
    name: 'ProductName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'Manufacturer',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'fldPartNumber',
    operator: 'contains',
    type: 'select',
    value: '',
  },
  {
    name: 'Amount',
    operator: 'contains',
    type: 'select',
    value: '',
  },
];

const transformData = async (item: any) => ({
  ...pick(item, ['PKey', 'ProductID','Amount']),
  ProductName: (await item.populate('ProductID'))?.ProductName,
  Manufacturer: (await item.populate('ProductID'))?.Manufacturer,
  fldPartNumber: (await item.populate('ProductID'))?.fldPartNumber,
  original: item,
});

const getRegexByOperator = (operator: string, value: string) => {
  switch (operator) {
    case 'contains':
      return new RegExp(`.*${value}`, 'i');
    case 'startsWith':
      return new RegExp(`^${value}`, 'i');
    case 'endsWith':
      return new RegExp(`${value}$`, 'i');
    case 'eq':
      return new RegExp(`${value}`, 'i');
    default:
  }
};

const getSelectorByFilterName = async (
  name: string,
  value: string,
  operator: string,
  db: TDIDb,
) => {
  switch (name) {
    case 'ProductName':
      const productname = await db.inventory
        .find({
          selector: {
            ProductName: { $regex: getRegexByOperator(operator, value) },
          },
        })
        .exec();
      return {
        ProductID: { $in: productname.map((e) => e.primary) },
      };

    case 'Manufacturer':
      const manufacturers = await db.inventory
        .find({
          selector: {
            Manufacturer: { $regex: getRegexByOperator(operator, value) },
          },
        })
        .exec();
      return {
        ProductID: { $in: manufacturers.map((e) => e.primary) },
      };

    case 'fldPartNumber':
      const fldPartNumbers = await db.inventory
        .find({
          selector: {
            fldPartNumber: { $regex: getRegexByOperator(operator, value) },
          },
        })
        .exec();
      return {
        ProductID: { $in: fldPartNumbers.map((e) => e.primary) },
      };

    case 'Amount':
      return {
        Amount: {$regex: getRegexByOperator(operator, value)}
      }
      ;

    default:
      return {};
  }
};

const SpareGridOld: FC<InjectedProps> = ({
  onEdit,
  onDelete,
  issue,
  onLocation,
  tableName = null,
  referenceKey = null,
  referenceValue = null,
  showLocationIcon = true,
  isLogentryCreation = false,
  spares,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const highlightedRowId = useRef<string>('-1');
  const [gridRef, setGridRef] = useState<any>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteSelected, setDeleteSelected] = useState<any>();
  const isMobile = useMediaQuery('(max-width: 420px)');

  const loadData = async ({
    skip,
    limit,
    filterValue,
  }: {
    sortInfo?: TypeSortInfo;
    skip?: number;
    limit?: number;
    filterValue?: any;
  }): Promise<{ data: any[]; count: number }> => {
    const db: any = await getDatabase();

    let selector: any = {
      $or: [
        { deletedBy: { $eq: null } },
        { deletedBy: { $exists: false } }, // Check for undefined
      ],
    };

    if(referenceKey) {
      selector[referenceKey] = referenceValue;
    } else if(referenceKey === 'LogKey'){
      selector.Logkey = referenceValue
    }
    else {
      selector.WorkKey = issue?.JobNumber;
    }

    await Promise.all(
      filterValue.map(async (v: any) => {
        if (isEmpty(v.value)) return v;

        const s = await getSelectorByFilterName(
          v.name,
          v.value,
          v.operator,
          db,
        );

        selector = {
          ...selector,
          ...s,
        };
        return v;
      }),
    );

    const table: string = tableName || "tblsparesused";
    const items = await db[table]
      .find({
        selector,
        skip,
        limit
      })
      .exec();

    const length = size(items);
    const data = await Promise.all(items.map(transformData));
    data.sort((a: any, b: any) => a.ProductName < b.ProductName ? -1 : 1);
    return { data, count: length };
  };

  const loadDataFromSpares = async (): Promise<{ data: any[]; count: number }> => {
    if (spares) {
      const length = size(spares);
      const data = await Promise.all(spares.map(transformData));
      data.sort((a: any, b: any) => (a.ProductName < b.ProductName ? -1 : 1));
      return { data, count: length };
    } else {
      // Handle the case when spares is undefined or falsy
      return { data: [], count: 0 };
    }
  };

  const init = async (ref: MutableRefObject<TypeComputedProps | null>) => {
    const db: any = await getDatabase();

    db.tblsparesused.$.subscribe(async (ev: any) => {
      if (ev.operation === 'INSERT' || ev.operation === 'UPDATE') {
        highlightedRowId.current = ev.documentId;
        ref.current?.reload();
      }
      if (ev.operation==='DELETE') {
        highlightedRowId.current = '-1';
        ref.current?.reload();
      }
    });

    if(tableName) {
      db[tableName].$.subscribe(async (ev: any) => {
        if (ev.operation === 'INSERT' || ev.operation === 'UPDATE') {
          highlightedRowId.current = ev.documentId;
          ref.current?.reload();
        }
        if (ev.operation==='DELETE') {
          highlightedRowId.current = '-1';
          ref.current?.reload();
        }
      });
    }
  };

  const onReady = (ref: MutableRefObject<TypeComputedProps | null>) => {
    setGridRef(ref);
    init(ref);
  };

  const onLoadingChange = (status: boolean) => {
    // If loading completed - check if there any items that needs to be highlighted.
    if (!status && highlightedRowId.current !== '-1') {
      // gridRef?.current?.scrollToId(highlightedRowId?.current || '-1');
      gridRef?.current?.setSelectedById(highlightedRowId.current, true);
      highlightedRowId.current = '-1';
    }
    setLoading(status);
  };

  const rowEdit= (item:any) =>{
   
    onEdit(item.original)
  }

  
  const rowDeletePress= (item:any) =>{
    setDeleteSelected(item.original);
    setIsDeleting(true);
  };

  const handleDeleteOk = () =>{
    onDelete(deleteSelected);
    setIsDeleting(false);
    setDeleteSelected(undefined);
  };

  const handleDeleteCancel = () =>{
    setIsDeleting(false);
    setDeleteSelected(undefined);
  };

  const rowLocation = (item:any) =>{
    onLocation(item.original)
  }

  const dataSource = !isLogentryCreation ? useCallback(loadData, []) : useCallback(loadDataFromSpares, [spares]);

  const columns = [
    {
      name: "ProductName",
      header: "Product Name",
      flex: 1,
    },
    {
      name: "Manufacturer",
      header: "Manufacturer",
      flex: 1,
    },
    {
      name: "fldPartNumber",
      header: "Part#",
      flex: 1,
    },
    {
      name: "Amount",
      header: "AMT#",
      headerAlign: 'start' as any,
      textAlign: "end" as any,
      flex: 0.5,
    },
    {
      id: "icons",
      // flex: 0.5,
      width: 100,
      render: ({ data }: any) =>{
      return (
        <div className = "flex justify-center">
          <IconButton
            onClick={() => rowEdit(data)}
            size="small"
            aria-label="Edit item"
          >
            <Edit fontSize="inherit" />
          </IconButton>
          <IconButton
            onClick={() => rowDeletePress(data)}
            size="small"
            color="error"
            aria-label="Delete item"
          >
            <DeleteTwoTone fontSize="inherit" />
          </IconButton>
          {showLocationIcon && <IconButton
            onClick={() => rowLocation(data)}
            size="small"
            color="primary"
            aria-label="Location"
          >
            <LocationOnTwoToneIcon fontSize="inherit" />
          </IconButton>}
        </div>
      )
      },
    },
  ];
  
  if (isMobile) {
    columns.splice(1, 3); // Remove Manufacturer, fldPartNumber and Amount
  }


  return (
    <>
      <div data-testid="data-grid" className="flex flex-col flex-grow spare-grid">
          <LicensedReactDataGrid
            onLoadingChange={onLoadingChange}
            defaultLimit={100}
            livePagination
            onReady={onReady}
            rowHeight={40}
            loading={loading}
            defaultFilterValue={filter}
            idProperty="PKey"
            showHoverRows={false}
            // theme="default-dark"
            columns={columns}
            dataSource={dataSource}
          />
        </div>
        <WarningDialog
          visible={isDeleting}
          title="Delete Warning"
          content="Are you sure you wish to delete record?"
          okText='Yes'
          color='error'
          onOk={handleDeleteOk}
          onCancel={handleDeleteCancel}
        />
      </>
  )
};

export default SpareGridOld;
