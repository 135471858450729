import './styles.css';
import { FC, useEffect, useRef, useState, useCallback, MutableRefObject } from 'react';
import { Alert, Snackbar, ImageList, ImageListItem, ImageListItemBar, IconButton, Tooltip, CircularProgress, useMediaQuery } from '@mui/material';
import { Edit, DeleteTwoTone, OpenInFull, FitScreen } from '@mui/icons-material';
import { TypeComputedProps, TypeSortInfo } from '@inovua/reactdatagrid-enterprise/types';
import LicensedReactDataGrid from 'src/components/UI/LicensedReactDataGrid';
import { isNil, get, size, sortBy } from 'lodash';
import { DOWNLOAD_URL } from 'src/consts';
import { TblDocumentCrossReferenceDocument } from '../../../api/queries/tblDocumentCrossReference/rxdb';
import { getDatabase } from '../../../rxdb';
import { DocumentRevisionDocument } from '../../../rxdb/collections/DocumentRevisions/schema';
import { TblDocumentationDocument } from '../../../api/queries/tblDocumentation/rxdb';
import { IAttachmentState } from '../indexOld';
import ImagePreviewPopup from './ImagePreviewPopup';
import { getListByTabType } from './utils';
import WarningDialog from 'src/components/UI/WarningDialog';
import { Subscription } from 'rxjs';

type Severity = 'error' | 'success' | 'info' | 'warning' | undefined;
type AttachmentGridProps = {
  data?: any;
  fileUploaderRef?: any;
  onUploaded?: any;
  onSelectedFilesChanged?: any;
  onUploadError?: any;
  allowedFileExtensions: string[];
  relatedKeys: string[];
  setUploadedFile: any;
  type: any;
  onEdit: (data: IAttachmentState) => void;
  onDelete: (data: IAttachmentState) => void;
  disabled?: boolean;
  reload?: boolean;
  editDisabled?: boolean;
};

export const transformAttachment = (
  attachment: TblDocumentCrossReferenceDocument,
  revision?: DocumentRevisionDocument | null,
  documentation?: TblDocumentationDocument | null,
): IAttachmentState => {
  const title = get(documentation, 'DocTitle', '');
  return {
    PKey: attachment.PKey,
    fldFKey: attachment.fldFKey,
    fldRecordKey: attachment.fldRecordKey,
    fldAltPath: revision?.fldAltPath,
    fldFileName: revision?.fldFileName,
    DocTitle: title || revision?.fldFileName || attachment.fldFKey,
    fileExtension: (
      revision?.fldFileName?.split('.').pop() || ''
    ).toUpperCase(),
    fldLibtype:documentation?.fldLibtype,
    fldDescription: documentation?.fldDescription,
    fldShowInCentral: documentation?.fldShowInCentral,
    fldNotes: revision?.fldNotes,
    fldPage: revision?.fldPage,
    fldRevision: revision?.fldRevision,
    fldRevisionDate: revision?.fldRevisionDate,
    deletedAt:
      documentation?.deletedAt
      || revision?.deletedAt
      || attachment?.deletedAt,
  };
};

const AttachmentGridOld: FC<AttachmentGridProps> = ({
  onSelectedFilesChanged,
  relatedKeys,
  setUploadedFile,
  type,
  onEdit,
  onDelete,
  disabled = false,
  reload,
  editDisabled= false,
}) => {
  const [attachments, setAttachments] = useState<IAttachmentState[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [fileName, setFileName] = useState('');
  const [fileSource, setFileSource] = useState('');
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<Severity>(undefined);
  const [showPreview, setShowPreview] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const highlightedRowId = useRef<string>('-1');
  const previewImageRef = useRef<HTMLImageElement | null>(null);
  const gridRef: any = useRef<HTMLDivElement>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteSelected, setDeleteSelected] = useState<any>();
  const activeSubscriptions = useRef<Subscription[]>([]);
  const isMobile = useMediaQuery('(max-width:600px)');

  const init = async () => {
    setLoading(true);
    const db = await getDatabase();

    const items = await db.tbldocumentcrossreference
      .find({
       selector: {
          fldRecordKey: { $in: relatedKeys},
          deletedAt: {
            $eq: null
          },
        },
      })
      .exec();
    const result = await Promise.all<IAttachmentState>(
      items.map(async (attachment: any) => {
        // Push subscription to entry list, so we can unsubscribe
        // subscriptions.current.set('cross', referenceSubscription);
        const revisionDocs = await db.documentrevision
          .find({
            selector: {
              fldFKey: attachment.fldFKey,
            },
          })
          .exec();
        const revisions: any  = sortBy(revisionDocs, ['fldRevisionDate', 'updatedAt']).reverse();
        const revision = revisions.length > 0 ? revisions[0]: null;
        if (isNil(revision)) {
          return transformAttachment(attachment, revision, null);
        }

        const document = await revision.populate("fldFKey");

        return transformAttachment(attachment, revision, document);
      })
    );
    setAttachments(getListByTabType(result, type));
    setLoading(false);

  };

  const updatePhotoGrid = async () =>{
    const db = await getDatabase();
    activeSubscriptions.current = [
      db.tbldocumentcrossreference.$.subscribe(()=>{
        init()
      }),
      db.tbldocumentation.$.subscribe(()=>{
        init()
      }),
      
    ]
  }

  useEffect(() => {
    updatePhotoGrid();
    return () => {
      activeSubscriptions.current?.map((sub) => sub.unsubscribe());
      activeSubscriptions.current = [];
    };
  }, []);

  useEffect(() => {
    init();
  }, [reload]);

  const onItemClick = (item: any) => {
    setShowPreview(true);
    setImageSrc(`${DOWNLOAD_URL}/${item.fldFileName}?w=164&h=164&fit=crop&auto=format`);
  };
  
  const onTitleClick = (item: any) => {
    setShowPreview(true);
    setImageSrc(`${DOWNLOAD_URL}/${item.fldFileName}?w=164&h=164&fit=crop&auto=format`);
  };

  const onFullScreenClick = (item: any) => {
    const newTab = window.open(`${DOWNLOAD_URL}/${item.fldFileName}`, '_blank');
    if (newTab) {
      newTab.focus(); // brings the new tab to focus if it's not null
    } else {
      console.error('Failed to open preview. Please check your popup blocker settings.');
    }
  };

  console.log('attachments:', attachments)
  
  const onDeleteImageClick = (data: any) => {
    setDeleteSelected(data);
    setIsDeleting(true);
    // onDelete && onDelete(data.PKey);
  };

  const handleDeleteAttachment = (data: any) => {
    // onDelete(initialValue);
    setDeleteSelected(data);
    setIsDeleting(true);
  };

  const handleDeleteOk = () =>{
    onDelete && onDelete(deleteSelected);
    setIsDeleting(false);
    setDeleteSelected(undefined);
  };

  const handleDeleteCancel = () =>{
    setIsDeleting(false);
    setDeleteSelected(undefined);
  }

  const onClose = () => {
    setShowPreview(false);
  };

  const loadData = async ({
    skip,
    limit,
    filterValue,
  }: {
    sortInfo?: TypeSortInfo;
    skip?: number;
    limit?: number;
    filterValue?: any;
  }): Promise<{ data: any[]; count: number }> => {
    const db = await getDatabase();

    let selector = {
      fldRecordKey: { $in: relatedKeys },
      deletedAt: {
        $eq: null
      },
    };

    const items = await db.tbldocumentcrossreference
      .find({
        selector,
        skip,
        limit,
      })
      .exec();

    const result = await Promise.all<IAttachmentState>(
      items.map(async (attachment: any) => {
        // Push subscription to entry list, so we can unsubscribe
        // subscriptions.current.set('cross', referenceSubscription);
        const revisionDocs = await db.documentrevision
          .find({
            selector: {
              fldFKey: attachment.fldFKey,
            },
          })
          .exec();
        const revisions: any  = sortBy(revisionDocs, ['fldRevisionDate', 'updatedAt']).reverse();
        const revision = revisions.length > 0 ? revisions[0]: null;
        if (isNil(revision)) {
          return transformAttachment(attachment, revision, null);
        }

        const document = await revision.populate("fldFKey");

        return transformAttachment(attachment, revision, document);
      })
    );
    const length = size(items);
    const data = getListByTabType(result, 'attachments');
    return { data, count: length };
  };
  const handleNewGridEvent = async (ev: any, ref: MutableRefObject<TypeComputedProps | null> ) => {
    if (ev.operation === 'INSERT' || ev.operation === 'UPDATE') {
      highlightedRowId.current = ev.documentId;
      ref.current?.reload();
    }
    if (ev.operation==='DELETE') {
      highlightedRowId.current = '-1';
      ref.current?.reload();
    }
  }
  const initGrid = async (ref: MutableRefObject<TypeComputedProps | null>) => {
    const db = await getDatabase();
    db.tbldocumentcrossreference.$.subscribe((ev:any) => handleNewGridEvent(ev, ref));
    db.tbldocumentation.$.subscribe((ev:any) => handleNewGridEvent(ev, ref));
  };

  const onReady = (ref: MutableRefObject<TypeComputedProps | null>) => {
    initGrid(ref);
  };

  const onLoadingChange = (status: boolean) => {
    // If loading completed - check if there any items that needs to be highlighted.
    if (!status && highlightedRowId.current !== '-1') {
      gridRef?.current?.scrollToId(highlightedRowId.current);
      gridRef?.current?.setSelectedById(highlightedRowId.current, true);
      highlightedRowId.current = '-1';
    }
    setLoading(status);
  };

  const dataSource = useCallback(loadData, []);


  const attachmentGridColumns = [
    {
      name: "DocTitle",
      header: "Library Title",
      flex: 1,
      render:({data}:any) =>{
        return (
          <a
            href={`${DOWNLOAD_URL}/${encodeURIComponent(data.fldFileName)}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {data?.DocTitle}
          </a>
        )
      }
    },

    {
      id: "icons",
      flex: 0.25,

      render: ({ data }: any) =>{
      return (
        <div className = "flex justify-center">
          {!editDisabled && (<IconButton
            onClick={() => onEdit(data)}
            size="small"
            aria-label="Edit item"
            className="mx-2"
          >
            <Edit fontSize="inherit" />
          </IconButton>)}
          {!editDisabled &&(<IconButton
            onClick={() => handleDeleteAttachment(data)}
            size="small"
            color="error"
            aria-label="Delete item"
            className="mx-2"
          >
            <DeleteTwoTone fontSize="inherit" />
          </IconButton>)}
        </div>
      )
      },
    },
  ];


  
  return (
    <>
      {type === 'attachments' && (
         <div data-testid="data-grid" className="flex flex-col flex-grow attachment-grid">
         <LicensedReactDataGrid
           onLoadingChange={onLoadingChange}
           defaultSortInfo={[{ name: 'DocTitle', dir: 1 }]}
           defaultLimit={100}
           livePagination
           onReady={onReady}
           rowHeight={40}
           loading={loading}
           idProperty="PKey"
           showHoverRows={false}
           columns={attachmentGridColumns}
           dataSource={dataSource}
         />
       </div>
      )}

      {/* Note: MUI File Upload is in progresshttps://mui.com/discover-more/roadmap/#heading-new-components */}
      {/* <FileUploader
        dropZone="#myID .dx-datagrid-rowsview"
        uploadMode="instantly"
        ref={fileUploaderRef}
        allowedFileExtensions={allowedFileExtensions}
        name="attachment"
        uploadUrl={`${import.meta.env.VITE_NODE_URL}/files/upload`}
        uploadHeaders={{ 'x-upload-path': 'images' }}
        visible={false}
        multiple={false}
        onUploaded={(e) => onUploaded(e)}
        onUploadError={onUploadError}
        onValueChanged={onSelectedFilesChanged}
      /> */}

      {type === 'photo' && (
        <div className="pt-5">
          {loading ? 
          (<div className="flex items-center justify-center w-full h-72">
            <CircularProgress />
          </div>) :
          <ImageList sx={{ width: '100%' }} cols={isMobile ? 1 : 3}>
            {attachments.map((item) => (
              <ImageListItem key={item.fldFileName} >
                <ImageListItemBar
                  title={item.DocTitle}
                  position="top"
                  onClick={() => onTitleClick(item)}
                  actionIcon={(
                    <>
                      {!editDisabled &&(
                        <Tooltip title="Preview">
                          <IconButton
                            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                            aria-label="Preview"
                            onClick={(event) => {
                              event.stopPropagation();
                              onFullScreenClick(item)
                            }}
                          >
                            <FitScreen />
                          </IconButton>
                        </Tooltip>
                      )}
                      {!editDisabled &&(
                        <Tooltip title="Edit">
                          <IconButton
                            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                            aria-label="Edit"
                            onClick={(event) => {
                              event.stopPropagation();
                              onEdit(item);
                            }}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      )}
                      {!editDisabled && (
                        <Tooltip title="Delete">
                          <IconButton
                            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                            aria-label="Delete"
                            onClick={(event) => {
                              event.stopPropagation();
                              onDeleteImageClick(item);
                            }}
                          >
                            <DeleteTwoTone />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  )}
                />
                <img
                  src={`${DOWNLOAD_URL}/${item.fldFileName}?w=${isMobile ? 300 : 164}&h=${isMobile ? 300 : 164}&fit=crop&auto=format`}
                  srcSet={`${DOWNLOAD_URL}/${item.fldFileName}?w=${isMobile ? 600 : 328}&h=${isMobile ? 600 : 328}&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.DocTitle}
                  loading="lazy"
                  onClick={() => onItemClick(item)}
                />
              </ImageListItem>
            ))}
          </ImageList>
          }
        </div>
      )}
      <ImagePreviewPopup
        popupVisible={showPreview}
        onClose={onClose}
        imageSrc={imageSrc}
      />
      <WarningDialog
        visible={isDeleting}
        title="Delete Warning"
        content="Are you sure you wish to delete record?"
        okText='Yes'
        color='error'
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />
      <Snackbar
        open={alert}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => {
          setAlert(false);
        }}
      >
        <Alert severity={alertSeverity}>{alertMessage}</Alert>
      </Snackbar>
    </>
  );
};

const textBoxOptions = { stylingMode: 'outlined', height: 40 };

export default AttachmentGridOld;
