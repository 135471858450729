import { omit } from 'lodash';
import { InvEq } from '../../../generated/graphql';
import {logger} from "../../../helpers/logger";

const fields = `
  EqKey
  ProductID
  deletedAt
  deletedBy
  isRecoverable
  updatedAt
  autoId
`;

export const invEqPullQuery = (doc: InvEq) => {
  if (!doc) {
    doc = {
      autoId: 0,
      EqKey: '',
      ProductID: '',
      updatedAt: new Date(0).toISOString(),
    };
  }
  logger('InvEq').info(
    `pull query triggered - InvEq: { ${new Date().toISOString()} - ID: ${doc.autoId}, updatedAt: ${doc.updatedAt}}`,
  );
  return {
    query: `
        query getInvEq($autoId: Int, , $filterDate: Date) {
          InvEq(
            where:{
              _and: [
                {
                  _or: [
                    {
                      isRecoverable: {
                        _is_null: true
                      }
                    },
                    {
                      isRecoverable: {
                        _eq: true
                      }
                    }
                  ]
                },
                {
                  _or: [
                    {
                      updatedAt: {_gt: $filterDate},                
                    },
                    {
                      autoId: {_gt: $autoId}
                      updatedAt: {_gte: $filterDate},
                    }
                  ]
                }
              ]                                    
            },
            order_by: [
              {updatedAt: asc},
              {autoId: asc}
            ],
            limit: 500
          ) {
            ${fields}           
          }
        }
      `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
    },
  };
};

export const invEqMutation = (doc: any) => {
  const query = `
    mutation InsertInvEq($input: InvEqUpsertInput!) {
      upsertInvEq(input: $input) {
        ${fields}
      }
    }
  `;

  const variables = {
    input: omit(doc, ['updatedAt', 'deletedAt', 'id']),
  };

  return {
    query,
    variables,
  };
};

export const invEqSubscription = `
  subscription onInvEqChanged {
    InvEq {
      ${fields}
    }
  }
`;
