/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

export interface SnackbarConfig {
  type: string,
  message: string,
}

const SnackbarContext = React.createContext({
  showSnackbar: (config: SnackbarConfig) => {
    return;
  },
  closeSnackbar: () => { return }
});

export default SnackbarContext;
