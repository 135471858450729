import { RxCollection, RxJsonSchema, RxDocument } from 'rxdb';
import { MultipleLocations } from '../../../generated/graphql';

export type MultipleLocationDocType = MultipleLocations;

export type MultipleLocationDocMethods = {};

export type MultipleLocationDocument = RxDocument<
  MultipleLocationDocType,
  MultipleLocationDocMethods
  >;

export type MultipleLocationCollectionMethods = {};

export type MultipleLocationCollection = RxCollection<
  MultipleLocationDocType,
  MultipleLocationDocMethods,
  MultipleLocationCollectionMethods
  >;

export const schema: RxJsonSchema<MultipleLocationDocType> = {
  title: 'MultipleLocations ',
  description: 'Multiple locations',
  version: 2,
  keyCompression: false,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    DateEntered: {
      type: ['string', 'null'],
    },
    Amount: {
      type: ['number', 'null'],
    },
    ProductID: {
      type: 'string',
      ref: 'inventory',
    },
    SerialNum: {
      type: ['string', 'null'],
    },
    UseBy: {
      type: ['string', 'null'],
    },
    fldDefault: {
      type: ['boolean', 'null'],
    },
    fldLocHierarchy: {
      type: ['string', 'null'],
    },
    fldMaxHldgLoc: {
      type: ['number', 'null'],
    },
    PKey: {
      type: 'string',
      primary: true,
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    updatedAt: {
      type: 'string',
    },
  },
  required: [],
  additionalProperties: false,
};

export default schema;
