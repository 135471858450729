// @ts-nocheck
import React, {
  FC,
  useCallback,
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  MutableRefObject,
} from 'react';
import LicensedReactDataGrid from '../../../components/UI/LicensedReactDataGrid';
import { pick, get, size, isEmpty,isNil  } from 'lodash';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Box,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { TypeComputedProps, TypeSortInfo } from '@inovua/reactdatagrid-enterprise/types';
import '../styles.css';
import { MangoQuerySortDirection } from 'rxdb/dist/types/types/rx-query';
import { exportCSV, exportExcel, getRegexByOperator } from '../../../utils';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import DeleteRecordBtnOld from '../../../components/DeleteRecordBtn/indexOld';
// Data
import { InventoryDocument } from 'src/pages/InventoryPage/rxdb';
import { useAppState } from 'src/contexts/app-state';
import { getDatabase, TDIDb } from 'src/rxdb';
import GridRowAttachmentIcon from "../../../components/UI/LicensedReactDataGrid/components/GridRowAttachmentIcon";
import { TblDdListDefaultsDocument } from 'src/api/queries/tblDDListDefaults/rxdb';
import { getSelectorByBarcodFilter } from 'src/pages/InventoryPage/utils';

type MedicalInventoryGridProps = {
  darken: boolean;
  onSelect: (item: InventoryDocument) => void;
  searchValue?: string;
  noResultFound?: (noResult: boolean) => void;
  sourceRoot?: boolean;
  moduleLevelSearch?: any;
};

interface MedicalInventoryGridRef {
  handleLoadGrid: () => void;
  handleExport: (type: string) => void;
}

const filter = [
  {
    name: 'ProductName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  // {
  //   name: "Department",
  //   operator: "eq",
  //   type: "select",
  //   value: "",
  // },
  {
    name: 'Manufacturer',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'fldPartNumber',
    operator: 'contains',
    type: 'select',
    value: '',
  },
  {
    name: 'ModelNumber',
    operator: 'contains',
    type: 'select',
    value: '',
  },
  {
    name: 'fldGuestType',
    operator: 'contains',
    type: 'select',
    value: '',
  },
];

const transformData = async (
  item: InventoryDocument,
  expiredData,
  amountData
) => {
  let expired = false;
  if (expiredData[item.ProductID]) {
    if (
      expiredData[item.ProductID].UseBy &&
      new Date(expiredData[item.ProductID].UseBy) < new Date()
    ) {
      expired = true;
    } else {
      expired = false;
    }
  } else {
    expired = false;
  }
  return {
    ...pick(item, [
      'ProductID',
      'ProductName',
      'Manufacturer',
      'fldPartNumber',
      'ModelNumber',
      'fldGuestType',
      'Department',
    ]),
    // fldTritonBC: (await item.populate("ProductID"))?.fldTritonBC,
    original: item,
    expired,
    totalAmount: amountData[item.ProductID] || 0,
  };
};

const getSelectorByFilterName = async (
  name: string,
  value: string,
  operator: string,
  db: TDIDb
) => {
  switch (name) {
    case 'ProductID': {
      return {
        ProductID: { $in: value },
      };
    }
    case 'ProductName':
      return {
        ProductName: { $regex: getRegexByOperator(operator, value) },
      };

    case 'Manufacturer':
      return {
        Manufacturer: { $regex: getRegexByOperator(operator, value) },
      };

    case 'fldPartNumber':
      return {
        fldPartNumber: { $regex: getRegexByOperator(operator, value) },
      };

    case 'ModelNumber':
      return {
        ModelNumber: { $regex: getRegexByOperator(operator, value) },
      };

    case 'fldGuestType':
      return {
        fldGuestType: { $regex: getRegexByOperator(operator, value) },
      };

    // case "Department": {
    //   return {
    //     Department: { $regex: getRegexByOperator(operator, value) },
    //   };
    // }

    default:
      return {};
  }
};

const MedicalInventoryGrid = forwardRef<
  MedicalInventoryGridRef,
  MedicalInventoryGridProps
>(
  (
    {
      onSelect,
      searchValue,
      noResultFound,
      sourceRoot = false,
      moduleLevelSearch = null,
    }: MedicalInventoryGridProps,
    ref
  ) => {
    const { settingsPersonal } = useAppState();
    const [loading, setLoading] = useState<boolean>(false);
    const highlightedRowId = useRef<string>('-1');
    const [gridRef, setGridRef] = useState<any>(null);
    const [allDepartment, setAllDepartment] =
      useState<TblDdListDefaultsDocument>([]);
    const [exportDialog, setExportDialog] = useState(null);
    const [noBarcodePopupVisible, setNoBarcodePopupVisible] =
      useState<boolean>(false);

    const [collapsedGroups, setCollapsedGroups] = useState({});
    const [groupCollapsedStatus, setGroupCollapsedStatus] = useState(false);

    const [currentCount, setCurrentCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [rowSelected, setRowSelected] = useState(null);
    const isTablet = useMediaQuery('(min-width: 700px) and (max-width: 1200px)');
    const isMobile = useMediaQuery('(max-width: 420px)');

    const isActionable =
      Object.values(rowSelected || {}).filter((item: any) => item).length > 0;

    const handleSelectionChange = useCallback(({ selected }) => {
      setRowSelected(selected);
    }, []);

    const handleGetCount = async () => {
      const db = await getDatabase();
      let selector = {
        fldDataType: {
          $eq: '4',
        },
        deletedBy: {
          $eq: null,
        },
      };
      const length = size(await db.inventory.find({ selector }).exec());
      setTotalCount(length);
    };

    useEffect(() => {
      handleGetCount();
    }, [loading]);

    const onRowClick = useCallback(
      ({ data }) => {
        if (Object.keys(rowSelected || {}).length < 2) {
          onSelect(data.original);
        }
      },
      [rowSelected]
    );

    const loadData = async ({
      skip,
      limit,
      filterValue,
    }: {
      sortInfo: TypeSortInfo;
      skip: number;
      limit: number;
      filterValue: any;
    }): Promise<{ data: any[]; count: number }> => {
      const db = await getDatabase();

      const sort = [
        {
          updatedAt: 'desc' as MangoQuerySortDirection,
        },
      ];

      // TODO: Handle filtervalue.OPERATOR

      let selector = {
        deletedBy: {
          $eq: null,
        },
        fldDataType: {
          $eq: '4',
        },
      };

      await Promise.all(
        filterValue.map(async (v) => {
          if (isEmpty(v.value)) return v;

          const s = await getSelectorByFilterName(
            v.name,
            v.value,
            v.operator,
            db
          );

          selector = {
            ...selector,
            ...s,
          };
          return v;
        })
      );

      if (searchValue !== '') {
        const s = await getSelectorByBarcodFilter(
          'fldTritonBC',
          searchValue,
          'contains',
          db
        );
        selector = {
          ...selector,
          ...s,
        };

        if (isEmpty(selector.ProductID?.$in)) {
          const partNumberSearch = await getSelectorByBarcodFilter(
            'fldPartNumber',
            searchValue,
            'contains',
            db
          );
          selector = {
            ...selector,
            ...partNumberSearch,
          };
        }

        if (isEmpty(selector.ProductID?.$in)) {
          const modelNumberSearch = await getSelectorByBarcodFilter(
            'ModelNumber',
            searchValue,
            'contains',
            db
          );
          selector = {
            ...selector,
            ...modelNumberSearch,
          };
        }

        if (isEmpty(selector.ProductID?.$in)) {
          const newSearchString = searchValue.replace(/[^a-zA-Z0-9]/g, '');
          const s = await getSelectorByBarcodFilter(
            'fldTritonBC',
            newSearchString,
            'contains',
            db
          );
          selector = {
            ...selector,
            ...s,
          };
        }

        if (isEmpty(selector.ProductID?.$in)) {
          const newSearchString = searchValue.replace(/[^a-zA-Z0-9]/g, '');
          const partNumberSearch = await getSelectorByBarcodFilter(
            'fldPartNumber',
            newSearchString,
            'contains',
            db
          );
          selector = {
            ...selector,
            ...partNumberSearch,
          };
        }
        if (isEmpty(selector.ProductID?.$in)) {
          const newSearchString = searchValue.replace(/[^a-zA-Z0-9]/g, '');
          const modelNumberSearch = await getSelectorByBarcodFilter(
            'ModelNumber',
            newSearchString,
            'contains',
            db
          );
          selector = {
            ...selector,
            ...modelNumberSearch,
          };
        }
        if (isEmpty(selector.ProductID?.$in)) {
          setNoBarcodePopupVisible(true);
          noResultFound(true);
        }
      }

      const length = size(await db.inventory.find({ selector }).exec());
      setCurrentCount(length);
      const issues = await db.inventory
        .find({
          selector,
          skip,
          limit,
          sort,
        })
        .exec();

      const locationItems = await db.tblmultiplelocations.find().exec();

      const expiredResult = {},
        amountResult = {};
      locationItems.forEach((item: any) => {
        if (expiredResult[item.ProductID]) {
          if (
            expiredResult[item.ProductID].UseBy &&
            expiredResult[item.ProductID].UseBy > item.UseBy
          ) {
            expiredResult[item.ProductID] = item;
          }
        } else {
          if (item.UseBy) {
            expiredResult[item.ProductID] = item;
          }
        }

        if (amountResult[item.ProductID]) {
          amountResult[item.ProductID] =
            amountResult[item.ProductID] + item.Amount;
        } else {
          amountResult[item.ProductID] = item.Amount || 0;
        }
      });

      const data = await Promise.all(
        issues.map((item) => transformData(item, expiredResult, amountResult))
      );

      if (size(data) === 1 && searchValue !== '') {
        onSelect(data[0].original);
      }

      return { data, count: length };
    };

    const barcodeSearchPopupClose = () => {
      setNoBarcodePopupVisible(false);
    };

    useImperativeHandle(ref, () => ({
      handleLoadGrid: () => {
        handleForceReload();
      },
      handleExport: (type) => {
        handleDataExport(type);
      },
    }));

    const handleForceReload = () => {
      gridRef.current?.reload();
    };

    // Find if any filters applied to grid
    const areFiltersActive = gridRef?.current.computedFilterValue.some(
      (f) => !isEmpty(f.value)
    );
    const currentFilters = get(gridRef, ['current', 'computedFilterValue'], []);

    const init = async (ref: MutableRefObject<TypeComputedProps | null>) => {
      const db = await getDatabase();
      // const alldpt = (
      //   await db.tblddlistdefaults
      //     .find({ selector: { fldListName: { $eq: "Department" } } })
      //     .exec()
      // ).map((e: any) => e.fldMember);

      // setAllDepartment(alldpt);

      // // Always reload grid on new item / update / delete.
      db.inventory.$.subscribe(async (ev) => {
        if (ev.operation === 'INSERT' || ev.operation === 'UPDATE') {
          highlightedRowId.current = ev.documentId;
          ref.current?.reload();
        }
        if (ev.operation === 'DELETE') {
          highlightedRowId.current = '-1';
          ref.current?.reload();
        }
      });
    };

    useEffect(() => {
      if (isNil(settingsPersonal) || isNil(gridRef)) return;

      if(moduleLevelSearch && moduleLevelSearch.length) {
        gridRef?.current?.setFilterValue([
          ...gridRef.current.computedFilterValue.filter(
            (f) => f.name !== 'ProductID'
          ),
          {
            name: 'ProductID',
            value: moduleLevelSearch,
          },
        ]);
      }
      else {
        if (!isNil(gridRef)) {
          gridRef?.current?.setFilterValue(
            gridRef.current.computedFilterValue.filter(
              (f) => f.name !== 'ProductID'
            )
          );
        }
      }

      // TODO: Please fix that, it creates subscription that's never unsubscribed
      init(gridRef);
    }, [ gridRef, moduleLevelSearch]);
    const onReady = (ref: MutableRefObject<TypeComputedProps | null>) => {
      setGridRef(ref);
      // init(ref);
    };

    const onLoadingChange = (status: boolean) => {
      // If loading completed - check if there any items that needs to be highlighted.
      if (!status && highlightedRowId.current !== '-1') {
        gridRef?.current?.scrollToId(highlightedRowId.current);
        gridRef?.current?.setSelectedById(highlightedRowId.current, true);
        highlightedRowId.current = '-1';
      }
      setLoading(status);
    };

    const dataSource = useCallback(loadData, [searchValue]);

    const exportData = (type = 'CSV' | 'xlsx', withFilters = true) => {
      setExportDialog(null);

      switch (type) {
        case 'CSV':
          return onExportToCSV(withFilters);
        case 'xlsx':
          return onExportToExcel(withFilters);
        default:
      }
    };

    const onExportToExcel = async (withFilters: boolean) => {
      const { data } = await loadData({
        filterValue: withFilters ? currentFilters : [],
      });

      const columnsData = gridRef.current.visibleColumns.map((c: any) => ({
        header: c.header,
        key: c.id,
      }));
      const columns = columnsData.filter(item => {
        return item.header && typeof item.header === 'string';
      });
      const rows = data.map((data: any) =>
        pick(data, [
          'ProductName',
          'Manufacturer',
          'fldPartNumber',
          'ModelNumber',
          'Department',
          'fldGuestType',
          'totalAmount',
        ])
      );

      return exportExcel(columns, rows);
    };
    const onExportToCSV = async (withFilters: boolean) => {
      const columns = gridRef.current.visibleColumns;

      const { data } = await loadData({
        filterValue: withFilters ? currentFilters : [],
      });

      const rows = data.map((data: any) =>
        pick(data, [
          'ProductName',
          'Manufacturer',
          'fldPartNumber',
          'ModelNumber',
          'Department',
          'fldGuestType',
          'totalAmount',
        ])
      );

      return exportCSV(columns, rows);
    };

    const handleDataExport = (type = 'CSV' | 'xlsx') => {
      if (areFiltersActive) {
        setExportDialog({
          visible: true,
          type,
          title: type === 'CSV' ? 'CSV' : 'Excel',
        });

        return;
      }

      exportData(type, false);
    };

    const CollapseButton = () => {
      if (groupCollapsedStatus) {
        setGroupCollapsedStatus(false);
        return gridRef.current.collapseAllGroups();
      }
      if (!groupCollapsedStatus) {
        setGroupCollapsedStatus(true);
        return gridRef.current.expandAllGroups();
      }
    };

    const columns = [
      {
        id: 'icons',
        header: 'Icons',
        defaultWidth: 65,
        visible: !(isTablet || isMobile),
        render: ({ data }: any) => (
            <GridRowAttachmentIcon selector={{ fldRecordKey: data.ProductID }} />
        ),
        onRender: (cellProps: any, { data }: { data: InventoryDocument }) => {
          if (data.expired) {
            cellProps.style.borderLeft = 'red 3px solid';
          } else {
            cellProps.style.borderLeft = '#e4e3e2 3px solid';
          }
        },
      },
      {
        name: 'ProductName',
        header: 'Name',
        flex: 1.5,
      },
      {
        name: 'Manufacturer',
        header: 'Manufacturer',
        flex: 1,
        visible: !isMobile,
      },
      {
        name: 'ModelNumber',
        header: 'Item Number',
        flex: 1,
        visible: !(isTablet || isMobile),
      },
      {
        name: 'totalAmount',
        header: 'Total Amount',
        flex: 0.5,
        visible: !isMobile,
        headerAlign: 'start' as any,
        textAlign: 'end' as any,
        type: 'number',
      },
    ];

    const footerRows = [
      {
        position: 'end',
        render: {
          icons: () => {
  
            const style = {
              paddingLeft: 20,
            };
  
            return (
              <div style={style}>
                Total Records: {currentCount}/{totalCount}
              </div>
            );
          },
        },
        colspan: {
          icons: (_, computedProps) => computedProps.visibleColumns.length,
        },
      },
    ];

    const renderGroupTitle = (value, { data }) => {
      const columns = data.fieldPath.map((col) =>
        col === data.name ? col.toUpperCase() : col
      );
      let path = columns && columns.length && columns.join('>');

      if (path == 'fldPartNumber') {
        path = 'Part Number';
      }
      if (path == 'ModelNumber') {
        path = 'Item Number';
      }
      if (path == 'fldPartNumber') {
        path = 'Part Number';
      }
      if (path == 'Manufacturer') {
        path = 'Maker/Bottler';
      }
      return data.value == 'null' ? `No ${path} Assigned` : data.value;
    };

    return (
      <div data-testid="data-grid" className="flex flex-col flex-grow">
        <div className="flex flex-row items-center justify-end">
          {!isEmpty(gridRef?.current.computedGroupBy) &&
            (groupCollapsedStatus ? (
              <div>
                <Tooltip title="Collapse All">
                  <IconButton onClick={CollapseButton}>
                    <UnfoldLessIcon />
                  </IconButton>
                </Tooltip>
              </div>
            ) : (
              <div>
                <Tooltip title="Expand All">
                  <IconButton onClick={CollapseButton}>
                    <UnfoldMoreIcon />
                  </IconButton>
                </Tooltip>
              </div>
            )
          )}
          {settingsPersonal?.fldInvMed === 2 && (
            <DeleteRecordBtnOld
              visible={isActionable}
              records={rowSelected}
              onSelect={setRowSelected}
            />
          )}
        </div>

        <LicensedReactDataGrid
          onRowClick={onRowClick}
          onLoadingChange={onLoadingChange}
          defaultLimit={5000}
          livePagination
          onReady={onReady}
          rowHeight={40}
          defaultGroupBy={[]}
          onGroupByChange={() => gridRef.current.collapseAllGroups()}
          collapsedGroups={collapsedGroups}
          onGroupCollapseChange={setCollapsedGroups}
          loading={loading}
          enableSelection
          // defaultSelected={defaultSelection? {1:true}:{} }
          defaultFilterValue={filter}
          idProperty="ProductID"
          // theme='default-dark'
          columns={columns}
          dataSource={dataSource}
          renderGroupTitle={renderGroupTitle}
          footerRows={footerRows}
          selected={rowSelected}
          onSelectionChange={handleSelectionChange}
          checkboxColumn
          sourceRoot={sourceRoot}
        />

        <Dialog maxWidth="xs" open={exportDialog?.visible || false}>
          <DialogTitle>
            Export data to
            {exportDialog?.title}
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              You have filters applied. Would you like to export with current
              filters?
            </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'flex-end' }}>
            <Button
              autoFocus
              onClick={() => exportData(exportDialog?.type, false)}
            >
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => exportData(exportDialog?.type)}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog maxWidth="xs" open={noBarcodePopupVisible}>
          <DialogTitle>Search By Bar Code</DialogTitle>
          <DialogContent dividers>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <InfoIcon color="primary" sx={{ fontSize: 40 }} />
                </Grid>
                <Grid item xs={10}>
                  <Typography gutterBottom>
                    Item Not Found. Try entering fewer characters and using
                    wildcards.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'flex-end' }}>
            <Button variant="contained" onClick={barcodeSearchPopupClose}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
);

export default MedicalInventoryGrid;
