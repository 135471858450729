import { RxCollection, RxJsonSchema, RxDocument } from 'rxdb';
import { TblLocationsHierarchy } from '../../../generated/graphql';

export type TblLocationsHierarchyDocType = TblLocationsHierarchy;

export type TblLocationsHierarchyDocMethods = {};

export type TblLocationsHierarchyDocument = RxDocument<
  TblLocationsHierarchyDocType,
  TblLocationsHierarchyDocMethods
>;

export type TblLocationsHierarchyCollectionMethods = {};

export type TblLocationsHierarchyCollection = RxCollection<
  TblLocationsHierarchyDocType,
  TblLocationsHierarchyDocMethods,
  TblLocationsHierarchyCollectionMethods
>;

export const tblLocationsHierarchySchema: RxJsonSchema<TblLocationsHierarchyDocType> = {
  title: 'tblLocationshierarchy ',
  description: 'describes a tblLocationshierarchy items',
  version: 1,
  keyCompression: false,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    PKey: {
      type: 'string',
      primary: true,
    },
    ParentID: {
      type: ['string', 'null'],
      ref: 'tbllocationshierarchy',
    },
    fldDepts: {
      type: ['string', 'null'],
    },
    fldLocation: {
      type: ['string', 'null'],
    },
    fldNoView: {
      type: ['boolean', 'null'],
    },
    fldTags: {
      type: ['string', 'null'],
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
    updatedAt: {
      type: 'string',
    },
  },
  required: [],
  additionalProperties: false,
};

export const tblLocationsHierarchyDocMethods: TblLocationsHierarchyDocMethods = {};

export const tblLocationsHierarchyCollectionMethods: TblLocationsHierarchyCollectionMethods = {};
