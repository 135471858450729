// @ts-nocheck
import React, {
  FC,
  useCallback,
  useState,
  MutableRefObject,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { isNil, pick, get, size, isEmpty } from 'lodash';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import TypeSortInfo, {
  TypeComputedProps,
} from '@inovua/reactdatagrid-enterprise/types';
import '../styles.css';
import {exportCSV, exportExcel, getRegexByOperator} from '../../../utils';
import LicensedReactDataGrid from '../../../components/UI/LicensedReactDataGrid';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import DeleteRecordBtnOld from '../../../components/DeleteRecordBtn/indexOld';
import { useAppState } from 'src/contexts/app-state';

// Data
import { TblDocumentationDocument } from '../rxdb';
import { getDatabase, TDIDb } from '../../../rxdb';
import GridRowAttachmentIcon from "../../../components/UI/LicensedReactDataGrid/components/GridRowAttachmentIcon";

type TblDocumentationGridProps = {
  darken: boolean;
  onSelect: (item: TblDocumentationDocument) => void;
  libraryType: any;
  sourceRoot?: boolean;
};

interface LoadDataParams {
  sortInfo: TypeSortInfo;
  skip: number;
  limit: number;
  filterValue: any;
  libraryType: string;
}

const filter = [
  {
    name: 'DocTitle',
    operator: 'contains',
    type: 'string',
    value: '',
  },
];

const transformData = async (item: TblDocumentationDocument) => ({
  ...pick(item, ['PKey', 'DocTitle']),
  original: item,
});

const getSelectorByFilterName = async (
  name: string,
  value: string,
  operator: string,
  db: TDIDb
) => {
  switch (name) {
    case 'DocTitle':
      return {
        DocTitle: { $regex: getRegexByOperator(operator, value) },
      };

    default:
      return {};
  }
};

interface TblDocumentationGridRef {
  handleExport: (type: string) => void;
}

const TblDocumentationGrid = forwardRef<
  TblDocumentationGridRef,
  TblDocumentationGridProps
>(
  (
    { onSelect, libraryType, sourceRoot = false }: TblDocumentationGridProps,
    ref
  ) => {
    const { settingsPersonal } = useAppState();
    const [loading, setLoading] = useState<boolean>(false);
    const highlightedRowId = useRef<string>('-1');
    const [gridRef, setGridRef] = useState<any>(null);
    const [exportDialog, setExportDialog] = useState(null);
    const [collapsedGroups, setCollapsedGroups] = useState({});
    const [groupCollapsedStatus, setGroupCollapsedStatus] = useState(false);
    const [currentCount, setCurrentCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [rowSelected, setRowSelected] = useState(null);

    const isActionable =
      Object.values(rowSelected || {}).filter((item: any) => item).length > 0;

    const handleSelectionChange = useCallback(({ selected }) => {
      setRowSelected(selected);
    }, []);

    useImperativeHandle(ref, () => ({
      handleExport: (type) => {
        handleDataExport(type);
      },
    }));

    const handleGetCount = async () => {
      const db = await getDatabase();
      let selector = {
        fldShowInCentral: { $eq: true },
        fldLibtype: { $eq: libraryType },
        deletedBy: {
          $eq: null,
        },
      };
      const length = size(await db.tbldocumentation.find({ selector }).exec());
      setTotalCount(length);
    };

    useEffect(() => {
      handleGetCount();
    }, [loading]);

    // Find if any filters applied to grid
    const areFiltersActive = gridRef?.current.computedFilterValue.some(
      (f) => !isEmpty(f.value)
    );
    const currentFilters = get(gridRef, ['current', 'computedFilterValue'], []);

    const init = async (ref: MutableRefObject<TypeComputedProps | null>) => {
      const db = await getDatabase();

      // // Always reload grid on new item / update / delete.
      db.tbldocumentation.$.subscribe(async (ev) => {
        if (ev.operation === 'INSERT' || ev.operation === 'UPDATE') {
          highlightedRowId.current = ev.documentId;
          ref.current?.reload();
        }
        if (ev.operation === 'DELETE') {
          highlightedRowId.current = '-1';
          ref.current?.reload();
        }
      });
    };

    const onReady = (ref: MutableRefObject<TypeComputedProps | null>) => {
      setGridRef(ref);
      init(ref);
    };
    const onLoadingChange = (status: boolean) => {
      // If loading completed - check if there any tasks that needs to be highlighted.
      if (!status && highlightedRowId.current !== '-1') {
        gridRef?.current?.scrollToId(highlightedRowId.current);
        gridRef?.current?.setSelectedById(highlightedRowId.current, true);
        highlightedRowId.current = '-1';
      }
      setLoading(status);
    };

    const loadData = async ({
      skip,
      limit,
      filterValue,
    }: {
      sortInfo: TypeSortInfo;
      skip: number;
      limit: number;
      filterValue: any;
    }): Promise<{ data: any[]; count: number }> => {
      const db = await getDatabase();
      let selector = {
        fldShowInCentral: { $eq: true },
        fldLibtype: { $eq: libraryType },
        deletedBy: {
          $eq: null,
        },
      };

      // console.log('*** updated selector', libraryType, selector);
      await Promise.all(
        filterValue.map(async (v) => {
          if (isEmpty(v.value)) return v;

          const s = await getSelectorByFilterName(
            v.name,
            v.value,
            v.operator,
            db
          );

          selector = {
            ...selector,
            ...s,
          };
          return v;
        })
      );

      const length = size(await db.tbldocumentation.find({ selector }).exec());
      setCurrentCount(length);
      const items = await db.tbldocumentation
        .find({
          selector,
          skip,
          limit,
          // sort: null,
        })
        .exec();

      const data = await Promise.all(items.map(transformData));

      return { data, count: length };
    };

    const dataSource = useCallback(loadData, [libraryType]);

    const onRowClick = useCallback(
      ({ data }) => {
        if (Object.keys(rowSelected || {}).length < 2) {
          onSelect(data.original);
        }
      },
      [rowSelected]
    );

    const exportData = (type = 'CSV' | 'xlsx', withFilters = true) => {
      setExportDialog(null);

      switch (type) {
        case 'CSV':
          return onExportToCSV(withFilters);
        case 'xlsx':
          return onExportToExcel(withFilters);
        default:
      }
    };
    const getRows = async (issue: any) => {
      const rows =  issue.map((data: any) =>{
      
        return {
          ...pick(data, [
            'DocTitle',
          ]),
        };
      }   
      );
      return rows
    };

    const onExportToExcel = async (withFilters: boolean) => {
      const { data } = await loadData({
        filterValue: withFilters ? currentFilters : [],
      });

      const columnsData = gridRef.current.visibleColumns.map((c: any) => ({
        header: c.header,
        key: c.id,
      }));
      const columns = columnsData.filter(item => {
        return item.header && typeof item.header === 'string';
      });
      const rows = await getRows(data)

      return exportExcel(columns, rows);
    };
    const onExportToCSV = async (withFilters: boolean) => {
      const columns = gridRef.current.visibleColumns;

      const { data } = await loadData({
        filterValue: withFilters ? currentFilters : [],
      });
      const rows = data.map((data: any) => pick(data, ['DocTitle']));

      return exportCSV(columns, rows);
    };

    const handleDataExport = (type = 'CSV' | 'xlsx') => {
      if (areFiltersActive) {
        setExportDialog({
          visible: true,
          type,
          title: type === 'CSV' ? 'CSV' : 'Excel',
        });

        return;
      }

      exportData(type, false);
    };

    const renderGroupTitle = (value, { data }) => {
      const columns = data.fieldPath.map((col) =>
        col === data.name ? col.toUpperCase() : col
      );
      const path = columns && columns.length && columns.join('>');
      return isNil(data.value) ? `No ${path} Assigned` : data.value;
    };

    const CollapseButton = () => {
      if (groupCollapsedStatus) {
        setGroupCollapsedStatus(false);
        return gridRef.current.collapseAllGroups();
      }
      if (!groupCollapsedStatus) {
        setGroupCollapsedStatus(true);
        return gridRef.current.expandAllGroups();
      }
    };

    const columns = [
      {
        id: 'icons',
        defaultWidth: 65,
        render: ({ data }: any) => (
            <GridRowAttachmentIcon selector={{ fldRecordKey: data.PKey }} />
        ),
        onRender: (
          cellProps: any,
          { data }: { data: TblDocumentationDocument }
        ) => {
          if (
            !isEmpty(data?.DateDue) &&
            new Date().toISOString() > data?.DateDue
          ) {
            cellProps.style.borderLeft = 'red 3px solid';
          } else {
            cellProps.style.borderLeft = '#e4e3e2 3px solid';
          }
        },
      },
      {
        name: 'DocTitle',
        header: 'Title',
        flex: 1,
      },
    ];
    const footerRows = [
      {
        position: 'end',
        render: {
          icons: () => {
  
            const style = {
              paddingLeft: 20,
            };
  
            return (
              <div style={style}>
                Total Records: {currentCount}/{totalCount}
              </div>
            );
          },
        },
        colspan: {
          icons: (_, computedProps) => computedProps.visibleColumns.length,
        },
      },
    ];

    return (
      <div data-testid="data-grid" className="flex flex-col flex-grow">
        <div className="flex flex-row items-center justify-end">
          {!isEmpty(gridRef?.current.computedGroupBy) &&
            (groupCollapsedStatus ? (
              <div>
                <Tooltip title="Collapse All">
                  <IconButton onClick={CollapseButton}>
                    <UnfoldLessIcon />
                  </IconButton>
                </Tooltip>
              </div>
            ) : (
              <div>
                <Tooltip title="Expand All">
                  <IconButton onClick={CollapseButton}>
                    <UnfoldMoreIcon />
                  </IconButton>
                </Tooltip>
              </div>
            ))}
          {settingsPersonal?.fldCDM === 2 && (
            <DeleteRecordBtnOld
              visible={isActionable}
              records={rowSelected}
              onSelect={setRowSelected}
            />
          )}
        </div>
        <LicensedReactDataGrid
          onRowClick={onRowClick}
          onLoadingChange={onLoadingChange}
          defaultLimit={5000}
          livePagination
          onReady={onReady}
          rowHeight={40}
          defaultGroupBy={[]}
          onGroupByChange={() => gridRef.current.collapseAllGroups()}
          collapsedGroups={collapsedGroups}
          onGroupCollapseChange={setCollapsedGroups}
          loading={loading}
          enableSelection
          defaultFilterValue={filter}
          idProperty="PKey"
          // theme="default-dark"
          columns={columns}
          dataSource={dataSource}
          renderGroupTitle={renderGroupTitle}
          defaultSortInfo={[{ name: 'DocTitle', dir: 1 }]}
          footerRows={footerRows}
          selected={rowSelected}
          onSelectionChange={handleSelectionChange}
          checkboxColumn
          sourceRoot={sourceRoot}
        />

        <Dialog maxWidth="xs" open={exportDialog?.visible || false}>
          <DialogTitle>
            Export data to
            {exportDialog?.title}
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              You have filters applied. Would you like to export with current
              filters?
            </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'flex-end' }}>
            <Button
              autoFocus
              onClick={() => exportData(exportDialog?.type, false)}
            >
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => exportData(exportDialog?.type)}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
);

export default TblDocumentationGrid;
