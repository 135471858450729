import { TblSchedMaint } from '../../../generated/graphql';
import {logger} from "../../../helpers/logger";
import { omit } from 'lodash';

const fields = `
  DateEntered
  Department
  PKey
  RecurInterval
  RecurPattern
  RecurType
  fldAssignedTo
  fldCSM
  fldDuration
  fldHTML
  fldHourInterval
  fldHourLock
  fldHourWarn
  fldHoursKey
  fldIsCheckList
  fldLinkID
  fldListType
  fldNotes
  fldPlainText
  fldQuotedCurrency
  fldQuotedPrice
  fldRTF
  fldSMS
  fldSRHKey
  fldSchedType
  fldSteps
  fldSubject
  fldTTLWork
  fldTimeInterval
  fldTimeMeasure
  fldTimeMeasureWarn
  fldTimeWarn
  fldToolsReqd
  fldUserDefined
  deletedAt
  deletedBy
  isRecoverable
  updatedAt
  autoId
`;

export const schedMaintQuery = (doc: TblSchedMaint) => {
  if (!doc) {
    doc = {
      autoId: 0,
      PKey: '',
      fldSubject: '',
      updatedAt: new Date(0).toISOString(),
    };
  }
  logger('TblSchedMaint').info(
    `pull query triggered - TblSchedMaint:  { ${new Date().toISOString()} - ID: ${doc.autoId} }`,
  );
  return {
    query: `
        query getTblSchedMaint ($autoId: Int, $filterDate: Date) {
              tblSchedMaint (
                where:{ 
                  _and: [
                    {
                      _or: [
                        {
                          isRecoverable: {
                            _is_null: true
                          }
                        },
                        {
                          isRecoverable: {
                            _eq: true
                          }
                        }
                      ]
                    },
                    {
                      _or: [
                        {
                          updatedAt: {_gt: $filterDate},                
                        },
                        {
                          autoId: {_gt: $autoId}
                          updatedAt: {_gte: $filterDate},
                        }
                      ]
                    }
                  ]                                    
                },
                order_by: [
                  {updatedAt: asc},
                  {autoId: asc}
                ],
                limit: 500
              ) {
                ${fields}
           }
          }
      `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
    },
  };
};

export const schedMaintMutation = (doc: any) => {
  const query = `
    mutation upsertSchedMaint($input: SchedMaintInput!) {
      upsertSchedMaint(input: $input) {
        ${fields}
      }
    }
  `;

  const variables = {
    input: omit(doc, ['deletedAt', 'updatedAt']),
  };

  return {
    query,
    variables,
  };
};

export const schedMaintSubscription = `
  subscription onSchedMaintChanged {
    tblSchedMaint {
      ${fields}
    }
  }
`;
