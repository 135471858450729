import { RxCollection, RxJsonSchema, RxDocument } from 'rxdb';
import { TblSparesUsed } from '../../generated/graphql';

export type TblSparesUsedDocType = TblSparesUsed;

export type TblSparesUsedDocMethods = {};

export type TblSparesUsedDocument = RxDocument<
  TblSparesUsedDocType,
  TblSparesUsedDocMethods
>;

export type TblSparesUsedCollectionMethods = {};

export type TblSparesUsedCollection = RxCollection<
  TblSparesUsedDocType,
  TblSparesUsedDocMethods,
  TblSparesUsedCollectionMethods
>;

export const tblSparesUsedSchema: RxJsonSchema<TblSparesUsedDocType> = {
  title: 'TblSparesUsed ',
  description: 'describes a workissue task items',
  version: 1,
  keyCompression: false,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    PKey: {
      type: 'string',
      primary: true,
    },
    ProductID: {
      type: ['string', 'null'],
      ref: 'inventory',
    },
    LogKey: {
      type: ['string', 'null'],
    },
    EqKey: {
      type: ['string', 'null'],
    },
    WorkKey: {
      type: ['string', 'null'],
    },
    MultiKey: {
      type: ['string', 'null'],
    },
    Qty: {
      type: ['string', 'null'],
    },
    Amount: {
      type: ['number', 'null'],
    },
    fldDateUsed: {
      type: ['string', 'null'],
    },
    DateEntered: {
      type: ['string', 'null'],
    },
    fldUpdateInvOnSave: {
      type: ['boolean', 'null'],
    },
    // Product: {
    //   type: 'object',
    //   properties: {
    //     ProductName: {
    //       type: ['string', 'null'],
    //     },
    //     Manufacturer: {
    //       type: ['string', 'null'],
    //     },
    //     fldPartNumber: {
    //       type: ['string', 'null'],
    //     },
    //   },
    // },
    deletedAt: {
      type: ['string', 'null'],
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
    updatedAt: {
      type: 'string',
    },
  },
  required: [],
  additionalProperties: false,
};

export const tblSparesUsedDocMethods: TblSparesUsedDocMethods = {};

export const tblSparesUsedCollectionMethods: TblSparesUsedCollectionMethods = {};
