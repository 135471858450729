import { RxCollection, RxJsonSchema, RxDocument } from 'rxdb';
import { TblMonSysTags } from 'src/generated/graphql';

export type TblMonSysTagsDocType = TblMonSysTags;

export type TblMonSysTagsDocMethods = {};

export type TblMonSysTagsDocument = RxDocument<
  TblMonSysTagsDocType,
  TblMonSysTagsDocMethods
>;

export type TblMonSysTagsCollectionMethods = {};

export type TblMonSysTagsCollection = RxCollection<
  TblMonSysTagsDocType,
  TblMonSysTagsDocMethods,
  TblMonSysTagsCollectionMethods
>;

export const tblMonSysTagsSchema: RxJsonSchema<TblMonSysTagsDocType> = {
  title: 'TblMonSysTags ',
  description: 'TblMonSysTags',
  version: 2,
  keyCompression: false,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    PKey: {
      type: 'string',
      primary: true,
    },
    EQKey: {
      type: ['string', 'null'],
    },
    fldTag: {
      type: ['string', 'null'],
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
    updatedAt: {
      type: 'string',
    },

  },
  indexes: [
    'PKey', 'updatedAt',
  ],
  additionalProperties: false,
};

export const tblMonSysTagsDocMethods: TblMonSysTagsDocMethods = {};

export const tblMonSysTagsCollectionMethods: TblMonSysTagsCollectionMethods = {};
