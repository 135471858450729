import React, { FC, useState } from 'react';
import { Control, UseFormWatch } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import { OrdersDocument } from 'src/rxdb/collections/Orders/schema';
import { Orders } from 'src/generated/graphql';
import { Button, Snackbar, Alert, CircularProgress, Dialog, DialogTitle, DialogContent, Box, Grid, DialogActions } from '@mui/material';
import { OrderItemsDocument } from 'src/rxdb/collections/OrderItems/schema';
import { getDatabase } from 'src/rxdb';
import {ReceivedSelectedItemEnum} from './components/OrderItemsAddDialog';
import OrderItemsGrid from './components/OrderItemsGrid';
import OrderItemsAddDialog from './components/OrderItemsAddDialog';
import Input from 'src/components/UI/Forms/Input';
import InvStorage from 'src/components/dataentry/inventoryForm';
import { isNil, pick, size } from 'lodash';
import { MultipleLocationDocument } from 'src/rxdb/collections/MultipleLocations/schema';
import WarningDialog from 'src/components/UI/WarningDialog';

interface Props {
  form: Orders;
  initialValue: OrdersDocument;
  onChange: (field: string, value: any) => void;
  control: Control;
  watch: UseFormWatch<any>;
  getValues: any;
  setPOItems: (data: any) => void;
  onSave: (isUpdated: boolean) => void;
  editFlag?: boolean;
  disableEdit: boolean;
}

const OrderLineItems: FC<Props> = ({
  form,
  onChange,
  initialValue,
  control,
  watch,
  getValues,
  setPOItems,
  onSave,
  editFlag = false,
  disableEdit = false,
}) => {
  const [addOrderItemPopupVisible, setAddOrderItemPopupVisible] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<OrdersDocument>(initialValue);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>(undefined);
  const [editOrderItemPopupVisible, setEditOrderItemPopupVisible] = useState<boolean>(false);
  const [receiveItemOption, setReceiveItemOption] = useState<ReceivedSelectedItemEnum | undefined>(); 
  const [showChooseLocationAndAmountDialog, setShowChooseLocationAndAmountDialog] = useState<boolean>(false);
  const [showChooseAmountDialog, setShowChooseAmountDialog] = useState<boolean>(false);
  const [showUseDefaultDialog, setShowUseDefaultDialog] = useState<boolean>(false);

  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const handleSelection = (item: any, receiveItemOption: ReceivedSelectedItemEnum | undefined) => {
    setSelectedItem(item);
    setReceiveItemOption(receiveItemOption);
    if(receiveItemOption === 0){
      setShowUseDefaultDialog(true);
    } 
    if(receiveItemOption === 1){
      setShowChooseAmountDialog(true)
    } 
    if(receiveItemOption === 2){
      setShowChooseLocationAndAmountDialog(true)
    } 
    if(receiveItemOption === undefined) { 
      setEditOrderItemPopupVisible(true)
    }
  };

  const handleAddClick = async () => {
    const db = await getDatabase();

    const document: OrderItemsDocument = await db.orderitems.newDocument({
      PKey: uuid(),
      ProductID: null,
      OrderID: '',
      ProductName: null,
      ProductDescription: null,
      Amount: 0,
      UnitPrice: 0.0,
      ModelNumber: null,
      Department: null,
      Manufacturer: null,
      Qty: null,
      Book: null,
      Page: null,
      Figure: null,
      ToInventory: false,
      SearchMod: null,
      SearchMaker: null,
      SearchPart: null,
      PartNumber: null,
      AmtReceived: 0.0,
      QCurrency: null,
      QCurrencyAmt: 0.0,
      QtyRecd: null,
      OrderComplete: 0,
      fldDataType: null,
      fldColor: null,
    });

    setReceiveItemOption(undefined);
    setSelectedItem(document);
    setAddOrderItemPopupVisible(true);
  };

  const handleOrderItemDialogCancel = () => {
    setSelectedItem(undefined);
    setAddOrderItemPopupVisible(false);
    setEditOrderItemPopupVisible(false);
    setShowUseDefaultDialog(false);
    setShowChooseAmountDialog(false);
    setShowChooseLocationAndAmountDialog(false);
  };

  const handleEditOrderItemSave = () => {
    setLoading(true);
    setInitialValues(initialValue);
    setEditOrderItemPopupVisible(false);
    setSnackbar({
      open: true,
      message: 'This item has been Updated!',
      type: 'success',
    });
    setLoading(false);
    setSelectedItem(undefined);
    onSave(true);
  };

  const handleAddOrderItemSave = (data?:any, isCreated?: boolean) => {
    setLoading(true);
    setInitialValues(initialValue);
    setAddOrderItemPopupVisible(false);
    setSnackbar({
      open: true,
      message: 'Item has been created!',
      type: 'success',
    });
    setLoading(false);
    if(!isCreated){
      setSelectedItem(undefined);
    } else {
      setSelectedItem(data);
    }
    onSave(true);
  };

  const handleDelete = async (item: OrderItemsDocument) => {
    try {
      await item.remove();

      setSnackbar({
        open: true,
        message: 'Item successfully removed',
        type: 'success',
      });
    } catch (e: any) {
      setSnackbar({
        open: true,
        message: e.message,
        type: 'error',
      });
    }
  };

  const transformData = async (item: MultipleLocationDocument) => {
    return {
      ...pick(item, ['ProductID', 'fldDefault', 'Amount', 'PKey']),
      original: item,
    }
  }

  const handleUseDefaultsClick = async () => {
    if (selectedItem.Amount <= selectedItem.AmtReceived) {
      setShowUseDefaultDialog(false);
      setSnackbar({
        open: true,
        type: 'error',
        message: 'Item received, nothing to received in inventory'
      });
    } else {
      const db = await getDatabase();
      let document = {} as any;

      const items = await db.tblmultiplelocations.find({
        selector: {
          ProductID: { $eq: selectedItem.ProductID }
        }
      }).exec()
      const data = await Promise.all(items.map(transformData));

      const parsedAmount = isNil(selectedItem.Amount) ? 0 : parseFloat(selectedItem.Amount);
      const parsedAmountReceived = isNil(selectedItem.AmtReceived) ? 0 : parseFloat(selectedItem.AmtReceived);

      if (size(items) === 0) {
        document = {
          ProductID: selectedItem.ProductID,
          Amount: parsedAmount,
          PKey: uuid(),
        } as any
      } else if (size(items) === 1) {
        const amountData = isNil(data[0]?.Amount) ? 0 : data[0]?.Amount;
        document = {
          ...data[0].original.toJSON(),
          Amount: amountData + parsedAmount - parsedAmountReceived,
        } as any
      } else {
        const defaultData = data.filter((e: any) => e.fldDefault === true);
        if (size(defaultData) === 1) {
          const amountData = isNil(defaultData[0]?.Amount) ? 0 : defaultData[0]?.Amount;
          document = {
            ...defaultData[0].original.toJSON(),
            Amount: amountData + parsedAmount - parsedAmountReceived
          } as any

        } else {
          const amountData = isNil(data[0]?.Amount) ? 0 : data[0]?.Amount;
          document = {
            ...data[0].original.toJSON(),
            Amount: amountData + parsedAmount - parsedAmountReceived
          } as any
        }
      }

      const orderItemDocument = {
        Amount: parseFloat(selectedItem.Amount) || 0,
        OrderID: initialValue.OrderID,
        ProductID: selectedItem.ProductID,
        PKey: selectedItem.PKey,
        fldDataType: selectedItem.fldDataType,
        ProductName: selectedItem.ProductName,
        Manufacturer: selectedItem.Manufacturer,
        ModelNumber: selectedItem.ModelNumber,
        fldColor: selectedItem.fldColor,
        fldSize: selectedItem.fldSize,
        PartNumber: selectedItem.PartNumber,
        Qty: selectedItem.Qty,
        UnitPrice: parseFloat(selectedItem.UnitPrice) || 0,
        QCurrency: selectedItem.QCurrency,
        ProductDescription: selectedItem.ProductDescription,
        updatedAt: new Date().toISOString(),
        AmtReceived: parseFloat(selectedItem.Amount)
      } as any

      try {
        await db.collections.tblmultiplelocations.upsert(document);
        await db.orderitems.upsert(orderItemDocument)
        handleEditOrderItemSave();
        setSnackbar({
          open: true,
          type: 'success',
          message: 'Item received!'
        });
        setShowUseDefaultDialog(false);
      } catch (e: any) {
        setSnackbar({
          open: true,
          type: 'error',
          message: e.message,
        });
      }
    }
  }

  const ReceivedAmount = watch("ReceivedAmount", "");

  const onSaveChooseAmount = async () => {
    let AmtReceived: number = selectedItem.AmtReceived ?? 0;
    AmtReceived += Number(ReceivedAmount) ?? 0;
    const document = {
      OrderID: initialValue.OrderID,
      ProductID: selectedItem.ProductID,
      PKey: selectedItem.PKey,
      fldDataType: selectedItem.fldDataType,
      ProductName: selectedItem.ProductName,
      Manufacturer: selectedItem.Manufacturer,
      ModelNumber: selectedItem.ModelNumber,
      fldSize: selectedItem.fldSize,
      fldColor: selectedItem.fldColor,
      PartNumber: selectedItem.PartNumber,
      Qty: selectedItem.Qty,
      UnitPrice: selectedItem.UnitPrice,
      QCurrency: selectedItem.QCurrency,
      Amount: selectedItem.Amount,
      ProductDescription: selectedItem.ProductDescription,
      updatedAt: new Date().toISOString(),
      AmtReceived,
    } as any;

    try {
      const db = await getDatabase();
      await db.orderitems.upsert(document);

      let locationItem: any = await db.tblmultiplelocations.find({
        selector: {
          ProductID: {
            $eq: selectedItem.ProductID,
          },
          deletedAt: {
            $eq: null
          },
        }
      }).exec();
      let newLocationItem = {} as any;
      if (size(locationItem) === 0) {
        newLocationItem = {
          ProductID: selectedItem.ProductID,
          Amount: Number(ReceivedAmount),
          PKey: uuid(),
        } as any
        await db.tblmultiplelocations.upsert(newLocationItem);
      }
       else if(locationItem && locationItem.length > 0) {
        newLocationItem = JSON.parse(JSON.stringify(locationItem[0]));
        newLocationItem.Amount = Number(newLocationItem.Amount) + Number(ReceivedAmount)
        delete newLocationItem.updatedAt;
        delete newLocationItem.deletedBy;
        delete newLocationItem.deletedAt;
        delete newLocationItem.isRecoverable;
        await db.tblmultiplelocations.upsert(newLocationItem);
      }
      handleEditOrderItemSave();
      handleOrderItemDialogCancel();
    } catch (e: any) {
      setSnackbar({
        open: true,
        type: 'error',
        message: e.message,
      });
    }
  }

  return (
    <>
      <div className="pt-5">
        <div className="flex justify-end mb-6">
          <Button
            variant="contained" 
            onClick={handleAddClick} 
            disabled={editFlag || disableEdit}
          >
            Add Item
          </Button>
        </div>
        <div>
          {loading ? (
            <div className="flex items-center justify-center w-full">
              <CircularProgress />
            </div>
          ) : (
            <OrderItemsGrid
              initialValue={initialValue}
              onDelete={handleDelete}
              loadingGrid={loading}
              onSelect={handleSelection}
              onSave={handleAddOrderItemSave}
              setPOItems={setPOItems}
              editFlag={editFlag}
              disableEdit={disableEdit}
            />
          )}
        </div>
        {!!selectedItem && addOrderItemPopupVisible && (
          <OrderItemsAddDialog
            visible={addOrderItemPopupVisible}
            initialValue={initialValues}
            orderItemInitialValue={selectedItem}
            onCancel={handleOrderItemDialogCancel}
            onSave={handleAddOrderItemSave}
            title="Add"
            receiveItemOption={receiveItemOption}
          />
        )}
        {!!selectedItem && (
          <OrderItemsAddDialog
            visible={editOrderItemPopupVisible}
            initialValue={initialValues}
            orderItemInitialValue={selectedItem}
            onCancel={handleOrderItemDialogCancel}
            onSave={handleEditOrderItemSave}
            title="Edit"
            receiveItemOption={receiveItemOption}
          />
        )}
        {!!selectedItem && (
          <Dialog
            scroll="paper"
            fullWidth
            maxWidth="md"
            open={showChooseAmountDialog}
            onClose={handleOrderItemDialogCancel}
          >
            <DialogTitle>
              <span className="font-bold text-2xl">Add Amount</span>
            </DialogTitle>
            <DialogContent dividers sx={{ p: 4 }}>
              <div>
                <Box>
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <Input
                        name="ReceivedAmount"
                        inputProps={{
                          label: 'Amount *',
                          type: 'number',
                          inputProps: {
                            min: 0,
                            inputMode: 'decimal',
                            step: 1,
                            style: { textAlign: 'end' },
                          },
                        }}
                        control={control}
                        rules={{
                          min: 0,
                          required: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box mt={4}>
                  <Button variant="contained" onClick={onSaveChooseAmount}>Save</Button>
                </Box>
              </div>
            </DialogContent>
          </Dialog>
        )}
        {!!selectedItem && (
          <Dialog
            scroll="paper"
            fullWidth
            maxWidth="md"
            open={showChooseLocationAndAmountDialog}
            onClose={handleOrderItemDialogCancel}
            PaperProps={{
              style: {
                minHeight: '80vh', // Adjust this value as needed
              },
            }}
          >
            <DialogTitle>
              <span className="font-bold text-2xl">Storage Locations</span>
            </DialogTitle>
            <DialogContent dividers sx={{ p: 4, pt: 0 }}>
              <div>
                <InvStorage
                  form={selectedItem.toJSON()}
                  componentName='OrderItemAddDialog'
                  onChange={onChange}
                  initialValue={selectedItem}
                  control={control}
                  watch={watch}
                  getValues={getValues}
                  onSave={() => { }}
                  onSaveInv={()=>{}}
                />
              </div>
            </DialogContent>
            <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'flex-end ' }}>
              <Button className="mr-2" onClick={handleOrderItemDialogCancel}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {!!selectedItem &&
          <WarningDialog
            visible={showUseDefaultDialog}
            title="Use Defaults"
            content={`Receive ${selectedItem.Amount} ${selectedItem.ProductName} to default Location?`}
            okText="OK"
            color="info"
            onOk={handleUseDefaultsClick}
            onCancel={handleOrderItemDialogCancel}
          />
        }
      </div>


      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        onClose={onSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
          {snackBar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default OrderLineItems;
