import { createCanvas } from 'canvas';
import JsBarcode from 'jsbarcode';

const generateBarcodeImage = (
  text: string,
  widthBC: number,
  heightBC: number,
  format: string = 'CODE128'
) => {
  const canvas = createCanvas(widthBC, heightBC);
  const options = {
    format: format,
    displayValue: false,
  };

  JsBarcode(canvas, text, options);

  return canvas.toDataURL();
};

export { generateBarcodeImage };