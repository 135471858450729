import { ApolloError, gql, useMutation } from '@apollo/client';
import { QueryGridSavedViewsArgs } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { GridSavedViewMutationResponse } from './useUpsertGridSavedView';
import { GET_GRID_SAVED_VIEWS } from './useGetGridSavedViews';

export const DELETE_GRID_SAVED_VIEW = gql`
  mutation DeleteGridSavedView($gridSavedViewId: String!) {
    deleteGridSavedView(gridSavedViewId: $gridSavedViewId) {
      view {
        pkey
        fldLayout
        fldModule
        fldViewName
      }
    }
  }
`;

interface DeleteGridSavedViewResult {
  removeGridSavedView: (gridSavedViewId: string, variables: QueryGridSavedViewsArgs) => Promise<GridSavedViewMutationResponse>;
  removeGridSavedViewLoading: boolean;
}

export const useDeleteGridSavedView = (): DeleteGridSavedViewResult => {
  const [deleteGridSavedView, { loading }] = useMutation(DELETE_GRID_SAVED_VIEW, {
    onError: (error: ApolloError) => {
      logger('DeleteGridSavedView').error('Error deleting view -->', error.message);
    }
  });

  const removeGridSavedView = async (gridSavedViewId: string, queryVariables: QueryGridSavedViewsArgs): Promise<GridSavedViewMutationResponse> => {
    const response = await deleteGridSavedView({ 
      variables: { gridSavedViewId },
      refetchQueries: [
        {
          query: GET_GRID_SAVED_VIEWS,
          variables: queryVariables,
        }
      ],
    });
    
    const responseData = response.data?.deleteGridSavedView?.view;
    if (responseData) {
      logger('DeleteGridSavedView').info(`View deleted successfully`, response.data);
      return {
        responseData,
        responseMessage: `View removed successfully!`
      };
    } else {
      return {
        responseMessage: `Failed to remove log view!`,
      };
    }
  };

  return { 
    removeGridSavedView, 
    removeGridSavedViewLoading: loading, 
  };
};