import { RxCollection, RxJsonSchema, RxDocument } from "rxdb";
import { TblGridSavedViews } from '../../../generated/graphql';

export type TblGridSavedViewsDocType = TblGridSavedViews;

export type TblGridSavedViewsDocMethods = {};

export type TblGridSavedViewsDocument = RxDocument<
  TblGridSavedViewsDocType,
  TblGridSavedViewsDocMethods
>;

export type TblGridSavedViewsCollectionMethods = {};

export type TblGridSavedViewsCollection = RxCollection<
  TblGridSavedViewsDocType,
  TblGridSavedViewsDocMethods,
  TblGridSavedViewsCollectionMethods
>;

export const tblGridSavedViewsSchema: RxJsonSchema<TblGridSavedViewsDocType> = {
  title: 'TBL Grid Saved Views',
  description: 'GridSavedView',
  version: 0,
  keyCompression: false,
  type: 'object',
  properties: {
    autoId: {
      type: 'number'
    },
    PKey: {
      type: 'string',
      primary: true,
    },
    fldCrewID: {
      type: ['string', 'null'],
    },
    fldLayout: {
      type: ['string', 'null'],
    },
    fldModule: {
      type: ['string', 'null'],
    },
    fldViewName: {
      type: ['string', 'null'],
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    updatedAt: {
      type: 'string',
    },
    deletedBy: {
      type: ['string', 'null'],
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
  },
  indexes: ['PKey', 'updatedAt'],
  required: [],
  additionalProperties: false,
};

export const tblGridSavedViewsDocMethods: TblGridSavedViewsDocMethods = {};

export const tblGridSavedViewsCollectionMethods: TblGridSavedViewsCollectionMethods = {};
