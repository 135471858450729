import React, { FC, useState } from 'react';
import { Control, UseFormWatch } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import { Inventory } from 'src/generated/graphql';
import { Button, Snackbar, Alert, CircularProgress, Typography, Box } from '@mui/material';
import { MultipleLocationDocument } from 'src/rxdb/collections/MultipleLocations/schema';
import { getDatabase } from 'src/rxdb';
import InvStorageAddDialog from './components/InvStorageAddDialog';
import InvStorageCardsGrid from './components/InvStorageCardsGrid';
import WarningDialog from 'src/components/UI/WarningDialog';
import InvStorageGrid from './components/InvStorageGrid';
import InvStorageAddAmountDialog from './components/InvStorageAddAmountDialog';


interface Props {
    form: Inventory;
    initialValue: any;
    onChange: (field: string, value: any) => void;
    control: Control;
    watch: UseFormWatch<any>;
    getValues: any;
    onSave: (isUpdated: boolean) => void;
    isCreated? : boolean;
    selectedItemOrderID?: string;
    onSaveInv: (invItem: Inventory, isCreated: boolean) => void;
    disableEdit?: boolean;
    componentName?: string;
}

const InvStorage: FC<Props> = ({
    initialValue,
    control,
    watch,
    getValues,
    onSave,
    isCreated= false,
    selectedItemOrderID = "",
    onSaveInv,
    disableEdit=false,
    componentName
}) => {
    const [addStoragePopupVisible, setAddStoragePopupVisible] = useState<boolean>(false);
    const [initialValues, setInitialValues] = useState<Inventory>(initialValue.toJSON ? initialValue.toJSON() : initialValue);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedStorage, setSelectedStorage] = useState<any>(undefined);
    const [editStoragePopupVisible, setEditStoragePopupVisible] = useState<boolean>(false);
    const [addAmountStoragePopupVisible, setAddAmountStoragePopupVisible] = useState<boolean>(false);
    const [isCreatingProduct, setIsCreatingProduct] = useState(false);
    const [snackBar, setSnackbar] = useState({
        open: false,
        type: 'success',
        message: '',
    });

    const onSnackbarClose = () => {
        setSnackbar({
            open: false,
            message: '',
            type: 'success',
        });
    };

    const handleSelection = (item: any) => {
        setSelectedStorage(item);
        setEditStoragePopupVisible(true);
    };

    const hnadleReceiveItemSelection = (item: any) => {
        setSelectedStorage(item);
        setAddAmountStoragePopupVisible(true);
    }

    const handleWarningOk = async () =>{
        setIsCreatingProduct(false);
    }

    const handleWarningCancel = () =>{
        setIsCreatingProduct(false);
    }
    const handleAddClick = async () => {
        const db = await getDatabase();
        if(isCreated){
            setIsCreatingProduct(true);
        } else{
            const document: MultipleLocationDocument = await db.tblmultiplelocations.newDocument({
                fldLocHierarchy: null,
                SerialNum: null,
                Amount: null,
                fldMaxHldgLoc: null,
                UseBy: null,
                fldDefault: false,
                PKey: uuid(),
            });

            setSelectedStorage(document);
            setAddStoragePopupVisible(true);
        }
    };

    const handleStorageDialogeCancel = () => {
        setSelectedStorage(undefined);
        setAddStoragePopupVisible(false);
        setEditStoragePopupVisible(false);
        setAddAmountStoragePopupVisible(false);
    };

    const handleAddAmountStorageSave = () => {
        setLoading(true);
        setInitialValues(initialValue);
        setAddAmountStoragePopupVisible(false);
        setSnackbar({
            open: true,
            message: 'Storage has been Updated!',
            type: 'success',
        });
        setLoading(false);
        setSelectedStorage(undefined);
        onSave(true);
    };

    const handleEditStorageSave = () => {
        setLoading(true);
        setInitialValues(initialValue);
        setEditStoragePopupVisible(false);
        setSnackbar({
            open: true,
            message: 'Storage has been Updated!',
            type: 'success',
        });
        setLoading(false);
        setSelectedStorage(undefined);
        onSave(true);
    };

    const handleAddStorageSave = () => {
        setLoading(true);
        setInitialValues(initialValue);
        setAddStoragePopupVisible(false);
        setSnackbar({
            open: true,
            message: 'Storage has been created!',
            type: 'success',
        });
        setLoading(false);
        setSelectedStorage(undefined);
        onSave(true);
    };

    const handleDelete = async (item: MultipleLocationDocument) => {
        try {
            await item.remove();

            setSnackbar({
                open: true,
                message: 'Storage successfully removed',
                type: 'success',
            });
        } catch (e: any) {
            setSnackbar({
                open: true,
                message: e.message,
                type: 'error',
            });
        }
    };

    return (
        <>
            <div className="pt-5">
                <div className="flex justify-between mb-6">
                    <div className="flex justify-start">
                        {componentName === 'OrderItemAddDialog' &&
                            <Box>
                                <Typography fontSize={14} display="block" fontWeight="bold">
                                    {`Amount Ordered: ${initialValue.Amount}`}
                                </Typography>
                                <Typography fontSize={14} display="block" fontWeight="bold">
                                    {`Amount Received: ${initialValue.AmtReceived}`}
                                </Typography>
                            </Box>
                        }
                    </div>
                    <div className="flex justify-end">
                        <Button 
                            variant="contained" 
                            onClick={handleAddClick}
                            disabled={disableEdit}
                        >
                            Add
                        </Button>
                    </div>
                </div>
                <div>
                    {loading ? (
                        <div className="flex items-center justify-center w-full">
                            <CircularProgress />
                        </div>
                    ) : (
                        componentName === 'OrderItemAddDialog' ? (
                            <InvStorageGrid
                                initialValue={initialValue}
                                onDelete={handleDelete}
                                loadingGrid={loading}
                                onSelect={hnadleReceiveItemSelection}
                            />
                        ) : (
                            <InvStorageCardsGrid
                                initialValue={initialValue}
                                onDelete={handleDelete}
                                loadingGrid={loading}
                                onSelect={handleSelection}
                                disableEdit={disableEdit}
                            />
                        )
                    )}
                </div>
                {!!selectedStorage && (
                    <InvStorageAddDialog
                        visible={addStoragePopupVisible}
                        initialValue={initialValues}
                        storageInitialValue={selectedStorage}
                        onCancel={handleStorageDialogeCancel}
                        onSave={handleAddStorageSave}
                        title="Add"
                        selectedItemOrderID={selectedItemOrderID}
                    />
                )}
                {!!selectedStorage && (
                    <InvStorageAddDialog
                        visible={editStoragePopupVisible}
                        initialValue={initialValues}
                        storageInitialValue={selectedStorage}
                        onCancel={handleStorageDialogeCancel}
                        onSave={handleEditStorageSave}
                        title="Edit"
                        selectedItemOrderID={selectedItemOrderID}
                    />
                )}
                {!!selectedStorage && (
                    <InvStorageAddAmountDialog
                        visible={addAmountStoragePopupVisible}
                        initialValue={initialValues}
                        storageInitialValue={selectedStorage}
                        onCancel={handleStorageDialogeCancel}
                        onSave={handleAddAmountStorageSave}
                        title="Add Amount"
                        selectedItemOrderID={selectedItemOrderID}
                    />
                )}
            </div>
            <WarningDialog
                visible={isCreatingProduct}
                title="New Storage Creation Warning"
                content="If you want to add storage location, then Save inventory first"
                okText='Create Inventory'
                color='warning'
                disabled={true}
                cancelText='Close'
                onOk={handleWarningOk}
                onCancel={handleWarningCancel}
            />

            <Snackbar
                open={snackBar.open}
                autoHideDuration={2000}
                onClose={onSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
                    {snackBar.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default InvStorage;
