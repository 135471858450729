import { RxCollection, RxJsonSchema, RxDocument } from 'rxdb';
import { TblDl4CellRefs } from 'src/generated/graphql';

export type TblDl4CellRefsDocType = TblDl4CellRefs;

export type TblDl4CellRefsDocMethods = {};

export type TblDl4CellRefsDocument = RxDocument<
  TblDl4CellRefsDocType,
  TblDl4CellRefsDocMethods
>;

export type TblDl4CellRefsCollectionMethods = {};

export type TblDl4CellRefsCollection = RxCollection<
  TblDl4CellRefsDocType,
  TblDl4CellRefsDocMethods,
  TblDl4CellRefsCollectionMethods
>;

export const tblDL4CellRefsSchema: RxJsonSchema<TblDl4CellRefsDocType> = {
  title: 'TblDl4CellRefs ',
  description: 'TblDl4CellRefs',
  version: 2,
  keyCompression: false,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    PKey: {
      type: 'string',
      primary: true,
    },
    EqKey: {
      type: ['string', 'null'],
    },
    fldCell: {
      type: ['string', 'null'],
    },
    fldDataType: {
      type: ['string', 'null'],
    },
    fldTag: {
      type: ['string', 'null'],
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
    updatedAt: {
      type: 'string',
    },

  },
  indexes: [
    'PKey', 'updatedAt',
  ],
  additionalProperties: false,
};

export const tblDl4CellRefsDocMethods: TblDl4CellRefsDocMethods = {};

export const tblDl4CellRefsCollectionMethods: TblDl4CellRefsCollectionMethods = {};
