// This group of imports should be in every data detail component
import '../../../App.css';
import '../../../theme/styles.css';
import '../styles.css';
import { v4 as uuid } from 'uuid';
import React, { FC, useEffect, useRef, useState } from 'react';
import { isNil, size, last, omit, isString, isArray, isEmpty } from 'lodash';
import moment from 'moment';
import { filterNonNullStrings, handleCharLimitWarning, toJSON } from 'src/utils';

// Third party components go here
// check here for @mui/icon-materials values https://mui.com/components/material-icons/
import { DeleteTwoTone } from '@mui/icons-material';
import { Icon, Portal, Alert, IconButton, Snackbar } from '@mui/material';

import { useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import CloseIcon from '@mui/icons-material/Close';
import { Subscription } from 'rxjs';
import { OrdersDocument } from 'src/rxdb/collections/Orders/schema';
import { RxDocument } from 'rxdb';
import {
  EquipmentDocMethods,
  EquipmentDocType,
} from 'src/pages/EquipmentPage/rxdb';
import { InvBarCodesDocument } from 'src/rxdb/collections/InvBarCodes/schema';
import Tabs from '../../UI/Tabs';
import Input from '../../UI/Forms/Input';
import StickyAppBar from '../../UI/StickyAppBar';
import WarningDialog from 'src/components/UI/WarningDialog';
import { useAuth } from 'src/contexts/auth';
import { useAppState } from 'src/contexts/app-state';

// images
import IconBarCode from '../../../assets/icon-bc-primary.svg';
import IconAddBarCode from '../../../assets/icon-bc-add.svg';
import IconRemoveBarCode from '../../../assets/icon-bc-remove.svg';
import IconPrintBarCode from '../../../assets/icon-bc-print.svg';

// Reusable module components go here
import { getDatabase } from '../../../rxdb';
import CommentsOld from '../../../modules/Comments/indexOld';
import AttachmentTabOld from '../../../modules/Attachments/indexOld';
import { CHAR_LIMIT, FileExtensionType } from '../../../consts';

// All module specific components go here
import { Orders } from '../../../generated/graphql';
import OrdersSummaryForm from './component/OrdersSummaryForm';
import { validateForm } from './utils';
import { TblDdListDefaultsDocument } from 'src/api/queries/tblDDListDefaults/rxdb';
import RecordEditWarningCard from 'src/components/UI/RecordEditWarningCard';
import { normalizeDateTime, normalizeDateFormValue } from 'src/helpers';
import { InjectedDrawerProps } from 'src/components/PageDrawer';
import PrintButtonOld from 'src/components/UI/PrintButton/indexOld';
import PurchaseReport from 'src/components/UI/PDF/reports/PurchasingReportsPDF/defaultPO';
import { TblShipsParticularsDocument } from 'src/api/queries/tblShipsParticulars/rxdb';
import CompaniesDropdownOld from 'src/components/Dropdowns/CompaniesDropdown/indexOld';

interface Props extends Partial<InjectedDrawerProps> {
  initialValue: OrdersDocument;
  onCancel: () => void;
  onSave: (orderItem: Orders, isCreated: boolean) => void;
  onDelete: (orderItem: OrdersDocument) => void;
  onUndo?: () => void;
  editFlag: boolean;
  type?: string;
  isCreated: boolean;
  orderItemsUpdate: () => void;
}

const PurchaseDetailForm: FC<Props> = ({
  initialValue,
  onCancel,
  onSave,
  onDelete,
  onUndo,
  editFlag = false,
  isCreated,
  type,
  orderItemsUpdate,
  setFormIsDirty,
}) => {
  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState,
  } = useForm<any>({
    // For uncontrolled components keep empty string or undefined. Null wouldn't work.
    defaultValues: {
      PurchaseOrderNumber: initialValue.PurchaseOrderNumber || '',
      OrderNumber: initialValue.PurchaseOrderNumber || '',
      OrderName: initialValue.OrderName,
      fldStatus: initialValue.fldStatus || null,
      fldType: initialValue.fldType,
      Supplier: initialValue.Supplier,
      SupplierID: initialValue.SupplierID || null,
      Department: initialValue?.Department || null,
      OrderedBy: initialValue?.OrderedBy || null,
      fldHTML: initialValue.fldHTML || '',
      // MyDesc: initialValue.MyDesc || '',
      NoView: initialValue.NoView || false,
      OrderSent: initialValue.OrderSent || false,
      PromisedByDate: normalizeDateFormValue(initialValue.PromisedByDate),
      OrderDate: normalizeDateFormValue(initialValue.OrderDate),
    },
  });

  // const [statuses, setStatuses] = useState<TblDdListDefaultsDocument[]>([]);
  const oldOrdersState = useRef<Orders>(initialValue.toJSON());

  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });

  const { settingsPersonal } = useAppState();
  const { user } = useAuth();
  const [oldOrdersUndo, setoldOrdersUndo] = useState<Orders[]>([]);
  const [orderItem, setOrdersItem] = useState<Orders>(initialValue.toJSON());
  const [attachmentCount, setAttachmentCount] = useState<number>(0);
  const [photoCount, setPhotoCount] = useState<number>(0);
  const [spareCount, setSpareCount] = useState<number>(0);
  const [commentsCount, setCommentsCount] = useState<number>(0);
  const [orderHistoryCount, setOrderHistoryCount] = useState<number>(0);
  const activeSubscriptions = useRef<Subscription[]>([]);
  const formInitialValues = useRef<any>({});
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [addPopupVisible, setAddPopupVisible] = useState<boolean>(false);
  const [removePopupVisible, setRemovePopupVisible] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [disableEditDepartment, setDisableEditDepartment] = useState(false);
  const [disableEdit, setDisableEdit] = useState(false);
  const [POItems, setPOItems] = useState<any[]>([]);
  const [isPOItemsReady, setIsPOItemsReady] = useState(false);
  const [vesselSpecs, setVesselSpecs] = useState<TblShipsParticularsDocument>();
  const { ORDERS } = CHAR_LIMIT;

  const handlePOItems = (data: any) => {
    setPOItems(data);
    setIsPOItemsReady(true)
  }

  const setVesselData = async () => {
    const db = await getDatabase();
    const result = await db.tblshipsparticulars.find().exec();
    setVesselSpecs(result[0]);
  };

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const getDocumentCount = async () => {
    // Count Attachments / Photos / Tasks / Schedules / Spares
    const db = await getDatabase();

    // Find and count attachments
    activeSubscriptions.current = [
      db.tbldocumentcrossreference
        .find({
          selector: {
            fldRecordKey: isNil(initialValue.OrderID)
              ? ''
              : initialValue.OrderID,
          },
        })
        .$.subscribe(async (attachments) => {
          const results = await Promise.all<string | undefined>(
            attachments.map(async (attachment) => {
              const revision = await db.documentrevision
                .findOne({
                  selector: {
                    fldFKey: attachment.fldFKey,
                  },
                })
                .exec();

              if (revision) {
                return revision.fldFileName
                  ? last(revision.fldFileName.split('.'))
                  : undefined;
              }

              return undefined;
            })
          );

          setPhotoCount(
            size(
              results
                .filter((e) => e)
                .filter((extension) =>
                  [
                    FileExtensionType.JPEG,
                    FileExtensionType.JPG,
                    FileExtensionType.PNG,
                    FileExtensionType.TIF,
                    FileExtensionType.BMP,
                  ].includes(extension!.toUpperCase() as any)
                )
            )
          );

          setAttachmentCount(
            size(
              results
                .filter((e) => e)
                .filter((extension) =>
                  [
                    FileExtensionType.PDF,
                    FileExtensionType.XLS,
                    FileExtensionType.XLSX,
                    FileExtensionType.DOC,
                    FileExtensionType.DOCX,
                    FileExtensionType.RTF,
                  ].includes(extension!.toUpperCase() as any)
                )
            )
          );
        }),

      db.comments
        .find({
          selector: {
            $and: [
              {referenceIssueId: isNil(initialValue.OrderID) ? '' : initialValue.OrderID},
              {deletedAt: {$eq: null}},
            ],
          },
        })
        .$.subscribe((c) => {
          setCommentsCount(size(c));
        }),
    ];
  };
  const setInitialValues = async () => {
    const defaultValues = {
      ...getValues(),
    };

    formInitialValues.current = defaultValues;
    reset(defaultValues);
  };

  const setDeptEditPermission = async ()=>{
    if (settingsPersonal.fldAllDepts != 2) {
      setDisableEditDepartment(true);
    }
    if(settingsPersonal.fldAllDepts === 1 && user?.Department != initialValue.Department){
      setDisableEdit(true)
    }
  }

  useEffect(()=>{
    if(settingsPersonal){
      setDeptEditPermission()
    }
  },[settingsPersonal])

  useEffect(() => {
    getDocumentCount();
    setInitialValues();
    setVesselData();
    return () => {
      activeSubscriptions.current?.map((sub) => sub.unsubscribe());
      activeSubscriptions.current = [];
      formInitialValues.current = {};
    };
  }, []);

  const onChange = async (name: string, value: any) => {
    let shouldDirty = true;
    if (name === 'Supplier') {
      setValue(
        name,
        { DisplayMember: value.DisplayMember },
        { shouldDirty: shouldDirty }
      );
      return;
    }

    setValue(name, value, { shouldDirty: shouldDirty });
  };

  const handleCancel = () => {
    setoldOrdersUndo([]);
    onCancel();
  };

  const extractContent = (s: string) => {
    const span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };

  const isCreation = isCreated;

  const handleSave = async (data: any) => {
    if (!validateForm(data, setSnackbar)) return;
    const db = await getDatabase();

    // Create items before creating Item.
    const getOrCreate = async (value: any, keyExpr: string) => {
      if (isNil(value)) return null;

      if (value.isCreate) {
        // Create item first and then proceed
        const collection = (db as any)[value.collection];

        // TODO: Hook up tblDefaults
        const result = await collection?.upsert(
          omit(value, ['inputValue', 'isCreate', 'collection'])
        );

        return result[keyExpr];
        // create value collection
      }

      return value[keyExpr];
    };

    const {
      OrderDate,
      OrderName,
      PurchaseOrderNumber,
      Supplier,
      SupplierID,
      Department,
      OrderedBy,
      Contact,
      NoView,
      MyDesc,
      fldHTML,
      OrderSent,
      fldType,
      fldStatus,
      PromisedByDate,
    } = data;
    
    const document = {
      ...orderItem,
      OrderName,
      OrderDate : moment(OrderDate).isValid() ? normalizeDateTime(OrderDate) : new Date().toISOString(),
      Supplier: typeof Supplier === 'object' ? Supplier?.DisplayMember : Supplier || null,
      Contact,
      fldHTML,
      PurchaseOrderNumber,
      OrderedBy: typeof OrderedBy === 'object' ? OrderedBy?.name : OrderedBy || null,
      Department: isString(Department)
        ? Department
        : Department?.fldMember || null,
      fldType,
      fldStatus: isString(fldStatus)
      ? fldStatus
      : fldStatus?.fldMember || null,
      PromisedByDate,
      MyDesc: extractContent(fldHTML),
      NoView,
      OrderSent,
      // Since we are passing empty object from parent we should distinguish create/update actions.
      // In case of Update we have to pass primary key (ProductID)
      OrderID: initialValue.primary || uuid(), // Set primary key, so we will be able to upsert.
      updatedAt: new Date().toISOString(),
    } as any;

    try {
      setoldOrdersUndo([]);
      const res = await db.collections.orders.upsert(document);
      onSave(res, isCreated);
      const dataValue = getValues();
      dataValue.Supplier =
        typeof Supplier === 'object' ? Supplier?.DisplayMember : null;
      reset(getValues());
    } catch (e: any) {
      setSnackbar({
        open: true,
        type: 'error',
        message: e.message,
      });
    }
  };
  const handleDelete = () => {
    setIsDeleting(true);
  };

  const handleDeleteOk = () => {
    onDelete(initialValue);
    setIsDeleting(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleting(false);
  };

  // Is this the correct way to handle calling modals from the form?
  const handleAddTask = () => [];

  const handleAddLog = () => [];

  const handleUndo = () => {
    const item = last(oldOrdersUndo);
    setOrdersItem(item as Orders);
    // Remove last step from our store
    setoldOrdersUndo(oldOrdersUndo.splice(-1));

    onUndo && onUndo();
  };

  const handleOrderItemsUpdate = (isUpdated: boolean) => {
    if (isUpdated) {
      orderItemsUpdate();
    }
  };

  const handleCancelUndo = () => {
    if (isCreation) {
      return onCancel();
    }

    reset(formInitialValues.current);
  };

  const handleOk = (isEditing: boolean) => {
    if (isEditing && !validateForm(getValues(), setSnackbar)) return;
    if (isEditing) return; // We will send submit action that will be handled in HandleSave.

    handleCancel();
  };

  const handleAddBarCodePress = () => {
    setAddPopupVisible(true);
  };

  const handleDeleteBarCodePress = () => {
    setRemovePopupVisible(true);
  };

  const handlePrintPress = () => {
    alert('Print Bar Code Coming Soon!!');
  };

  const handlePopupCancel = () => {
    setAddPopupVisible(false);
    setRemovePopupVisible(false);
    handleClose();
  };

  if (isNil(orderItem)) return null;

  const hasValuesBeenChanged =
    formState.isDirty &&
    (size(formState.dirtyFields) > 0 || size(formState.touchedFields) > 0);

  const isEditing = hasValuesBeenChanged || isCreation;

  useEffect(() => {
    setFormIsDirty && setFormIsDirty(hasValuesBeenChanged);
  }, [hasValuesBeenChanged]);
  
  const relatedKeys = filterNonNullStrings([initialValue.OrderID]);

  const formClass =
    type === 'Dialog'
      ? 'relative bg-white flex-grow'
      : 'relative bg-white pt-14 md:pt-19 flex-grow';

  const handleGeneratePDF = () => {
    const data = {
      PO: initialValue,
      POItems: POItems,
      VesselSpecs: vesselSpecs,
    }

    return PurchaseReport(data);
  };

  return (
    <form
      id="Inventory-Edit-form"
      className={`${formClass}`}
      onSubmit={handleSubmit(handleSave)}
    >
      <div className="bg-white h-full flex-grow pt-6">
        <div className="px-6 h-full">
          <div className="mb-6">
            {(disableEdit || editFlag) && <RecordEditWarningCard />}
            <div className="mui-textfield-header mb-2">
              <Input
                inputProps={{
                  size: 'medium',
                  label: 'Order Name',
                  variant: 'standard',
                }}
                rules={{ required: true, maxLength: ORDERS.OrderName }}
                warning={(value) => handleCharLimitWarning(value, ORDERS.OrderName)}
                control={control}
                name="OrderName"
              />
            </div>

            <div className="mt-3">
              <Input
                inputProps={{
                  size: 'small',
                  label: 'Order Number',
                  variant: 'standard',
                }}
                rules={{ maxLength: ORDERS.PurchaseOrderNumber }}
                warning={(value) => handleCharLimitWarning(value, ORDERS.PurchaseOrderNumber)}
                defaultValue={initialValue.PurchaseOrderNumber}
                control={control}
                name="PurchaseOrderNumber"
              />
            </div>

            <div className="mt-3">
              <CompaniesDropdownOld
                control={control}
                label="Supplier"
                name="Supplier"
                onChange={onChange}
                variant="standard"
                size="small"
              />
            </div>

            <div className="mt-3">
              <Input
                inputProps={{
                  size: 'small',
                  label: 'Contact Person',
                  variant: 'standard',
                }}
                defaultValue={initialValue.Contact}
                control={control}
                name="Contact"
              />
            </div>
          </div>

          <div className="mt-3 mb-20">
            <Tabs
              tabs={[
                {
                  label: 'Summary',
                  component: (
                    <OrdersSummaryForm
                      watch={watch}
                      control={control}
                      initialValue={initialValue}
                      setPOItems={handlePOItems}
                      form={orderItem}
                      onChange={onChange}
                      getValues={getValues}
                      onOrderItemUpdate={handleOrderItemsUpdate}
                      disableEdit = {disableEdit}
                      editFlag={editFlag}
                    />
                  ),
                },
                {
                  label: `Attachments (${attachmentCount})`,
                  component: (
                    <AttachmentTabOld
                      type="attachments"
                      primaryKey={initialValue.OrderID}
                      relatedKeys={relatedKeys}
                      // SRHKey={initialValue.fldSRHKey}
                      allowedFileExtensions={['.pdf']}
                      tableName="Orders"
                      disabled={editFlag}
                      disableEdit={disableEdit}
                    />
                  ),
                },

                {
                  label: `Photos (${photoCount})`,
                  component: (
                    <AttachmentTabOld
                      type="photo"
                      primaryKey={initialValue.OrderID}
                      relatedKeys={relatedKeys}
                      // SRHKey={initialValue.fldSRHKey}
                      allowedFileExtensions={['.jpg', '.jpeg', '.gif', '.png']}
                      tableName="Orders"
                      disabled={editFlag}
                      disableEdit={disableEdit}
                    />
                  ),
                },
                {
                  label: `Comments (${commentsCount})`,
                  component: (
                    <CommentsOld
                      selectorType="Orders"
                      selectorKeyValue={initialValue.OrderID}
                      disableEdit={disableEdit}
                    />
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
      {type !== 'Dialog' && (
        <StickyAppBar
          cancelText="Cancel"
          okType={isEditing ? 'submit' : 'button'}
          okText={isEditing ? 'Save' : 'Close'}
          onOk={() => handleOk(isEditing)}
          onCancel={isEditing ? () => handleCancelUndo() : undefined}
          disabled={(editFlag || disableEdit)  && isEditing}
        >
          <PrintButtonOld
            generatePDF={handleGeneratePDF}
            enable={isPOItemsReady}
            title='Print PO'
          />

          {!editFlag && !isNil(orderItem.OrderID) && !disableEdit && (
            <IconButton
              onClick={handleDelete}
              color="error"
              aria-label="Delete item"
            >
              <DeleteTwoTone />
            </IconButton>
          )}
        </StickyAppBar>
      )}

      <WarningDialog
        visible={isDeleting}
        title="Delete Warning"
        content="Are you sure you wish to delete record?"
        okText="Yes"
        color="error"
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />
      
      <Portal>
        <Snackbar
          open={snackBar.open}
          autoHideDuration={2000}
          onClose={onSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Portal>
    </form>
  );
};

export default PurchaseDetailForm;
