import React, { FC, ReactNode } from 'react';
import { Control, RegisterOptions, useController } from 'react-hook-form';
import { OverridableStringUnion } from '@mui/types';
import { isString, isNil } from 'lodash';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MaterialSelect,
  TextFieldPropsSizeOverrides,
} from '@mui/material';
import { SelectInputProps } from '@mui/material/Select/SelectInput';

interface InjectedProps {
  control: Control;
  name: string;
  defaultValue?: any;
  rules?: RegisterOptions;
  label?: string;
  size?: OverridableStringUnion<
    'small' | 'medium',
    TextFieldPropsSizeOverrides
  >;
  options: any[];
  valueExpr?: string;
  keyExpr?: string;
  fullWidth?: boolean;
  displayExpr?: string;
  selectProps?: Partial<SelectInputProps> & {
    renderItem?: (option: any) => ReactNode;
  };
}

const Select: FC<InjectedProps> = ({
  control,
  name,
  defaultValue,
  rules,
  label,
  keyExpr,
  displayExpr,
  valueExpr,
  options,
  selectProps,
  size = "small",
  fullWidth = true,
}) => {
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
    rules,
  });

  const renderItem = (option: any) => {
    if (selectProps?.renderItem) return selectProps.renderItem(option);

    if (isString(option)) {
      return (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      );
    }

    if (isNil(keyExpr) || isNil(valueExpr)) return null;

    return (
      <MenuItem key={option[keyExpr || valueExpr]} value={option[valueExpr]}>
        {displayExpr ? option[displayExpr] : null}
      </MenuItem>
    );
  };

  return (
    <FormControl
      size={size}
      required={!!rules?.required}
      fullWidth={fullWidth}
      error={!!error}
    >
      <InputLabel id={name}>{label}</InputLabel>
      <MaterialSelect
        {...selectProps}
        {...inputProps}
        label={label}
        labelId={name}
        inputProps={{
          ref,
        }}
      >
        {options.map(renderItem)}
      </MaterialSelect>
      {error && (
        <FormHelperText error={!!error}>{error?.message}</FormHelperText>
      )}
    </FormControl>
  );
};

export default Select;
