import { RxCollection, RxJsonSchema, RxDocument } from 'rxdb';
import { TblDocumentation } from '../../../generated/graphql';

export type TblDocumentationDocType = TblDocumentation;

export type TblDocumentationDocMethods = {};

export type TblDocumentationDocument = RxDocument<
  TblDocumentationDocType,
  TblDocumentationDocMethods
>;

export type TblDocumentationCollectionMethods = {};

export type TblDocumentationCollection = RxCollection<
  TblDocumentationDocType,
  TblDocumentationDocMethods,
  TblDocumentationCollectionMethods
>;

export const tblDocumentationSchema: RxJsonSchema<TblDocumentationDocType> = {
  title: 'TblDocumentation ',
  description: 'describes a document item',
  version: 1,
  keyCompression: false,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    PKey: {
      type: 'string',
      primary: true,
    },
    DateEntered: {
      type: ['string', 'null'],
    },
    DocTitle: {
      type: ['string', 'null'],
    },
    Received: {
      type: ['boolean', 'null'],
    },
    fldAddedBy: {
      type: ['string', 'null'],
    },
    fldCompleteReqd: {
      type: ['boolean', 'null'],
    },
    fldDescription: {
      type: ['string', 'null'],
    },
    fldDocFigure: {
      type: ['string', 'null'],
    },
    fldDocPage: {
      type: ['string', 'null'],
    },
    fldFileType: {
      type: ['string', 'null'],
    },
    fldLibtype: {
      type: ['string', 'null'],
    },
    fldNumber: {
      type: ['string', 'null'],
    },
    fldPassword: {
      type: ['string', 'null'],
    },
    fldSMSReport: {
      type: ['boolean', 'null'],
    },
    fldSRHKey: {
      description: 'Categories foreign key. TblSystemHierarchy',
      type: ['string', 'null'],
      ref: 'tblsystemshierarchy',
    },
    fldShowInCentral: {
      type: ['boolean', 'null'],
    },
    fldSpecRef: {
      type: ['string', 'null'],
    },
    fldStatus: {
      type: ['string', 'null'],
    },
    fldStatusInternal: {
      type: ['string', 'null'],
    },
    fldTOC: {
      type: ['string', 'null'],
    },
    fldVerifiedReq: {
      type: ['boolean', 'null'],
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    updatedAt: {
      type: 'string',
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
  },
  indexes: ['updatedAt'],
  required: [],
  additionalProperties: false,
};

export const tblDocumentationDocMethods: TblDocumentationDocMethods = {};

export const tblDocumentationCollectionMethods: TblDocumentationCollectionMethods = {};
