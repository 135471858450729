import { RxCollection, RxJsonSchema, RxDocument } from 'rxdb';
import { TblSchedMaintEq } from '../../../generated/graphql';

export type SchedMaintEQType = TblSchedMaintEq;

export type SchedMaintEQDocMethods = {};

export type SchedMaintEQDocument = RxDocument<
  SchedMaintEQType,
  SchedMaintEQDocMethods
  >;

export type SchedMaintEQCollectionMethods = {};

export type SchedMaintEQCollection = RxCollection<
  SchedMaintEQType,
  SchedMaintEQDocMethods,
  SchedMaintEQCollectionMethods
  >;

export const schema: RxJsonSchema<SchedMaintEQType> = {
  title: 'Sched Maint EQ Collection',
  description: 'Sched Maint EQ',
  version: 5,
  keyCompression: false,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    DateEntered: {
      type: ['string', 'null'],
    },
    EqKey: {
      type: ['string', 'null'],
      ref: 'equipment',
    },
    SchedKey: {
      type: 'string',
      ref: 'tblschedmaint',
    },
    fldDateTrigger: {
      type: ['string', 'null'],
    },
    fldDeferred: {
      type: ['boolean', 'null'],
    },
    fldDeferredDate: {
      type: ['string', 'null'],
    },
    fldHoursCompleted: {
      type: ['number', 'null'],
    },
    fldHoursTrigger: {
      type: ['number', 'null'],
    },
    fldIndex: {
      type: ['number', 'null'],
    },
    fldIterations: {
      type: ['number', 'null'],
    },
    fldRunOnce: {
      type: ['boolean', 'null'],
    },
    PKey: {
      type: 'string',
      primary: true,
    },
    fldSRHKey: {
      description: 'Categories foreign key. TblSystemHierarchy',
      type: ['string', 'null'],
      ref: 'tblsystemshierarchy',
    },
    fldLocHierarchy: {
      type: ['string', 'null'],
      description: 'Locations foreign key',
      ref: 'tbllocationshierarchy',
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
    updatedAt: {
      type: 'string',
    },
  },
  required: [],
  additionalProperties: false,
};

export default schema;
