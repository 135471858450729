import { omit } from 'lodash';
import { OrderItems } from '../../../generated/graphql';
import {logger} from "../../../helpers/logger";

const fields = `
PKey
ProductID
OrderID
ProductName
ProductDescription
Amount
UnitPrice
ModelNumber
Department
Manufacturer
Qty
Book
Page
Figure
ToInventory
SearchMod
SearchMaker
SearchPart
PartNumber
AmtReceived
EqDesc
QCurrency
QCurrencyAmt
QtyRecd
OrderComplete
fldDataType
fldSize
fldColor
DateEntered
deletedAt
updatedAt
deletedBy
isRecoverable
autoId
`;

export const orderItemsPullQuery = (doc: OrderItems) => {
  if (!doc) {
    doc = {
      autoId: 0,
      PKey: '',
      ProductID: '',
      OrderID: '',
      updatedAt: new Date(0).toISOString(),
    };
  }
  logger('OrderItems').info(
    `pull query triggered - OrderItems: { ${new Date().toISOString()} -  ID: ${doc.autoId} - updatedAt: ${doc.updatedAt} }`,
  );
  return {
    query: `
        query getOrderItems($autoId: Int, $filterDate: Date) {
          OrderItems(
            where:{
              _and: [
                {
                  _or: [
                    {
                      isRecoverable: {
                        _is_null: true
                      }
                    },
                    {
                      isRecoverable: {
                        _eq: true
                      }
                    }
                  ]
                },
                {
                  _or: [
                    {
                      updatedAt: {_gt: $filterDate},                
                    },
                    {
                      autoId: {_gt: $autoId}
                      updatedAt: {_gte: $filterDate},
                    }
                  ]
                }
              ]                                    
            },
            order_by: [
              {updatedAt: asc},
              {autoId: asc}
            ],            
            limit: 1000
          ) {
            ${fields}
          }
        }
      `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
    },
  };
};

export const orderItemsMutation = (doc: any) => {
  const query = `
    mutation InsertOrderItems($input: OrderItemsUpsertInput!) {
      upsertOrderItems(input: $input) {
        ${fields}
      }
    }
  `;

  const variables = {
    input: omit(doc, ['updatedAt', 'deletedAt']),
  };

  return {
    query,
    variables,
  };
};

export const orderItemsSubscription = `
  subscription onOrderItemsChanged {
    OrderItems {
      ${fields}
    }
  }
`;
