// @ts-nocheck
import '../ReportsPrintButton/styles.css';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import { IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Alert, Portal, Snackbar } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import { getDatabase } from 'src/rxdb';
import { pick } from 'lodash';
import ForwardedPSPDFKitViewer from '../Util/PSPDFKitViewer';
import BarcodeLabel from '../BarcodePDF/jsBarcodeLabel';
import { InventoryDocument } from 'src/pages/InventoryPage/rxdb';
import { getFullPath } from 'src/pages/WorkIssuesPage/utils';
import BarcodeForm from './BarcodeForm';
import { TblSettingsApplication } from 'src/generated/graphql';

interface InjectedProps {
  initialValue: InventoryDocument;
  visible: boolean;
  onCancel?: () => void;
  saveLoading: boolean;
  componentName?: string;
}

const transformData = async (item: any) => ({
    ...pick(item, ['PKey', 'ProductID', 'Amount', 'fldLocHierarchy', 'UseBy', 'fldDefault'])
});

const BarcodePopup: FC<InjectedProps> = ({
  initialValue,
  visible,
  onCancel,
  saveLoading,
  componentName
}) => {

  const { control, handleSubmit, setValue } = useForm<any>({
    mode: 'onBlur',
    shouldFocusError: true,
  });
  const [storageLocations, setStorageLocations] = useState<any[]>();
  const [loading, setLoading] = useState(false);
  const childRef = useRef<{ handlePSPDFKitOpen: () => void } | null>(null);
  const [payload, setPayload] = useState<any>()
  
  const handlePDFOpen = () => {
    childRef.current?.handlePSPDFKitOpen()
  }

  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  useEffect(() => {
    loadStorageLocationsData();
    loadBarcodeLabelSize();
  }, [initialValue, onCancel]);

  const loadBarcodeLabelSize = async () => {
    try {
      const db = await getDatabase();
      const labels = await db.collections.tblsettingsapplication
        .find({
          selector: {
            fldPref: { $eq: 'YMSBCL' },
            deletedAt: { $eq: null },
          },
        })
        .exec();
      if (labels.length > 0) {
        const value = labels[0]?.fldSetting || '';
        setValue('labelID', parseInt(value));
      }
    } catch (error) {
      console.error('Error fetching label size:', error);
      setSnackbar({
        open: true,
        type: 'error',
        message: 'Label size fetching failed!',
      });
    }
  }

  const getInvBarCode = async (productID: string) => {
    const db = await getDatabase();
    const result = await db.invbarcodes
      .find({
        selector: {
          ProductID: { $eq: productID },
          deletedBy: { $eq: null },
          fldDefault: { $eq: true }
        },
      }).exec();

    return result;
  };

  const loadStorageLocationsData = useCallback(async () => {
      try {
          const db = await getDatabase();

          let items = await db.tblmultiplelocations
              .find({
                  selector: {
                      ProductID: initialValue?.ProductID,
                  },
              })
              .exec();
          items = items.filter((i: any) => !i.deletedAt);
          
          const transformedData = await Promise.all(items.map(transformData));

          for (const dataItem of transformedData) {
              dataItem.fldLocHierarchy = await getLocNodes(dataItem, db);
              if (dataItem.fldDefault) {
                setValue('PKey', dataItem.PKey);
              }
              if (transformedData.length === 1) {
                setValue('PKey', dataItem.PKey);
              }
          }
          setStorageLocations(transformedData);
      } catch (error) {
          console.error('Error loading data:', error);
      }
  }, [initialValue]);

  const getLocNodes = async (item: any, db: any) => {
      const locArrayData = [];
      let node: any = {
          PKey: '',
          fldLocation: '',
          ParentID: item.fldLocHierarchy,
      };
      while (node.ParentID) {
          node = await db.tbllocationshierarchy
              .findOne({
                  selector: {
                      PKey: node.ParentID,
                  },
              })
              .exec();
          locArrayData.push(node.fldLocation);
      }

      return locArrayData.reverse().join(' >> ');
  };

  const getLocationName = (selectedPKey: string) => {
    const selectedLocation = storageLocations?.find(location => location.PKey === selectedPKey);
    return selectedLocation ? selectedLocation.fldLocHierarchy : '';
  }

  const onOkClicked = async (data: any) => {
    try {
      if (!loading) {
        setLoading(true)
        // retrieve user input data
        const { PKey, labelID } = data
        const locationName = await getLocationName(PKey);
        const barcode = await getInvBarCode(initialValue.ProductID)
        const category = await getFullPath(initialValue)

        let labelSize = {};
        if(labelID === 1) labelSize = {width: 144, height: 288}
        if(labelID === 2) labelSize = {width: 144, height: 216}
        if(labelID === 3) labelSize = {width: 72, height: 216}
        if(labelID === 4) labelSize = {width: 216, height: 216}
        if(labelID === 5) labelSize = {width: 216, height: 288}
        if(labelID === 6) labelSize = {width: 90, height: 162}
        if(labelID === 7) labelSize = {width: 90, height: 252}

        const BCData = {
          // Inventory tbl
          productId: initialValue?.ProductID,
          labelStyle: '',
          ProductName: initialValue?.ProductName,
          Manufacturer: initialValue?.Manufacturer,
          ModelNumber: initialValue?.ModelNumber,
          fldPartNumber: initialValue?.fldPartNumber,
          category: category,
          fldLLPno: initialValue?.fldLLBC,
          fldTritonBC: barcode[0].fldTritonBC, // InvBarCodes tbl
          locationData: locationName, // MultipleLocations tbl
          llBCdata: [
            {
              building: '',
              unit: '',
              pos: '',
            },
          ],
          luerssenStyle: false,
          labelSize: labelSize
        };

        setPayload(BCData)
        setLoading(false)
      }
      handlePDFOpen();
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const handleGeneratePDF = () => {
    if (payload) return BarcodeLabel(payload);
  };

  const onCancelClick = () => {
    onCancel && onCancel();
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onOkClicked)}>
        <Dialog
          open={visible}
          onClose={onCancelClick}
          fullWidth
          PaperProps={{
            style: {
              maxWidth: '30%', // Adjust the width percentage as needed
            },
          }}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle sx={{ m: 0, p: 4 }} style={{ fontSize: '16px' }}>
            <span className="ml-2">
              {`${componentName} Barcode Print Utility`}
            </span>
            {onCancel ? (
              <IconButton
                aria-label="close"
                onClick={onCancelClick}
                sx={{
                  position: 'absolute',
                  right: 10,
                  top: 14,
                  color: (theme) => theme.palette.grey[400],
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>

          <DialogContent dividers>
            {!storageLocations ? (
              <span>Storage locations are loading...</span>
              ) : (
                <BarcodeForm options={storageLocations} control={control} />
              )}
          </DialogContent>

          <DialogActions sx={{ m: 0, p: 3, justifyContent: 'flex-end' }}>
          <Button onClick={onCancelClick} className="w-32">
              Cancel
            </Button>
            <LoadingButton
              onClick={handleSubmit(onOkClicked)}
              loading={loading}
              className="ml-4 mr-3 w-32"
              variant="contained"
            >
              <span>OK</span>
            </LoadingButton>
          </DialogActions>
        </Dialog>

        <ForwardedPSPDFKitViewer 
            generatePDF={handleGeneratePDF}
            onClose={onCancelClick}
            enable={!!payload}
            ref={childRef}
            reportType='Barcode'
        />

        <Portal>
          <Snackbar
            open={snackBar.open}
            autoHideDuration={2000}
            onClose={onSnackbarClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
              {snackBar.message}
            </Alert>
          </Snackbar>
        </Portal>
      </form>
    </div>
  );
};

export default BarcodePopup;

