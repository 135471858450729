/* eslint-disable @typescript-eslint/ban-types */
import { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';
import { TblRunningLog } from '../../../generated/graphql';

export type TblRunningLogDocType = TblRunningLog;

export type TblRunningLogDocMethods = {};

export type TblRunningLogDocument = RxDocument<
  TblRunningLogDocType,
  TblRunningLogDocMethods
>;

export type TblRunningLogCollectionMethods = {};

export type TblRunningLogCollection = RxCollection<
  TblRunningLogDocType,
  TblRunningLogDocMethods,
  TblRunningLogCollectionMethods
>;

export const schema: RxJsonSchema<TblRunningLogDocType> = {
  keyCompression: false,
  title: 'Running Logs',
  description: 'TblRunningLog',
  version: 4,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    PKey: {
      type: 'string',
      primary: true,
    },
    fldYMSLogDate: {
      type: ['number'],
    },
    fldLogTime: {
      type: ['string', 'null'],
    },
    fldTimeZone: {
      type: ['string', 'null'],
    },
    fldVesselSpeed: {
      type: ['string', 'null'],
    },
    fldApproxLocation: {
      type: ['string', 'null'],
    },
    fldComment: {
      type: ['string', 'null'],
    },
    updatedAt: {
      type: 'string',
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
  },
  indexes: ['PKey'],
  required: [],
  additionalProperties: false,
};

export default schema;
