import React, {
    useState,
    createContext,
    useContext,
    useEffect,
    FC,
} from 'react';
import { getDatabase, GraphQLReplicator } from '../rxdb';
import { useAuth } from './auth';

export type AppStateProps = {
    settingsPersonal: any;
    setSettingsPersonal: (data: any) => void;
    tableState: any;
    setTableState: (data: any) => void;
    hierarchyState: any;
    setHierarchyState: (data: any) => void;
    syncRxdb: () => Promise<void>;
};

export type AppStateProviderProps = {
    children?: JSX.Element;
};

const AppStateProvider: FC<AppStateProviderProps> = ({ children }) => {
    const [settingsPersonal, setSettingsPersonal] = useState<any>(null);
    const [tableState, setTableState] = useState<any>();
    const [hierarchyState, setHierarchyState] = useState<any>();
    const { token, user } = useAuth();

    useEffect(() => {
        handleLoadSettingsPersonal();
        handleLoadTableState();
    }, []);

    useEffect(() => {
        handleSaveTableState()
    }, [tableState])

    const handleLoadSettingsPersonal = async () => {
        const db = await getDatabase();

        db.tblsettingspersonal.find().$.subscribe((settingsPersonalUpdate) => {
            if (settingsPersonalUpdate && settingsPersonalUpdate[0]) {
                setSettingsPersonal(settingsPersonalUpdate[0]);
            }
        });
    }

    const handleLoadTableState = async () => {
        try {
            const recordData = localStorage.getItem('tableStateRecord');
            if (recordData) {
                const gridState = JSON.parse(recordData);
                setTableState(gridState)
            }
        } catch (e: any) {
            console.log("Error:", e.message)
        }
    }

    const handleSaveTableState = () => {
        localStorage.setItem('tableStateRecord', JSON.stringify(tableState));
    }

    const syncRxdb = async () => {
        const db = await getDatabase();
        const replicator = new GraphQLReplicator(db);

        if (token) {
            await replicator.start(token, user, (response: any) => {
                console.log('Replication progress:', response);
            });
        }

        await replicator.manualSync();
    };

    return (
        <AppStateContext.Provider
            value={{
                settingsPersonal,
                setSettingsPersonal,
                tableState,
                setTableState,
                hierarchyState,
                setHierarchyState,
                syncRxdb
            }}
        >
            {children}
        </AppStateContext.Provider>
    );
};

const AppStateContext = createContext<AppStateProps>({
    setSettingsPersonal: (data: any) => { },
    settingsPersonal: null,
    tableState: {},
    setTableState: (data: any) => { },
    hierarchyState: {},
    setHierarchyState: (data: any) => { },
    syncRxdb: async  () => { },
});
const useAppState = () => useContext(AppStateContext);

export { AppStateProvider, useAppState };
