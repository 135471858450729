// @ts-nocheck

// React components
import { useCallback, useState, MutableRefObject, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { pick, get, size, isEmpty, isNil } from 'lodash';
import moment from 'moment';

// Styles
import '../styles.css';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { BarChart, BackupTable } from '@mui/icons-material';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import InfoIcon from '@mui/icons-material/Info';

// Inovua DataGrid components
import {TypeComputedProps, TypeSortInfo} from '@inovua/reactdatagrid-enterprise/types';
import SelectFilter from '@inovua/reactdatagrid-enterprise/SelectFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';

// TDI components
import LicensedReactDataGrid from 'src/components/UI/LicensedReactDataGrid';
import GridRowAttachmentIcon from 'src/components/UI/LicensedReactDataGrid/components/GridRowAttachmentIcon';

// Utils
import { exportCSV, exportExcel, getRegexByOperator } from 'src/utils';

// Data
import { getDatabase } from 'src/rxdb';
import { MangoQuerySortDirection } from 'rxdb/dist/types/types/rx-query';
import { TblDdListDefaultsDocument } from 'src/api/queries/tblDDListDefaults/rxdb';
import { OrdersDocument } from 'src/rxdb/collections/Orders/schema';

type PurchaseOrderGridProps = {
  darken: boolean;
  onSelect: (item: OrdersDocument) => void;
  sourceRoot?: boolean;
  searchValue?: string;
  noResultFound?: (noResult: boolean) => void;
};

const filter = [
  {
    name: 'fldStatus',
    operator: 'eq',
    type: 'select',
    value: '',
  },
  {
    name: 'PurchaseOrderNumber',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'OrderedBy',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'OrderName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'Supplier',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'Department',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'OrderDate',
    operator: 'inrange',
    type: 'date',
    value: '',
  },
];

const transformData = async (item: OrdersDocument) => ({
  ...pick(item, [
    'OrderID',
    'OrderDate',
    'PurchaseOrderNumber',
    'Supplier',
    'Department',
    'OrderedBy',
    'OrderName',
    'fldStatus',
  ]),
  original: item,
});

const getSelectorByOrderItemsFilter = async (
  name: string,
  value: string,
  operator: string,
) => {
  switch (name) {
    case 'ProductName':
      return {
        ProductName: { $regex: getRegexByOperator(operator, value) },
      };
    case 'Manufacturer':
      return {
        Manufacturer: { $regex: getRegexByOperator(operator, value) },
      };
    case 'PartNumber':
      return {
        PartNumber: { $regex: getRegexByOperator(operator, value) },
      };
    case 'ModelNumber':
      return {
        ModelNumber: { $regex: getRegexByOperator(operator, value) },
      };
    default:
      return {};
  }
};

const getSelectorByFilterName = async (
  name: string,
  value: string,
  operator: string,
) => {
  switch (name) {
    case 'fldStatus':
      return {
        fldStatus: { $regex: getRegexByOperator(operator, value) },
      };
    case 'PurchaseOrderNumber':
      return {
        PurchaseOrderNumber: { $regex: getRegexByOperator(operator, value) },
      };
    case 'OrderedBy':
      return {
        OrderedBy: { $regex: getRegexByOperator(operator, value) },
      };
    case 'OrderName':
      return {
        OrderName: { $regex: getRegexByOperator(operator, value) },
      };
    case 'Supplier':
      return {
        Supplier: { $regex: getRegexByOperator(operator, value) },
      };
    case 'Department': {
      return {
        Department: { $regex: getRegexByOperator(operator, value) },
      };
    }
    case 'OrderDate': {
      if (!isEmpty(value.start) && (isNil(value.end) || isEmpty(value.end))) {
        const dateformate = moment(value?.start)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        return {
          OrderDate: { $gte: dateformate },
        };
      }
      if (!isEmpty(value.end) && (isNil(value.start) || isEmpty(value.start))) {
        const dateformate = moment(value?.end)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        return {
          OrderDate: { $lte: dateformate },
        };
      }
      if (!isEmpty(value.start) && !isEmpty(value.end)) {
        const startdateformate = moment(value?.start)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        const enddateformate = moment(value?.end)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        return {
          OrderDate: { 
            $gte: startdateformate,
            $lte: enddateformate
          },
        };
      }
      return {};
    }

    default:
      return {};
  }
};

interface PurchaseOrderGridRef {
  handleExport: (type: string) => void;
}

const PurchaseOrderGrid = forwardRef<PurchaseOrderGridRef, PurchaseOrderGridProps>(({ 
  onSelect, 
  sourceRoot = false,
  searchValue,
  noResultFound
}: PurchaseOrderGridProps, ref )=> {
  const [loading, setLoading] = useState<boolean>(false);
  const highlightedRowId = useRef<string>('-1');
  const [gridRef, setGridRef] = useState<any>(null);
  const [allStatus, setAllStatus] = useState<TblDdListDefaultsDocument>([]);
  const [collapsedGroups, setCollapsedGroups] = useState({});
  const [groupCollapsedStatus, setGroupCollapsedStatus] = useState(false);
  const [count, setCount] = useState(0);
  const [exportDialog, setExportDialog] = useState(null);
  const [searchPopupVisible, setSearchPopupVisible] = useState<boolean>(false);
  let currentCount = 0;

  const handleGetCount = async () => {
    const db = await getDatabase();
    const length = size(await db.orders.find().exec());
    setCount(length);
  };

  const loadData = async ({
    skip,
    limit,
    filterValue,
    sortInfo,
  }: {
    sortInfo: TypeSortInfo;
    skip: number;
    limit: number;
    filterValue: any;
  }): Promise<{ data: any[]; count: number }> => {
    const db = await getDatabase();
  
    let preSort = {
      OrderDate: 'desc' as MangoQuerySortDirection,
    };
  
    if (!isNil(sortInfo)) {
      if (sortInfo.name === 'OrderDate') {
        preSort = {
          [sortInfo.name]:
            sortInfo.dir === 1
              ? ('asc' as MangoQuerySortDirection)
              : ('desc' as MangoQuerySortDirection),
        };
      }
    }
  
    const sort = [
      {
        ...preSort,
      },
    ];
  
    // TODO: Handle filtervalue.OPERATOR
  
    let selector = {
      deletedBy: {
        $eq: null,
      },
    };
  
    await Promise.all(
      filterValue.map(async (v) => {
        if (isEmpty(v.value)) return v;
  
        const s = await getSelectorByFilterName(v.name, v.value, v.operator);
  
        selector = {
          ...selector,
          ...s,
        };
        return v;
      })
    );

    let orderIDs: string[] = [];

    // Use getSelectorByOrderItemsFilter for searching in orderItems
    if (searchValue !== '' && searchValue !== undefined) {
      const productNameSelector = await getSelectorByOrderItemsFilter(
        'ProductName',
        searchValue,
        'contains',
      );
  
      const manufacturerSelector = await getSelectorByOrderItemsFilter(
        'Manufacturer',
        searchValue,
        'contains',
      );
  
      const partNumberSelector = await getSelectorByOrderItemsFilter(
        'PartNumber',
        searchValue,
        'contains',
      );
  
      const modelNumberSelector = await getSelectorByOrderItemsFilter(
        'ModelNumber',
        searchValue,
        'contains',
      );

      // Merge selectors obtained for each field
      const s = {
        $or: [
          productNameSelector,
          manufacturerSelector,
          partNumberSelector,
          modelNumberSelector,
        ],
      };

      const orderItems = await db.orderitems
        .find({
          selector: {
            ...s,
            deletedBy: { $eq: null },
          },
        })
        .exec();
      orderIDs = orderItems.map((item: any) => item.OrderID);

      if (orderIDs.length <= 0) {
        setSearchPopupVisible(true);
        noResultFound(true);
      }
    }

    // Include filtered OrderIDs in the selector
    if (orderIDs.length > 0) {
      selector = {
        ...selector,
        OrderID: { $in: orderIDs },
      };
    }

    const rawData = await db.orders.find({ selector }).exec();
    const length = size(rawData);
    currentCount = length;
  
    const items = await db.orders
      .find({
        selector,
        skip,
        limit,
        sort,
      })
      .exec();
  
    const data = await Promise.all(items.map(transformData));
    
    return { data, count: length };
  };

  useImperativeHandle(ref, () => ({
    handleExport: (type) => {
      handleDataExport(type);
    },
  }));

  useEffect(() => {
    handleGetCount();
  }, [loading]);

  // Find if any filters applied to grid
  const areFiltersActive = gridRef?.current.computedFilterValue.some(
    (f) => !isEmpty(f.value)
  );

  const currentFilters = get(gridRef, ['current', 'computedFilterValue'], []);

  const init = async (ref: MutableRefObject<TypeComputedProps | null>) => {
    const db = await getDatabase();
    const allStats = (
      await db.tblddlistdefaults
        .find({ selector: { fldListName: { $eq: 'Order Status' } } })
        .exec()
    ).map((e: any) => e.fldMember);
    setAllStatus(allStats);

    // Always reload grid on new item / update / delete.
    db.orders.$.subscribe(async (ev) => {
      if (ev.operation === 'INSERT' || ev.operation === 'UPDATE') {
        highlightedRowId.current = ev.documentId;
        ref.current?.reload();
      }
      if (ev.operation === 'DELETE') {
        highlightedRowId.current = '-1';
        ref.current?.reload();
      }
    });
  };

  const onReady = (ref: MutableRefObject<TypeComputedProps | null>) => {
    setGridRef(ref);
    init(ref);
  };

  const onLoadingChange = (status: boolean) => {
    // If loading completed - check if there any items that needs to be highlighted.
    if (!status && highlightedRowId.current !== '-1') {
      gridRef?.current?.scrollToId(highlightedRowId.current);
      gridRef?.current?.setSelectedById(highlightedRowId.current, true);
      highlightedRowId.current = '-1';
    }
    setLoading(status);
  };

  const dataSource = useCallback(loadData, [searchValue]);

  const onRowClick = useCallback(({ data }) => {
    onSelect(data.original);
  }, []);

  const exportData = (type = 'CSV' | 'xlsx', withFilters = true) => {
    setExportDialog(null);

    switch (type) {
      case 'CSV':
        return onExportToCSV(withFilters);
      case 'xlsx':
        return onExportToExcel(withFilters);
      default:
    }
  };

  const getRows = async (issue: any) => {
    const rows =  issue.map((data: any) =>{
      const dateFormatted = data.OrderDate ? moment(data.OrderDate).format('DD-MMM-YYYY') : '';
      return {
        ...pick(data, [
          'fldStatus', 'Department', 'OrderName', 'OrderedBy'
        ]),
        OrderDate: dateFormatted,
      };
    }   
    );
    return rows
  };

  const onExportToExcel = async (withFilters: boolean) => {
    const { data } = await loadData({
      filterValue: withFilters ? currentFilters : [],
    });

    const columnsData = gridRef.current.visibleColumns.map((c: any) => ({
      header: c.header,
      key: c.id,
    }));
    const columns = columnsData.filter(item => {
      return item.header && typeof item.header === 'string';
    });

    const rows = await getRows(data);

    return exportExcel(columns, rows);
  };

  const onExportToCSV = async (withFilters: boolean) => {
    const columns = gridRef.current.visibleColumns;

    const { data } = await loadData({
      filterValue: withFilters ? currentFilters : [],
    });

    const rows = await getRows(data);
    return exportCSV(columns, rows);
  };

  const handleDataExport = (type = 'CSV' | 'xlsx') => {
    if (areFiltersActive) {
      setExportDialog({
        visible: true,
        type,
        title: type === 'CSV' ? 'CSV' : 'Excel',
      });

      return;
    }

    exportData(type, false);
  };

  const renderGroupTitle = (value, { data }) => {
    const columns = data.fieldPath.map((col) =>
      col === data.name ? col.toUpperCase() : col
    );
    const path = columns && columns.length && columns.join('>');
    return isNil(data.value) ? `No ${path} Assigned` : data.value;
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const CollapseButton = () => {
    if (groupCollapsedStatus) {
      setGroupCollapsedStatus(false);
      return gridRef.current.collapseAllGroups();
    }
    if (!groupCollapsedStatus) {
      setGroupCollapsedStatus(true);
      return gridRef.current.expandAllGroups();
    }
  };

  const searchPopupClose = () => {
    setSearchPopupVisible(false);
  };

  const columns = [
    {
      id: 'icons',
      defaultWidth: 65,
      render: ({ data }: any) => (
          <GridRowAttachmentIcon selector={{ fldRecordKey: data.OrderID }} />
      ),
    },
    {
      name: 'fldStatus',
      header: 'Status',
      defaultFlex: isDesktop ? 0.5 : undefined,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: 'All',
        dataSource: allStatus.map((c) => ({ id: c, label: c })),
      },
    },
    {
      name: 'OrderName',
      header: 'Name',
      defaultFlex: isDesktop ? 1 : undefined,
    },
    {
      name: 'PurchaseOrderNumber',
      header: 'Order #',
      defaultFlex: isDesktop ? 0.5 : undefined,
    },
    {
      name: 'OrderedBy',
      header: 'Ordered By',
      defaultFlex: isDesktop ? 0.8 : undefined,
    },
    {
      name: 'OrderDate',
      header: 'Order Date',
      headerAlign: 'start' as any,
      textAlign: 'end' as any,
      defaultFlex: isDesktop ? 1 : undefined,
      dateFormat: 'DD-MMM-YYYY',
      filterEditor: DateFilter,
      filterEditorProps: (props, { index }) => {
        return { placeholder: index == 1 ? 'End...' : 'Start...' };
      },
      render: ({ value, cellProps: { dateFormat } }) => {
        if (!isNil(value)) {
          return moment(value).format(dateFormat);
        }
      },
    },
    {
      name: 'Supplier',
      header: 'Supplier',
      defaultFlex: isDesktop ? 1 : undefined,
    },
    {
      name: 'Department',
      header: 'Department',
      defaultFlex: isDesktop ? 0.5 : undefined,
    },
  ];

  const footerRows = [
    {
      position: 'end',
      render: {
        icons: () => {

          const style = {
            paddingLeft: 20,
          };

          return (
            <div style={style}>
              Total Records: {currentCount}/{count}
            </div>
          );
        },
      },
      colspan: {
        icons: (_, computedProps) => computedProps.visibleColumns.length,
      },
    },
  ];

  return (
    <div data-testid="data-grid" className="flex flex-col flex-grow">
      <div className="flex flex-row items-center justify-end">
        {!isEmpty(gridRef?.current.computedGroupBy) &&
          (groupCollapsedStatus ? (
            <div>
              <Tooltip title="Collapse All">
                <IconButton onClick={CollapseButton}>
                  <UnfoldLessIcon />
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            <div>
              <Tooltip title="Expand All">
                <IconButton onClick={CollapseButton}>
                  <UnfoldMoreIcon />
                </IconButton>
              </Tooltip>
            </div>
          ))}
      </div>
      <LicensedReactDataGrid
        onRowClick={onRowClick}
        onLoadingChange={onLoadingChange}
        defaultLimit={5000}
        livePagination
        onReady={onReady}
        rowHeight={40}
        defaultGroupBy={[]}
        onGroupByChange={() => gridRef.current.collapseAllGroups()}
        collapsedGroups={collapsedGroups}
        onGroupCollapseChange={setCollapsedGroups}
        loading={loading}
        enableSelection
        defaultFilterValue={filter}
        defaultActiveIndex={3}
        idProperty="OrderID"
        columns={columns}
        dataSource={dataSource}
        renderGroupTitle={renderGroupTitle}
        footerRows={footerRows}
        sourceRoot={sourceRoot}
      />

      <Dialog maxWidth="xs" open={exportDialog?.visible}>
        <DialogTitle>
          Export data to
          {exportDialog?.title}
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            You have filters applied. Would you like to export with current
            filters?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-end' }}>
          <Button
            autoFocus
            onClick={() => exportData(exportDialog?.type, false)}
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={() => exportData(exportDialog?.type)}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog maxWidth="xs" open={searchPopupVisible}>
        <DialogTitle>Search Line Items</DialogTitle>
        <DialogContent dividers>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <InfoIcon color="primary" sx={{ fontSize: 40 }} />
              </Grid>
              <Grid item xs={10}>
                <Typography gutterBottom>
                  Item Not Found. Try entering fewer characters.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-end' }}>
          <Button variant="contained" onClick={searchPopupClose}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default PurchaseOrderGrid;
