import React, { FC, useState } from 'react';
import {
  Button,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  Snackbar,
  Alert,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import EqDetailForm from 'src/components/dataentry/equipmentDE/EqDetailForm';
import CertificatesDetail from 'src/components/dataentry/certificatesDE/CertificatesDE';
import InventoryDetailForm from 'src/components/dataentry/inventoryDE/InventoryDetailForm';
import MedicalInventoryDetailForm from 'src/components/dataentry/invmedicalDE/MedicalInventoryDetailForm';
import BeveragesDetailForm from 'src/components/dataentry/invbeveragesDE/BeveragesDetailForm';
import DryStoresDetailForm from 'src/components/dataentry/invdrystoresDE/DryStoresDetailForm';
import UniformsDetailForm from 'src/components/dataentry/invuniformsDE/UniformsDetailForm';
import PurchaseDetailForm from '../../purchaseDE/PurchaseDetailForm';
import EquipmentSchedulesDetailForm from '../../EquipmentSchedulesDE/EquipmentSchedulesDetailForm';
import { CustomDialog } from 'src/helpers/modals';

interface InjectedProps {
  visible: boolean;
  initialValue: any;
  onSave: () => void;
  onCancel?: () => void;
  tableName?: string;
}

const RelatedItemEditDialog: FC<InjectedProps> = ({
  initialValue,
  visible,
  onCancel,
  onSave,
  tableName,
}) => {
  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });
  const [processing, setProcessing] = useState<boolean>(false);

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const handleSave = () => {
    onSave();
  };

  const handleCancel = () => {
    onCancel && onCancel();
    setProcessing(false);
    setSnackbar({
      open: false,
      type: 'success',
      message: '',
    });
  };

  const handleDelete = () => {};

  return (
    <>
      <CustomDialog
        scroll="paper"
        fullWidth
        maxWidth="md"
        open={visible}
        onClose={handleCancel}
      >
        <DialogTitle>
          <span className="font-bold text-2xl">Edit Item</span>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 0 }}>
          {!!initialValue && tableName === 'Equipment' && (
            <EqDetailForm
              initialValue={initialValue}
              onCancel={handleCancel}
              onSave={handleSave}
              onDelete={handleDelete}
              type="Dialog"
            />
          )}

          {!!initialValue && tableName === 'Certificates' && (
            <CertificatesDetail
              initialValue={initialValue}
              onCancel={handleCancel}
              onSave={handleSave}
              onDelete={handleDelete}
              type="Dialog"
            />
          )}

          {!!initialValue && tableName === 'TblSchedMaintEq' && (
            <EquipmentSchedulesDetailForm
              initialValue={initialValue}
              onCancel={handleCancel}
              onSave={handleSave}
              onDelete={handleDelete}
              type="Dialog"
              isCreated={false}
              editFlag={false}
            />
          )}
          {!!initialValue && tableName === 'Orders' && (
            <PurchaseDetailForm
              initialValue={initialValue}
              onCancel={handleCancel}
              onSave={handleSave}
              onDelete={handleDelete}
              type="Dialog"
              isCreated={false}
              orderItemsUpdate={function (): void {
                throw new Error('Function not implemented.');
              } }
              editFlag={false}
            />
          )}
          {!!initialValue && tableName === 'General' && (
            <InventoryDetailForm
              initialValue={initialValue}
              onCancel={handleCancel}
              onSave={handleSave}
              onDelete={handleDelete}
              type="Dialog"
              editFlag={false}
              isCreated={false}
              storageUpdate={function (): void {
                throw new Error('Function not implemented.');
              }}
            />
          )}

          {!!initialValue && tableName === 'Beverage' && (
            <BeveragesDetailForm
              initialValue={initialValue}
              onCancel={handleCancel}
              onSave={handleSave}
              onDelete={handleDelete}
              type="Dialog"
              editFlag={false}
              isCreated={false}
              storageUpdate={function (): void {
                throw new Error('Function not implemented.');
              }}
            />
          )}

          {!!initialValue && tableName === 'Dry' && (
            <DryStoresDetailForm
              initialValue={initialValue}
              onCancel={handleCancel}
              onSave={handleSave}
              onDelete={handleDelete}
              type="Dialog"
              editFlag={false}
              isCreated={false}
              storageUpdate={function (): void {
                throw new Error('Function not implemented.');
              }}
            />
          )}

          {!!initialValue && tableName === 'Uniform' && (
            <UniformsDetailForm
              initialValue={initialValue}
              onCancel={handleCancel}
              onSave={handleSave}
              onDelete={handleDelete}
              type="Dialog"
              editFlag={false}
              isCreated={false}
              storageUpdate={function (): void {
                throw new Error('Function not implemented.');
              }}
            />
          )}

          {!!initialValue && tableName === 'Medical' && (
            <MedicalInventoryDetailForm
              initialValue={initialValue}
              onCancel={handleCancel}
              onSave={handleSave}
              onDelete={handleDelete}
              editFlag={false}
              isCreated={false}
              storageUpdate={function (): void {
                throw new Error('Function not implemented.');
              }}
            />
          )}
        </DialogContent>
        <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
          <Box
            sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}
          >
            <Button className="mr-2" onClick={handleCancel}>
              Cancel
            </Button>
            <LoadingButton
              loading={processing}
              type="submit"
              form="EquipmentDetailForm"
              variant="contained"
            >
              Save
            </LoadingButton>
          </Box>
        </DialogActions>
      </CustomDialog>

      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        onClose={onSnackbarClose}
      >
        <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
          {snackBar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default RelatedItemEditDialog;
