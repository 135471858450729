// @ts-nocheck
import { TypeSortInfo } from "@inovua/reactdatagrid-enterprise/types";
import { Box, Button, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material"
import { isBoolean, isEmpty, isObject, size } from "lodash";
import { FC, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import DatePicker from "src/components/UI/Forms/DatePicker";
import Input from "src/components/UI/Forms/Input";
import LicensedReactDataGrid from "src/components/UI/LicensedReactDataGrid";
import { EquipmentDocument } from "src/pages/EquipmentPage/rxdb";
import { getDatabase, TDIDb } from "src/rxdb";
import { SchedMaintDocument } from "src/rxdb/collections/SchedMaint/schema";
import { SchedMaintEQDocument } from "src/rxdb/collections/SchedMaintEQ/schema";
import { uuid } from "uuidv4";
import { getSelectorByFilterName } from "src/pages/EquipmentPage/component/EquipmentGrid";
import { CustomDialog } from "src/helpers/modals";
import { isValidDateFormat } from "src/utils/format-dates";

interface SnackbarCopyProps {
    initialValue: any;
    onClose: (created?: boolean) => void;
    linkSched?: boolean;
}

const filter = [
    {
      name: 'UniqueName',
      operator: 'contains',
      type: 'string',
      value: '',
    },
    {
      name: 'Manufacturer',
      operator: 'contains',
      type: 'string',
      value: '',
    },
    {
      name: 'ModelNumber',
      operator: 'contains',
      type: 'string',
      value: '',
    },
  ];  

const copySchedMaintEqData = async (schedMaint: SchedMaintDocument, schedMaintEq: SchedMaintEQDocument, equipments: EquipmentDocument[], data: any = null, link: boolean = false) => {
    const db = await getDatabase();
    for (let i in equipments) {
        const equipment: EquipmentDocument = equipments[i];
        const PKey = uuid();
        const newSchedMaintEq: any = {
            ...schedMaintEq,
            PKey,
            EqKey: equipment.EqKey,
        }
        let newSchedMaint: any = {};

        if(data) {
            if(data["fldHoursTrigger_" + equipment.id]) {
                const fldHoursTrigger = Number(data["fldHoursTrigger_" + equipment.id] ?? 0);
                newSchedMaintEq.fldHoursTrigger = fldHoursTrigger;
            }
            if(data["fldDateTrigger_" + equipment.id]) {
                const fldDateTrigger = data["fldDateTrigger_" + equipment.id].toString();
                newSchedMaintEq.fldDateTrigger = fldDateTrigger;
            }
        }

        if(link) {
            newSchedMaintEq.fldSRHKey = equipment.fldSRHKey;
            newSchedMaintEq.fldLocHierarchy = equipment.fldLocHierarchy;
        }
        else {
            newSchedMaint = {
                ...schedMaint,
                PKey: uuid(),
            };
            let newSchedMaintRes = await db.tblschedmaint.upsert(newSchedMaint);
            newSchedMaintEq.SchedKey = newSchedMaintRes.PKey;
        }
        
        await db.collections.tblschedmainteq.upsert(newSchedMaintEq);

        let documentcrossreference = await db.tbldocumentcrossreference.find({
            selector: {
                fldRecordKey: schedMaintEq.PKey,
            },
        }).exec();
        for (let j in documentcrossreference) {
            let doc = JSON.parse(JSON.stringify(documentcrossreference[j]));
            let newDoc: any = {
                ...doc,
                PKey: uuid(),
                fldRecordKey: newSchedMaintEq.PKey,
            };
            await db.tbldocumentcrossreference.insert(newDoc);
        };

        if(!link) {
            let schedMainParts = await db.tblschedmaintparts.find({
                selector: {
                    fldSchedMaintKey: schedMaint.PKey,
                }
            }).exec();
            for (let j in schedMainParts) {
                let doc = JSON.parse(JSON.stringify(schedMainParts[j]));
                let newDoc: any = {
                    ...doc,
                    PKey: uuid(),
                    fldSchedMaintKey: newSchedMaint.PKey,
                };
                await db.tblschedmaintparts.insert(newDoc);
            };
        }
    }
}

const SnackbarCopyComponent: FC<SnackbarCopyProps> = ({
    initialValue,
    onClose,
    linkSched = false,
}: SnackbarCopyProps) => {
    const {
        control,
        handleSubmit,
      } = useForm<any>();

    const [allEquipments, setAllEquipments] = useState<EquipmentDocument[]>([]);
    const [selectedEquipments, setSelectedEquipments] = useState<EquipmentDocument[]>([]);
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    const [show, setShow] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [showHoursPopup, setShowHoursPopup] = useState<boolean>(false);

    const loadData = async ({
        skip,
        limit,
        filterValue,
        sortInfo,
    }: {
        sortInfo: TypeSortInfo;
        skip: number;
        limit: number;
        filterValue: any;
    }): Promise<{ data: any[]; count: number }> => {
        const db = await getDatabase();
        const schedEq = await db.tblschedmainteq.find({
            selector: {
                SchedKey: initialValue.SchedKey,
                deletedBy: {
                    $eq: null,
                },
            }
        }).exec();
        let schedEqList = [];
        if(schedEq && schedEq.length > 0) {
            schedEqList = schedEq.map((d: EquipmentDocument) => d.EqKey);
        }

        let selector: any = {
            deletedBy: {
                $eq: null,
            },
            EqKey: {
                $nin: schedEqList
            },
        };

        await Promise.all(
            filterValue.map(async (v) => {
                if (isEmpty(v.value)) return v;
                const s = await getSelectorByFilterName(v.name, v.value, v.operator, db);
                selector = {
                    ...selector,
                    ...s,
                };
                return v;
            })
        );

        const data = await db.equipment.find({ selector }).exec();
        data.sort((a: EquipmentDocument, b: EquipmentDocument) => a.UniqueName < b.UniqueName ? -1 : 1);
        setAllEquipments([ ...data ]);
        const length = size(data);
        return { data, count: length };
    };

    const handleSelectionChange = useCallback(({ selected }) => {
        if(isObject(selected) && Object.keys(selected).length === 0) {
            setSelectedEquipments([]);
        }
        else {
            setSelectedEquipments(selected);
        }
    }, []);

    const onLoadingChange = (status: boolean) => {
        setLoading(status);
    };

    const dataSource = useCallback(loadData, []);

    const columns = [
        {
            name: 'UniqueName',
            header: 'Equipment Name',
            defaultFlex: isDesktop ? 1 : undefined,
        },

        {
            name: 'Manufacturer',
            header: 'Manufacturer',
            defaultFlex: isDesktop ? 1 : undefined,
        },

        {
            name: 'ModelNumber',
            header: 'Model Number',
            defaultFlex: isDesktop ? 1 : undefined,
        },
    ];

    const columnsHours = [
        {
            name: 'UniqueName',
            header: 'Equipment Name',
            flex: 1,
        },
        {
            header: 'Hours Next Due',
            flex: 1,
            render: ({ data }: any) => {
                if(initialValue.fldHourInterval > 0) {
                    return <Box sx={{ p: 1 }}>
                                <Input
                                    inputProps={{
                                        size: 'medium',
                                        label: 'Hours Next Due',
                                        variant: 'outlined',
                                        type: 'number',
                                    }}
                                    rules={{ required: true }}
                                    control={control}
                                    name={"fldHoursTrigger_" + data.id}
                                    defaultValue={initialValue.fldHoursTrigger}
                                />
                        </Box>;
                }
                return "-";
            }
        },
    ];

    if(!linkSched) {
        columnsHours.push({
            header: 'Date Next Due',
            flex: 1,
            render: ({ data }: any) => {
                if(initialValue.RecurType >= 47 && initialValue.RecurType <= 64) {
                    return <Box sx={{ p: 1 }}>
                                <DatePicker
                                    name={"fldDateTrigger_" + data.id}
                                    control={control}
                                    label="Date Next Due"
                                    rules={{
                                        required: '*Date value is required for this field!',
                                        validate: (value) => isValidDateFormat(value) || 'Please enter a valid date format dd-MMM-yyyy',
                                      }}
                                    defaultValue={initialValue.fldDateTrigger}
                                />
                            </Box>;
                }
                return "-";
            }
        });
    }

    const onClickNext = () => {
        setShow(false);
        setShowHoursPopup(true);
    }
    
    const selectedEquipmentsData = isBoolean(selectedEquipments) && selectedEquipments
                                    ? allEquipments
                                    : isObject(selectedEquipments) ? Object.values(selectedEquipments) : selectedEquipments;
    
    const copySched = async (data: any) => {
        const db = await getDatabase();
        let schedMaintEqRes: any = await db.tblschedmainteq.findOne({
            selector: {
                SchedKey: initialValue.SchedKey,
            },
        }).exec();
        let schedMaintEq: any = JSON.parse(JSON.stringify(schedMaintEqRes));
        delete schedMaintEq.autoId;
        let schedMaintRes: any = await db.tblschedmaint.findOne({
            selector: {
                PKey: initialValue.SchedKey,
            },
        }).exec();
        let schedMaint: any = JSON.parse(JSON.stringify(schedMaintRes));
        delete schedMaint.updatedAt;
        delete schedMaint.deletedBy;
        delete schedMaint.deletedAt;

        schedMaintEq.SchedKey = schedMaint.PKey;
        await copySchedMaintEqData(schedMaint, schedMaintEq, selectedEquipmentsData, data, linkSched);
        onClose(true);
    }

    return (
        <Box>
            <CustomDialog open={show} fullWidth maxWidth="lg" onClose={() => onClose()}>
                <DialogTitle>
                    <span className="font-bold text-2xl">{linkSched ? "Link" : "Copy"} Equipment Schedule</span>
                </DialogTitle>
                <DialogContent dividers>
                    <div className="flex flex-grow h-full equipment-hour-grid">
                        <LicensedReactDataGrid
                            rowHeight={40}
                            defaultGroupBy={[]}
                            idProperty="EqKey"
                            columns={columns}
                            dataSource={dataSource}
                            onLoadingChange={onLoadingChange}
                            loading={loading}
                            selected={selectedEquipments}
                            defaultFilterValue={filter}
                            onSelectionChange={handleSelectionChange}
                            checkboxColumn
                        />
                    </div>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'flex-end' }}>
                    <Button variant="contained" onClick={onClickNext} disabled={selectedEquipmentsData.length === 0}>
                        Next
                    </Button>
                    <Button
                        autoFocus
                        onClick={() => onClose()}
                    >
                        Close
                    </Button>
                </DialogActions>
            </CustomDialog>
            <CustomDialog open={showHoursPopup} fullWidth maxWidth="lg" onClose={() => onClose()}>
                <DialogTitle>
                    <span className="font-bold text-2xl">Equipment Hours Next Due</span>
                </DialogTitle>
                <DialogContent dividers>
                    <div className="flex flex-grow h-full equipment-hour-grid">
                        <LicensedReactDataGrid
                            rowHeight={60}
                            defaultGroupBy={[]}
                            idProperty="EqKey"
                            columns={columnsHours}
                            dataSource={selectedEquipmentsData}
                        />
                    </div>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'flex-end' }}>
                    <Box display={"flex"}>
                        <Button variant="contained" onClick={handleSubmit(copySched)}>
                            Next
                        </Button>
                        <Button
                            autoFocus
                            onClick={() => onClose()}
                        >
                            Cancel
                        </Button>
                    </Box>
                </DialogActions>
            </CustomDialog>
        </Box>
    )
}

export default SnackbarCopyComponent
