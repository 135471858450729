import React, { FC, useEffect, useState } from 'react';
import { isNil, isString, pick } from 'lodash';
import { Button, DialogTitle, Dialog, DialogActions, DialogContent, Box, Snackbar, Alert, Grid, Portal } from '@mui/material';
import { useForm } from 'react-hook-form';
import Input from 'src/components/UI/Forms/Input';
import { InventoryDocument } from 'src/pages/InventoryPage/rxdb';
import { OrdersDocument } from 'src/rxdb/collections/Orders/schema';
import { getDatabase } from 'src/rxdb';
import Autocomplete from 'src/components/UI/Forms/Autocomplete';
import { toJSON } from 'src/utils';
import CurrenciesDropdownOld from 'src/components/Dropdowns/CurrenciesDropdown/indexOld';
import RichTextEditor from 'src/components/UI/Forms/RichTextEditor';
import { TblDdListDefaults } from 'src/generated/graphql';
import { uuid } from 'uuidv4';

export enum ReceivedSelectedItemEnum {
  UseDefaults,
  ChooseLocationAndAmount,
  ChooseAmount,
};

interface InjectedProps {
  visible: boolean;
  initialValue: InventoryDocument;
  onCancel?: () => void;
  onSave?: (successMessage: string) => void;
}

const InvOrderItemAddDialog: FC<InjectedProps> = ({
  visible,
  onCancel,
  initialValue,
  onSave,
}) => {
  const {
    control,
    setValue,
    handleSubmit,
    reset,
    watch,
  } = useForm<any>({
    defaultValues: {
      Qty: initialValue.fldOrderQty !== null ? initialValue.fldOrderQty : initialValue.fldUseQty,
      ProductDescription: initialValue.ProductDescription,
    },
  });

  const [qtyList, setQtyList] = useState<InventoryDocument[]>([]);
  const [ordersList, setOrdersList] = useState<OrdersDocument[]>([]);

  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });

  const init = async() => {
    const db = await getDatabase();
    db.tblddlistdefaults.find({ selector: { deletedBy: { $eq: null } } }).$.subscribe((defaults: TblDdListDefaults[]) => {
      const qtyList = defaults.filter((d: TblDdListDefaults) => d.fldListName === "Qty" && d.fldMember !== "");
      setQtyList(toJSON(qtyList));
    });
    db.orders.find({ selector: { deletedBy: { $eq: null } } }).$.subscribe((orders: OrdersDocument[]) => {
      setOrdersList(toJSON(orders));
    });
  }

  useEffect(() => {
    init();
  }, []);

  const onChange = async (name: string, value: any) => {
    setValue(name, value, { shouldDirty: true });
  };

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const handleCancel = () => {
    onCancel && onCancel();
    reset();
  };

  const handleSave = async (data: any) => {
    const db = await getDatabase();

    let {
      Amount,
      Order,
      Qty,
      UnitPrice,
      QCurrency,
      ProductDescription,
    } = data;

    // Check for duplicates first
    const matchedOrderItem = await db.orderitems
    .find({ selector: {
      OrderID: { $eq: Order.OrderID},
      ProductID: { $eq: initialValue.ProductID }
    }})
    .exec();
    if (matchedOrderItem && matchedOrderItem.length > 0) {
      setSnackbar({
        open: true,
        type: "error",
        message: "Duplicate items of inventory are not permitted on the same PO",
      });
      return;
    }

    const QtyValue = isString(Qty)
      ? Qty
      : Qty?.fldMember || null;

    const QCurrencyValue = isString(QCurrency)
      ? QCurrency
      : QCurrency?.Curr || null;

    const document = {
      PKey: uuid(),
      Amount: parseFloat(Amount) || 0,
      OrderID: Order.OrderID,
      ProductID: initialValue.ProductID,
      Department: initialValue.Department,
      fldDataType: initialValue.fldDataType,
      ProductName: initialValue.ProductName,
      Manufacturer: initialValue.Manufacturer,
      ModelNumber: initialValue.ModelNumber,
      fldColor: initialValue.fldColor,
      fldSize: initialValue.fldSize,
      PartNumber: initialValue.fldPartNumber,
      Qty: QtyValue,
      UnitPrice: parseFloat(UnitPrice) || 0,
      QCurrency: QCurrencyValue,
      ProductDescription: ProductDescription,
      SearchMod: initialValue.SearchMod,
      SearchMaker: initialValue.SearchMaker,
      SearchPart: initialValue.SearchPart,
      AmtReceived: 0.0,
      DateEntered: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    } as any;

    try {
      await db.orderitems.upsert(document);
      onSave && onSave(`${initialValue.ProductName} has been added to ${Order.OrderName} PO.`);
      reset();
    } catch (e: any) {
      setSnackbar({
        open: true,
        type: 'error',
        message: e.message,
      });
    }
  };

  const displayExtendedCost = (): number => {
    const amount: number = watch('Amount', 0);
    const costPerUnit: number = watch('UnitPrice', 0);
    const extCost: number = isNaN(amount) || isNaN(costPerUnit) ? 0 : Number((amount * costPerUnit).toFixed(2));
    return extCost;
  }

  const displayCurrency = (): string => {
    return watch('QCurrency')?.Curr || ""
  }

  return (
    <>
      <form
        className="relative bg-white flex-grow"
        onSubmit={handleSubmit(handleSave)}
      >
        <Dialog
          scroll="paper"
          fullWidth
          maxWidth="md"
          open={visible}
          onClose={handleCancel}
        >
          <DialogTitle>
            <span className="font-bold text-2xl">Add Inventory to PO</span>
          </DialogTitle>
          <DialogContent dividers sx={{ p: 4 }}>
            <Grid container spacing={5} mt={1}>
              <Grid item xs={12}>
                <Autocomplete
                  onChange={onChange}
                  label={"Purchase Order *"}
                  options={ordersList}
                  control={control}
                  name={"Order"}
                  keyExpr="autoId"
                  displayExpr="OrderName"
                  rules={{
                    required: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  name="Amount"
                  inputProps={{
                    label: 'Amount *',
                    type: 'number',
                    inputProps: {
                      min: 0,
                      inputMode: 'decimal',
                      step: 1,
                    },
                  }}
                  control={control}
                  rules={{
                    min: 0,
                    required: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  onChange={onChange}
                  label={"Unit"}
                  options={qtyList}
                  control={control}
                  name={"Qty"}
                  keyExpr="autoId"
                  displayExpr="fldMember"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  name="UnitPrice"
                  inputProps={{
                    label: 'Cost Per Unit',
                    type: "number",
                    inputProps: {
                      min: 0,
                      inputMode: 'decimal',
                      step: 0.01,
                    },
                  }}
                  rules={{
                    min: 0,
                  }}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CurrenciesDropdownOld
                  label="Currency"
                  onChange={onChange}
                  control={control}
                  name="QCurrency"
                />
              </Grid>
              <Grid item xs={12} md={6}>
              <span style={{ fontWeight: 'bold' }}>Extended cost:</span>{` ${displayExtendedCost()} ${displayCurrency()}`}
              </Grid>
              <Grid item xs={12}>
                <RichTextEditor
                  control={control}
                  name="ProductDescription"
                  onChange={onChange}
                  label="Product Description"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
            <Box  sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}>
              <Button className="mr-2" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                onClick={handleSubmit(handleSave)}
                className="mr-3"
                variant="contained"
              >
                Save
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      </form>
      <Portal>
        <Snackbar
          open={snackBar.open}
          autoHideDuration={2000}
          onClose={onSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Portal>
    </>
  );
};

export default InvOrderItemAddDialog;
