import { omit } from 'lodash';
import { TblSparesUsed } from '../../generated/graphql';
import {logger} from "../../helpers/logger";

const fields = `
  PKey
  ProductID
  LogKey
  EqKey
  WorkKey
  MultiKey
  Qty
  Amount
  fldDateUsed
  DateEntered
  fldUpdateInvOnSave
  deletedAt
  deletedBy
  isRecoverable
  updatedAt
  autoId
`;

export const tblSparesUsedQuery = (doc: TblSparesUsed) => {
  if (!doc) {
    doc = {
      autoId: 0,
      PKey: '',
      ProductID: '',
      updatedAt: new Date(0).toISOString(),
    };
  }
  logger('tblSparesUsed').info(
    `pull query triggered - tblSparesUsed:  { ${new Date().toISOString()} - ID: ${doc.autoId}, updatedAt: ${doc.updatedAt} }`,
  );
  return {
    query: `
      query GetLatestTblSparesUsed($autoId: Int, $filterDate: Date) {
        tblSparesUsed(
          where:{ 
            _and: [
              {
                _or: [
                  {
                    isRecoverable: {
                      _is_null: true
                    }
                  },
                  {
                    isRecoverable: {
                      _eq: true
                    }
                  }
                ]
              },
              {
                _or: [
                  {
                    updatedAt: {_gt: $filterDate},                
                  },
                  {
                    autoId: {_gt: $autoId}
                    updatedAt: {_gte: $filterDate},
                  }
                ]
              }
            ]                                    
          },
          order_by: [
            {updatedAt: asc},
            {autoId: asc}],
          limit: 500
        ) {
          ${fields}          
        }
      }
    `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
    },
  };
};

export const tblSparesUsedMutation = (doc: any) => {
  const query = `
        mutation upsertSpare($input: SpareUsedInput!) {
          upsertSpare(input: $input) {
            ${fields}
          }
        }
    `;
  const variables = {
    input: omit(doc, ['deletedAt', 'updatedAt']),
  };

  return {
    query,
    variables,
  };
};

export const tblSparesUsedSubscription = `
  subscription ontblSparesUsedChanged {
    tblSparesUsed {
      ${fields}      
    }
  }
`;
