import { omit } from 'lodash';
import { TblLogCustom } from '../../../generated/graphql';
import {logger} from "../../../helpers/logger";

const fields = `
  PKey
  fldReportType
  fldLogDate
  fldLogTime
  fldTimeZone
  fldMode
  fldApproxLocation
  fldRemark
  fldReportFile
  fldDateCreated
  fldCreatedBy
  fldCreatedByName
  fldParent
  fldSend
  fldNoView
  isLocked
  deletedAt
  deletedBy
  isRecoverable
  updatedAt
  autoId
`;

export const dayLogsPullQuery = (doc: TblLogCustom) => {
  if (!doc) {
    doc = {
      autoId: 0,
      PKey: '',
      updatedAt: new Date(0).toISOString(),
      fldCreatedByName: '',
      fldReportFile: '',
      fldReportType: '',
    };
  }
  logger('DayLogs').info(
    `pull query triggered - DayLogs:  { ${new Date().toISOString()} - ID: ${
      doc.autoId
    }, updatedAt: ${doc.updatedAt}`,
  );
  return {
    query: `
    query getLogCustom($autoId: Int,  $filterDate: Date) {
        tblLogCustom(
          where:{ 
            _and: [
              {
                _or: [
                  {
                    isRecoverable: {
                      _is_null: true
                    }
                  },
                  {
                    isRecoverable: {
                      _eq: true
                    }
                  }
                ]
              },
              {
                _or: [
                  {
                    updatedAt: {_gt: $filterDate},                
                  },
                  {
                    autoId: {_gt: $autoId}
                    updatedAt: {_gte: $filterDate},
                  }
                ]
              }
            ]                                    
          },
          order_by: [
            {updatedAt: asc},
            {autoId: asc}
          ],
          limit: 500
        ) {
          ${fields}           
      }
   }
      `,
    variables: {
      autoId: doc?.autoId,
      filterDate: doc.updatedAt,
    },
  };
};

export const tblLogCustomMutation = (doc: any) => {
  const query = `
    mutation insertTblLogCustom($input: TblLogCustomUpsertInput!) {
      upsertTblLogCustom(input: $input) {
        ${fields}
      }
    }
  `;

  const variables = {
    input: omit(doc, ['updatedAt', 'deletedAt', 'id']),
  };

  return {
    query,
    variables,
  };
};

export const dayLogsSubscription = `
  subscription onLogCustomChanged {
    tblLogCustom {
      ${fields}      
    }
  }
`;
