import React, { FC, ReactNode } from 'react';
import {
  Breakpoint,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@mui/material';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';

interface GenericModalProps extends Partial<DialogProps> {
  minHeight?: number;
  maxWidth?: Breakpoint | false;
  maxHeight?: number;
  open?: boolean;
  visible: boolean;
  title: string;
  children?: ReactNode;
  width?: number;
  height?: number;
  leftButtonText?: string;
  rightButtonText?: string;
  onHiding?: () => void;
  handleLeftButtonClick?: () => void;
  handleRightButtonClick?: () => void;
  handleSaveChange?: () => void;
  handleCloseChange?: () => void;
  okButtonProps?: Partial<LoadingButtonProps>;
  cancelButtonProps?: Partial<ButtonProps>;
  okText?: string;
  cancelText?: string;
  onOk?: () => void;
  onCancel?: () => void;
  type?: 'info' | 'confirm';
}

const GenericModal: FC<GenericModalProps> = ({
  visible,
  title,
  children,
  okButtonProps,
  cancelButtonProps,
  cancelText = 'Close',
  okText = 'Save',
  onCancel,
  onOk,
  type = 'confirm',
  maxWidth = 'md',
}) => {
  const isInfoType = type === 'info';

  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth}
      open={visible}
      onClose={onCancel}
      scroll="paper"
      className="pt-14 md:pt-19"
      aria-labelledby={`${title}-id`}
      aria-describedby={`${title}-description-id`}
    >
      <DialogTitle id={`${title}-id`}>{title}</DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions className="justify-end">
        {!isInfoType && (
          <LoadingButton {...cancelButtonProps} sx={{ minWidth: '120px' }} onClick={onCancel}>
            {cancelText}
          </LoadingButton>
        )}
        <LoadingButton {...okButtonProps} sx={{ minWidth: '120px' }} onClick={onOk}>
          {okText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default GenericModal;
