import { FC, useRef } from "react";
import { Box, Button, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { DeleteTwoTone } from "@mui/icons-material";
import { CustomDialog } from "src/helpers/modals";
import { LogEntry } from "src/generated/dotnet.graphql";
import LogEntryDetailForm from "./LogEntryDetailForm";

interface LogEntryFormDialogProps {
  selectedItem: LogEntry | undefined;
  isCreate?: boolean;
  onSave: (responseData: LogEntry, responseMessage: string, isCreated: boolean) => void;
  onDelete?: (responseData: LogEntry, responseMessage: string) => void;
  onCancel: () => void;
  moduleReadOnly: boolean;
  refetchQueryVariables: any;
  callerComponent: string;
}

const LogEntryFormDialog: FC<LogEntryFormDialogProps> = ({
  selectedItem,
  isCreate,
  onSave,
  onDelete,
  onCancel,
  moduleReadOnly,
  refetchQueryVariables,
  callerComponent
}) => {
  const logEntryDetailFormRef = useRef<any>(null);

  const onSaveClick = () => {
    if (logEntryDetailFormRef.current) {
      logEntryDetailFormRef.current.onSaveClick();
    }
  };

  const onDeleteClick = () => {
    if (logEntryDetailFormRef.current) {
      logEntryDetailFormRef.current.onDeleteClick();
    }
  };

  const handleSaveResponse = (responseData: LogEntry, responseMessage: string, isCreated: boolean) => {
    onSave(responseData, responseMessage, isCreated);
  }

  return (
    <CustomDialog
      scroll="paper"
      fullWidth
      maxWidth="md"
      open={!!selectedItem}
    >
      <DialogTitle>
        <span className="font-bold text-2xl">{`${isCreate ? 'Add' : 'Edit'} Log Entry`}</span>
      </DialogTitle>

      <DialogContent dividers sx={{ p: 0 }}>
        {!!selectedItem && (
          <LogEntryDetailForm
            initialValue={selectedItem}
            isCreate={isCreate}
            onCancel={onCancel}
            onSave={handleSaveResponse}
            onDelete={onDelete}
            moduleReadOnly={moduleReadOnly}
            refetchQueryVariables={refetchQueryVariables}
            ref={logEntryDetailFormRef}
            callerComponent={callerComponent}
            type='Dialog'
          />
        )}
      </DialogContent>

      <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
        {onDelete && !isCreate &&
          <Box sx={{ justifyContent: 'flex-start', flexGrow: 1, display: 'flex' }}>
            <IconButton
            onClick={onDeleteClick}
            color="error"
            aria-label="Delete item"
            disabled={moduleReadOnly}
            >
              <DeleteTwoTone />
            </IconButton>
          </Box>
        }
        <Box sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}>
          <Button 
            className="mr-2" 
            onClick={onCancel}
          >
            {`${isCreate ? 'Cancel' : 'Close'}`}
          </Button>
          
          <Button
            onClick={onSaveClick}
            variant="contained"
            disabled={moduleReadOnly}
          >
            {`${isCreate ? 'Create' : 'Save'}`}
          </Button>
        </Box>
      </DialogActions>
    </CustomDialog>
  );
};

export default LogEntryFormDialog;
