import React, { FC } from 'react';
import { isNil } from 'lodash';
import { Control, RegisterOptions, useController } from 'react-hook-form';
import { FormHelperText } from '@mui/material';
import GenericTreeViewDropdown from '../../../GenericTreeViewDropdown/indexOld';

interface InjectedProps {
  control: Control;
  name: string;
  defaultValue?: any;
  rules?: RegisterOptions;
  label?: string;
  onChange: (field: string, value: any) => void;
  options: any[];
  valueExpr: string;
  displayExpr: string;
  parentIdExpr: string;
  keyExpr: string;
  disabled?: boolean;
  dropdownHeight?: number;
}

const TreeSelectOld: FC<InjectedProps> = ({
  control,
  onChange,
  name,
  rules,
  defaultValue,
  label,
  valueExpr,
  displayExpr,
  options,
  children,
  keyExpr,
  parentIdExpr,
  disabled,
  dropdownHeight
}) => {
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
    rules,
  });

  const validation = isNil(error) ? 'valid' : 'invalid';

  return (
    <div className="flex flex-col">
      <GenericTreeViewDropdown
        {...inputProps}
        disabled={disabled}
        data={options}
        valueExpr={valueExpr}
        keyExpr={keyExpr}
        parentIdExpr={parentIdExpr}
        displayExpr={displayExpr}
        placeholder={label}
        onValueChanged={(e) => onChange(name, e.value)}
        children={children}
        validationStatus={validation}
        error={error}
        inputRef={ref}
        dropdownHeight={dropdownHeight}
      />
      {/* {error && (
        <FormHelperText error={!!error}>{error?.message}</FormHelperText>
      )} */}
    </div>
  );
};

export default TreeSelectOld;
