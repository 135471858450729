import { omit } from 'lodash';
import { TblAnyReportHistory } from '../../../generated/graphql';
import {logger} from "../../../helpers/logger";

const fields = `
  FKey
  PKey
  fldAction
  fldDateRevision
  fldLastAccessedBy
  fldLastAccessedByName
  fldLastAccessedDate
  fldReportFile
  fldReportPassword
  deletedAt
  deletedBy
  isRecoverable
  updatedAt
  autoId
`;

export const anyReportsHistoryPullQuery = (doc: TblAnyReportHistory) => {
  if (!doc) {
    doc = {
      autoId: 0,
      fldLastAccessedBy: '',
      fldLastAccessedByName: '',
      fldReportFile: '',
      FKey: '',
      PKey: '',
      updatedAt: new Date(0).toISOString(),
    };
  }
  logger('Any Report History').info(
    `pull query triggered - Any Report History:  { ${new Date().toISOString()} - ID: ${doc.autoId} }`,
  );
  return {
    query: `
        query getAnyReportHistory ($autoId: Int, $filterDate: Date) {
              tblAnyReportHistory (
                where:{ 
                  _and: [
                    {
                      _or: [
                        {
                          isRecoverable: {
                            _is_null: true
                          }
                        },
                        {
                          isRecoverable: {
                            _eq: true
                          }
                        }
                      ]
                    },
                    {
                      _or: [
                        {
                          updatedAt: {_gt: $filterDate},                
                        },
                        {
                          autoId: {_gt: $autoId}
                          updatedAt: {_gte: $filterDate},
                        }
                      ]
                    }
                  ]                                    
                },
                order_by: [           
            {updatedAt: asc},
            {autoId: asc}
          ],
                limit: 500) {
                ${fields}
           }
          }
      `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
    },
  };
};

export const anyReportHistoryMutation = (doc: any) => {
  const query = `
    mutation upsertAnyReportHistory($input: AnyReportHistoryInput!) {
      upsertAnyReportHistory(input: $input) {
        ${fields}
      }
    }
  `;

  const variables = {
    input: omit(doc, ['deletedAt']),
  };

  return {
    query,
    variables,
  };
};

export const anyReportHistorySubscription = `
  subscription onAnyReportHistoryChanged {
    tblAnyReportHistory {
      ${fields}
    }
  }
`;
