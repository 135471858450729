export const logger = (tag: string) => {
  const isEnabled = true; // TODO: Add env variable or something

  if (!isEnabled)
    return ({
      error: () => undefined,
      warning: () => undefined,
      info: () => undefined,
      debug: () => undefined,
      trace: () => undefined,
    });

  return {
    error: (...args: unknown[]) => console.log(`[x][${tag}]:`, ...args),
    warning: (...args: unknown[]) => console.log(`[!][${tag}]:`, ...args),
    info: (...args: unknown[]) => console.log(`[+][${tag}]:`, ...args),
    debug: (...args: unknown[]) => console.log(`[-][${tag}]:`, ...args),
    trace: (...args: unknown[]) => console.log(`[*][${tag}]:`, ...args),
  };
};
