import { styled } from '@mui/material/styles';
import Badge, { BadgeProps } from '@mui/material/Badge';

export const AMCSBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -3,
      top: 27,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));
  