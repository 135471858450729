import React, { FC } from 'react';
import { isNil, head, last } from 'lodash';

interface InjectedProps {
  size?: number;
  src?: string;
  title?: string;
  className?: string;
}

const Avatar: FC<InjectedProps> = ({
  size = 43, src, title, className,
}) => {
  const renderPlaceholderItem = () => {
    if (!isNil(src)) return null;
    // Render letters if no URL given. Andrei Huziuk => AH

    const items = title ? title.split(/\s/g) : ['Y', 'M'];
    return (
      <div
        className={`rounded-full justify-center items-center flex bg-primary-blue ${
          className || ''
        }`}
        style={{
          minHeight: size,
          minWidth: size,
          height: size,
          width: size,
        }}
      >
        <span className="font-bold text-white leading-none">
          {`${head(head(items))?.toUpperCase() || 'Y'}${
            head(last(items))?.toUpperCase() || 'M'
          }`}
        </span>
      </div>
    );
  };

  if (isNil(src)) return renderPlaceholderItem();

  return (
    <img
      alt={title}
      src={src}
      className="rounded-full block"
      style={{ height: size, width: size }}
    />
  );
};

export default Avatar;
