import pdf from '@react-pdf/renderer';
import { InspectionReportType } from 'src/components/dataentry/inspectionsDE/InspectionsDetailForm';
const {
  Document,
  Font,
  Page,
  Text,
  View,
  StyleSheet,
} = pdf;

// Register the font files
Font.register({
  family: 'Roboto',
  fonts: [
    { src: `./fonts/Roboto-Regular.ttf` },
    { src: `./fonts/Roboto-Bold.ttf`, fontWeight: 'bold' },
    { src: `./fonts/Roboto-Italic.ttf`, fontStyle: 'italic' },
    { src: `./fonts/Roboto-BoldItalic.ttf`, fontWeight: 'bold', fontStyle: 'italic' },
  ],
});

const borderColor = '#000000';
const styles = StyleSheet.create({
  itemsTable: {
    fontFamily: 'Roboto',
    fontSize: 9,
    width: '100%',
    paddingLeft: 0, // Add this line to remove left padding
  },
  reportTitle: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    width: '100%',
  },
  itemsTableRow: {
    flexDirection: 'row',
    // justifyContent: 'flex-start',
    backgroundColor: '#C3C3C3',
    fontWeight: 'bold',
    paddingTop: 3,
    paddingBottom: 3,
    minHeight: 15,
  },
  row: {
    marginTop: 5,
    fontFamily: 'Roboto',
    fontSize: 9,
    width: '99%',
    flexDirection: 'row',
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    paddingBottom: 2,
  },
  itemNumber: {
    width: 20,
    textAlign: 'left',
    paddingLeft: 5,
  },
  itemCheckHdr: {
    width: 30,
    textAlign: 'left',
    marginLeft: 5,
  },
  itemCheck: {
    width: 20,
    height: 20, // Define the height to match the width for a square
    textAlign: 'center',
    border: 1, // Add border properties
    borderColor: '#000000', // Define border color
    marginLeft: 5,
    justifyContent: 'center', // Align content vertically
    alignItems: 'center', // Align content horizontally
  },
  itemTitle: {
    width: '27%',
    textAlign: 'left',
    marginLeft: 5,
  },
  itemEquipment: {
    width: '27%',
    textAlign: 'left',
    marginLeft: 15,
  },
  itemLocation: {
    width: '27%',
    marginLeft: 15,
    textAlign: 'left',
    paddingLeft: 5,
    paddingRight: 5,
  },
  itemDescription: {
    width: '90%',
    textAlign: 'left',
    paddingRight: 2,
    marginRight: 7,
    marginLeft: 57,
  },
  footerLabel: {
    fontWeight: 'bold',
    fontStyle: 'italic',
  },

  footerView: {
    marginTop: 5,
    paddingLeft: 0, // Add this line to remove left padding
    fontFamily: 'Roboto',
    fontSize: 9.75,
    fontStyle: 'italic',
    lineHeight: 2,
  },
});

const InspectionReport = ( data: InspectionReportType) => {
  return (
    <Document>
      <Page orientation='portrait' size={data.pageSize || 'LETTER'}>
        <View
          style={{
            ...styles.itemsTable,
            maxHeight: '100%',
          }}
        >
          <View
            style={{
              marginLeft: 5,
              marginRight: 5,
              marginTop: 30,
              marginBottom: 30,
            }}
          >
            <Text style={styles.reportTitle}>{data.fldSubject}</Text>
            <View style={styles.itemsTableRow}>
              <Text style={styles.itemNumber} />
              <View style={styles.itemCheckHdr} />
              <Text style={styles.itemTitle}>Title</Text>
              <Text style={styles.itemEquipment}>Equipment</Text>
              <Text style={styles.itemLocation}>Location</Text>
              {/* <Text style={styles.itemDescription}>Description</Text> */}
            </View>
            {data.checks.map((check: any) => (
              <View style={{ ...styles.row }} key={check.pkey}>
                <View style={{ flexDirection: 'column' }}>
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={styles.itemNumber}>
                      {check.fldIndex}.
                    </Text>
                    <View style={styles.itemCheck}>
                      {check.Completed && (
                        <Text style={{ textAlign: 'center' }}>X</Text>
                      )}
                    </View>
                    {/* <ReactPdfSquare styles={styles.itemCheck} /> */}
                    <Text style={styles.itemTitle}>
                      {check.fldTitle}
                    </Text>
                    <Text style={styles.itemEquipment}>
                      {check.equipment}
                    </Text>
                    <Text style={styles.itemLocation}>
                      {check.location}
                    </Text>
                  </View>

                  <Text style={styles.itemDescription}>
                    {check.fldDescriptions}
                  </Text>

                  {/* This block (below is commented out it is a test
                  to try to render the HTML within the document
                  which does not work  */}
                  {/* {InspectionItem.fldDescriptions && (
                  <Text style={styles.itemDescription} wrap={true}>
                    {typeof InspectionItem.fldDescriptions === 'string' ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: InspectionItem.fldDescriptions,
                        }}
                      />
                    ) : (
                      InspectionItem.fldDescriptions
                    )}
                  </Text>
                )} */}
                </View>
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default InspectionReport;