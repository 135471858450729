import { CrewProfiles, TblSettingsPersonal } from '../../../generated/graphql';
import {logger} from "../../../helpers/logger";

const fields = `
  PKey
  fldCrewID
  fldEQ
  fldPO
  fldInv
  fldInvMed
  fldInvBev
  fldInvStores
  fldInvUni
  fldNoRights
  fldCDM
  fldSMS
  fldISPS
  fldShowCurrentTasks
  fldSMSAdmin
  fldISPSAdmin
  fldCerts
  fldInvNewItemsConsume
  fldCrewEmp
  fldCrew
  deletedAt
  updatedAt
  fldAllDepts
  fldWorkOffline
  fldAddlocs
  fldAddCats
  fldLogsRL
  fldLogsDay
  fldCrewView
  fldGuest
  fldDeptHead
  fldWL
  fldLogs
  fldLogsMaint
  fldTritonOpts
  fldTritonSettings
  fldScheduling
  deletedBy
  isRecoverable
  autoId
`;

export const settingsPersonalPullQuery = (user: CrewProfiles | null) => (doc: TblSettingsPersonal) => {
  if (!doc) {
    doc = {
      autoId: 0,
      PKey: '',
      fldCrewID: '',
      updatedAt: new Date(0).toISOString(),

    };
  }
  logger('SettingsPersonal').info(
    `pull query triggered - SettingsPersonal:  { ${new Date().toISOString()} - ID: ${doc.autoId}, fldCrewId: ${user?.fldCrewID}, updatedAt: ${doc.updatedAt}`,
  );
  return {
    query: `
    query getSettingsPersonal($fldCrewID: String,  $filterDate: Date) {
        tblSettingsPersonal(
          where:{            
            fldCrewID:{_eq: $fldCrewID }, 
            updatedAt: {_gt: $filterDate},            
          },
          order_by: [
            {updatedAt: asc},
            {autoId: asc}
          ],
          limit: 500
        ) {
          ${fields}           
      }
   }
      `,
    variables: {
      autoId: doc.autoId,
      fldCrewID: user?.fldCrewID,
      filterDate: doc.updatedAt,
    },
  };
};

export const settingsPersonalSubscription = `
  subscription onSettingsPersonalChanged {
    tblSettingsPersonal {
      ${fields}      
    }
  }
`;
