import { RxCollection, RxJsonSchema, RxDocument } from 'rxdb';
import { TblDocumentRevisions } from '../../../generated/graphql';

export type DocumentRevisionDocType = TblDocumentRevisions;

export type DocumentRevisionDocMethods = {};

export type DocumentRevisionDocument = RxDocument<
    DocumentRevisionDocType,
    DocumentRevisionDocMethods
    >;

export type DocumentRevisionCollectionMethods = {};

export type DocumentRevisionCollection = RxCollection<
    DocumentRevisionDocType,
    DocumentRevisionDocMethods,
    DocumentRevisionCollectionMethods
    >;

//   DateEntered

export const schema: RxJsonSchema<DocumentRevisionDocType> = {
  title: 'Document Revision ',
  description: 'DocumentRevision',
  version: 1,
  keyCompression: false,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    fldAltPath: {
      type: ['number', 'null'],
    },
    PKey: {
      type: 'string',
      primary: true,
    },
    DateEntered: {
      type: ['string', 'null'],
    },
    fldApprovedByPE: {
      type: ['string', 'null'],
    },
    fldApprovedByPM: {
      type: ['string', 'null'],
    },
    fldBinderIndex: {
      type: ['string', 'null'],
    },
    fldBinderLocation: {
      type: ['string', 'null'],
    },
    fldFKey: {
      type: 'string',
      ref: 'tbldocumentation',
    },
    fldFileName: {
      type: ['string', 'null'],
    },
    fldFileType: {
      type: ['string', 'null'],
    },
    fldLocHierarchy: {
      type: ['string', 'null'],
    },
    fldNotes: {
      type: ['string', 'null'],
    },
    fldPage: {
      type: ['string', 'null'],
    },
    fldStatus: {
      type: ['string', 'null'],
    },
    fldRevisionDate: {
      type: ['string', 'null'],
    },
    fldRevision: {
      type: ['string', 'null'],
    },
    tblDocumentation: {
      type: ['object', 'null'],
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
    updatedAt: {
      type: 'string',
    },
  },
  required: [],
  additionalProperties: false,
};

export default schema;
