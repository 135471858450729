import { gql, useQuery } from "@apollo/client";
import { Comment, CommentSortInput, RecordType } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";
import { isNotNil } from "src/utils";

export const GET_COMMENTS_FOR_RECORD = gql`
  query GetCommentsForRecord($recordId: String!, $order: [CommentSortInput!]) {
    commentsForRecord(recordId: $recordId, order: $order) {
      id
      authorId
      author {
        fldFirst
        fldLast
      }
      createdAt
      content
      parentId
      recordId
      recordType
      replies {
        id
        authorId
        author {
          fldFirst
          fldLast
        }
        createdAt
        content
        parentId
        recordId
        recordType
      }
    }
  }
`

interface CommentsResult {
  commentsForRecord: Comment[];
  commentsForRecordLoading: boolean;
  commentsForRecordCount: number;
}

export const useGetComments = (recordId: string, recordType: RecordType, order: CommentSortInput): CommentsResult => {

  if (!isNotNil(recordId)) {
    return {
      commentsForRecord: [],
      commentsForRecordLoading: false,
      commentsForRecordCount: 0
    };
  }
  
  const { data, loading, error } = useQuery(GET_COMMENTS_FOR_RECORD, {
    variables: { recordId, order },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    logger('GetCommentsForRecord').error(`Error fetching comments for ${recordType}: ${recordId} -->`, error.message);
  }

  return {
    commentsForRecord: data?.commentsForRecord || [],
    commentsForRecordLoading: loading,
    commentsForRecordCount: data?.commentsForRecord?.length || 0,
  };
};

