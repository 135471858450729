import { ChangeEventHandler, FC, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, Button, Box, TextField, FormHelperText, SxProps, Theme } from "@mui/material";
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import { LoadingButton } from "@mui/lab";

const dialogStyle: SxProps<Theme> = {
  '& .MuiDialogContent-root': {
    maxHeight: '300px',  // Prevents the dialog content from expanding too much vertically
    overflowY: 'auto',   // Allows scrolling if the content exceeds the max height
  },
  '& .MuiDialog-paper': {
    maxWidth: '500px', // Prevents the dialog from stretching too wide
    width: '500px',    // Fixed width
  },
};

const textFieldStyle: SxProps<Theme> = { 
  '& .MuiOutlinedInput-notchedOutline': { 
    border: 'none' 
  } 
}

interface LogEntryEquipmentHoursDialogProps extends Partial<DialogProps> {
  visible: boolean;
  title?: string;
  okText?: string;
  cancelText?: string;
  onOk: () => void;
  onCancel: () => void;
  color?: any;
  disabled?: boolean;
  loading?: boolean;
  equipmentHours: number | null;
  eqHoursCompletedAt: number | null;
  onChange: (value: any) => void;
}

const LogEntryEquipmentHoursDialog: FC<LogEntryEquipmentHoursDialogProps> = ({
  visible,
  title = 'Provide Completed At Equipment Hours',
  cancelText = 'Cancel',
  okText = "OK",
  color="error",
  onCancel,
  onOk,
  disabled = false,
  loading = false,
  equipmentHours,
  eqHoursCompletedAt,
  onChange,
}) => {

  const [isFocused, setIsFocused] = useState(false);

  const message = () => {
    if (eqHoursCompletedAt === null) return '* Leaving this blank will use current Equipment hours.';
    if (equipmentHours && eqHoursCompletedAt && eqHoursCompletedAt < equipmentHours) return '* Completed at less than Current. Entered Hours will be logged and used to update schedule, Equipment hours will not be updated.';
    return '';
  }

  const handleChangeWrapper: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;
    // If the input is empty, set it to null
    if (value === '') {
      onChange(null);
    } else {
      const parsedValue = parseInt(value, 10);
      // Only update if it's a valid number and >= 0
      if (!isNaN(parsedValue) && parsedValue >= 0) {
        onChange(parsedValue);
      }
    }
  };

  return (
    <Dialog
      open={visible}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="warning-dialog"
      sx={dialogStyle}
    >
      <DialogTitle id="alert-dialog-title">
        {(<WarningTwoToneIcon fontSize="small" color={color} className="mr-3" />)}{title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="ml-3">
            <div className="flex items-center justify-between mb-2">
              <span>Current Equipment hours:</span>
              <TextField
                value={equipmentHours}
                fullWidth
                disabled
                autoComplete="off"
                rows={1}
                multiline={false}
                variant="outlined"
                size="small"
                type="number"
                inputProps={{ 
                  style: { 
                    textAlign: 'end', 
                    fontWeight: 'bold',
                  } 
                }}
                sx={textFieldStyle}
                className="w-1/3"
              />
            </div>

            <div className="flex items-center justify-between">
              <span>Equipment hours completed at:</span>
              <TextField
                value={eqHoursCompletedAt}
                fullWidth
                autoComplete="off"
                rows={1}
                multiline={false}
                size="small"
                type="number"
                inputProps={{
                  min: 0,
                  style: {
                    textAlign: 'end',
                    color: isFocused ? 'inherit' : '#888',
                  },
                }}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                onChange={handleChangeWrapper}
                className="w-1/3"
              />
            </div>
            <FormHelperText error>
              {message()}
            </FormHelperText>
          </div>
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
        <Box sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}>
          {!disabled && (
            <LoadingButton loading={loading} data-testid="warning-dialog-confirm" className="mx-2" onClick={onOk}>
              {okText}
            </LoadingButton>
          )}
        </Box>
        <Button data-testid="warning-dialog-cancel" onClick={onCancel} variant="contained" autoFocus>
          {cancelText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogEntryEquipmentHoursDialog;