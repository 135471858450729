import { flatten, uniq, filter, isNil, isEmpty } from 'lodash';
import {getDatabase} from "../index";

export interface VDDAllContacts {
  id: string;
  DisplayMember: string;
}

export default async (): Promise<VDDAllContacts[]> => {
  const db = await getDatabase();

  // TypeError: Cannot read properties of null (reading 'every')
  // Unable to apply `$ne: null` on $or query, so filter it on JS side

  const equipment = await db.equipment.find().exec(); // Manufacturer + Supplier
  const inventory = await db.inventory.find().exec(); // Manufacturer
  const logentry = await db.logentry.find().exec(); // fldCompany
  const orderItems = await db.orderitems.find().exec(); // Manufacturer
  const orders = await db.orders.find().exec(); // Supplier
  // const contactManager = await db.tblcontactmanager.find().exec(); // fldCompany
  const workissues = await db.workissues.find().exec(); // Company

  const result = [
    flatten(equipment.map(e => [e.Manufacturer, e.Supplier])),
    inventory.map(i => i.Manufacturer),
    logentry.map(le => le.fldCompany),
    orderItems.map(oi => oi.Manufacturer),
    orders.map(o => o.Supplier),
    // contactManager.map(cm => cm.fldCompany),
    workissues.map(wi => wi.Company)
  ];

  // Remove any nulls / empty strings and also make sure it has uniq rows
  const filtered = uniq(
    filter(
      flatten(result),
      v => !isNil(v) && !isEmpty(v)
    )
  );

  // Now in order to support exisitng code
  // Transform it to proper format
  //DisplayMember
  return filtered.map(v => ({ DisplayMember: v!, id: v! }));
};
