import React, { FC, useEffect, useState } from 'react';
import { Control, UseFormWatch } from 'react-hook-form';
import { isNil } from 'lodash';
import { useParams } from 'react-router-dom';
// MUI Components
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

// TDI Componenents
import Checkbox from 'src/components/UI/Forms/Checkbox';
import DocumentRevision from 'src/modules/DocumentRevision';
import { TblDocumentationDocument } from 'src/pages/LibrariesPage/rxdb';
import RichTextEditor from '../../../UI/Forms/RichTextEditor';
import Autocomplete from '../../../UI/Forms/Autocomplete';
import CategoryDropdownOld from '../../../Dropdowns/CategoryDropdown/indexOld';

// Utilities

// Data
import { getDatabase } from '../../../../rxdb';
import { TblDdListDefaultsDocument } from '../../../../api/queries/tblDDListDefaults/rxdb';
import { TblDocumentation } from '../../../../generated/graphql';
import { useAppState } from 'src/contexts/app-state';

interface DocItemSummaryProps {
  form: TblDocumentation;
  initialValue: TblDocumentationDocument;
  onChange: (field: string, value: any) => void;
  control: Control;
  watch: UseFormWatch<any>;
  getValues: any;
  libraryType? : string;
  disabled?: boolean;
}

const DocSummaryForm: FC<DocItemSummaryProps> = ({
  form,
  onChange,
  initialValue,
  control,
  watch,
  getValues,
  libraryType,
  disabled= false,
}) => {
  const { settingsPersonal } = useAppState();
  const { type } = useParams() as { type: string };

  const init = async () => {
    const db = await getDatabase();
  };

  useEffect(() => {
    if (isNil(initialValue)) return;

    init();
  }, [initialValue]);

  const renderListDefaultBox = (
    items: TblDdListDefaultsDocument[],
    fieldName: string,
    fldListName: string,
    freeSolo = false,
  ) => (
    <Autocomplete
      defaultPayload={{
        fldListName,
        fldT4Reqd: true,
      }}
      displayExpr="fldMember"
      keyExpr="PKey"
      onChange={onChange}
      freeSolo={freeSolo}
      options={items}
      label={fldListName}
      control={control}
      name={fieldName}
      collection="tblddlistdefaults"
    />
  );
  return (
    <div className="mt-3">
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="general-information"
          id="general-information"
        >
          General Information
        </AccordionSummary>
        <AccordionDetails>
          <div className="w-full flex flex-col">
            {/* Category Hierarchial, collapsable Tree */}
            <div className="mt-3">
              <CategoryDropdownOld
                name="fldSRHKey"
                label="Category"
                defaultValue={initialValue.fldSRHKey}
                control={control}
                onChange={onChange}
                rules={{
                  required: true,
                }}
                sourceForm={libraryType ? libraryType : 'task'}
                allDepts={settingsPersonal?.fldAllDepts > 0}
              />
            </div>

            { !(type === 'drawings' || type === 'photos' || type ==='reference') &&
              <FormGroup row className="mt-4">
              <div className="ml-5 w-40">
                <Checkbox
                  control={control}
                  name="fldSMSReport"
                  label="This is a report"
                />
              </div>

              <div className="ml-5 w-40">
                <Checkbox
                  control={control}
                  name="fldVerificationReq"
                  label="Requires Verification"
                />
              </div>
            </FormGroup>}
          </div>

          <div className="my-4">
            <RichTextEditor
              control={control}
              name="fldDescription"
              onChange={onChange}
            />
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded className="mt-3">
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="planning-status"
          id="planning-status"
        >
          Revision History
        </AccordionSummary>
        <AccordionDetails>
          <DocumentRevision
            type={libraryType === 'PHOTOS' ? 'photo' : 'attachments'}
            allowedFileExtensions={libraryType === 'PHOTOS' ? ['.jpg', '.jpeg', '.gif', '.png'] : ['.pdf']}
            relatedKey={form.PKey}
            disabled={disabled}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default DocSummaryForm;
