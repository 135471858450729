import { RxCollection, RxJsonSchema, RxDocument } from 'rxdb';
import { InvEq } from '../../../generated/graphql';

export type InvEqDocType = InvEq & { id: string};

export type InvEqDocMethods = {};

export type InvEqDocument = RxDocument<
    InvEqDocType,
    InvEqDocMethods
    >;

export type InvEqCollectionMethods = {};

export type InvEqCollection = RxCollection<
    InvEqDocType,
    InvEqDocMethods,
    InvEqCollectionMethods
    >;

//   DateEntered

export const schema: RxJsonSchema<InvEqDocType> = {
  keyCompression: false,
  title: 'InvEq ',
  description: 'InvEq',
  version: 1,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    EqKey: {
      type: 'string',
      // primary: true,
      ref: 'equipment',
    },
    ProductID: {
      type: ['string', 'null'],
      ref: 'inventory',
      // primary: true,
    },
    id: {
      type: 'string',
      primary: true,
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
    updatedAt: {
      type: 'string',
    },
  },
  indexes: ['id', 'updatedAt'],
  required: [

  ],
  additionalProperties: false,
};

export default schema;
