import { FC } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

interface InjectedProps {
    COLOR_OPTION?: any;
    height: any;
    width: any;
    data: any;
    minKey: string;
    maxKey: string;
    nameKey: string;
}

const BarChartUI: FC<InjectedProps> = ({
    COLOR_OPTION,
    width,
    height,
    data,
    minKey,
    maxKey,
    nameKey
}) => {

    return (
        <ResponsiveContainer width={width} height={height}>
            <BarChart
                data={data}
            >
                <Bar dataKey={maxKey}>
                    {data.map((entry: any, index: any) => {
                        return (
                            <Cell cursor="pointer" fill={COLOR_OPTION.good[index]} key={`cell-${index}`} />
                        )
                    })}
                </Bar>
                <XAxis dataKey={nameKey} />
                <YAxis />
                <Bar dataKey={minKey} fill={COLOR_OPTION.below[0]} />
                <Tooltip />
                <Legend />
                <CartesianGrid strokeDasharray="1 1" />
            </BarChart>
        </ResponsiveContainer>
    );

}

export default BarChartUI