import { gql, useQuery } from '@apollo/client';
import { logger } from 'src/helpers/logger';

export const GET_COMPANY_NAMES = gql`
  query GetCompanyNames {
    companyNames {
      name
    }
  }
`;

interface CompanyNamesResult {
  data: String[];
  loading: boolean;
}

export const useGetCompanyNames = (): CompanyNamesResult => {
  const { data, loading, error } = useQuery(GET_COMPANY_NAMES, {
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    logger('GetCompanyNames').error('Error fetching companies -->', error.message);
  }

  return {
    data: data?.companyNames || [],
    loading,
  };
};
