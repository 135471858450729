import React, {FC, useState} from 'react';
import {
  Button,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
} from '@mui/material';
import { isNil } from 'lodash';
import EquipmentSchedulesDetailForm from 'src/components/dataentry/EquipmentSchedulesDE/EquipmentSchedulesDetailForm';
import {SchedMaintEQDocument} from "../../../rxdb/collections/SchedMaintEQ/schema";
import {LoadingButton} from "@mui/lab";
import { CustomDialog } from 'src/helpers/modals';
import { logger } from 'src/helpers/logger';

interface InjectedProps {
  visible: boolean;
  initialValue: SchedMaintEQDocument;
  onSave?: (sched: SchedMaintEQDocument, isCreation: boolean, e?: any) => void;
  onCancel?: () => void;
  editable?: boolean;
  title?: string;
}

const EquipmentSchedulesFormDialog: FC<InjectedProps> = ({
  initialValue,
  visible,
  onCancel,
  onSave,
  editable = true,
  title,
}) => {
  const [processing, setProcessing] = useState<boolean>(false);

  const isCreation = isNil(initialValue?.SchedKey);

  const handleSave = (data: SchedMaintEQDocument, isCreation: boolean, e?: any) => {
    onSave && onSave(data, isCreation, e);
  };
  
  const handleCancel = () => {
    onCancel && onCancel();
    setProcessing(false);
  };

  const handleDelete = () => {};

  const getTitle = () => {
    if (!!title) return title;

    if (isCreation) return 'Create Schedule';

    return 'Edit Schedule';
  };

  return (
    <>
      <CustomDialog
        scroll="paper"
        fullWidth
        maxWidth="md"
        open={visible}
        onClose={handleCancel}
      >
        <DialogTitle>
          <span className="font-bold text-2xl">{getTitle()}</span>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 0 }}>
          <EquipmentSchedulesDetailForm
            initialValue={initialValue}
            onCancel={handleCancel}
            onSave={handleSave}
            onDelete={handleDelete}
            isCreated={false}
            editFlag={false}
            type="Dialog"
          />
        </DialogContent>
        <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
          <Box
            sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}
          >
            <Button className="mr-2" onClick={handleCancel} variant="contained">
              Close
            </Button>
            {editable && (
              <LoadingButton
                loading={processing}
                type="submit"
                form="EquipmentSchedulesDetailForm"
                variant="contained"
              >
                Save
              </LoadingButton>
            )}
          </Box>
        </DialogActions>
      </CustomDialog>
    </>
  );
};

export default EquipmentSchedulesFormDialog;
