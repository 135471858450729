import React, { FC, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { isEmpty, isNil, size } from 'lodash';
import Button from '@mui/material/Button';
import {
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Add, Edit } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { WorkIssuesDocument } from 'src/rxdb/collections/WorkIssues/rxdb';
import { InventoryDocument } from 'src/pages/InventoryPage/rxdb';
import { ISpareFormPayload } from '../../../../types/spares';
import { TblSparesUsedDocument } from '../../rxdb';
import { getDatabase } from '../../../../rxdb';
import FilteredSparePopupOld from './components/FilteredSparePopupOld';
import InventoryPopupOld from './components/InventoryPopupOld';

interface InjectedProps {
  initialValues?: TblSparesUsedDocument;
  visible: boolean;
  onCancel?: () => void;
  onSubmit: (payload: ISpareFormPayload) => void;
  issue: any;
}
type SparePopupForm = {
  PKey: string;
  ProductName: string | null;
  ProductID: any;
  Manufacturer: any;
  fldPartNumber: any;
  Amount: any;
};

const sparedefaultValue = {
  PKey: '',
  ProductName: null,
  ProductID: '',
  Manufacturer: '',
  fldPartNumber: '',
  Amount: 0,
};

const SparePopupOld: FC<InjectedProps> = ({
  initialValues,
  visible,
  onCancel,
  onSubmit,
  issue,
}) => {
  const {
    control, handleSubmit, reset, getValues,
  } = useForm<SparePopupForm>({
    mode: 'onBlur',
  });
  const [inventoryPopup, setInventoryPopup] = useState(false);
  const [filterSparePopup, setFilterSparePopup] = useState(false);
  const [count, setCount] = useState<number>(0);

  const getInventories = async (query?: string) => {
    const db = await getDatabase();
    const inveq = await db.inveq
      .find({
        selector: {
          EqKey: !isNil(issue?.EqKey) ? issue?.EqKey : !isNil(issue?.Equipment?.EqKey) ? issue?.Equipment?.EqKey : null,
        },
      })
      .exec();
    setCount(size(inveq));
  };

  const updateFormData = (payload: any) => {
    if (!isNil(payload)) {
      reset(payload);
    }
  };

  const setInitialValue = async () => {
    // Dont do anything special
    if (isNil(initialValues)) return reset(sparedefaultValue);

    const inventory: InventoryDocument | null = await initialValues.populate(
      'ProductID',
    );

    if (isNil(inventory)) return;

    const payload = {
      PKey: initialValues.PKey,
      ProductID: initialValues.ProductID,
      fldPartNumber: inventory.fldPartNumber,
      Amount: initialValues.Amount,
      ProductName: inventory.ProductName,
      Manufacturer: inventory.Manufacturer,
    };

    updateFormData(payload);
  };

  useEffect(() => {
    getInventories();
  }, []);

  const onSpareAddClick = () => {
    if (count > 0) {
      setFilterSparePopup(true);
    } else {
      setInventoryPopup(true);
    }
  };

  useEffect(() => {
    if (isNil(initialValues) && visible) {
      onSpareAddClick();
    }
  }, [visible]);

  useEffect(() => {
    setInitialValue();
  }, [initialValues]);

  const onAddClick = () => {
    setFilterSparePopup(false);
    setInventoryPopup(true);
  };

  const onCancelInventoryPopup = () => {
    setInventoryPopup(false);
    setFilterSparePopup(false);
  };

  const onselectionchange = (e: any) => {
    const inventory = e as InventoryDocument;
    if (isNil(inventory)) return;
    const payload = {
      ProductID: inventory.ProductID,
      fldPartNumber: inventory.fldPartNumber,
      Manufacturer: inventory.Manufacturer,
      ProductName: inventory.ProductName,
      Amount: 0,
    };
    updateFormData(payload);
  };

  const onSelectInventory = (inventory: InventoryDocument) => {
    onselectionchange(inventory);
    setFilterSparePopup(false);
    setInventoryPopup(false);
  };

  const onSaveClicked = (data: SparePopupForm) => {
    const payload: any = {
      Amount: parseInt(data.Amount),
      PKey: data.PKey,
      ProductID: data.ProductID,
      PartNumber: data.fldPartNumber,
      Manufacturer: data.Manufacturer,
    };
    onSubmit(payload);
    reset(sparedefaultValue);
  };

  return (
    <div>
      <form>
        <Dialog
          open={visible}
          onClose={onCancel}
          fullWidth
          maxWidth="md"
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle sx={{ m: 0, p: 4 }} style={{ fontSize: '26px' }}>
            <span className="ml-2">Spare Info</span>
            {onCancel ? (
              <IconButton
                aria-label="close"
                onClick={onCancel}
                sx={{
                  position: 'absolute',
                  right: 10,
                  top: 14,
                  color: (theme) => theme.palette.grey[400],
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>
          <DialogContent dividers style={{ maxHeight: '60vh' }}>
            <Controller
              name="ProductName"
              control={control}
              defaultValue=""
              rules={{ required: 'Product name required' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  required
                  value={value || ''}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  label="Product Name"
                  className="mb-8 w-full"
                  autoComplete='off'
                  size="small"
                  InputProps={{
                    readOnly: true,
                    endAdornment: !initialValues?.ProductID && (
                      <IconButton onClick={onSpareAddClick}>
                        {isEmpty(getValues().ProductName) ?
                          <Add /> :
                          <Edit />
                        }
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
            <Controller
              name="Manufacturer"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  value={value}
                  autoComplete='off'
                  onChange={onChange}
                  className="mb-8 w-full"
                  label="Manufacturer"
                  size="small"
                />
              )}
            />
            <Controller
              name="fldPartNumber"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  value={value}
                  autoComplete='off'
                  onChange={onChange}
                  className="mb-8 w-full"
                  label="Part Number"
                  size="small"
                />
              )}
            />
            <Controller
              name="Amount"
              control={control}
              defaultValue={0}
              rules={{
                validate: () => {
                  if (!(getValues('Amount') > 0 && getValues('Amount') < 501)) {
                    return 'Amout should between 1 to 500';
                  }
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label="Amount"
                  type="number"
                  autoComplete='off'
                  error={!!error}
                  helperText={error ? error.message : null}
                  className="mb-8 w-full"
                  value={value}
                  onChange={onChange}
                  size="small"
                  inputProps={{
                    max: 500,
                    min: 0,
                  }}
                />
              )}
            />
          </DialogContent>
          <DialogActions sx={{ m: 0, p: 3 }}>
            <Button
              onClick={handleSubmit(onSaveClicked)}
              className="ml-4 mr-3 w-32"
              variant="contained"
            >
              Save
            </Button>
            <Button onClick={onCancel} className="w-32">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </form>
      <div>
        <FilteredSparePopupOld
          filterSparePopup={filterSparePopup}
          onCancelInventoryPopup={onCancelInventoryPopup}
          onSelectInventory={onSelectInventory}
          onAddClick={onAddClick}
          keyValue={!isNil(issue?.EqKey) ? issue?.EqKey : !isNil(issue?.Equipment?.EqKey) ? issue?.Equipment?.EqKey : null}
        />
      </div>
      <div>
        <InventoryPopupOld
          inventoryPopup={inventoryPopup}
          onCancelInventoryPopup={onCancelInventoryPopup}
          onSelectInventory={onSelectInventory}
        />
      </div>
    </div>
  );
};

export default SparePopupOld;
