import { RxCollection, RxJsonSchema, RxDocument } from 'rxdb';
import { OrderItems } from '../../../generated/graphql';

export type OrderItemsDocType = OrderItems;

export type OrderItemsDocMethods = {};

export type OrderItemsDocument = RxDocument<
  OrderItemsDocType,
  OrderItemsDocMethods
  >;

export type OrderItemsCollectionMethods = {};

export type OrderItemsCollection = RxCollection<
  OrderItemsDocType,
  OrderItemsDocMethods,
  OrderItemsCollectionMethods
  >;

export const schema: RxJsonSchema<OrderItemsDocType> = {
  title: 'OrderItems',
  description: 'Multiple locations',
  version: 3,
  keyCompression: false,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    PKey: {
      type: 'string',
      primary: true,
    },
    ProductID: {
      type: ['string', 'null'],
      ref: 'inventory',
    },
    OrderID: {
      type: 'string',
      ref: 'orders',
    },
    ProductName: {
      type: ['string', 'null'],
    },
    ProductDescription: {
      type: ['string', 'null'],
    },
    Amount: {
      type: ['number', 'null'],
    },
    UnitPrice: {
      type: ['number', 'null'],
    },
    ModelNumber: {
      type: ['string', 'null'],
    },
    Department: {
      type: ['string', 'null'],
    },
    Manufacturer: {
      type: ['string', 'null'],
    },
    Qty: {
      type: ['string', 'null'],
    },
    Book: {
      type: ['string', 'null'],
    },
    Page: {
      type: ['string', 'null'],
    },
    Figure: {
      type: ['string', 'null'],
    },
    ToInventory: {
      type: ['boolean', 'null'],
    },
    SearchMod: {
      type: ['string', 'null'],
    },
    SearchMaker: {
      type: ['string', 'null'],
    },
    SearchPart: {
      type: ['string', 'null'],
    },
    PartNumber: {
      type: ['string', 'null'],
    },
    AmtReceived: {
      type: ['number', 'null'],
    },
    EqDesc: {
      type: ['string', 'null'],
    },
    QCurrency: {
      type: ['string', 'null'],
    },
    QCurrencyAmt: {
      type: ['number', 'null'],
    },
    QtyRecd: {
      type: ['string', 'null'],
    },
    OrderComplete: {
      type: ['number', 'null'],
    },
    fldDataType: {
      type: ['string', 'null'],
    },
    fldSize: {
      type: ['string', 'null'],
    },
    fldColor: {
      type: ['string', 'null'],
    },
    DateEntered: {
      type: ['string', 'null'],
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    updatedAt: {
      type: 'string',
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
  },
  required: [],
  additionalProperties: false,
};

export default schema;
