import React, {
  FC, useEffect, useState,
} from 'react';
import {
  isNil, size, isString
} from 'lodash';
import { useForm } from 'react-hook-form';

import {
  Portal,
  Alert,
  Snackbar,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
} from '@mui/material';

import Input from 'src/components/UI/Forms/Input';
import Checkbox from 'src/components/UI/Forms/Checkbox';

import { TblShipsParticularsDocument } from 'src/api/queries/tblShipsParticulars/rxdb';
import { ExpandMore } from '@mui/icons-material';
import { TblDdListDefaultsDocument } from 'src/api/queries/tblDDListDefaults/rxdb';
import { toJSON } from 'src/utils';
import { getDatabase } from 'src/rxdb';
import Autocomplete from 'src/components/UI/Forms/Autocomplete';
import DatePicker from 'src/components/UI/Forms/DatePicker';
import { TblShipsParticulars } from '../../../../generated/graphql';
import { normalizeDateTime } from 'src/helpers';
import { isValidDateFormat, utcToTimeZone } from 'src/utils/format-dates';

interface Props {
  initialValue: TblShipsParticularsDocument;
  onSave: () => void;
  saveButtonStatus: (staus: boolean) => void;
  cancelClick: boolean;
}

const VesselEditForm: FC<Props> = ({ initialValue, onSave, saveButtonStatus, cancelClick }) => {
  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState,
  } = useForm<any>({
    defaultValues: {
      PKey: initialValue?.PKey,
      fldVesselName: initialValue?.fldVesselName,
      fldFlag: initialValue?.fldFlag,
      fldPortOfReg: initialValue?.fldPortOfReg,
      fldOfficialNum: initialValue?.fldOfficialNum,
      fldCallSign: initialValue?.fldCallSign,
      fldRegNum: initialValue?.fldRegNum,
      fldAAIC: initialValue?.fldAAIC,
      fldMMSI: initialValue?.fldMMSI,
      fldAgentAddress: initialValue?.fldAgentAddress,
      fldShippingAddress: initialValue?.fldShippingAddress,
      fldGrossTon: initialValue?.fldGrossTon,
      fldDepth: initialValue?.fldDepth,
      fldChainLenPort: initialValue?.fldChainLenPort,
      fldNet: initialValue?.fldNet,
      fldBeamMould: initialValue?.fldBeamMould,
      fldChainLenStbd: initialValue?.fldChainLenStbd,
      fldLOA: initialValue?.fldLOA,
      fldMaxDisplacement: initialValue?.fldMaxDisplacement,
      fldChainStern: initialValue?.fldChainStern,
      fldLWaterline: initialValue?.fldLWaterline,
      fldChainMeasure: initialValue?.fldChainMeasure,
      fldDeliveryDate: utcToTimeZone(initialValue?.fldDeliveryDate),
      fldEngineType: initialValue?.fldEngineType,
      fldDPAName: initialValue?.fldDPAName,
      fldDPACompany: initialValue?.fldDPACompany,
      fldDPAAddress: initialValue?.fldDPAAddress,
      fldDPAPhone: initialValue?.fldDPAPhone,
      fldDPAFax: initialValue?.fldDPAFax,
      fldDPAEmail: initialValue?.fldDPAEmail,
      fldSMSNoControl: initialValue?.fldSMSNoControl,
    },
  });

  const [chainUnit, setChainUnit] = useState<TblDdListDefaultsDocument[]>([]);
  const [vesselItem, setVesselItem] = useState<TblShipsParticulars>(initialValue?.toJSON());
  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const init = async () => {
    const db = await getDatabase();

    db.tblddlistdefaults.find({ selector: { deletedBy: { $eq: null } }}).$.subscribe((defaults) => {
      const chainUnits = defaults.filter((d) => d.fldListName === 'CHAINUNIT' && d.fldMember !== "");
      setChainUnit(toJSON(chainUnits));
    });
  };

  useEffect(() => {
    if (isNil(initialValue)) return;

    init();
  }, [initialValue]);

  const onChange = async (name: string, value: any) => {
    setValue(name, value, { shouldDirty: true });
  };

  const renderListDefaultBox = (
    items: TblDdListDefaultsDocument[],
    fieldName: string,
    fldListName: string,
    freeSolo = false,
  ) => {
    const sortedData = items?.sort((a: any, b: any) => a.fldMember.localeCompare(b.fldMember));

    return (
      <Autocomplete
        defaultPayload={{
          fldListName,
          fldT4Reqd: true,
        }}
        displayExpr="fldMember"
        keyExpr="PKey"
        onChange={onChange}
        freeSolo={freeSolo}
        options={sortedData}
        label={fldListName === 'CHAINUNIT' ? 'Chain Unit' : fldListName}
        control={control}
        name={fieldName}
        collection="tblddlistdefaults" // Use this in order to create new items.
      />
    );
  };

  const handleSave = async (data: any) => {
    const db = await getDatabase();

    const {
      PKey,
      fldVesselName,
      fldFlag,
      fldPortOfReg,
      fldOfficialNum,
      fldCallSign,
      fldRegNum,
      fldAAIC,
      fldMMSI,
      fldAgentAddress,
      fldShippingAddress,
      fldGrossTon,
      fldDepth,
      fldChainLenPort,
      fldNet,
      fldBeamMould,
      fldChainLenStbd,
      fldLOA,
      fldMaxDisplacement,
      fldChainStern,
      fldLWaterline,
      fldChainMeasure,
      fldDeliveryDate,
      fldEngineType,
      fldDPAName,
      fldDPACompany,
      fldDPAAddress,
      fldDPAPhone,
      fldDPAFax,
      fldDPAEmail,
      fldSMSNoControl,
    } = data;

    const document = {
      ...vesselItem,
      PKey,
      fldVesselName,
      fldFlag,
      fldPortOfReg,
      fldOfficialNum,
      fldCallSign,
      fldRegNum,
      fldAAIC,
      fldMMSI,
      fldAgentAddress,
      fldShippingAddress,
      fldGrossTon: parseInt(fldGrossTon) || null,
      fldDepth: parseFloat(fldDepth) || null,
      fldChainLenPort: parseInt(fldChainLenPort) || null,
      fldNet: parseInt(fldNet) || null,
      fldBeamMould: parseFloat(fldBeamMould) || null,
      fldChainLenStbd: parseInt(fldChainLenStbd) || null,
      fldLOA: parseFloat(fldLOA) || null,
      fldMaxDisplacement: parseInt(fldMaxDisplacement) || null,
      fldChainStern: parseInt(fldChainStern) || null,
      fldLWaterline: parseFloat(fldLWaterline) || null,
      fldChainMeasure: isString(fldChainMeasure)
        ? fldChainMeasure
        : fldChainMeasure?.fldMember || null,
      fldDeliveryDate: normalizeDateTime(fldDeliveryDate),
      fldEngineType,
      fldDPAName,
      fldDPACompany,
      fldDPAAddress,
      fldDPAPhone,
      fldDPAFax,
      fldDPAEmail,
      fldSMSNoControl,
    } as any;

    try {
      await db.tblshipsparticulars.upsert(document);
      setSnackbar({
        open: true,
        type: 'success',
        message: 'Vessel update success!',
      });
      onSave();
      reset(getValues());
    } catch (e: any) {
      setSnackbar({
        open: true,
        type: 'error',
        message: e.message,
      });
    }
  };

  useEffect(() => {
    if (cancelClick) {
      reset();
    };
  }, [cancelClick]);

  useEffect(() => {
    const hasValuesBeenChanged = formState.isDirty && (size(formState.dirtyFields) > 0 || size(formState.touchedFields) > 0);
    saveButtonStatus(hasValuesBeenChanged)
  }, [formState])

  return (
    <>
      <form
        id="Vessel-Edit-Form"
        className="relative bg-white flex-grow"
        onSubmit={handleSubmit(handleSave)}
      >
        <div className="bg-white h-full flex-grow pt-6">
          <div className="px-6 h-full">
            <Accordion defaultExpanded className="mt-3">
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="Vessel registration"
                id="vessel-registration"
              >
                Vessel Name and Registration
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={4} rowSpacing={2}>
                    {/* <Grid container item> */}
                    <Grid item xs={12}>
                      <div className="mui-textfield-header mb-2">
                        <Input
                          inputProps={{
                            label: 'Vessel Name',
                          }}
                          control={control}
                          name="fldVesselName"
                        />
                      </div>
                    </Grid>
                    {/* </Grid> */}
                    {/* <Grid container item spacing={4}> */}
                    <Grid item xs={12} sm={6}>
                      <div className="mui-textfield-header mb-2">
                        <Input
                          inputProps={{
                            label: 'Flag',
                          }}
                          control={control}
                          name="fldFlag"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="mui-textfield-header mb-2">
                        <Input
                          inputProps={{
                            label: 'Port',
                          }}
                          control={control}
                          name="fldPortOfReg"
                        />
                      </div>
                    </Grid>
                    {/* </Grid> */}
                    <Grid item xs={12} sm={4}>
                      <div className="mui-textfield-header mb-2">
                        <Input
                          inputProps={{
                            label: 'Official Number',
                          }}
                          control={control}
                          name="fldOfficialNum"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <div className="mui-textfield-header mb-2">
                        <Input
                          inputProps={{
                            label: 'Call Sign',
                          }}
                          control={control}
                          name="fldCallSign"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <div className="mui-textfield-header mb-2">
                        <Input
                          inputProps={{
                            label: 'IMO Number',
                          }}
                          control={control}
                          name="fldRegNum"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="mui-textfield-header mb-2">
                        <Input
                          inputProps={{
                            label: 'AAIC Number',
                          }}
                          control={control}
                          name="fldAAIC"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="mui-textfield-header mb-2">
                        <Input
                          inputProps={{
                            label: 'MMSI Number',
                            // inputProps:{
                            //     pattern:"/^[1-9]\d*(\d+)?$/i"
                            // }
                            // pattern: { value: /^[1-9]\d*(\d+)?$/i},
                          }}
                          control={control}
                          name="fldMMSI"
                        //   rules={{
                        //       pattern: {
                        //           value: /^[1-9]\d*(\d+)?$/i,
                        //       },
                        //   }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="mui-textfield-header mb-2">
                        <Input
                          inputProps={{
                            label: 'Agent Address',
                          }}
                          rows={5}
                          multiline={true}
                          control={control}
                          name="fldAgentAddress"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="mui-textfield-header mb-2">
                        <Input
                          inputProps={{
                            label: 'Shipping Address',
                          }}
                          rows={5}
                          multiline={true}
                          control={control}
                          name="fldShippingAddress"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion className="mt-3">
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="Vessel registration"
                id="vessel-registration"
              >
                Specifications
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={4} rowSpacing={2}>
                    {/* <Grid container item> */}
                    <Grid item xs={12} sm={4}>
                      <div className="mui-textfield-header mb-2">
                        <Input
                          inputProps={{
                            label: 'Gross Ton',
                            type: 'number',
                            inputProps: { style: { textAlign: 'end' } },
                          }}
                          control={control}
                          name="fldGrossTon"
                        />
                      </div>
                    </Grid>
                    {/* </Grid> */}
                    {/* <Grid container item spacing={4}> */}
                    <Grid item xs={12} sm={4}>
                      <div className="mui-textfield-header mb-2">
                        <Input
                          inputProps={{
                            label: 'Draught',
                            type: 'number',
                            inputProps: {
                              inputMode: 'decimal',
                              pattern: '([0-9]+)?[,\\.]?[0-9]*',
                              step: 0.25,
                              style: { textAlign: 'end' },
                            },
                          }}
                          control={control}
                          name="fldDepth"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <div className="mui-textfield-header mb-2">
                        <Input
                          inputProps={{
                            label: 'Chain Port',
                            type: 'number',
                            inputProps: { style: { textAlign: 'end' } },
                          }}
                          control={control}
                          name="fldChainLenPort"
                        />
                      </div>
                    </Grid>
                    {/* </Grid> */}
                    <Grid item xs={12} sm={4}>
                      <div className="mui-textfield-header mb-2">
                        <Input
                          inputProps={{
                            label: 'Net Tonnage',
                            type: 'number',
                            inputProps: { style: { textAlign: 'end' } },
                          }}
                          control={control}
                          name="fldNet"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <div className="mui-textfield-header mb-2">
                        <Input
                          inputProps={{
                            label: 'Beam Mould',
                            type: 'number',
                            inputProps: {
                              inputMode: 'decimal',
                              pattern: '([0-9]+)?[,\\.]?[0-9]*',
                              step: 0.25,
                              style: { textAlign: 'end' },
                            },
                          }}
                          control={control}
                          name="fldBeamMould"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <div className="mui-textfield-header mb-2">
                        <Input
                          inputProps={{
                            label: 'Chain Starboard',
                            type: 'number',
                            inputProps: { style: { textAlign: 'end' } },
                          }}
                          control={control}
                          name="fldChainLenStbd"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <div className="mui-textfield-header mb-2">
                        <Input
                          inputProps={{
                            label: 'L.O.A.',
                            type: 'number',
                            inputProps: {
                              inputMode: 'decimal',
                              pattern: '([0-9]+)?[,\\.]?[0-9]*',
                              step: 0.25,
                              style: { textAlign: 'end' }
                            },
                          }}
                          control={control}
                          name="fldLOA"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <div className="mui-textfield-header mb-2">
                        <Input
                          inputProps={{
                            label: 'Displacement',
                            type: 'number',
                            inputProps: { style: { textAlign: 'end' } },
                          }}
                          control={control}
                          name="fldMaxDisplacement"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <div className="mui-textfield-header mb-2">
                        <Input
                          inputProps={{
                            label: 'Chain Stern',
                            type: 'number',
                            inputProps: { style: { textAlign: 'end' } },
                          }}
                          control={control}
                          name="fldChainStern"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="mui-textfield-header mb-2">
                        <Input
                          inputProps={{
                            label: 'Length',
                            type: 'number',
                            inputProps: {
                              inputMode: 'decimal',
                              pattern: '([0-9]+)?[,\\.]?[0-9]*',
                              step: 0.25,
                              style: { textAlign: 'end' }
                            },
                          }}
                          control={control}
                          name="fldLWaterline"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="mui-textfield-header mb-2">
                        {renderListDefaultBox(
                          chainUnit,
                          'fldChainMeasure',
                          'CHAINUNIT',
                          true
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="mui-textfield-header mb-2">
                        <Box className="bg-transparent">
                          <DatePicker
                            name="fldDeliveryDate"
                            control={control}
                            label="Date Vessel Put In Service"
                            rules={{
                              validate: (value) => isValidDateFormat(value) || 'Please enter a valid date format dd-MMM-yyyy',
                            }}
                          />
                        </Box>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="mui-textfield-header mb-2">
                        <Input
                          inputProps={{
                            label: 'Propulsion / Type',
                          }}
                          control={control}
                          name="fldEngineType"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion className="mt-3">
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="Vessel registration"
                id="vessel-registration"
              >
                SMS Agent / Shoreside support
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={4} rowSpacing={2}>
                    {/* <Grid container item> */}
                    <Grid item xs={12}>
                      <div className="mui-textfield-header mb-2">
                        <Input
                          inputProps={{
                            label: 'DPA Name',
                          }}
                          control={control}
                          name="fldDPAName"
                        />
                      </div>
                    </Grid>
                    {/* </Grid> */}
                    {/* <Grid container item spacing={4}> */}
                    <Grid item xs={12} sm={6}>
                      <div className="mui-textfield-header mb-2">
                        <Input
                          inputProps={{
                            label: 'DPA Company',
                          }}
                          control={control}
                          name="fldDPACompany"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="mui-textfield-header mb-2">
                        <Input
                          inputProps={{
                            label: 'DPA Email',
                          }}
                          control={control}
                          name="fldDPAEmail"
                        />
                      </div>
                    </Grid>
                    {/* </Grid> */}
                    <Grid item xs={12} sm={6}>
                      <div className="mui-textfield-header mb-2">
                        <Input
                          inputProps={{
                            label: 'DPA Phone Number',
                          }}
                          control={control}
                          name="fldDPAPhone"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="mui-textfield-header mb-2">
                        <Input
                          inputProps={{
                            label: 'DPA Fax',
                          }}
                          control={control}
                          name="fldDPAFax"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="mui-textfield-header mb-2">
                        <Input
                          inputProps={{
                            label: 'DAP Address',
                            rows: 3,
                            multiline: true,
                          }}
                          control={control}
                          name="fldDPAAddress"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="mui-textfield-header mb-2">
                        <Checkbox
                          control={control}
                          name="fldSMSNoControl"
                          label="Triton forms are NOT controlled documents"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <Portal>
          <Snackbar
            open={snackBar.open}
            autoHideDuration={2000}
            onClose={onSnackbarClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
              {snackBar.message}
            </Alert>
          </Snackbar>
        </Portal>
      </form>
    </>
  );
};

export default VesselEditForm;
