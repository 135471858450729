import { DateInputProps } from '@mui/lab/internal/pickers/PureDateInput';
import { ConcatOperator } from 'igniteui-react-excel';
import moment from 'moment'
import { getNthDayInMonth, getLastDayOfMonth as getLastDayOfMonthCalc } from 'src/components/dataentry/EquipmentSchedulesDE/ScheduleComponent/MonthlySchedule';
import { getNthDayInWeek } from 'src/components/dataentry/EquipmentSchedulesDE/ScheduleComponent/WeeklySchedule';
import { timeZoneToUTC } from '../format-dates';

// Pattern 62 && 64: Both of these patterns simply add a number of days to the logged completed date
export function getDailySimple(recurrencePattern: string, startDate: Date) {
    if (recurrencePattern !== null &&  Number.isInteger(parseInt(recurrencePattern, 10)) && parseInt(recurrencePattern, 10) <= 0) {
        // Code to execute if recurrencepattern is null, not a number, or not a valid integer > 0
        throw new Error(`Invalid <recurrencePattern> Argument: The recurrence pattern must be 15, provided ${recurrencePattern}`);
      }
    const recur = parseInt(recurrencePattern);
    return moment(startDate).add(recur, 'days').toDate();

}

// Pattern 63:
export function getDailyEveryWeekday(recurrencePattern: string, startDate: Date, today: Date, nextDue: Date) : Date {
    if (recurrencePattern != '2,3,4,5,6')
        throw new Error("Invalid <recurrencePattern> Argument: The recurrence pattern must be '2,3,4,5,6'.");
        
    let dayOfMonth = startDate.getUTCDate();
    let startDateDay : number = startDate.getUTCDay();
    
    switch (startDateDay) {
        case 5: // Friday, must add 3 days to get to the next weekday
            startDate.setUTCDate(dayOfMonth + 3);
            break;
        case 6: // Saturday, must add 2 days to get to the next weekeday
            startDate.setUTCDate(dayOfMonth + 2);
            break;
        default:
            startDate.setUTCDate(dayOfMonth + 1);
            break;
    }

    nextDue = startDate;
    return nextDue;
}

// Pattern 48:
export function getWeeklyEveryXWeeksOnYDays(recurrencePattern: string, logDate: Date, lastTrigger: Date): Date {
    let paramsList = recurrencePattern.split(",").map(Number);
    let weeksBetween: number = paramsList[0];
    let selectedDays: number[] = paramsList.slice(1).sort();
    let isErr: boolean = false;

    try {
  
    if (!Array.isArray(selectedDays) || selectedDays.length === 0) {
        isErr = true;
        throw new Error("Invalid selectedDays parameter");
    }
  
    if (weeksBetween < 1) {
        isErr = true;
        throw new Error("Invalid weekly interval, must be > 1");
    }

    selectedDays.map(value => {
        if (value < 1 || value > 7) {
            isErr = true;
            throw new Error("Invalid <recurrencePattern> Argument: DAY OF THE WEEK must be between 1 and 7.");
        }
        })


    console.log(`logDate: ${logDate}, lastTrigger: ${lastTrigger}`);
    if (logDate < new Date(lastTrigger)) {
      logDate = new Date(lastTrigger);
    }
  
    const uniqueDays = selectedDays.filter((day, index) => selectedDays.indexOf(day) === index);
    const validDays = [1, 2, 3, 4, 5, 6, 7];
    if (uniqueDays.length !== selectedDays.length || !uniqueDays.every(day => validDays.includes(day))) {
        isErr =true;
        throw new Error("Invalid selectedDays parameter");
    }
  
    if (!Number.isInteger(weeksBetween) || weeksBetween < 1 || weeksBetween > 52) {
        isErr = true;
        throw new Error("Invalid weeksBetween parameter");
    }
  
    const logDayNumber = logDate.getUTCDay();
  
    let nextDayNumber: number | undefined = validDays.find(day => day > logDayNumber && selectedDays.includes(day));
  
    if (nextDayNumber === undefined) {
      // Handle the case when no selected day is later in the week
      const nextWeek = Math.ceil((logDayNumber - selectedDays[0]) / 7) + 1;
      nextDayNumber = selectedDays[0];
      const daysToAdd = (nextWeek - 1) * 7 + (nextDayNumber - logDayNumber);
      logDate.setUTCDate(logDate.getUTCDate() + daysToAdd);
    } else {
      const daysToAdd = (nextDayNumber - logDayNumber);
      logDate.setUTCDate(logDate.getUTCDate() + daysToAdd);
    }
    const nextDue = getNthDayInWeek(new Date(logDate), selectedDays[0]-1)
    // const nextDue = new Date(logDate);
    // nextDue.setUTCDate(nextDue.getUTCDate() + (7 * (weeksBetween - 1)));
    return nextDue;
}
catch (error) {
    console.log(`An error occurred: ${error}`);
    return lastTrigger;
}
  }  

// Pattern 56: This function works off the last trigger date regardless of when it is completed.  It then advances to the next 
// time by incrementing the last time
export function getMonthlyDayXOfEveryYMonths(recurrencePattern: string, 
    nextDue: Date, 
    lastTrigger: Date) : Date {
    let paramsList = recurrencePattern.split(",").map(Number);
    let dayOfMonth: number = paramsList[0]; // From 1 to 31 are represented the days of the month.
    let numberOfMonths: number = paramsList[1]; // Greater than 0 are represented the number of months to increment.
    let monthOfYear: number = timeZoneToUTC(new Date(lastTrigger)).getUTCMonth(); // This function will sets months as 0 based array. You MUST add 1 to account for this
    let year: number = timeZoneToUTC(new Date(lastTrigger)).getUTCFullYear();
    let lastDayOfMonth = getLastDayOfMonth(year, monthOfYear);  // this function adds 1 month when calculating to account for the 0 based array

    // console.log(`lastTrigger: ${lastTrigger}, startDate: ${startDate}, dayOfMonth: ${dayOfMonth}, numberOfMonths: ${numberOfMonths}, monthOfYear: ${monthOfYear}, year: ${year}, lastDayOfMonth ${lastDayOfMonth}`)

    // Arguments validation.
    if (dayOfMonth < 1 || dayOfMonth > 31)
        throw new Error(`Invalid <recurrencePattern> Argument: 1st value - DAY OF THE MONTH must be between 1 and 31.`);
    if (dayOfMonth > 28 && lastDayOfMonth <= 30)
        console.log(`Some months have fewer than ${dayOfMonth}. For these months, the occurrence will fall on the last day of the month.`);
    if (numberOfMonths < 1)
        throw new Error("Invalid <recurrencePattern> Argument: 2nd value - NUMBER OF MONTHS increment must not be less than 1.");

    // Validate 29th to 31st days of the month.
    if (dayOfMonth > lastDayOfMonth) dayOfMonth = lastDayOfMonth;

    // Initialize the Date of the recurrence to happen on a specific day every x months from <recurrencePattern>.
    nextDue = setRecurrenceDate(nextDue, year, monthOfYear + 1, dayOfMonth);

    // Reset time on startDate for accurate comparisson with nextRecurrence.
    // startDate.setUTCHours(0,0,0,0); // No need to reference startDate

    // If next calculated recurrence is in the past then increment monthOfYear with the numberOfMonths and calculate again.
    // This schedule should simply add to the last trigger instance.
    // console.log(`nextDue set to: ${nextDue}, lastTrigger: ${new Date(lastTrigger)}`);
    let tempDate = nextDue;
    monthOfYear = (monthOfYear + numberOfMonths) % 12; // get the month of the recurrence to happen
    tempDate.setUTCMonth(nextDue.getUTCMonth() + numberOfMonths); // set temporary date in order to get the year of the recurrence to happen
    year = tempDate.getUTCFullYear();
    lastDayOfMonth = getLastDayOfMonth(year, monthOfYear); // get last day of the month for the recurrence to happen
    if (dayOfMonth > lastDayOfMonth) dayOfMonth = lastDayOfMonth; // if dayOfMonth parameter is greater than last day of month will set it to the last day.
    nextDue = setRecurrenceDate(nextDue, year, monthOfYear + 1, dayOfMonth);

    return nextDue;
}

// Pattern 51: Calculated off of lastTrigger date to advance to next iteration
export function getYearlyEachXMonthYDay(recurrencePattern: string,  nextDue: Date, lastTrigger: Date) : Date {
    let paramsList = recurrencePattern.split(",").map(Number);
    let monthOfYear: number = paramsList[0]; // From 1 to 12 are represented the months of the year.
    let dayOfMonth: number = paramsList[1]; // From 1 to 31 are represented the days of the month.
    let year: number = new Date(lastTrigger).getUTCFullYear() ?? new Date().getUTCFullYear();

    // Arguments validation after the extraction from the <recurrencePattern> string.
    if (monthOfYear < 1 || monthOfYear > 12)
        throw new Error("Invalid <recurrencePattern> Argument: 1st value - MONTH OF THE YEAR must be between 0 and 11.");

    // Months of the year with 30 days
    if ([4, 6, 9, 11].includes(monthOfYear)) {
        if (dayOfMonth < 1 || dayOfMonth > 30)
            throw new Error(`Invalid <recurrencePattern> Argument: 2nd value - DAY OF THE MONTH must be between 1 and 30.`);
    }

    // Months of the year with 31 days
    if ([1, 3, 5, 7, 8, 10, 12].includes(monthOfYear)) {
        if (dayOfMonth < 1 || dayOfMonth > 31)
            throw new Error(`Invalid <recurrencePattern> Argument: 2nd value - DAY OF THE MONTH must be between 1 and 31.`);
    }

    if (monthOfYear == 2) {
        if (dayOfMonth < 1 || dayOfMonth > 29)
            throw new Error(`Invalid <recurrencePattern> Argument: 2nd value - DAY OF THE MONTH must be between 1 and 29.`);
    }

    // Initialize the Date of the recurrence to happen with once a year on specific day and month from <recurrencePattern>.
    nextDue = setRecurrenceDate(nextDue, year, monthOfYear , dayOfMonth);

    // If next calculated recurrence is in the past then increment year.
    if (nextDue <= new Date(lastTrigger))
        nextDue.setUTCFullYear(year + 1);

    // After recurrence date setup catch if is not leap year which means that February has only 28 days.
    if (monthOfYear == 2 && !isLeapYear(nextDue.getUTCFullYear())) {
        if (dayOfMonth < 1 || dayOfMonth > 28)
            throw new Error(`Invalid <recurrencePattern> Argument: 2nd value - DAY OF THE MONTH must be between 1 and 28.`);
    }

    return nextDue;
}

// Pattern 55: 
export function getMonthlyTheOrdinalDayEveryXMonths(recurrencePattern: string, startDate: Date, today: Date, nextDue: Date) : Date {
    // Retrieve the arguments from the <recurrencePattern> string:
    let paramsList = recurrencePattern.split(",").map(Number);            
    let ordinalNumber: number = paramsList[0]; // From 0 to 4 are represented the ordinal numbers first, second, third, fourth or last.
    let dayOfWeek: number = paramsList[1]; // From 1 to 7 are represented the days of the week starting from Sunday.
    let numberOfMonths: number = paramsList[2]; // Greater than 0 are represented the number of months to increment.

    // Arguments validation after the extraction from the <recurrencePattern> string.
    if (ordinalNumber < 0 || ordinalNumber > 4)
        throw new Error(`Invalid <recurrencePattern> Argument: 1st value - ORDINAL NUMBER must be between 1 and 5.`);
    if (dayOfWeek < 1 || dayOfWeek > 7)
        throw new Error(`Invalid <recurrencePattern> Argument: 2nd value - DAY OF THE WEEK must be between 0 and 6.`);
    if (numberOfMonths < 1)
        throw new Error("Invalid <recurrencePattern> Argument: 3rd value - NUMBER OF MONTHS increment must not be less than 1.");

    startDate.setMonth(startDate.getMonth() + numberOfMonths);
    if (ordinalNumber === 4) {
        startDate = getLastDayOfMonthCalc(startDate, dayOfWeek - 1);
    }
    else {
        startDate = getNthDayInMonth(startDate, ordinalNumber + 1, dayOfWeek - 1); 
    }
    
    return startDate;
}

// Pattern 50: This uses the last trigger date to calculate the next occurrence for this pattern
export function getYearlyTheOrdinalDayOfMonth(recurrencePattern: string,  nextDue: Date, lastTrigger:Date) : Date {
    // Retrieve the arguments from the <recurrencePattern> string:
    let paramsList = recurrencePattern.split(",").map(Number);            
    let ordinalNumber: number = paramsList[0]; // From 0 to 4 are represented the ordinal numbers first, second, third, fourth or last.
    let dayOfWeek: number = paramsList[1]; // From 1 to 7 are represented the days of the week starting from Sunday.
    let monthOfYear: number = paramsList[2]; // From 1 to 12 are represented the months of the year.

    // Arguments validation after the extraction from the <recurrencePattern> string.
    if (ordinalNumber < 0 || ordinalNumber > 4)
        throw new Error(`Invalid <recurrencePattern> Argument: 1st value - ORDINAL NUMBER must be between 1 and 5.`);
    if (dayOfWeek < 1 || dayOfWeek > 7)
        throw new Error(`Invalid <recurrencePattern> Argument: 2nd value - DAY OF THE WEEK must be between 0 and 6.`);
    if (monthOfYear < 1 || monthOfYear > 12)
        throw new Error("Invalid <recurrencePattern> Argument: 3rd value - MONTH OF THE YEAR must be between 1 and 12");

    // Get the year of the recurrence based on <startDate> if present otherwise use the year of today's Date
    let year: number = new Date(lastTrigger)?.getUTCFullYear() ?? new Date().getUTCFullYear();

    // Set next recurrence
    // get the first day of the month of the pattern
    nextDue = new Date(year, monthOfYear-1);  // this function expects a 0 through 11 value for months where 0 = January
    nextDue = getOrdinalDate(nextDue,ordinalNumber + 1,dayOfWeek -1);

    // If next calculated recurrence is in the past then increment year and calculate again.
    if (nextDue <= new Date(lastTrigger)) {
        console.log(`We got to line 222`);
        nextDue = new Date(year + 1, monthOfYear-1);
        nextDue = getOrdinalDate(nextDue,ordinalNumber + 1,dayOfWeek - 1);
    }

    return nextDue;
}

export const getOrdinalDate = (date: Date, nth: number, day: number): Date => {
    let nextRecurDate = new Date(date.getFullYear(), date.getMonth());
    nextRecurDate.setDate(1 + (7 - nextRecurDate.getDay() + day) % 7 + (nth - 1) * 7);
    return nextRecurDate;
}

function isLeapYear(year: number) {
    return new Date(year, 1, 29).getDate() === 29;
}

function setRecurrenceDate(nextDue: Date, year: number, monthOfYear: number, dayOfMonth: number) : Date{
    return new Date(year.toString() + '-' + monthOfYear.toString().padStart(2, '0') + '-' + dayOfMonth.toString().padStart(2, '0') + 'T00:00:00.00');
}

function getLastDayOfMonth(year: number, month: number) : number {
    return new Date(year, month + 1, 0).getDate();
}