import { RxCollection, RxJsonSchema, RxDocument } from 'rxdb';
import { TblSchedMaintParts } from '../../../generated/graphql';

export type tblSchedMaintPartsType = TblSchedMaintParts;

export type tblSchedMaintPartsDocMethods = {};

export type TblSchedMaintPartsDocument = RxDocument<
  tblSchedMaintPartsType,
  tblSchedMaintPartsDocMethods
  >;

export type TblSchedMaintPartsCollectionMethods = {};

export type TblSchedMaintPartsCollection = RxCollection<
  tblSchedMaintPartsType,
  tblSchedMaintPartsDocMethods,
  TblSchedMaintPartsCollectionMethods
  >;

export const schema: RxJsonSchema<tblSchedMaintPartsType> = {
  title: 'Sched Maint Parts Collection',
  description: 'Sched Maint Parts',
  version: 0,
  keyCompression: false,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    DateEntered: {
      type: ['string', 'null'],
    },
    EQKey: {
      type: ['string', 'null'],
      ref: 'equipment',
    },
    fldSchedMaintKey: {
      type: 'string',
      ref: 'tblschedmaint',
    },
    fldPartName: {
      type: ['string', 'null'],
    },
    Amount: {
      type: ['number', 'null'],
    },
    PKey: {
      type: 'string',
      primary: true,
    },
    fldPartNumber: {
      type: ['string', 'null'],
    },
    Qty: {
      type: ['string', 'null'],
    },
    ProductID: {
      type: ['string', 'null'],
      ref: 'inventory',
    },
    ModelNumber: {
      type: ['string', 'null'],
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
    updatedAt: {
      type: 'string',
    },
  },
  required: [],
  additionalProperties: false,
};

export default schema;
