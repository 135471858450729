import { omit } from 'lodash';
import { MultipleLocations } from '../../../generated/graphql';
import {logger} from "../../../helpers/logger";

const fields = `
  Amount
  DateEntered
  PKey
  ProductID
  SerialNum
  UseBy
  fldDefault
  fldLocHierarchy
  fldMaxHldgLoc
  deletedAt
  deletedBy
  isRecoverable
  updatedAt
  autoId
`;

export const multipleLocationsPullQuery = (doc: MultipleLocations) => {
  if (!doc) {
    doc = {
      autoId: 0,
      PKey: '',
      ProductID: '',
      updatedAt: new Date(0).toISOString(),
    };
  }
  logger('MultipleLocations').info(
    `pull query triggered - MultipleLocations: { ${new Date().toISOString()} - ID: ${doc.autoId}}`,
  );
  return {
    query: `
        query getMultipleLocations($autoId: Int, $filterDate: Date) {
          MultipleLocations(
            where:{
              _and: [
                {
                  _or: [
                    {
                      isRecoverable: {
                        _is_null: true
                      }
                    },
                    {
                      isRecoverable: {
                        _eq: true
                      }
                    }
                  ]
                },
                {
                  _or: [
                    {
                      updatedAt: {_gt: $filterDate},                
                    },
                    {
                      autoId: {_gt: $autoId}
                      updatedAt: {_gte: $filterDate},
                    }
                  ]
                }
              ]                                    
            },
            order_by: [
              {updatedAt: asc},
              {autoId: asc}
            ],
            limit: 1000
          ) {
            ${fields}
          }
        }
      `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
    },
  };
};

export const multipleLocationsMutation = (doc: any) => {
  const query = `
    mutation upsertMultipleLocation($input: MultipleLocationInput!) {
      upsertMultipleLocation(input: $input) {
        ${fields}
      }
    }
  `;

  const variables = {
    input: omit(doc, ['updatedAt', 'deletedAt']),
  };

  return {
    query,
    variables,
  };
};

export const multipleLocationsSubscription = `
  subscription onMultipleLocationsChanged {
    MultipleLocations {
      ${fields}
    }
  }
`;
