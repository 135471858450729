import { omit } from 'lodash';
import { Equipment } from '../../generated/graphql';
import {logger} from "../../helpers/logger";

const fields = `
autoId
EqKey
UniqueName
Name
fldHoursKey
Hours
fldParent
Amount
Manufacturer
ModelNumber
Rating
PowerRequired
Notes
Voltage
Weight
WeightQty
Department
Qty
SearchMod
SearchMaker
SerialNum
ArtNumber
ArrangementNum
DrawingRef
CircuitNumber
Breaker
DistBoard
Supplier
fldCountHours
fldHoursOffset
fldDateReset
fldSMS
fldLocHierarchy
fldExpireDate
fldDay
fldMonth
fldYear
fldRestricted
fldHrsEst
fldInService
fldReportingType
fldSRHKey
deletedAt
updatedAt
deletedBy
isRecoverable
`;

export const equipmentQuery = (doc: Equipment) => {
  if (!doc) {
    doc = {
      autoId: 0,
      EqKey: '',
      UniqueName: '',
      updatedAt: new Date(0).toISOString(),
    };
  }
  logger('Equipment').info(
    `pull query triggered - Equipment: { ${new Date().toISOString()} - primaryKey: ${doc.EqKey}, updatedAt: ${doc.updatedAt} }`,
  );
  return {
    query: `
        query GetLatestEquipment($autoId: Int, $filterDate: Date) {
          Equipment(
            where:{
              _and: [
                {
                  _or: [
                    {
                      isRecoverable: {
                        _is_null: true
                      }
                    },
                    {
                      isRecoverable: {
                        _eq: true
                      }
                    }
                  ]
                },
                {
                  _or: [
                    {
                      updatedAt: {_gt: $filterDate},                
                    },
                    {
                      autoId: {_gt: $autoId}
                      updatedAt: {_gte: $filterDate},
                    }
                  ]
                }
              ]                                    
            },
            order_by: [
              {updatedAt: asc},
              {autoId: asc}],
              limit: 1000
          ) {
            ${fields}            
          }
        }
      `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
    },
  };
};

export const equipmentMutation = (doc: any) => {
  const query = `
    mutation UpsertEquipment($input: EquipmentInput!) {
      upsertEquipment(input: $input) {
        ${fields}
      }
    }
  `;

  const variables = {
    input: omit(doc, ['updatedAt', 'deletedAt', 'accessDate']),
  };

  return {
    query,
    variables,
  };
};

export const equipmentSubscription = `
  subscription onEquipmentChanged {
    Equipment {
      ${fields}
    }
  }
`;
