import { omit } from 'lodash';
import { TblDdListDefaults } from '../../../generated/graphql';
import {logger} from "../../../helpers/logger";

const fields = `
  autoId
  PKey
  fldListName
  fldMember
  fldT4Reqd
  fldIndex
  deletedAt
  deletedBy
  isRecoverable
  updatedAt
`;

export const tblDDListDefaultsQuery = (doc: TblDdListDefaults) => {
  if (!doc) {
    doc = {
      autoId: 0,
      PKey: '',
      fldT4Reqd: false,
      updatedAt: new Date(0).toISOString(),
    };
  }
  logger('TblDdListDefaults').info(
    `pull query triggered - TblDdListDefaults: { ${new Date().toISOString()} - ID: ${doc.autoId}, updatedAt: ${doc.updatedAt} }`,
  );
  return {
    query: `
        query GetLatestTblDDLListDefaults($autoId: Int, $filterDate: Date) {
          tblDDListDefaults(
            where:{
              _and: [
                {
                  _or: [
                    {
                      isRecoverable: {
                        _is_null: true
                      }
                    },
                    {
                      isRecoverable: {
                        _eq: true
                      }
                    }
                  ]
                },
                {
                  _or: [
                    {
                      updatedAt: {_gt: $filterDate},                
                    },
                    {
                      autoId: {_gt: $autoId}
                      updatedAt: {_gte: $filterDate},
                    }
                  ]
                }
              ]                                    
            },
            order_by: [
              {updatedAt: asc},
              {autoId: asc}
            ],
            limit: 500
          ) {
            ${fields}
          }
        }
      `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
    },
  };
};

export const tblDDListDefaultMutation = (doc: any) => {
  const query = `
    mutation upsertListDefault($input: ListDefaultInput!) {
      upsertListDefault(input: $input) {
        ${fields}
      }
    }
  `;

  const variables = {
    input: omit(doc, ['deletedAt']),
  };

  return {
    query,
    variables,
  };
};

export const tblDDListDefaultsSubscription = `
  subscription onTblDDListDefaultsChanged {
    tblDDListDefaults {
      ${fields}
    }
  }
`;
