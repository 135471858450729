import { FC, HTMLAttributes } from 'react';
import { Control } from 'react-hook-form';
import Autocomplete from '../../UI/Forms/Autocomplete';
import { useGetCurrencies } from 'src/hooks/currencies/useGetCurrencies';

type CurrenciesProps = {
  onChange: (field: string, value: any) => void;
  placeholder?: string;
  control: Control;
  label?: string;
  name?: string;
};

const CurrenciesDropdown: FC<CurrenciesProps> = ({
  placeholder,
  control,
  label,
  onChange,
  name,
}) => {
  const { data, loading } = useGetCurrencies();

  const renderOption = (
    props: HTMLAttributes<HTMLLIElement>,
    option: any,
  ) => {
    const hasAbbreviation = !!option.abbreviation;
    return (
      <li {...props} key={props.id}>
        {option.abbreviation}
        {' '}
        {`${hasAbbreviation ? '(' : ''} ${option.curr} ${
          hasAbbreviation ? ')' : ''
        }`}
      </li>
    );
  };

  return (
    <Autocomplete
      renderOption={renderOption}
      freeSolo
      onChange={onChange}
      loading={loading}
      label={label}
      placeholder={placeholder}
      options={data}
      control={control}
      name={name || 'Currency'}
      keyExpr=""
      displayExpr="curr"
    />
  );
};

export default CurrenciesDropdown;
