import { ApolloError, gql, useMutation } from '@apollo/client';
import { ModifyCommentInput, RecordType } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { CommentMutationResponse } from './useAddCommentsForRecord';

export const UPDATE_COMMENT = gql`
  mutation UpdateComment($input: ModifyCommentInput!) {
    modifyComment(input: $input) {
      comment {
        id
        authorId
        author {
          fldFirst
          fldLast
        }
        createdAt
        content
        parentId
        recordId
        recordType
        replies {
          id
          authorId
          author {
            fldFirst
            fldLast
          }
          createdAt
          content
          parentId
          recordId
          recordType
        }
      }
    }
  }
`;

interface UpdateCommentResult {
  updateComment: (input: ModifyCommentInput) => Promise<CommentMutationResponse>;
  updateCommentLoading: boolean;
  updateCommentError?: ApolloError;
}

export const useUpdateComment = (recordId: string, recordType: RecordType, isReply: boolean): UpdateCommentResult => {
  const [modifyComment, { loading, error }] = useMutation(UPDATE_COMMENT, {
    onError: (error: ApolloError) => {
      logger('UpdateComment').error(`Error updating comment for ${recordType}: ${recordId} -->`, error.message);
    }
  });

  const updateComment = async (input: ModifyCommentInput): Promise<CommentMutationResponse> => {
    const response = await modifyComment({ variables: { input } });
    const responseData = response.data?.modifyComment?.comment;
    if (responseData) {
      logger('UpdateComment').info('Comment updated successfully', response.data);
      return {
        responseData,
        responseMessage: `${isReply ? 'Reply' : 'Comment'} updated successfully!`,
      }
    } else {
      return {
        responseMessage: `Failed to update ${isReply ? 'reply' : 'comment'}!`,
      };
    }
  };

  return { 
    updateComment, 
    updateCommentLoading: loading, 
    updateCommentError: error,
  };
};