import { RxCollection, RxJsonSchema, RxDocument } from 'rxdb';
import { TblDocumentCrossReference } from '../../../generated/graphql';

export type TblDocumentCrossReferenceDocType = TblDocumentCrossReference;

export type TblDocumentCrossReferenceDocMethods = {};

export type TblDocumentCrossReferenceDocument = RxDocument<
  TblDocumentCrossReferenceDocType,
  TblDocumentCrossReferenceDocMethods
>;

export type TblDocumentCrossReferenceCollectionMethods = {};

export type TblDocumentCrossReferenceCollection = RxCollection<
  TblDocumentCrossReferenceDocType,
  TblDocumentCrossReferenceDocMethods,
  TblDocumentCrossReferenceCollectionMethods
>;

export const tblDocumentCrossReferenceSchema: RxJsonSchema<TblDocumentCrossReferenceDocType> = {
  title: 'TblDocumentCrossReference ',
  description: 'describes a document item',
  version: 1,
  keyCompression: false,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    PKey: {
      type: 'string',
      primary: true,
    },
    DateEntered: {
      type: ['string', 'null'],
    },
    fldFKey: {
      type: ['string', 'null'],
    },
    fldFigure: {
      type: ['string', 'null'],
    },
    fldIsFavorite: {
      type: ['boolean', 'null'],
    },
    fldPage: {
      type: ['string', 'null'],
    },
    fldPageNum: {
      type: ['string', 'null'],
    },
    fldRecordKey: {
      type: ['string', 'null'],
    },
    fldTableName: {
      type: ['string', 'null'],
    },
    fldVolume: {
      type: ['string', 'null'],
    },
    tblDocumentation: {
      type: ['object', 'null'],
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
    updatedAt: {
      type: 'string',
    },
  },
  required: [],
  additionalProperties: false,
};

export const tblDocumentCrossReferenceDocMethods: TblDocumentCrossReferenceDocMethods = {};

export const tblDocumentCrossReferenceCollectionMethods: TblDocumentCrossReferenceCollectionMethods = {};
