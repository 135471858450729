import { omit } from 'lodash';
import { CrewProfiles, TblHrsOfRest } from '../../../generated/graphql';
import {logger} from "../../../helpers/logger";

const fields = `
  PKey
  fldDate
  fldCrewID
  fldData
  fldComment
  fldVerifiedBy
  deletedBy
  isRecoverable
  deletedAt
  updatedAt
  autoId
`;

export const hrsOfRestPullQuery = (user: CrewProfiles | null) => (doc: TblHrsOfRest) => {
  if (!doc) {
    doc = {
      autoId: 0,
      PKey: '',
      fldCrewID: '',
      updatedAt: new Date(0).toISOString(),
    };
  }
  logger('HoursOfRest').info(
    `pull query triggered - HoursOfRest:  { ${new Date().toISOString()} - ID: ${doc.autoId}, fldCrewId: ${user?.fldCrewID}`,
  );
  return {
    query: `
    query getHrsOfRest($autoId: Int, $filterDate: Date) {
       tblHrsOfRest(
        where:{ 
          _and: [
            {
              _or: [
                {
                  isRecoverable: {
                    _is_null: true
                  }
                },
                {
                  isRecoverable: {
                    _eq: true
                  }
                }
              ]
            },
            {
              _or: [
                {
                  updatedAt: {_gt: $filterDate},                
                },
                {
                  autoId: {_gt: $autoId}
                  updatedAt: {_gte: $filterDate},
                }
              ]
            }
          ]                                    
        },
        order_by: [
              {updatedAt: asc},
              {autoId: asc}]
      ) {
          ${fields} 
      }
   }
      `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
    },
  };
};

export const hrsOfRestPullMutation = (doc: any) => {
  const query = `
        mutation upsertHoursOfRest($input: HoursOfRestInput!) {
          upsertHoursOfRest(input: $input) {
            ${fields}
          }
        }
    `;
  const variables = {
    input: omit(doc, ['deletedAt']),
  };

  return {
    query,
    variables,
  };
};

export const hrsOfRestSubscription = `
  subscription onHrsOfRestChanged {
    tblHrsOfRest {
      ${fields}    
    }
  }
  `
