import { omit } from 'lodash';
import { TblDocumentRevisions } from '../../../generated/graphql';
import {logger} from "../../../helpers/logger";

const fields = `
    fldAltPath
    PKey
    DateEntered
    fldApprovedByPE
    fldApprovedByPM
    fldBinderIndex
    fldBinderLocation
    fldFKey
    fldFileName
    fldFileType
    fldLocHierarchy
    fldNotes
    fldPage
    fldStatus
    fldRevisionDate
    fldRevision
    deletedBy
    isRecoverable
    deletedAt
    updatedAt
    autoId
`;

export const documentRevisionPullQuery = (doc: TblDocumentRevisions) => {
  if (!doc) {
    doc = {
      autoId: 0,
      fldFKey: '',
      tblDocumentation: {
        autoId: 0,
        PKey: '',
        DocTitle: '',
        updatedAt: new Date(0).toISOString(),
      },
      PKey: '',
      updatedAt: new Date(0).toISOString(),
    };
  }
  logger('Document Revisions').info(
    `pull query triggered - Document Revisions:  { ${new Date().toISOString()} - ID: ${doc.autoId}, updatedAt: ${doc.updatedAt} }`,
  );
  return {
    query: `
        query getDocumentRevisions($autoId: Int, $filterDate: Date) {
          tblDocumentRevisions(
            where:{
              _and: [
                {
                  _or: [
                    {
                      isRecoverable: {
                        _is_null: true
                      }
                    },
                    {
                      isRecoverable: {
                        _eq: true
                      }
                    }
                  ]
                },
                {
                  _or: [
                    {
                      updatedAt: {_gt: $filterDate},                
                    },
                    {
                      autoId: {_gt: $autoId}
                      updatedAt: {_gte: $filterDate},
                    }
                  ]
                }
              ]                                    
            },
            order_by: [
              {updatedAt: asc},
              {autoId: asc}
            ],
            limit: 500
          ) {
            ${fields}            
          }
        }
      `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
    },
  };
};

export const tblDocumentRevisionsMutation = (doc: any) => {
  const query = `
        mutation upsertDocumentRevisions($input: DocumentRevisionsInput!) {
          upsertDocumentRevisions(input: $input) {
            ${fields}
          }
        }
    `;
  const variables = {
    input: omit(doc, ['deletedAt', 'updatedAt', 'tblDocumentation']),
  };

  return {
    query,
    variables,
  };
};

export const documentRevisionsSubscription = `
  subscription onDocumentRevisionsChanged {
    tblDocumentRevisions {
      ${fields}
    }
  }
`;
