import { Certificate } from 'src/generated/graphql';
import { omit } from 'lodash';
import {logger} from "../../../helpers/logger";

const fields = `
  PKey
  fldType
  DateIssue
  DateExpire
  fldCertNum
  fldCrewID
  fldSMS
  fldTimeWarn
  fldTimeWarnMeasure
  fldNotes
  fldResponsible
  DateEntered
  fldCertType
  fldNationality
  fldIssue
  fldArchive
  fldSRHKey
  updatedAt
  deletedAt
  deletedBy
  isRecoverable
  autoId
`;

export const CertificatesQuery = (doc: Certificate) => {
  if (!doc) {
    doc = {
      autoId: 0,
      PKey: '',
      fldType: '',
      updatedAt: new Date(0).toISOString(),
    };
  }
  logger('Certificates').info(
    `pull query triggered - Certificates:  { ${new Date().toISOString()} - ID: ${doc.autoId}, updatedAt: ${doc.updatedAt} }`,
  );
  return {
    query: `
        query GetLatestCertificates($autoId: Int, $filterDate: Date) {
          Certificates(
            where:{ 
              _and: [
                {
                  _or: [
                    {
                      isRecoverable: {
                        _is_null: true
                      }
                    },
                    {
                      isRecoverable: {
                        _eq: true
                      }
                    }
                  ]
                },
                {
                  _or: [
                    {
                      updatedAt: {_gt: $filterDate},                
                    },
                    {
                      autoId: {_gt: $autoId}
                      updatedAt: {_gte: $filterDate},
                    }
                  ]
                }
              ]                                    
            },
            order_by: [
              {updatedAt: asc},
              {autoId: asc}],
            limit: 500
          ) {
            ${fields}            
          }
        }
      `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
    },
  };
};

export const CertificatesMutation = (doc: any) => {
  const query = `
    mutation UpsertCertificate($input: CertificateInput!) {
      upsertCertificate(input: $input) {
        ${fields}
      }
    }
  `;

  const variables = {
    input: omit(doc, ['updatedAt', 'deletedAt']),
  };

  return {
    query,
    variables,
  };
};

export const CertificatesSubscription = `
  subscription onCertificatesChanged {
    Certificates {
      ${fields}      
    }
  }
`;
