import { Currencies } from '../../../generated/graphql';
import {logger} from "../../../helpers/logger";

const fields = `
  autoId
  Abbreviation
  Curr
  ID
  Symbol
  SymbolBefore
  deletedAt
  deletedBy
  isRecoverable
  updatedAt
`;

export const CurrenciesQuery = (doc: Currencies) => {
  if (!doc) {
    doc = {
      autoId: 0,
      ID: '',
      Curr: '',
      updatedAt: new Date(0).toISOString(),
    };
  }
  logger('Currencies').info(
    `pull query triggered - Currencies: { ${new Date().toISOString()} - ID: ${doc.autoId}, updatedAt: ${doc.updatedAt} }`,
  );
  return {
    query: `
      query GetLatestCurrenies ($autoId: Int!, $filterDate: Date){
          Currencies(
            where:{
              _and: [
                {
                  _or: [
                    {
                      isRecoverable: {
                        _is_null: true
                      }
                    },
                    {
                      isRecoverable: {
                        _eq: true
                      }
                    }
                  ]
                },
                {
                  _or: [
                    {
                      updatedAt: {_gt: $filterDate},                
                    },
                    {
                      autoId: {_gt: $autoId}
                      updatedAt: {_gte: $filterDate},
                    }
                  ]
                }
              ]                                    
            },
            order_by: [
              {updatedAt: asc},
              {autoId: asc}
            ],
            limit: 500
          ) {
            ${fields}
          }
      }
      `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
    },
  };
};

export const CurrenciesSubscription = `
  subscription onCurrenciesChanged {
    Currencies {
      ${fields}
    }
  }
`;
