import { FC } from 'react';
import { Control } from 'react-hook-form';
import Autocomplete from '../Autocomplete';
import { useGetList } from 'src/hooks/lists/useGetList';

interface InjectedProps {
  listName: string;
  onChange: (name: string, value: any) => void;
  freeSolo?: boolean;
  label?: string;
  control: Control;
  name: string;
  disabled?: boolean;
}

const ListDefaultAutocomplete: FC<InjectedProps> = ({
  listName,
  onChange,
  freeSolo,
  label,
  control,
  name,
  ...rest
}) => {
  const { data } = useGetList(listName);

  return (
    <Autocomplete
      {...rest}
      defaultPayload={{
        fldListName: listName,
        fldT4Reqd: true,
      }}
      displayExpr="fldMember"
      keyExpr="pkey"
      onChange={onChange}
      freeSolo={freeSolo}
      options={data}
      label={label || listName}
      control={control}
      name={name}
      collection="tblddlistdefaults" // Use this in order to create new items.
    />
  );
};

export default ListDefaultAutocomplete;
